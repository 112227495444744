import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// import socketIo from 'socket.io-client';

import {
	Badge,
	NavLink,
	NavItem,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Dropdown
} from 'reactstrap';
// import dateFormat from 'dateformat';

// import notificationsAPI from '../../services/api/notifications';
// import classes from './NotificationsDropdown.module.css';
// import axios from '../../services/api/api';
// import machines from '../../services/api/machines';
// import vmUtil from '../../services/util/vmUtil';

class MassageDropdown extends Component {
	state = {
		numberOfUnreadNotifications: 0,
		dropdownOpen: false
	};

	// componentDidMount() {
	// 	this.initWebsocket();

	// 	notificationsAPI.getUnreadNotifications().then(result => {
	// 		this.setState({ numberOfUnreadNotifications: result.data });
	// 	});

	// 	// Check for service worker
	// 	if ('serviceWorker' in navigator) {
	// 		this.registerPushNotificationSubscription().catch(err =>
	// 			console.error(err)
	// 		);
	// 	}
	// }

	// initWebsocket() {
	// 	if (this.props.usePrivilege && this.props.usePrivilege.privatekey) {
	// 		const socket = socketIo(process.env.REACT_APP_BACKEND_WEBSOCKET_SERVER, {
	// 			transport: ['websocket']
	// 		});
	// 		socket.on('connect', () => {
	// 			socket.emit('join', this.props.usePrivilege.privatekey);
	// 		});
	// 		socket.on('refreshnotifications', () => {
	// 			notificationsAPI.getUnreadNotifications().then(result => {
	// 				this.setState({ numberOfUnreadNotifications: result.data });
	// 			});
	// 		});
	// 	}
	// }

	toggle = () => {
		if (!this.state.dropdownOpen) {
			// notificationsAPI.getNotifications(0, 5).then(notifications => {
			// 	this.setState({ notifications: notifications.data });
			// });
		}
		this.setState({
			numberOfUnreadNotifications: 0,
			dropdownOpen: !this.state.dropdownOpen
		});
	};

	// urlBase64ToUint8Array(base64String) {
	// 	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	// 	const base64 = (base64String + padding)
	// 		.replace(/-/g, '+')
	// 		.replace(/_/g, '/');

	// 	const rawData = window.atob(base64);
	// 	const outputArray = new Uint8Array(rawData.length);

	// 	for (let i = 0; i < rawData.length; ++i) {
	// 		outputArray[i] = rawData.charCodeAt(i);
	// 	}
	// 	return outputArray;
	// }

	renderBadge() {
		if (this.state.numberOfUnreadNotifications > 0) {
			if (this.state.numberOfUnreadNotifications > 99) {
				return (
					<Badge pill color="info" className="ml-1">
						99+
					</Badge>
				);
			} else {
				return (
					<Badge pill color="info" className="ml-1">
						{this.state.numberOfUnreadNotifications}
					</Badge>
				);
			}
		} else {
			return null;
		}
	}

	// async onNotificationClick(notification) {
	// 	const parsedValues = JSON.parse(notification.values);
	// 	if (parsedValues.hardwareID) {
	// 		const vendingMachineID = await machines.getVendingMachineByHardwareID(
	// 			parsedValues.hardwareID
	// 		);
	// 		if (vendingMachineID) {
	// 			const encodedVMID = vmUtil.encodeVMID(vendingMachineID);
	// 			this.props.history.push('/vms/' + encodedVMID + '/detailer');
	// 		}
	// 	}
	// }

	// renderDropdownItems() {
	// 	if (this.state.notifications) {
	// 		return this.state.notifications.map((notification, index) => {
	// 			const textDate = dateFormat(
	// 				new Date(notification.insertedAt),
	// 				'yyyy. mm. dd. HH:MM'
	// 			);

	// 			return (
	// 				<DropdownItem

	// 					key={index}
	// 					onClick={() => this.onNotificationClick(notification)}
	// 				>
	// 					<div className={classes.IconDiv}>
	// 						<i
	// 							className={notification.icon}
	// 							style={{ fontSize: '1.5rem' }}
	// 							aria-hidden="true"
	// 						></i>
	// 					</div>
	// 					<div className={classes.TextDiv}>
	// 						<p>{notification.resolvedText}</p>
	// 						<p className={classes.DateParagraph}>{textDate}</p>
	// 					</div>
	// 				</DropdownItem>
	// 			);
	// 		});
	// 	} else {
	// 		return null;
	// 	}
	// }

	render() {
		return !this.props.dev ? (
			<Dropdown
				isOpen={this.state.dropdownOpen}
				className={this.props.className}
				toggle={this.toggle}
			>
				<DropdownToggle nav className="text-uppercase" caret>
					<i className="icon-envelope"></i>
					{this.renderBadge()}
				</DropdownToggle>
				<DropdownMenu right width="200">
					<DropdownItem header tag="div" className="text-center">
						<strong>
							{this.props.t('invisibleTouchManager.messagePanel.messages')}
						</strong>
					</DropdownItem>
					{/* {this.renderDropdownItems()} */}
					<DropdownItem footer="true" tag="div" className="text-center">
						<NavLink href="/#/notifications" className="nav-link">
							<strong>
								{this.props.t('invisibleTouchManager.messagePanel.noData')}
							</strong>
						</NavLink>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		) : null;
	}
}

const mapStateToProps = state => ({
	usePrivilege: state.user
});

export default withRouter(
	connect(mapStateToProps)(withTranslation()(MassageDropdown))
);
