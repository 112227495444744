//@flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { RedMachineButton } from '../../../../components/Machines/MachineComponents'
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent'
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard'

type Props = {
  vmID: string,
  history: Object,
  products?: Array<Object>,
}
type States = {}

class Quarzo500 extends React.Component<Props, States> {
  keyboardConfig = [
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <i className="fa fa-long-arrow-up mr-1" /> 15
          </>
        ),
        buttonID: '15',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <span style={{ fontSize: 'medium' }}>ENTER</span> <br /> 17
          </>
        ),
        buttonID: '17',
      },
    ],

    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <i className="fa fa-long-arrow-down mr-1" />
            16
          </>
        ),
        buttonID: '16',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <span style={{ fontSize: 'medium' }}>ESC</span> <br /> 18
          </>
        ),
        buttonID: '18',
      },
    ],

    [
      {
        buttonContent: <>1</>,
        buttonID: '1',
      },
      {
        buttonContent: <>8</>,
        buttonID: '8',
      },
    ],
    [
      {
        buttonContent: <>2</>,
        buttonID: '2',
      },
      {
        buttonContent: <>9</>,
        buttonID: '9',
      },
    ],
    [
      {
        buttonContent: <>3</>,
        buttonID: '3',
      },
      {
        buttonContent: <>10</>,
        buttonID: '10',
      },
    ],
    [
      {
        buttonContent: <>4</>,
        buttonID: '4',
      },
      {
        buttonContent: <>11</>,
        buttonID: '11',
      },
    ],
    [
      {
        buttonContent: <>5</>,
        buttonID: '5',
      },
      {
        buttonContent: <>12</>,
        buttonID: '12',
      },
    ],
    [
      {
        buttonContent: <>6</>,
        buttonID: '6',
      },
      {
        buttonContent: <>13</>,
        buttonID: '13',
      },
    ],
    [
      {
        buttonContent: <>7</>,
        buttonID: '7',
      },
      {
        buttonContent: <>14</>,
        buttonID: '14',
      },
    ],
  ]

  render() {
    return (
      <DirectChooseMachineBaseComponent
        machineTitle="Quarzo500"
        lineNumber={2}
        lineLength={20}
        keyboard={
          <DirectChooseKeyboard
            programmingButtonVisible={false}
            keyboardConfig={this.keyboardConfig}
            {...this.props}
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(Quarzo500)
