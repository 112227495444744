import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import classes from './SalesAndIncomeTable.module.css';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

const TableToolBar = props => {
	return (
		<Container fluid className={classes.TableToolbarContainer}>
			<Row
				noGutters
				className={classes.TableToolbar}
				style={{ alignItems: 'flex-start' }}
			>
				<Col xs={12} sm={12} md={12} lg={9} xl={10}>
					<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
						<Col sm={12} md={12} lg={12} xl={6}>
							<Row
								style={{
									alignItems: 'center',
									justifyContent: 'center'
								}}
							>
								<Col
									xs={12}
									sm={4}
									md={3}
									lg={4}
									xl={11}
									xxl={12}
									className={classes.labelCol}
								>
									<Form.Label>
										{props.t('salesSnadIncome.startDate')}
									</Form.Label>
								</Col>
								<Col xs={12} sm={8} md={9} lg={8} xl={11} xxl={12}>
									<Form.Control
										className={classes.Date}
										name="startDate"
										type="datetime-local"
										value={
											moment(props.startDate).format('YYYY-MM-DDTHH:mm:ss') ||
											''
										}
										onChange={e => props.onInputChange(e)}
									></Form.Control>
								</Col>
								<Col></Col>
							</Row>
						</Col>
						<Col sm={12} md={12} lg={12} xl={6}>
							<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
								<Col
									xs={12}
									sm={4}
									md={3}
									lg={4}
									xl={11}
									xxl={12}
									className={classes.labelCol}
								>
									<Form.Label>{props.t('salesSnadIncome.endDate')} </Form.Label>
								</Col>
								<Col xs={12} sm={8} md={9} lg={8} xl={11} xxl={12}>
									<Form.Control
										className={classes.Date}
										name="endDate"
										type="datetime-local"
										value={
											moment(props.endDate).format('YYYY-MM-DDTHH:mm:ss') || ''
										}
										onChange={e => props.onInputChange(e)}
									></Form.Control>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col xs={12} sm={12} md={12} lg={3} xl={2} className={classes.BtnCol}>
					{props.loadButton ? props.loadButton : null}

					{props.btnGroup}

					{props.excelExportButton ? props.excelExportButton() : null}
				</Col>
			</Row>
		</Container>
	);
};

export default withTranslation()(TableToolBar);
