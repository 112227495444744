//@flow
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
//import { NavLink } from 'reactstrap';
import Button from 'react-bootstrap/Button';
//Google Maps

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import InfoWindowEx from './InfoWindowEx';

import VMUtil from '../../../services/util/vmUtil';

class GoogleMapsComponent extends Component {
	state = {
		showInfoWindow: false,
		activeMarker: null,
		selectedLocation: '',
		dimensions: {}
	};
	center = { lat: 50.10222, lng: 9.25442 };
	zoom = 3.8;

	constructor(props) {
		super(props);
		this.mapRef = React.createRef();
	}

	componentDidMount() {}

	getBoundsZoomLevel(bounds, mapDim) {
		var WORLD_DIM = { height: 256, width: 256 };
		var ZOOM_MAX = 21;

		function latRad(lat) {
			var sin = Math.sin((lat * Math.PI) / 180);
			var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
			return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
		}

		function zoom(mapPx, worldPx, fraction) {
			return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
		}

		var ne = bounds.getNorthEast();
		var sw = bounds.getSouthWest();

		var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

		var lngDiff = ne.lng() - sw.lng();
		var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

		var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
		var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

		return Math.min(latZoom, lngZoom, ZOOM_MAX);
	}

	render() {
		let markers = [];
		if (this.props.machines && this.props.machines.length > 0) {
			const locations = this.props.machines
				.filter(machine => machine.lat && machine.lng)
				.map(machine => {
					return {
						lat: machine.lat,
						lng: machine.lng,
						title: machine.name,
						link: 'vms/' + VMUtil.encodeVMID(machine.vmID) + '/detailer',
						connectionStatus: machine.onlineState,
						address: machine.address
					};
				});

			if (locations.length > 0) {
				const bounds = new window.google.maps.LatLngBounds();
				markers = locations.map((loc, index) => {
					bounds.extend(new window.google.maps.LatLng(loc.lat, loc.lng));
					return (
						<Marker
							key={index}
							position={{ lat: loc.lat, lng: loc.lng }}
							title={loc.title}
							onClick={(props, marker, e) =>
								this.setState({
									showInfoWindow: true,
									activeMarker: marker,
									selectedLocation: loc
								})
							}
						/>
					);
				});

				const boundsCenter = bounds ? bounds.getCenter() : undefined;
				this.center = {
					lat: boundsCenter ? boundsCenter.lat() : null,
					lng: boundsCenter ? boundsCenter.lng() : null
				};

				const dimensions = { width: 400, height: 400 };
				if (this.mapRef && this.mapRef.current) {
					dimensions.height = this.mapRef.current.clientHeight
						? this.mapRef.current.clientHeight
						: 400;
					dimensions.width = this.mapRef.current.clientWidth;
				}
				const boundsZoomLevel =
					this.center.lat && this.center.lng
						? this.getBoundsZoomLevel(bounds, dimensions)
						: undefined;

				this.zoom = boundsZoomLevel ? Math.min(boundsZoomLevel, 16) : 6;
			}
		}

		const mapStyles = {
			margin: '0px 40px 80px 0px'
		};
		const monitorStyle = {
			margin: '0px 10px 10px 0px',
			borderRadius: '10px'
		};
		return (
			<div ref={this.mapRef} style={{ width: '100%', height: '100%' }}>
				<Map
					google={this.props.google}
					zoom={this.zoom}
					style={this.props.monitor ? monitorStyle : mapStyles}
					center={this.center}
					initialCenter={this.center}
					mapTypeControl={false}
					streetViewControl={false}
					onClick={() => this.setState({ showInfoWindow: false })}
				>
					{markers}
					<InfoWindowEx
						marker={this.state.activeMarker}
						visible={this.state.showInfoWindow}
					>
						<>
							<h4>{this.state.selectedLocation.title}</h4>
							<div style={{ marginBottom: '1rem' }}>
								<b>{this.props.t('mapView.address')}: </b>&nbsp;
								{this.state.selectedLocation.address}
								<br />
								<b>{this.props.t('mapView.connectionState')}: </b>&nbsp;
								{this.state.selectedLocation.connectionStatus}
								<br />
							</div>
							<Button
								style={{ float: 'right' }}
								onClick={e =>
									this.props.history.push(this.state.selectedLocation.link)
								}
							>
								{this.props.t('mapView.show')}&nbsp;
								<i className="fa fa-external-link"></i>
							</Button>
						</>
					</InfoWindowEx>
				</Map>
			</div>
		);
	}
}
export default withTranslation()(
	withRouter(
		GoogleApiWrapper({
			apiKey: process.env.REACT_APP_GOOGLE_API_KEY
		})(GoogleMapsComponent)
	)
);
