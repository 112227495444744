/* eslint-disable eqeqeq */
import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';

import classes from './DeviceComponents.module.css';
import { withTranslation } from 'react-i18next';

class DeviceComponents extends Component {
	renderHardwareState(hardwareState) {
		let stateString = this.props.hardwareStates
			.filter(state => state.id == hardwareState)
			.map(state => state.statename);

		let style = { fontStyle: 'italic' };
		switch (hardwareState) {
			case 1:
				style.color = '#4dbd74';
				break;
			case 2:
				style.color = '#DB021D';
				break;
			case 3:
				style.color = '#7C8797';
				break;
			default:
				break;
		}

		return <span style={style}>{stateString}</span>;
	}

	renderHardwareComponent(hardwareComp) {
		const machineDetail =
			hardwareComp.machines && hardwareComp.machines.length > 0
				? hardwareComp.machines.map(machine => {
						return ' ' + machine.machineName + ' ';
				  })
				: null;

		return (
			<>
				<div>{hardwareComp.partType}</div>
				<div className={'small text-muted ' + classes.detailMargin}>
					{this.props.t(
						'hardwareManagement.hardwareHistory.electronicsItemNumber'
					)}
					&nbsp;
					{hardwareComp.electronicsItemNumber}
				</div>
				<div className={'small text-muted ' + classes.detailMargin}>
					{this.props.t('hardwareManagement.hardwareHistory.hardwareState')}
					&nbsp;
					{this.renderHardwareState(hardwareComp.hardwareState)}
				</div>
				{hardwareComp.hidHardwareID ? (
					<div className={'small text-muted ' + classes.detailMargin}>
						{this.props.t('hardwareManagement.hardwareHistory.hidID')}
						{hardwareComp.hidHardwareID}
					</div>
				) : null}
				{hardwareComp.hidHardwareID ? (
					<div className={'small text-muted ' + classes.detailMargin}>
						{this.props.t('hardwareManagement.hardwareHistory.machine')}
						{machineDetail}
					</div>
				) : null}
			</>
		);
	}

	renderCardBody() {
		switch (this.props.historyEvent.event) {
			case 'ADD':
				return this.renderHardwareComponent(this.props.historyEvent);
			case 'REMOVE':
				return this.renderHardwareComponent(this.props.historyEvent);
			case 'STATE':
				const componentList = this.props.historyEvent.parts.map(part =>
					this.renderHardwareComponent(part)
				);
				return (
					<>
						<div>
							{this.props.t('hardwareManagement.hardwareHistory.componentList')}
						</div>
						<div className={classes.detailMargin}>{componentList}</div>
					</>
				);
			case 'CONNECTED_TO_MACHINE': {
				return (
					<>
						{this.props.t('hardwareManagement.hardwareHistory.machine')}&nbsp;
						{this.props.historyEvent.machineName}
						<br /> {this.props.t('hardwareManagement.hardwareHistory.customer')}
						&nbsp;
						{this.props.historyEvent.customer}
					</>
				);
			}
			case 'REMOVED_FROM_MACHINE': {
				return (
					<>
						{this.props.t('hardwareManagement.hardwareHistory.machine')}
						<br /> {this.props.t('hardwareManagement.hardwareHistory.customer')}
						&nbsp;
						{this.props.historyEvent.customer}
					</>
				);
			}
			default:
				return undefined;
		}
	}

	getDateString(source) {
		return new Date(source).toLocaleString();
	}

	renderCardTitle() {
		switch (this.props.historyEvent.event) {
			case 'ADD':
				return (
					<>
						<div className={classes.eventBubble}>
							<i
								className={
									'fa fa-plus ' + classes.addSign + ' ' + classes.eventSign
								}
							></i>
						</div>
						<span className={classes.dateSpan}>
							{this.getDateString(this.props.historyEvent.date)}
						</span>
						<span className={classes.eventSpan}>
							{this.props.t('hardwareManagement.hardwareHistory.added')}
						</span>
					</>
				);
			case 'REMOVE':
				return (
					<>
						<div className={classes.eventBubble}>
							<i
								className={
									'fa fa-minus ' + classes.removeSign + ' ' + classes.eventSign
								}
							></i>
						</div>
						<span className={classes.dateSpan}>
							{this.getDateString(this.props.historyEvent.date)}
						</span>
						<span className={classes.eventSpan}>
							{this.props.t('hardwareManagement.hardwareHistory.removed')}
						</span>
					</>
				);
			case 'STATE':
				return (
					<>
						<div className={classes.eventBubble}>
							<i
								className={
									'fa fa-arrow-right ' +
									classes.stateSign +
									' ' +
									classes.eventSign
								}
							></i>
						</div>
						<span className={classes.dateSpan}>
							{this.getDateString(this.props.historyEvent.date)}
						</span>
						<span className={classes.eventSpan}>
							{this.props.t('hardwareManagement.hardwareHistory.state')}
						</span>
					</>
				);
			case 'MANUFACTURED':
				return (
					<>
						<div className={classes.eventBubble}>
							<i
								className={
									'fa fa-industry ' +
									classes.stateSign +
									' ' +
									classes.eventSign
								}
							></i>
						</div>
						<span className={classes.dateSpan}>
							{this.getDateString(this.props.historyEvent.date)}
						</span>
						<span className={classes.eventSpan}>
							{this.props.t('hardwareManagement.hardwareHistory.manufactured')}
						</span>
					</>
				);
			case 'ACTIVATED':
				return (
					<>
						<div className={classes.eventBubble}>
							<i
								className={
									'fa fa-play ' + classes.stateSign + ' ' + classes.eventSign
								}
							></i>
						</div>
						<span className={classes.dateSpan}>
							{this.getDateString(this.props.historyEvent.date)}
						</span>
						<span className={classes.eventSpan}>
							{this.props.t('hardwareManagement.hardwareHistory.activated')}
						</span>
					</>
				);
			case 'CONNECTED_TO_MACHINE':
				return (
					<>
						<div className={classes.eventBubble}>
							<i
								className={
									'fa fa-plus ' + classes.addSign + ' ' + classes.eventSign
								}
							></i>
						</div>
						<span className={classes.dateSpan}>
							{this.getDateString(this.props.historyEvent.date)}
						</span>
						<span className={classes.eventSpan}>
							{this.props.t(
								'hardwareManagement.hardwareHistory.addedToMachine'
							)}
						</span>
					</>
				);
			case 'REMOVED_FROM_MACHINE':
				return (
					<>
						<div className={classes.eventBubble}>
							<i
								className={
									'fa fa-minus ' + classes.removeSign + ' ' + classes.eventSign
								}
							></i>
						</div>
						<span className={classes.dateSpan}>
							{this.getDateString(this.props.historyEvent.date)}
						</span>
						<span className={classes.eventSpan}>
							{this.props.t(
								'hardwareManagement.hardwareHistory.removedFromMachine'
							)}
						</span>
					</>
				);
			default:
				return undefined;
		}
	}

	render() {
		const cardTitle = this.renderCardTitle();
		const cardBody = this.renderCardBody();
		return (
			<Card className={classes.historyCard}>
				<Card.Body className={classes.cardPadding}>
					<Card.Title className={classes.titleMargin}>{cardTitle}</Card.Title>
					<Card.Text className={classes.bodyMargin}>{cardBody}</Card.Text>
				</Card.Body>
			</Card>
		);
	}
}

export default withTranslation()(DeviceComponents);
