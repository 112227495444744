import { combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import language from './langauge';
import user from './user';
import table from './table';
import invisibleTouch from './invisibleTouch';

const store = createStore(
	combineReducers({
		language,
		user,
		table,
		invisibleTouch
	}),
	composeWithDevTools()
);
export default store;
