//@flow
import React, { Component } from 'react';
import { Card, Row, Col, Input } from 'reactstrap';
import {
	Section,
	SectionList,
	SectionSmallSubTitle,
	SectionSmallTitle,
	SectionSubTitle,
	SectionTitle
} from '../../../components/Section';
import { GoogleApiWrapper } from 'google-maps-react';
import Toggle from 'react-bootstrap-toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

import {
	getColorForState
	// getStateTextClassName
} from '../../../services/util/colors';
import { withTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import getStatusId from '../../../i18n/connectionStatus';
import getMachineStatusById from '../../../i18n/machineStatus';
import DeviceImageUploader from './DeviceImageUploader';
import 'react-butterfiles';

import MapViewCard from '../../Dashboard/CardHandling/MapViewCard/MapViewCard';
import VMUtil from '../../../services/util/vmUtil';

type Props = {
	t: TFunction,
	machine: Object,
	gallery: Array<any>,
	onAddImage: (files: Array<{ file: File, base64: string }>) => void,
	onRemoveImage: (file: { index?: number, id?: number }) => void,
	handleNameChange: (name: string) => void
};

type States = {
	dragging?: boolean,
	editMode?: boolean
};

class StateCard extends Component<Props, States> {
	state = {
		editMode: false
	};
	getMachinaName = machine => {
		if (machine.machine === 'Brio3MiniSnakky') {
			return 'Brio3 - MiniSnakky';
		} else if (machine.machine === 'KikkoSnakky') {
			return 'Kikko - Snakky';
		}
		return machine.machine;
	};

	//handleNameChange = event => this.props.handleNameChange(event.target.value);
	handleAddressChange = event => this.props.handleAddressChange(event.target);
	handleLongitudeChange = event =>
		this.props.handleLongitudeChange(event.target.value);
	handleLattitudeChange = event =>
		this.props.handleLattitudeChange(event.target.value);
	handleToggleAccess = newValue => {
		this.props.handleAccessChange(newValue);
	};
	handleToggleUpgState = newValue => {
		this.props.handleNfdUpgStateChange(newValue);
	};
	handleToggleVisibility = newValue => {
		this.props.handleMapVisibilityChange(newValue);
	};
	handleToggleInvTouchOperation = newValue => {
		this.props.handleToggleInvTouchOperation(newValue);
	};
	handleCustomNameChange = event => {
		this.props.handleCustomNameChange(event.target.value);
	};

	initGeocoder = maps => {
		this.Geocoder = new maps.Geocoder();
	};

	handleGeoCodeClick = event => {
		const geoCoder = new window.google.maps.Geocoder();
		geoCoder.geocode(
			{
				address: `${this.props.machine.country} ${this.props.machine.city} + ${this.props.machine.address} `
			},
			(results, status) => {
				if (status === 'OK') {
					this.props.handleLongitudeChange(results[0].geometry.location.lng());
					this.props.handleLattitudeChange(results[0].geometry.location.lat());
				} else {
					alert(
						'Geocode was not successful for the following reason: ' + status
					);
				}
			}
		);
	};

	render() {
		const { editMode = false } = this.props;
		const { machine, t, gallery, onRemoveImage, onAddImage } = this.props;
		if (!machine) return null;

		return (
			<>
				<Card>
					<SectionList>
						<Section>
							<SectionTitle>{t('device.details.name')}</SectionTitle>

							{editMode ? (
								<SectionSmallSubTitle>
									<Input
										value={machine.customName}
										onChange={e => this.handleCustomNameChange(e)}
									/>
								</SectionSmallSubTitle>
							) : (
								<SectionSubTitle>{machine.customName}</SectionSubTitle>
							)}
						</Section>
						<Section>
							<SectionSmallTitle>
								{t('device.details.machineStatus')}
							</SectionSmallTitle>
							<SectionSmallSubTitle
								className={`text-${getColorForState(
									VMUtil.getMachineStateStringFromID(
										machine.vendingMachineState
									)
								)}`}
							>
								<Row>
									<Col md="12">
										{t(getMachineStatusById(machine.vendingMachineState))}
									</Col>
								</Row>
							</SectionSmallSubTitle>
						</Section>
						<Section>
							<SectionSmallTitle>
								{t('device.details.connectionStatus')}
							</SectionSmallTitle>
							<SectionSmallSubTitle
								className={`text-${getColorForState(machine.onlineState)}`}
							>
								{t(getStatusId(machine.onlineState))}
							</SectionSmallSubTitle>
						</Section>
						{/* -------------- H-vend Access Switch --------------- */}
						<Section>
							<SectionSmallTitle>
								{t('device.details.hvendAccess')}
							</SectionSmallTitle>
							<SectionSmallSubTitle>
								{
									<Toggle
										onClick={() =>
											this.props.editMode
												? this.handleToggleAccess(
														machine.supportAccessEnabled !== 'Y'
												  )
												: {}
										}
										on={t('device.details.enabled')}
										off={t('device.details.disabled')}
										active={machine.supportAccessEnabled === 'Y'}
										onstyle="info"
										offstyle="primary"
										size="sm"
										enabled={this.props.editMode}
										style={{
											width: '150px',
											height: '30px',
											borderRadius: '25px'
										}}
									/>
								}
							</SectionSmallSubTitle>
						</Section>
						{/* -------------- H-vend Access Switch --------------- */}

						{/* -------------- IVT Settings --------------- */}
						<Section>
							<SectionSmallTitle>
								{t('device.details.invisibleTouchEnabled')}
							</SectionSmallTitle>
							<SectionSmallSubTitle>
								{
									<Toggle
										onClick={() =>
											this.props.editMode
												? this.handleToggleInvTouchOperation(
														machine.invisibleTouchOperationEnabled !== 'Y'
												  )
												: {}
										}
										on={t('device.details.enabled')}
										off={t('device.details.disabled')}
										active={machine.invisibleTouchOperationEnabled === 'Y'}
										onstyle="info"
										offstyle="primary"
										size="sm"
										enabled={this.props.editMode}
										style={{
											width: '150px',
											height: '30px',
											borderRadius: '25px'
										}}
									/>
								}
							</SectionSmallSubTitle>
							<SectionSmallTitle>
								{t('device.details.mapVisible')}
							</SectionSmallTitle>
							<SectionSmallSubTitle>
								{
									<Toggle
										onClick={() =>
											this.props.editMode
												? this.handleToggleVisibility(
														machine.invisibleTouchVisibleOnMap !== 'Y'
												  )
												: {}
										}
										on={t('device.details.enabled')}
										off={t('device.details.disabled')}
										active={machine.invisibleTouchVisibleOnMap === 'Y'}
										onstyle="info"
										offstyle="primary"
										size="sm"
										enabled={this.props.editMode}
										style={{
											width: '150px',
											height: '30px',
											borderRadius: '25px'
										}}
									/>
								}
							</SectionSmallSubTitle>
						</Section>
						{/* -------------- IVT Settings --------------- */}

						{/* -------------- Upgrade Satatus Switch--------------- */}
						<Section>
							<SectionSmallTitle>
								{t('device.details.upgStatus')}
							</SectionSmallTitle>
							<SectionSmallSubTitle>
								{
									<Toggle
										onClick={() =>
											this.props.editMode
												? this.handleToggleUpgState(machine.nfdUpgraded !== 'Y')
												: {}
										}
										on={t('device.details.upg')}
										off={t('device.details.original')}
										active={machine.nfdUpgraded === 'Y'}
										onstyle="success"
										offstyle="secondary"
										size="sm"
										enabled={this.props.editMode}
										style={{
											width: '150px',
											height: '30px',
											borderRadius: '25px'
										}}
									/>
								}
							</SectionSmallSubTitle>
						</Section>
						{/* -------------- Upgrade Satatus Switch --------------- */}
						<Section>
							{!editMode && (
								<SectionSmallTitle>
									{t('device.details.address')}
								</SectionSmallTitle>
							)}
							{editMode && (
								<SectionSmallTitle>
									{t('device.details.country')}
								</SectionSmallTitle>
							)}

							<SectionSmallSubTitle>
								{!editMode && <>{machine.country}</>}
								{editMode && (
									<Input
										type="text"
										id="country"
										name="country"
										value={machine.country}
										onChange={this.handleAddressChange}
									/>
								)}
							</SectionSmallSubTitle>

							{editMode && (
								<SectionSmallTitle>
									{t('device.details.city')}
								</SectionSmallTitle>
							)}
							<SectionSmallSubTitle>
								{!editMode && <>{machine.city}</>}
								{editMode && (
									<Input
										type="text"
										id="city"
										name="city"
										value={machine.city}
										onChange={this.handleAddressChange}
									/>
								)}
							</SectionSmallSubTitle>

							{editMode && (
								<SectionSmallTitle>
									{t('device.details.zipcode')}
								</SectionSmallTitle>
							)}
							<SectionSmallSubTitle>
								{!editMode && <>{machine.zipcode}</>}
								{editMode && (
									<Input
										type="text"
										id="zipcode"
										name="zipcode"
										value={machine.zipcode}
										onChange={this.handleAddressChange}
									/>
								)}
							</SectionSmallSubTitle>
							{editMode && (
								<SectionSmallTitle>
									{t('device.details.address')}
								</SectionSmallTitle>
							)}

							<SectionSmallSubTitle>
								{!editMode && <>{machine.address}</>}
								{editMode && (
									<Input
										type="text"
										id="address"
										name="address"
										value={machine.address}
										onChange={this.handleAddressChange}
									/>
								)}
							</SectionSmallSubTitle>
						</Section>
						<Section>
							<SectionSmallTitle>
								{t('device.details.geoLocation')}
							</SectionSmallTitle>

							<SectionSmallSubTitle>
								<Row>
									<Col md="4" className="align-self-center">
										{!editMode && (
											<>
												{t('device.details.lattitude')}
												<br />
												{machine.lat}
											</>
										)}
										{editMode && (
											<>
												{t('device.details.lattitude')}
												<Input
													type="text"
													id="lattitude"
													name="lattitude"
													value={machine.lat}
													onChange={this.handleLattitudeChange}
												/>
											</>
										)}
									</Col>
									<Col md="4" className="align-self-center">
										{!editMode && (
											<>
												{t('device.details.longitude')}
												<br />
												{machine.lng}
											</>
										)}
										{editMode && (
											<>
												{t('device.details.longitude')}
												<Input
													type="text"
													id="longitude"
													name="longitude"
													value={machine.lng}
													onChange={this.handleLongitudeChange}
												/>
											</>
										)}
									</Col>

									<Col
										md="4"
										style={{ marginBottom: '0px', marginTop: 'auto' }}
									>
										{this.props.editMode ? (
											<button
												className="btn btn-outline-success pull-right"
												onClick={this.handleGeoCodeClick}
											>
												{t('device.details.resolveAddress')}
											</button>
										) : null}
									</Col>
								</Row>
							</SectionSmallSubTitle>
						</Section>
						{machine && machine.lat && machine.lng ? (
							<MapViewCard machine={{ ...machine }} />
						) : null}
						<Section>
							<SectionSmallTitle>{t('device.details.type')}</SectionSmallTitle>
							<SectionSmallSubTitle>
								{this.getMachinaName(machine)}
							</SectionSmallSubTitle>
						</Section>
						<Section>
							<SectionSmallTitle>
								{t('device.details.manufactureDate')}
							</SectionSmallTitle>
							<SectionSmallSubTitle>
								{machine.manufactureDate}
							</SectionSmallSubTitle>
						</Section>
						<Section>
							<SectionSmallTitle>
								{t('device.details.activationDate')}
							</SectionSmallTitle>
							<SectionSmallSubTitle>
								{machine.activationDate}
							</SectionSmallSubTitle>
						</Section>
						<Section>
							<SectionSmallTitle>
								{t('device.details.company')}
							</SectionSmallTitle>
							<SectionSmallSubTitle>{machine.company}</SectionSmallSubTitle>
						</Section>

						<Section last>
							<DeviceImageUploader
								machine={machine}
								gallery={gallery}
								onAddImage={onAddImage}
								onRemoveImage={onRemoveImage}
								editable={editMode}
							/>
						</Section>
					</SectionList>
				</Card>
			</>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY
})(withTranslation()(StateCard));
