import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';

import classes from './PageTitleCard.module.css';

class PageTitleCard extends Component {
	render() {
		return (
			<Card className={classes.HeaderBackground}>
				<CardBody className={classes.CardBody}>
					<b>{this.props.title}</b>
				</CardBody>
			</Card>
		);
	}
}

export default PageTitleCard;
