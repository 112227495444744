import React from 'react';
import { Row, Card, CardBody } from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
// import EventListCard from '../Dashboard/CardHandling/EventListCard/EventListCard';
import classes from './Logs.module.css';
import LogsCard from './LogsCard/LogsCard';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';

class LogsList extends React.Component {
	render() {
		return (
			<div className="animated fadeIn">
				<Row>
					<DashboardCardContainerWithFlow>
						<Card body>
							<PageTiltleCard
								title={this.props.t('eventList.title')}
							></PageTiltleCard>
							<LogsCard
								days={1}
								showGrid={true}
								className={classes.LogListView}
								hideLink={false}
								// filter={true}
							/>
						</Card>
					</DashboardCardContainerWithFlow>
				</Row>
			</div>
		);
	}
}

export default withRouter(withTranslation()(LogsList));
