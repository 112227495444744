import React from 'react';
import { Card } from 'reactstrap';
import {
	Section,
	SectionList,
	SectionSubTitle,
	SectionTitle
} from '../../../components/Section';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

const statisticCard = props => {
	const { t, data, balance, transactions, machines } = props;
	const name =
		data && data.firstName && data.lastName
			? `${data.firstName} ${data.lastName}`
			: t('invisibleTouchManager.controlpanel.noData');
	const balanceLabel = ` ${
		balance && Number.isFinite(balance) ? balance.toFixed(2) : ''
	} ${
		data && data.currency && data.currency.length > 0 && data.currency[0].symbol
			? data.currency[0].symbol
			: ''
	}`;
	const purchases = transactions.filter(e => e.transactionTypeID === 7);
	const lastPurchase =
		purchases && purchases.length > 0
			? purchases.sort(
					(a, b) => new Date(b.insertedAt) - new Date(a.insertedAt)
			  )[0]
			: null;
	const lastUsedVm =
		machines && machines.length > 0 && lastPurchase
			? machines.find(e => e.vmID === lastPurchase.vmID)
			: '';
	return (
		<>
			<Card>
				<SectionList>
					<Section>
						<SectionTitle>
							{t('invisibleTouchManager.purchasesPanel.account')}
						</SectionTitle>

						<SectionSubTitle>{data.email}</SectionSubTitle>
					</Section>
					<Section>
						<SectionTitle>
							{t('invisibleTouchManager.purchasersTable.name')}
						</SectionTitle>

						<SectionSubTitle>{name}</SectionSubTitle>
					</Section>
					<Section>
						<SectionTitle>
							{t('invisibleTouchManager.purchasersTable.balance')}
						</SectionTitle>

						<SectionSubTitle>{balanceLabel}</SectionSubTitle>
					</Section>
					<Section>
						<SectionTitle>
							{t('invisibleTouchManager.purchasersTable.succesPurchase')}
						</SectionTitle>

						<SectionSubTitle>{purchases.length}</SectionSubTitle>
					</Section>
					<Section>
						<SectionTitle>
							{t('invisibleTouchManager.purchasersTable.lastPurchase')}
						</SectionTitle>

						<SectionSubTitle>
							{lastPurchase && lastPurchase.insertedAt
								? moment(lastPurchase.insertedAt).format('YYYY.MM.DD HH:mm:ss')
								: null}
						</SectionSubTitle>
					</Section>
					<Section last>
						<SectionTitle>
							{' '}
							{t('invisibleTouchManager.purchasersTable.lastUsedMchine')}
						</SectionTitle>

						<SectionSubTitle>
							{lastUsedVm && lastUsedVm.customName
								? lastUsedVm.customName
								: t('invisibleTouchManager.controlpanel.noData')}
						</SectionSubTitle>
					</Section>
				</SectionList>
			</Card>
		</>
	);
};

export default withTranslation()(statisticCard);
