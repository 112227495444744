import axios from './api';
import qs from 'qs';
import systemApi from './system';

const getCustomer = async cmID => {
	return await axios.get().get(`/customer`, { params: { cmID } });
};
export default {
	/*
   This function loads all the customers, but only in a simple format. 
   So it does not loads it's users or machines.
  */
	getCustomers: async () => {
		return await axios.get().get(`/customers`);
	},

	/**
	 * This function load the barion Settings of the user
	 * @returns
	 */
	getBarionSettings: async cmID => {
		const param = {
			cmID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/customer/invisibleTouch/settings${query}`);
	},
	/**
	 * This function load the barion Settings of the user
	 * @returns
	 */
	setBarionSettings: async (
		cmID,
		barionEmail,
		barionPercentageSettings,
		barionDisabled,
		cashUploadDisabled,
		invTouchDisabled
	) => {
		const param = {
			cmID,
			barionEmail,
			barionPercentageSettings,
			barionDisabled,
			cashUploadDisabled,
			invTouchDisabled
		};

		return await axios.get().put(`/customer/invisibleTouch/settings`, param);
	},

	/*
   This function loads all a customer, but only in a simple format. 
   So it does not loads it's users or machines.
  */
	getCustomer: getCustomer,

	getDailyIncome: async () => {
		return await axios.get().get(`/customer/dailyincome`);
	},

	getMonthlyIncome: async () => {
		return await axios.get().get(`/customer/monthlyincome`);
	},

	/**
	 * This function loads the customer related statistics from the database.
	 */
	getStatistics: async (statisticDataID, limitNumber) => {
		const param = {
			statisticDataID,
			limitNumber
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/customer/statistics${query}`);
	},

	/**
	 * This functions get machine events connected to the customer.
	 */
	getEvents: async (startDate, numberOfDays, machineId) => {
		const param = { startDate, numberOfDays, machineId };

		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/customer/events${query}`);
	},

	/**
	 * This function insert a customer into the database
	 */
	createCustomer: newPartner => {
		return axios.get().post('/customers', newPartner);
	},

	/**
	 * This function Updates a customer in the database
	 */
	saveCustomer: newPartner => {
		return axios.get().put('/customers', newPartner);
	},

	/*
    This function Delete a customer and it's users from the database
  */
	deleteCustomer: partnerToDelete => {
		return axios
			.get()
			.delete('/customers', { params: { cmID: partnerToDelete.cmID } });
	},

	/**
	 * This function get income informations of the user's assigned income values
	 */
	getIncomeStatistics: async (startDate, endDate) => {
		const param = { periodStart: startDate, periodEnd: endDate };
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/customer/incomeStatistic${query}`);
	},

	getCustomerCurrencySymbol: async cmID => {
		let result = '';

		const customerResponse = await getCustomer(cmID);
		const currenciesResponse = await systemApi.getCurrencies();
		if (
			customerResponse &&
			customerResponse.data &&
			customerResponse.data.response.length > 0 &&
			currenciesResponse
		) {
			const customer = customerResponse.data.response[0];
			const currencies = currenciesResponse.data.currencies;
			const currency = currencies.find(
				currency => currency.id == customer.currencyID
			);
			result = currency ? currency.symbol : '';
		}

		return result;
	}
};
