//@flow
import React from 'react'
import Kikko from '../Kikko/Kikko'
import Snakky from '../Snakky/Snakky'

type Props = {}
class KikkoSnakky extends React.Component<Props> {
  render() {
    return (
      <>
        <Kikko {...this.props} />
        <Snakky isSlave {...this.props} />
      </>
    )
  }
}

export default KikkoSnakky
