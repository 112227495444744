import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import clientApi from '../../../services/api/client';

class AddCustomerDialog extends Component {
	state = {
		customers: {
			accountNumber: '',
			companyName: '',
			contactEmail: '',
			contactPerson: '',
			contactPhone: '',
			location: '',
			taxNuber: '',
			id: ''
		},
		validated: false
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	onSubmitHandler = () => {
		const requestBody = {
			companyName: this.state.companyName,
			address: this.state.location,
			taxNumber: this.state.taxNuber,
			contactPerson: this.state.contactPerson,
			contactEmail: this.state.contactEmail,
			contactPhone: this.state.contactPhone
		};

		clientApi
			.createClient(requestBody)
			.then(res => this.props.onHide())
			.catch(e => console.log(e));
	};
	validationHandler = () => {
		const form = document.getElementById('addCostumerForm');
		if (form.checkValidity() === true) {
			this.onSubmitHandler();
		} else {
			this.setState({ validated: true });
		}
	};

	render() {
		return (
			<Form noValidate validated={this.state.validated} id={'addCostumerForm'}>
				<Form.Group controlId="companyName">
					<Form.Label>
						{this.props.t('customerlist.basicdata.label.companyname')}
					</Form.Label>
					<Form.Control
						required
						name="companyName"
						type="text"
						placeholder={this.props.t(
							'customerlist.addbutton.placeholder.companyname'
						)}
						value={this.state.companyName}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="location">
					<Form.Label>
						{this.props.t('customerlist.basicdata.label.location')}
					</Form.Label>
					<Form.Control
						required
						name="location"
						type="text"
						placeholder={this.props.t(
							'customerlist.addbutton.placeholder.location'
						)}
						value={this.state.location}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="taxNuber">
					<Form.Label>
						{this.props.t('customerlist.basicdata.label.taxnumber')}
					</Form.Label>
					<Form.Control
						required
						name="taxNuber"
						type="text"
						placeholder={this.props.t(
							'customerlist.addbutton.placeholder.taxnumber'
						)}
						value={this.state.taxNuber}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="contactPerson">
					<Form.Label>
						{this.props.t('customerlist.contact.label.contactperson')}
					</Form.Label>
					<Form.Control
						required
						name="contactPerson"
						type="text"
						placeholder={this.props.t(
							'customerlist.addbutton.placeholder.contactperson'
						)}
						value={this.state.contactPerson}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="contactEmail">
					<Form.Label>
						{this.props.t('customerlist.contact.label.email')}
					</Form.Label>
					<Form.Control
						required
						name="contactEmail"
						type="email"
						placeholder={this.props.t(
							'customerlist.addbutton.placeholder.email'
						)}
						value={this.state.contactEmail}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="contactPhone">
					<Form.Label>
						{this.props.t('customerlist.contact.label.phone')}
					</Form.Label>
					<Form.Control
						required
						name="contactPhone"
						type="text"
						placeholder={this.props.t(
							'customerlist.addbutton.placeholder.phone'
						)}
						value={this.state.contactPhone}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Button
					variant="success"
					className="DefaultButton"
					onClick={() => this.validationHandler()}
				>
					<i className="fa fa-floppy-o mr-2" />
					&nbsp;
					{this.props.t('customerlist.addbutton.savebutton')}
				</Button>
			</Form>
		);
	}
}

export default withTranslation()(AddCustomerDialog);
