export const TOAST_MESSAGE_TIMEOUT = 5000;
export const PIN_LENGTH = 6;

export const ROLES = {
	root: 'class1',
	companyAdmin: 'class2',
	technician: 'class3',
	regionalCompanyAdmin: 'class4',
	contentManager: 'class5',
	hvendTechnican: 'class6',
	mobileUser: 'class7'
};

export const LOG_TYPES = {
	info: 'info',
	error: 'error',
	fatal: 'fatal',
	warning: 'warn'
};
