import axios from './api';
import qs from 'qs';

export default {
	getMachineGroups: async () => {
		return await axios.get().get('/vendingmachines/groups');
	},

	getAssignedVms: async groupID => {
		const param = {
			groupID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/vendingmachines/groups/assignedVMS${query}`);
	},

	createGroup: (name, description) => {
		const param = {
			name,
			description
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios.get().post(`/vendingmachines/group${query}`);
	},

	deleteGroup: async groupID => {
		const param = {
			groupID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().delete(`/vendingmachines/group${query}`);
	},

	setAssignedVms: async (groupID, vmIDS) => {
		const param = {
			groupID
		};
		const query = qs.stringify(param);
		return await axios
			.get()
			.put(`/vendingmachines/groups/assignedVMS?&vmIDS=${vmIDS}&${query}`);
	},

	updateGroup: (groupID, name, description) => {
		const param = {
			groupID,
			name,
			description
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios.get().put(`/vendingmachines/group${query}`);
	}
};
