import styled from 'styled-components';
import { Row, Col } from 'reactstrap';

const colors = {
	hoki: '#607d8b', //simple button background
	light_slate_grey: '#78909c', //lighter button background
	empress: '#757575', // button label background
	night_rider: '#303030', //button/label collector background
	charcoal: '#424242', //background
	mortar: '#515151', //disabled button color
	red_orange: '#f44336', //red button background
	japanese_laurel: '#2F7D31' //programming button backround
};

const ButtonContainer = styled(Row)`
	border-radius: 5px;
	padding: 5px;
	padding-bottom: 7px;
	margin: 2px;
	background-color: ${props =>
		props.transparentBackground ? 'transparent' : colors.night_rider};
`;

const ButtonContainerCol = styled(Col)`
	border-radius: 5px;
	padding: 5px;
	padding-bottom: 7px;
	margin: 2px;
	background-color: ${colors.night_rider};
`;

const MachineButton = styled.button`
	width: 100%;
	padding: 10px;
	background-color: ${colors.hoki};
	color: white;
	border-radius: 5px;
	border: none;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	margin: 2px;
	min-height: 60px;
	font-weight: 600;
	font-size: larger;

	&:active {
		background-color: #61ce8b;
	}

	&:disabled {
		background-color: ${colors.mortar};
		color: rgba(255, 255, 255, 0.5);
	}
`;

const RedMachineButton = styled(MachineButton)`
	background-color: ${colors.red_orange};
	font-weight: 500;
	font-size: smaller;
`;

const GreenMachineButton = styled(MachineButton)`
	background-color: ${colors.japanese_laurel};
	font-weight: 500;
	font-size: larger;
`;

const GreenAccessButton = styled(MachineButton)`
	background-color: ${colors.japanese_laurel};
	font-weight: 600;
	font-size: 0.8rem;
	width: 45%;
	margin-top: -10px;
	height: 48px;
	min-height: 48px;
	padding: 0px;
`;

const RedAccessButton = styled(MachineButton)`
	background-color: ${colors.red_orange};
	font-weight: 600;
	font-size: 0.8rem;
	width: 45%;
	margin-top: -10px;
	height: 48px;
	min-height: 48px;
	padding: 0px;
`;

const DisabledAccessButton = styled(MachineButton)`
	background-color: ${colors.mortar};
	font-weight: 600;
	font-size: 0.8rem;
	width: 45%;
	margin-top: -10px;
	height: 48px;
	min-height: 48px;
	padding: 0px;

	&:active {
		background-color: ${colors.mortar};
	}
`;

const LightGrayMachineButton = styled(MachineButton)`
	background-color: ${colors.light_slate_grey};
	font-weight: 500;
	font-size: larger;
`;

const MachineLabel = styled.div`
	background-color: ${colors.empress};
	text-align: center;
	vertical-align: middle;
	min-height: 60px;
	border-radius: 5px;
	margin: 2px;
	padding: 10px;
	font-weight: 500;
	font-size: smaller;
	color: white;
`;

export {
	MachineButton,
	RedMachineButton,
	GreenMachineButton,
	GreenAccessButton,
	RedAccessButton,
	LightGrayMachineButton,
	MachineLabel,
	ButtonContainer,
	ButtonContainerCol,
	DisabledAccessButton
};
