export let SystemStaticsDataType = {
	partnerCount: 'PARTNER_COUNT',
	machineCount: 'MACHINE_COUNT',
	liveMachineCount: 'LIVE_MACHINE_COUNT',
	machineWithErrorsCount: 'MACHINE_WITH_ERROR_COUNT',
	freeDeviceCount: 'FREE_DEVICE_COUNT',

	incomeOfDay: 'INCOME_OF_DAY',
	incomeOfMonth: 'INCOME_OF_MONTH',
	customerOnlineMachines: 'CUSTOMER_ONLINE_MACHINES',
	customerMachineWithError: 'CUSTOMER_MACHINE_WITH_ERRORS',

	technicanOnlineMachines: 'TECHNICAN_ONLINE_MACHINES',
	technicanMachineWithError: 'TECHNICAN_MACHINE_WITH_ERRORS'
};
