import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import classes from './PrintDialog.module.css';

class PrintDialog extends Component {
	state = { printType: 0, numberOfCopies: 1 };

	inputUpdate(e) {
		let newState = { ...this.state };
		newState.numberOfCopies = e.target.value;
		this.setState(newState);
	}

	printOptionSelectUpdate(e) {
		this.setState({ printType: e.target.value });
	}

	handleFormSubmit(e) {
		e.preventDefault();
		this.props.onPrint(this.state.printType, this.state.numberOfCopies);
	}

	render() {
		let printOptions = [];
		printOptions.push(
			<option key={0} value={0}>
				{this.props.t('hardwareManagement.hardwareList.HID.printSmall')}
			</option>
		);
		printOptions.push(
			<option key={1} value={1}>
				{this.props.t('hardwareManagement.hardwareList.HID.printLarge')}
			</option>
		);
		return (
			<Form onSubmit={e => this.handleFormSubmit(e)}>
				<Form.Row>
					<Form.Label>
						{this.props.t('hardwareManagement.hardwareList.HID.stickerType')}
					</Form.Label>
					<Form.Control
						id="printOption"
						as="select"
						onChange={e => this.printOptionSelectUpdate(e)}
						value={this.state.printType}
					>
						{printOptions}
					</Form.Control>
				</Form.Row>
				<Form.Row>
					<Form.Label>
						{this.props.t('hardwareManagement.hardwareList.HID.numberOfCopies')}
					</Form.Label>
					<Form.Control
						id="numberOfCopies"
						type="number"
						value={this.state.numberOfCopies}
						onChange={e => this.inputUpdate(e)}
					/>
				</Form.Row>
				<div className={classes.ToolbarDiv}>
					<Button variant="success" type="submit" className="DefaultButton">
						<i className="fa fa-print mr-2" aria-hidden="true"></i>&nbsp;
						{this.props.t('hardwareManagement.hardwareList.part.print')}
					</Button>
				</div>
			</Form>
		);
	}
}

export default withTranslation()(PrintDialog);
