import React, { Component } from 'react';
import { Card } from 'reactstrap';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';
import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import { withTranslation } from 'react-i18next';
import SalesAndIncomeCard from '../../components/SalesAndInomeCard/SalesAndIncomeCard';

class SalesAndIncome extends Component {
	render() {
		const { t } = this.props;
		return (
			<div className="animated fadeIn">
				<DashboardCardContainerWithFlow>
					<Card body>
						<PageTiltleCard title={this.props.t('salesSnadIncome.title')} />
						<SalesAndIncomeCard day={1} />
					</Card>
				</DashboardCardContainerWithFlow>
			</div>
		);
	}
}

export default withTranslation()(SalesAndIncome);
