//@flow
import React, { Component } from 'react';

import { Col, Row } from 'reactstrap';
import {
	RedMachineButton,
	GreenMachineButton
} from '../../../../components/Machines/MachineComponents';
import { MachineButton as BaseButton } from '../../../../components/Machines/MachineComponents';
import styled from 'styled-components';

const MachineButton = styled(BaseButton)`
	font-size: small;
`;

class ColibriKeyboardHeader extends Component {
	sendResetCommand() {
		this.props.onCommandClick('reset');
	}

	sendProgramming() {
		this.props.onButtonClick('100', false)();
	}

	sendWashingCycle() {
		this.props.onButtonClick('101', false)();
	}

	render() {
		return (
			<>
				<Row className="machine-lcd-40">
					<Col
						xs={{ size: 6, offset: 0 }}
						md={{ size: 6, offset: 0 }}
						lg={{ size: 6, offset: 0 }}
						xl={{ size: 6, offset: 0 }}
						className="pl-3"
					>
						<GreenMachineButton onClick={() => this.sendProgramming()}>
							<i className="fa fa-mobile  mr-1 fa-lg" /> | Programming
						</GreenMachineButton>
					</Col>
					<Col
						xs={{ size: 3, offset: 0 }}
						md={{ size: 3, offset: 0 }}
						lg={{ size: 3, offset: 0 }}
						xl={{ size: 3, offset: 0 }}
						className="px-1"
					>
						<GreenMachineButton onClick={() => this.sendWashingCycle()}>
							Washing Cycle
						</GreenMachineButton>
					</Col>
					<Col
						xs={{ size: 3, offset: 0 }}
						md={{ size: 3, offset: 0 }}
						lg={{ size: 3, offset: 0 }}
						xl={{ size: 3, offset: 0 }}
						className="pl-0 pr-4"
					>
						<GreenMachineButton onClick={() => this.sendResetCommand()}>
							Reset Electronics
						</GreenMachineButton>
					</Col>
				</Row>
			</>
		);
	}
}

export default ColibriKeyboardHeader;
