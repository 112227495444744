//@flow
import React from 'react';
import aes from 'crypto-js/aes';
import encb64 from 'crypto-js/enc-base64';
import enchex from 'crypto-js/enc-hex';
import encutf8 from 'crypto-js/enc-utf8';

export default class VMUtil {
	static getButtonLabel = (
		button: Object,
		products: Array<Object> = [],
		defaultValue?: any
	) => {
		const productID = button.buttonID;
		const slavePattern =
			button.slavePattern === undefined ? null : button.slavePattern;
		const product = products.find(
			product =>
				product.productID === productID && product.slavePattern === slavePattern
		);
		if (!product) {
			return defaultValue ? defaultValue : null;
		}

		return (
			<>
				{product.name}
				<br />
				{product.price} {product.currency}
			</>
		);
	};

	/*
		These functions are not part of the system main security, they are only here for preventing the user
		to cleary see their machine's id in the system. So they would not see an url like http://address/#/vms/10/detailer
		
		Important! The following functions are also available on the frontend!
  	*/
	static secretPhrase = 'QQ51QR7EMEH380R0B29PR2K5D38ZUJRH9CL80TBP';

	static encodeVMID = vmID => {
		let b64 = aes.encrypt(vmID.toString(), VMUtil.secretPhrase).toString();
		var e64 = encb64.parse(b64);
		var eHex = e64.toString(enchex);
		return eHex;
	};

	static decodeVMID = encrpyted => {
		let reb64 = enchex.parse(encrpyted);
		let bytes = reb64.toString(encb64);
		let decrypt = aes.decrypt(bytes, VMUtil.secretPhrase);
		let plain = decrypt.toString(encutf8);
		return plain;
	};

	static getMachineStateIdFromString = stateString => {
		switch (stateString.toLowerCase()) {
			case 'operational':
				return 0;
			case 'ok':
				return 0;
			case 'programming':
				return 1;
			case 'warning':
				return 2;
			case 'error':
				return 3;
			default:
				break;
		}
	};

	static getMachineStateStringFromID = stateID => {
		const machineStates = ['ok', 'programming', 'warning', 'error'];
		return stateID || stateID == 0 ? machineStates[stateID] : 'undefined';
	};

	static hex2a = hexx => {
		var hex = hexx.toString(); //force conversion
		var str = '';
		for (var i = 0; i < hex.length; i += 2) {
			// Replace the some characters, given by zsolt
			const currentCharacterValue = parseInt(hex.substr(i, 2), 16);
			switch (currentCharacterValue) {
				case 0x1c:
					str += '●';
					break;
				case 0x1d:
					str += '○';
					break;
				default:
					str += String.fromCharCode(currentCharacterValue);
					break;
			}
		}
		return str;
	};
}
