import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import clientApi from '../../../services/api/client';

class EditCostumerDialog extends Component {
	state = {
		editedCustomer: {
			accountNumber: '',
			companyName: '',
			contactEmail: '',
			contactPerson: '',
			contactPhone: '',
			address: '',
			taxNuber: ''
		},
		validated: false
	};

	componentDidMount() {
		const customer = { ...this.props.customer };
		this.setState({ editedCustomer: customer });
	}

	inputChangeHandler = event => {
		let editedCustomer = { ...this.state.editedCustomer };
		editedCustomer[event.target.name] = event.target.value;
		this.setState({ editedCustomer: editedCustomer });
	};

	onSubmitHandler = () => {
		const requestBody = {
			id: this.props.customer.id,
			companyName: this.state.editedCustomer.companyName,
			address: this.state.editedCustomer.address,
			taxNumber: this.state.editedCustomer.taxNumber,
			contactPerson: this.state.editedCustomer.contactPerson,
			contactEmail: this.state.editedCustomer.contactEmail,
			contactPhone: this.state.editedCustomer.contactPhone
		};
		clientApi
			.updateClient(requestBody)
			.then(res => this.props.onHide())
			.catch(e => console.log(e));
	};
	validationHandler = () => {
		const form = document.getElementById('editCostumerForm');
		if (form.checkValidity() === true) {
			this.onSubmitHandler();
		} else {
			this.setState({ validated: true });
		}
	};

	render() {
		return (
			<Form noValidate validated={this.state.validated} id={'editCostumerForm'}>
				<Form.Group controlId="companyName">
					<Form.Label>
						{this.props.t('customerlist.basicdata.label.companyname')}
					</Form.Label>
					<Form.Control
						required
						name="companyName"
						type="text"
						value={this.state.editedCustomer.companyName}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="location">
					<Form.Label>
						{this.props.t('customerlist.basicdata.label.location')}
					</Form.Label>
					<Form.Control
						required
						name="address"
						type="text"
						value={this.state.editedCustomer.address}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="taxNumber">
					<Form.Label>
						{this.props.t('customerlist.basicdata.label.taxnumber')}
					</Form.Label>
					<Form.Control
						required
						name="taxNumber"
						type="text"
						value={this.state.editedCustomer.taxNumber}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="contactPerson">
					<Form.Label>
						{this.props.t('customerlist.contact.label.contactperson')}
					</Form.Label>
					<Form.Control
						required
						name="contactPerson"
						type="text"
						value={this.state.editedCustomer.contactPerson}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="contactEmail">
					<Form.Label>
						{this.props.t('customerlist.contact.label.email')}
					</Form.Label>
					<Form.Control
						required
						name="contactEmail"
						type="email"
						value={this.state.editedCustomer.contactEmail}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="contactPhone">
					<Form.Label>
						{this.props.t('customerlist.contact.label.phone')}
					</Form.Label>
					<Form.Control
						required
						name="contactPhone"
						type="text"
						value={this.state.editedCustomer.contactPhone}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Button
					variant="success"
					className="DefaultButton"
					onClick={() => this.validationHandler()}
				>
					<i className="fa fa-floppy-o mr-2" />
					&nbsp;
					{this.props.t('customerlist.addbutton.savebutton')}
				</Button>
			</Form>
		);
	}
}

export default withTranslation()(EditCostumerDialog);
