//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import AlphanumericMachineKeyboard from '../AlphanumericMachine/AlphanumericMachineKeyboard';
import AlphanumericMachineBaseComponent from '../AlphanumericMachine/AlphanumericMachineBaseComponent';

type Props = {
	vmID: string,
	history: Object,
	products?: Array<Object>,
	isSlave: ?boolean
};
type States = {};

class AstroAlphaNumeric extends React.Component<Props, States> {
	keyboardConfig = [
		[
			{
				buttonID: '1',
				buttonContent: '1'
			},
			{
				buttonID: '2',
				buttonContent: '2'
			},
			{
				buttonID: '3',
				buttonContent: '3'
			}
		],
		[
			{
				buttonID: '4',
				buttonContent: '4'
			},
			{
				buttonID: '5',
				buttonContent: '5'
			},
			{
				buttonID: '6',
				buttonContent: '6'
			}
		],
		[
			{
				buttonID: '7',
				buttonContent: '7'
			},
			{
				buttonID: '8',
				buttonContent: '8'
			},
			{
				buttonID: '9',
				buttonContent: '9'
			}
		],
		[
			{
				buttonID: '10',
				buttonContent: '0'
			},
			{
				buttonID: '11',
				buttonContent: 'C'
			},
			{
				buttonID: '12',
				buttonContent: 'E'
			}
		],
		[
			{
				buttonID: '100',
				buttonContent: 'Prog'
			},
			{
				buttonID: '101',
				buttonContent: '+'
			},
			{
				buttonID: '102',
				buttonContent: '-'
			}
		]
	];
	render() {
		return (
			<AlphanumericMachineBaseComponent
				lineNumber={2}
				lineLength={20}
				machineTitle="AstroAlphaNumeric"
				keyboard={
					<AlphanumericMachineKeyboard
						keyboardConfig={this.keyboardConfig}
						{...this.props}
					/>
				}
				{...this.props}
			/>
		);
	}
}

export default withRouter(AstroAlphaNumeric);
