function getRoleTextId(roleId) {
	const roles = {
		class1: 'users.role.root',
		class2: 'users.role.company_admin',
		class3: 'users.role.technician',
		class4: 'users.role.regional_admin',
		class5: 'users.role.content_manager',
		class6: 'users.role.hvend_technican',
		class7: 'users.role.mobile_user'
	};
	return roles[roleId];
}

export default getRoleTextId;
