import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Col, CardTitle, Row, Button } from 'reactstrap';
import statisticsAPI from '../../services/api/statistics';
import Spinner from '../../components/UI/Spinner/Spinner';
import ProductChart from './ProductChart';
import classes from '../CashFlow/IncomeChart.module.css';
import PeriodChartFilterPanel from '../CashFlow/PeriodChartFiltePanel';
import machineGroupApi from '../../services/api/machineGroup';
import machineApi from '../../services/api/machines';
import customerApi from '../../services/api/customers';
import { connect } from 'react-redux';

const chart = {
	DAILY_SALES: 'DAILY_SALES',
	MONTHLY_SALES: 'MONTHLY_SALES',
	YEARLY_SALES: 'YEARLY_SALES',
	PERIOD_SALES: 'PERIOD_SALES'
};

class NewSalesList extends Component {
	state = {
		allVendingMachines: [],
		selectedVendingMachines: [],
		fromDate: new Date(),
		toDate: new Date(),
		loading: true,
		optionMachine: null,
		optionGroup: null,
		period: []
	};

	componentDidMount() {
		Promise.all([
			this.loadMachineGroups(),
			this.loadMachines(),
			this.loadCurrecySymbol()
		]).then(val => {
			this.setMachineData(val, () => {
				Promise.all([
					this.loadDailyData(),
					this.loadMonthlyData(),
					this.loadYearlyData()
				]).then(values => {
					this.setData(values);
				});
			});
		});
	}

	loadDailyData = () => {
		let d = new Date();
		let date = d.getDate();
		let month = d.getMonth();
		let year = d.getFullYear();

		let param = {
			machineIDList: this.state.machineIDList,
			fromDate: new Date(year, month, date, '0', '0'),
			toDate: new Date(year, month, date, '23', '59')
		};

		return statisticsAPI.getProductIncomePercents(
			param.machineIDList,
			param.fromDate,
			param.toDate
		);
	};
	loadMonthlyData = () => {
		let d = new Date();
		let month = d.getMonth();
		let year = d.getFullYear();

		let param = {
			machineIDList: this.state.machineIDList,
			fromDate: new Date(year, month, '01'),
			toDate: new Date(year, month, '30')
		};

		return statisticsAPI.getProductIncomePercents(
			param.machineIDList,
			param.fromDate,
			param.toDate
		);
	};
	loadYearlyData = () => {
		let d = new Date();
		let year = d.getFullYear();

		let param = {
			machineIDList: this.state.machineIDList,
			fromDate: new Date(year, '0', '01'),
			toDate: new Date(year, '11', '31')
		};

		return statisticsAPI.getProductIncomePercents(
			param.machineIDList,
			param.fromDate,
			param.toDate
		);
	};
	loadMachineGroups = () => {
		return machineGroupApi.getMachineGroups();
	};
	loadMachines = () => {
		return machineApi.getVisibleVendingMachines();
	};
	loadCurrecySymbol = () => {
		return customerApi.getCustomerCurrencySymbol(this.props.user.cmID);
	};
	setMachineData = (values, promise) => {
		let machineGroups = values[0] ? values[0].data.groups : [];
		let machines = values[1]
			? values[1].data.response.filter(e => e.telemetryServiceEnabled === 'Y')
			: [];
		let machineIDList = machines.map(machine => machine.vmID);
		let currencySymbol = values[2];

		this.setState(
			{
				machineIDList: machineIDList,
				machineGroups: machineGroups,
				machines: machines,
				currencySymbol: currencySymbol
			},
			() => promise()
		);
	};
	setPeriodData = (income, state) => {
		if (state.salesSelectedMachines.assignedVMID) {
			return this.loadPeriodData(
				income,
				state.salesSelectedMachines.assignedVMID,
				state
			);
		}
		if (state.salesSelectedMachines.assignedGroupID) {
			return machineGroupApi
				.getAssignedVms(state.salesSelectedMachines.assignedGroupID)
				.then(r => {
					let v = r.data.vms
						.filter(e => e.telemetryServiceEnabled === 'Y')
						.map(e => e.vmID);

					this.setState({ groupMachines: v }, () =>
						this.loadPeriodData(income, this.state.groupMachines.join(), state)
					);
				});
		}
		return this.loadPeriodData(income, null, state);
	};

	loadPeriodData = (income, m, state) => {
		let machinesIDString = null;
		let selected = [];
		selected.push(state.salesSelectedMachines);
		if (selected[0].assignedGroupID) {
			machineGroupApi.getAssignedVms(selected[0].assignedGroupID).then(res => {
				machinesIDString = res.data.vms.map(e => {
					return e.vmID;
				});
			});
		}
		if (selected[0].assignedVMID) {
			machinesIDString = [parseInt(selected[0].assignedVMID)];
		}
		let param = {
			machineIDList: machinesIDString
				? machinesIDString
				: this.state.machineIDList,
			fromDate: state.salesStartDate,
			toDate: state.salesEndDate
		};
		statisticsAPI
			.getProductIncomePercents(
				param.machineIDList,
				param.fromDate,
				param.toDate
			)
			.then(res => {
				this.setState({ period: this.formatData(res.data.series) });
			});
	};
	setData = values => {
		let optionMachine = this.state.machines.map(e => ({
			key: e.vmID,
			value: e.vmID,
			label: e.customName
		}));
		let optionGroup = this.state.machineGroups.map(e => ({
			key: e.id,
			value: e.id,
			label: e.name
		}));
		this.setState({
			chartType: chart.DAILY_SALES,
			daily: this.formatData(values[0].data.series),
			monthly: this.formatData(values[1].data.series),
			yearly: this.formatData(values[2].data.series),
			optionMachine: optionMachine,
			optionGroup: optionGroup,
			loading: false
		});
	};
	formatData = value => {
		let data = [];
		if (value && value[0]) {
			data = value[0].xValues.map((e, idx) => {
				let y;
				value[0].yValues.forEach((el, i) => {
					if (idx === i) y = el;
				});
				return { date: e, value: y };
			});
		}
		return data;
	};
	setDaily = value => {
		return value.slice(0, 10);
	};
	setMonthly = value => {
		return value.slice(10, 20);
	};
	setYearly = value => {
		return value.slice(20, 30);
	};

	//--------------------------------------------
	chartType = data => {
		const { t } = this.props;
		let chartType = <Spinner />;
		if (this.state.daily && this.state.monthly && this.state.yearly) {
			switch (this.state.chartType) {
				case chart.DAILY_SALES:
					chartType = (
						<>
							<ProductChart
								currencySymbol={this.state.currencySymbol}
								data={this.state.daily}
								title={t('productSales.chartbyTypes.title.daily')}
							/>
						</>
					);
					break;
				case chart.MONTHLY_SALES:
					chartType = (
						<>
							<ProductChart
								currencySymbol={this.state.currencySymbol}
								data={this.state.monthly}
								title={t('productSales.chartbyTypes.title.monthly')}
							/>
						</>
					);
					break;
				case chart.YEARLY_SALES:
					chartType = (
						<>
							<ProductChart
								currencySymbol={this.state.currencySymbol}
								data={this.state.yearly}
								title={t('productSales.chartbyTypes.title.yearly')}
							/>
						</>
					);
					break;
				case chart.PERIOD_SALES:
					chartType = (
						<>
							{this.state.optionMachine && this.state.optionGroup ? (
								<PeriodChartFilterPanel
									optionMachine={this.state.optionMachine}
									optionGroup={this.state.optionGroup}
									onFilter={this.setPeriodData}
									product
								/>
							) : null}

							<ProductChart
								data={this.state.period}
								currencySymbol={this.state.currencySymbol}
								title={t('productSales.chartbyTypes.title.period')}
							/>
						</>
					);
					break;
			}
		}

		return chartType;
	};

	chartChangeHandler = chart => {
		this.setState({ chartType: chart });
	};

	typePanel = () => {
		const { t } = this.props;
		const { chartType } = this.state;
		return (
			<Row className={classes.ButtonGroup}>
				<Button
					className={
						chart.DAILY_SALES === chartType
							? classes.ChartButtonActive
							: classes.ChartButton
					}
					onClick={() => this.chartChangeHandler(chart.DAILY_SALES)}
				>
					<i className="fa fa-line-chart mr-2"></i>
					{t('productSales.chartbyTypes.button.daily')}
				</Button>
				<Button
					className={
						chart.MONTHLY_SALES === chartType
							? classes.ChartButtonActive
							: classes.ChartButton
					}
					onClick={() => this.chartChangeHandler(chart.MONTHLY_SALES)}
				>
					<i className="fa fa-line-chart mr-2"></i>
					{t('productSales.chartbyTypes.button.monthly')}
				</Button>
				<Button
					className={
						chart.YEARLY_SALES === chartType
							? classes.ChartButtonActive
							: classes.ChartButton
					}
					onClick={() => this.chartChangeHandler(chart.YEARLY_SALES)}
				>
					<i className="fa fa-line-chart mr-2"></i>
					{t('productSales.chartbyTypes.button.yearly')}
				</Button>
				<Button
					className={
						chart.PERIOD_SALES === chartType
							? classes.ChartButtonActive
							: classes.ChartButton
					}
					onClick={() => this.chartChangeHandler(chart.PERIOD_SALES)}
				>
					<i className="fa fa-line-chart mr-2"></i>
					{t('productSales.chartbyTypes.button.period')}
				</Button>
			</Row>
		);
	};
	chart = () => {
		const { t } = this.props;

		return (
			<Card body className={this.props.className} style={this.props.style}>
				<Col>
					<CardTitle className="mb-0 text-muted">
						<i
							className="fa fa-line-chart fa-lg"
							style={{ marginRight: '10px' }}
						></i>
						{t('productSales.chartbyTypes.cardtitle')}
					</CardTitle>
					<hr></hr>
				</Col>
				{this.typePanel()}
				{this.chartType(this.state.data)}
			</Card>
		);
	};
	render() {
		const { t } = this.props;
		return this.chart();
	}
}
const mapStateToProps = state => ({
	user: state.user
});
export default connect(mapStateToProps)(withTranslation()(NewSalesList));
