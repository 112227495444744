//@flow
import React from 'react'

import { Col, Row } from 'reactstrap'

import { MachineButton as BaseButton } from '../../../../components/Machines/MachineComponents'
import styled from 'styled-components'
const MachineButton = styled(BaseButton)`
  font-size: small;
`
const ButtonLed = styled.i`
  color: ${props => (props.isLedOn ? 'lime' : 'black')};
`

const LuceX2KeyboardHeader = ({
  onButtonClick = (key: string) => async () => {},
}: {
  onButtonClick?: (key: string) => () => Promise<void>,
}) => {
  return (
    <Row>
      <Col xs="3" sm="3" md="2" xl="2">
        <MachineButton onCLick={onButtonClick('5')}>Sugar - </MachineButton>
      </Col>
      <Col xs="6" sm="6" md="8" xl="8">
        <Row>
          <Col
            xs={{ size: 2, offset: 1 }}
            md={{ size: 2, offset: 1 }}
            lg={{ size: 2, offset: 1 }}
            xl={{ size: 2, offset: 1 }}
          >
            <ButtonLed isLedOn={true} className="fa fa-circle" />
          </Col>
          <Col
            xs={{ size: 2 }}
            md={{ size: 2 }}
            lg={{ size: 2 }}
            xl={{ size: 2 }}
          >
            <ButtonLed isLedOn={true} className="fa fa-circle" />
          </Col>
          <Col
            xs={{ size: 2 }}
            md={{ size: 2 }}
            lg={{ size: 2 }}
            xl={{ size: 2 }}
          >
            <ButtonLed isLedOn={true} className="fa fa-circle" />
          </Col>
          <Col
            xs={{ size: 2 }}
            md={{ size: 2 }}
            lg={{ size: 2 }}
            xl={{ size: 2 }}
          >
            <ButtonLed isLedOn={false} className="fa fa-circle" />
          </Col>
          <Col
            xs={{ size: 2 }}
            md={{ size: 2 }}
            lg={{ size: 2 }}
            xl={{ size: 2 }}
          >
            <ButtonLed isLedOn={false} className="fa fa-circle" />
          </Col>
        </Row>
      </Col>

      <Col xs="3" sm="3" md="2" xl="2">
        <MachineButton onCLick={onButtonClick('4')}>Sugar + </MachineButton>
      </Col>
    </Row>
  )
}

export default LuceX2KeyboardHeader
