import React, { Component } from 'react';
import { NavItem, Button, Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class LogoutButton extends Component {
	handleLogoutClick() {
		this.props.history.replace('/Logout');
	}

	render() {
		return (
			<NavItem>
				<Button
					outline={false}
					className={this.props.className}
					key="toggle"
					color="transparent"
					onClick={() => this.handleLogoutClick()}
				>
					<i className="icon-power mr-2" />

					<span>{this.props.t('users.dropDown.logout')}</span>
				</Button>
			</NavItem>
		);
	}
}

const mapStateToProps = state => ({
	usePrivilege: state.user
});

export default withRouter(
	connect(mapStateToProps)(withTranslation()(LogoutButton))
);
