export default {
	users: [
		{
			cID: 'hvendservicekft',
			connCountry: null,
			connPhone: '000356603736',
			connPostCode: null,
			debugMenu: '0',
			deletedAt: null,
			editVM: '0',
			email: 'jakuszoli83@gmail.com',
			firstName: 'Zoltan',
			insertedAt: '2020-07-10T06:06:27.000Z',
			languageCode: 'hu',
			lastName: 'Jakus',
			payAddress: null,
			payCountry: null,
			payName: null,
			payPostCode: null,
			payTaxNum: null,
			phoneVaidator: null,
			privilege: 'class2',
			regMenu: '0',
			ID: 296,
			updatedAt: null,
			userMenu: '0',
			viewLCD: '0',
			vmsMenu: '0',
			web: null
		}
	],

	costumers: [
		{
			ID: 1,
			accountNumber: '234543624576',
			companyName: 'Test Company 1 Kft',
			contactEmail: 'gyula@email.cim',
			contactPerson: 'Teszt Gyula',
			contactPhone: '324561346',
			location: '19, teszt utca teszt város teszt ország 4567',
			taxNumber: '346342547'
		},
		{
			ID: 2,
			accountNumber: '234543624576',
			companyName: 'Test Company 1 Kft',
			contactEmail: 'gyula@email.cim',
			contactPerson: 'Teszt Gyula',
			contactPhone: '324561346',
			location: '19, teszt utca teszt város teszt ország 4567',
			taxNumber: '346342547'
		}
	],

	campaning: [
		{
			id: 1,
			campaningadat1: 'campdata1',
			campaningadat2: 'campdata2',
			campaningadat3: 'campdata3',
			campaningadat4: 'campata4',
			campaningadat5: 'campata5',
			campaningadat6: 'campdata6',
			campaningadat7: 'campdata7'
		},
		{
			id: 2,
			campaningadat1: 'campdata1',
			campaningadat2: 'campdata2',
			campaningadat3: 'campdata3',
			campaningadat4: 'campata4',
			campaningadat5: 'campata5',
			campaningadat6: 'campdata6',
			campaningadat7: 'campdata7'
		}
	],
	machines: [
		{
			id: 1,
			name: 'KIKO1',
			location: 'Vasútállomás',
			idnum: 1231
		},
		{
			id: 2,
			name: 'KIKO2',
			location: 'Vasútállomás',
			idnum: 12312
		},
		{
			id: 3,
			name: 'KIKO3',
			location: 'Sportcsarnok',
			idnum: 123123
		},
		{
			id: 4,
			name: 'KIKO4',
			location: 'Sportcsarnok',
			idnum: 1231234
		},
		{
			id: 5,
			name: 'KIKO5',
			location: 'Egyéb',
			idnum: 12312345
		},
		{
			id: 6,
			name: 'ASZTRO1',
			location: 'Vasútállomás',
			idnum: 123123456
		},
		{
			id: 7,
			name: 'ASZTRO2',
			location: 'Vasútállomás',
			idum: 1231234567
		},
		{
			id: 8,
			name: 'ASZTRO3',
			location: 'Sportcsarnok',
			idnum: 12312345678
		},
		{
			id: 9,
			name: 'ASZTRO4',
			location: 'Egyéb',
			idnum: 123123456789
		},
		{
			id: 10,
			name: 'ASZTRO5',
			location: 'Egyéb',
			idnum: 12312345678910
		},
		{
			id: 11,
			name: 'COLIBRI1',
			location: 'Sportcsarnok',
			idnum: 12312345678911
		},
		{
			id: 12,
			name: 'COLIBRI22',
			location: 'Vasútállomás',
			idnum: 12312345678912
		}
	],
	eventTypeSeriesSettings: [
		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: '#FFAA42',
			borderWidth: 2,
			label: 'Kategória nélküli esemény',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'rectRounded',
			text: 'Kategória nélküli esemény. Üzenet: ${this.m}',
			typeId: 0
		},

		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'red',
			borderWidth: 2,
			label: 'Futásidejű hiba',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'rectRounded',
			text: 'Runtime Error. Információ: ${this.m}',
			typeId: 1
		},

		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: '#54D7FF',
			borderWidth: 2,
			label: 'A gép elindult',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'rectRounded',
			text:
				'Egy gép elindult a következő névvel: ${this.mn} A következő hardverrel: ${this.hID}',
			typeId: 2
		},
		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'red',
			borderWidth: 2,
			label: 'Gép meghibásodott',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'triangle',
			text: 'A gép meghibásodott.',
			typeId: 3
		},

		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'red',
			borderWidth: 2,
			label: 'Sikertelen termék kiszolgálás',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'triangle',
			text: 'Következő termék kiszolgálása sikertelen: ${this.p}',
			typeId: 4
		},

		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'red',
			borderWidth: 2,
			label: 'A termék készlete elfogyott',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'triangle',
			text: 'The following position run out of stock: ${this.p}',
			typeId: 5
		},
		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'red',
			borderWidth: 2,
			label: 'Készlet figyelmeztetés',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'triangle',
			text: 'A ${this.p} pozíció ${this.pe} százalék alatt van (${this.of})',
			typeId: 6
		},

		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'blue',
			borderWidth: 2,
			label: 'Termék le lett tiltva',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'triangle',
			text:
				'Egy vagy több termék le lett tiltva.  ${Object.values(this).map(p :> p.p).toString()}',
			typeId: 7
		},

		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'blue',
			borderWidth: 2,
			label: 'A Gép fűt',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'rectRounded',
			text: 'A gép fűt.',
			typeId: 8
		},

		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'red',
			borderWidth: 2,
			label: 'A csészék elfogytak',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'triangle',
			text: 'A csészék elfogytak',
			typeId: 9
		},

		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'red',
			borderWidth: 2,
			label: 'A kis csészék elfogytak.',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'triangle',
			text: 'A kis csészék elfogytak.',
			typeId: 10
		},

		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'red',
			borderWidth: 2,
			label: 'Az espresso elfogyott.',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'triangle',
			text: 'Az espresso elfogyott.',
			typeId: 11
		},

		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'red',
			borderWidth: 2,
			label: 'Az apró elfogyott.',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'triangle',
			text: 'Az apró elfogyott.',
			typeId: 12
		},

		{
			backgroundColor: 'rgba(0,0,216,0.1)',
			borderColor: 'yellow',
			borderWidth: 2,
			label: 'A gépet karbantartották.',
			pointHoverBackgroundColor: '#fff',
			pointRadius: 10,
			pointStyle: 'rectRounded',
			text: 'A gépet karbantartották.',
			typeId: 13
		}
	],
	filteredData: [
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 0,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: 'The cake is a lie'
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 1,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: 'Some kind of error'
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 2,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: 'Gyula'
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 3,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: 'some hiba'
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: 'Gyula'
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 4,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'p',
					parameterValue: '5'
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 5,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'p',
					parameterValue: '5'
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 6,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'p',
					parameterValue: '5'
				},

				{
					parameterName: 'pe',
					parameterValue: '5'
				},

				{
					parameterName: 'of',
					parameterValue: '5'
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 7,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 8,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 9,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 10,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 11,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 12,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 13,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 0,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 1,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 2,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 3,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 4,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 5,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 6,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 7,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 8,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 9,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 10,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 11,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 12,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		},
		{
			date: '2021-03-15T06:45:11.000Z',
			eventType: 13,
			hardwareId: '69040102',
			i: 0,
			machineId: 13,
			machineName: 'Szeged bemutató terem',
			params: [
				{
					parameterName: 'm',
					parameterValue: ''
				},

				{
					parameterName: 'ak',
					parameterValue: ''
				},

				{
					parameterName: 'c',
					parameterValue: ''
				},

				{
					parameterName: 'hID',
					parameterValue: '69040102'
				},

				{
					parameterName: 'mn',
					parameterValue: ''
				}
			]
		}
	],
	purchasers: [
		{
			id: 1,
			account: 'gyula@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '1',
			purchases: [
				{
					id: 1,
					account: 'asdf1234',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 2,
					account: 'asdf1235',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Failed'
				},
				{
					id: 3,
					account: 'asdf1236',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Failed'
				},
				{
					id: 4,
					account: 'asdf1237',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Refunded'
				},
				{
					id: 5,
					account: 'asdf1238',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Reported'
				},
				{
					id: 6,
					account: 'asdf1239',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Refunded'
				},
				{
					id: 7,
					account: 'asdf12310',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Reported'
				},
				{
					id: 8,
					account: 'asdf12311',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 9,
					account: 'asdf12312',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Reported'
				}
			],
			balance: '13200',
			invoice: [
				{
					id: 1,
					account: 'asdf1234',
					state: 'Requested'
				},
				{
					id: 2,
					account: 'asdf1235',
					state: 'Approval'
				},
				{
					id: 3,
					account: 'asdf1236',
					state: 'Requested'
				},
				{
					id: 4,
					account: 'asdf1237',
					state: 'Requested'
				},
				{
					id: 5,
					account: 'asdf1238',
					state: 'Approval'
				}
			],
			massage: [
				{
					id: 1,
					subject: 'test',
					massage: 'Teszt üzenet!',
					date: '2020-05-10'
				},
				{
					id: 2,
					subject: 'test test',
					massage: 'Teszt üzenet! Teszt üzenet!',
					date: '2020-05-11'
				},
				{
					id: 3,
					subject: 'test subject',
					massage: 'Teszt üzenet! Teszt massage! Teszt massage!',
					date: '2020-05-12'
				},
				{
					id: 4,
					subject: 'NAGYONHOSSZÚTÁRGYÜZENET HOGY VÉLETLENÜLSE FÉRJEN KI',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				},
				{
					id: 5,
					subject: 'test',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				},
				{
					id: 6,
					subject: 'test',
					massage: 'Teszt üzenet!',
					date: '2020-05-10'
				},
				{
					id: 7,
					subject: 'test test',
					massage: 'Teszt üzenet! Teszt üzenet!',
					date: '2020-05-11'
				},
				{
					id: 8,
					subject: 'test subject',
					massage: 'Teszt üzenet! Teszt massage! Teszt massage!',
					date: '2020-05-12'
				},
				{
					id: 9,
					subject: 'NAGYONHOSSZÚTÁRGYÜZENET HOGY VÉLETLENÜLSE FÉRJEN KI',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				},
				{
					id: 10,
					subject: 'test',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				}
			]
		},
		{
			id: 2,
			account: 'lajos@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '2',
			purchases: [
				{
					id: 1,
					account: 'asdf1234',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 2,
					account: 'asdf1235',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Succes'
				},
				{
					id: 3,
					account: 'asdf1236',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Failed'
				},
				{
					id: 4,
					account: 'asdf1237',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Succes'
				},
				{
					id: 5,
					account: 'asdf1238',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Failed'
				},
				{
					id: 6,
					account: 'asdf1239',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Succes'
				},
				{
					id: 7,
					account: 'asdf12310',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Reported'
				},
				{
					id: 8,
					account: 'asdf12311',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 9,
					account: 'asdf12312',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Reported'
				}
			],
			balance: '3200',
			invoice: [
				{
					id: 1,
					account: 'asdf1234',
					state: 'Requested'
				},
				{
					id: 2,
					account: 'asdf1235',
					state: 'Approval'
				},
				{
					id: 3,
					account: 'asdf1236',
					state: 'Requested'
				},
				{
					id: 4,
					account: 'asdf1237',
					state: 'Requested'
				},
				{
					id: 5,
					account: 'asdf1238',
					state: 'Approval'
				}
			],
			massage: [
				{
					id: 1,
					subject: 'test',
					massage: 'Teszt üzenet!',
					date: '2020-05-10'
				},
				{
					id: 2,
					subject: 'test test',
					massage: 'Teszt üzenet! Teszt üzenet!',
					date: '2020-05-11'
				},
				{
					id: 3,
					subject: 'test subject',
					massage: 'Teszt üzenet! Teszt massage! Teszt massage!',
					date: '2020-05-12'
				},
				{
					id: 4,
					subject: 'NAGYONHOSSZÚTÁRGYÜZENET HOGY VÉLETLENÜLSE FÉRJEN KI',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				},
				{
					id: 5,
					subject: 'test',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				}
			]
		},

		{
			id: 3,
			account: 'jozsef@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '3',
			purchases: [
				{
					id: 1,
					account: 'asdf1234',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 2,
					account: 'asdf1235',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Succes'
				},
				{
					id: 3,
					account: 'asdf1236',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Failed'
				},
				{
					id: 4,
					account: 'asdf1237',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Succes'
				},
				{
					id: 5,
					account: 'asdf1238',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Failed'
				},
				{
					id: 6,
					account: 'asdf1239',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Succes'
				},
				{
					id: 7,
					account: 'asdf12310',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Reported'
				},
				{
					id: 8,
					account: 'asdf12311',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 9,
					account: 'asdf12312',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Reported'
				}
			],
			balance: '100',
			invoice: [
				{
					id: 1,
					account: 'asdf1234',
					state: 'Requested'
				},
				{
					id: 2,
					account: 'asdf1235',
					state: 'Approval'
				},
				{
					id: 3,
					account: 'asdf1236',
					state: 'Requested'
				},
				{
					id: 4,
					account: 'asdf1237',
					state: 'Requested'
				},
				{
					id: 5,
					account: 'asdf1238',
					state: 'Approval'
				}
			],
			massage: [
				{
					id: 1,
					subject: 'test',
					massage: 'Teszt üzenet!',
					date: '2020-05-10'
				},
				{
					id: 2,
					subject: 'test test',
					massage: 'Teszt üzenet! Teszt üzenet!',
					date: '2020-05-11'
				},
				{
					id: 3,
					subject: 'test subject',
					massage: 'Teszt üzenet! Teszt massage! Teszt massage!',
					date: '2020-05-12'
				},
				{
					id: 4,
					subject: 'NAGYONHOSSZÚTÁRGYÜZENET HOGY VÉLETLENÜLSE FÉRJEN KI',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				},
				{
					id: 5,
					subject: 'test',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				}
			]
		},

		{
			id: 4,
			account: 'peter@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '4',
			purchases: [
				{
					id: 1,
					account: 'asdf1234',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 2,
					account: 'asdf1235',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Succes'
				},
				{
					id: 3,
					account: 'asdf1236',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Failed'
				},
				{
					id: 4,
					account: 'asdf1237',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Succes'
				},
				{
					id: 5,
					account: 'asdf1238',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Failed'
				},
				{
					id: 6,
					account: 'asdf1239',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Succes'
				},
				{
					id: 7,
					account: 'asdf12310',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Reported'
				},
				{
					id: 8,
					account: 'asdf12311',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 9,
					account: 'asdf12312',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Reported'
				}
			],
			balance: '10123',
			invoice: [
				{
					id: 1,
					account: 'asdf1234',
					state: 'Requested'
				},
				{
					id: 2,
					account: 'asdf1235',
					state: 'Approval'
				},
				{
					id: 3,
					account: 'asdf1236',
					state: 'Requested'
				},
				{
					id: 4,
					account: 'asdf1237',
					state: 'Requested'
				},
				{
					id: 5,
					account: 'asdf1238',
					state: 'Approval'
				}
			],
			massage: [
				{
					id: 1,
					subject: 'test',
					massage: 'Teszt üzenet!',
					date: '2020-05-10'
				},
				{
					id: 2,
					subject: 'test test',
					massage: 'Teszt üzenet! Teszt üzenet!',
					date: '2020-05-11'
				},
				{
					id: 3,
					subject: 'test subject',
					massage: 'Teszt üzenet! Teszt massage! Teszt massage!',
					date: '2020-05-12'
				},
				{
					id: 4,
					subject: 'NAGYONHOSSZÚTÁRGYÜZENET HOGY VÉLETLENÜLSE FÉRJEN KI',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				},
				{
					id: 5,
					subject: 'test',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				}
			]
		},

		{
			id: 5,
			account: 'marton@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '5',
			purchases: [
				{
					id: 1,
					account: 'asdf1234',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 2,
					account: 'asdf1235',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Succes'
				},
				{
					id: 3,
					account: 'asdf1236',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Failed'
				},
				{
					id: 4,
					account: 'asdf1237',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Succes'
				},
				{
					id: 5,
					account: 'asdf1238',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Failed'
				},
				{
					id: 6,
					account: 'asdf1239',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Succes'
				},
				{
					id: 7,
					account: 'asdf12310',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Reported'
				},
				{
					id: 8,
					account: 'asdf12311',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 9,
					account: 'asdf12312',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Reported'
				}
			],
			balance: '5431',
			invoice: [
				{
					id: 1,
					account: 'asdf1234',
					state: 'Requested'
				},
				{
					id: 2,
					account: 'asdf1235',
					state: 'Approval'
				},
				{
					id: 3,
					account: 'asdf1236',
					state: 'Requested'
				},
				{
					id: 4,
					account: 'asdf1237',
					state: 'Requested'
				},
				{
					id: 5,
					account: 'asdf1238',
					state: 'Approval'
				}
			],
			massage: [
				{
					id: 1,
					subject: 'test',
					massage: 'Teszt üzenet!',
					date: '2020-05-10'
				},
				{
					id: 2,
					subject: 'test test',
					massage: 'Teszt üzenet! Teszt üzenet!',
					date: '2020-05-11'
				},
				{
					id: 3,
					subject: 'test subject',
					massage: 'Teszt üzenet! Teszt massage! Teszt massage!',
					date: '2020-05-12'
				},
				{
					id: 4,
					subject: 'NAGYONHOSSZÚTÁRGYÜZENET HOGY VÉLETLENÜLSE FÉRJEN KI',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				},
				{
					id: 5,
					subject: 'test',
					massage:
						'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
					date: '2020-05-10'
				}
			]
		},
		{
			id: 6,
			account: 'abel@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '6',
			purchases: [
				{
					id: 1,
					account: 'asdf1234',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 2,
					account: 'asdf1235',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Succes'
				},
				{
					id: 3,
					account: 'asdf1236',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Failed'
				},
				{
					id: 4,
					account: 'asdf1237',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Succes'
				},
				{
					id: 5,
					account: 'asdf1238',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Failed'
				},
				{
					id: 6,
					account: 'asdf1239',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Succes'
				},
				{
					id: 7,
					account: 'asdf12310',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Reported'
				},
				{
					id: 8,
					account: 'asdf12311',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 9,
					account: 'asdf12312',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Reported'
				}
			],
			balance: '6543',
			invoice: '1',
			massage: '1'
		},
		{
			id: 7,
			account: 'elemer@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '7',
			purchases: [
				{
					id: 1,
					account: 'asdf1234',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 2,
					account: 'asdf1235',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Succes'
				},
				{
					id: 3,
					account: 'asdf1236',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Failed'
				},
				{
					id: 4,
					account: 'asdf1237',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Succes'
				},
				{
					id: 5,
					account: 'asdf1238',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Failed'
				},
				{
					id: 6,
					account: 'asdf1239',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Succes'
				},
				{
					id: 7,
					account: 'asdf12310',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Reported'
				},
				{
					id: 8,
					account: 'asdf12311',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 9,
					account: 'asdf12312',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Reported'
				}
			],
			balance: '1',
			invoice: '1',
			massage: '1'
		},

		{
			id: 8,
			account: 'test@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '8',
			purchases: [
				{
					id: 1,
					account: 'asdf1234',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 2,
					account: 'asdf1235',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Succes'
				},
				{
					id: 3,
					account: 'asdf1236',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Failed'
				},
				{
					id: 4,
					account: 'asdf1237',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Succes'
				},
				{
					id: 5,
					account: 'asdf1238',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Failed'
				},
				{
					id: 6,
					account: 'asdf1239',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Succes'
				},
				{
					id: 7,
					account: 'asdf12310',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Reported'
				},
				{
					id: 8,
					account: 'asdf12311',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 9,
					account: 'asdf12312',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Reported'
				}
			],
			balance: '1',
			invoice: '1',
			massage: '1'
		},

		{
			id: 9,
			account: 'test@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '9',
			purchases: [
				{
					id: 1,
					account: 'asdf1234',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 2,
					account: 'asdf1235',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Succes'
				},
				{
					id: 3,
					account: 'asdf1236',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Failed'
				},
				{
					id: 4,
					account: 'asdf1237',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Succes'
				},
				{
					id: 5,
					account: 'asdf1238',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Failed'
				},
				{
					id: 6,
					account: 'asdf1239',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Succes'
				},
				{
					id: 7,
					account: 'asdf12310',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Reported'
				},
				{
					id: 8,
					account: 'asdf12311',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 9,
					account: 'asdf12312',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Reported'
				}
			],
			balance: '1',
			invoice: '1',
			massage: '1'
		},

		{
			id: 10,
			account: 'test@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '10',
			purchases: [
				{
					id: 1,
					account: 'asdf1234',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 2,
					account: 'asdf1235',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Succes'
				},
				{
					id: 3,
					account: 'asdf1236',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Failed'
				},
				{
					id: 4,
					account: 'asdf1237',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Refunded'
				},
				{
					id: 5,
					account: 'asdf1238',
					date: '2020-05-10',
					device: 'Teszt Eszköz 2',
					state: 'Failed'
				},
				{
					id: 6,
					account: 'asdf1239',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Refunded'
				},
				{
					id: 7,
					account: 'asdf12310',
					date: '2020-05-10',
					device: 'Teszt Eszköz 5',
					state: 'Reported'
				},
				{
					id: 8,
					account: 'asdf12311',
					date: '2020-05-10',
					device: 'Teszt Eszköz 1',
					state: 'Succes'
				},
				{
					id: 9,
					account: 'asdf12312',
					date: '2020-05-10',
					device: 'Teszt Eszköz 3',
					state: 'Reported'
				}
			],
			balance: '1',
			invoice: '1',
			massage: '1'
		},
		{
			id: 11,
			account: 'test@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '11',
			purchases: '1',
			balance: '1',
			invoice: '1',
			massage: '1'
		},

		{
			id: 12,
			account: 'test@email.cim',
			device: 'KIKO - bemutatóterem',
			other: '12',
			purchases: '1',
			balance: '1',
			invoice: '1',
			massage: '1'
		}
	],

	transactions: [
		{
			id: 12340,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 1,
			paymentMethod: 1,
			price: 0.2,
			income: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12341,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Americano',
			transactionType: 1,
			paymentMethod: 2,
			price: 0.25,
			income: 0.25,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12342,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 2,
			paymentMethod: 3,
			price: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12343,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			transactionType: 3,
			paymentMethod: 1,
			income: 20.0,
			currency: 'EUR'
		},
		{
			id: 12344,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Americano',
			transactionType: 1,
			paymentMethod: 1,
			price: 0.25,
			income: 0.25,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12345,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 1,
			paymentMethod: 2,
			price: 0.2,
			income: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12346,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 2,
			paymentMethod: 3,
			price: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12347,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			transactionType: 3,
			paymentMethod: 2,
			income: 20.0,
			currency: 'EUR'
		},
		{
			id: 12348,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Americano',
			transactionType: 1,
			paymentMethod: 1,
			price: 0.25,
			income: 0.25,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12349,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 1,
			paymentMethod: 2,
			price: 0.2,
			income: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12350,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 2,
			paymentMethod: 3,
			price: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12351,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			transactionType: 3,
			paymentMethod: 1,
			income: 20.0,
			currency: 'EUR'
		},
		{
			id: 12352,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Americano',
			transactionType: 1,
			paymentMethod: 1,
			price: 0.25,
			income: 0.25,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12353,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 1,
			paymentMethod: 2,
			price: 0.2,
			income: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12354,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 2,
			paymentMethod: 3,
			price: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12356,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			transactionType: 3,
			paymentMethod: 2,
			income: 20.0,
			currency: 'EUR'
		},
		{
			id: 12357,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Americano',
			transactionType: 1,
			paymentMethod: 1,
			price: 0.25,
			income: 0.25,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12358,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 1,
			paymentMethod: 2,
			price: 0.2,
			income: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12359,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 2,
			paymentMethod: 3,
			price: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12360,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			transactionType: 3,
			paymentMethod: 2,
			income: 20.0,
			currency: 'EUR'
		},
		{
			id: 12361,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Americano',
			transactionType: 1,
			paymentMethod: 1,
			price: 0.25,
			income: 0.25,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12362,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 1,
			paymentMethod: 2,
			price: 0.2,
			income: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12363,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 2,
			paymentMethod: 3,
			price: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12364,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			transactionType: 3,
			paymentMethod: 1,
			income: 20.0,
			currency: 'EUR'
		},
		{
			id: 12365,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 1,
			paymentMethod: 1,
			price: 0.2,
			income: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12366,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 1,
			paymentMethod: 2,
			price: 0.2,
			income: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12367,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 2,
			paymentMethod: 3,
			price: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12368,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			transactionType: 3,
			paymentMethod: 2,
			income: 20.0,
			currency: 'EUR'
		},
		{
			id: 12369,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Americano',
			transactionType: 1,
			paymentMethod: 1,
			price: 0.25,
			income: 0.25,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12370,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 1,
			paymentMethod: 2,
			price: 0.2,
			income: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12371,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			name: 'Eszpresso',
			transactionType: 2,
			paymentMethod: 3,
			price: 0.2,
			currency: 'EUR',
			buttonId: 1
		},
		{
			id: 12372,
			createdAt: 'Thu May 27 2021 00:00:00 GMT+0200 (közép-európai nyári idő)',
			vmID: 'Szegedi bemutatóterm - KIKO',
			transactionType: 3,
			paymentMethod: 2,
			income: 20.0,
			currency: 'EUR'
		}
	],
	chartData: [
		{ date: 1, value: 193, value2: 80, value3: 20, value4: 80, value5: 13 },
		{ date: 2, value: 195, value2: 80, value3: 20, value4: 80, value5: 15 },
		{ date: 3, value: 298, value2: 150, value3: 50, value4: 80, value5: 18 },
		{ date: 4, value: 499, value2: 150, value3: 50, value4: 180, value5: 119 },
		{ date: 5, value: 599, value2: 200, value3: 100, value4: 180, value5: 119 },
		{ date: 6, value: 699, value2: 200, value3: 100, value4: 280, value5: 119 },
		{
			date: 7,
			value: 1100,
			value2: 300,
			value3: 200,
			value4: 380,
			value5: 320
		},
		{
			date: 8,
			value: 1100,
			value2: 300,
			value3: 200,
			value4: 380,
			value5: 320
		},
		{
			date: 9,
			value: 1100,
			value2: 250,
			value3: 250,
			value4: 380,
			value5: 320
		},
		{
			date: 10,
			value: 1303,
			value2: 300,
			value3: 300,
			value4: 400,
			value5: 303
		},
		{
			date: 11,
			value: 1305,
			value2: 250,
			value3: 350,
			value4: 400,
			value5: 305
		},
		{
			date: 12,
			value: 1406,
			value2: 350,
			value3: 300,
			value4: 450,
			value5: 306
		},
		{
			date: 13,
			value: 1407,
			value2: 250,
			value3: 400,
			value4: 450,
			value5: 307
		},
		{
			date: 14,
			value: 1508,
			value2: 400,
			value3: 300,
			value4: 500,
			value5: 308
		},
		{
			date: 15,
			value: 1709,
			value2: 500,
			value3: 300,
			value4: 550,
			value5: 359
		},
		{
			date: 16,
			value: 1707,
			value2: 300,
			value3: 500,
			value4: 550,
			value5: 357
		},
		{
			date: 17,
			value: 1409,
			value2: 450,
			value3: 200,
			value4: 450,
			value5: 309
		},
		{
			date: 18,
			value: 910,
			value2: 200,
			value3: 200,
			value4: 300,
			value5: 210
		},
		{
			date: 19,
			value: 1311,
			value2: 400,
			value3: 200,
			value4: 400,
			value5: 311
		},
		{
			date: 20,
			value: 1111,
			value2: 300,
			value3: 200,
			value4: 300,
			value5: 311
		},
		{ date: 21, value: 193, value2: 80, value3: 20, value4: 80, value5: 13 },
		{ date: 22, value: 195, value2: 70, value3: 30, value4: 80, value5: 15 },
		{ date: 23, value: 298, value2: 150, value3: 50, value4: 80, value5: 18 },
		{
			date: 24,
			value: 499,
			value2: 100,
			value3: 100,
			value4: 180,
			value5: 119
		},
		{
			date: 25,
			value: 599,
			value2: 150,
			value3: 150,
			value4: 180,
			value5: 119
		},
		{ date: 26, value: 193, value2: 90, value3: 10, value4: 80, value5: 13 },
		{ date: 27, value: 195, value2: 50, value3: 50, value4: 80, value5: 15 },
		{ date: 28, value: 298, value2: 130, value3: 70, value4: 80, value5: 18 },
		{
			date: 29,
			value: 499,
			value2: 100,
			value3: 100,
			value4: 180,
			value5: 119
		},
		{ date: 30, value: 599, value2: 120, value3: 180, value4: 180, value5: 119 }
	],

	// input: [
	// 	{ createdAt: '2020/07/13', purchaseCashIncome: 1, purchaseCashPrice: 1 },
	// 	{
	// 		createdAt: '2020/07/13',
	// 		purchaseCashlessIncome: 2,
	// 		purchaseCashlessPrice: 0
	// 	},
	// 	{ createdAt: '2020/07/13', purchaseCashIncome: 1, purchaseCashPrice: 1 },
	// 	{
	// 		createdAt: '2020/07/13',
	// 		purchaseCashlessIncome: 5,
	// 		purchaseCashlessPrice: 0
	// 	}
	// ],
	// output: [
	// 	{
	// 		createdAt: '2020/07/13',
	// 		purchaseCashlessIncome: 5,
	// 		purchaseCashlessPrice: 0,
	// 		purchaseCashIncome: 2,
	// 		purchaseCashPrice: 2
	// 	}
	// ]
	productChartData: [
		{ date: 'Bomba classic', value: 193 },
		{ date: 'Bomba mojito', value: 195 },
		{ date: 'Choco - choco extra', value: 298 },
		{ date: 'Chocolate', value: 499 },
		{ date: 'Coffee - Americno', value: 599 },
		{ date: 'Coffee - Cappucchino', value: 699 },
		{
			date:
				'Coffee - Coffee withe Ginsenggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg',
			value: 1100
		},
		{
			date: 'Coffee - Coffee withe Instant',
			value: 1100
		},
		{
			date: 'Coffee - Espresso',
			value: 1100
		},
		{
			date: 'Coffee - Espresso Instant',
			value: 1303
		},
		{
			date: 'Coffee - Espresso milk',
			value: 1305
		},
		{
			date: 'Coffee - irish',
			value: 1406
		},
		{
			date: 'Coffee - latte macchiato',
			value: 1407
		},
		{
			date: 'Energy Coffee Cappuchino',
			value: 1508
		},
		{
			date: 'Energy Coffee Double Espresso',
			value: 1709
		},
		{
			date: 'Energy Coffee Slim Latte',
			value: 1707
		},
		{
			date: 'Espresso',
			value: 1409
		},
		{
			date: 'Hell arcade tropical',
			value: 910
		},
		{
			date: 'Hell classic',
			value: 1311
		},
		{
			date: 'milk',
			value: 1111
		},
		{ date: 'Nestle aquarel bubi', value: 193 },
		{ date: 'Szentkirályi Still', value: 195 },
		{ date: 'Nestle aquarel', value: 298 },
		{
			date: 'Szentkirályi bubi',
			value: 499
		},
		{
			date: 'Coca-cola',
			value: 599
		},
		{ date: 'Fanta', value: 193 },
		{ date: 'Ginger', value: 195 },
		{ date: 'Szalámis Szendvics', value: 298 },
		{
			date: 'Sonkás Szendvics',
			value: 499
		},
		{ date: 'Vega Szendvics', value: 599 }
	],
	ingredients: [
		{ name: 'sugar', value: 3 },
		{ name: 'fat', value: 2 },
		{ name: 'Carbonhydrates', value: 6 },
		{ name: 'protein', value: 2 },
		{ name: 'water', value: 12 },
		{ name: 'coffein', value: 3 },
		{ name: 'milk', value: 4 },
		{ name: 'salt', value: 1 }
	],
	packages: [
		{
			id: 1,
			name: 'Package 1.',
			identifier: '2345432',
			createdAt: new Date(),
			layouts: [
				{
					id: 1,
					createdAt: new Date(),
					name: 'Layout 1',
					vmName: 'Kiko'
				},
				{
					id: 2,
					createdAt: new Date(),
					name: 'Layout 2',
					vmName: 'Kiko'
				},
				{
					id: 3,
					createdAt: new Date(),
					name: 'Layout 3',
					vmName: 'Kiko'
				},
				{
					id: 4,
					createdAt: new Date(),
					name: 'Layout 4',
					vmName: 'Kiko'
				}
			]
		},
		{
			id: 2,
			name: 'Package 2.',
			identifier: '2345467',
			createdAt: new Date(),
			layouts: [
				{
					id: 1,
					createdAt: new Date(),
					name: 'Layout 2.1',
					vmName: 'Kiko'
				},
				{
					id: 2,
					createdAt: new Date(),
					name: 'Layout 2.2',
					vmName: 'Kiko'
				},
				{
					id: 3,
					createdAt: new Date(),
					name: 'Layout 2.3',
					vmName: 'Kiko'
				},
				{
					id: 4,
					createdAt: new Date(),
					name: 'Layout 2.4',
					vmName: 'Kiko'
				}
			]
		},
		{
			id: 3,
			name: 'Package 3.1.',
			identifier: '2398723',
			createdAt: new Date(),
			layouts: [
				{
					id: 1,
					createdAt: new Date(),
					name: 'Layout 3.1',
					vmName: 'Kiko'
				},
				{
					id: 2,
					createdAt: new Date(),
					name: 'Layout 3.2',
					vmName: 'Kiko'
				},
				{
					id: 3,
					createdAt: new Date(),
					name: 'Layout 3.3',
					vmName: 'Kiko'
				},
				{
					id: 4,
					createdAt: new Date(),
					name: 'Layout 3.4',
					vmName: 'Kiko'
				}
			]
		},
		{
			id: 4,
			name: 'Package 4.',
			identifier: '2394453',
			createdAt: new Date(),
			layouts: [
				{
					id: 1,
					createdAt: new Date(),
					name: 'Layout 4.1',
					vmName: 'Kiko'
				},
				{
					id: 2,
					createdAt: new Date(),
					name: 'Layout 4.2',
					vmName: 'Kiko'
				},
				{
					id: 3,
					createdAt: new Date(),
					name: 'Layout 4.3',
					vmName: 'Kiko'
				},
				{
					id: 4,
					createdAt: new Date(),
					name: 'Layout 4.4',
					vmName: 'Kiko'
				}
			]
		},
		{
			id: 5,
			name: 'Package 5.',
			identifier: '2396666',
			createdAt: new Date(),
			layouts: [
				{
					id: 1,
					createdAt: new Date(),
					name: 'Layout 5.1',
					vmName: 'Kiko'
				},
				{
					id: 2,
					createdAt: new Date(),
					name: 'Layout 5.2',
					vmName: 'Kiko'
				},
				{
					id: 3,
					createdAt: new Date(),
					name: 'Layout 5.3',
					vmName: 'Kiko'
				},
				{
					id: 4,
					createdAt: new Date(),
					name: 'Layout 5.4',
					vmName: 'Kiko'
				}
			]
		}
	],
	language: [
		{
			id: 1,
			name: 'English',
			filename: 'flags/flag_en.png',
			language: 'en'
		},
		{
			id: 2,
			name: 'Deutsch',
			filename: 'flags/flag_de.png',
			language: 'de'
		},
		{
			id: 3,
			name: 'Italiano',
			filename: 'flags/flag_it.png',
			language: 'it'
		},
		{
			id: 4,
			name: 'Magyar',
			filename: 'flags/flag_en.png',
			language: 'hu'
		},
		{
			id: 5,
			name: 'Français',
			filename: 'flags/flag_de.png',
			language: 'fr'
		},
		{
			id: 6,
			name: 'Español',
			filename: 'flags/flag_it.png',
			language: 'es'
		}
	],
	productInfo: {
		id: 10,
		name: 'Cappuccino',
		servingsize: 100,
		servingsizeunitid: 3,
		image: 'products/lavazza_cappuccino.png',
		price: 1.5,
		location: 1,
		unit: [
			{
				id: 1,
				name: 'gramm',
				global: 'g'
			},
			{
				id: 2,
				name: 'kilogramm',
				global: 'kg'
			},
			{
				id: 3,
				name: 'milliliter',
				global: 'ml'
			},
			{
				id: 4,
				name: 'liter',
				global: 'L'
			},
			{
				id: 5,
				global: '',
				location: [
					{ id: 1, name: 'Pieces' },
					{ id: 2, name: 'Stück' },
					{ id: 3, name: 'Pezzo' },
					{ id: 4, name: 'Darab' },
					{ id: 5, name: 'Pièces' },
					{ id: 6, name: 'Piezas' }
				]
			},
			{
				id: 6,
				name: 'calorie',
				global: 'cal'
			},
			{
				id: 7,
				name: 'kilocalorie',
				global: 'kcal'
			}
		],
		attributes: [
			{
				id: 1,
				location: [
					{ id: 1, name: 'Fat' },
					{ id: 2, name: 'Fett' },
					{ id: 3, name: 'Grasso' },
					{ id: 4, name: 'Zsír' },
					{ id: 5, name: 'Graisse' },
					{ id: 6, name: 'Gordo' }
				],
				unitid: '1'
			},
			{
				id: 2,
				location: [
					{ id: 1, name: 'Energy content' },
					{ id: 2, name: 'Energiegehalt' },
					{ id: 3, name: 'Contenuto energetico' },
					{ id: 4, name: 'Energia tartalom' },
					{ id: 5, name: 'Contenu énergétique' },
					{ id: 6, name: 'Contenido energético' }
				],
				unitid: '7'
			},
			{
				id: 3,
				location: [
					{ id: 1, name: 'Carbohydrate' },
					{ id: 2, name: 'Kohlenhydrat' },
					{ id: 3, name: 'Carboidrato' },
					{ id: 4, name: 'Szénhidrát' },
					{ id: 5, name: 'Glucides' },
					{ id: 6, name: 'Carbohidrato' }
				],
				unitid: '1'
			},
			{
				id: 4,
				location: [
					{ id: 1, name: 'Protein' },
					{ id: 2, name: 'Protein' },
					{ id: 3, name: 'Proteina' },
					{ id: 4, name: 'Fehérje' },
					{ id: 5, name: 'Protéine' },
					{ id: 6, name: 'Proteína' }
				],
				unitid: '1'
			},
			{
				id: 5,
				location: [
					{ id: 1, name: 'Salt' },
					{ id: 2, name: 'Salz' },
					{ id: 3, name: 'Sale' },
					{ id: 4, name: 'Só' },
					{ id: 5, name: 'Sel' },
					{ id: 6, name: 'Sal' }
				],
				unitid: '1'
			},
			{
				id: 6,
				location: [
					{ id: 1, name: 'Minerals' },
					{ id: 2, name: 'Mineralien' },
					{ id: 3, name: 'Minerali' },
					{ id: 4, name: 'Ásványi anyagok' },
					{ id: 5, name: 'Minéraux' },
					{ id: 6, name: 'Minerales' }
				],
				unitid: '1'
			}
		],
		allergen: [
			{
				id: '1',
				location: [
					{ id: 1, name: 'Gluten' },
					{ id: 2, name: 'Gluten' },
					{ id: 3, name: 'Glutene' },
					{ id: 4, name: 'Glutén' },
					{ id: 5, name: 'Gluten' },
					{ id: 6, name: 'Gluten' }
				],
				image: 'allergens/allergen_gluten.png'
			},
			{
				id: '2',
				location: [
					{ id: 1, name: 'Milk' },
					{ id: 2, name: 'Milch' },
					{ id: 3, name: 'Latte' },
					{ id: 4, name: 'Tej' },
					{ id: 5, name: 'Lait' },
					{ id: 6, name: 'Leche' }
				],
				image: 'allergens/allergen_milk.png'
			},
			{
				id: '3',
				location: [
					{ id: 1, name: 'Tree nuts' },
					{ id: 2, name: 'Nüsse' },
					{ id: 3, name: "Noci dell'albero" },
					{ id: 4, name: 'Mogyorófélék' },
					{ id: 5, name: 'Noix' },
					{ id: 6, name: 'Nueces de árbol' }
				],
				image: 'allergens/allergen_tree_nuts.png'
			}
		],
		nameLocation: [
			{ id: 1, name: 'Coffee White' },
			{ id: 2, name: 'Espresso hell' },
			{ id: 3, name: 'Caffè macchiato' },
			{ id: 4, name: 'Eszpresszó kávé tejjel' },
			{ id: 5, name: 'Café macchiato' },
			{ id: 6, name: '' }
		],
		priceLocation: [
			{ id: 1, name: 'Price:' },
			{ id: 2, name: 'Preis:' },
			{ id: 3, name: 'Prezzo:' },
			{ id: 4, name: 'Ár:' },
			{ id: 5, name: 'Prix:' },
			{ id: 6, name: 'Precio:' }
		],
		servingSizeLocation: [
			{ id: 1, name: 'Serving size:' },
			{ id: 2, name: 'Serviergröße:' },
			{ id: 3, name: 'Porzioni:' },
			{ id: 4, name: 'Adag:' },
			{ id: 5, name: 'Portion:' },
			{ id: 6, name: 'Tamaño de la porción:' }
		],
		nutritionInfoLocation: [
			{ id: 1, name: 'Nutrition information' },
			{ id: 2, name: 'Ernährungsinformation' },
			{ id: 3, name: 'Informazioni nutrizionali' },
			{ id: 4, name: 'Tápértékadatok' },
			{ id: 5, name: 'Informations nutritionnelles' },
			{ id: 6, name: 'Información nutrición' }
		],
		attributevalues: [
			{ id: '1', type: 'fixed', attributeid: '2', value: '250.5', indent: '0' },
			{ id: '2', type: 'fixed', attributeid: '1', value: '1.55', indent: '0' },
			{ id: '3', type: 'fixed', attributeid: '3', value: '1.08', indent: '0' },
			{ id: '4', type: 'fixed', attributeid: '4', value: '1.15', indent: '0' },
			{ id: '5', type: 'fixed', attributeid: '5', value: '2.05', indent: '0' },
			{ id: '6', type: 'fixed', attributeid: '6', value: '0.27', indent: '0' }
		],
		informations: [
			{
				localization: [
					{
						id: 1,
						header: 'Attention',
						content:
							'High caffeine content. Not recommended for children and pregnant or breastfeeding women.'
					},
					{
						id: 2,
						header: 'Beachtung',
						content:
							'Hoher Koffeingehalt. Nicht empfohlen für Kinder, und schwangere oder stillende Frauen.'
					},
					{
						id: 3,
						header: 'Attenzione',
						content:
							'Alto contenuto di caffeina. Non raccomandato per bambini o donne in gravidanza o allattamento.'
					},
					{
						id: 4,
						header: 'Figyelmeztetés',
						content:
							'Magas koffein tartalom. Fogyasztása gyermekek és terhes vagy szoptató nők számára nem ajánlott.'
					},
					{
						id: 5,
						header: 'Attention',
						content:
							'Teneur élevée en caféine. Déconseillé aux enfants et aux femmes enceintes ou allaitantes.'
					},
					{
						id: 6,
						header: 'Atención',
						content: ''
					}
				]
			},
			{
				localization: [
					{
						id: 1,
						header: 'Ingredients',
						content:
							'Natural roasted coffee, water, whole milk, skimmed milk, anti-caking.'
					},
					{
						id: 2,
						header: 'Zutaten',
						content:
							'Natürlicher gerösteter Kaffee, Wasser, Magermilch, Vollmilch, Antiklumpmittel.'
					},
					{
						id: 3,
						header: 'Ingredienti',
						content:
							'Caffè tostato naturale, acqua, latte scremato, latte intero, antiagglomerante.'
					},
					{
						id: 4,
						header: 'Összetevők',
						content: 'Természetes pörkölt kávé, víz.'
					},
					{
						id: 5,
						header: 'Ingrédients',
						content: 'Café torréfié nature, eau.'
					},
					{
						id: 6,
						header: 'Ingredientes',
						content: ''
					}
				]
			},

			{
				localization: [
					{
						id: 1,
						header: 'Allergens'
					},
					{
						id: 2,
						header: 'Allergene'
					},
					{
						id: 3,
						header: 'Allergeni'
					},
					{
						id: 4,
						header: 'Allergének'
					},
					{
						id: 5,
						header: 'Allergének'
					},
					{
						id: 6,
						header: 'Alérgenos'
					}
				],
				contains: [{ allergenid: 1 }, { allergenid: 2 }, { allergenid: 3 }]
			}
		]
	},
	layouts: [
		{
			id: 1,
			name: 'ManualLayout 1',
			products: [
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso',
					pID: 13794,
					price: 0.3,
					productID: '1'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Americano',
					pID: 13800,
					price: 0.3,
					productID: '2'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso milk',
					pID: 13803,
					price: 0.3,
					productID: '3'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Cappuccino',
					pID: 13797,
					price: 0.4,
					productID: '4'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Choco Cappuccino',
					pID: 13796,
					price: 0.5,
					productID: '5'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Latte macchiato',
					pID: 13802,
					price: 1,
					productID: '6'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Moccaccino',
					pID: 13795,
					price: 0.4,
					productID: '7'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso milk extra',
					pID: 13798,
					price: 0.35,
					productID: '8'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - irish',
					pID: 13799,
					price: 0.4,
					productID: '9'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Choco - choco',
					pID: 13801,
					price: 0.3,
					productID: '10'
				}
			]
		},
		{
			id: 2,
			name: 'ManualLayout 2',
			products: [
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso',
					pID: 13794,
					price: 0.3,
					productID: '1'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Americano',
					pID: 13800,
					price: 0.3,
					productID: '2'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso milk',
					pID: 13803,
					price: 0.3,
					productID: '3'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Cappuccino',
					pID: 13797,
					price: 0.4,
					productID: '4'
				}
			]
		},
		{
			id: 3,
			name: 'ManualLayout 3',
			products: [
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso',
					pID: 13794,
					price: 0.3,
					productID: '1'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Americano',
					pID: 13800,
					price: 0.3,
					productID: '2'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso milk',
					pID: 13803,
					price: 0.3,
					productID: '3'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Cappuccino',
					pID: 13797,
					price: 0.4,
					productID: '4'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Choco Cappuccino',
					pID: 13796,
					price: 0.5,
					productID: '5'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Latte macchiato',
					pID: 13802,
					price: 1,
					productID: '6'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Moccaccino',
					pID: 13795,
					price: 0.4,
					productID: '7'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso milk extra',
					pID: 13798,
					price: 0.35,
					productID: '8'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - irish',
					pID: 13799,
					price: 0.4,
					productID: '9'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Choco - choco',
					pID: 13801,
					price: 0.3,
					productID: '10'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Choco Cappuccino',
					pID: 13796,
					price: 0.5,
					productID: '11'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Latte macchiato',
					pID: 13802,
					price: 1,
					productID: '12'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Moccaccino',
					pID: 13795,
					price: 0.4,
					productID: '13'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso milk extra',
					pID: 13798,
					price: 0.35,
					productID: '14'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - irish',
					pID: 13799,
					price: 0.4,
					productID: '15'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Choco - choco',
					pID: 13801,
					price: 0.3,
					productID: '16'
				}
			]
		},
		{
			id: 4,
			name: 'ManualLayout 4',
			products: [
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso',
					pID: 13794,
					price: 0.3,
					productID: '1'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Americano',
					pID: 13800,
					price: 0.3,
					productID: '2'
				}
			]
		},
		{
			id: 5,
			name: 'ManualLayout 5',
			products: [
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso',
					pID: 13794,
					price: 0.3,
					productID: '1'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Americano',
					pID: 13800,
					price: 0.3,
					productID: '2'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso milk',
					pID: 13803,
					price: 0.3,
					productID: '3'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Cappuccino',
					pID: 13797,
					price: 0.4,
					productID: '4'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Choco Cappuccino',
					pID: 13796,
					price: 0.5,
					productID: '5'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Latte macchiato',
					pID: 13802,
					price: 1,
					productID: '6'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Moccaccino',
					pID: 13795,
					price: 0.4,
					productID: '7'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso milk extra',
					pID: 13798,
					price: 0.35,
					productID: '8'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - irish',
					pID: 13799,
					price: 0.4,
					productID: '9'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Choco - choco',
					pID: 13801,
					price: 0.3,
					productID: '10'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso milk',
					pID: 13803,
					price: 0.3,
					productID: '11'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Cappuccino',
					pID: 13797,
					price: 0.4,
					productID: '12'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Choco Cappuccino',
					pID: 13796,
					price: 0.5,
					productID: '13'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Latte macchiato',
					pID: 13802,
					price: 1,
					productID: '14'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Moccaccino',
					pID: 13795,
					price: 0.4,
					productID: '15'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - Espresso milk extra',
					pID: 13798,
					price: 0.35,
					productID: '16'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Coffee - irish',
					pID: 13799,
					price: 0.4,
					productID: '17'
				},
				{
					insertedAt: '2021-04-27T07:42:27.000Z',
					name: 'Choco - choco',
					pID: 13801,
					price: 0.3,
					productID: '18'
				}
			]
		}
	],
	IVTtransactions: [
		{
			i: 886,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 35,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 316,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 887,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 35,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 326,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 888,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 35,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 316,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 1039,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: {
				remark: 'Refund! Transaction account: 997'
			},
			userWalletID: 326,
			transactionTypeID: 0,
			amount: 0.25,
			billingStateID: null
		},
		{
			i: 508,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: {
				uID: '70061237'
			},
			userWalletID: 316,
			transactionTypeID: 6,
			amount: 0.09999999999999964,
			billingStateID: null
		},
		{
			i: 678,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: null,
			userWalletID: 326,
			transactionTypeID: 6,
			amount: 0.09999999999999964,
			billingStateID: null
		},
		{
			i: 213,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: null,
			userWalletID: 2,
			transactionTypeID: 6,
			amount: 3,
			billingStateID: 4
		},
		{
			i: 886,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 35,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 7,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 887,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 35,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 7,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 888,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 35,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 7,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 1039,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: {
				remark: 'Refund! Transaction account: 997'
			},
			userWalletID: 7,
			transactionTypeID: 0,
			amount: 0.25,
			billingStateID: null
		},
		{
			i: 508,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: {
				uID: '70061237'
			},
			userWalletID: 7,
			transactionTypeID: 6,
			amount: 0.09999999999999964,
			billingStateID: null
		},
		{
			i: 678,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: null,
			userWalletID: 10,
			transactionTypeID: 6,
			amount: 0.09999999999999964,
			billingStateID: null
		},
		{
			i: 213,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: null,
			userWalletID: 2,
			transactionTypeID: 6,
			amount: 3,
			billingStateID: 3
		},
		{
			i: 886,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 13,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 296,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 887,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 13,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 296,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 888,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 13,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 296,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 1039,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: {
				remark: 'Refund! Transaction account: 997'
			},
			userWalletID: 7,
			transactionTypeID: 0,
			amount: 0.25,
			billingStateID: null
		},
		{
			i: 508,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: {
				uID: '70061237'
			},
			userWalletID: 316,
			transactionTypeID: 6,
			amount: 0.09999999999999964,
			billingStateID: null
		},
		{
			i: 678,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: null,
			userWalletID: 316,
			transactionTypeID: 6,
			amount: 0.09999999999999964,
			billingStateID: null
		},
		{
			i: 213,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: null,
			userWalletID: 316,
			transactionTypeID: 6,
			amount: 3,
			billingStateID: 2
		},
		{
			i: 886,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 35,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 7,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 887,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 35,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 316,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 888,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: 35,
			details: {
				productID: '14105',
				productName: 'Coffee - Espresso milk',
				uID: '70061235'
			},
			userWalletID: 7,
			transactionTypeID: 7,
			amount: -0.25,
			billingStateID: null
		},
		{
			i: 1039,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: {
				remark: 'Refund! Transaction account: 997'
			},
			userWalletID: 7,
			transactionTypeID: 0,
			amount: 0.25,
			billingStateID: null
		},
		{
			i: 508,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: {
				uID: '70061237'
			},
			userWalletID: 7,
			transactionTypeID: 6,
			amount: 0.09999999999999964,
			billingStateID: null
		},
		{
			i: 678,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: null,
			userWalletID: 10,
			transactionTypeID: 6,
			amount: 0.09999999999999964,
			billingStateID: null
		},
		{
			i: 213,
			id: 'hvendservicekft-130j929kojnyh1f',
			insertedAt: new Date(),
			vmID: null,
			details: null,
			userWalletID: 296,
			transactionTypeID: 6,
			amount: 3,
			billingStateID: 1
		}
	]
};
