import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import statistics from '../../../services/api/statistics';

import classes from './EditPromotionDialog.module.css';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SummaryModal from './SummaryModal/SummaryModal';
import MachineGroupSelector from '../../../components/MachineGroupSelector/MachineGroupSelector';
import PromotionTypeSelector from './PromotionTypeSelector/PromotionTypeSelector';
import HelperTooltip from '../../../components/UI/HelperTooltip/HelperTooltip';
import ActiveDays from './ActiveDaysCheckBox/ActiveDaysCheckBox';
import TimeperiodCheckBox from './TimeperiodCheckBox/TimeperiodCheckBox';
import SelectedUsers from './SelectedUsers/SelectedUsers';
import PromotionUserSelectorDialog from '../PromotionUserSelectorDialog/PromotionUserSelectorDialog';
import TimePeriodLimit from './TimePeriodLimit/TimePeriodLimit';
import moment from 'moment';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { Alert } from 'reactstrap';

import promotionsUtil from '../../../services/util/promotionsUtil';

//____________________FROM DEV:__________________
// Product promotion now only can recive promotion ID manually.
// Commented codeblocks preperd for producthandling when it will be avileble

class EditPromotionDialog extends Component {
	state = {
		promotion: {
			startDate: null,
			dailyPeriodEnd: null,
			endDate: '',
			activeDays: null,
			description: '',
			name: '',
			promotedMachine: null,
			promotedMachineGroup: null,
			promotionType: null,
			promotedProductGroup: null,
			promotedProduct: null
		},
		optionMachine: null,
		optionGroup: null,

		validated: false,
		timePeriodChecked: false,
		isGroupSelected: false,

		showUserSelector: false
	};

	componentDidMount() {
		let promotion = { ...this.props.promotion };
		if (!!this.props.promotion.params)
			promotion.params = this.props.promotion.params.map(item => {
				return { ...item };
			});
		this.setState({
			promotion: promotion,
			timePeriodChecked: promotion.dailyPeriodStart || promotion.dailyPeriodEnd,
			isGroupSelected: promotion && !!promotion.assignedGroupID
		});

		let optionMachine = this.props.machines.map(e => ({
			key: e.vmID,
			value: e.vmID,
			label: e.customName
		}));
		let optionGroup = this.props.machineGroups.map(e => ({
			key: e.id,
			value: e.id,
			label: e.name
		}));

		this.setState({
			optionMachine,
			optionGroup
		});
	}

	// Render user selector wizard
	renderUserSelectorDialog() {
		return (
			<Modal
				show={this.state.showUserSelector}
				onHide={() => this.hideUserSelectorDialog()}
				size="xs"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i className="fa fa-bullhorn" style={{ marginRight: '10px' }}></i>
						{this.props.t(
							'promotionManager.addPromotion.promotionUsers.wizard.dialogTitle'
						)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: 'white' }}>
					<PromotionUserSelectorDialog
						machines={this.state.optionMachine}
						onGenerate={(selectedID, startDate, endDate) => {
							statistics
								.getUsersPurchasedOnMachine({
									fromDate: new Date(startDate),
									toDate: new Date(endDate),
									machineID: selectedID
								})
								.then(result => {
									// Add the result to the selected user list.
									if (
										result &&
										result.data &&
										result.data &&
										result.data.emails &&
										result.data.emails.length > 0
									) {
										let selectedUsers = promotionsUtil.getParamValueByName(
											this.state.promotion,
											promotionsUtil.promotionParameters
												.PROMOTION_SELECTED_USERS
										);

										selectedUsers = selectedUsers ? selectedUsers : '';

										if (
											selectedUsers.trim().length > 0 &&
											selectedUsers.trim().lastIndexOf(',') !=
												selectedUsers.length - 1
										) {
											selectedUsers = selectedUsers + ', ';
										}
										selectedUsers =
											selectedUsers + result.data.emails.join(', ');
										const promotion = { ...this.state.promotion };
										promotionsUtil.setParamValue(
											promotion,
											promotionsUtil.promotionParameters
												.PROMOTION_SELECTED_USERS,
											selectedUsers
										);

										this.setState({ promotion }, () =>
											this.hideUserSelectorDialog()
										);
									}
								});
						}}
					></PromotionUserSelectorDialog>
				</Modal.Body>
			</Modal>
		);
	}

	handleLimitTypeChange = value => {
		let promotion = { ...this.state.promotion };
		promotionsUtil.setParamValue(
			promotion,
			promotionsUtil.promotionParameters.PROMOTION_EXECUTION_LIMIT_TYPE,
			value
		);

		this.setState({ promotion: promotion });
	};

	handleLimitValueChange = value => {
		let promotion = { ...this.state.promotion };
		promotionsUtil.setParamValue(
			promotion,
			promotionsUtil.promotionParameters.PROMOTION_EXECUTION_LIMIT_NUMBER,
			value
		);

		this.setState({ promotion: promotion });
	};

	hideUserSelectorDialog = () => {
		this.setState({ showUserSelector: false });
	};

	showUserSelectorDialog = () => {
		this.setState({ showUserSelector: true });
	};

	inputChangeHandler = event => {
		let promotion = { ...this.state.promotion };
		promotion[event.target.name] = event.target.value;

		this.setState({ promotion: promotion });
	};

	inputParamChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	activeDaysChangeHandler = event => {
		let promotion = { ...this.state.promotion };
		let activeDays = [];
		if (promotion.activeDays) {
			activeDays = promotion.activeDays.split(',');
		}

		if (event.target.checked) {
			activeDays.push(event.target.value);
			promotion.activeDays = activeDays.toString();
		}
		if (!event.target.checked) {
			activeDays = activeDays.filter(i => i !== event.target.value);
			promotion.activeDays = activeDays.toString();
		}
		if (promotion.activeDays == '') {
			promotion.activeDays = null;
		}

		this.setState({ promotion: promotion });
	};

	timePeriodCheckedHandling = () => {
		let promotion = { ...this.state.promotion };
		if (this.state.timePeriodChecked) {
			promotion.dailyPeriodStart = null;
			promotion.dailyPeriodEnd = null;
			this.setState({
				timePeriodChecked: false,
				promotion: promotion
			});
		}
		if (!this.state.timePeriodChecked) {
			this.setState({
				timePeriodChecked: true,
				promotion: promotion
			});
		}
	};

	validationHandler = () => {
		const form = document.getElementById('editPromotionForm');
		if (form.checkValidity() === true) {
			this.showAddSummaryModal();
		} else {
			this.setState({ validated: true });
		}
	};

	showAddSummaryModal() {
		let promotedMachine = this.state.promotion.promotedMachine;
		let promotedMachineGroup = this.state.promotion.promotedMachineGroup;

		this.defaultOnclickhandler();
	}

	defaultOnclickhandler = () => {
		let promotion = { ...this.state.promotion };
		this.setState({ showSummaryModal: true, promotion: promotion });
	};

	hideAddSummaryModal = () => {
		this.setState({ showSummaryModal: false });
	};

	renderSummaryModal = () => {
		return (
			<Modal
				show={this.state.showSummaryModal}
				onHide={() => this.hideAddSummaryModal()}
				size="lg"
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header>
					<Modal.Title>
						{this.props.t('promotionManager.summary.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: 'GhostWhite' }}>
					<SummaryModal
						data={this.state.promotion}
						machines={this.props.machines}
						machineGroups={this.props.machineGroups}
						onSave={this.props.onSave}
						onHide={this.props.onHide}
					/>
				</Modal.Body>
			</Modal>
		);
	};

	render() {
		const { t } = this.props;

		return (
			<Form
				noValidate
				validated={this.state.validated}
				id={'editPromotionForm'}
			>
				{this.renderUserSelectorDialog()}
				{this.renderSummaryModal()}
				<Row>
					<Col>
						<Form.Group>
							<Row style={{ paddingLeft: '20px', alignItems: 'flex-start' }}>
								<Form.Label style={{ fontWeight: 'bold' }}>
									{t('promotionManager.addPromotion.promotionName.title')}
								</Form.Label>
								<HelperTooltip
									height={'100px'}
									width={'250px'}
									direction={'right'}
									id={'name'}
									title={t(
										'promotionManager.addPromotion.promotionName.tooltip.title'
									)}
									content={t(
										'promotionManager.addPromotion.promotionName.tooltip.content'
									)}
								></HelperTooltip>
							</Row>
							<Form.Control
								required
								id="name"
								name="name"
								type="text"
								value={this.state.promotion.name}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Row style={{ paddingLeft: '20px', alignItems: 'flex-start' }}>
								<Form.Label style={{ fontWeight: 'bold' }}>
									{t(
										'promotionManager.addPromotion.promotionDescription.title'
									)}
								</Form.Label>
								<HelperTooltip
									height={'100px'}
									width={'250px'}
									direction={'right'}
									id={'description'}
									title={t(
										'promotionManager.addPromotion.promotionName.tooltip.title'
									)}
									content={t(
										'promotionManager.addPromotion.promotionName.tooltip.content'
									)}
								></HelperTooltip>
							</Row>
							<Form.Control
								id="description"
								required
								name="description"
								type="text"
								value={this.state.promotion.description}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
				</Row>

				<PromotionTypeSelector
					id={'edit'}
					promotion={this.state.promotion}
					onChanged={promotion => {
						if (promotion.promotionTypeID == 6) {
							const paramValue = promotionsUtil.setParamValue(
								promotion,
								promotionsUtil.promotionParameters
									.PROMOTION_GENERAL_DISCOUNT_FIX_AMOUNT,
								'Y'
							);
						}
						this.setState({ promotion });
					}}
					productTags={this.props.productTags}
				/>

				<hr></hr>

				{this.state.promotion.promotionTypeID != 5 &&
				this.state.promotion.promotionTypeID != 6 ? (
					<>
						<Row style={{ paddingLeft: '20px', alignItems: 'flex-start' }}>
							<Form.Label style={{ fontWeight: 'bold' }}>
								{t('promotionManager.addPromotion.promotionDevice.title')}
							</Form.Label>
							<HelperTooltip
								height={'100px'}
								width={'300px'}
								direction={'top'}
								id={'machineGroups'}
								title={t(
									'promotionManager.addPromotion.promotionDevice.tooltip.title'
								)}
								content={t(
									'promotionManager.addPromotion.promotionDevice.tooltip.content'
								)}
							></HelperTooltip>
						</Row>
						<MachineGroupSelector
							optionGroup={this.state.optionGroup}
							optionMachine={this.state.optionMachine}
							isGroupSelected={this.state.isGroupSelected}
							selectedIDS={
								this.state.isGroupSelected
									? this.state.promotion.assignedGroupID
									: this.state.promotion.assignedVMID
							}
							onIsGroupSelectedChanged={(groupSelected, newItemSelection) => {
								const newPromotion = { ...this.state.promotion };
								if (groupSelected) {
									newPromotion.assignedVMID = null;
									newPromotion.assignedGroupID = newItemSelection;
								} else {
									newPromotion.assignedVMID = newItemSelection;
									newPromotion.assignedGroupID = null;
								}
								this.setState({
									promotion: newPromotion,
									isGroupSelected: groupSelected
								});
							}}
							onSelectionChanged={value => {
								const newPromotion = { ...this.state.promotion };
								if (this.state.isGroupSelected) {
									newPromotion.assignedVMID = null;
									newPromotion.assignedGroupID = value;
								} else {
									newPromotion.assignedVMID = value;
									newPromotion.assignedGroupID = null;
								}
								this.setState({ promotion: newPromotion });
							}}
						/>
						<hr></hr>
					</>
				) : null}
				<Row>
					<Col xs={12} sm={12} md={6} lg={6}>
						<Row style={{ paddingLeft: '20px' }}>
							<Form.Label style={{ fontWeight: 'bold' }}>
								{t('promotionManager.addPromotion.dateHandling.title')}
							</Form.Label>
							<HelperTooltip
								height={'110px'}
								width={'600px'}
								direction={'top'}
								id={'machineGroups'}
								title={t(
									'promotionManager.addPromotion.dateHandling.tooltip.title'
								)}
								content={t(
									'promotionManager.addPromotion.dateHandling.tooltip.content'
								)}
							></HelperTooltip>
						</Row>
						<Row>
							<Col xs={12} sm={12} md={12} lg={6}>
								<Form.Group controlId="startDate">
									<Row style={{ paddingLeft: '15px' }}>
										<Form.Label className={classes.Label}>
											{t('promotionManager.addPromotion.startDate.title')}
										</Form.Label>
									</Row>
									<Form.Control
										required
										name="startDate"
										type="date"
										value={
											moment(this.state.promotion.startDate).format(
												'YYYY-MM-DD'
											) || ''
										}
										onChange={this.inputChangeHandler}
									/>
								</Form.Group>
							</Col>
							<Col xs={12} sm={12} md={12} lg={6}>
								<Form.Group controlId="endDate">
									<Row style={{ paddingLeft: '15px' }}>
										<Form.Label className={classes.Label}>
											{t('promotionManager.addPromotion.endDate.title')}
										</Form.Label>
									</Row>
									<Form.Control
										name="endDate"
										type="date"
										value={
											moment(this.state.promotion.endDate).format(
												'YYYY-MM-DD'
											) || ''
										}
										onChange={this.inputChangeHandler}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Col>
					{this.state.promotion.promotionTypeID != 6 ? (
						<Col
							xs={12}
							sm={12}
							md={6}
							lg={6}
							style={
								this.state.timePeriodChecked
									? {
											backgroundColor: 'white'
									  }
									: { backgroundColor: 'ghostwhite' }
							}
						>
							<TimeperiodCheckBox
								timePeriodChecked={this.state.timePeriodChecked}
								dailyPeriodStart={this.state.promotion.dailyPeriodStart}
								dailyPeriodEnd={this.state.promotion.dailyPeriodEnd}
								onChanged={this.inputChangeHandler}
								onChecked={this.timePeriodCheckedHandling}
							/>
						</Col>
					) : null}
				</Row>
				<hr></hr>

				<Row style={{ paddingLeft: '20px' }}>
					<Form.Label style={{ fontWeight: 'bold' }}>
						{t('promotionManager.promotionTable.timePeriodLimit.title')}
					</Form.Label>
					<HelperTooltip
						height={'100px'}
						width={'300px'}
						direction={'right'}
						id={'machineGroups'}
						title={t(
							'promotionManager.promotionTable.timePeriodLimit.tooltip.title'
						)}
						content={t(
							'promotionManager.promotionTable.timePeriodLimit.tooltip.content'
						)}
					></HelperTooltip>
				</Row>
				<TimePeriodLimit
					timePeriodType={promotionsUtil.getParamValueByName(
						this.state.promotion,
						promotionsUtil.promotionParameters.PROMOTION_EXECUTION_LIMIT_TYPE
					)}
					timePeriodValue={promotionsUtil.getParamValueByName(
						this.state.promotion,
						promotionsUtil.promotionParameters.PROMOTION_EXECUTION_LIMIT_NUMBER
					)}
					onTimePeriodTypeChange={this.handleLimitTypeChange}
					onTimePeriodValueChange={this.handleLimitValueChange}
				></TimePeriodLimit>

				<hr></hr>
				{this.state.promotion.promotionTypeID != 6 ? (
					<>
						<Row style={{ paddingLeft: '20px' }}>
							<Form.Label style={{ fontWeight: 'bold' }}>
								{t('promotionManager.promotionTable.activeDays.title')}
							</Form.Label>
							<HelperTooltip
								height={'100px'}
								width={'300px'}
								direction={'right'}
								id={'machineGroups'}
								title={t(
									'promotionManager.promotionTable.activeDays.tooltip.title'
								)}
								content={t(
									'promotionManager.promotionTable.activeDays.tooltip.content'
								)}
							></HelperTooltip>
						</Row>
						<ActiveDays
							activeDays={
								this.state.promotion.activeDays
									? this.state.promotion.activeDays.split(',')
									: []
							}
							onChanged={this.activeDaysChangeHandler}
						/>
						<hr></hr>
					</>
				) : null}

				<Row style={{ paddingLeft: '20px' }}>
					<Form.Label style={{ fontWeight: 'bold' }}>
						{t('promotionManager.addPromotion.promotionUsers.title')}
					</Form.Label>
					<HelperTooltip
						height={'100px'}
						width={'300px'}
						direction={'top'}
						id={'usersToolTip'}
						title={t(
							'promotionManager.addPromotion.promotionUsers.tooltip.title'
						)}
						content={t(
							'promotionManager.addPromotion.promotionUsers.tooltip.content'
						)}
					></HelperTooltip>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
						<SelectedUsers
							promotion={this.state.promotion}
							onChange={promotion => {
								this.setState({ promotion });
							}}
							onShowUserSelector={() => this.showUserSelectorDialog()}
						/>
					</Col>
				</Row>
				<hr></hr>
				<Button
					variant="success"
					className="DefaultButton"
					onClick={() => this.validationHandler()}
				>
					<i className="fa fa-floppy-o mr-2" />{' '}
					{t('promotionManager.addPromotion.savebutton')}
				</Button>
			</Form>
		);
	}
}

export default withTranslation()(EditPromotionDialog);
