//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
// import { Col, Row } from 'reactstrap';
import {
	RedMachineButton,
	// GreenMachineButton
} from '../../../../components/Machines/MachineComponents';
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent';
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard';
//import realtime from '../../../services/api/realtime';
import ColibriKeyboardHeader from './ColibriKeyboardHeader';

type Props = {
	vmID: string,
	history: Object,
	products?: Array<Object>
};
type States = {};

class Colibri extends React.Component<Props, States> {
	keyboardConfig = [
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						<i className="fa fa-long-arrow-right ml-1" />
						<br />+<br />1
					</>
				),
				buttonID: '1'
			},
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						+<br />
						<i className="fa fa-long-arrow-right ml-1" /> <br />5
					</>
				),
				buttonID: '5'
			}
		],

		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						CANCEL <br />2
					</>
				),
				buttonID: '2'
			},
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						ENTER
						<br />6
					</>
				),
				buttonID: '6'
			}
		],

		[
			{
				buttonContent: <>3</>,
				buttonID: '3'
			},
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						MODIFY
						<br />7
					</>
				),
				buttonID: '7'
			}
		],

		[
			{
				buttonContent: <>4</>,
				buttonID: '4'
			},
			{
				buttonContent: <>8</>,
				buttonID: '8'
			}
		]
	];

	render() {
		return (
			<DirectChooseMachineBaseComponent
				machineTitle="Colibri"
				lineNumber={1}
				lineLength={16}
				keyboard={
					<DirectChooseKeyboard
						keyboardConfig={this.keyboardConfig}
						keyboardHeaderLine={<ColibriKeyboardHeader />}
						{...this.props}
					/>
				}
				{...this.props}
			/>
		);
	}
}

export default withRouter(Colibri);
