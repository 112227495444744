//@flow
import React from 'react'
import type { Node } from 'react'
import { Col, Row } from 'reactstrap'

import VMUtil from '../../../../services/util/vmUtil'
import ButtonWithLabelHorizontal from '../../../../components/Machines/ButtonWithLabelHorizontal'

type Props = {
  products: Array<Object>,
  keyboardConfig: Array<
    Array<{
      buttonContent: any,
      buttonID: string,
      ledIndex?: number,
      ButtonComponent?: Node,
    }>
  >,
  programmingButtonVisible?: boolean,
  onButtonClick?: (buttonId: string, isSlave: ?boolean) => () => Promise<void>,
}

export default class DirectChooseHorizontalKeyboard extends React.Component<Props> {
  render() {
    const {
      onButtonClick = (buttonId: string, isSlave: ?boolean) => () => {},
      keyboardConfig = [],
      products = [],
    } = this.props
    return (
      <>
        {keyboardConfig.map((line, idx) => (
          <Row
            key={`machine-keyboard-line-${idx}`}
            className="d-flex justify-content-center m-0"
          >
            {line.map((button, columnIdx) => (
              <Col
                xs={{ size: 12 / line.length }}
                md={{ size: 12 / line.length }}
                lg={{ size: 12 / line.length }}
                xl={{ size: 12 / line.length }}
                style={{ padding: '2px' }}
                key={`button-${idx}-${columnIdx}`}
              >
                {button && (
                  <ButtonWithLabelHorizontal
                    {...button}
                    onButtonClick={onButtonClick}
                    labelContent={VMUtil.getButtonLabel(button, products)}
                    buttonAlign={columnIdx % 2 ? 'left' : 'right'}
                  />
                )}
              </Col>
            ))}
          </Row>
        ))}
      </>
    )
  }
}
