import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './EditProductGroupForm.module.css';

import { Row, Col } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ProductSelectedTable from '../AddProductGroupForm/ProductSelectedTable';
import ProductSelectorTable from '../AddProductGroupForm/ProductSelectorTable';

import HelperTooltip from '../../../../components/UI/HelperTooltip/HelperTooltip';

class EditProductGroupForm extends Component {
	state = {
		editedGroup: {
			products: [],
			groupName: '',
			description: '',
			creationDate: new Date()
		},
		allProducts: [],
		groupProducts: []
	};

	componentDidMount() {
		const groupProducts = { ...this.props.group.products };
		const allProducts = { ...this.props.allProducts };
		const group = { ...this.props.group };
		this.setState({
			editedGroup: group,
			allProducts: allProducts,
			groupProducts: groupProducts
		});
	}

	inputChangeHandler = event => {
		let editedGroup = { ...this.state.editedGroup };
		editedGroup[event.target.name] = event.target.value;
		this.setState({ editedGroup: editedGroup });
	};

	selectedProducts = value => {
		if (this.state.editedGroup.products.length !== value.length) {
			let valueGroup = { ...this.state.editedGroup };
			valueGroup.products = value;
			this.setState({ editedGroup: valueGroup });
		}
	};

	loadSelectorTabel = () => {
		return (
			<>
				<ProductSelectorTable
					selected={this.props.group.products}
					data={this.props.allProducts}
					selectedProducts={this.selectedProducts}
				/>
			</>
		);
	};

	loadSelectedTabel = () => {
		return (
			<>
				<ProductSelectedTable
					data={this.state.editedGroup.products}
					height="180"
				/>
			</>
		);
	};

	onSubmitHandler = () => {
		//too do
	};

	validationHandler = () => {
		const form = document.getElementById('editProductgroupForm');
		if (form.checkValidity() === true) {
			this.onSubmitHandler();
		} else {
			this.setState({ validated: true });
		}
	};

	//Render-----------------------------------------------------------------

	render() {
		const { t } = this.props;

		return (
			<Form
				noValidate
				validated={this.state.validated}
				id={'editProductgroupForm'}
			>
				<Row>
					<Col>
						<Form.Group
							className={classes.FormGroup}
							controlId="groupName"
							style={{ padding: '15px' }}
						>
							<Row style={{ paddingLeft: '15px', alignItems: 'center' }}>
								<Form.Label className={classes.Label}>
									{t('groupManager.addGroup.listName.title')}
								</Form.Label>
								<HelperTooltip
									height={'100px'}
									width={'250px'}
									direction={'right'}
									id={'groupName'}
									title={t('groupManager.addGroup.listName.tooltip.title')}
									content={t('groupManager.addGroup.listName.tooltip.content')}
								></HelperTooltip>
							</Row>
							<Form.Control
								required
								name="groupName"
								type="text"
								value={this.state.editedGroup.groupName}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group
							className={classes.FormGroup}
							controlId="description"
							style={{ padding: '15px' }}
						>
							<Row style={{ paddingLeft: '15px', alignItems: 'center' }}>
								<Form.Label className={classes.Label}>
									{t('groupManager.addGroup.listDescription.title')}
								</Form.Label>
								<HelperTooltip
									height={'100px'}
									width={'250px'}
									direction={'right'}
									id={'groupDescription'}
									title={t(
										'groupManager.addGroup.listDescription.tooltip.title'
									)}
									content={t(
										'groupManager.addGroup.listDescription.tooltip.content'
									)}
								></HelperTooltip>
							</Row>
							<Form.Control
								required
								name="description"
								type="text"
								value={this.state.editedGroup.description}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Form.Group
					className={classes.FormGroup}
					controlId="addNewMachines"
					style={{ padding: '15px' }}
				>
					<Row style={{ paddingLeft: '15px', alignItems: 'center' }}>
						<Form.Label className={classes.Label}>
							{t('groupManager.addGroup.selectorTable.title')}
						</Form.Label>
						<HelperTooltip
							height={'100px'}
							width={'400px'}
							direction={'right'}
							id={'machineSelector'}
							title={t('groupManager.addGroup.selectorTable.tooltip.title')}
							content={t(
								'groupManager.addGroup.selectorTable.tooltip.product.content'
							)}
						></HelperTooltip>
					</Row>
					{this.loadSelectorTabel()}
				</Form.Group>
				<hr></hr>
				<Form.Group
					className={classes.FormGroup}
					controlId="NewMachineGroup"
					style={this.props.style}
				>
					<Form.Label className={classes.Label}>
						<strong>{t('groupManager.addGroup.selectedTable')}</strong>
					</Form.Label>
					{this.loadSelectedTabel()}
				</Form.Group>
				<hr></hr>
				<Button variant="primary" onClick={() => this.validationHandler()}>
					{t('groupManager.addGroup.savebutton')}
				</Button>
			</Form>
		);
	}
}

export default withTranslation()(EditProductGroupForm);
