import React, { Component } from 'react';
import { Col, Row, Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import classes from './SalesAndIncomeTable.module.css';
import DietailRow from './DietailRow';

class SummaryPanel extends Component {
	state = {
		transactionDietails: false,
		salesDietails: false,
		incomeDietails: false,
		cashStock: false
	};
	sumaryCollapsOpener = (value, name) => {
		let open = value;
		this.setState({ [name]: !open });
	};

	render() {
		const { t } = this.props;
		return (
			<Form>
				<Form.Group>
					<Row className={classes.SummaryRow}>
						<Col xs={12} sm={6} md={4} lg={4}>
							<Form.Label>{t('salesSnadIncome.transactions')}</Form.Label>
						</Col>
						<Col xs={12} sm={6} md={2} lg={2}>
							<Row noGutters>
								<Col
									xs={6}
									sm={6}
									md={6}
									lg={6}
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										marginRight: '5px'
									}}
								>
									{this.props.transactionSum}
								</Col>
								<Col xs={5} sm={5} md={5} lg={5}>
									{t('salesSnadIncome.summary.piece')}
								</Col>
							</Row>
						</Col>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Badge
								className={classes.Dietail}
								onClick={() =>
									this.sumaryCollapsOpener(
										this.state.transactionDietails,
										'transactionDietails'
									)
								}
								aria-expanded={this.state.transactionDietails}
							>
								{t('salesSnadIncome.summary.details')}
								<i
									className={
										!this.state.transactionDietails
											? 'fa fa-caret-down ml-2'
											: 'fa fa-caret-up ml-2'
									}
								></i>
							</Badge>
							<Collapse in={this.state.transactionDietails}>
								<Col className={classes.DietailPanel}>
									<DietailRow
										name={t('salesSnadIncome.summary.purchaseCash')}
										measure={t('salesSnadIncome.summary.piece')}
										label={this.props.purchaseCashTrSum}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.purchaseCashless')}
										measure={t('salesSnadIncome.summary.piece')}
										label={this.props.purchaseCashlessTrSum}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.purchaseWallet')}
										measure={t('salesSnadIncome.summary.piece')}
										label={this.props.purchaseWalletTrSum}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.topUpCash')}
										measure={t('salesSnadIncome.summary.piece')}
										label={this.props.topUpCashTrSum}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.topUpCashless')}
										measure={t('salesSnadIncome.summary.piece')}
										label={this.props.topUpCashlessTrSum}
										style={{ borderColor: 'transparent' }}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.undefinedSource')}
										measure={t('salesSnadIncome.summary.piece')}
										label={this.props.undefinedSum}
										style={{ borderColor: 'transparent' }}
									/>
								</Col>
							</Collapse>
						</Col>
					</Row>

					<Row className={classes.SummaryRow}>
						<Col xs={12} sm={6} md={4} lg={4}>
							<Form.Label>{t('salesSnadIncome.failedTransactions')}</Form.Label>
						</Col>
						<Col xs={12} sm={6} md={2} lg={2}>
							<Row noGutters>
								<Col
									xs={6}
									sm={6}
									md={6}
									lg={6}
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										marginRight: '5px'
									}}
								>
									{this.props.failedTransactionSum}
								</Col>
								<Col xs={5} sm={5} md={5} lg={5}>
									{t('salesSnadIncome.summary.piece')}
								</Col>
							</Row>
						</Col>
					</Row>

					<Row className={classes.SummaryRow}>
						<Col xs={12} sm={6} md={4} lg={4}>
							<Form.Label>{t('salesSnadIncome.sales')}</Form.Label>
						</Col>
						<Col xs={12} sm={6} md={2} lg={2}>
							<Row noGutters>
								<Col
									xs={6}
									sm={6}
									md={6}
									lg={6}
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										marginRight: '5px'
									}}
								>
									{this.props.priceSum}
								</Col>
								<Col xs={5} sm={5} md={5} lg={5}>
									{this.props.measure}
								</Col>
							</Row>
						</Col>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Badge
								className={classes.Dietail}
								onClick={() =>
									this.sumaryCollapsOpener(
										this.state.salesDietails,
										'salesDietails'
									)
								}
								aria-expanded={this.state.salesDietails}
							>
								{t('salesSnadIncome.summary.details')}
								<i
									className={
										!this.state.salesDietails
											? 'fa fa-caret-down ml-2'
											: 'fa fa-caret-up ml-2'
									}
								></i>
							</Badge>
							<Collapse in={this.state.salesDietails}>
								<Col className={classes.DietailPanel}>
									<DietailRow
										name={t('salesSnadIncome.summary.purchaseCash')}
										measure={this.props.measure}
										label={this.props.purchaseCashPriceSum}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.purchaseCashless')}
										measure={this.props.measure}
										label={this.props.purchaseCashlessPriceSum}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.purchaseWallet')}
										measure={this.props.measure}
										label={this.props.purchaseWalletPriceSum}
										style={{ borderColor: 'transparent' }}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.undefinedSource')}
										measure={this.props.measure}
										label={this.props.undefinedPriceSum}
										style={{ borderColor: 'transparent' }}
									/>
								</Col>
							</Collapse>
						</Col>
					</Row>

					<Row className={classes.SummaryRow}>
						<Col xs={12} sm={6} md={4} lg={4}>
							<Form.Label>{t('salesSnadIncome.income')}</Form.Label>
						</Col>
						<Col xs={12} sm={6} md={2} lg={2}>
							<Row noGutters>
								<Col
									xs={6}
									sm={6}
									md={6}
									lg={6}
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										marginRight: '5px'
									}}
								>
									{this.props.incomeSum}
								</Col>
								<Col xs={5} sm={5} md={5} lg={5}>
									{this.props.measure}
								</Col>
							</Row>
						</Col>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Badge
								className={classes.Dietail}
								onClick={() =>
									this.sumaryCollapsOpener(
										this.state.incomeDietails,
										'incomeDietails'
									)
								}
								aria-expanded={this.state.incomeDietails}
							>
								{t('salesSnadIncome.summary.details')}
								<i
									className={
										!this.state.incomeDietails
											? 'fa fa-caret-down ml-2'
											: 'fa fa-caret-up ml-2'
									}
								></i>
							</Badge>
							<Collapse in={this.state.incomeDietails}>
								<Col className={classes.DietailPanel}>
									<DietailRow
										name={t('salesSnadIncome.summary.purchaseCash')}
										measure={this.props.measure}
										label={this.props.purchaseCashIncomeSum}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.purchaseCashless')}
										measure={this.props.measure}
										label={this.props.purchaseCashlessIncomeSum}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.topUpCash')}
										measure={this.props.measure}
										label={this.props.topUpCashIncomeSum}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.topUpCashless')}
										measure={this.props.measure}
										label={this.props.topUpCashlessIncomeSum}
										style={{ borderColor: 'transparent' }}
									/>
								</Col>
							</Collapse>
						</Col>
					</Row>
					<Row
						className={classes.SummaryRow}
						style={{ borderColor: 'transparent' }}
					>
						<Col xs={12} sm={6} md={4} lg={4}>
							<Form.Label>{t('salesSnadIncome.cashStock')}</Form.Label>
						</Col>
						<Col xs={12} sm={6} md={2} lg={2}>
							<Row noGutters>
								<Col
									xs={6}
									sm={6}
									md={6}
									lg={6}
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										marginRight: '5px'
									}}
								>
									{this.props.cashSum}
								</Col>
								<Col xs={5} sm={5} md={5} lg={5}>
									{this.props.measure}
								</Col>
							</Row>
						</Col>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Badge
								className={classes.Dietail}
								onClick={() =>
									this.sumaryCollapsOpener(this.state.cashStock, 'cashStock')
								}
								aria-expanded={this.state.cashStock}
							>
								{t('salesSnadIncome.summary.details')}
								<i
									className={
										!this.state.cashStock
											? 'fa fa-caret-down ml-2'
											: 'fa fa-caret-up ml-2'
									}
								></i>
							</Badge>
							<Collapse in={this.state.cashStock}>
								<Col className={classes.DietailPanel}>
									<DietailRow
										name={t('salesSnadIncome.summary.purchaseCash')}
										measure={this.props.measure}
										label={this.props.purchaseCashStock}
									/>
									<DietailRow
										name={t('salesSnadIncome.summary.topUpCash')}
										measure={this.props.measure}
										label={this.props.topUpCashStock}
										style={{ borderColor: 'transparent' }}
									/>
								</Col>
							</Collapse>
						</Col>
					</Row>
				</Form.Group>
			</Form>
		);
	}
}

export default withTranslation()(SummaryPanel);
