import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import '../../DeviceGroups/AddMachineGroupForm/node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';

import Spinner from '../../../components/UI/Spinner/Spinner';
import FilterBar from '../../FilterBar/FilterBar';
import classes from './PartList.module.css';
import machineAPI from '../../../services/api/machines';
import customers from '../../../services/api/customers';

import { dateFromMYSQLTimeStamp } from '../../../services/util/dateUtil';

import EditPart from './EditDialog/EditPart';
import DeviceComponentsHistory from '../DeviceComponentsHistory/DeviceComponentsHistory';
import PrintDialog from './PrintDialog/PrintDialog';
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';

class MIList extends Component {
	state = { selectedRows: [], currentFilterValue: '' };
	formatterList = {};

	componentDidMount() {
		this.prepareFormatterList();
		this.loadDataFromBackend();
	}

	getFormatterForField(fieldName) {
		if (!this.formatterList.formatters) return null;

		let formatterElement = this.formatterList.formatters.find(formatter =>
			formatter.bindedFieldList.includes(fieldName)
		);
		if (formatterElement) {
			return formatterElement.formatter;
		} else {
			return null;
		}
	}

	formatValue(fieldName, value) {
		const formatter = this.getFormatterForField(fieldName);
		if (formatter) {
			return formatter(value, '');
		} else {
			return value;
		}
	}

	visibleFieldList = [
		'id',
		'manufactureDate',
		'machineInterfaceType',
		'electronicsItemNumber',
		'hardwareState'
	];

	prepareFormatterList() {
		this.formatterList = {
			formatters: [
				{
					formatter: this.dateFormatter,
					bindedFieldList: ['manufactureDate']
				},
				{ formatter: this.customerFormatter, bindedFieldList: ['customerID'] },
				{
					formatter: this.machineInterfaceTypeFormatter,
					bindedFieldList: ['machineInterfaceType']
				},
				{ formatter: this.idStateClassFormatter, bindedFieldList: ['idState'] },
				{
					formatter: this.hardwareStateClassFormatter,
					bindedFieldList: ['hardwareState']
				}
			]
		};
	}

	loadDataFromBackend() {
		customers.getCustomers().then(result => {
			this.setState({ customers: [...result.data.response] });
		});
		machineAPI.getMIHardwareTypes().then(result => {
			this.setState({ hardwareTypes: [...result.data.response] });
		});
		machineAPI.getHardwareStates().then(result => {
			this.setState({ hardwareStates: [...result.data.response] });
		});
		machineAPI.getMIHardwares().then(result => {
			let fullMIList = [...result.data.response];
			fullMIList = fullMIList.map((row, index) => {
				return { ...row, nr: index + 1 };
			});
			this.setState({ fullMachineList: fullMIList }, () =>
				this.filterHardwares()
			);
		});
	}

	filterHardwares() {
		let filteredMachines = [...this.state.fullMachineList];
		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredMachines = this.state.fullMachineList.filter(hardware => {
				let curHardware = { ...hardware };

				this.visibleFieldList.forEach(key => {
					curHardware[key] = this.formatValue(key, curHardware[key]);
				});

				//let userFieldArray = Object.values(curHardware);
				const filterValueForDates = filterValue.replace(/\./g, '. ').trim();
				return this.visibleFieldList.some(
					key =>
						String(curHardware[key])
							.toUpperCase()
							.indexOf(filterValue) >= 0 ||
						String(curHardware[key])
							.toUpperCase()
							.indexOf(filterValueForDates) >= 0
				);
			});
		}

		this.setState({ filteredMachines });
	}

	showEditDialog() {
		this.setState({ showEditDialog: true });
	}

	hideEditDialog() {
		this.setState({ showEditDialog: false });
	}

	showPrintDialog() {
		this.setState({ showPrintDialog: true });
	}

	hidePrintDialog() {
		this.setState({ showPrintDialog: false });
	}

	showConfirmationDialog(mi, editedParts) {
		this.setState({
			multipleRecordSave: {
				mi,
				editedParts
			}
		});
	}

	showPartHistory(row) {
		this.setState({ showPartHistory: true, historyPartID: row.id });
	}

	hidePartHistory() {
		this.setState({ showPartHistory: false });
	}

	idStateClassFormatter = (cell, row) => {
		if (this.state.idStates) {
			return this.state.idStates
				.filter(idState => idState.id === cell)
				.map(idState => idState.statename);
		} else {
			return cell;
		}
	};

	hardwareStateClassFormatter = (cell, row) => {
		if (this.state.hardwareStates) {
			return this.state.hardwareStates
				.filter(hardwareState => hardwareState.id === cell)
				.map(hardwareState => hardwareState.statename);
		} else {
			return cell;
		}
	};

	getHardwareStateColorStyle(fieldValue) {
		let result = {}; // paddingTop: '15px'
		switch (fieldValue) {
			case 1:
				result = {
					...result,
					backgroundColor: '#4dbd74',
					borderColor: '#4dbd74',
					color: 'white'
				};
				break;
			case 2:
				result = {
					...result,
					backgroundColor: '#DB021D',
					borderColor: '#DB021D',
					color: 'white'
				};
				break;
			case 3:
				result = {
					...result,
					backgroundColor: '#7C8797',
					borderColor: '#7C8797',
					color: 'white'
				};
				break;
			default:
				break;
		}

		return result;
	}

	machineInterfaceTypeFormatter = (cell, row) => {
		if (this.state.hardwareTypes) {
			return this.state.hardwareTypes
				.filter(hardwareType => hardwareType.id === cell)
				.map(hardwareType => hardwareType.name);
		} else {
			return cell;
		}
	};

	customerFormatter = (cell, row) => {
		if (this.state.customers && cell) {
			return this.state.customers
				.filter(customer => customer.cID === cell)
				.map(customer => customer.company);
		} else {
			return cell;
		}
	};

	dateFormatter = (cell, row) => {
		if (cell) {
			const dateFromTimeStamp = dateFromMYSQLTimeStamp(cell);
			return dateFromTimeStamp.toLocaleDateString();
		} else {
			return cell;
		}
	};

	onRowSelect = (row, isSelected) => {
		let selectedRows = [...this.state.selectedRows];
		selectedRows = selectedRows.filter(item => item !== row.id);

		if (isSelected) {
			selectedRows = [...selectedRows, row.id];
		}
		this.setState({
			selectedRows
		});
	};

	handleSelectAll = (isSelected, rows) => {
		if (rows && isSelected) {
			this.setState({ selectedRows: rows.map(row => row.id) });
		} else {
			this.setState({ selectedRows: [] });
		}
	};

	async printSelectedMIs(numberOfCopies) {
		this.selectedMIs = this.getSelectedMIList();
		try {
			let printed = false;
			for (let mi of this.selectedMIs) {
				const interfaceType = this.state.hardwareTypes.filter(
					hardwareType => hardwareType.id === mi.partType
				);
				if (interfaceType.length > 0) {
					const label1 = interfaceType[0].printLabel1;
					let label2 = interfaceType[0].printLabel2;
					const id = mi.id;

					label2 = label2.replace('$datenow$', new Date().toLocaleDateString());
					label2 = label2.replace('$yearnow$', new Date().getFullYear());
					for (let i = 0; i < numberOfCopies; i++) {
						await machineAPI.printMIZebra(label1, label2, id);
						printed = true;
					}
				}
			}
			if (printed) {
				toast.info(
					this.props.t(
						'hardwareManagement.hardwareList.common.printingSuccessfull'
					)
				);
				this.hidePrintDialog();
			}
		} catch (e) {
			toast.error(
				this.props.t('hardwareManagement.hardwareList.common.printingFailed')
			);
		}
	}

	onFilterChange(filteValue) {
		this.setState({ currentFilterValue: filteValue }, () =>
			this.filterHardwares()
		);
	}

	renderFilterBar() {
		return (
			<FilterBar
				onFilterChanged={value => this.onFilterChange(value)}
				showEditButton
				onShowEditItem={() => this.showEditDialog()}
				onShowPrintItem={() => this.showPrintDialog()}
				onCheckEditButtonDisabled={() => this.state.selectedRows.length <= 0}
				onCheckPrintButtonDisabled={() => this.state.selectedRows.length <= 0}
			/>
		);
	}

	async saveMI(mi, editedMIList) {
		this.setState({ multipleRecordSave: null });

		for (let targetMI of editedMIList) {
			let currentMI = {
				...mi,
				manufactureDate: undefined,
				id: targetMI.id
			};
			if (editedMIList.length > 1) {
				currentMI.electronicsItemNumber = undefined;
				currentMI.partType = undefined;
			}
			await machineAPI.updateMI(currentMI);
		}

		this.setState({ showEditDialog: false }, () => {
			this.loadDataFromBackend();
		});
	}

	getSelectedMIList() {
		let selectedMI;
		if (this.state.selectedRows.length > 0) {
			selectedMI = this.state.fullMachineList.filter(mi =>
				this.state.selectedRows.includes(mi.id)
			);
		}

		return selectedMI;
	}

	renderPartHistoryDialog() {
		if (this.state.fullMachineList && this.state.historyPartID) {
			return (
				<Modal
					show={this.state.showPartHistory}
					onHide={() => this.hidePartHistory()}
				>
					<Modal.Header>
						<Modal.Title>
							{this.props.t('hardwareManagement.hardwareHistory.title')}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<DeviceComponentsHistory
							partID={this.state.historyPartID}
							hardwareStates={this.state.hardwareStates}
						/>
					</Modal.Body>
				</Modal>
			);
		}
	}

	renderPrintDialog() {
		return (
			<Modal
				show={this.state.showPrintDialog}
				onHide={() => this.hidePrintDialog()}
			>
				<Modal.Header>
					<Modal.Title>
						{this.props.t('hardwareManagement.hardwareList.part.printMI')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PrintDialog
						onPrint={numberOfCopies => this.printSelectedMIs(numberOfCopies)}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	renderEditMIDialog() {
		let selectedMI = this.getSelectedMIList();
		let miEditValue = selectedMI ? { ...selectedMI[0] } : null;
		if (miEditValue && selectedMI.length > 1) {
			miEditValue.hardwareState = 0;
			miEditValue.partType = 0;
			miEditValue.manufactureDate = null;
			miEditValue.electronicsItemNumber = '';
			miEditValue.customerID = null;
		}

		return (
			<Modal
				show={this.state.showEditDialog}
				onHide={() => this.hideEditDialog()}
			>
				<Modal.Header>
					<Modal.Title>
						{this.props.t('hardwareManagement.hardwareList.part.editMI')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditPart
						part={miEditValue ? miEditValue : null}
						machineInterfaceTypes={this.state.hardwareTypes}
						hardwareStates={this.state.hardwareStates}
						customers={this.state.customers}
						editedParts={selectedMI}
						onSave={(mi, editedParts) => {
							editedParts.length > 1
								? this.showConfirmationDialog(mi, editedParts)
								: this.saveMI(mi, editedParts);
						}}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	renderSaveConfirmationDialog() {
		if (this.state.multipleRecordSave) {
			return (
				<ConfirmationDialog
					isOpen={this.state.multipleRecordSave}
					onToggle={() => this.setState({ multipleRecordSave: null })}
					title="Confirmation"
					confirmationText={this.props.t(
						'hardwareManagement.hardwareList.common.multipleEditConfirmation'
					)}
					cancelText={this.props.t(
						'hardwareManagement.hardwareList.common.cancel'
					)}
					okText={this.props.t('hardwareManagement.hardwareList.common.ok')}
					onCancel={() => this.setState({ multipleRecordSave: null })}
					onOk={() =>
						this.saveMI(
							this.state.multipleRecordSave.mi,
							this.state.multipleRecordSave.editedParts
						)
					}
				/>
			);
		} else {
			return null;
		}
	}

	actionButtonsFormatter = (cell, row) => {
		return (
			<Button
				onClick={e => this.showPartHistory(row)}
				variant="success"
				className={classes.EditButton}
			>
				<i className="fa fa-book" />
			</Button>
		);
	};

	renderTable() {
		const { t } = this.props;

		const tableOptions = {
			sortIndicator: true,
			hideSizePerPage: false,
			paginationSize: 3,
			sizePerPageList: [10, 15, 25, 50, 100],
			sizePerPage: this.props.recordPerPage || 15,
			withFirstAndLast: true,
			paginationPosition: 'bottom',
			alwaysShowAllBtns: false,
			clearSearch: true,
			hidePageListOnlyOnePage: true,
			sortName: 'id',
			sortOrder: 'desc',
			searchPanel: this.renderFilterBar,
			onSearchChange: this.onFilterChange,
			checkboxHeader: false
		};

		const tableSelectOptions = {
			mode: 'checkbox',
			clickToSelect: true,
			bgColor: '#2EADD3',
			columnWidth: '10px',
			onSelect: this.onRowSelect,
			onSelectAll: this.handleSelectAll
		};

		return (
			<BootstrapTable
				data={this.state.filteredMachines ? this.state.filteredMachines : null}
				version="4"
				striped
				hover
				pagination={!this.props.hidePager}
				insertRow={false}
				options={tableOptions}
				selectRow={tableSelectOptions}
				multiColumnSearch
				condensed
				bordered={false}
				dataAlign={'center'}
				size="sm"
				checkboxHeader="false"
			>
				<TableHeaderColumn
					dataSort
					width="0"
					dataAlign="center"
					dataField="id"
					isKey
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.part.nr')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="electronicsItemNumber"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.part.electronicsItemNumber')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="15"
					dataAlign="center"
					dataField="hardwareState"
					dataFormat={this.getFormatterForField('hardwareState')}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					tdStyle={fieldValue => this.getHardwareStateColorStyle(fieldValue)}
				>
					{t('hardwareManagement.hardwareList.HID.state')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="manufactureDate"
					dataFormat={this.getFormatterForField('manufactureDate')}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.part.manufactureDate')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="partType"
					dataFormat={this.getFormatterForField('machineInterfaceType')}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.part.machineType')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="customerID"
					dataFormat={this.getFormatterForField('customerID')}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.company')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="orderNumber"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.part.orderNumber')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="firmwareName"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.part.firmwareName')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="firmwareFilename"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.part.firmwareFilename')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="microcontrollerType"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.part.microcontrollerType')}
				</TableHeaderColumn>

				<TableHeaderColumn
					width="10"
					dataAlign="right"
					dataField="id"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					dataFormat={this.actionButtonsFormatter}
				></TableHeaderColumn>
			</BootstrapTable>
		);
	}

	render() {
		return (
			<>
				{this.renderEditMIDialog()}
				{this.renderPrintDialog()}
				{this.renderFilterBar()}
				{this.renderSaveConfirmationDialog()}
				{this.renderPartHistoryDialog()}

				{this.state.hardwareTypes && this.state.fullMachineList ? (
					this.renderTable()
				) : (
					<Spinner />
				)}
			</>
		);
	}
}

export default withTranslation()(MIList);
