import React from 'react';
import { withTranslation } from 'react-i18next';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import { Col, Row, Badge } from 'reactstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import classes from '../PackagesTable/PackagesTable.module.css';

const LayoutTable = props => {
	const { t } = props;

	const dateFormater = cell => {
		let date = '';
		if (cell) {
			date = moment(cell).format('YYYY.MM.DD HH:mm:ss');
		}
		return date;
	};

	const userFormatter = (cell, row) => {
		return <i className="fa fa-columns" id="userIcon" />;
	};

	const detailsButton = (coll, row) => {
		let name = [];
		if (props.machines) {
			name = props.machines
				.map(e => {
					if (
						e.appliedLayoutID == row.layoutID &&
						e.appliedPackageID == props.pId
					) {
						return (
							<Row style={{ padding: '10px' }}>
								<Col>{e.customName}</Col>
							</Row>
						);
					} else {
						return null;
					}
				})
				.filter(e => e != null);
			if (name.length > 10) {
				name = name.slice(0, 10);
				name.push('...');
			}
		}

		const overlay = (
			<Tooltip
				style={{
					pointerEvents: 'none',
					borderColor: 'transparent',
					background: 'transparent'
				}}
			>
				<Col style={{ pointerEvents: 'none' }}>
					{name.length < 1
						? props.t('promotionManager.promotionTable.noData')
						: name}
				</Col>
			</Tooltip>
		);

		const button = (
			<Badge
				{...props}
				className={classes.MachineTooltip}
				style={{ backgroundColor: 'transparent ' }}
			>
				<i className="fa fa-info-circle" />
			</Badge>
		);

		return (
			<OverlayTrigger
				placement="right"
				delay={{ show: 250, hide: 400 }}
				overlay={overlay}
			>
				{button}
			</OverlayTrigger>
		);
	};

	//Render Table
	const isColumnsVisibile = columName => {
		return !props.hiddenColumns || props.hiddenColumns.indexOf(columName) < 0;
	};

	//Table options
	const tableOptions = {
		sortIndicator: true,
		hideSizePerPage: false,
		paginationSize: 3,
		sizePerPageList: [10],
		sizePerPage: props.recordPerPage || 10,
		paginationPosition: 'bottom',
		withFirstAndLast: true,
		alwaysShowAllBtns: true,
		pageStartIndex: 1,
		clearSearch: true,
		hidePageListOnlyOnePage: true,
		sortName: 'ID',
		sortOrder: 'asc',
		searchPanel: props.renderFilterBar,
		onSearchChange: props.onFilterChange
	};
	// const { t } = props;

	return (
		<>
			{!props.hideToolbar ? props.renderFilterBar() : null}
			<BootstrapTable
				key="bootstrapTable"
				data={props.data}
				version="4"
				striped
				hover
				pagination={!props.hidePager}
				insertRow={false}
				options={tableOptions}
				multiColumnSearch
				condensed
				bordered={false}
				dataAlign={'center'}
				size="sm"
			>
				{isColumnsVisibile('userIcon') ? (
					<TableHeaderColumn
						isKey
						width="30"
						dataAlign="center"
						dataField="ID"
						dataFormat={userFormatter}
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px' }}
					/>
				) : null}
				{isColumnsVisibile('name') ? (
					<TableHeaderColumn
						width="100"
						dataField="name"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{props.t('packageManager.layoutTable.layoutName')}
					</TableHeaderColumn>
				) : null}

				{isColumnsVisibile('insertedAt') ? (
					<TableHeaderColumn
						width="100"
						dataField="insertedAt"
						dataFormat={dateFormater}
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
					>
						{props.t('packageManager.layoutTable.createdAt')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('layoutID') ? (
					<TableHeaderColumn
						width="200"
						dataField="layoutID"
						dataFormat={detailsButton}
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
					>
						{props.t('packageManager.layoutTable.vmName')}
					</TableHeaderColumn>
				) : null}
			</BootstrapTable>
		</>
	);
};

export default withTranslation()(LayoutTable);
