//@flow
import React from 'react'

import { withRouter } from 'react-router-dom'

import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard'
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent'

import JedeLynxKeyboardHeader from './JedeLynxKeyboardHeader'

type Props = {
  products?: Array<Object>,
}

class JedeLynx extends React.Component<Props> {
  keyboardConfig = [
    [
      {
        buttonContent: (
          <>
            <span style={{ fontSize: 'medium' }}>EXIT</span> <br />1
          </>
        ),
        buttonID: '1',
      },
    ],
    [
      {
        buttonContent: <>2</>,
        buttonID: '2',
      },
    ],
    [
      {
        buttonContent: <>3</>,
        buttonID: '3',
      },
    ],
    [
      {
        buttonContent: <>4</>,
        buttonID: '4',
      },
    ],
    [
      {
        buttonContent: <>5</>,
        buttonID: '5',
      },
    ],
    [
      {
        buttonContent: <>6</>,
        buttonID: '6',
      },
    ],
    [
      {
        buttonContent: <>7</>,
        buttonID: '7',
      },
    ],
    [
      {
        buttonContent: <>8</>,
        buttonID: '8',
      },
    ],
  ]

  render() {
    const { products = [] } = this.props

    return (
      <DirectChooseMachineBaseComponent
        machineTitle="JedeLynx"
        lineNumber={4}
        lineLength={20}
        ledNumbers={0}
        keyboard={
          <DirectChooseKeyboard
            keyboardHeaderLine={<JedeLynxKeyboardHeader />}
            keyboardConfig={this.keyboardConfig}
            products={products}
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(JedeLynx)
