//@flow
import React from 'react';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	FormGroup,
	Input,
	InputGroup,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';
// React select
import Select from 'react-select';
//Toast for alerts
import { toast } from 'react-toastify';

import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { withTranslation } from 'react-i18next';
import UserApi from '../../services/api/users';
import CustomerApi from '../../services/api/customers';
import { ROLES } from '../../services/util/consts';
// import { TOAST_MESSAGE_TIMEOUT } from '../../services/util/consts';
import logger from '../../services/logger';

type Props = {
	t: (key: string) => string,
	className: string,
	title: string,
	privileges: Array<{ value: string, label: string }>
};
type States = {
	email: string,
	privilege: string,
	cmID: string,
	expLeft: boolean,
	modal: boolean,
	success: boolean,
	userMenu: boolean,
	regMenu: boolean,
	debugMenu: boolean,
	vmsMenu: boolean,
	progress?: number,
	options: Array<Object>
};
class AddUserContainer extends React.Component<Props, States> {
	constructor(props: Props) {
		super(props);

		this.state = {
			email: '',
			expLeft: false, //a loding button-nak kell az állapot felméréséhez, hogy pörögjön e
			modal: false,
			success: false,
			privilege: props.privileges.length > 0 ? props.privileges[0].value : '',
			cmID: '',
			userMenu: false,
			regMenu: false,
			debugMenu: false,
			vmsMenu: false,
			options: []
		};
	}

	//Komponens betöltése
	componentDidMount = () => {
		this.getCustomers();
	};

	//Modal success
	toggleSuccess = () => {
		this.setState({
			success: !this.state.success
		});
	};

	//Szükséges mező
	needKeyError = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('error.missingFields'));
	};

	//Ez az email cím már van
	emailExist = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('error.emailAlreadyExist'));
	};

	//Reg gomb event-je
	handleClick = async () => {
		const { email } = this.state;
		if (email !== '') {
			//Regist töltés állapot beállítása
			this.setState({
				expLeft: true,
				progress: 0.5
			});
			try {
				const response = await UserApi.userRegistration(this.state);
				if (response.data.hasOwnProperty('response')) {
					//Meta postolása
					this.postUserMeta(email);

					//Ha error jön vissza
				} else {
					this.setState({
						expLeft: false,
						progress: 1.0
					});

					//Ez az email cím már van , alert feldobása
					this.emailExist();
				}
			} catch (e) {
				logger.error(e);
			}
		} else {
			this.setState({
				expLeft: false,
				progress: 1.0
			});

			//Mezők kitöltése kötelező
			this.needKeyError();
		}
	};

	//getCustomers
	getCustomers = async () => {
		try {
			const response = await CustomerApi.getCustomers();
			const options = response.data.response; //{0:'Kasun', 1:'columbo',2: '29'}
			this.setState({ options });
		} catch (e) {
			logger.error(e);
		}
	};

	//postUserMeta
	postUserMeta = async email => {
		try {
			const response = await UserApi.postUserInfo(email);
			if (response.data.hasOwnProperty('response')) {
				//Success
				this.toggleSuccess();
			}
		} catch (error) {
			logger.error(error);
		}
	};

	//OK vissza
	cancel = () => {
		this.setState({
			email: '',
			privilege: this.props.privileges[0].value,
			cmID: '',
			success: !this.state.success,
			expLeft: false
		});
	};

	//Select option change priv
	handlePrivilegeChange(event) {
		this.setState({
			privilege: event.target.value,
			cmID: ''
		});
	}

	//Email field változás
	handleChange = key => event => {
		this.setState({ [key]: event.target ? event.target.value : event.value });
	};

	handleToggleState = key => () => {
		this.setState({
			[key]: !this.state[key]
		});
	};

	//cmID selector View
	getCMView = () => {
		const { privilege, cmID } = this.state;
		if (privilege !== ROLES.technician) {
			return null;
		}
		const options = this.state.options.map(category => ({
			value: category.cmID,
			label: category.company + ' - ' + category.cmID
		}));

		return (
			<Col xs="10" sm="8" md="8" lg="12" xl="5">
				<Card>
					<CardHeader>
						<i className="fa fa-address-book-o" />
						<b>Customer ID</b>
					</CardHeader>
					<CardBody className="p-8">
						<h6>cmID:</h6>
						<Row>
							<Col>
								<Select
									name="form-field-name2"
									value={cmID}
									onChange={this.handleChange('cmID')}
									options={options}
									labelKey="label"
									valueKey="value"
									clearable
								/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Col>
		);
	};

	//Previliges selector view
	getPrevView = () => {
		const {
			privilege,
			cmID,
			userMenu,
			regMenu,
			debugMenu,
			vmsMenu,
			expLeft
		} = this.state;

		if (
			privilege === ROLES.companyAdmin ||
			privilege === ROLES.technician ||
			cmID !== ''
		) {
			const { t } = this.props;
			return (
				<Col xs="10" sm="8" md="8" lg="12" xl="5">
					<Card>
						<CardHeader>
							<i className="fa fa-lock" />
							<b>{t('addnewuser.card2.title')}</b>
						</CardHeader>
						<CardBody className="p-8">
							<Row>
								<Col xs="12">
									<FormGroup row>
										<Col md="3">
											<Label>{t('addnewadmin.card2.menu.title')}</Label>
										</Col>
										<Col md="9">
											<FormGroup check className="checkbox">
												<Input
													className="form-check-input"
													type="checkbox"
													id="userMenu"
													name="userMenu"
													checked={userMenu}
													onChange={this.handleToggleState('userMenu')}
												/>
												<Label
													check
													className="form-check-label"
													htmlFor="userMenu"
												>
													{t('sidebarmenu.users')}
												</Label>
											</FormGroup>
											<FormGroup check className="checkbox">
												<Input
													className="form-check-input"
													type="checkbox"
													id="regMenu"
													name="regMenu"
													checked={regMenu}
													onChange={this.handleToggleState('regMenu')}
												/>
												<Label
													check
													className="form-check-label"
													htmlFor="regMenu"
												>
													{t('login.registration.label')}
												</Label>
											</FormGroup>
											<FormGroup check className="checkbox">
												<Input
													className="form-check-input"
													type="checkbox"
													id="debugMenu"
													name="debugMenu"
													checked={debugMenu}
													onChange={this.handleToggleState('debugMenu')}
												/>
												<Label
													check
													className="form-check-label"
													htmlFor="debugMenu"
												>
													Debug
												</Label>
											</FormGroup>
											<FormGroup check className="checkbox">
												<Input
													className="form-check-input"
													type="checkbox"
													id="vmsMenu"
													name="vmsMenu"
													checked={vmsMenu}
													onChange={this.handleToggleState('vmsMenu')}
												/>
												<Label
													check
													className="form-check-label"
													htmlFor="vmsMenu"
												>
													VMS
												</Label>
											</FormGroup>
										</Col>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col xs="6">
									<LaddaButton
										id="redButton"
										className="btn btn-success btn-ladda"
										loading={expLeft}
										data-style={EXPAND_LEFT}
										onClick={this.handleClick}
									>
										{t('login.registration.button')}
									</LaddaButton>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			);
		}
	};

	render() {
		const { t, className, title } = this.props;
		const { success, email, privilege } = this.state;

		return (
			<div>
				<Modal
					isOpen={success}
					toggle={this.toggleSuccess}
					className={'modal-success ' + className}
				>
					<ModalHeader toggle={this.toggleSuccess}>
						{t('login.registration.modal.title')}
					</ModalHeader>
					<ModalBody>{t('login.registration.modal.info')}</ModalBody>
					<ModalFooter>
						<Button color="success" onClick={this.cancel}>
							OK
						</Button>{' '}
					</ModalFooter>
				</Modal>

				<Row>
					<Col xs="10" sm="8" md="8" lg="10" xl="5">
						<Card>
							<CardHeader>
								<i className="fa fa-user" />
								<b>{title}</b>
							</CardHeader>
							<CardBody className="p-8">
								<Row>
									<Col xs="12">
										<FormGroup>
											<h6>{t('addnewadmin.card1.label.email')}:</h6>
											<InputGroup className="mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="fa fa-at" />
													</span>
												</div>
												<Input
													type="email"
													placeholder={t('login.email')}
													value={email}
													onChange={this.handleChange('email')}
												/>
											</InputGroup>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col xs="8">
										<FormGroup>
											<h6>{t('addnewadmin.card1.label.type')}:</h6>
											<InputGroup className="mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="fa fa-key" />
													</span>
												</div>

												<Input
													type="select"
													name="ccmonth"
													id="ccmonth"
													onChange={this.handlePrivilegeChange}
													value={privilege}
												>
													{this.props.privileges.map(privilegeOption => (
														<option value={privilegeOption.value}>
															{privilegeOption.label}
														</option>
													))}
												</Input>
											</InputGroup>
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>

					{this.getCMView()}
					{this.getPrevView()}
				</Row>
			</div>
		);
	}
}

export default withTranslation()(AddUserContainer);
