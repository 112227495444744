import axios from './api'
import qs from 'qs'

export default {
  getOwnerOperators: async cmID => {
    const param = {
      cmID,
    }
    const query = qs.stringify(param, { addQueryPrefix: true })
    return await axios.get().get(`/operators${query}`)
  },
  operatorRegistration: async (operatorData: {
    email: string,
    privilege: string,
    cmID: string,
    userMenu: string,
    debugMenu: string,
    vmsMenu: string,
    regMenu: string,
    editVM: string,
    viewLCD: string,
  }) => {
    const param = {
      isRoot: true,
      ...operatorData,
    }
    
    return await axios.get().post('/registerUser', param);
  },
  postOperatorConnections: async (cmID, vmID, email) => {
    const param = {
      cmID,
      vmID,
      email,
    }
    return await axios.get().post(`/subUserConn`, param)
  },
  postOperatorAccess: async (userID, editVM, viewLCD) => {
    const param = {
      userID,
      editVM,
      viewLCD,
      isAccess: true,
    }
    return await axios.get().post(`/subUserConn`, param)
  },
  deleteOperatorConnection: async connID => {
    const param = { connID }
    return await axios.get().delete(`/subUserConn`, param)
  },
}
