import axios from './api';
import qs from 'qs';

export default {
	getClients: async cmID => {
		const query = qs.stringify({ cmID }, { addQueryPrefix: true });
		return await axios.get().get(`/customer/clients${query}`);
	},

	deleteClient: async ID => {
		let cId = {
			id: ID
		};
		const query = qs.stringify(cId, { addQueryPrefix: true });
		return axios.get().delete(`/customer/clients${query}`);
	},

	createClient: async value => {
		const body = { ...value };
		return await axios.get().post(`/customer/clients`, body);
	},

	updateClient: async value => {
		const body = { ...value };
		return await axios.get().put(`/customer/clients`, body);
	}
};
