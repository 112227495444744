//@flow
import React from 'react';

import { withTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import MachinaBaseComponent from '../MachinaBaseComponent';
import {
	GreenAccessButton,
	RedAccessButton,
	DisabledAccessButton
} from '../../../../components/Machines/MachineComponents';
import realtime from '../../../../services/api/realtime';
import ConfimationDialog from '../../../ConfirmationDialog/ConfirmationDialog';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import classes from './DirectChooseMachineBaseComponent.module.css';
import { DivIcon } from 'leaflet';

const LCDContainer = styled.div`
	max-width: 100%;
	margin: auto;
`;

class DirectChooseMachineBaseComponent extends MachinaBaseComponent {
	getLCDScreen = LCDLines =>
		LCDLines.map((line, idx) => (
			<>
				<br />
				<snap className="lcd_font pl-5 pr-5  text-white">{line}</snap>
				<br />
			</>
		));

	onSwitchLCD() {
		this.setState({ showLCD: !this.state.showLCD }, async () => {
			if (this.state.showLCD) {
				await realtime.turnOnLCD(this.state.hardwareID);
			} else {
				await realtime.turnOffLCD(this.state.hardwareID);
				await realtime.turnOffRemoteControl(this.state.hardwareID);
				this.setState({ remoteAcccess: false });
			}
		});
	}

	onSwitchRemoteControl() {
		this.setState({ remoteAcccess: !this.state.remoteAcccess }, async () => {
			if (this.state.remoteAcccess) {
				this.setState({ showLCD: true });
				await realtime.turnOnLCD(this.state.hardwareID);
				await realtime.turnOnRemoteControl(this.state.hardwareID);
				if (this.props.onRemoteControlSwitched) {
					this.props.onRemoteControlSwitched(this.state.remoteAcccess);
				}
			} else {
				await realtime.turnOffRemoteControl(this.state.hardwareID);
				if (this.props.onRemoteControlSwitched) {
					this.props.onRemoteControlSwitched(this.state.remoteAcccess);
				}
			}
		});
	}

	hideConfirmationDialog() {
		this.setState({
			confirmationVisible: false
		});
	}

	showConfirmation() {
		this.setState({
			confirmationVisible: true
		});
	}

	renderConfirmationDialog = () => {
		return (
			<ConfimationDialog
				title={this.props.t('device.details.services.confTitle')}
				confirmationText={
					<>
						<Col>{this.props.t('device.details.services.confirmation')}</Col>
					</>
				}
				okText={this.props.t('device.details.invisibleTouch.ok')}
				cancelText={this.props.t('device.details.invisibleTouch.cancel')}
				isOpen={this.state.confirmationVisible}
				onToggle={() => {
					this.hideConfirmationDialog();
				}}
				onOk={() => {
					this.hideConfirmationDialog();
				}}
				cancelOff
			/>
		);
	};

	render() {
		const { keyboard = null, machineTitle = '', lcd } = this.props;
		const lcdComponent = lcd ? (
			<lcd.type
				{...lcd.props}
				lcdLines={this.state.LCDLines}
				bntPressHandler={this.bntPressHandler}
			/>
		) : (
			<LCDContainer className="machine-led-background text-center">
				{this.getLCDScreen(this.state.LCDLines)}
			</LCDContainer>
		);

		const machineInterface = (
			<>
				{keyboard && (
					<keyboard.type
						{...keyboard.props}
						onButtonClick={this.bntPressHandler}
						onCommandClick={this.commandHandler}
						ledStatuses={this.state.ledStatuses}
					/>
				)}
			</>
		);

		return (
			<Card className="machine-container klix-bg" style={this.props.style}>
				{this.renderConfirmationDialog()}
				<CardHeader
					className={`border-dark bg-dark color-light ${classes.CardTitle}`}
				>
					<Row>
						<Col md="4" xs="12">
							<div className={classes.MachineTitle}>{machineTitle}</div>
						</Col>
						<Col
							md="1"
							xs="12"
							style={{ textAlign: 'right', minHeight: '1rem' }}
						></Col>
						<Col md="7" xs="12" style={{ textAlign: 'center' }}>
							{this.state.showLCD ? (
								<GreenAccessButton onClick={() => this.onSwitchLCD()}>
									{this.props.t('remoteControl.showLCD') +
										': ' +
										this.props.t('remoteControl.on')}
								</GreenAccessButton>
							) : (
								<RedAccessButton onClick={() => this.onSwitchLCD()}>
									{this.props.t('remoteControl.showLCD') +
										': ' +
										this.props.t('remoteControl.off')}
								</RedAccessButton>
							)}
							{this.props.remoteControlServiceEnabled ? (
								<>
									{this.state.remoteAcccess ? (
										<GreenAccessButton
											onClick={() => this.onSwitchRemoteControl()}
										>
											{this.props.t('remoteControl.remoteControl') +
												': ' +
												this.props.t('remoteControl.on')}
										</GreenAccessButton>
									) : (
										<RedAccessButton
											onClick={() => this.onSwitchRemoteControl()}
										>
											{this.props.t('remoteControl.remoteControl') +
												': ' +
												this.props.t('remoteControl.off')}
										</RedAccessButton>
									)}
								</>
							) : (
								<DisabledAccessButton onClick={() => this.showConfirmation()}>
									{this.props.t('remoteControl.remoteControl') +
										': ' +
										this.props.t('remoteControl.off')}
								</DisabledAccessButton>
							)}
						</Col>
					</Row>
				</CardHeader>
				<CardBody className={`klix-bg`} style={{ position: 'relative' }}>
					<Row>
						<Col xs="12" md="12" lg="12" xl="12">
							{lcdComponent}
						</Col>
					</Row>
					<br />
					{this.state.remoteAcccess ? (
						machineInterface
					) : (
						<div className={classes.MachineInterface}>
							<div className={classes.Overlay}></div>
							{machineInterface}
						</div>
					)}
				</CardBody>
			</Card>
		);
	}
}

export default withTranslation()(withRouter(DirectChooseMachineBaseComponent));
