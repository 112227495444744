//@flow
import React from 'react'

import { Col } from 'reactstrap'

import styled from 'styled-components'

import ButtonWithLabelHorizontal from '../../../../../components/Machines/ButtonWithLabelHorizontal'
import VMUtil from '../../../../../services/util/vmUtil'

const ColP5 = styled(Col)`
  padding: 5px;
`

type KeyboardLineProps = {
  button: {
    hasLabel?: boolean,
    hideButton?: boolean,
    buttonID: string,
    buttonContent?: any,
  },
  ButtonComponent: any,
  products: Array<Object>,
  onButtonClick: (key: string) => () => Promise<void>,
}

const KeyboardLine = ({
  button,
  ButtonComponent,
  products,
  onButtonClick,
}: KeyboardLineProps) => (
  <>
    {button.hasLabel && (
      <Col
        xs={{ size: 3 }}
        sm={{ size: 4 }}
        md={{ size: 4 }}
        lg={{ size: 4 }}
        xl={{ size: 4 }}
        style={{ padding: '0px' }}
      >
        <ButtonWithLabelHorizontal
          {...button}
          transparentBackground={true}
          hasTooltip={!!VMUtil.getButtonLabel(button, products)}
          labelContent={VMUtil.getButtonLabel(button, products, <></>)}
          buttonAlign="right"
        />
      </Col>
    )}
    {!button.hasLabel && (
      <ColP5
        xs={{ size: 3 }}
        sm={{ size: 2 }}
        md={{ size: 2 }}
        lg={{ size: 2 }}
        xl={{ size: 2 }}
      >
        <ButtonComponent
          style={{ height: '100%' }}
          onClick={onButtonClick(button.buttonID)}
        >
          {button.buttonContent}
        </ButtonComponent>
      </ColP5>
    )}
  </>
)

export default KeyboardLine
