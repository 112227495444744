import axios from './api';
import qs from 'qs';

const paramMapper = params => {
	const result = {};

	params.forEach(param => {
		result[param.paramname] = param.paramvalue;
	});

	return result;
};

export default {
	getPromotions: async () => {
		return await axios.get().get(`/vendingmachines/promotions`);
	},

	deletePromotion: async ID => {
		let pId = {
			promotionID: ID
		};
		const query = qs.stringify(pId, { addQueryPrefix: true });

		return axios
			.get()
			.delete(`/vendingmachines/promotion${query}`)
			.then()
			.catch(e => console.log(e));
	},

	createPromotion: async promotion => {
		const body = { ...promotion };
		body.params = JSON.stringify(paramMapper(promotion.params));
		return await axios.get().post(`/vendingmachines/promotion`, body);
	},

	updatePromotion: async (promotion, state) => {
		const body = { ...promotion };

		body.params = JSON.stringify(paramMapper(promotion.params));
		body.promotionID = promotion.id;

		return await axios.get().put(`/vendingmachines/promotion`, body);
	}
};
