export default class {
	static promotionParameters = {
		PROMOTION_GENERAL_DISCOUNT: 'PROMOTION_GENERAL_DISCOUNT',
		PROMOTION_GENERAL_DISCOUNT_FIX_AMOUNT:
			'PROMOTION_GENERAL_DISCOUNT_FIX_AMOUNT',
		PROMOTION_SEQUENTIAL_NUMBER: 'PROMOTION_SEQUENTIAL_NUMBER',
		PROMOTION_ENABLED_PRODUCTS: 'PROMOTION_ENABLED_PRODUCTS',
		PROMOTION_MINIMUM_CREDIT_UPLOAD: 'PROMOTION_MINIMUM_CREDIT_UPLOAD',
		PROMOTION_SELECTED_USERS: 'PROMOTION_SELECTED_USERS',
		PROMOTION_PRODUCT_TAGS: 'PROMOTION_PRODUCT_TAGS',
		PROMOTION_EXECUTION_LIMIT_TYPE: 'PROMOTION_EXECUTION_LIMIT_TYPE',
		PROMOTION_EXECUTION_LIMIT_NUMBER: 'PROMOTION_EXECUTION_LIMIT_NUMBER'
	};

	static promotionLimitType = {
		NOTLIMITED: 'NOT_LIMITED',
		DAILY: 'DAILY',
		WEEKLY: 'WEEKLY',
		MONTHLY: 'MONTHLY',
		LIFETIME: 'LIFETIME'
	};

	static getParamValueByName = (promotion, paramName) => {
		if (promotion && promotion.params) {
			const matchedParams = promotion.params.filter(
				param => param.paramname == paramName
			);
			if (matchedParams.length > 0) {
				return matchedParams[0].paramvalue;
			}
		}
		return undefined;
	};

	static setParamValue = (promotion, paramName, paramValue) => {
		if (promotion) {
			if (!promotion.params) {
				promotion.params = [];
			}

			const matchedParams = promotion.params.filter(
				param => param.paramname == paramName
			);
			if (matchedParams.length > 0) {
				matchedParams[0].paramvalue = paramValue;
			} else {
				promotion.params.push({
					paramname: paramName,
					paramvalue: paramValue
				});
			}
		}
	};

	static hasParamByName = (promotion, paramName) => {
		if (promotion && promotion.params) {
			const matchedParams = promotion.params.filter(
				param => param.paramname == paramName
			);
			if (matchedParams.length > 0) {
				return true;
			}
		}
		return false;
	};

	static isFixAmount = (promotion, paramName) => {
		if (this.hasParamByName(promotion, paramName)) {
			const matchedParams = promotion.params.filter(
				param => param.paramname == paramName && param.paramvalue == 'Y'
			);
			if (matchedParams.length > 0) {
				return true;
			}
		}
		return false;
	};

	static removeParam = (promotion, paramName) => {
		if (promotion) {
			if (!promotion.params) {
				promotion.params = [];
			}

			promotion.params = promotion.params.filter(
				param => param.paramname != paramName
			);
		}
	};
}
