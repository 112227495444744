//@flow
import React from 'react'

import { withRouter } from 'react-router-dom'

import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard'
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent'

import Brio3KeyboardHeader from './Brio3KeyboardHeader'

type Props = {
  products?: Array<Object>,
}

class Brio3 extends React.Component<Props> {
  keyboardConfig = [
    [
      {
        buttonContent: <>1</>,
        buttonID: '1',
      },
      {
        buttonContent: <>8</>,
        buttonID: '8',
      },
    ],
    [
      {
        buttonContent: <>2</>,
        buttonID: '2',
      },
      {
        buttonContent: <>9</>,
        buttonID: '9',
      },
    ],
    [
      {
        buttonContent: <>3</>,
        buttonID: '3',
      },
      {
        buttonContent: <>10</>,
        buttonID: '10',
      },
    ],
    [
      {
        buttonContent: <>4</>,
        buttonID: '4',
      },
      {
        buttonContent: <>11</>,
        buttonID: '11',
      },
    ],
    [
      {
        buttonContent: <>5</>,
        buttonID: '5',
      },
      {
        buttonContent: <>12</>,
        buttonID: '12',
      },
    ],
    [
      {
        buttonContent: <>6</>,
        buttonID: '6',
      },
      {
        buttonContent: <>13</>,
        buttonID: '13',
      },
    ],
    [
      {
        buttonContent: <>7</>,
        buttonID: '7',
      },
      {
        buttonContent: <>14</>,
        buttonID: '14',
      },
    ],
  ]

  render() {
    const { products = [] } = this.props

    return (
      <DirectChooseMachineBaseComponent
        machineTitle="Brio3"
        lineNumber={2}
        lineLength={16}
        ledNumbers={0}
        keyboard={
          <DirectChooseKeyboard
            keyboardHeaderLine={<Brio3KeyboardHeader />}
            keyboardConfig={this.keyboardConfig}
            products={products}
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(Brio3)
