import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SalesAndIncome from '../../views/SalesAndIncome/SalesAndIncome';

class SalesAndIncomePageRoute extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route
						path="/sales_and_income"
						name="SalesAndIncome"
						component={SalesAndIncome}
					/>
					<Redirect from="/" to="/sales_and_income" />
				</Switch>
			</div>
		);
	}
}

export default SalesAndIncomePageRoute;
