import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CampaningManager from '../../views/CampaningManager/CampaningManager';

class CampaningManagerPageRoute extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/campaning_manager" name="CampaningManager" component={CampaningManager} />
                    <Redirect from="/" to="/campaning_manager" />
                </Switch>
            </div>
        );
    }
}

export default CampaningManagerPageRoute;



