//@flow

import React, { Component } from 'react';

import AntaresTroll from '../Machines/AntaresTroll/AntaresTroll';
import Astro from '../Machines/Astro/Astro';
import AstroP from '../Machines/AstroP/AstroP';
import AstroAlphaNumeric from '../Machines/AstroAlphaNumeric/AstroAlphaNumeric';
import Klix from '../Machines/Klix/Klix';
import Klix900 from '../Machines/Klix900/Klix900';
import Canto from '../Machines/Canto/Canto';
import Colibri from '../Machines/Colibri/Colibri';
import Kikko from '../Machines/Kikko/Kikko';
import KikkoMax from '../Machines/KikkoMax/KikkoMax';
import SnakkyMax from '../Machines/Snakky/SnakkyMax';
import Snakky from '../Machines/Snakky/Snakky';
import Zeta from '../Machines/Zeta/Zeta';
import Wurlitzer from '../Machines/Wurlitzer/Wurlitzer';
import Bvm971 from '../Machines/Bvm971/Bvm971';
import Bvm972 from '../Machines/Bvm972/Bvm972';
import SpazioRD from '../Machines/SpazioRD/SpazioRD';
import Spazio from '../Machines/Spazio/Spazio';
import Group500 from '../Machines/Group500/Group500';
import Luce18 from '../Machines/Luce18/Luce18';
import Luce12 from '../Machines/Luce12/Luce12';
import Luce12v2 from '../Machines/Luce12v2/Luce12v2';
import Quarzo500 from '../Machines/Quarzo500/Quarzo500';
import CaffeEuropa from '../Machines/CaffeEuropa/CaffeEuropa';
import Venezia from '../Machines/Venezia/Venezia';
import VeneziaPlus from '../Machines/VeneziaPlus/VeneziaPlus';
import Zenith from '../Machines/Zenith/Zenith';
import JedeLynx from '../Machines/JedeLynx/JedeLynx';
import Lazio from '../Machines/Lazio/Lazio';
import Lazio18 from '../Machines/Lazio18/Lazio18';
import LucePortofino from '../Machines/LucePortofino/LucePortofino';
import Brio3 from '../Machines/Brio3/Brio3';
import Brio200 from '../Machines/Brio200/Brio200';
import LuceX2 from '../Machines/LuceX2/LuceX2';
import KikkoSnakky from '../Machines/KikkoSnakky/KikkoSnakky';
import Brio3MiniSnakky from '../Machines/Brio3MiniSnakky/Brio3MiniSnakky';

import AlphanumericSlaveComponent from '../Machines/AlphanumericMachine/AlphanumericSlaveComponent';
import AlphanumericMachineKeyboard from '../Machines/AlphanumericMachine/AlphanumericMachineKeyboard';
import { Container, Row, Col, Card } from 'reactstrap';
import { Form } from 'react-bootstrap';
import realtime from '../../../services/api/realtime';
import classes from './DeviceMonitor.module.css';
import GoogleMapsComponent from '../../Dashboard/Maps/GoogleMapsComponent';
import { withTranslation } from 'react-i18next';
import CommandPanel from './CommandPanel';
import Azkoyen from '../Machines/Azkoyen/Azkoyen';

class DeviceMonitor extends Component {
	state = { remoteControl: false, selectedSlaveIndex: 0 };

	machines = {
		AntaresTroll,
		Astro,
		AstroP,
		AstroAlphaNumeric,
		Canto,
		Colibri,
		Kikko,
		KikkoMax,
		SnakkyMax,
		Snakky,
		Zeta,
		Klix,
		KikkoSnakky,
		Klix900,
		Wurlitzer,
		Bvm971,
		Bvm972,
		SpazioRD,
		Spazio,
		Group500,
		Quarzo500,
		Luce18,
		Luce12,
		Luce12v2,
		CaffeEuropa,
		Venezia,
		VeneziaPlus,
		Zenith,
		JedeLynx,
		Lazio,
		Lazio18,
		LucePortofino,
		Brio3,
		Azkoyen,
		LuceX2,
		Brio3MiniSnakky,
		Brio200
	};

	slaveKeyboardConfig = [
		[
			{
				buttonID: '1',
				buttonContent: '1'
			},
			{
				buttonID: '2',
				buttonContent: '2'
			},
			{
				buttonID: '3',
				buttonContent: '3'
			}
		],
		[
			{
				buttonID: '4',
				buttonContent: '4'
			},
			{
				buttonID: '5',
				buttonContent: '5'
			},
			{
				buttonID: '6',
				buttonContent: '6'
			}
		],
		[
			{
				buttonID: '7',
				buttonContent: '7'
			},
			{
				buttonID: '8',
				buttonContent: (
					<>
						<i class="fa fa-long-arrow-up mr-1"></i>8
					</>
				)
			},
			{
				buttonID: '9',
				buttonContent: '9'
			}
		],
		[
			{
				buttonID: '10',
				buttonContent: (
					<>
						<i class="fa fa-long-arrow-down mr-1"></i>0
					</>
				)
			},
			{
				buttonID: '11',
				buttonContent: (
					<>
						ENTER
						<br />E
					</>
				)
			},
			{
				buttonID: '12',
				buttonContent: (
					<>
						EXIT
						<br />C
					</>
				)
			}
		]
	];

	getKeyboardByMachineName(machineName) {
		return this.machines[
			Object.keys(this.machines).find(
				key =>
					key.toLowerCase() == machineName.replace(' ', '').toLowerCase() ||
					key.toLowerCase() == machineName.toLowerCase()
			)
		];
	}

	handleSlaveButtonClick(key) {
		realtime.pressButton();
	}

	handleSlaveIndexChange(index) {
		this.setState({ selectedSlaveIndex: index });
	}

	render() {
		const { t } = this.props;
		const { machine, products } = this.props;

		if (!machine || !machine.name || !machine.machine) return <></>;
		const vmID = machine.vmID;
		const machineKeyboards = [];
		const MainKeyboard = this.getKeyboardByMachineName(machine.machine);
		let location =
			(machine.country ? machine.country : '') +
			' ' +
			(machine.city ? machine.city : '') +
			' ' +
			(machine.address ? machine.address : '') +
			' ' +
			(machine.zipcode ? machine.zipcode : '');

		if (MainKeyboard) {
			machineKeyboards.push(
				<Col xl="7" lg="12" md="12" xs="12">
					<MainKeyboard
						vmID={vmID}
						products={products}
						style={{
							width: '100%'
						}}
						remoteControlServiceEnabled={
							machine.remoteControlServiceEnabled === 'Y'
						}
						onRemoteControlSwitched={remoteControl => {
							this.setState({ remoteControl });
						}}
					></MainKeyboard>
				</Col>
			);
		}

		if (
			machine.slaves &&
			machine.slaves.length > 0 &&
			machine.slaves[this.state.selectedSlaveIndex].prefix &&
			machine.slaves[this.state.selectedSlaveIndex].prefix.length > 0
		) {
			const alphaNumericKeyboard = (
				<AlphanumericMachineKeyboard
					keyboardConfig={this.slaveKeyboardConfig}
					//onButtonClick={key => () => this.handleSlaveButtonClick(key)} // I had to accept this solution because of backward compatibiltiy
				/>
			);
			const slaveKeyboard = (
				<Col xl="5" lg="12" md="12" xs="12">
					<AlphanumericSlaveComponent
						vmID={vmID}
						machine={machine}
						remoteControlEnabled={this.state.remoteControl}
						selectedSlaveIndex={this.state.selectedSlaveIndex}
						onSlaveIndexChange={index => this.handleSlaveIndexChange(index)}
						keyboard={alphaNumericKeyboard}
						slavePrefix={
							this.props.machine.slaves[this.state.selectedSlaveIndex].prefix
						}
					></AlphanumericSlaveComponent>
				</Col>
			);
			machineKeyboards.push(slaveKeyboard);
		}

		return (
			<Container style={{ maxWidth: '100%' }}>
				<Col>
					{machine.slaves &&
					machine.slaves.length > 0 &&
					machine.slaves[this.state.selectedSlaveIndex].prefix &&
					machine.slaves[this.state.selectedSlaveIndex].prefix.length > 0 ? (
						<>
							<Row className={classes.HeaderRowSlave}>
								<Col xl="7" lg="12" md="12" xs="12">
									<Row>
										<Col>
											<Col>
												<Col>
													<Form.Label>{t('device.details.name')}</Form.Label>
												</Col>
												<Col className={classes.LabelCol}>
													<Form.Label>{machine.customName}</Form.Label>
												</Col>
											</Col>
											<Col>
												<Col>
													<Form.Label>
														{t('device.details.geoLocation')}
													</Form.Label>
												</Col>
												<Col className={classes.LabelCol}>
													<Form.Label>
														{location && location.length > 3
															? location
															: t('promotionManager.promotionTable.noData')}
													</Form.Label>
												</Col>
											</Col>
										</Col>
										<Col>
											{machine && machine.lat && machine.lng ? (
												<Card className={classes.LocationCard}>
													<div className="chart-wrapper">
														<GoogleMapsComponent
															machines={[machine]}
															monitor="true"
														/>
													</div>
												</Card>
											) : null}
										</Col>
									</Row>
								</Col>

								<Col xl="5" lg="12" md="12" xs="12"></Col>
							</Row>
							<Row className={classes.HeaderRow}>
								<Col
									xl="7"
									lg="12"
									md="12"
									xs="12"
									className={classes.SlaveCol}
								>
									{t('device.details.datasheet.master')}
								</Col>

								<Col
									xl="5"
									lg="12"
									md="12"
									xs="12"
									className={classes.SlaveCol}
								>
									{t('device.details.datasheet.slave')}
								</Col>
							</Row>
							<Row className={classes.HeaderRow}>
								<Col xl="7" lg="12" md="12" xs="12">
									<CommandPanel machine={machine} />
								</Col>
							</Row>
						</>
					) : (
						<Row style={{ paddingRight: '40px' }}>
							<Col xl="7" lg="12" md="12" xs="12" className={classes.HeaderCol}>
								<Row>
									<Col>
										<Col>
											<Col>
												<Form.Label>{t('device.details.name')}</Form.Label>
											</Col>
											<Col className={classes.LabelCol}>
												<Form.Label>{machine.customName}</Form.Label>
											</Col>
										</Col>
										<Col>
											<Col>
												<Form.Label>
													{t('device.details.geoLocation')}
												</Form.Label>
											</Col>
											<Col className={classes.LabelCol}>
												<Form.Label>
													{location && location.length > 3
														? location
														: t('promotionManager.promotionTable.noData')}
												</Form.Label>
											</Col>
										</Col>
									</Col>
									<Col>
										{machine && machine.lat && machine.lng ? (
											<Card className={classes.LocationCard}>
												<div className="chart-wrapper">
													<GoogleMapsComponent machines={[machine]} monitor />
												</div>
											</Card>
										) : null}
									</Col>
								</Row>

								<CommandPanel machine={machine} monitor="true" />
							</Col>
						</Row>
					)}
				</Col>

				<Row style={{ marginTop: '10px' }}>{machineKeyboards}</Row>
			</Container>
		);
	}
}

export default withTranslation()(DeviceMonitor);
