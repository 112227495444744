//@flow
import React from 'react';
import { Row } from 'reactstrap';
//BootstrapTable
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import '../DeviceGroups/AddMachineGroupForm/node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
// React DateRangePicker
import 'react-dates/initialize';
// import { DateRangePicker } from 'react-dates';
// import Select from 'react-select';
import 'react-dates/lib/css/_datepicker.css';
import './LogsList.css';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import EventListCard from '../Dashboard/CardHandling/EventListCard/EventListCard';
import classes from './LogList.module.css';

class LogsList extends React.Component {
	render() {
		return (
			<div className="animated fadeIn">
				<Row>
					<DashboardCardContainerWithFlow>
						<EventListCard
							days={7}
							showGrid={true}
							className={classes.LogListView}
						/>
					</DashboardCardContainerWithFlow>
				</Row>
			</div>
		);
	}
}

export default withRouter(withTranslation()(LogsList));
