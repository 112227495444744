import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row } from 'reactstrap';

import DashboardCardWithStatistics from '../CardHandling/DashboardCardWithStatistics';
import DashboardCardContainerWithFlow from '../CardHandling/DashboardCardContainerWithFlow';
import { SystemStaticsDataType } from '../CardHandling/SystemStaticsDataType';

import IncomeCard from '../CardHandling/IncomeCard/IncomeCard';
import EventListCard from '../CardHandling/EventListCard/EventListCard';
import MapViewCard from '../CardHandling/MapViewCard/MapViewCard';
import NotificationCard from '../CardHandling/NotificationsCard/NotificationCard';

import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { Record } from 'immutable';

import classes from './TechnicanDashboard.module.css';

type Props = {
	t: TFunction,
	user: Record
};
type States = {
	vms: Array<Object>,
	users: Array<Object>
};

const mapStateToProps = state => ({
	user: state.user
});

class CustomerDashboard extends React.Component<Props, States> {
	constructor(props) {
		super(props);

		this.state = {
			showComponent: false
		};
	}

	componentDidMount() {}

	render() {
		const statRefreshInteval = 60000;
		const { t } = this.props;
		return (
			<div className="animated fadeIn">
				<Row>
					<DashboardCardContainerWithFlow>
						<div className={classes.TopLeftBlock}>
							<Row>
								{' '}
								<DashboardCardContainerWithFlow>
									<div className={classes.HeaderCard}>
										<DashboardCardWithStatistics
											className={classes.RootCard + ' bg-info'}
											subTitle={t('customerDashboard.onlineMachines.title')}
											iconClass="fa fa-plug"
											refreshInterval={statRefreshInteval}
											statisticsDataType={
												SystemStaticsDataType.technicanOnlineMachines
											}
											titleOnClick={() =>
												this.props.history.push('/vms?connectionState=1')
											}
										/>
									</div>

									<div className={classes.HeaderCard}>
										<DashboardCardWithStatistics
											className={classes.RootCard + ' bg-warning'}
											subTitle={t('customerDashboard.machineWithError.title')}
											iconClass="fa fa-exclamation-triangle"
											refreshInterval={statRefreshInteval}
											statisticsDataType={
												SystemStaticsDataType.technicanMachineWithError
											}
											titleOnClick={() =>
												this.props.history.push('/vms?machinesWithErrors=1')
											}
										/>
									</div>
								</DashboardCardContainerWithFlow>
							</Row>
							<Row>
								<MapViewCard className={classes.MachineMapCard} />
							</Row>
						</div>
						<NotificationCard className={classes.NotificationCard} />
						<EventListCard days={31} className={classes.EventListCard} />
					</DashboardCardContainerWithFlow>
				</Row>
			</div>
		);
	}
}

export default connect(mapStateToProps)(
	withRouter(withTranslation()(CustomerDashboard))
);
