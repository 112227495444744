// @flow
import io from 'socket.io-client';
import logger from '../logger';
import api from './api';

const websocketLogger = logger.child({ category: 'websocket' });

const socket = io(process.env.REACT_APP_REALTIME_BASE_URL, {
	path: '/'
});

type Handler = (Object, Object) => void | Promise<mixed>;
export type Subscription = {
	unsubscribe: () => void
};

function subscribeRoom(room, isJSON = true) {
	return (handler: Handler): Subscription => {
		const callback = (data, params) => {
			try {
				const jsonData = JSON.parse(data);
				handler(isJSON ? jsonData : data, params);
			} catch (error) {
				handler(data, params);
			}
		};

		socket.emit('subscribe', room, api.getToken());
		socket.on(room, callback);
		websocketLogger.debug(`Subscribe ${room}`);

		return {
			unsubscribe: () => {
				socket.emit('unsubscribe', room);
				socket.off(room, callback);
				websocketLogger.debug(`Unsubscribe ${room}`);
			}
		};
	};
}

function send(event, data) {
	websocketLogger.debug(`Send event (${event}): ${JSON.stringify(data)}`);
	socket.emit(event, data);
}

export default {
	remoteCommands: {
		takeScreenshot: 'takeScreenshot',
		setInfoText: 'setInfoText',
		setLayout: 'setLayout',
		getLogFile: 'getLogFile',
		startDebug: 'startDebug',
		stopDebug: 'stopDebug',
		rebootRunner: 'reboot'
	},

	subscribeKeepAlive: subscribeRoom('keepAlive'),
	subscribeStatusReport: subscribeRoom('statusReports'),
	subscribeProductInfo: subscribeRoom('productInfo'),
	subscribeStatusReportForVM: (hardwareID: string, handler: Handler) =>
		subscribeRoom(`machine/${hardwareID}/statusReport`, false)(handler),
	subscribeSale: (hardwareID: string, handler: Handler) =>
		subscribeRoom(`machine/${hardwareID}/sale`, false)(handler),
	subscribeProductScanForVM: (hardwareID: string, handler: Handler) =>
		subscribeRoom(`machine/${hardwareID}/productScan`)(handler),
	subscribeProductUpdateForVM: (hardwareID: string, handler: Handler) =>
		subscribeRoom(`machine/${hardwareID}/productReset`, false)(handler),
	subscribeProductPriceResponseForVM: (hardwareID: string, handler: Handler) =>
		subscribeRoom(`machine/${hardwareID}/priceAck`)(handler),
	subscribeProductPromoResponseForVM: (hardwareID: string, handler: Handler) =>
		subscribeRoom(`machine/${hardwareID}/promoAck`)(handler),
	subscribeProductInfoForVM: (hardwareID: string, handler: Handler) =>
		subscribeRoom(`machine/${hardwareID}/productInfo`)(handler),
	subscribeLCDForVM: (hardwareID: string, handler: Handler) =>
		subscribeRoom(`machine/${hardwareID}/lcd`, false)(handler),
	subscribeLCDTimeoutForVM: (hardwareID: string, handler: Handler) =>
		subscribeRoom(`machine/${hardwareID}/lcdTimeout`, false)(handler),
	subscribeLayoutRefresh: (hardwareID: string, handler: Handler) =>
		subscribeRoom(`machine/${hardwareID}/layoutRefresh`)(handler),
	subscribeCommandResult: (hardwareID: string, handler: Handler) =>
		subscribeRoom(`machine/${hardwareID}/commandResult`)(handler),

	turnOnLCD: (hardwareID: string) =>
		send('lcdStatus', {
			hardwareID: hardwareID,
			jwtToken: api.getToken(),
			status: 'on'
		}),
	turnOffLCD: (hardwareID: string) =>
		send('lcdStatus', {
			hardwareID: hardwareID,
			jwtToken: api.getToken(),
			status: 'off'
		}),
	turnOnRemoteControl: (hardwareID: string) => {
		send('lcdRemote', {
			hardwareID: hardwareID,
			jwtToken: api.getToken(),
			status: 'on'
		});
	},
	turnOffRemoteControl: (hardwareID: string) =>
		send('lcdRemote', {
			hardwareID: hardwareID,
			jwtToken: api.getToken(),
			status: 'off'
		}),
	pressButton: (hardwareID: string, buttonId: string, slavePrefix: string) => {
		send('buttonPress', {
			hardwareID: hardwareID,
			jwtToken: api.getToken(),
			buttonId,
			slavePrefix
		});
	},
	sendCommand: (hardwareID: string, command, parameter) => {
		send('remoteCommand', {
			hardwareID: hardwareID,
			jwtToken: api.getToken(),
			commandString: command,
			parameter
		});
	},
	setPromotion: (hardwareID: string, buttonId: string, promotion: Object) =>
		send('setPromotion', {
			hardwareID: hardwareID,
			jwtToken: api.getToken(),
			buttonId,
			promotion
		}),
	updateProductList: (hardwareID: string) =>
		send('updateProductList', {
			hardwareID: hardwareID,
			jwtToken: api.getToken()
		}),
	updateProductPrice: (
		hardwareID: string,
		buttonId: string,
		price: number,
		slavePattern: ?string
	) =>
		send('updateProductPrice', {
			hardwareID: hardwareID,
			jwtToken: api.getToken(),
			buttonId,
			price,
			slavePattern
		}),

	updateMachineName: (hardwareID: string, machineName: string) => {
		send('updateMachineName', { hardwareID, machineName });
	}
};
