import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import DatePicker from 'react-datepicker';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import qs from 'qs';
import axios from '../../../services/api/api';

import MachineSelectorDialog from '../../MachineSelectorDialog/MachineSelectorDialog';

import classes from './SalesFilter.module.css';

class SalesFilter extends Component {
	state = {};

	CT_MACHINE_INCOME_PIE = 1;
	CT_PRODUCT_COUNT_OF_SALES_PIE = 2;
	CT_PRODUCT_INCOME_PIE = 3;
	CT_STATE_CHANGES = 4;
	CT_DAILY_INCOME = 5;
	CT_HOURLY_INCOME = 6;

	charTypes = [
		{ id: this.CT_MACHINE_INCOME_PIE, name: 'Machine Income Pie' },
		{
			id: this.CT_PRODUCT_COUNT_OF_SALES_PIE,
			name: 'Product Count of Sales Pie'
		},
		{ id: this.CT_PRODUCT_INCOME_PIE, name: 'Product Income Pie' },
		{ id: this.CT_STATE_CHANGES, name: 'State Changes' },
		{ id: this.CT_DAILY_INCOME, name: 'Daily Income' },
		{ id: this.CT_HOURLY_INCOME, name: 'Hourly Income' }
	];

	componentDidMount() {
		/*const toDate = new Date();
		const fromDate = new Date();
		fromDate.setDate(fromDate.getDate() - 30);

		this.setState(
			{
				toDate,
				fromDate,
				chartType: this.CT_MACHINE_INCOME_PIE
			},
			() => this.handleFilterChange(this.props.selectedVendingMachines)
		);*/
	}

	onCartTypeChange(e) {
		const newChartType = e.target.value;

		let fromDate = this.props.fromDate;
		// We should not load so many date for hourly statistics, so we decrease the period
		if (newChartType == this.CT_HOURLY_INCOME) {
			fromDate = new Date(this.props.toDate);
			fromDate.setDate(fromDate.getDate() - 7);
		}

		this.handleFilterChange(
			newChartType,
			fromDate,
			this.props.toDate,
			this.props.selectedVendingMachines
		);
	}

	onFromDateChange(date) {
		let newDate = date;
		const oneWeekDate = new Date(this.props.toDate);
		oneWeekDate.setDate(oneWeekDate.getDate() - 7);

		if (
			this.props.chartType == this.CT_HOURLY_INCOME &&
			newDate < oneWeekDate
		) {
			newDate = oneWeekDate;
		}

		this.handleFilterChange(
			this.props.chartType,
			newDate,
			this.props.toDate,
			this.props.selectedVendingMachines
		);
	}

	onToDateChange(date) {
		let newFromDate = this.props.fromDate;
		const oneWeekDate = new Date(date);
		oneWeekDate.setDate(oneWeekDate.getDate() - 7);
		if (
			this.props.chartType == this.CT_HOURLY_INCOME &&
			newFromDate < oneWeekDate
		) {
			newFromDate = oneWeekDate;
		}

		this.handleFilterChange(
			this.props.chartType,
			newFromDate,
			date,
			this.props.selectedVendingMachines
		);
	}

	getDownloadURL() {
		const token = axios.getToken();
		const apiURL = process.env.REACT_APP_API_BASE_URL;

		const machineIDList = this.props.selectedVendingMachines.map(
			vendingMachine => vendingMachine.vmID
		);

		let subURLPart = '';

		switch (String(this.props.chartType)) {
			case String(this.CT_MACHINE_INCOME_PIE):
				subURLPart = 'machineIncomePercents';
				break;
			case String(this.CT_PRODUCT_INCOME_PIE):
				subURLPart = 'productIncomePercents';
				break;
			case String(this.CT_PRODUCT_COUNT_OF_SALES_PIE):
				subURLPart = 'productCountPercents';
				break;
			case String(this.CT_STATE_CHANGES):
				subURLPart = 'machineStateChanges';
				break;
			case String(this.CT_DAILY_INCOME):
				subURLPart = 'machineDailyIncomes';
				break;
			case String(this.CT_HOURLY_INCOME):
				subURLPart = 'machineHourlyIncomes';
				break;
			default:
				break;
		}

		const filterParams = {
			vendingMachines: machineIDList,
			fromDate: this.props.fromDate,
			toDate: this.props.toDate,
			download: true,
			token
		};
		const query = qs.stringify(filterParams, { addQueryPrefix: true });

		return `${apiURL}/statistics/${subURLPart}${query}`;
	}

	renderChartTypes() {
		const options = this.charTypes.map(charType => {
			return (
				<option key={charType.id} value={charType.id}>
					{charType.name}
				</option>
			);
		});

		return (
			<Form.Control
				as="select"
				onChange={e => this.onCartTypeChange(e)}
				value={this.props.chartType}
			>
				{options}
			</Form.Control>
		);
	}

	renderSelectedMachines() {
		return this.props.selectedVendingMachines.map(selectedMachine => {
			return (
				<ListGroup.Item
					key={selectedMachine.vmID}
					className={classes.MachineItem}
					onClick={() => this.removeSelection(selectedMachine)}
				>
					<div className={classes.CursorPointer}>
						<i className="fa fa-minus" aria-hidden="true"></i>&nbsp;
						{selectedMachine.customName}
					</div>
				</ListGroup.Item>
			);
		});
	}

	hideMachineSelector() {
		this.setState({ showMachineSelector: false });
	}

	showMachineSelector() {
		this.setState({ showMachineSelector: true });
	}

	handleFilterChange(chartType, fromDate, toDate, selectedVendingMachines) {
		const newFilterSettings = {};
		newFilterSettings.chartType = chartType;
		newFilterSettings.fromDate = fromDate;
		newFilterSettings.toDate = toDate;
		newFilterSettings.machines = selectedVendingMachines;
		this.props.onFilterChange(newFilterSettings);
	}

	clearSelection() {
		this.handleFilterChange(
			this.props.chartType,
			this.props.fromDate,
			this.props.toDate,
			[]
		);
	}

	getSelectableVendingMachines() {
		return this.props.allVendingMachines.filter(vendingMachine => {
			return !this.props.selectedVendingMachines.find(
				selectedVendingMachine =>
					selectedVendingMachine.name == vendingMachine.name
			);
		});
	}

	addSelection(newSelection) {
		this.hideMachineSelector();

		const newList = [...this.props.selectedVendingMachines];
		newList.push(...newSelection);
		this.handleFilterChange(
			this.props.chartType,
			this.props.fromDate,
			this.props.toDate,
			newList
		);
	}

	removeSelection(machineToRemove) {
		const newList = this.props.selectedVendingMachines.filter(
			machine => machine.name != machineToRemove.name
		);
		this.handleFilterChange(
			this.props.chartType,
			this.props.fromDate,
			this.props.toDate,
			newList
		);
	}

	renderMachineSelectorDialog() {
		return (
			<Modal
				show={this.state.showMachineSelector}
				onHide={() => this.hideMachineSelector()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i
							className="fa fa-cog"
							aria-hidden="true"
							style={{ marginRight: '10px' }}
						></i>
						{this.props.t('salesList.machineSelector')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: '0px' }}>
					<MachineSelectorDialog
						allVendingMachines={this.getSelectableVendingMachines()}
						onSelected={newSelection => this.addSelection(newSelection)}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	render() {
		return (
			<>
				{this.renderMachineSelectorDialog()}
				<Card>
					<Card.Body>
						<Card.Title className="mb-0 text-muted">
							<i className="icon-equalizer" style={{ marginRight: '10px' }}></i>
							{this.props.t('salesList.filterSettings')}
						</Card.Title>

						<Row className={classes.row}>
							<Col md="1" className={classes.col + ' ' + classes.leftCol}>
								{this.props.t('salesList.chartType')}
							</Col>
							<Col md="11">{this.renderChartTypes()}</Col>
						</Row>

						<Row className={classes.row}>
							<Col md="1" className={classes.col + ' ' + classes.leftCol}>
								{this.props.t('salesList.machines')}
							</Col>
							<Col md="9">
								<div className={classes.MachinesDiv}>
									<ListGroup horizontal className={classes.MachineList}>
										{this.renderSelectedMachines()}
									</ListGroup>
								</div>
							</Col>
							<Col md="2" className={classes.col}>
								<Button
									variant="success"
									className={classes.AddButton}
									onClick={() => this.showMachineSelector()}
									disabled={
										this.props.allVendingMachines.length ==
										this.props.selectedVendingMachines.length
									}
								>
									<i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
									{this.props.t('salesList.add')}
								</Button>

								<Button
									variant="primary"
									className={classes.AddButton}
									onClick={() => this.clearSelection()}
								>
									<i className="fa fa-minus" aria-hidden="true"></i>&nbsp;
									{this.props.t('salesList.clear')}
								</Button>
							</Col>
						</Row>

						<Row className={classes.row}>
							<Col md="1" className={classes.col + ' ' + classes.leftCol}>
								{this.props.t('salesList.fromDate')}
							</Col>
							<Col md="5">
								<DatePicker
									className={classes.DatePicker}
									selected={this.props.fromDate}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="time"
									dateFormat="yyyy MMMM d h:mm aa"
									onChange={date => this.onFromDateChange(date)}
								/>
							</Col>
							<Col md="1" className={classes.col + ' ' + classes.leftCol}>
								{this.props.t('salesList.toDate')}
							</Col>
							<Col md="5">
								<DatePicker
									className={classes.DatePicker}
									selected={this.props.toDate}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="time"
									dateFormat="yyyy MMMM d h:mm aa"
									onChange={date => this.onToDateChange(date)}
								/>
							</Col>
						</Row>
						<Row className={classes.row}>
							<Col md="10"> </Col>
							<Col md="2">
								<Button
									variant="success"
									className={classes.DownloadButton}
									type="submit"
									disabled={this.props.selectedVendingMachines.length == 0}
									href={this.getDownloadURL()}
									download="VendingMachineStatistics"
								>
									<i className="fa fa-download" aria-hidden="true"></i>&nbsp;
									{this.props.t('salesList.downloadData')}
								</Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</>
		);
	}
}

const mapStateToProps = state => ({
	usePrivilege: state.user
});

export default withRouter(
	connect(mapStateToProps)(withTranslation()(SalesFilter))
);
