import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-dates/initialize';
import classes from './SalesAndIncomeTable.module.css';
import { Badge, Row, Col } from 'reactstrap';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import SalesAndIncomeUtil from '../../../services/util/salesAndIncomeUtils';
import SummaryPanel from './SummaryPanel';
import TableToolBar from './TableToolBar';
import { Button } from 'reactstrap';
import ReactExport from '@ibrahimrahmani/react-export-excel';

import { connect } from 'react-redux';
import store from '../../../store';
import { setTableData } from '../../../store/table';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const filterParams = {
	DEVICE: 1,
	POSITION: 2,
	PRODUCT: 3,
	TRANSACTION: 4,
	PAYMENT: 5,
	PRICE: 6,
	INCOME: 7,
	EMAIL: 8,
	SELECTIONMETHOD: 9
};

class SalesAndIncomeTable extends Component {
	state = {
		startDate: null,
		endDate: null
	};

	componentDidMount = () => {
		store.dispatch(setTableData(JSON.stringify(this.props.data)));

		SalesAndIncomeUtil.setParamToStore(this.props.data);
		SalesAndIncomeUtil.getLocalStorage();
	};

	getDetailsSumLabel = param => {
		return <Form.Label id={param}></Form.Label>;
	};

	changeDates = val => {
		this.setState({ startDate: val.startDate, endDate: val.endDate });
	};

	renderPaginationPanel = props => {
		return (
			<div className="col">
				<div className="px-3">{props.components.pageList}</div>
			</div>
		);
	};

	dateFormatter = cell => {
		return moment(cell).format('YYYY. MM. DD. HH:mm:ss');
	};

	positionFormatter = (cell, row) => {
		let data = '';
		if (row.buttonID) {
			data = `${row.buttonID} ${row.slavePattern ? row.slavePattern : ''}`;
		} else {
			data = row.itemNumber;
		}
		return data;
	};

	priceFormatter = (cell, row) => {
		let data = '';
		if (cell) {
			data = `${cell.toFixed(2)} ${row.currency}`;
		}

		return data;
	};

	transactionTypeFormater = cell => {
		let data = '';
		switch (cell) {
			case SalesAndIncomeUtil.paymentTransactionTypes.PAYMENT:
				data = this.props.t('salesSnadIncome.table.devicePurchase');
				break;

			case SalesAndIncomeUtil.paymentTransactionTypes
				.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE:
				data = this.props.t('salesSnadIncome.table.topUp');
				break;
		}

		return data;
	};

	cashFormater = (cell, row) => {
		let data = '';
		switch (row.paymentMethod) {
			case SalesAndIncomeUtil.creditSourceTypes.MACHINE_CASH_PAYMENT:
				data = this.props.t('salesSnadIncome.table.cash');
				break;
			case SalesAndIncomeUtil.creditSourceTypes.MACHINE_CARD_PAYMENT:
				data = this.props.t('salesSnadIncome.table.cashless');
				break;

			case SalesAndIncomeUtil.creditSourceTypes.INV_TOUCH_PAYMENT:
				data = this.props.t('salesSnadIncome.table.wallet');
				break;
		}
		if (
			row.transactionType ===
			SalesAndIncomeUtil.paymentTransactionTypes
				.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE
		) {
			data = this.props.t('salesSnadIncome.table.cash');
		}
		if (
			row.transactionType ===
			SalesAndIncomeUtil.paymentTransactionTypes.BARION_CREDIT_ENTRY
		) {
			data = this.props.t('salesSnadIncome.table.cashless');
		}
		return data;
	};

	paymentMethodFormatter = (cell, row) => {
		let data = '';
		switch (row.paymentMethod) {
			case 0:
				data = this.props.t('salesSnadIncome.table.paymentMethods.lcd');
				break;
			case 1:
				data = this.props.t('salesSnadIncome.table.paymentMethods.exe');
				break;
			case 2:
				data = this.props.t('salesSnadIncome.table.paymentMethods.cash');
				break;
			case 3:
				data = this.props.t('salesSnadIncome.table.paymentMethods.cd1');
				data = row.paymentDetail ? `${data} (${row.paymentDetail})` : data;
				break;
			case 4:
				data = this.props.t('salesSnadIncome.table.paymentMethods.cd2');
				data = row.paymentDetail ? `${data} (${row.paymentDetail})` : data;
				break;
			case 5:
				data = this.props.t('salesSnadIncome.table.paymentMethods.wallet');
				break;
			case 6:
				data = this.props.t('salesSnadIncome.table.paymentMethods.cd');
				break;
		}
		return data;
	};

	selectionModeFormatter = (cell, row) => {
		let data = '';
		switch (row.selectionMethod) {
			case 0:
				data = this.props.t('salesSnadIncome.table.selectionModes.machine');
				break;
			case 1:
				data = this.props.t('salesSnadIncome.table.selectionModes.app');
				break;
		}

		return data;
	};

	salesPriceSum = filteredSales => {
		let total = 0;
		let price = filteredSales.map(s => ({
			key: s.id,
			price: s.price
		}));

		for (let i = 0; i < price.length; i++) {
			total = total + price[i].price;
		}

		return total.toFixed(2);
	};
	rowClassNameFormat = row => {
		let className = '';
		if (!row.purchaseFailed) {
			switch (row.transactionType) {
				case SalesAndIncomeUtil.paymentTransactionTypes.PAYMENT:
					className = classes.ManualRow;
					break;
				case SalesAndIncomeUtil.paymentTransactionTypes
					.PAYMENT_TROUGH_INVISIBLE_TOUCH:
					className = classes.MobilRow;
					break;
				case SalesAndIncomeUtil.paymentTransactionTypes.BARION_CREDIT_ENTRY:
					className = classes.CreditRow;
					break;
				case SalesAndIncomeUtil.paymentTransactionTypes
					.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE:
					className = classes.CreditRow;
					break;
			}
		} else {
			className = classes.FailedTransaction;
		}
		return className;
	};

	transactionTypeFilterValue = (cell, row) => {
		return row.transactionType;
	};

	paymentMethodFilterValue = (cell, row) => {
		return row.paymentMethod;
	};

	positionFilterValue = (cell, row) => {
		let value = [];
		value.push(row.buttonID);
		return value;
	};

	emailFilterValue = (cell, row) => {
		let value = [];
		value.push(row.customerEmail);
		return value;
	};

	afterColumnFilter = (filterConds, result) => {
		store.dispatch(setTableData(JSON.stringify(result)));

		SalesAndIncomeUtil.setParamToStore(result);
		SalesAndIncomeUtil.getLocalStorage();
	};

	formatExcel = v => {
		let value = [];
		if (v) {
			value = v;
		}
		const { t, filterParamSet } = this.props;
		const header = [
			{
				value: t('device.details.sales.datetime'),
				widthPx: 160,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('salesSnadIncome.table.device'),
				widthPx: 180,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('device.details.sales.position'),
				widthPx: 50,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('device.details.sales.product'),
				widthPx: 180,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('salesSnadIncome.table.transactionType'),
				widthPx: 100,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('device.details.sales.method'),
				widthPx: 100,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('device.details.sales.price'),
				widthPx: 80,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('salesSnadIncome.table.income'),
				widthPx: 80,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('salesSnadIncome.table.turnover'),
				widthPx: 80,
				style: { font: { sz: '24', bold: true } }
			}
		];
		const val = value.map(e => {
			let date = e.createdAt ? this.dateFormatter(e.createdAt) : '';
			let position = e.buttonID ? e.buttonID : '';
			let product = e.productName ? e.productName : '';
			let transaction = e.transactionType
				? this.transactionTypeFormater(e.transactionType)
				: '';
			let method = e ? this.cashFormater(null, e) : '';
			let income = e.income ? this.priceFormatter(e.income, e) : '';
			let price = e.price ? this.priceFormatter(e.price, e) : '';
			let turnover = e.price ? this.priceFormatter(e.turnover, e) : '';
			let el = [
				{ value: date },
				{ value: e.vmName },
				{ value: position },
				{ value: product },
				{ value: transaction },
				{ value: method },
				{ value: price },
				{ value: income },
				{ value: turnover }
			];
			return el;
		});
		const styledMultiDataSet = [
			{
				columns: header,
				data: val
			}
		];
		return (
			<div>
				<ExcelFile
					element={
						<Button className={classes.DefaultFilterButton}>
							<i className=" fa fa-file mr-2"></i>
							{t('salesSnadIncome.exportToXLS')}
						</Button>
					}
				>
					<ExcelSheet
						dataSet={styledMultiDataSet}
						name={t('salesSnadIncome.title')}
					/>
				</ExcelFile>
			</div>
		);
	};

	renderExcelExportButton = () => {
		let dataSet = [];
		if (JSON.parse(this.props.table)) {
			dataSet = JSON.parse(this.props.table);
		}

		return this.formatExcel(dataSet);
	};

	createCustomToolBar = props => {
		const getCSVBtn = () => {
			return (
				<Button
					className={classes.DefaultFilterButton}
					onClick={() =>
						props.components.btnGroup.props.children[0].props.onClick()
					}
				>
					<i className="fa fa-download mr-2" />
					{this.props.t('salesSnadIncome.exportToCSV')}
				</Button>
			);
		};

		return (
			<>
				<TableToolBar
					startDate={this.props.startDate}
					endDate={this.props.endDate}
					btnGroup={getCSVBtn()}
					onInputChange={this.props.onDateChange}
					excelExportButton={this.renderExcelExportButton}
					loadButton={this.props.loadButton}
				/>
			</>
		);
	};

	getProductOptions = () => {
		const distinct = (value, index, self) => {
			return self.indexOf(value) === index;
		};
		let product = this.props.data
			.map(e => {
				return e.productName;
			})
			.filter(e => e !== null)
			.filter(distinct);
		let productOp = Object.assign({}, product);
		let productOptions = Object.assign(productOp, product);
		return productOptions;
	};

	renderHeaderFilter = (title, value, param) => {
		let noVisibleElements = null;
		noVisibleElements = this.props.elementVisibilities.find(
			e => e.param === param
		);
		return (
			<Row style={{ alignItmes: 'center', justifyContent: 'center' }}>
				<Badge
					className={
						noVisibleElements
							? classes.ActiveFilterButton
							: classes.FilterButton
					}
					onClick={value}
				>
					<i className="fa fa-search" style={{ margin: '3px' }} />
				</Badge>
				{title}
			</Row>
		);
	};

	resetFilter = () => {
		this.props.onResetFilter();
	};

	renderResetFilterButton = (title, value) => {
		return (
			<Row
				style={{
					alignItmes: 'center',
					justifyContent: 'center'
				}}
			>
				<div className={classes.FilterButton} onClick={value}>
					<span
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItmes: 'center',
							justifyContent: 'center',
							height: '70px',
							width: '60px'
						}}
					>
						{title}
						<i className="fa fa-search" style={{ marginTop: '5px' }} />
					</span>
				</div>
			</Row>
		);
	};

	idFormater = () => {
		return ' ';
	};

	//Render
	render() {
		const { t } = this.props;
		//Table options
		const tableOptions = {
			sortIndicator: true,
			hideSizePerPage: true,
			sizePerPage: 10,
			withFirstAndLast: true,
			alwaysShowAllBtns: true,
			pageStartIndex: 1,
			paginationPosition: 'bottom',
			paginationSize: 3,
			defaultSortName: 'createdAt',
			defaultSortOrder: 'desc',
			toolBar: this.createCustomToolBar,
			afterColumnFilter: this.afterColumnFilter
		};
		const textFilterOptions = {
			type: 'TextFilter',
			placeholder: t('filterToolbar.filterPlaceholder'),
			style: {
				marginTop: '20px',
				borderRadius: '5px',
				borderColor: 'var(--control-mid)'
			}
		};
		const priceFilterOptions = {
			type: 'TextFilter',
			placeholder: t('filterToolbar.filterPlaceholder'),
			condition: 'eq',
			style: {
				marginTop: '20px',
				borderRadius: '5px',
				borderColor: 'var(--control-mid)'
			}
		};

		const transactionTypeOptions = {};
		transactionTypeOptions[
			SalesAndIncomeUtil.paymentTransactionTypes.PAYMENT
		] = this.props.t('salesSnadIncome.table.devicePurchase');
		transactionTypeOptions[
			SalesAndIncomeUtil.paymentTransactionTypes.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE
		] = this.props.t('salesSnadIncome.table.topUp');

		const transactionTypeFilterOptions = {
			placeholder: t('filterToolbar.filterPlaceholder'),
			type: 'SelectFilter',
			options: transactionTypeOptions,
			style: {
				marginTop: '20px',
				borderRadius: '5px',
				borderColor: 'var(--control-mid)'
			}
		};

		const paymentMethodOptions = {
			0: this.props.t('salesSnadIncome.table.paymentMethods.lcd'),
			1: this.props.t('salesSnadIncome.table.paymentMethods.exe'),
			2: this.props.t('salesSnadIncome.table.paymentMethods.cash'),
			3: this.props.t('salesSnadIncome.table.paymentMethods.cd1'),
			4: this.props.t('salesSnadIncome.table.paymentMethods.cd2'),
			5: this.props.t('salesSnadIncome.table.paymentMethods.wallet'),
			6: this.props.t('salesSnadIncome.table.paymentMethods.cd')
		};

		const selectionMethodOptions = {
			0: this.props.t('salesSnadIncome.table.selectionModes.machine'),
			1: this.props.t('salesSnadIncome.table.selectionModes.app')
		};

		const paymentMethodFilterOptions = {
			placeholder: t('filterToolbar.filterPlaceholder'),
			type: 'SelectFilter',
			options: paymentMethodOptions,
			style: {
				marginTop: '20px',
				borderRadius: '5px',
				borderColor: 'var(--control-mid)'
			}
		};

		const selectionMethodFilterOptions = {
			placeholder: t('filterToolbar.filterPlaceholder'),
			type: 'SelectFilter',
			options: selectionMethodOptions,
			style: {
				marginTop: '20px',
				borderRadius: '5px',
				borderColor: 'var(--control-mid)'
			}
		};

		return (
			<>
				<BootstrapTable
					data={this.props.data}
					version="4"
					pagination
					options={tableOptions}
					bordered={false}
					condensed
					size="sm"
					trClassName={this.rowClassNameFormat}
					tableHeaderClass={classes.TableHeaderClass}
					headerContainerClass={classes.HeaderContainerClass}
					exportCSV
				>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.datetime')}
						csvFormat={this.dateFormatter}
						filter={textFilterOptions}
						width="100"
						dataAlign="center"
						dataField="createdAt"
						thStyle={{
							borderColor: 'transparent'
						}}
						dataFormat={this.dateFormatter}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{t('device.details.sales.datetime')}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('salesSnadIncome.table.device')}
						filter={textFilterOptions}
						width="200"
						dataAlign="center"
						dataField="vmName"
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{this.renderHeaderFilter(
							t('salesSnadIncome.table.device'),
							this.props.showDeviceFilterDialog,
							filterParams.DEVICE
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.position')}
						csvFormat={this.positionFormatter}
						filterValue={this.positionFilterValue}
						filter={textFilterOptions}
						dataAlign="center"
						width="80"
						dataField="buttonID"
						dataFormat={this.positionFormatter}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{this.renderHeaderFilter(
							t('device.details.sales.position'),
							this.props.showPositionFilterDialog,
							filterParams.POSITION
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.product')}
						filterValue={this.productFilterValue}
						filter={textFilterOptions}
						dataAlign="center"
						width="100"
						dataField="productName"
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{this.renderHeaderFilter(
							t('device.details.sales.product'),
							this.props.showProductFilterDialog,
							filterParams.PRODUCT
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={'Tranzakció Típusa'}
						csvFormat={this.transactionTypeFormater}
						filter={transactionTypeFilterOptions}
						filterValue={this.transactionTypeFilterValue}
						dataAlign="center"
						width="150"
						dataField="transactionType"
						thStyle={{
							borderColor: 'transparent'
						}}
						dataFormat={this.transactionTypeFormater}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{this.renderHeaderFilter(
							t('salesSnadIncome.table.transactionType'),
							this.props.showTransactionTypeFilterDialog,
							filterParams.TRANSACTION
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.method')}
						filterValue={this.paymentMethodFilterValue}
						csvFormat={this.paymentMethodFormatter}
						filter={paymentMethodFilterOptions}
						dataField="paymentMethod"
						dataAlign="center"
						thStyle={{
							borderColor: 'transparent'
						}}
						dataFormat={this.paymentMethodFormatter}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
						width="100"
					>
						{this.renderHeaderFilter(
							t('device.details.sales.method'),
							this.props.showPaymentMethodFilterDialog,
							filterParams.PAYMENT
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('salesSnadIncome.table.selectionMode')}
						filterValue={this.selectionMethodOptions}
						csvFormat={this.selectionModeFormatter}
						filter={selectionMethodFilterOptions}
						dataField="selectionMethod"
						dataAlign="center"
						thStyle={{
							borderColor: 'transparent'
						}}
						dataFormat={this.selectionModeFormatter}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
						width="100"
					>
						{this.renderHeaderFilter(
							t('salesSnadIncome.table.selectionMode'),
							this.props.showSelectionMethodFilterDialog,
							filterParams.SELECTIONMETHOD
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.email')}
						width="100"
						dataField="customerEmail"
						dataAlign="center"
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{ verticalAlign: 'middle' }}
						filterValue={this.emailFilterValue}
						filter={textFilterOptions}
					>
						{t('device.details.sales.email')}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.price')}
						csvFormat={this.priceFormatter}
						filter={priceFilterOptions}
						dataAlign="center"
						width="80"
						dataField="price"
						dataFormat={this.priceFormatter}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{t('device.details.sales.price')}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('salesSnadIncome.table.income')}
						csvFormat={this.priceFormatter}
						filter={priceFilterOptions}
						dataAlign="center"
						width="80"
						dataField="income"
						dataFormat={this.priceFormatter}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{t('salesSnadIncome.table.income')}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('salesSnadIncome.table.turnover')}
						csvFormat={this.priceFormatter}
						filter={priceFilterOptions}
						dataAlign="center"
						width="80"
						dataField="turnover"
						dataFormat={this.priceFormatter}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{t('salesSnadIncome.table.turnover')}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.id')}
						width="60"
						dataField="id"
						isKey
						dataFormat={this.idFormater}
						dataAlign="center"
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{ verticalAlign: 'middle' }}
					>
						{this.renderResetFilterButton(
							this.props.t('salesSnadIncome.reset'),
							this.resetFilter
						)}
					</TableHeaderColumn>
				</BootstrapTable>
				<SummaryPanel
					value={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.Value
					)}
					priceSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PRICE_SUM
					)}
					incomeSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.INCOME_SUM
					)}
					transactionSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.TRANSACTION_SUM
					)}
					failedTransactionSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.FAILED_TRANSACTIONS_SUM
					)}
					cashSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.CASH_SUM
					)}
					purchaseCashTrSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_CASH_TR_SUM
					)}
					purchaseCashlessTrSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_CASHLESS_TR_SUM
					)}
					purchaseWalletTrSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_WALLET_TR_SUM
					)}
					topUpCashTrSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.TOP_UP_CASH_TR_SUM
					)}
					topUpCashlessTrSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.TOP_UP_CASHLESS_TR_SUM
					)}
					purchaseCashIncomeSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_CASH_INCOME_SUM
					)}
					purchaseCashlessIncomeSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_CASHLESS_INCOME_SUM
					)}
					topUpCashIncomeSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.TOP_UP_CASH_INCOME_SUM
					)}
					topUpCashlessIncomeSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.TOP_UP_CASHLESS_INCOME_SUM
					)}
					purchaseCashPriceSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_CASH_PRICE_SUM
					)}
					purchaseCashlessPriceSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_CASHLESS_PRICE_SUM
					)}
					purchaseWalletPriceSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_WALLET_PRICE_SUM
					)}
					purchaseCashStock={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_CASH_STOCK
					)}
					topUpCashStock={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.TOP_UP_CASH_STOCK
					)}
					undefinedSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_UNDEFINED_SUM
					)}
					undefinedIncomeSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_UNDEFINED_INCOME_SUM
					)}
					undefinedPriceSum={this.getDetailsSumLabel(
						SalesAndIncomeUtil.summaryParameters.PURCHASE_UNDEFINED_PRICE_SUM
					)}
					measure={this.props.data[0] ? this.props.data[0].currency : ''}
				/>
			</>
		);
	}
}

function mapStateToProps(state) {
	const { table } = state;
	return { table };
}

export default connect(mapStateToProps)(withTranslation()(SalesAndIncomeTable));
