//@flow
import React from 'react';
import {
	Card,
	CardBody,
	CardSubtitle,
	CardText,
	CardTitle,
	Col,
	Row
} from 'reactstrap';
import { Line, Chart } from 'react-chartjs-2';
import statisticsAPI from '../../../services/api/statistics';
import customersAPI from '../../../services/api/customers';
import userAPI from '../../../services/api/users';
// import userUtil from '../../../services/util/userUtil';
import { SystemStaticsDataType } from './SystemStaticsDataType';

type Props = {
	subTitle: string | number,
	iconClass: string,
	statisticsDataType: String,
	footerComponent?: any,
	refreshInterval: any,
	titleOnClick?: Function
};

export default class DashboardCardWithStatistics extends React.Component<Props> {
	state = {
		labelValue: ''
	};

	mainChart = {
		labels: [],
		datasets: [
			{
				backgroundColor: 'rgba(255,255,255,0.2)',
				borderColor: 'rgba(255,255,255,0.55)',
				data: []
			}
		]
	};

	mainChartOpts = {
		tooltips: {
			callbacks: {
				label: tooltipItem => {
					return tooltipItem.yLabel;
				}
			},
			position: 'myPositioner'
		},
		plugins: {
			datalabels: {
				display: false
			}
		},
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		scales: {
			xAxes: [
				{
					display: false
				}
			],
			yAxes: [
				{
					display: false,
					ticks: {
						max: 0,
						stepSize: 1
					}
				}
			]
		},
		elements: {
			line: {
				tension: 0.01,
				borderWidth: 2
			},
			point: {
				radius: 0,
				hitRadius: 5,
				hoverRadius: 5
			}
		}
	};

	defaultVisibleTickCount = 30;

	pad(num, size) {
		let s = '000000000' + num;
		return s.substr(s.length - size);
	}

	getLabelFromDateWithTime(date) {
		return date.getHours() + ':' + this.pad(date.getMinutes(), 2);
	}

	getLabelFromDateWithDay(date) {
		return (
			(date.getMonth() + 1 + '').padStart(2, 0) +
			'. ' +
			(date.getDate() + '').padStart(2, 0)
		);
	}

	getLabelFromDateWithMonth(date) {
		return (
			date.getFullYear() + '. ' + (date.getMonth() + 1 + '').padStart(2, 0)
		);
	}

	checkExistingDay(data, date) {
		return !!data.find(item => {
			const itemDate = new Date(item.tickDateTime);
			return (
				itemDate.getFullYear() === date.getFullYear() &&
				itemDate.getMonth() === date.getMonth() &&
				itemDate.getDate() === date.getDate()
			);
		});
	}

	checkExistingMonth(data, date) {
		return !!data.find(item => {
			const itemDate = new Date(item.tickDateTime);
			return (
				itemDate.getFullYear() === date.getFullYear() &&
				itemDate.getMonth() === date.getMonth()
			);
		});
	}

	prepareChartData(data) {
		let chartData = [];
		let chartLabels = [];

		switch (this.props.statisticsDataType) {
			case SystemStaticsDataType.incomeOfDay:
				//We should have 31 days of data insert the missing days
				for (let index = 0; index < 31; index++) {
					let currentDate = new Date();
					currentDate.setDate(currentDate.getDate() - index);
					const dateExists = this.checkExistingDay(data, currentDate);
					if (!dateExists) {
						data.push({ tickDateTime: currentDate, tickValue: 0 });
					}
				}
				break;
			case SystemStaticsDataType.incomeOfMonth:
				//We should have 12 month of data insert the missing days
				for (let index = 0; index < 12; index++) {
					let currentDate = new Date();
					currentDate.setMonth(currentDate.getMonth() - index);
					const dateExists = this.checkExistingMonth(data, currentDate);
					if (!dateExists) {
						data.push({ tickDateTime: currentDate, tickValue: 0 });
					}
				}
				break;

			default:
				const finalDate = new Date();

				let missingDataCount = this.visibleDayCount - data.length;
				if (missingDataCount < 0) missingDataCount = 0;
				for (let i = 0; i < missingDataCount; i++) {
					chartData.push(0);

					let tempDate = new Date(
						finalDate.getTime() -
						(missingDataCount - 1 + i) * this.dataTimePeriod
					);
					const dateLabel = this.getLabelFromDateWithTime(tempDate);
					chartLabels.push(dateLabel);
				}
				break;
		}

		data = data.sort((a, b) => {
			return (
				new Date(a.tickDateTime).getTime() - new Date(b.tickDateTime).getTime()
			);
		});

		data.forEach(row => {
			chartData.push(row.tickValue);

			switch (this.props.statisticsDataType) {
				case SystemStaticsDataType.incomeOfDay:
					chartLabels.push(
						this.props.subTitle +
						': ' +
						this.getLabelFromDateWithDay(new Date(row.tickDateTime))
					);
					break;
				case SystemStaticsDataType.incomeOfMonth:
					chartLabels.push(
						this.props.subTitle +
						': ' +
						this.getLabelFromDateWithMonth(new Date(row.tickDateTime))
					);
					break;
				default:
					if (this.props.dayBased) {
						chartLabels.push(
							this.props.subTitle +
							': ' +
							this.getLabelFromDateWithDay(new Date(row.tickDateTime))
						);
					} else {
						chartLabels.push(
							this.props.subTitle +
							': ' +
							this.getLabelFromDateWithTime(new Date(row.tickDateTime))
						);
					}
					break;
			}
		});

		this.mainChart.datasets[0].data = chartData;
		this.mainChart.labels = chartLabels;

		const maxValue =
			chartData.length > 0
				? chartData.reduce((prev, current) => (current > prev ? current : prev))
				: 0;
		this.mainChartOpts.scales.yAxes[0].ticks.max = maxValue;
		this.mainChartOpts.scales.yAxes[0].ticks.stepSize = Math.min([
			1,
			Math.round(maxValue / 5)
		]);

		if (this.reference && this.reference.chartInstance) {
			this.setState({
				labelValue: chartData.length > 0 ? chartData[chartData.length - 1] : 0
			});

			Chart.Tooltip.positioners.myPositioner = (unused, position) => {
				return { x: position.x, y: 20 };
			};

			this.reference.chartInstance.update();
		}
	}

	refreshChartData() {
		if (
			document.visibilityState === 'visible' ||
			document.visibilityState === 'prerender'
		) {
			switch (this.props.statisticsDataType) {
				case SystemStaticsDataType.partnerCount:
				case SystemStaticsDataType.machineCount:
				case SystemStaticsDataType.liveMachineCount:
				case SystemStaticsDataType.machineWithErrorsCount:
				case SystemStaticsDataType.freeDeviceCount:
					statisticsAPI
						.getSystemData(
							this.props.statisticsDataType,
							this.defaultVisibleTickCount,
							this.props.dayBased
						)
						.then(resp => {
							this.prepareChartData(resp.data.response);
						});
					break;
				case SystemStaticsDataType.incomeOfDay:
					customersAPI.getDailyIncome().then(resp => {
						this.prepareChartData(resp.data.response);
					});
					break;
				case SystemStaticsDataType.incomeOfMonth:
					customersAPI.getMonthlyIncome().then(resp => {
						this.prepareChartData(resp.data.response);
					});
					break;
				case SystemStaticsDataType.customerOnlineMachines:
					customersAPI
						.getStatistics(
							SystemStaticsDataType.liveMachineCount,
							this.defaultVisibleTickCount
						)
						.then(resp => this.prepareChartData(resp.data.response));
					break;
				case SystemStaticsDataType.customerMachineWithError:
					customersAPI
						.getStatistics(
							SystemStaticsDataType.machineWithErrorsCount,
							this.defaultVisibleTickCount
						)
						.then(resp => this.prepareChartData(resp.data.response));
					break;
				case SystemStaticsDataType.technicanOnlineMachines:
					userAPI
						.getStatistics(
							SystemStaticsDataType.liveMachineCount,
							this.defaultVisibleTickCount
						)
						.then(resp => this.prepareChartData(resp.data.response));
					break;
				case SystemStaticsDataType.technicanMachineWithError:
					userAPI
						.getStatistics(
							SystemStaticsDataType.machineWithErrorsCount,
							this.defaultVisibleTickCount
						)
						.then(resp => this.prepareChartData(resp.data.response));
					break;
				default:
					break;
			}
		}
	}

	interval = null;
	componentDidMount() {
		if (this.props.visibleDayCount) {
			this.visibleDayCount = this.props.visibleDayCount;
		}
		if (this.props.dataTimePeriod) {
			this.dataTimePeriod = this.props.dataTimePeriod;
		} else {
			this.dataTimePeriod = 1;
		}

		if (this.props.statisticsDataType) {
			this.refreshChartData();

			const defaultInterval = 24 * 60 * 60 * 1000;
			this.interval = setInterval(
				() => this.refreshChartData(),
				this.props.refreshInterval || defaultInterval
			);
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	renderChart() {
		if (this.props.statisticsDataType) {
			return (
				<Line
					data={this.mainChart}
					options={this.mainChartOpts}
					ToolTip={this.toolTip}
					height={70}
					ref={reference => (this.reference = reference)}
				/>
			);
		} else return null;
	}

	render() {
		const {
			subTitle = '',
			iconClass = 'fa fa-user',
			titleOnClick
		} = this.props;
		return (
			<Card className={this.props.className} style={this.props.style}>
				<CardBody>
					<Row>
						<Col>
							<CardTitle
								onClick={titleOnClick}
								className="text-muted text-light"
								style={{ cursor: titleOnClick ? 'pointer' : 'default' }}
							>
								<i
									className={`${iconClass} fa-lg text-light`}
									style={{ marginRight: '10px' }}
								/>
								<strong className="text-light">{subTitle}</strong>
								<strong className="text-light" style={{ float: 'right' }}>
									{this.state.labelValue}
								</strong>
							</CardTitle>
						</Col>
					</Row>
					<CardSubtitle
						onClick={titleOnClick}
						style={{
							fontSize: '1.75rem',
							paddingLeft: '40px'
						}}
					></CardSubtitle>
					<CardText
						onClick={titleOnClick}
						style={{ margin: '-20px', marginTop: '10px' }}
						className="text-muted text-bold text-light"
					>
						{this.renderChart()}
					</CardText>
				</CardBody>
			</Card>
		);
	}
}
