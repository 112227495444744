import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import HardwareRiport from '../../views/HardwareRiport/HardwareRiport';

class NewIncomeRoute extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route
						path="/hardware_report"
						name="HardwareRiport"
						component={HardwareRiport}
					/>
					<Redirect from="/" to="/hardware_report" />
				</Switch>
			</div>
		);
	}
}

export default NewIncomeRoute;
