//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import classes from './DashboardCardContainerWithFlow.module.css';

const DashboardCardContainer = ({
	children,
	history,
	to = null,
	...rest
}: any) => {
	//	const navigate = () => history.push(to);
	return <div className={classes.CardContainer}>{children}</div>;
};

export default withRouter(DashboardCardContainer);

/*
			{...(to
				? {
						onClick: navigate,
						style: { cursor: 'pointer' }
				  }
				: {})}
			{...rest}
*/
