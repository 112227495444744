import React, { Component } from 'react';
import { Card, Col, Row, Button } from 'reactstrap';
import {
	Section,
	SectionList,
	SectionTitle,
	SectionSmallTitle
} from '../../../../components/Section';
import machineAPI from '../../../../services/api/machines';
import { withTranslation } from 'react-i18next';
import NfdHardwareSheet from './NfdHardwareSheet';
import MISelectorDialog from '../../../MISelectorDialog/MISelectorDialog';
import Modal from 'react-bootstrap/Modal';
import classes from './Datasheet.module.css';

class NfdHardwareCard extends Component {
	state = {
		machine: null,
		peripherials: null,
		paymentSys: null,
		peripherialType: 36,
		paymantSysType: 39
	};

	componentDidMount() {
		this.loadPeriperials();
	}
	componentDidUpdate(prevProps) {
		if (this.props.machine !== prevProps.machine) {
			let machine = { ...this.props.machine };
			this.setState({ machine: machine });
		}
	}
	loadPeriperials = () => {
		machineAPI.getVMPeripherials(this.props.vmId).then(resp => {
			let machine = { ...this.props.machine };

			let hardwares = resp.data.connectedParts.filter(e => e.name == 'NFD_BOX');
			let parts = [];
			parts = resp.data.connectedParts.map(e => {
				return { partID: e.partID };
			});
			machineAPI.getMIHardwares(true).then(result => {
				let miList = [...result.data.response];
				this.setState({
					miList,
					machine: machine,
					hardwares: hardwares,
					parts: parts
				});
			});
		});
	};

	renderPeriperials = value => {
		return value.map(e => {
			return (
				<Col xs={12} sm={12} md={12} lg={12}>
					<NfdHardwareSheet
						editMode={this.state.editMode}
						peripherial={e}
						onDelet={this.handleDelete}
						machine={this.props.machine}
						reload={this.loadPeriperials}
						root={this.props.root}
						companyAdmin={this.props.companyAdmin}
					/>
				</Col>
			);
		});
	};

	handleDelete = partID => {
		let parts = [];
		this.state.parts.map(e => {
			if (e.partID !== partID) {
				parts.push(e);
			}
		});
		machineAPI
			.setConnectedPeripherals(this.props.vmId, parts)
			.then(res => this.loadPeriperials());
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	hideAddDeviceDialog() {
		this.setState(
			{
				showAddDeviceDialog: false
			},
			() => this.loadPeriperials()
		);
	}

	async addPeripheral(selectedParts) {
		selectedParts.forEach(part => {
			this.state.parts.push({ partID: part.id });
		});

		await machineAPI.setConnectedPeripherals(this.props.vmId, this.state.parts);

		this.loadPeriperials();
		this.setState({ showAddDeviceDialog: false });
	}

	renderAddDeviceDialog() {
		return (
			<Modal
				show={this.state.showAddDeviceDialog}
				onHide={() => this.hideAddDeviceDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i
							className="fa fa-cog"
							aria-hidden="true"
							style={{ marginRight: '10px' }}
						></i>

						{this.props.t('device.details.peripheral.addPeripheral')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: '0px' }}>
					<MISelectorDialog
						allHardwares={this.state.miList}
						onSelected={newSelections => this.addPeripheral(newSelections)}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	render() {
		const { t, root, companyAdmin } = this.props;
		const { machine } = this.state;
		const addDeviceDialog = this.renderAddDeviceDialog();

		if (!machine) return null;
		return (
			<Card>
				{addDeviceDialog}

				<SectionList>
					<Row>
						<Col>
							<Section
								className={classes.TitleRow}
								style={{ borderRadius: '10px 10px 0px 0px' }}
							>
								<Col>
									<SectionTitle>
										{t('device.details.datasheet.nfd.title')}
									</SectionTitle>
								</Col>
								{(root || companyAdmin) &&
								(!this.state.hardwares || this.state.hardwares.length <= 0) ? (
									<>
										<Row
											style={{
												margin: '10px',
												justifyContent: 'space-between',
												alignItems: 'center'
											}}
										>
											<Col xs={12} sm={12} md={8} lg={8}>
												<SectionSmallTitle>
													{this.props.t(
														'device.details.datasheet.nfd.nfdData.new'
													)}
												</SectionSmallTitle>
											</Col>

											<Col xs={12} sm={12} md={4} lg={4}>
												<Button
													color={'success'}
													className="DefaultButton"
													onClick={() =>
														this.setState({ showAddDeviceDialog: true })
													}
												>
													<i class={'fa fa-plus mr-2'}></i>&nbsp;
													{this.props.t(
														'device.details.peripheral.addPeripheral'
													)}
												</Button>
											</Col>
										</Row>
									</>
								) : null}
							</Section>
						</Col>
					</Row>
					<Row>
						{this.state.hardwares
							? this.renderPeriperials(this.state.hardwares)
							: null}
					</Row>
				</SectionList>
			</Card>
		);
	}
}

export default withTranslation()(NfdHardwareCard);
