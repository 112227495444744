import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PromotionManager from '../../views/PromotionManager/PromotionManager';

class PromotionManagerPageRoute extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/promotion_manager" name="PromotionManager" component={PromotionManager} />
                    <Redirect from="/" to="/promotion_manager" />
                </Switch>
            </div>
        );
    }
}

export default PromotionManagerPageRoute;