export default {
	PARTNER_CREDIT_ENTRY: 0,
	BARION_TRANSACTION_START: 1,
	BARION_TRANSACTION_STARTED: 2,
	BARION_TRANSACTION_START_FAILED: 3,
	BARION_TRANSACTION_FAILED: 4,
	BARION_CREDIT_ENTRY: 5,
	VENDING_MACHINE_CREDIT_ENTRY: 6,
	VENDING_MACHINE_PRODUCT_PURCHASE: 7,
	PROMOTION_APPLIED: 8,
	TRANSACTION_WITHDRAWAL: 9,
	PROMOTION_APPLIED_BONUS_CREDIT: 10
};
