export default class {
	static summaryParameters = {
		TRANSACTION_SUM: 'TRANSACTION_SUM',
		TRANSACTIONS: 'TRANSACTIONS'
	};

	static addLocalStorage = transactionSum => {
		localStorage.setItem(
			this.summaryParameters.TRANSACTION_SUM,
			transactionSum
		);
	};

	static getLocalStorage = () => {
		document.getElementById(
			this.summaryParameters.TRANSACTION_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.TRANSACTION_SUM)
		);
	};
	static formatTransaction = (param, value) => {
		let total = 0;
		let par = [];
		switch (param) {
			case this.summaryParameters.TRANSACTIONS:
				total = value.length;
				break;
		}
		return total;
	};
	static setParamToStore = value => {
		this.addLocalStorage(
			this.formatTransaction(this.summaryParameters.TRANSACTIONS, value)
		);
	};
}
