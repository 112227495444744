import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import CustomersApi from '../../services/api/customers';
import MachineApi from '../../services/api/machines';
import SystemApi from '../../services/api/system';

import classes from './Customer.module.css';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContent from 'react-bootstrap/TabContent';

import Spinner from '../../components/UI/Spinner/Spinner';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import CustomerGeneral from './General/CustomerGeneral';
import InvisibleTouchSettings from './BarionSettings/InvisibleTouchSettings';
import UserList from '../UserList/UserList';
import DevicesList from '../VMlistComponens/Devices/DeviceList';
import { ROLES } from '../../services/util/consts';

class Customer extends Component {
	state = {
		partnerToDelete: null,
		contentVisible: false
	};

	componentDidMount() {
		SystemApi.getCurrencies().then(res => {
			this.setState({ currencies: res.data.currencies });
		});
	}

	loadVendingMachines() {
		MachineApi.getVmBycmID(this.props.Customer.cmID)
			.then(res => {
				if (!res.data.error) {
					let vendingMachines = [...res.data.response];
					this.setState({ vendingMachines });
				}
			})
			.catch(this.setState({ vendingMachines: [] }));
	}

	toggleAccrodionContent() {
		if (!this.state.contentVisible) {
			this.loadVendingMachines();
		}

		this.setState({ contentVisible: true });
	}

	savePartnerGeneralData(partner) {
		CustomersApi.saveCustomer(partner).then(res => {
			this.props.onPartnerChanged();
		});
	}

	deletePartner() {
		CustomersApi.deleteCustomer(this.state.partnerToDelete).then(res => {
			this.setState({ partnerToDelete: null });
			this.props.onPartnerChanged();
		});
	}

	async handleCreateMachine(hid, mi) {
		await MachineApi.createInvTouchMachine(
			this.props.Customer.cID,
			hid ? hid.id : null,
			mi ? mi.id : null
		);

		this.loadVendingMachines();
	}

	renderDeleteConfirmation() {
		if (this.state.partnerToDelete) {
			return (
				<ConfirmationDialog
					isOpen={this.state.partnerToDelete}
					onToggle={() => this.setState({ partnerToDelete: null })}
					title="Confirmation"
					confirmationText="Are you sure you want to delete the Partner?"
					cancelText="Cancel"
					okText="Ok"
					onCancel={() => this.setState({ partnerToDelete: null })}
					onOk={() => this.deletePartner()}
				/>
			);
		} else {
			return null;
		}
	}

	renderVendingMachines() {
		if (this.state.vendingMachines) {
			return (
				<DevicesList
					renderDocked
					hiddenColumns={['lastSale', 'detailButtons']}
					vms={this.state.vendingMachines}
					onCreateMachine={(hid, mi) => this.handleCreateMachine(hid, mi)}
				></DevicesList>
			);
		} else {
			return <Spinner />;
		}
	}

	renderUsers() {
		if (this.state.contentVisible) {
			const possibleRolesOnCustomerPage = [
				ROLES.companyAdmin,
				ROLES.regionalCompanyAdmin,
				ROLES.technician
			];
			return (
				<UserList
					cmId={this.props.Customer.cmID}
					hiddenColumns={['company']}
					possibleRolesForCreation={possibleRolesOnCustomerPage}
					possibleRolesEdit={possibleRolesOnCustomerPage}
					style={{ height: '95%' }}
					showInviteEmailSettings="true"
				/>
			);
		} else {
			return null;
		}
	}

	renderInvisibleTouchSettings() {
		return (
			<InvisibleTouchSettings
				customer={this.props.Customer}
			></InvisibleTouchSettings>
		);
	}

	renderAccordionContent() {
		if (this.state.contentVisible) {
			return (
				<div style={{ height: '560' }}>
					<Tabs
						className={classes.navTabs}
						defaultActiveKey="general"
						id="uncontrolled-tab-example"
					>
						<Tab
							eventKey="general"
							title={this.props.t('partnerManagement.tabs.general')}
							style={{ marginTop: '10px' }}
						>
							<TabContent
								style={{ border: '0px' }}
								className={classes.tabContent}
							>
								<CustomerGeneral
									company={this.props.Customer}
									showDeleteButton="true"
									showSaveButton="true"
									currencies={this.state.currencies}
									onSave={partner => {
										this.savePartnerGeneralData(partner);
									}}
									onDelete={partner => {
										this.setState({ partnerToDelete: partner });
									}}
								/>
							</TabContent>
						</Tab>
						<Tab
							eventKey="machines"
							title={this.props.t('partnerManagement.tabs.machines')}
							style={{ marginTop: '10px' }}
						>
							<TabContent className={classes.tabContent}>
								{this.renderVendingMachines()}
							</TabContent>
						</Tab>
						<Tab
							eventKey="users"
							title={this.props.t('partnerManagement.tabs.users')}
							style={{ marginTop: '10px' }}
						>
							<TabContent className={classes.tabContent}>
								{this.renderUsers()}
							</TabContent>
						</Tab>
						<Tab
							eventKey="invisibleTouchSettings"
							title={this.props.t(
								'partnerManagement.tabs.invisibleTouchSettings'
							)}
							style={{ marginTop: '10px' }}
						>
							<TabContent className={classes.tabContent}>
								{this.renderInvisibleTouchSettings()}
							</TabContent>
						</Tab>
					</Tabs>
				</div>
			);
		} else {
			return <div></div>;
		}
	}

	render() {
		return (
			<>
				{this.renderDeleteConfirmation()}
				<Card className={classes.card}>
					<Accordion.Toggle
						className={classes.cardHeader}
						as={Card.Header}
						eventKey={this.props.Customer.cmID}
						onClick={() => this.toggleAccrodionContent()}
					>
						<i
							className="fa fa-chevron-down"
							style={{ marginRight: '2rem !important' }}
						></i>
						{this.props.Customer.company}
					</Accordion.Toggle>
					<Accordion.Collapse eventKey={this.props.Customer.cmID}>
						{this.renderAccordionContent()}
					</Accordion.Collapse>
				</Card>
			</>
		);
	}
}
export default withTranslation()(Customer);
