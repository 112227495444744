import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { HorizontalBar } from 'react-chartjs-2';
import Dropdown from 'react-bootstrap/Dropdown';

// import statisticsAPI from '../../../../services/api/statistics';

class MachinePerformanceMonitorCard extends Component {
	state = { dropdownSelection: 0 };

	mainChart = {
		labels: [],
		datasets: [
			{
				label: '',
				backgroundColor: 'rgba(216,0,28,0.1)',
				borderColor: 'red',
				pointHoverBackgroundColor: '#fff',
				borderWidth: 2,
				data: []
			}
		]
	};

	mainChartOpts = {
		tooltips: {
			enabled: true,
			intersect: true,
			mode: 'index',
			position: 'nearest',
			callbacks: {
				label: tooltipItem => {
					const unit =
						this.state.dropdownSelection == 0
							? 'Ft'
							: this.props.t('units.pieces');
					return `${tooltipItem.xLabel} ${unit}`;
				},
				labelColor: function(tooltipItem, chart) {
					return {
						backgroundColor:
							chart.data.datasets[tooltipItem.datasetIndex].borderColor
					};
				}
			}
		},
		plugins: {
			datalabels: {
				display: false
			}
		},
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		scales: {
			xAxes: [
				{
					ticks: {
						callback: (value, index, values) => {
							const unit =
								this.state.dropdownSelection == 0
									? 'Ft'
									: this.props.t('units.pieces');
							return `${value} ${unit}`;
						}
					}
				}
			]
		},
		elements: {
			point: {
				radius: 0,
				hitRadius: 10,
				hoverRadius: 4,
				hoverBorderWidth: 3
			}
		}
	};

	updateChart() {
		if (!!!this.chartReference) return;
		this.chartReference.chartInstance.update();
	}

	componentDidMount() {
		this.prepareCharts();
	}

	loadData() {}

	prepareCharts() {
		this.mainChart.datasets[0].data = [];
		this.mainChart.labels = [];
		if (this.props.statisticsData) {
			const dataSource = this.props.statisticsData[
				this.props.datasets[this.state.dropdownSelection]
			];

			dataSource.forEach(salesData => {
				this.mainChart.datasets[0].data.push(
					this.state.dropdownSelection === 0
						? salesData.salesSum
						: salesData.salesCount
				);
				let yLabel = salesData.customname
					? salesData.customname
					: salesData.name;
				if (yLabel.length > 25) {
					yLabel = yLabel.slice(0, 22) + '...';
				}
				this.mainChart.labels.push(yLabel);
			});
		}
		this.updateChart();
	}

	renderDropDown(activeSelection, selectionChangeEvent) {
		return (
			<Dropdown>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					{activeSelection === 0
						? this.props.t('customerDashboard.machinePerformanceCard.salesSum')
						: this.props.t(
								'customerDashboard.machinePerformanceCard.salesCount'
						  )}
				</Dropdown.Toggle>

				<Dropdown.Menu>
					<Dropdown.Item onClick={() => selectionChangeEvent(0)}>
						{this.props.t('customerDashboard.machinePerformanceCard.salesSum')}
					</Dropdown.Item>
					<Dropdown.Item onClick={() => selectionChangeEvent(1)}>
						{this.props.t(
							'customerDashboard.machinePerformanceCard.salesCount'
						)}
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	}

	render() {
		const { t } = this.props;
		if (!this.props.statisticsData) return null;
		this.prepareCharts();
		return (
			<Container>
				<Row>
					<Col sm="12">
						<div style={{ textAlign: 'center' }}>
							<h4>
								<b>{this.props.title}</b>
							</h4>
						</div>
					</Col>
				</Row>
				<Row>
					<Col sm="12">
						<div style={{ textAlign: 'center' }}>
							{this.renderDropDown(
								this.state.dropdownSelection,
								selectionIndex =>
									this.setState({ dropdownSelection: selectionIndex }, () =>
										this.prepareCharts()
									)
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col sm="12">
						<div
							className="chart-wrapper"
							style={{ height: 300 + 'px', marginTop: '10px' }}
						>
							<HorizontalBar
								data={this.mainChart}
								options={this.mainChartOpts}
								height={300}
								ref={reference => {
									this.chartReference = reference;
									this.updateChart();
								}}
								redraw
							/>
						</div>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default withTranslation()(MachinePerformanceMonitorCard);
