/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './EditUserDialog.module.css';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TabContent from 'react-bootstrap/TabContent';
import Accordion from 'react-bootstrap/Accordion';
import Toggle from 'react-bootstrap-toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

import CustomersApi from '../../../services/api/customers';
import getRoleTextId from '../../../i18n/userRole';

import notificationAPI from '../../../services/api/notifications';
import machineAPI from '../../../services/api/machines';
import userAPI from '../../../services/api/users';
import EditNotificationSettings from '../EditNotificationSetttings/EditNotificationSettings';
import { ROLES } from '../../../services/util/consts';

class EditUserDialog extends Component {
	state = {
		company: '',
		editedUser: {},
		notificationSettings: [],
		machineFilterValue: '',
		machines: [],
		filteredMachines: [],

		activeTab: 'general',

		userPassword: {}
	};

	componentDidMount() {
		const user = { ...this.props.user };
		this.setState({ editedUser: user });

		if (user.cmID && user.cmID != '') {
			CustomersApi.getCustomer(user.cmID).then(res => {
				if (res.data.response && res.data.response.length > 0)
					this.setState({ company: res.data.response[0].company });
			});
		}

		//Get the possible notification list for user
		notificationAPI.getNotificationSettings(user.uID).then(resp => {
			this.setState({ notificationSettings: resp.data });
		});

		//Load the company's machines
		machineAPI.getVmBycmID(user.cmID).then(resp => {
			this.setState({ machines: resp.data.response }, () =>
				this.filterMachineList()
			);
		});

		//Load the assignments
		userAPI.getAssignedMachines(user.uID).then(resp => {
			this.setState({ assignedMachines: resp.data.assignments });
		});
	}

	onNotificationSettinsChanged(param) {
		const newSettings = [...this.state.notificationSettings];
		newSettings
			.filter(curSettings => curSettings.id === param.id)
			.forEach(curSettings => {
				curSettings.notificationDisabled = param.notificationDisabled;
				curSettings.emailNotificationDisabled = param.emailNotificationDisabled;
				curSettings.pushNotificationDisabled = param.pushNotificationDisabled;
			});
		this.setState({ notificationSettings: newSettings });
	}

	inputUpdate(e) {
		let editedUser = { ...this.state.editedUser };
		editedUser[e.target.id] = e.target.value;
		this.setState({ editedUser: editedUser });
	}

	passwordInputUpdate(e) {
		let userPassword = { ...this.state.userPassword };
		userPassword[e.target.id] = e.target.value;
		this.setState({ userPassword: userPassword });
	}

	selectUpdate(e) {
		let editedUser = { ...this.state.editedUser };
		editedUser.privilege = this.props.possibleRoles[e.target.selectedIndex];
		this.setState({ editedUser });
	}

	renderRoleCombobox() {
		const roleOtions = this.props.possibleRoles.map(roleId => (
			<option
				key={roleId}
				selected={roleId === this.state.editedUser.privilege}
			>
				{this.props.t(getRoleTextId(roleId))}
			</option>
		));
		return (
			<>
				<Form.Label>
					{this.props.t('userManagement.userGeneral.userRole')}
				</Form.Label>
				<Form.Control
					as="select"
					disabled={this.props.disableRoleInput}
					onChange={e => this.selectUpdate(e)}
				>
					{roleOtions}
				</Form.Control>
			</>
		);
	}

	handleFormSubmit(e) {
		let assignedMachines = undefined;
		if (!this.props.hideAssignmentSettings) {
			if (
				this.state.editedUser.privilege == ROLES.technician ||
				this.state.editedUser.privilege == ROLES.regionalCompanyAdmin
			) {
				assignedMachines = this.state.assignedMachines;
			} else {
				assignedMachines = [];
			}
		}

		e.preventDefault();
		this.props.onSave(
			this.state.editedUser,
			this.state.notificationSettings,
			assignedMachines
		);
	}

	renderNotificationSettings() {
		return this.state.notificationSettings.map(settings => {
			return (
				<>
					<EditNotificationSettings
						notification={settings}
						onSettingsChange={param => this.onNotificationSettinsChanged(param)}
						placeholder={''}
					></EditNotificationSettings>
					<hr />
				</>
			);
		});
	}

	filterTimeout = null;

	filterMachineList() {
		const trimmedFilterValue = this.state.machineFilterValue
			.trim()
			.toLocaleUpperCase();
		const filteredMachines = this.state.machines
			? this.state.machines.filter(machine => {
					return (
						trimmedFilterValue.length == 0 ||
						(machine.customName &&
							machine.customName
								.toLocaleUpperCase()
								.indexOf(trimmedFilterValue) >= 0) ||
						(machine.address &&
							machine.address.toLocaleUpperCase().indexOf(trimmedFilterValue) >=
								0)
					);
			  })
			: [];
		this.setState({ filteredMachines });
	}

	machineListfilterUpdate(e) {
		this.setState({ machineFilterValue: e.target.value }, () => {
			clearTimeout(this.filterTimeout);
			setTimeout(() => {
				this.filterMachineList();
			}, 300);
		});
	}

	clearFilter() {
		this.setState({ machineFilterValue: '' }, () => this.filterMachineList());
	}

	isMachineAssigned(vmID) {
		return (
			this.state.assignedMachines &&
			this.state.assignedMachines.find(machine => machine.VMID == vmID)
		);
	}

	toggleMachineAssignment(vmID) {
		if (this.isMachineAssigned(vmID)) {
			let newAssignedMachines = [...this.state.assignedMachines];
			newAssignedMachines = newAssignedMachines.filter(
				machine => machine.VMID != vmID
			);
			this.setState({ assignedMachines: newAssignedMachines });
		} else {
			let newAssignedMachines = [...this.state.assignedMachines];
			newAssignedMachines.push({ VMID: vmID });
			this.setState({
				assignedMachines: newAssignedMachines
			});
		}
	}

	isAllMachineAssigned() {
		return !this.state.filteredMachines.find(
			machine => !this.isMachineAssigned(machine.vmID)
		);
	}

	toggleAssignmentOnAllMachines() {
		if (this.isAllMachineAssigned()) {
			let assignedMachines = [...this.state.assignedMachines];
			this.state.filteredMachines.forEach(machine => {
				if (this.isMachineAssigned(machine.vmID)) {
					assignedMachines = assignedMachines.filter(
						assignedMachine => assignedMachine.VMID != machine.vmID
					);
				}
			});
			this.setState({ assignedMachines });
		} else {
			const allMachineVMID = this.state.filteredMachines.map(machine => {
				return {
					VMID: machine.vmID
				};
			});
			this.setState({ assignedMachines: allMachineVMID });
		}
	}

	renderMachineAssignments() {
		const machineList = this.state.filteredMachines.map(machine => {
			return (
				<div key={machine}>
					<Row>
						<Col md="9">
							<span>
								<b>
									{this.props.t('userManagement.userGeneral.machineName')}
									{': '}
								</b>
								{machine.customName}
							</span>
							<br />
							<span>
								<b>
									{this.props.t('userManagement.userGeneral.machineAddress')}
									{': '}
								</b>
								{machine.address && machine.address.length > 0
									? machine.address
									: '-'}
							</span>
						</Col>
						<Col md="3">
							<Toggle
								onClick={() => this.toggleMachineAssignment(machine.vmID)}
								on={this.props.t('userManagement.userGeneral.assigned')}
								off={this.props.t('userManagement.userGeneral.notAssigned')}
								active={!!this.isMachineAssigned(machine.vmID)}
								onstyle="info"
								offstyle="primary"
								size="sm"
								style={{
									width: '120px',
									height: '30px',
									borderRadius: '25px',
									float: 'right'
								}}
							/>
						</Col>
					</Row>
					<hr />
				</div>
			);
		});

		return (
			<>
				<Row>
					<Col md="5" className={classes.PaddingRight}>
						<Button
							variant="success"
							onClick={() => this.toggleAssignmentOnAllMachines()}
							className={'DefaultButton ' + classes.FilterSelectionButton}
						>
							<i className="fa fa-plus" aria-hidden="true"></i>
							{this.isAllMachineAssigned()
								? this.props.t('userManagement.userGeneral.deleteAssignments')
								: this.props.t(
										'userManagement.userGeneral.selectAllAssignments'
								  )}
						</Button>
					</Col>
					<Col md="7" className={classes.PaddingLeft}>
						<InputGroup className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text id="basic-addon1">
									<i className="fa fa-search"></i>
								</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								id="machinefilter"
								type="text"
								value={this.state.machineFilterValue}
								onChange={e => this.machineListfilterUpdate(e)}
								placeholder="Filter"
							/>
							<Button
								variant="light"
								onClick={() => this.clearFilter()}
								className={classes.FilterClearButton}
							>
								<i className="fa fa-times" aria-hidden="true"></i>
							</Button>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<div className={classes.MachineListDiv}>{machineList}</div>
					</Col>
				</Row>
			</>
		);
	}

	renderGeneralSettings() {
		return (
			<>
				<Form.Label>
					{this.props.t('userManagement.userGeneral.company')}
				</Form.Label>
				<Form.Control
					id="company"
					type="text"
					readOnly
					value={this.state.company}
					onChange={e => this.inputUpdate(e)}
				/>
				<Form.Label>
					{this.props.t('userManagement.userGeneral.email')}
				</Form.Label>
				<Form.Control
					id="email"
					type="email"
					value={this.state.editedUser.email}
					onChange={e => this.inputUpdate(e)}
				/>
				{this.renderRoleCombobox()}
				<Form.Label>
					{this.props.t('userManagement.userGeneral.firstName')}
				</Form.Label>
				<Form.Control
					id="firstName"
					type="text"
					value={this.state.editedUser.firstName}
					onChange={e => this.inputUpdate(e)}
				/>
				<Form.Label>
					{this.props.t('userManagement.userGeneral.lastName')}
				</Form.Label>
				<Form.Control
					id="lastName"
					type="text"
					value={this.state.editedUser.lastName}
					onChange={e => this.inputUpdate(e)}
				/>
				<Form.Label>
					{this.props.t('userManagement.userGeneral.phoneNumber')}
				</Form.Label>
				<Form.Control
					id="connPhone"
					type="text"
					value={this.state.editedUser.connPhone}
					onChange={e => this.inputUpdate(e)}
				/>
			</>
		);
	}

	renderChangePassword() {
		return (
			<>
				{!this.props.hideOriginalPassword ? (
					<>
						<Form.Label>
							{this.props.t('userManagement.changePassword.originalPassword')}
						</Form.Label>
						<Form.Control
							id="password"
							type="password"
							value={this.state.userPassword.password}
							onChange={e => this.passwordInputUpdate(e)}
						/>
					</>
				) : null}

				<Form.Label>
					{this.props.t('userManagement.changePassword.newPassword')}
				</Form.Label>
				<Form.Control
					id="newPassword1"
					type="password"
					value={this.state.userPassword.newPassword1}
					onChange={e => this.passwordInputUpdate(e)}
				/>
				<Form.Label>
					{this.props.t('userManagement.changePassword.newPassword2')}
				</Form.Label>
				<Form.Control
					id="newPassword2"
					type="password"
					value={this.state.userPassword.newPassword2}
					onChange={e => this.passwordInputUpdate(e)}
				/>
				<div style={{ marginTop: '10px' }}></div>
				{this.props.passwordChangeState == 1 ? (
					<Form.Label>Password changed</Form.Label>
				) : null}
				{this.props.passwordChangeState == 2 ? (
					<Form.Label>Password change failed</Form.Label>
				) : null}
			</>
		);
	}

	render() {
		return (
			<Form onSubmit={e => this.handleFormSubmit(e)}>
				<Tabs
					defaultActiveKey="general"
					ic="controlledTab"
					activeKey={this.state.activeTab}
					onSelect={key => this.setState({ activeTab: key })}
					style={{
						marginBottom: '0rem',
						marginLeft: '0rem'
					}}
				>
					<Tab
						eventKey="general"
						title={this.props.t('userManagement.userGeneral.generalSettings')}
					>
						<TabContent>{this.renderGeneralSettings()}</TabContent>
					</Tab>
					{!this.props.hidePasswordEdit ? (
						<Tab
							eventKey="changePassword"
							title={this.props.t('userManagement.changePassword.title')}
						>
							<TabContent>{this.renderChangePassword()}</TabContent>
						</Tab>
					) : null}

					<Tab
						eventKey="notificationSettings"
						title={this.props.t(
							'userManagement.userGeneral.notificationSettings'
						)}
						style={{ padding: '0px' }}
					>
						<TabContent>
							<div className={classes.NotifiactionListDiv}>
								<Accordion>{this.renderNotificationSettings()}</Accordion>
							</div>
						</TabContent>
					</Tab>
					{!this.props.hideAssignmentSettings &&
					(this.state.editedUser.privilege == ROLES.technician ||
						this.state.editedUser.privilege == ROLES.regionalCompanyAdmin) ? (
						<Tab
							eventKey="hardwareAssignment"
							title={this.props.t(
								'userManagement.userGeneral.hardwareAssignment'
							)}
						>
							{this.renderMachineAssignments()}
						</Tab>
					) : null}
				</Tabs>
				<div className={classes.ToolbarDiv}>
					{this.state.activeTab != 'changePassword' ? (
						<Button variant="success" className="DefaultButton" type="submit">
							<i className="fa fa-floppy-o mr-2" />
							{this.props.t('userManagement.userGeneral.save')}
						</Button>
					) : (
						<Button
							variant="success"
							className="DefaultButton"
							onClick={() => {
								this.props.onSavePassword(
									this.state.userPassword.password,
									this.state.userPassword.newPassword1
								);
							}}
							disabled={
								!this.state.userPassword.newPassword1 ||
								this.state.userPassword.newPassword1.trim() == '' ||
								this.state.userPassword.newPassword1.trim().length < 6 ||
								this.state.userPassword.newPassword1 !=
									this.state.userPassword.newPassword2
							}
						>
							<i className="fa fa-floppy-o mr-2" />
							{this.props.t('userManagement.changePassword.button')}
						</Button>
					)}
				</div>
			</Form>
		);
	}
}
export default withTranslation()(EditUserDialog);
