//@flow
import React from 'react';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Form,
	FormGroup,
	Input,
	InputGroup,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';
import ReactPhoneInput from 'react-phone-input-2';
import ReactCodeInput from 'react-code-input';

import 'ladda/dist/ladda-themeless.min.css';

import LaddaButton, { EXPAND_LEFT, EXPAND_RIGHT } from 'react-ladda';
import UserAPI from '../../services/api/users';
import { PIN_LENGTH } from '../../services/util/consts';
import logger from '../../services/logger';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { withRouter } from 'react-router-dom';

type Props = {
	uID: string,
	pinWhenPhoneUpdate: boolean,
	className: string,
	history: Object,
	t: TFunction
};
type States = {
	modal: boolean,
	success: boolean,
	warning: boolean,
	danger: boolean,
	expLeft: boolean,
	expRight: boolean,
	userFormValue: Object,
	phone: string,
	PIN: string,
	resend: boolean,
	progress?: number
};
class EditUserContainer extends React.Component<Props, States> {
	constructor() {
		super();

		this.state = {
			modal: false,
			success: false,
			warning: false,
			danger: false,
			expLeft: false,
			expRight: false,
			userFormValue: {},
			phone: '',
			PIN: '',
			resend: true
		};
	}

	componentDidMount = () => {
		this.getProfile();
	};

	//Modal success
	toggleSuccess = () => {
		this.setState({
			success: !this.state.success
		});
	};

	//Modell danger
	toggleDanger = () => {
		this.setState({
			danger: !this.state.danger
		});
	};

	//Modell warning
	toggleWarning = () => {
		this.setState({
			warning: !this.state.warning
		});
	};

	//GetProfil
	getProfile = async () => {
		const userID = this.props.uID;
		try {
			const response = await UserAPI.getUserById(userID);
			const userFormValue = response.data.response;
			const phone = userFormValue.connPhone;
			this.setState({ userFormValue, phone });
		} catch (e) {
			logger.error(e);
		}
	};

	updateProfile = async () => {
		this.setState({
			expLeft: true,
			progress: 0.5
		});
		const { pinWhenPhoneUpdate = false } = this.props;

		try {
			const response = await UserAPI.updateUser(
				this.state.userFormValue,
				this.state.phone !== this.state.userFormValue.connPhone ? 'true' : ''
			);
			if (response.data.hasOwnProperty('response')) {
				if (
					pinWhenPhoneUpdate &&
					this.state.phone !== this.state.userFormValue.connPhone &&
					this.state.resend
				) {
					this.toggleDanger();
				} else if (this.state.resend) {
					this.toggleSuccess();
				}

				//Resend állapot
				this.setState({
					expRight: false,
					progress: 30.0
				});
			}
		} catch (e) {
			logger.error(e);
		}
	};

	//PhoneValidator
	phoneValidator = async (pin: string) => {
		try {
			const response = await UserAPI.phoneValidator(pin);
			if (response.data.hasOwnProperty('response')) {
				this.toggleDanger();
				this.toggleSuccess();
			} else if (response.data.hasOwnProperty('error')) {
				this.toggleDanger();
				this.toggleWarning();
			}
		} catch (e) {
			logger.error(e);
		}
	};

	//Cancel
	cancel = () => {
		this.props.history.goBack();
	};

	//Input fieldek mentése
	handleChange = (event: SyntheticInputEvent<any>) => {
		event.preventDefault();

		const { userFormValue } = this.state;
		const { name, value } = event.target;
		this.setState({
			userFormValue: {
				...userFormValue,
				[name]: value
			}
		});
	};

	handlePhoneChange = (connPhone: string) => {
		this.setState({
			userFormValue: {
				...this.state.userFormValue,
				connPhone
			}
		});
	};

	//PIN field változás
	handlePINChange = (PIN: string) => {
		this.setState({ PIN });
	};

	//Send PIN code
	sendPINCode = () => {
		var pin = this.state.PIN;
		if (pin.length !== PIN_LENGTH) {
			this.toggleWarning();
		} else {
			this.phoneValidator(pin);
		}
	};

	//Re Send PIN code
	ReSendPINCode = () => {
		//Resend állapot
		this.setState({
			expRight: true,
			progress: 30
		});

		this.setState({ resend: false });

		this.updateProfile();
	};

	//Render
	render() {
		const { t } = this.props;
		return (
			<div>
				<Modal
					isOpen={this.state.success}
					toggle={this.toggleSuccess}
					className={'modal-success ' + this.props.className}
				>
					<ModalHeader toggle={this.toggleSuccess}>Sikeres mentés</ModalHeader>
					<ModalFooter>
						<Button color="success" onClick={this.cancel}>
							OK
						</Button>{' '}
					</ModalFooter>
				</Modal>
				<Modal
					isOpen={this.state.danger}
					toggle={this.toggleDanger}
					className={'modal-danger ' + this.props.className}
				>
					<ModalHeader toggle={this.toggleDanger}>
						{t('useredit.setpin.modal.title')}
					</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Label htmlFor="name">{t('useredit.setpin.modal.label')}</Label>
							<InputGroup>
								<ReactCodeInput
									type="text"
									fields={PIN_LENGTH}
									value={this.state.PIN}
									onChange={this.handlePINChange}
								/>
							</InputGroup>
						</FormGroup>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={this.sendPINCode}>
							{t('useredit.setpin.modal.verifybutton')}
						</Button>
						<LaddaButton
							className="btn btn-warning btn-ladda"
							loading={this.state.expRight}
							data-style={EXPAND_RIGHT}
							onClick={this.ReSendPINCode}
						>
							{t('useredit.setpin.modal.resendbutton')}
						</LaddaButton>
					</ModalFooter>
				</Modal>
				<Modal
					isOpen={this.state.warning}
					toggle={this.toggleWarning}
					className={'modal-warning ' + this.props.className}
				>
					<ModalHeader toggle={this.toggleWarning}>
						{t('useredit.setpin.warningmodal.title')}
					</ModalHeader>
					<ModalBody>{t('useredit.setpin.warningmodal.label')}</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={this.cancel}>
							OK
						</Button>
					</ModalFooter>
				</Modal>

				<Row>
					<Col xs="12" sm="4">
						<Card style={{ height: '570px' }}>
							<CardHeader>
								<b>1. {t('usersettings.basicdata.title')}</b>
							</CardHeader>
							<CardBody>
								<Form>
									<FormGroup>
										<Label htmlFor="company">
											{t('usersettings.basicdata.label.company')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-home" />
												</span>
											</div>
											<Input
												type="text"
												id="company"
												name="company"
												value={this.state.userFormValue.company}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="email">
											{t('usersettings.basicdata.label.email')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-envelope" />
												</span>
											</div>
											<Input
												type="email"
												id="email"
												name="email"
												value={this.state.userFormValue.email}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="web">
											{t('usersettings.basicdata.label.web')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-globe" />
												</span>
											</div>
											<Input
												type="text"
												id="web"
												name="web"
												value={this.state.userFormValue.web}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
								</Form>
							</CardBody>
						</Card>
					</Col>
					<Col xs="12" sm="4">
						<Card style={{ height: '570px' }}>
							<CardHeader>
								<b>2. {t('usersettings.contact.title')}</b>
							</CardHeader>
							<CardBody>
								<Form>
									<FormGroup>
										<Label htmlFor="firstName">
											{t('usersettings.contact.label.firstname')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-address-card" />
												</span>
											</div>
											<Input
												type="text"
												id="firstName"
												name="firstName"
												value={this.state.userFormValue.firstName}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="lastName">
											{t('usersettings.contact.label.lastname')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-address-card" />
												</span>
											</div>
											<Input
												type="text"
												id="lastName"
												name="lastName"
												value={this.state.userFormValue.lastName}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="connEmail">
											{t('usersettings.contact.label.email')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-envelope" />
												</span>
											</div>
											<Input
												type="email"
												id="connEmail"
												name="connEmail"
												value={this.state.userFormValue.connEmail}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="connCountry">
											{t('usersettings.contact.label.country')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-flag" />
												</span>
											</div>
											<Input
												type="text"
												id="connCountry"
												name="connCountry"
												value={this.state.userFormValue.connCountry}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="connPostCode">
											{t('usersettings.contact.label.postcode')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-telegram" />
												</span>
											</div>
											<Input
												type="text"
												id="connPostCode"
												name="connPostCode"
												value={this.state.userFormValue.connPostCode}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="connPhone">
											{t('usersettings.contact.label.phone')}
										</Label>
										<InputGroup>
											<ReactPhoneInput
												defaultCountry={'hu'}
												id="connPhone"
												name="connPhone"
												value={this.state.userFormValue.connPhone}
												onChange={this.handlePhoneChange}
											/>
										</InputGroup>
									</FormGroup>
								</Form>
							</CardBody>
						</Card>
					</Col>
					<Col xs="12" sm="4">
						<Card style={{ height: '570px' }}>
							<CardHeader>
								<b>3. {t('usersettings.billing.title')}</b>
							</CardHeader>
							<CardBody>
								<Form>
									<FormGroup>
										<Label htmlFor="payName">
											{t('usersettings.billing.label.name')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-credit-card-alt" />
												</span>
											</div>
											<Input
												type="text"
												id="payName"
												name="payName"
												value={this.state.userFormValue.payName}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="payCountry">
											{t('usersettings.billing.label.country')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-flag" />
												</span>
											</div>
											<Input
												type="text"
												id="payCountry"
												name="payCountry"
												value={this.state.userFormValue.payCountry}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="payPostCode">
											{t('usersettings.billing.label.postcode')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-telegram" />
												</span>
											</div>
											<Input
												type="text"
												id="payPostCode"
												name="payPostCode"
												value={this.state.userFormValue.payPostCode}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="payAddress">
											{t('usersettings.billing.label.address')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-thumb-tack" />
												</span>
											</div>
											<Input
												type="text"
												id="payAddress"
												name="payAddress"
												value={this.state.userFormValue.payAddress}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<Label htmlFor="payTaxNum">
											{t('usersettings.billing.label.taxnumber')}
										</Label>
										<InputGroup>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-calculator" />
												</span>
											</div>
											<Input
												type="text"
												id="payTaxNum"
												name="payTaxNum"
												value={this.state.userFormValue.payTaxNum}
												onChange={this.handleChange}
											/>
										</InputGroup>
									</FormGroup>

									<FormGroup className="form-actions pt-4">
										<Button
											type="button"
											size="xl"
											color="danger"
											className="mr-3"
											onClick={this.cancel}
										>
											{t('setpromotion.modal.cancel')}
										</Button>

										<LaddaButton
											className="btn btn-success btn-ladda"
											loading={this.state.expLeft}
											data-style={EXPAND_LEFT}
											onClick={this.updateProfile}
										>
											{t('userSettings.notiSetting.buttonLabel')}
										</LaddaButton>
									</FormGroup>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

export default withRouter(withTranslation()(EditUserContainer));
