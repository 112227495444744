import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import 'react-dates/initialize';
import classes from './InvoiceList.module.css';
import { Badge, Row, Col, ButtonGroup, Button } from 'reactstrap';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import InvisibleTouchutil from '../../../services/util/invisibleTouchUtil';
import SummaryPanel from './SummaryPanel';
import TableToolBar from '../../../components/SalesAndInomeCard/SalesAndIncomeTable/TableToolBar';
import ReactExport from '@ibrahimrahmani/react-export-excel';
import store from '../../../store';
import { setTableData } from '../../../store/table';
import transactionTypes from '../../../services/util/transactionTypes';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const filterParams = {
	EMAIL: 1,
	STATE: 2
};

class SalesAndIncomeTable extends Component {
	state = {
		startDate: null,
		endDate: null
	};

	componentDidMount = () => {
		if (this.props.data) {
			store.dispatch(setTableData(JSON.stringify(this.props.data)));

			InvisibleTouchutil.setParamToStore(this.props.data);
			InvisibleTouchutil.getLocalStorage();
		}
	};

	getDietailsSumLabel = param => {
		return <Form.Label id={param}></Form.Label>;
	};

	changeDates = val => {
		this.setState({ startDate: val.startDate, endDate: val.endDate });
	};

	// --------------- Excel export -----------------

	formatExcel = v => {
		let value = [];
		if (v) {
			value = v;
		}
		const { t, filterParamSet } = this.props;
		const header = [
			{
				value: t('device.details.sales.datetime'),
				widthPx: 160,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('invisibleTouchManager.purchasesPanel.account'),
				widthPx: 180,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('invisibleTouchManager.purchasersTable.email'),
				widthPx: 200,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('invisibleTouchManager.purchasesPanel.state'),
				widthPx: 100,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('invisibleTouchManager.purchasesPanel.amount'),
				widthPx: 80,
				style: { font: { sz: '24', bold: true } }
			}
		];
		const val = value.map(e => {
			let el = [];
			if (e) {
				let date = e.insertedAt
					? this.dateFormatter(e.insertedAt)
					: this.props.t('invisibleTouchManager.controlpanel.noData');
				let customer = e.user
					? this.customerFormatter(e.user, null, true)
					: this.props.t('invisibleTouchManager.controlpanel.noData');
				let account = e.index
					? e.index.toString()
					: this.props.t('invisibleTouchManager.controlpanel.noData');
				let state = e.billingStateID
					? this.excelStateFormater(e.billingStateID)
					: this.props.t('invisibleTouchManager.controlpanel.noData');
				let amount = e.amount
					? this.priceFormatter(e.amount, e)
					: this.props.t('invisibleTouchManager.controlpanel.noData');
				el = [
					{ value: date },
					{ value: account },
					{ value: customer },
					{ value: state },
					{ value: amount }
				];
			}
			return el;
		});
		const styledMultiDataSet = [
			{
				columns: header,
				data: val
			}
		];
		return (
			<div>
				<ExcelFile
					element={
						<Button className={classes.DefaultFilterButton}>
							<i className=" fa fa-file mr-2"></i>
							{this.props.t('salesSnadIncome.exportToXLS')}
						</Button>
					}
				>
					<ExcelSheet
						dataSet={styledMultiDataSet}
						name={this.props.t('salesSnadIncome.title')}
					/>
				</ExcelFile>
			</div>
		);
	};

	renderExcelExportButton = () => {
		let dataSet = [];
		if (this.props.table && JSON.parse(this.props.table)) {
			dataSet = JSON.parse(this.props.table);
		}

		return this.formatExcel(dataSet);
	};

	// --------------- Excel export -----------------

	createCustomToolBar = props => {
		const getCSVBtn = () => {
			return (
				<Button
					className={classes.DefaultFilterButton}
					onClick={() =>
						props.components.btnGroup.props.children[0].props.onClick()
					}
				>
					<i className="fa fa-download mr-2" />
					{this.props.t('salesSnadIncome.exportToCSV')}
				</Button>
			);
		};

		return (
			<>
				<TableToolBar
					startDate={this.props.startDate}
					endDate={this.props.endDate}
					btnGroup={getCSVBtn()}
					onInputChange={this.props.onDateChange}
					excelExportButton={() => this.renderExcelExportButton()}
					loadButton={this.props.loadButton}
				/>
			</>
		);
	};

	// -------------- Filterbuttons ------------ //
	renderHeaderFilter = (title, value, param) => {
		let noVisibleElements = null;
		noVisibleElements = this.props.elementVisibilities.find(
			e => e.param === param
		);
		return (
			<Row style={{ alignItmes: 'center', justifyContent: 'center' }}>
				<Badge
					className={
						noVisibleElements
							? classes.ActiveFilterButton
							: classes.FilterButton
					}
					onClick={value}
				>
					<i className="fa fa-search" style={{ margin: '3px' }} />
				</Badge>
				{title}
			</Row>
		);
	};
	renderNoFilterHeader = (title, value, param) => {
		let noVisibleElements = null;
		noVisibleElements = this.props.elementVisibilities.find(
			e => e.param === param
		);
		return (
			<Row
				noGutters
				style={{
					flexDirection: 'column',
					alignItmes: 'center',
					justifyContent: 'center'
				}}
			>
				<Row
					style={{
						alignItmes: 'center',
						justifyContent: 'center'
					}}
				>
					{!param ? title : this.renderHeaderFilter(title, value, param)}
				</Row>
				<Row
					style={{
						height: '3rem'
					}}
				></Row>
			</Row>
		);
	};

	resetFilter = () => {
		this.props.onResetFilter();
	};

	renderResetFilterButton = (title, value) => {
		return (
			<Row
				style={{
					alignItmes: 'center',
					justifyContent: 'center'
				}}
			>
				<div className={classes.ResetFilterButton} onClick={value}>
					<span
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItmes: 'center',
							justifyContent: 'center',
							height: '70px',
							width: '60px'
						}}
					>
						{title}
						<i className="fa fa-search" style={{ marginTop: '5px' }} />
					</span>
				</div>
			</Row>
		);
	};

	afterColumnFilter = (filterConds, result) => {
		if (result) {
			store.dispatch(setTableData(JSON.stringify(result)));
			InvisibleTouchutil.setParamToStore(result);
			InvisibleTouchutil.getLocalStorage();
		}
	};

	// -------------- Filterbuttons ------------ //

	// -------------- Dataformaters ------------ //

	excelStateFormater = value => {
		let state = this.props.t('invisibleTouchManager.controlpanel.noData');
		switch (value) {
			case 3:
				state = this.props.t('invisibleTouchManager.invoicePanel.declained');
				break;
			case 2:
				state = this.props.t('invisibleTouchManager.invoicePanel.provided');
				break;
			case 1:
				state = this.props.t('invisibleTouchManager.invoicePanel.requested');
				break;
		}
		return state;
	};

	stateFormater = (cell, row) => {
		let state = this.props.t('invisibleTouchManager.controlpanel.noData');
		let style = {
			color: 'var(--purchases-dark)',
			backgroundColor: 'transparent'
		};
		let i = 'fa fa-exclamation';

		switch (row.billingStateID) {
			case 3:
				state = this.props.t('invisibleTouchManager.invoicePanel.declained');
				style = {
					color: 'var(--purchases-dark)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-times-circle';
				break;
			case 2:
				state = this.props.t('invisibleTouchManager.invoicePanel.provided');
				style = { color: 'var(--balance)', backgroundColor: 'transparent' };
				i = 'fa fa-check-circle';
				break;
			case 1:
				state = this.props.t('invisibleTouchManager.invoicePanel.requested');
				style = {
					color: 'var(--inv-tuch-error)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-exclamation-circle';
				break;
		}
		return (
			<>
				<i className={i} style={style} />
				<Badge style={style}>{state}</Badge>
			</>
		);
	};

	approvalhandler = (bsID, row) => {
		this.props.onEditBillingState(bsID, row, this.props.data);
	};

	approvalFormater = (cell, row) => {
		let disabled = false;
		if (row.billingStateID !== 1) {
			disabled = true;
		}

		return (
			<ButtonGroup>
				<Col>
					<Button
						onClick={e => this.approvalhandler(2, row)}
						className={classes.ApproveButton}
						disabled={disabled}
					>
						<i className="fa fa-check-circle mr-2" />
						&nbsp;
						{this.props.t('invisibleTouchManager.invoicePanel.providing')}
					</Button>
				</Col>
				<Col>
					<Button
						onClick={e => this.approvalhandler(3, row)}
						className={classes.DeclainButton}
						disabled={disabled}
					>
						<i className="fa fa-times-circle mr-2" />
						&nbsp;
						{this.props.t('invisibleTouchManager.invoicePanel.declaining')}
					</Button>
				</Col>
			</ButtonGroup>
		);
	};

	priceFormatter = (cell, row) => {
		let data = '';
		if (cell) {
			let currency = '';
			if (
				this.props.currency &&
				this.props.currency[0] &&
				this.props.currency[0].symbol
			)
				currency = this.props.currency[0].symbol;
			data = `${cell.toFixed(2)} ${currency}`;
		}

		return data;
	};

	customerFormatter = (cell, row) => {
		let data = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (cell && cell.email) {
			data = cell.email;
		}
		return data;
	};
	getCutomerStyle = (cell, row) => {
		let style = {
			verticalAlign: 'middle',
			whiteSpace: 'normal',
			fontWeight: 'bold',
			opacity: '0.3'
		};
		if (cell && (cell.email || cell.productName)) {
			style = {
				verticalAlign: 'middle',
				whiteSpace: 'normal',
				fontWeight: 'bold'
			};
		}
		return style;
	};

	dateFormatter = cell => {
		return moment(cell).format('YYYY. MM. DD. HH:mm:ss');
	};

	rowClassNameFormat = row => {
		let className = classes.InvoiceRow;

		return className;
	};

	idFormater = () => {
		return ' ';
	};
	// -------------- Dataformaters ------------ //

	//Render
	render() {
		const { t } = this.props;

		//Table options
		const tableOptions = {
			sortIndicator: true,
			hideSizePerPage: true,
			sizePerPage: [10],
			withFirstAndLast: true,
			alwaysShowAllBtns: true,
			pageStartIndex: 1,
			paginationPosition: 'bottom',
			paginationSize: 3,
			defaultSortName: 'insertedAt',
			defaultSortOrder: 'desc',
			toolBar: this.createCustomToolBar,
			afterColumnFilter: this.afterColumnFilter
		};
		const textFilterOptions = {
			type: 'TextFilter',
			placeholder: t('filterToolbar.filterPlaceholder'),
			style: {
				marginTop: '20px',
				borderRadius: '5px',
				borderColor: 'var(--control-mid)'
			}
		};
		const priceFilterOptions = {
			type: 'TextFilter',
			placeholder: t('filterToolbar.filterPlaceholder'),
			condition: 'eq',
			style: {
				marginTop: '20px',
				borderRadius: '5px',
				borderColor: 'var(--control-mid)'
			}
		};

		return (
			<>
				<BootstrapTable
					data={this.props.data.filter(e => e.billingStateID)}
					version="4"
					pagination
					options={tableOptions}
					bordered={false}
					condensed
					size="sm"
					trClassName={this.rowClassNameFormat}
					tableHeaderClass={classes.TableHeaderClass}
					headerContainerClass={classes.HeaderContainerClass}
					exportCSV
				>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.datetime')}
						csvFormat={this.dateFormatter}
						filter={textFilterOptions}
						width="100"
						dataAlign="center"
						dataField="insertedAt"
						thStyle={{
							borderColor: 'transparent'
						}}
						dataFormat={this.dateFormatter}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{t('device.details.sales.datetime')}
					</TableHeaderColumn>
					<TableHeaderColumn
						isKey
						csvHeader={t('invisibleTouchManager.purchasesPanel.account')}
						csvFormat={this.positionFormatter}
						filterValue={this.positionFilterValue}
						filter={textFilterOptions}
						dataAlign="center"
						width="100"
						dataField="index"
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{t('invisibleTouchManager.purchasesPanel.account')}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('invisibleTouchManager.purchasersTable.email')}
						csvFormat={this.customerFormatter}
						dataAlign="center"
						width="300"
						dataField="userWalletID"
						dataFormat={this.customerFormatter}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={this.getCutomerStyle}
					>
						{this.renderNoFilterHeader(
							t('invisibleTouchManager.purchasersTable.email'),
							this.props.showEmailFilterDialog,
							filterParams.EMAIL
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('invisibleTouchManager.purchasesPanel.amount')}
						csvFormat={this.priceFormatter}
						filter={priceFilterOptions}
						dataAlign="center"
						width="100"
						dataField="amount"
						dataFormat={this.priceFormatter}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{t('invisibleTouchManager.purchasesPanel.amount')}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('invisibleTouchManager.purchasesPanel.state')}
						csvFormat={this.stateFormater}
						dataField="transactionTypeID"
						thStyle={{
							borderColor: 'transparent',
							verticalAlign: 'middle'
						}}
						dataAlign="center"
						dataFormat={this.stateFormater}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
						width="200"
					>
						{this.renderNoFilterHeader(
							t('invisibleTouchManager.purchasesPanel.state'),
							this.props.showStateFilterDialog,
							filterParams.STATE
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('salesSnadIncome.table.transactionType')}
						csvFormat={this.approvalFormater}
						dataAlign="center"
						width="300"
						dataField="transactionTypeID"
						thStyle={{
							borderColor: 'transparent',
							verticalAlign: 'middle'
						}}
						dataFormat={this.approvalFormater}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{this.renderNoFilterHeader(
							t('invisibleTouchManager.invoicePanel.approval'),
							false,
							false
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.id')}
						width="60"
						dataField="id"
						dataFormat={this.idFormater}
						dataAlign="center"
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{ verticalAlign: 'middle' }}
					>
						{this.renderResetFilterButton(
							this.props.t('salesSnadIncome.reset'),
							this.resetFilter
						)}
					</TableHeaderColumn>
				</BootstrapTable>
				<SummaryPanel transactionSum={this.getDietailsSumLabel('ALL_COUNT')} />
			</>
		);
	}
}

const mapStateToProps = state => ({
	table: state.table
});

export default connect(mapStateToProps)(withTranslation()(SalesAndIncomeTable));
