import axios from './api';
import qs from 'qs';

export default {
	SetlayoutForofflineMachine: param => {
		const p = {
			vmID: param.vmID,
			packageID: param.packageID,
			layoutID: param.layoutID
		};
		const query = qs.stringify(p, { addQueryPrefix: true });
		return axios
			.get()
			.post(`/vendingmachine/layout${query}`)
			.then()
			.catch(e => console.log(e));
	}
};
