import React, { Component } from 'react';
import { Col, Row, Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import classes from './TransactionList.module.css';
import DietailRow from '../../../components/SalesAndInomeCard/SalesAndIncomeTable/DietailRow';

class SummaryPanel extends Component {
	state = {
		transactionDietails: false,
		salesDietails: false,
		incomeDietails: false,
		cashStock: false
	};
	sumaryCollapsOpener = (value, name) => {
		let open = value;
		this.setState({ [name]: !open });
	};

	render() {
		const { t } = this.props;
		return (
			<Form>
				<Form.Group>
					<Row className={classes.SummaryRow}>
						<Col xs={12} sm={6} md={4} lg={4}>
							<Form.Label>
								{t(
									'invisibleTouchManager.transactionTable.summaryPanel.transactions'
								)}
							</Form.Label>
						</Col>
						<Col xs={12} sm={6} md={2} lg={2}>
							<Row noGutters>
								<Col
									xs={6}
									sm={6}
									md={6}
									lg={6}
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										marginRight: '5px'
									}}
								>
									{this.props.transactionSum}
								</Col>
								<Col xs={5} sm={5} md={5} lg={5}>
									{t('salesSnadIncome.summary.piece')}
								</Col>
							</Row>
						</Col>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Badge
								className={classes.Dietail}
								onClick={() =>
									this.sumaryCollapsOpener(
										this.state.transactionDietails,
										'transactionDietails'
									)
								}
								aria-expanded={this.state.transactionDietails}
							>
								{t('salesSnadIncome.summary.details')}
								<i
									className={
										!this.state.transactionDietails
											? 'fa fa-caret-down ml-2'
											: 'fa fa-caret-up ml-2'
									}
								></i>
							</Badge>
							<Collapse in={this.state.transactionDietails}>
								<Col className={classes.DietailPanel}>
									<DietailRow
										name={t(
											'invisibleTouchManager.transactionTable.summaryPanel.purchaseCount'
										)}
										measure={t('salesSnadIncome.summary.piece')}
										label={this.props.purchaseCount}
										style={{ borderColor: 'transparent' }}
									/>
									<DietailRow
										name={t(
											'invisibleTouchManager.transactionTable.summaryPanel.purchaseSum'
										)}
										measure={this.props.currency}
										label={this.props.purchaseSum}
										style={{ borderColor: 'transparent' }}
									/>

									<DietailRow
										name={t(
											'invisibleTouchManager.transactionTable.summaryPanel.withdrawnCount'
										)}
										measure={t('salesSnadIncome.summary.piece')}
										label={this.props.withdrawnTransactionCount}
										style={{ borderColor: 'transparent' }}
									/>
									<DietailRow
										name={t(
											'invisibleTouchManager.transactionTable.summaryPanel.withdrawnAmount'
										)}
										measure={this.props.currency}
										label={this.props.withdrawnTransactionSum}
										style={{ borderColor: 'transparent' }}
									/>

									<DietailRow
										name={t(
											'invisibleTouchManager.transactionTable.summaryPanel.balanceTransactionsCashCount'
										)}
										measure={t('salesSnadIncome.summary.piece')}
										label={this.props.cashBalanceCount}
										style={{ borderColor: 'transparent' }}
									/>
									<DietailRow
										name={t(
											'invisibleTouchManager.transactionTable.summaryPanel.balanceTransactionsCashSum'
										)}
										measure={this.props.currency}
										label={this.props.cashBalanceSum}
										style={{ borderColor: 'transparent' }}
									/>
									<DietailRow
										name={t(
											'invisibleTouchManager.transactionTable.summaryPanel.balanceTransactionsBarionCount'
										)}
										measure={t('salesSnadIncome.summary.piece')}
										label={this.props.barionBalanceCount}
										style={{ borderColor: 'transparent' }}
									/>
									<DietailRow
										name={t(
											'invisibleTouchManager.transactionTable.summaryPanel.balanceTransactionsBarionSum'
										)}
										measure={this.props.currency}
										label={this.props.barionBalacnceSum}
										style={{ borderColor: 'transparent' }}
									/>
								</Col>
							</Collapse>
						</Col>
					</Row>
				</Form.Group>
			</Form>
		);
	}
}

export default withTranslation()(SummaryPanel);
