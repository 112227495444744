//@flow
import React from 'react'

import { Col, Row } from 'reactstrap'

import styled from 'styled-components'

import VMUtil from '../../../../../services/util/vmUtil'
import Klix900KeyboardHeader from './Klix900KeyboardHeader'
import Klix900ButtonGroup from './Klix900ButtonGroup'

const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
`

const keyboardConfig = [
  [
    {
      buttonID: '1',
      buttonContent: '1',
    },
    {
      buttonID: '2',
      buttonContent: '2',
    },
    {
      buttonID: '3',
      buttonContent: '3',
    },
  ],
  [
    {
      buttonID: '4',
      buttonContent: '4',
    },
    {
      buttonID: '5',
      buttonContent: '5',
    },
    {
      buttonID: '6',
      buttonContent: '6',
    },
  ],
  [
    {
      buttonID: '7',
      buttonContent: '7',
    },
    {
      buttonID: '8',
      buttonContent: '8',
    },
    {
      buttonID: '9',
      buttonContent: '9',
    },
  ],
  [
    {
      buttonID: '10',
      buttonContent: '10',
    },
    {
      buttonID: '11',
      buttonContent: '11',
    },
    {
      buttonID: '12',
      buttonContent: '12',
    },
  ],
]

const Klix900Keyboard = ({
  onButtonClick = (key: string) => async () => {},
  products = [],
  ledStatuses = [],
  ...rest
}: {
  onButtonClick?: (key: string) => () => Promise<void>,
  products: Array<Object>,
  ledStatuses?: Array<boolean>,
}) => {
  return (
    <>
      <Klix900KeyboardHeader onButtonClick={onButtonClick} />
      <br />
      {keyboardConfig.map((line, lineIdx) => (
        <Row key={`line-${lineIdx}`}>
          {line.map((button, columnIdx) => (
            <FlexCol
              key={`group-${lineIdx}-${columnIdx}`}
              xs="12"
              sm="4"
              md="4"
              lg="4"
              xl="4"
            >
              <Klix900ButtonGroup
                buttonID={button.buttonID}
                buttonContent={button.buttonContent}
                labelContent={VMUtil.getButtonLabel(button, products)}
                onButtonClick={onButtonClick}
                isLedOn={!!ledStatuses[parseInt(button.buttonID)]}
              />
            </FlexCol>
          ))}
        </Row>
      ))}
    </>
  )
}

export default Klix900Keyboard
