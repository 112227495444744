import axios from './api';
import qs from 'qs';

export default {
	getSystemData: (statisticDataID, limitNumber, dayBased) => {
		const param = {
			statisticDataID,
			limitNumber,
			dayBased
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios.get().get(`/statistics/system${query}`);
	},

	getTrafficData: limitNumber => {
		const param = {
			limitNumber
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios.get().get(`/statistics/traffic${query}`);
	},

	getMachineIncomePercents: (machineIDList, fromDate, toDate, download) => {
		return axios.get().post(`/statistics/machineIncomePercents`, {
			vendingMachines: machineIDList,
			fromDate,
			toDate,
			download
		});
	},

	getProductCountPercents: (machineIDList, fromDate, toDate) => {
		return axios.get().post(`/statistics/productCountPercents`, {
			vendingMachines: machineIDList,
			fromDate,
			toDate
		});
	},

	getProductIncomePercents: (machineIDList, fromDate, toDate) => {
		const filterParams = {
			vendingMachines: machineIDList,
			fromDate: fromDate,
			toDate: toDate
		};
		return axios.get().post(`/statistics/productIncomePercents`, filterParams);
	},

	getMachineStateChanges: (machineIDList, fromDate, toDate) => {
		return axios.get().post(`/statistics/machineStateChanges$`, {
			vendingMachines: machineIDList,
			fromDate,
			toDate
		});
	},

	getMachineDailyIncomes: (machineIDList, fromDate, toDate) => {
		return axios.get().post(`/statistics/machineDailyIncomes`, {
			vendingMachines: machineIDList,
			fromDate,
			toDate
		});
	},

	getMachineHourlyIncomes: (machineIDList, fromDate, toDate) => {
		return axios.get().post(`/statistics/machineHourlyIncomes`, {
			vendingMachines: machineIDList,
			fromDate,
			toDate
		});
	},

	getBestAndWorstMachines: () => {
		return axios.get().get(`/statistics/bestAndWorstMachines`);
	},

	getSalesandIncome: param => {
		return axios.get().post(`/statistics/salesAndIncome`, param);
	},

	getUsersPurchasedOnMachine: param => {
		return axios.get().post(`/statistics/usersPurchasedOnMachine`, param);
	}
};
