import { Col, Row, Button } from 'reactstrap';
import React from 'react';
import AutoInfCard from './AutoInfCard';
import { withTranslation } from 'react-i18next';
import { Component } from 'react';
import CustomPeripherialsCard from './CustomPeripherialsCard';
import NfdHardwareCard from './NfdHardwareCard';
import SlaveCard from './SlaveCard';

class Datasheet extends Component {
	renderSheetPanel = () => {
		const { t, root, companyAdmin, regionalAdmin, clients } = this.props;
		return (
			<>
				<Col>
					<AutoInfCard
						machine={this.props.machine}
						root={root}
						companyAdmin={companyAdmin}
						regionalAdmin={regionalAdmin}
						clients={clients}
					></AutoInfCard>
					<Row style={{ justifyContent: 'space-around' }}>
						<Col xs={12} sm={12} md={6} lg={6}>
							<NfdHardwareCard
								vmId={this.props.vmId}
								machine={this.props.machine}
								root={root}
								companyAdmin={companyAdmin}
								regionalAdmin={regionalAdmin}
								reload={this.props.updateMachine}
							></NfdHardwareCard>
							<SlaveCard
								vmId={this.props.vmId}
								machine={this.props.machine}
								root={root}
								companyAdmin={companyAdmin}
								regionalAdmin={regionalAdmin}
								reload={this.props.updateMachine}
							/>
						</Col>
						<Col xs={12} sm={12} md={6} lg={6}>
							<CustomPeripherialsCard
								vmId={this.props.vmId}
								machine={this.props.machine}
								root={root}
								companyAdmin={companyAdmin}
								regionalAdmin={regionalAdmin}
							/>
						</Col>
					</Row>
				</Col>
			</>
		);
	};

	render() {
		const { t } = this.props;
		return this.props.machine ? this.renderSheetPanel() : null;
	}
}

export default withTranslation()(Datasheet);
