import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
// import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Tabs from 'react-bootstrap/Tabs';
// import TabPane from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import ListGroup from 'react-bootstrap/ListGroup';

import DoughnutChart from './DoughnutChart/DoughnutChart';
import StateLineChart from './StateLineChart/StateLineChart';
import HourlyIncomeLineChart from './HourlyIncomeLineChart/HourlyIncomeLineChart';
import DailyIncomeLineChart from './DailyIncomeLineChart/DailyIncomeLineChart';
// import SalesBar from './NewSalesList/SalesBar/Salesbar'

class SalesData extends Component {
	state = {
		selectedMachines: []
	};

	render() {
		let chart = null;
		if (
			String(this.props.chartType) === '1' ||
			String(this.props.chartType) === '2' ||
			String(this.props.chartType) === '3'
		) {
			chart = (
				<DoughnutChart
					xValues={this.props.xValues}
					yValues={this.props.yValues}
				/>
			);
		}


		if (String(this.props.chartType) === '4') {
			chart = <StateLineChart series={this.props.series} />;
		}

		if (String(this.props.chartType) === '5') {
			chart = <DailyIncomeLineChart series={this.props.series} />;
		}

		if (String(this.props.chartType) === '6') {
			chart = <HourlyIncomeLineChart series={this.props.series} />;
		}

		//const { t } = this.props;
		return (
			<Card>
				<Card.Body>
					<Row>
						<Col sm="12">{chart}</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	}
}
/*
						<Tabs>
							<TabPane title={t('sales_list.chart.header')}></TabPane>
							<TabPane title={t('sales_list.table.header')}></TabPane>
						</Tabs>
*/
export default withTranslation()(SalesData);
