function getMachineStatusLocID(status) {
	const statuses = {
		0: 'device.status.operational',
		1: 'device.status.programming',
		2: 'device.status.warning',
		3: 'device.status.error'
	};

	return status != null && status != undefined
		? statuses[status]
		: 'device.status.unknown';
}

export default getMachineStatusLocID;
