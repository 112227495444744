import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
// Styles
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';

// Import Main styles for this application
import './assets/scss/style.scss';
// Temp fix for reactstrap
import './assets/scss/core/_dropdown-menu-right.scss';

import './assets/font/lcd.css';
import './assets/TabTheme.css';
import './assets/pagination.css';
// Containers
import Full from './containers/Full/Full';
import Login from './views/Login/Login';
import forgotPassw from './views/ForgotPassw/forgotPassw';
import SetPassw from './views/SetPassword/setPassw';
import RegistrationForm from './views/RegistrationForm/RegistrationForm';

import API from './services/api/api';

import { Provider } from 'react-redux';
import store from './store';
import { deleteUser } from './store/user';
import { ToastContainer } from 'react-toastify';

//React DatePicker
import 'react-datepicker/dist/react-datepicker.css';

// Sentry
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (module.hot) {
	module.hot.accept();
}

API.setUnauthorizedCallback(error => {
	store.dispatch(deleteUser());
});

const PrivateRoute = ({ component: Component, ...rest }) => {
	const value = store.getState().user !== null;

	return (
		<Route
			{...rest}
			render={props =>
				value === true ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: '/login', state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

//Ez az alerteknek kell
const containerStyle = {
	zIndex: 1999
};

// Sentry Integration
if (
	process.env.REACT_APP_NODE_ENV &&
	process.env.REACT_APP_NODE_ENV.toLocaleUpperCase() == 'PRODUCTION'
) {
	Sentry.init({
		dsn:
			'https://23e5866b4a3b40cc872168d4372c8959@o351135.ingest.sentry.io/6055806',
		integrations: [new Integrations.BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0
	});
}

//<Route path="/registration/:cmID" name="Reg" component={Regist} />
ReactDOM.render(
	<>
		<ToastContainer
			position="top-right"
			autoClose={5000}
			style={containerStyle}
		/>
		<Provider store={store}>
			<HashRouter>
				<Switch>
					<Route path="/login" name="Login" component={Login} />

					<Route path="/forgotPassw" name="Reg" component={forgotPassw} />
					<Route path="/setPassw/:key" name="SetPassw" component={SetPassw} />
					<Route
						path="/registration/:key"
						name="Registration"
						component={RegistrationForm}
					/>
					<PrivateRoute path="/app" component={Full} />
					<Route path="/app" name="Home" component={Full} />
					<Route path="/" name="Login" onEnter component={Full} />
					<Redirect from="/" to="/app" />
				</Switch>
			</HashRouter>
		</Provider>
	</>,
	document.getElementById('root')
);
