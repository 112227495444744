import moment from 'moment';
import store from '../../store';
import { setItem } from '../../store/table';

export default class {
	static summaryParameters = {
		INCOME: 'INCOME',
		PRICE: 'PRICE',
		WALLET_TURNOVER: 'WALLET_TURNOVER',
		TRANSACTIONS: 'TRANSACTIONS',
		CASH: 'CASH',
		PURCHASE_CASH: 'PURCHASE_CASH',
		PURCHASE_CASHLESS: 'PURCHASE_CASHLESS',
		PURCHASE_WALLET: 'PURCHASE_WALLET',
		TOP_UP_CASH: 'TOP_UP_CASH',
		TOP_UP_CASHLESS: 'TOP_UP_CASHLESS',
		PRICE_SUM: 'PRICE_SUM',
		INCOME_SUM: 'INCOME_SUM',
		TRANSACTION_SUM: 'TRANSACTION_SUM',
		FAILED_TRANSACTIONS_SUM: 'FAILED_TRANSACTIONS_SUM',
		CASH_SUM: 'CASH_SUM',
		PURCHASE_CASH_TR_SUM: 'PURCHASE_CASH_TR_SUM',
		PURCHASE_CASHLESS_TR_SUM: 'PURCHASE_CASHLESS_TR_SUM',
		PURCHASE_WALLET_TR_SUM: 'PURCHASE_WALLET_TR_SUM',
		PURCHASE_UNDEFINED_SUM: 'PURCHASE_UNDEFINED_SUM',
		TOP_UP_CASH_TR_SUM: 'TOP_UP_CASH_TR_SUM',
		TOP_UP_CASHLESS_TR_SUM: 'TOP_UP_CASHLESS_TR_SUM',
		PURCHASE_CASH_INCOME_SUM: 'PURCHASE_CASH_INCOME_SUM',
		PURCHASE_CASHLESS_INCOME_SUM: 'PURCHASE_CASHLESS_INCOME_SUM',
		PURCHASE_UNDEFINED_INCOME_SUM: 'PURCHASE_UNDEFINED_INCOME_SUM',
		TOP_UP_CASH_INCOME_SUM: 'TOP_UP_CASH_INCOME_SUM',
		TOP_UP_CASHLESS_INCOME_SUM: 'TOP_UP_CASHLESS_INCOME_SUM',
		PURCHASE_CASH_PRICE_SUM: 'PURCHASE_CASH_PRICE_SUM',
		PURCHASE_CASHLESS_PRICE_SUM: 'PURCHASE_CASHLESS_PRICE_SUM',
		PURCHASE_WALLET_PRICE_SUM: 'PURCHASE_WALLET_PRICE_SUM',
		PURCHASE_UNDEFINED_PRICE_SUM: 'PURCHASE_UNDEFINED_PRICE_SUM',
		TOP_UP_CASH_STOCK: 'TOP_UP_CASH_STOCK',
		PURCHASE_CASH_STOCK: 'PURCHASE_CASH_STOCK',
		VALUE: 'VALUE'
	};
	static creditSourceTypes = {
		LCD: 0,
		EXE: 1,
		CASH: 2,
		CD1: 3,
		CD2: 4,
		WALLET: 5,
		CD: 6
	};

	static paymentTransactionTypes = {
		PAYMENT: 1,
		PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE: 2
	};

	static breakDownOptions = {
		HOURLY: 'YY.MM.DD HH[h]',
		DAILY: 'YY. MMM. DD',
		MONTHLY: 'YYYY. MMM',
		YEARLY: 'YYYY'
	};

	static isCashPaymentSource(creditSource) {
		return (
			creditSource == this.creditSourceTypes.CASH ||
			creditSource == this.creditSourceTypes.EXE ||
			creditSource == this.creditSourceTypes.LCD
		);
	}

	static isCashlessPaymentSource(creditSource) {
		return (
			creditSource == this.creditSourceTypes.CD ||
			creditSource == this.creditSourceTypes.CD1 ||
			creditSource == this.creditSourceTypes.CD2
		);
	}

	static isUndefinedPaymentSource(creditSource) {
		return creditSource == null;
	}

	static formatSum = (param, value) => {
		let total = 0;
		let result = [];
		switch (param) {
			case this.summaryParameters.INCOME:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].income &&
						(value[i].transactionType ===
							this.paymentTransactionTypes
								.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE ||
							!this.isUndefinedPaymentSource(value[i].paymentMethod))
					) {
						result.push(value[i].income);
					}
				}
				break;

			case this.summaryParameters.PRICE:
				for (let i = 0; i < value.length; i++) {
					if (value[i].price) {
						result.push(value[i].price);
					}
				}
				break;
			case this.summaryParameters.WALLET_TURNOVER:
				for (let i = 0; i < value.length; i++) {
					if (value[i].price) {
						result.push(value[i].turnover);
					}
				}
				break;

			case this.summaryParameters.CASH:
				for (let i = 0; i < value.length; i++) {
					if (
						(value[i].income &&
							this.isCashPaymentSource(value[i].paymentMethod)) ||
						value[i].transactionType ===
							this.paymentTransactionTypes.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE
					) {
						result.push(value[i].income);
					}
				}
				break;
			case this.summaryParameters.PURCHASE_CASH_INCOME_SUM:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].income &&
						this.isCashPaymentSource(value[i].paymentMethod) &&
						value[i].transactionType === this.paymentTransactionTypes.PAYMENT
					) {
						result.push(value[i].income);
					}
				}
				break;
			case this.summaryParameters.PURCHASE_CASHLESS_INCOME_SUM:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].income &&
						this.isCashlessPaymentSource(value[i].paymentMethod) &&
						value[i].transactionType === this.paymentTransactionTypes.PAYMENT
					) {
						result.push(value[i].income);
					}
				}
				break;
			case this.summaryParameters.TOP_UP_CASH_INCOME_SUM:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].income &&
						value[i].transactionType ===
							this.paymentTransactionTypes.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE
					) {
						result.push(value[i].income);
					}
				}
				break;
			case this.summaryParameters.TOP_UP_CASHLESS_INCOME_SUM: //TODO. We removed the barion transactions from this table
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].income &&
						this.isCashlessPaymentSource(value[i].paymentMethod) &&
						value[i].transactionType ===
							this.paymentTransactionTypes.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE
					) {
						result.push(value[i].income);
					}
				}
				break;
			case this.summaryParameters.PURCHASE_UNDEFINED_INCOME_SUM:
				break;
			case this.summaryParameters.PURCHASE_CASH_PRICE_SUM:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].price &&
						this.isCashPaymentSource(value[i].paymentMethod) &&
						value[i].transactionType === this.paymentTransactionTypes.PAYMENT
					) {
						result.push(value[i].price);
					}
				}
				break;
			case this.summaryParameters.PURCHASE_CASHLESS_PRICE_SUM:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].price &&
						this.isCashlessPaymentSource(value[i].paymentMethod) &&
						value[i].transactionType === this.paymentTransactionTypes.PAYMENT
					) {
						result.push(value[i].price);
					}
				}
				break;
			case this.summaryParameters.PURCHASE_WALLET_PRICE_SUM:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].price &&
						value[i].paymentMethod == this.creditSourceTypes.WALLET &&
						value[i].transactionType === this.paymentTransactionTypes.PAYMENT
					) {
						result.push(value[i].price);
					}
				}
				break;
			case this.summaryParameters.PURCHASE_UNDEFINED_PRICE_SUM:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].price &&
						this.isUndefinedPaymentSource(value[i].paymentMethod) &&
						value[i].transactionType === this.paymentTransactionTypes.PAYMENT
					) {
						result.push(value[i].price);
					}
				}
				break;
			case this.summaryParameters.TOP_UP_CASH_STOCK:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].income &&
						value[i].transactionType ===
							this.paymentTransactionTypes.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE
					) {
						result.push(value[i].income);
					}
				}
				break;
			case this.summaryParameters.PURCHASE_CASH_STOCK:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].income &&
						this.isCashPaymentSource(value[i].paymentMethod) &&
						value[i].transactionType === this.paymentTransactionTypes.PAYMENT
					) {
						result.push(value[i].income);
					}
				}
				break;
		}

		let res = result.map((s, idx) => ({
			key: idx,
			res: s
		}));
		for (let i = 0; i < res.length; i++) {
			total = total + res[i].res;
		}
		return total.toFixed(2);
	};

	static formatTransaction = (param, value) => {
		let total = 0;
		let par = [];
		switch (param) {
			case this.summaryParameters.TRANSACTIONS:
				total = value.length;
				break;
			case this.summaryParameters.PURCHASE_CASH:
				for (let i = 0; i < value.length; i++) {
					if (
						this.isCashPaymentSource(value[i].paymentMethod) &&
						value[i].transactionType === this.paymentTransactionTypes.PAYMENT
					) {
						par.push(value[i]);
					}
				}
				total = par.length;
				break;
			case this.summaryParameters.PURCHASE_CASHLESS:
				for (let i = 0; i < value.length; i++) {
					if (
						this.isCashlessPaymentSource(value[i].paymentMethod) &&
						value[i].transactionType === this.paymentTransactionTypes.PAYMENT
					) {
						par.push(value[i]);
					}
				}
				total = par.length;
				break;

			case this.summaryParameters.PURCHASE_WALLET:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].paymentMethod === this.creditSourceTypes.WALLET &&
						value[i].transactionType === this.paymentTransactionTypes.PAYMENT
					) {
						par.push(value[i]);
					}
				}
				total = par.length;
				break;
			case this.summaryParameters.PURCHASE_UNDEFINED_SUM:
				for (let i = 0; i < value.length; i++) {
					if (
						this.isUndefinedPaymentSource(value[i].paymentMethod) &&
						value[i].transactionType === this.paymentTransactionTypes.PAYMENT
					) {
						par.push(value[i]);
					}
				}
				total = par.length;
				break;
			case this.summaryParameters.TOP_UP_CASH:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].transactionType ===
						this.paymentTransactionTypes.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE
					) {
						par.push(value[i]);
					}
				}

				total = par.length;
				break;
			case this.summaryParameters.TOP_UP_CASHLESS:
				for (let i = 0; i < value.length; i++) {
					if (
						value[i].transactionType ===
							this.paymentTransactionTypes
								.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE &&
						this.isCashlessPaymentSource(value[i].paymentMethod)
					) {
						par.push(value[i]);
					}
				}

				total = par.length;
				break;
		}
		return total;
	};

	static addLocalStorage = (
		priceSum,
		incomeSum,
		transactionSum,
		cashSum,
		purchaseCashTrSum,
		purchaseCashlessTrSum,
		purchaseWalletTrSum,
		topUpCashTrSum,
		topUpCashlessTrSum,
		purchaseCashIncomeSum,
		purchaseCashlessIncomeSum,
		topUpCashIncomeSum,
		topUpCashlessIncomeSum,
		purchaseCashPriceSum,
		purchaseCashlessPriceSum,
		purchaseWalletPriceSum,
		purchaseCashStock,
		topUpCashStock,
		failedTransactionSum,
		undefinedPaymentSource,
		undefinedPaymentSourcePriceSum
	) => {
		store.dispatch(setItem(this.summaryParameters.PRICE_SUM, priceSum));
		localStorage.setItem(this.summaryParameters.PRICE_SUM, priceSum);
		localStorage.setItem(this.summaryParameters.INCOME_SUM, incomeSum);
		localStorage.setItem(
			this.summaryParameters.TRANSACTION_SUM,
			transactionSum
		);
		localStorage.setItem(
			this.summaryParameters.FAILED_TRANSACTIONS_SUM,
			failedTransactionSum
		);
		localStorage.setItem(this.summaryParameters.CASH_SUM, cashSum);
		localStorage.setItem(
			this.summaryParameters.PURCHASE_CASH_TR_SUM,
			purchaseCashTrSum
		);
		localStorage.setItem(
			this.summaryParameters.PURCHASE_CASHLESS_TR_SUM,
			purchaseCashlessTrSum
		);
		localStorage.setItem(
			this.summaryParameters.PURCHASE_WALLET_TR_SUM,
			purchaseWalletTrSum
		);
		localStorage.setItem(
			this.summaryParameters.TOP_UP_CASH_TR_SUM,
			topUpCashTrSum
		);
		localStorage.setItem(
			this.summaryParameters.TOP_UP_CASHLESS_TR_SUM,
			topUpCashlessTrSum
		);
		localStorage.setItem(
			this.summaryParameters.PURCHASE_CASH_INCOME_SUM,
			purchaseCashIncomeSum
		);
		localStorage.setItem(
			this.summaryParameters.PURCHASE_CASHLESS_INCOME_SUM,
			purchaseCashlessIncomeSum
		);
		localStorage.setItem(
			this.summaryParameters.TOP_UP_CASH_INCOME_SUM,
			topUpCashIncomeSum
		);
		localStorage.setItem(
			this.summaryParameters.TOP_UP_CASHLESS_INCOME_SUM,
			topUpCashlessIncomeSum
		);
		localStorage.setItem(
			this.summaryParameters.PURCHASE_CASH_PRICE_SUM,
			purchaseCashPriceSum
		);
		localStorage.setItem(
			this.summaryParameters.PURCHASE_CASHLESS_PRICE_SUM,
			purchaseCashlessPriceSum
		);
		localStorage.setItem(
			this.summaryParameters.PURCHASE_WALLET_PRICE_SUM,
			purchaseWalletPriceSum
		);

		localStorage.setItem(
			this.summaryParameters.PURCHASE_CASH_STOCK,
			purchaseCashStock
		);
		localStorage.setItem(
			this.summaryParameters.TOP_UP_CASH_STOCK,
			topUpCashStock
		);

		// Undefined Payment Source
		localStorage.setItem(
			this.summaryParameters.PURCHASE_UNDEFINED_SUM,
			undefinedPaymentSource
		);
		localStorage.setItem(
			this.summaryParameters.PURCHASE_UNDEFINED_PRICE_SUM,
			undefinedPaymentSourcePriceSum
		);
	};

	static getLocalStorage = () => {
		document.getElementById(
			this.summaryParameters.PRICE_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PRICE_SUM)
		).toFixed(2);
		document.getElementById(
			this.summaryParameters.INCOME_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.INCOME_SUM)
		).toFixed(2);
		document.getElementById(
			this.summaryParameters.TRANSACTION_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.TRANSACTION_SUM)
		);
		document.getElementById(
			this.summaryParameters.FAILED_TRANSACTIONS_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.FAILED_TRANSACTIONS_SUM)
		);
		document.getElementById(this.summaryParameters.CASH_SUM).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.CASH_SUM)
		).toFixed(2);
		document.getElementById(
			this.summaryParameters.PURCHASE_CASH_TR_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PURCHASE_CASH_TR_SUM)
		);
		document.getElementById(
			this.summaryParameters.PURCHASE_CASHLESS_TR_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PURCHASE_CASHLESS_TR_SUM)
		);
		document.getElementById(
			this.summaryParameters.PURCHASE_WALLET_TR_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PURCHASE_WALLET_TR_SUM)
		);
		document.getElementById(
			this.summaryParameters.TOP_UP_CASH_TR_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.TOP_UP_CASH_TR_SUM)
		);
		document.getElementById(
			this.summaryParameters.TOP_UP_CASHLESS_TR_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.TOP_UP_CASHLESS_TR_SUM)
		);
		document.getElementById(
			this.summaryParameters.PURCHASE_CASH_INCOME_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PURCHASE_CASH_INCOME_SUM)
		).toFixed(2);
		document.getElementById(
			this.summaryParameters.PURCHASE_CASHLESS_INCOME_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PURCHASE_CASHLESS_INCOME_SUM)
		).toFixed(2);
		document.getElementById(
			this.summaryParameters.TOP_UP_CASH_INCOME_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.TOP_UP_CASH_INCOME_SUM)
		).toFixed(2);
		document.getElementById(
			this.summaryParameters.TOP_UP_CASHLESS_INCOME_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.TOP_UP_CASHLESS_INCOME_SUM)
		).toFixed(2);
		document.getElementById(
			this.summaryParameters.PURCHASE_CASH_PRICE_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PURCHASE_CASH_PRICE_SUM)
		).toFixed(2);
		document.getElementById(
			this.summaryParameters.PURCHASE_CASHLESS_PRICE_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PURCHASE_CASHLESS_PRICE_SUM)
		).toFixed(2);
		document.getElementById(
			this.summaryParameters.PURCHASE_WALLET_PRICE_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PURCHASE_WALLET_PRICE_SUM)
		).toFixed(2);

		document.getElementById(
			this.summaryParameters.PURCHASE_CASH_STOCK
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PURCHASE_CASH_STOCK)
		).toFixed(2);
		document.getElementById(
			this.summaryParameters.TOP_UP_CASH_STOCK
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.TOP_UP_CASH_STOCK)
		).toFixed(2);

		//Undefined payment Source
		document.getElementById(
			this.summaryParameters.PURCHASE_UNDEFINED_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PURCHASE_UNDEFINED_SUM)
		).toFixed(0);

		document.getElementById(
			this.summaryParameters.PURCHASE_UNDEFINED_PRICE_SUM
		).innerHTML = Number(
			localStorage.getItem(this.summaryParameters.PURCHASE_UNDEFINED_PRICE_SUM)
		).toFixed(2);
	};

	static setParamToStore = value => {
		const transactionsWithoutFailed = value.filter(
			trans => !trans.purchaseFailed || trans.purchaseFailed != 1
		);
		const failedTransactions = value.filter(
			trans => trans.purchaseFailed && trans.purchaseFailed == 1
		);
		this.addLocalStorage(
			this.formatSum(this.summaryParameters.PRICE, transactionsWithoutFailed),
			this.formatSum(this.summaryParameters.INCOME, transactionsWithoutFailed),
			this.formatTransaction(
				this.summaryParameters.TRANSACTIONS,
				transactionsWithoutFailed
			),
			this.formatSum(this.summaryParameters.CASH, transactionsWithoutFailed),
			this.formatTransaction(
				this.summaryParameters.PURCHASE_CASH,
				transactionsWithoutFailed
			),
			this.formatTransaction(
				this.summaryParameters.PURCHASE_CASHLESS,
				transactionsWithoutFailed
			),
			this.formatTransaction(
				this.summaryParameters.PURCHASE_WALLET,
				transactionsWithoutFailed
			),
			this.formatTransaction(
				this.summaryParameters.TOP_UP_CASH,
				transactionsWithoutFailed
			),
			this.formatTransaction(
				this.summaryParameters.TOP_UP_CASHLESS,
				transactionsWithoutFailed
			),
			this.formatSum(
				this.summaryParameters.PURCHASE_CASH_INCOME_SUM,
				transactionsWithoutFailed
			),
			this.formatSum(
				this.summaryParameters.PURCHASE_CASHLESS_INCOME_SUM,
				transactionsWithoutFailed
			),
			this.formatSum(
				this.summaryParameters.TOP_UP_CASH_INCOME_SUM,
				transactionsWithoutFailed
			),
			this.formatSum(
				this.summaryParameters.TOP_UP_CASHLESS_INCOME_SUM,
				transactionsWithoutFailed
			),
			this.formatSum(
				this.summaryParameters.PURCHASE_CASH_PRICE_SUM,
				transactionsWithoutFailed
			),
			this.formatSum(
				this.summaryParameters.PURCHASE_CASHLESS_PRICE_SUM,
				transactionsWithoutFailed
			),
			this.formatSum(
				this.summaryParameters.PURCHASE_WALLET_PRICE_SUM,
				transactionsWithoutFailed
			),
			this.formatSum(
				this.summaryParameters.PURCHASE_CASH_STOCK,
				transactionsWithoutFailed
			),
			this.formatSum(
				this.summaryParameters.TOP_UP_CASH_STOCK,
				transactionsWithoutFailed
			),
			failedTransactions ? failedTransactions.length : 0,
			this.formatTransaction(
				this.summaryParameters.PURCHASE_UNDEFINED_SUM,
				transactionsWithoutFailed
			),
			this.formatSum(
				this.summaryParameters.PURCHASE_UNDEFINED_PRICE_SUM,
				transactionsWithoutFailed
			)
		);
	};
	// Sals and Income chart dataformat---------------

	static mapIncomeData = data => {
		let newArray = data;

		newArray = newArray.map(e => ({
			createdAt: e.createdAt,
			income: e.income,
			price: e.price,
			paymentMethod: e.paymentMethod,
			transactionType: e.transactionType
		}));
		return newArray;
	};
	static mapTrType = data => {
		let output = [];
		if (data)
			data.map(e => {
				if (
					e.paymentMethod === this.creditSourceTypes.MACHINE_CASH_PAYMENT &&
					e.transactionType === this.paymentTransactionTypes.PAYMENT
				) {
					return output.push({
						createdAt: e.createdAt,
						purchaseCashIncome: e.income ? e.income : 0,
						purchaseCashPrice: e.price ? e.price : 0
					});
				}
				if (
					e.paymentMethod === this.creditSourceTypes.MACHINE_CARD_PAYMENT &&
					e.transactionType === this.paymentTransactionTypes.PAYMENT
				) {
					return output.push({
						createdAt: e.createdAt,
						purchaseCashlessIncome: e.income ? e.income : 0,
						purchaseCashlessPrice: e.price ? e.price : 0
					});
				}
				if (
					e.transactionType ===
					this.paymentTransactionTypes.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE
				) {
					return output.push({
						createdAt: e.createdAt,
						topUpCashIncome: e.income ? e.income : 0,
						topUpCashPrice: e.price ? e.price : 0
					});
				}
				if (
					e.transactionType === this.paymentTransactionTypes.BARION_CREDIT_ENTRY
				) {
					return output.push({
						createdAt: e.createdAt,
						topUpCashlessIncome: e.income ? e.income : 0,
						topUpCashlessPrice: e.price ? e.price : 0
					});
				}
				if (
					e.paymentMethod === this.creditSourceTypes.INV_TOUCH_PAYMENT &&
					e.transactionType ===
						this.paymentTransactionTypes.PAYMENT_TROUGH_INVISIBLE_TOUCH
				) {
					return output.push({
						createdAt: e.createdAt,
						walletIncome: e.income ? e.income : 0,
						walletPrice: e.price ? e.price : 0
					});
				}
			});
		return output;
	};
	static mapDate = (data, dateFormat) => {
		let d = data;
		d.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));
		d.forEach(e => {
			e.createdAt = moment(e.createdAt).format(dateFormat);
		});
		return d;
	};

	static groupBy = (array, key) => {
		return array.reduce((rv, x) => {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};
	static sumKey = (key, e) => {
		return e.reduce((a, b) => a + (b[key] || 0), 0);
	};
	static formatData = (data, dateFormat) => {
		let finalData = [];
		Object.values(
			this.groupBy(
				this.mapDate(this.mapTrType(this.mapIncomeData(data)), dateFormat),
				'createdAt'
			)
		).map(e => {
			finalData.push({
				createdAt: e[0].createdAt,
				purchaseCashIncome: this.sumKey('purchaseCashIncome', e),
				purchaseCashPrice: this.sumKey('purchaseCashPrice', e),
				purchaseCashlessIncome: this.sumKey('purchaseCashlessIncome', e),
				purchaseCashlessPrice: this.sumKey('purchaseCashlessPrice', e),
				topUpCashIncome: this.sumKey('topUpCashIncome', e),
				topUpCashPrice: this.sumKey('topUpCashPrice', e),
				topUpCashlessIncome: this.sumKey('topUpCashlessIncome', e),
				topUpCashlessPrice: this.sumKey('topUpCashlessPrice', e),
				walletIncome: this.sumKey('walletIncome', e),
				walletPrice: this.sumKey('walletPrice', e)
			});
		});

		return finalData;
	};
}
