//@flow
import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';

const AlphanumericMachineButton = styled.button`
	width: 100%;
	padding: 10px;
	background-color: ${props => (props.isRed ? '#f44336' : '#78909c')};
	color: white;
	border-radius: 5px;
	border: none;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	margin: 2px;
	min-height: 90px;
	font-weight: 600;
	font-size: large;
	height: 100%;
	&:active {
		background-color: #61ce8b;
	}
`;
const AlphanumericMachineButtonContainer = styled(Col)`
	margin: 2px;
	padding: 2px;
`;

type Props = {
	products: Array<Object>,
	keyboardConfig: Array<
		Array<{
			buttonContent: any,
			buttonID: string,
			ledIndex?: number
		}>
	>,
	onButtonClick?: (key: string) => () => Promise<void>
};

export default class AlphanumericMachineKeyboard extends React.Component<Props> {
	render() {
		const {
			onButtonClick = (key: string) => () => {},
			keyboardConfig = []
		} = this.props;
		return (
			<>
				{keyboardConfig.map((configs, lineIndex) => (
					<Row
						key={`machine-line-${lineIndex}`}
						className="justify-content-center"
					>
						{configs.map((config, idx) => (
							<AlphanumericMachineButtonContainer
								xs="3"
								md="3"
								lg="3"
								xl="3"
								key={`machine-button-${lineIndex}-${idx}`}
							>
								<AlphanumericMachineButton
									onClick={onButtonClick(config.buttonID)}
									{...config}
								>
									{config.buttonContent}
								</AlphanumericMachineButton>
							</AlphanumericMachineButtonContainer>
						))}
					</Row>
				))}
			</>
		);
	}
}
