import React, { Component } from 'react';
import { Card, Row, CardBody, Col, CardTitle, Label } from 'reactstrap';

import Modal from 'react-bootstrap/Modal';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import FilterBar from '../../FilterBar/FilterBar';
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';
import AddProductGroupForm from './AddProductGroupForm/AddProductGroupForm';
import EditProductGroupForm from './EditProductGroupForm/EditProductGroupForm';
import ProductSelectedTable from './AddProductGroupForm/ProductSelectedTable';
import ProductGroupTable from './ProductGroupTable/ProductGroupTable';
import HelperTooltip from '../../../components/UI/HelperTooltip/HelperTooltip';

import { withTranslation } from 'react-i18next';

class ProductGroupManagerTab extends Component {
	state = {
		productGroups: [],
		selectedProducts: [],
		products: []
	};

	//GET proxy machineGroups data
	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		// too do
	};

	//Render Filterbar with ADDButton

	renderFilterBar = () => {
		return (
			<FilterBar
				key="filterbar"
				showAddButton
				onShowCreateItem={() => this.handleAddButtonClick()}
				onFilterChanged={value => this.onFilterChange(value)}
			/>
		);
	};

	filterProductGroups() {
		let filteredProductGroups = [...this.state.productGroups];
		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredProductGroups = this.state.productGroups.filter(productGroups => {
				let curProductGroups = { ...productGroups };
				let productGroupsFieldArray = Object.values(curProductGroups);
				return productGroupsFieldArray.some(
					val =>
						String(val)
							.toUpperCase()
							.indexOf(filterValue) >= 0
				);
			});
		}

		this.setState({ filteredProductGroups: filteredProductGroups });
	}

	onFilterChange(filterValue) {
		this.setState({ currentFilterValue: filterValue }, () =>
			this.filterProductGroups()
		);
	}

	handleAddButtonClick = () => {
		this.showAddDialog();
	};

	//AddMachineGroups rendering and handleing

	showAddDialog() {
		this.setState({ showAddMachineGroupsDialog: true });
	}

	hideAddDialog() {
		this.props.defaultTab('ProductGroups');
		this.setState({ showAddMachineGroupsDialog: false });
		this.loadData();
	}

	renderAddProductGroupsDialog() {
		return (
			<Modal
				size="lg"
				show={this.state.showAddMachineGroupsDialog}
				onHide={() => this.hideAddDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i class="fa fa-archive" style={{ marginRight: '10px' }}></i>
						{this.props.t('groupManager.addGroup.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AddProductGroupForm
						products={this.state.products}
						onHide={() => this.hideAddDialog()}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	//editProfil------------------------------------------
	showList = value => {
		this.setState({ selectedProducts: value.products });
	};

	editProfil = group => {
		this.showEditDialog(group);
	};

	showEditDialog(group) {
		this.setState({ showEditMachineGroupsDialog: true, groupToEdit: group });
	}

	hideEditDialog() {
		this.props.defaultTab('ProductGroups');
		this.setState({
			showEditMachineGroupsDialog: false
		});
		this.loadData();
	}

	onDeleteGroup = group => {
		this.setState({ groupToDelete: group });
	};

	onDeleteGroupConfirmed = () => {
		//too do
	};

	//  render DeletConfirmation
	renderDeleteConfirmation() {
		if (this.state.groupToDelete) {
			return (
				<ConfirmationDialog
					isOpen={this.state.groupToDelete}
					onToggle={() => this.setState({ groupToDelete: null })}
					title={this.props.t('groupManager.deleteGroup.title')}
					// {this.props.t('customerlist.deletebutton.title')}
					confirmationText={this.props.t('groupManager.deleteGroup.label')}
					// {this.props.t(
					//     'customerlist.deletebutton.label')}
					cancelText={this.props.t('groupManager.deleteGroup.cancelButton')}
					okText={this.props.t('groupManager.deleteGroup.okButton')}
					onCancel={() => this.setState({ groupToDelete: null })}
					onOk={() => this.onDeleteGroupConfirmed()}
				/>
			);
		} else {
			return null;
		}
	}

	// render EditDialog
	renderEditProductGroupsDialog() {
		return (
			<Modal
				size="lg"
				show={this.state.showEditMachineGroupsDialog}
				onHide={() => this.hideEditDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i class="fa fa-archive" style={{ marginRight: '10px' }}></i>
						{this.props.t('groupManager.editGroup.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: 'GhostWhite' }}>
					<EditProductGroupForm
						onHide={() => this.hideEditDialog()}
						group={this.state.groupToEdit}
						allProducts={this.state.products}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	//-----------------------------------------------------------------
	renderProductTable() {
		return (
			<ProductGroupTable
				data={this.state.filteredProductGroups}
				onDeleteGroup={this.onDeleteGroup}
				editProfil={this.editProfil}
				renderFilterBar={this.renderFilterBar}
				onFilterChange={this.onFilterChange}
				showList={this.showList}
			/>
		);
	}

	//Render Title
	renderProductTitle() {
		const { t, titleOnClick = () => {} } = this.props;

		return (
			<Row>
				<Col sm={8}>
					<Row style={{ paddingLeft: '15px', alignItems: 'center' }}>
						<CardTitle
							className="text-muted"
							style={{ cursor: 'pointer' }}
							onClick={titleOnClick}
						>
							<i className={`fa fa-archive`} style={{ marginRight: '10px' }} />
							{t('groupManager.productsTab.label')}
						</CardTitle>
						<Col>
							<HelperTooltip
								height={'100px'}
								width={'500px'}
								direction={'right'}
								id={'productGroupmanager'}
								title={t('groupManager.productsTab.tooltip.title')}
								content={t('groupManager.productsTab.tooltip.content')}
							></HelperTooltip>
						</Col>
					</Row>
				</Col>
				<Col sm={4}>
					<CardTitle
						className="text-muted"
						style={{ cursor: 'pointer' }}
						onClick={titleOnClick}
					>
						{t('groupManager.productsTab.displayedLabel')}
					</CardTitle>
				</Col>
			</Row>
		);
	}

	//Render!
	render() {
		const { t } = this.props;
		if (!this.props.dev) {
			return (
				<div className="animated fadeIn">
					{this.renderEditProductGroupsDialog()}
					{this.renderAddProductGroupsDialog()}
					{this.renderDeleteConfirmation()}
					<Card className={this.props.className} style={this.props.style}>
						<CardBody>
							{this.renderProductTitle()}
							<Row>
								<Col sm="8">{this.renderProductTable()}</Col>
								<Col sm="4">
									<ProductSelectedTable
										data={this.state.selectedProducts}
										height="420"
									/>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			);
			// ON Dev.
		} else {
			return (
				<Card body>
					<Label className="h1">{t('groupManager.dev.title')}</Label>
					<Label className="h5">{t('groupManager.dev.content')}</Label>
				</Card>
			);
		}
	}
}

export default withTranslation()(ProductGroupManagerTab);
