import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap';
import classNames from 'classnames';
import nav from './_nav';
import SidebarFooter from './../SidebarFooter/SidebarFooter';
import SidebarForm from './../SidebarForm/SidebarForm';
import SidebarHeader from './../SidebarHeader/SidebarHeader';
import SidebarMinimizer from './../SidebarMinimizer/SidebarMinimizer';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class Sidebar extends Component {
	state = {
		activeRoute: null
	};
	handleClick = e => {
		e.preventDefault();
		e.target.parentElement.classList.toggle('open');
		const activeRoute = e.target.parentElement.id;
		this.setState({ activeRoute: activeRoute });
	};

	activeRoute = (routeName, props, value, item) => {
		if (value) {
			if (value === item) {
				return 'nav-item nav-dropdown open';
			}
			if (value !== item) {
				return 'nav-item nav-dropdown';
			}
		} else {
			return props.location.pathname.indexOf(routeName) > -1
				? 'nav-item nav-dropdown open'
				: 'nav-item nav-dropdown';
		}
	};

	hideMobile = () => {
		if (document.body.classList.contains('sidebar-mobile-show')) {
			document.body.classList.toggle('sidebar-mobile-show');
		}
	};

	// todo Sidebar nav secondLevel
	// secondLevelActive(routeName) {
	//   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
	// }

	render() {
		const props = this.props;

		// badge addon to NavItem
		const badge = badge => {
			if (badge) {
				const classes = classNames(badge.class);
				return (
					<Badge className={classes} color={badge.variant}>
						{badge.text}
					</Badge>
				);
			}
		};

		// simple wrapper for nav-title item
		const wrapper = item => {
			return item.wrapper && item.wrapper.element
				? React.createElement(
						item.wrapper.element,
						item.wrapper.attributes,
						item.name
				  )
				: item.name;
		};

		// nav list section title
		const title = (title, key) => {
			const classes = classNames('nav-title', title.class);
			return (
				<li key={key} className={classes}>
					{wrapper(title)}{' '}
				</li>
			);
		};

		// nav list divider
		const divider = (divider, key) => {
			const classes = classNames('divider', divider.class);
			return <li key={key} className={classes} />;
		};

		// nav label with nav link
		const navLabel = (item, key) => {
			const classes = {
				item: classNames('hidden-cn', item.class),
				link: classNames('nav-label', item.class ? item.class : ''),
				icon: classNames(
					!item.icon ? 'fa fa-circle' : item.icon,
					item.label.variant ? `text-${item.label.variant}` : '',
					item.label.class ? item.label.class : ''
				)
			};
			return navLink(item, key, classes);
		};

		// nav item with nav link
		const navItem = (item, key) => {
			const classes = {
				item: classNames(item.class),
				link: classNames(
					'nav-link',
					item.variant ? `nav-link-${item.variant}` : ''
				),
				icon: classNames(item.icon)
			};
			return navLink(item, key, classes);
		};

		// nav link
		const navLink = (item, key, classes) => {
			const url = item.url ? item.url : '';
			return (
				<NavItem key={key} className={classes.item}>
					{isExternal(url) ? (
						<RsNavLink href={url} className={classes.link} active>
							<i className={classes.icon} />
							{item.name}
							{badge(item.badge)}
						</RsNavLink>
					) : (
						<NavLink
							to={url}
							className={classes.link}
							activeClassName="active"
							onClick={this.hideMobile}
						>
							<i className={classes.icon} />
							{item.name}
							{badge(item.badge)}
						</NavLink>
					)}
				</NavItem>
			);
		};

		// nav dropdown
		const navDropdown = (item, key) => {
			return (
				<li
					key={key}
					id={item.name}
					className={this.activeRoute(
						item.url,
						props,
						this.state.activeRoute,
						item.name
					)}
				>
					<span
						className="nav-link nav-dropdown-toggle"
						onClick={e => this.handleClick(e)}
					>
						<i className={item.icon} />
						{item.name}
					</span>
					<ul className="nav-dropdown-items">{navList(item.children)}</ul>
				</li>
			);
		};

		// nav type
		const navType = (item, idx) =>
			item.title
				? title(item, idx)
				: item.divider
				? divider(item, idx)
				: item.label
				? navLabel(item, idx)
				: item.children
				? navDropdown(item, idx)
				: navItem(item, idx);

		// nav list
		const navList = items => {
			const { t } = this.props;

			const sidebarMenu = {
				sidebarmenu_dashboard: t('sidebarmenu.dashboard'),
				sidebarmenu_remoteControl: t('sidebarmenu.remoteControl'),
				sidebarmenu_admins: t('sidebarmenu.admins'),
				sidebarmenu_partner_management: t('sidebarmenu.partnerManagement'),
				sidebarmenu_hardware_management: t('sidebarmenu.hardwareManagement'),
				sidebarmenu_company_profile: t('sidebarmenu.companyProfile'),
				sidebarmenu_logout: t('sidebarmenu.logout'),
				sidebarmenu_devices: t('sidebarmenu.devices'),
				sidebarmenu_debug: t('sidebarmenu.debug'),
				sidebarmenu_users: t('sidebarmenu.users'),
				sidebarmenu_payments: t('sidebarmenu.payments'),
				sidebarmenu_operator: t('sidebarmenu.operator'),
				sidebarmenu_operatorCreat: t('sidebarmenu.operatorCreat'),
				sidebarmenu_operatorEdit: t('sidebarmenu.operatorEdit'),
				sidebarmenu_logs: t('sidebarmenu.logs'),
				sales: t('sidebarmenu.sales'),
				// admin menu
				sidebarmenu_administration: t('sidebarmenu.administration'),
				sidebarmenu_reports: t('sidebarmenu.reports'),
				sidebarmenu_devices: t('sidebarmenu.devices'),
				sidebarmenu_invisibleTouch: t('sidebarmenu.invisibleTouch'),
				sidebarmenu_device_monitor: t('sidebarmenu.deviceMonitor'),
				sidebarmenu_remoteControl: t('sidebarmenu.remoteControl'),
				sidebarmenu_invisibleTouch: t('sidebarmenu.invisibleTouch'),
				//  Administration children
				sidebarmenu_company_information: t('sidebarmenu.companyInformation'),
				sidebarmenu_invisible_touch_manager: t(
					'sidebarmenu.invisibleTouchManager'
				),
				sidebarmenu_device_manager: t('sidebarmenu.deviceManager'),
				sidebarmenu_partner_manager: t('sidebarmenu.partnerManager'),
				sidebarmenu_product_manager: t('sidebarmenu.productManager'),
				sidebarmenu_group_manager: t('sidebarmenu.groupManager'),
				sidebarmenu_campanign_manager: t('sidebarmenu.campanignManager'),
				sidebarmenu_promotion_manager: t('sidebarmenu.promotionManager'),
				sidebarmenu_package_manager: t('sidebarmenu.packageManager'),
				sidebarmenu_media_manager: t('sidebarmenu.mediaManager'),
				//  report children
				sidebarmenu_devices_list: t('sidebarmenu.devicesList'),
				sidebarmenu_customer_list: t('sidebarmenu.customerList'),
				sidebarmenu_sales: t('sidebarmenu.sales'),
				sidebarmenu_cash_flow: t('sidebarmenu.cashFlow'),
				sidebarmenu_event_list: t('sidebarmenu.eventList'),
				sidebarmenu_hardware_report: t('sidebarmenu.hardwareRiport'),
				sidebarmenu_sales_and_income: t('sidebarmenu.salesAndIncome'),
				sidebarmenu_media_appearances: t('sidebarmenu.mediaAppearances')
			};

			return items.map((item, index) => {
				const newItem = Object.assign({}, item, {
					name: sidebarMenu[item.name]
				});
				return navType(newItem, index);
			});
		};

		const getNav = () => {
			//HA van user akkor a menüt olvassuk be ha nincs akkor a nav.js ből a dasboardot. a dasboard az alapértelmezett
			const { user } = this.props;
			if (user) {
				var obj = JSON.parse(user.menu);
				return navList(obj);
			} else {
				//Nincs user a nav.js olvasódik be
				return navList(nav.items);
			}
		};

		const isExternal = url => {
			const link = url ? url.substring(0, 4) : '';
			return link === 'http';
		};

		// sidebar-nav root
		return (
			<div className="sidebar">
				<SidebarHeader />
				<SidebarForm />
				<nav className="sidebar-nav">
					<Nav>{getNav()}</Nav>
				</nav>
				<SidebarFooter />
				<SidebarMinimizer />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user
});

export default connect(mapStateToProps)(withTranslation()(Sidebar));
