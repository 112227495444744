//@flow
import React, { Component } from 'react';
import { Card } from 'reactstrap';
import {
	Section,
	SectionList,
	SectionSmallSubTitle,
	SectionSmallTitle,
	SectionSubTitle,
	SectionTitle
} from '../../../components/Section';
import getPercent from '../percent';
import { getColorForState } from '../../../services/util/colors';
import getStatusId from '../../../i18n/connectionStatus';
import moment from 'moment';
import type { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';

type Props = {
	t: TFunction,
	machine: Object
};

class ProductCard extends Component<Props> {
	render() {
		const { machine, t } = this.props;
		return (
			<Card>
				<SectionList>
					<Section>
						<SectionSmallTitle>
							{t('device.details.lastSale')}
						</SectionSmallTitle>
						<SectionSmallSubTitle>
							{machine.lastSaleDate
								? moment(machine.lastSaleDate).format('YYYY. MM. DD. HH:mm:ss')
								: '-'}
						</SectionSmallSubTitle>
					</Section>
					<Section>
						<SectionSmallTitle>
							{t('device.details.lastStatus')}
						</SectionSmallTitle>
						<SectionSmallSubTitle>
							{machine.statusUpdatedAt
								? moment(machine.statusUpdatedAt).fromNow()
								: '-'}
							<SectionSubTitle
								className={`text-${getColorForState(machine.idState)}`}
							>
								{t(getStatusId(machine.idState))}
							</SectionSubTitle>
						</SectionSmallSubTitle>
					</Section>
					<Section last={machine.note === 'Virtual HID'}>
						<SectionSmallTitle>
							{t('devicelist.tableheader.devicePartnerid')}
						</SectionSmallTitle>
						<SectionSmallSubTitle>{machine.name}</SectionSmallSubTitle>
					</Section>
					{machine.note !== 'Virtual HID' ? (
						<Section last>
							<SectionSmallTitle>
								{t('device.details.connectedHardwareIdentifier')}
							</SectionSmallTitle>
							<SectionSmallSubTitle>{machine.hardwareID}</SectionSmallSubTitle>
						</Section>
					) : null}
				</SectionList>
			</Card>
		);
	}
}

export default withTranslation()(ProductCard);
