import axios from './api';
import qs from 'qs';

export default {
	getProductSalesSummary: async () => {
		return await axios.get().get(`/machines/sales/summary`);
	},
	getProductSalesSummaryDetails: async q => {
		const query = qs.stringify(q);
		return await axios.get().get(`/machines/sales/summary/details?${query}`);
	},

	getProductSalesSummaryGroupedByVMS: async q => {
		const query = qs.stringify(q);
		return await axios.get().get(`/machines/sales/summary/byvm?${query}`);
	},

	getProductSalesSummaryGroupedByProductName: async q => {
		const query = qs.stringify(q);
		return await axios.get().get(`/machines/sales/summary/byproduct?${query}`);
	},

	getAllSales: async q => {
		const query = qs.stringify(q);
		return await axios.get().get(`/machines/sales?${query}`);
	},

	getProductSales: async vmId => {
		return await axios.get().get(`/machines/${vmId}/sales`);
	},

	getProductDetails: async (vmId, langCode, productID) => {
		const query = qs.stringify({ productID, langCode });
		return await axios.get().get(`/machines/${vmId}/product?${query}`);
	},

	getProductsByVmId: async vmID =>
		axios.get().get(`/machines/${vmID}/products`),

	editPrice: async (
		vmID,
		productID,
		slavePattern,
		price = 0.0,
		isPromotion = 0
	) => {
		const params = {
			isPromotion,
			price
		};
		const query = qs.stringify({
			slavePattern:
				slavePattern === null || slavePattern === undefined
					? undefined
					: slavePattern
		});
		return await axios
			.get()
			.put(`/machines/${vmID}/products/${productID}/pricing?${query}`, params);
	},

	editCountFields: (vmID, pID, maxCount, notifyThreshold) => {
		const params = { maxCount, notifyThreshold };
		return axios.get().put(`/machines/${vmID}/products/${pID}`, params);
	},

	editAmounts: (vmID, pID, amount) => {
		const params = { amount };
		return axios.get().put(`/machines/${vmID}/products/${pID}/upload`, params);
	},

	editPromotion: async (
		vmID,
		productID,
		slavePattern,
		promoPrice = 0.0,
		isPromotion = 0
	) => {
		const params = {
			isPromotion,
			promoPrice
		};

		const query = qs.stringify({
			slavePattern:
				slavePattern === null || slavePattern === undefined
					? undefined
					: slavePattern
		});

		return await axios
			.get()
			.put(`/machines/${vmID}/products/${productID}/pricing?${query}`, params);
	}
};
