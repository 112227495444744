/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './MachineSelectorDialog.module.css';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

class MachineSelectorDialogModule extends Component {
	state = {
		selectedMachines: [],
		filteredMachineList: this.props.allVendingMachines
	};

	filterText = '';

	selectMachine(machine) {
		const alreadyContained = this.state.selectedMachines.find(
			curMachine => curMachine.name == machine.name
		);
		if (alreadyContained) {
			const clearedList = this.state.selectedMachines.filter(
				curMachine => curMachine.name != machine.name
			);
			this.setState({ selectedMachines: clearedList });
		} else {
			const newList = [...this.state.selectedMachines];
			newList.push(machine);
			this.setState({ selectedMachines: newList });
		}
	}

	onOkClick() {
		this.props.onSelected(this.state.selectedMachines);
		this.setState({ selectedMachines: [] });
	}

	onSelectAll() {
		this.setState({ selectedMachines: [...this.state.filteredMachineList] });
	}

	onDeSelectAll() {
		this.setState({ selectedMachines: [] });
	}

	isAnySelected() {
		return this.state.selectedMachines.length > 0;
	}

	onFilterChange(e) {
		this.filterText = e.target.value;
		clearTimeout(this.filterTimeout);
		this.filterTimeout = setTimeout(() => this.filterTheList(), 300);
	}

	filterTheList() {
		const filteredList = [];
		this.props.allVendingMachines.forEach(vendingMachine => {
			if (
				this.filterText.trim() == '' ||
				(vendingMachine.name &&
					vendingMachine.name
						.toUpperCase()
						.indexOf(this.filterText.toUpperCase().trim()) >= 0) ||
				(vendingMachine.customName &&
					vendingMachine.customName
						.toUpperCase()
						.indexOf(this.filterText.toUpperCase().trim()) >= 0) ||
				(vendingMachine.address &&
					vendingMachine.address
						.toUpperCase()
						.indexOf(this.filterText.toUpperCase().trim()) >= 0)
			) {
				filteredList.push(vendingMachine);
			}
		});

		this.setState({ filteredMachineList: filteredList });
	}

	renderMachines() {
		const machineList = this.state.filteredMachineList.map(machine => {
			return (
				<ListGroup.Item
					key={machine.vmID}
					active={this.state.selectedMachines.find(
						curMachine => curMachine.name == machine.name
					)}
					onClick={e => this.selectMachine(machine)}
				>
					<span>
						<b>
							{this.props.t('userManagement.userGeneral.machineName')}
							{': '}
						</b>
						{machine.customName}
					</span>
					<br />
					<span>
						<b>
							{this.props.t('userManagement.userGeneral.machineAddress')}
							{': '}
						</b>
						{machine.address && machine.address.length > 0
							? machine.address
							: '-'}
					</span>
				</ListGroup.Item>
			);
		});

		return (
			<div style={{ height: '300px', overflowY: 'auto' }}>
				<ListGroup>{machineList}</ListGroup>
			</div>
		);
	}

	render() {
		return (
			<Form>
				<Row>
					<Col sm="12">
						<Form.Control
							type="text"
							placeholder={this.props.t('salesList.filter')}
							onChange={e => this.onFilterChange(e)}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm="12">{this.renderMachines()}</Col>
				</Row>
				<Row>
					<Col sm="12" className={classes.ToolbarDiv}>
						<Button
							variant={this.isAnySelected() ? 'primary' : 'success'}
							onClick={() =>
								this.isAnySelected() ? this.onDeSelectAll() : this.onSelectAll()
							}
						>
							<i className="fa fa-check-square"></i>&nbsp;
							{this.isAnySelected()
								? this.props.t('salesList.deselectAll')
								: this.props.t('salesList.selectAll')}
						</Button>
						<Button variant="success" onClick={() => this.onOkClick()}>
							<i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
							{this.props.t('salesList.add')}
						</Button>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default withTranslation()(MachineSelectorDialogModule);
