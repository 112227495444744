//@flow
import React from 'react'
import { Col, Row } from 'reactstrap'

import KlixButtonContainer from './KlixButtonContainer'

type Props = {
  bntPressHandler?: (key: string) => () => Promise<void>,
  lcdLines?: Array<string>,
}

class KlixLCD extends React.Component<Props> {
  lcdButtons = [
    {
      right: {
        content: <i className="fa fa-long-arrow-left" aria-hidden="true"></i>,
        buttonID: '26',
      },
      left: {
        content: <i className="fa fa-long-arrow-right" aria-hidden="true"></i>,
        buttonID: '24',
      },
    },
    {
      right: {
        content: <i className="fa fa-long-arrow-left" aria-hidden="true"></i>,
        buttonID: '27',
      },
      left: {
        content: <i className="fa fa-long-arrow-right" aria-hidden="true"></i>,
        buttonID: '25',
      },
    },
  ]

  getKlixLCDElements = (
    line: string,
    buttons: Object,
    bntPressHandler: (key: string) => () => Promise<void>,
    idx: number
  ) => {
    return (
      <Row key={idx}>
        <Col xs="2" md="2" lg="2" xl="1" className="klix-led-arrow">
          <KlixButtonContainer onClick={bntPressHandler(buttons.left.buttonID)}>
            {buttons.left.content}
          </KlixButtonContainer>
        </Col>
        <Col xs="8" md="8" lg="8" xl="10" className="klix-led text-center">
          <p className="lcd_font pb-2 pt-2 pl-2 klix-lcd-text">{line}</p>
        </Col>
        <Col xs="2" md="2" lg="2" xl="1" className="klix-led-arrow">
          <KlixButtonContainer
            onClick={bntPressHandler(buttons.right.buttonID)}
          >
            {buttons.right.content}
          </KlixButtonContainer>
        </Col>
      </Row>
    )
  }

  render() {
    const {
      bntPressHandler = (key: string) => async () => {},
      lcdLines = [],
    } = this.props
    return (
      <>
        {lcdLines.map((line, idx) =>
          this.getKlixLCDElements(
            line,
            this.lcdButtons[idx],
            bntPressHandler,
            idx
          )
        )}
      </>
    )
  }
}

export default KlixLCD
