import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import classes from './PrintDialog.module.css';

class PrintDialog extends Component {
	state = { numberOfCopies: 1 };

	inputUpdate(e) {
		let newState = { ...this.state };
		newState.numberOfCopies = e.target.value;
		this.setState(newState);
	}

	handleFormSubmit(e) {
		e.preventDefault();
		this.props.onPrint(this.state.numberOfCopies);
	}

	render() {
		return (
			<Form onSubmit={e => this.handleFormSubmit(e)}>
				<Form.Row>
					<Form.Label>
						{this.props.t(
							'hardwareManagement.hardwareList.part.numberOfCopies'
						)}
					</Form.Label>
					<Form.Control
						id="numberOfCopies"
						type="number"
						value={this.state.numberOfCopies}
						onChange={e => this.inputUpdate(e)}
					/>
				</Form.Row>
				<div className={classes.ToolbarDiv}>
					<Button variant="success" type="submit" className="DefaultButton">
						<i className="fa fa-print mr-2" aria-hidden="true"></i>&nbsp;
						{this.props.t('hardwareManagement.hardwareList.part.print')}
					</Button>
				</div>
			</Form>
		);
	}
}
export default withTranslation()(PrintDialog);
