import React, { Component } from 'react';
import { Card, Row, CardBody, Col, CardTitle } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import { withTranslation } from 'react-i18next';
import FilterBar from '../../views/FilterBar/FilterBar';
import ConfimationDialog from '../ConfirmationDialog/ConfirmationDialog';
import HelperTooltip from '../../components/UI/HelperTooltip/HelperTooltip';
import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';
import proxy from '../PromotionManager/proxy';
import PackagesTable from './PackagesTable/PackagesTable';
import LayoutTable from './LayoutTable/LayoutTable';
import packageApi from '../../services/api/package';
import machineApi from '../../services/api/machines';

class PackageManager extends Component {
	state = {
		data: [],
		filteredPackages: [],
		toDelete: null,
		machines: []
	};
	componentDidMount() {
		Promise.all([this.loadPackages(), this.loadMachines()]).then(values => {
			this.setState(
				{ data: values[0].data.packages, machines: values[1].data.response },
				() => this.filterPackages()
			);
		});
	}

	loadPackages = () => {
		return packageApi.getPackages();
	};

	loadMachines = () => {
		return machineApi.getVisibleVendingMachines();
	};

	//Render Filterbar
	renderFilterBar = () => {
		return (
			<FilterBar
				key="filterbar"
				onFilterChanged={value => this.onFilterChange(value)}
			/>
		);
	};

	filterPackages() {
		if (this.state.data) {
			let filteredPackages = [...this.state.data];
			let filterValue = this.state.currentFilterValue
				? this.state.currentFilterValue.trim().toUpperCase()
				: '';
			if (filterValue.length > 0) {
				filteredPackages = this.state.data.filter(data => {
					let curata = { ...data };
					let DataFieldArray = Object.values(curata);
					return DataFieldArray.some(
						val =>
							String(val)
								.toUpperCase()
								.indexOf(filterValue) >= 0
					);
				});
			}

			this.setState({ filteredPackages: filteredPackages });
		}
	}

	onFilterChange(filteValue) {
		this.setState({ currentFilterValue: filteValue }, () =>
			this.filterPackages()
		);
	}

	// Render Layout Dialog
	showLayouts = idx => {
		let p = [];
		if (idx) {
			p = this.state.data.filter(e => e.packageID === idx);
		}
		this.showLayoutDialog(p[0]);
	};

	showLayoutDialog(p) {
		this.setState({ showLayoutDialog: true, shownPackage: p });
	}

	hideLayoutDialog() {
		this.setState({ showLayoutDialog: false, shownPackage: null });
		// this.loadData();
	}

	renderLayoutDialog() {
		return (
			<Modal
				show={this.state.showLayoutDialog}
				onHide={() => this.hideLayoutDialog()}
				size="lg"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i class="fa fa-columns" style={{ marginRight: '10px' }}></i>

						{this.props.t('packageManager.layoutTable.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row
						style={{
							marginBottom: '20px'
						}}
					>
						<Col
							style={{
								display: 'flex',
								flexDirection: 'collumn',
								alignItems: 'center',
								justifyContent: 'space-around',
								fontWeight: 'bold'
							}}
						>
							{this.props.t('packageManager.packageTable.packageName') + ' :'}
						</Col>
						<Col
							style={{
								display: 'flex',
								flexDirection: 'collumn',
								alignItems: 'center',

								fontSize: 'large'
							}}
						>
							{this.state.shownPackage ? this.state.shownPackage.filename : ''}
						</Col>
					</Row>
					<LayoutTable
						hideToolbar
						pId={
							this.state.shownPackage ? this.state.shownPackage.packageID : null
						}
						data={
							this.state.shownPackage ? this.state.shownPackage.layouts : []
						}
						machines={this.state.machines}
						onHide={() => this.hideLayoutDialog()}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	// ---------- Delete confirmation handling -------------

	hideConfirmationDialog = () => {
		this.setState({
			deleteConfirmationVisible: false
		});
	};

	showDeleteConfirmation = id => {
		let fileToDel = this.state.data.filter(e => e.id === id);

		this.setState({
			deleteConfirmationVisible: true,
			fileToDel: fileToDel
		});
	};

	renderDeleteConfirmationDialog = () => {
		return (
			<ConfimationDialog
				title="Confirmation"
				confirmationText={
					<>
						<Col>{this.props.t('attachedFiles.delConf')}</Col>
						<Col>
							<Row>
								<Col>{this.props.t('attachedFiles.fileName')}</Col>
								<Col style={{ fontWeight: 'bold' }}>
									{this.state.fileToDel ? this.state.fileToDel[0].name : ''}
								</Col>
							</Row>
						</Col>
					</>
				}
				okText={this.props.t('device.details.invisibleTouch.ok')}
				cancelText={this.props.t('device.details.invisibleTouch.cancel')}
				isOpen={this.state.deleteConfirmationVisible}
				onToggle={() => {
					this.hideConfirmationDialog();
				}}
				onCancel={() => {
					this.hideConfirmationDialog();
				}}
				onOk={() => {
					this.hideConfirmationDialog();
					alert('DELETE FILE');
				}}
			/>
		);
	};

	//Render Table
	renderTable() {
		return (
			<PackagesTable
				data={this.state.filteredPackages}
				toDel={this.state.toDelete}
				machines={this.state.machines}
				onDel={this.showDeleteConfirmation}
				renderFilterBar={this.renderFilterBar}
				onFilterChange={this.onFilterChange}
				onShowLayouts={this.showLayouts}
			/>
		);
	}

	//Render Title
	renderTitle() {
		return (
			<Row style={{ alignItems: 'flex-start' }}>
				<CardTitle
					className="text-muted"
					style={{ cursor: 'pointer', marginLeft: '20px' }}
				>
					<i className={'fa fa-folder'} style={{ marginRight: '10px' }} />
					{this.props.t('packageManager.packageTable.title')}
				</CardTitle>
			</Row>
		);
		/*
		
				<HelperTooltip
					height={'100px'}
					width={'600px'}
					direction={'right'}
					id={'PromotionManagerHelperTooltip'}
					title={this.props.t('promotionManager.tooltip.title')}
					content={this.props.t('promotionManager.tooltip.content')}
				></HelperTooltip>
				*/
	}
	render() {
		const { t } = this.props;

		return (
			<div className="animated fadeIn">
				<DashboardCardContainerWithFlow>
					<Card body>
						<PageTiltleCard title={this.props.t('packageManager.title')} />
						{this.renderDeleteConfirmationDialog()}
						{this.renderLayoutDialog()}
						<Card className={this.props.className} style={this.props.style}>
							<CardBody>
								{this.renderTitle()} {this.renderTable()}
							</CardBody>
						</Card>
					</Card>
				</DashboardCardContainerWithFlow>
			</div>
		);
	}
}

export default withTranslation()(PackageManager);
