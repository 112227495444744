//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';

import VMUtil from '../../../../services/util/vmUtil';

import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent';

import AntaresTrollKeyboard from './Keyboard/AntaresTrollKeyboard';

type Props = {
	products?: Array<Object>
};

class AntaresTroll extends React.Component<Props> {
	render() {
		const { products = [] } = this.props;
		return (
			<DirectChooseMachineBaseComponent
				machineTitle="AntaresTroll"
				lineNumber={2}
				lineLength={16}
				ledNumbers={0}
				keyboard={<AntaresTrollKeyboard products={products} />}
				{...this.props}
			/>
		);
	}
}

export default withRouter(AntaresTroll);
