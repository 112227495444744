//@flow
import React, { Component } from 'react';
import {
	Button,
	Card,
	CardBody,
	CardGroup,
	CardImg,
	Col,
	Container,
	Input,
	InputGroup,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';
import { toast } from 'react-toastify';
import Logo from '../../assets/images/logo.png';

//Server resp
//Pörgős gomb
import 'ladda/dist/ladda-themeless.min.css';
import LaddaButton, { EXPAND_LEFT, EXPAND_RIGHT } from 'react-ladda';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
// import { TOAST_MESSAGE_TIMEOUT } from '../../services/util/consts';
import AuthApi from '../../services/api/auth';
import logger from '../../services/logger';
import { withRouter } from 'react-router-dom';

import { deleteUser } from '../../store/user';
import { connect } from 'react-redux';

type Props = {
	t: TFunction,
	match: {
		params: {
			key: string
		}
	},
	history: Object,
	className: string
};
type States = {
	password: string,
	password_confirm: string,
	expLeft: boolean,
	success: boolean,
	progress?: number
};

class SetPassw extends Component<Props, States> {
	constructor(props) {
		super(props);

		this.state = {
			password: '',
			password_confirm: '',
			expLeft: false, //a loding button-nak kell az állapot felméréséhez, hogy pörögjön e
			success: false
		};
	}

	//Modal success
	toggleSuccess = () => {
		this.setState({
			success: !this.state.success
		});
	};

	//Szökséges KEY error
	needKeyError = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('error.missingFields'));
	};

	//Szökséges KEY error
	access = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('error.wrongAccessKey'));
	};

	notSameError = () => {
		return toast.error(this.props.t('error.passwordsDoNotMatch'));
	};

	//gomb event-je
	handleClick = async () => {
		//2 mező nem üres
		if (this.state.password !== '' && this.state.password_confirm !== '') {
			//Nem egyezik a 2 jelszó
			if (this.state.password !== this.state.password_confirm) {
				this.setState({
					expLeft: false,
					progress: 1.0
				});

				this.notSameError();
			} else {
				//Loginbutton töltés állapot beállítása
				this.setState({
					expLeft: true,
					progress: 0.5
				});

				//Body adatok
				var passw = this.state.password;
				//Linkből adat
				let accessKey = this.props.match.params.key;

				try {
					const response = await AuthApi.setPassword(passw, accessKey);
					if (response.data.hasOwnProperty('response')) {
						this.toggleSuccess();
					} else {
						this.setState({
							expLeft: false,
							progress: 1.0
						});

						//Rossz access keyt adtunk meg , alert feldobása
						this.access();
					}
				} catch (error) {
					logger.error(error);
				}
			}
		} else {
			this.setState({
				expLeft: false,
				progress: 1.0
			});

			this.needKeyError();
		}
	};

	//Loginra vissza
	handleCancel() {
		this.props.history.replace('/login');
	}

	handleInputChange = key => event =>
		this.setState({ [key]: event.target.value });

	render() {
		this.props.deleteUser();

		const { t, className = '' } = this.props;
		const {
			password = '',
			password_confirm = '',
			expLeft = false,
			success = false
		} = this.state;

		return (
			<div className="app flex-row align-items-center">
				<Modal
					isOpen={success}
					toggle={this.toggleSuccess}
					className={'modal-success ' + className}
				>
					<ModalHeader toggle={this.toggleSuccess}>
						{t('login.setPassword.modal.title')}
					</ModalHeader>
					<ModalBody>{t('login.setPassword.modal.info')}</ModalBody>
					<ModalFooter>
						<Button color="success" onClick={() => this.handleCancel()}>
							{t('login.continueToLoginButton')}
						</Button>{' '}
					</ModalFooter>
				</Modal>

				<Container>
					<Row className="justify-content-center">
						<Col md="5">
							<CardGroup className="mb-4">
								<Card className="p-8">
									<CardBody>
										<div className="text-left">
											<CardImg
												className="card-img-top"
												id="mainLogo"
												src={Logo}
												alt="Card image cap"
											/>
										</div>
										<h1>{t('login.resetPassword.title')}</h1>
										<p className="text-left">{t('login.resetPassword.info')}</p>
										<InputGroup className="mb-3">
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-lock" />
												</span>
											</div>
											<Input
												type="password"
												placeholder={t('login.password')}
												value={password}
												onChange={this.handleInputChange('password')}
											/>
										</InputGroup>
										<InputGroup className="mb-3">
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-lock" />
												</span>
											</div>
											<Input
												type="password"
												placeholder={t('login.passwordAgain')}
												value={password_confirm}
												onChange={this.handleInputChange('password_confirm')}
											/>
										</InputGroup>
										<Row>
											<Col xs="6">
												<LaddaButton
													id="redButton"
													className="btn btn-success btn-ladda"
													loading={expLeft}
													data-style={EXPAND_LEFT}
													onClick={this.handleClick}
												>
													{t('login.resetPassword.button')}
												</LaddaButton>
											</Col>
											<Col xs="6" className="text-right">
												<LaddaButton
													id="forgotButton2"
													className="btn btn-success btn-ladda"
													loading={expLeft}
													data-style={EXPAND_RIGHT}
													onClick={() => this.handleCancel()}
												>
													{t('login.loginButton')}
												</LaddaButton>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</CardGroup>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		deleteUser: () => {
			dispatch(deleteUser());
		}
	};
};

export default connect(
	null,
	mapDispatchToProps
)(withRouter(withTranslation()(SetPassw)));
