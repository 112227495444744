import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Scatter } from 'react-chartjs-2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Toggle from 'react-bootstrap-toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import Spinner from '../../../../components/UI/Spinner/Spinner.js';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import VMUtil from '../../../../services/util/vmUtil';
import customerAPI from '../../../../services/api/customers';
import machineAPI from '../../../../services/api/machines';
import { dateFromMYSQLTimeStamp } from '../../../../services/util/dateUtil';
import { getLanguage } from '../../../../localization';
import 'chartjs-plugin-zoom';

import EventListCardToolTip from './ToolTip/EventListTooltip';

import classes from './EventListCard.module.css';
// import { create } from 'ladda';

import proxy from '../../../PromotionManager/proxy';

class EventListCard extends Component {
	state = {
		loading: true,
		showDialog: false,
		eventVisibilities: [],
		eventList: [],
		eventTypeSeriesSettings: [],
		proxyData: proxy.filteredData
	};

	unvisibleEvents = [15];
	mainChart = {
		datasets: [],
		labels: ['0', '123', '4', '566']
	};

	mainChartOpts = {
		scales: {
			yAxes: [
				{
					ticks: {
						callback: function(value, index, values) {
							const pad = (num, size) => {
								var s = num + '';
								while (s.length < size) s = '0' + s;
								return s;
							};
							if (value % 1 === 0) {
								return value + 'h';
							} else {
								return (
									Math.floor(value / 1) +
									':' +
									pad(Math.floor(Math.floor((value % 1) * 60)), 2)
								);
							}
						}
					}
				}
			],
			xAxes: [
				{
					type: 'linear',
					position: 'bottom',
					ticks: {
						//autoSkip: false,
						callback: function(value, index, values) {}
					}
				}
			]
		},
		tooltips: {
			enabled: true,
			callbacks: {
				label: (tooltipItem, data) => {
					return (
						data.datasets[tooltipItem.datasetIndex].label[tooltipItem.index] ||
						''
					);
				}
			}
		},
		plugins: {
			datalabels: {
				display: false
			},

			zoom: {
				// Container for zoom options
				zoom: {
					// Boolean to enable zooming
					enabled: false,
					//drag: true,
					// Zooming directions. Remove the appropriate direction to disable
					// Eg. 'y' would only allow zooming in the y direction
					mode: 'y',
					rangeMin: {
						// Format of min zoom range depends on scale type

						y: 1
					},
					rangeMax: {
						// Format of max zoom range depends on scale type

						y: 24
					}
				}
			}
		},
		legend: {
			display: false
		},
		maintainAspectRatio: false
	};

	datasetLinks = [];
	dataSets = [];
	dataSetLabels = [];
	dataSeriesSettings = [];

	getDateLabelFromDate(date) {
		return (
			(date.getMonth() + 1 + '').padStart(2, 0) +
			'. ' +
			(date.getDate() + '').padStart(2, 0) +
			'.'
		);
	}

	getTimeLabelFromDate(date) {
		return (
			(date.getHours() + '').padStart(2, 0) +
			':' +
			(date.getMinutes() + '').padStart(2, 0) +
			':' +
			(date.getSeconds() + '').padStart(2, 0)
		);
	}

	templateResolver(templateString, templateVars) {
		return new Function('return `' + templateString + '`;').call(templateVars);
	}

	renderMalfunctionTooltip(params, props) {
		try {
			const parsedValues = JSON.parse(params.m);
			if (
				!parsedValues ||
				parsedValues.length <= 0 ||
				!parsedValues[0].display
			) {
				throw new Error('Incorrect error message');
			}
			return (
				<Tooltip
					style={{
						borderRadius: '10px',
						padding: '0px',
						overflow: 'hidden'
					}}
				>
					<EventListCardToolTip screens={parsedValues} />
				</Tooltip>
			);
		} catch (error) {
			return (
				<Tooltip id="button-tooltip" {...props}>
					{params.m}
				</Tooltip>
			);
		}
	}

	getEventStringFromEventType(eventTypeId, params) {
		const eventType = this.state.eventTypeSeriesSettings.find(
			curEventType => curEventType.typeId === eventTypeId
		);

		let mappedParam = {};
		params.forEach(param => {
			mappedParam[param.parameterName] = param.parameterValue;
		});

		const label = eventType ? eventType.text : '';
		try {
			const resolvedText = this.templateResolver(label, mappedParam);
			let result = resolvedText;
			if (
				(eventType.typeId == 3 ||
					eventType.typeId == 22 ||
					eventType.typeId == 23) &&
				(mappedParam.m && mappedParam.m.length > 0)
			) {
				const tooltip = this.renderMalfunctionTooltip(mappedParam);
				result = (
					<OverlayTrigger
						placement="up"
						overlay={tooltip}
						trigger="click"
						rootClose
						transition={null}
					>
						<div className={classes.HintBlock}>
							{resolvedText} <i class="fa fa-book"></i>
						</div>
					</OverlayTrigger>
				);
			}
			return <>{result}</>;
		} catch (error) {
			console.error('Could not resolve text: ' + label);
			return '';
		}
	}

	prepareChartData(data) {
		this.datasetLinks = [];
		this.dataSets = [];
		this.dataSetLabels = [];
		this.dataSeriesSettings = [];

		data = data.map(row => {
			return { ...row, date: dateFromMYSQLTimeStamp(row.date) };
		});

		this.state.eventTypeSeriesSettings.map(evenTypeSettings => {
			const dataForType = data
				.filter(row => row.eventType === evenTypeSettings.typeId)
				.filter(row => row.date.getTime() >= this.state.startingDate.getTime())
				.map(row => {
					const dayIndexFromOrigin = Math.floor(
						(row.date.getTime() - this.state.startingDate.getTime()) /
							(24 * 60 * 60 * 1000)
					);
					const hourDistanceFromMidnight =
						((row.date.getHours() * 60 * 60 +
							row.date.getMinutes() * 60 +
							row.date.getSeconds()) /
							(24 * 60 * 60)) *
						24;

					return {
						x: dayIndexFromOrigin ? dayIndexFromOrigin : 0,
						y: hourDistanceFromMidnight ? hourDistanceFromMidnight : 0
					};
				});

			const labelsForType = data
				.filter(row => row.eventType === evenTypeSettings.typeId)
				.filter(row => row.date.getTime() >= this.state.startingDate.getTime())
				.map(row => {
					return (
						this.getDateLabelFromDate(row.date) +
						' ' +
						this.getTimeLabelFromDate(row.date) +
						' ' +
						evenTypeSettings.label +
						'  ' +
						this.props.t('eventList.machineName') +
						row.machineName
					);
				});

			const curDataSetLinks = data
				.filter(row => row.eventType === evenTypeSettings.typeId)
				.filter(row => row.date.getTime() >= this.state.startingDate.getTime())
				.map(row => {
					return 'vms/' + VMUtil.encodeVMID(row.machineId) + '/detailer';
				});

			this.datasetLinks.push(curDataSetLinks);
			this.dataSets.push(dataForType);
			this.dataSetLabels.push([...labelsForType]);
			this.dataSeriesSettings.push(evenTypeSettings);
		});

		//	this.reference.chartInstance.update();
		//		this.initChart();
	}

	updateChart() {
		if (this.reference && this.reference.chartInstance) {
			this.reference.chartInstance.canvas.onclick = event =>
				this.onChartClick(event);

			this.reference.chartInstance.options.scales.xAxes[0].ticks.min = 0;
			this.reference.chartInstance.options.scales.xAxes[0].ticks.max =
				this.props.days - 1;
			this.reference.chartInstance.options.scales.xAxes[0].ticks.stepSize = 1;
			this.reference.chartInstance.options.scales.yAxes[0].ticks.min = 0;
			this.reference.chartInstance.options.scales.yAxes[0].ticks.max = 24;
			this.reference.chartInstance.options.scales.yAxes[0].ticks.stepSize = 1;

			this.reference.chartInstance.options.scales.xAxes[0].ticks.callback = (
				value,
				index,
				values
			) => {
				if (!this.state || !this.state.startingDate) return;

				const curDate = new Date(this.state.startingDate);
				curDate.setDate(this.state.startingDate.getDate() + index);

				return this.getDateLabelFromDate(curDate);
			};

			const self = this;
			this.mainChart.datasets = this.dataSets.map((data, index) => {
				return {
					...self.dataSeriesSettings[index],
					data,
					label: self.dataSetLabels[index],
					hidden: !self.isEventTypeVisible(
						self.dataSeriesSettings[index].typeId
					)
				};
			});

			this.reference.chartInstance.update();

			/*this.mainChart.datasets.push({
				...evenTypeSettings,

				data: this.dataForType,
				label: tlabelsForType
			});*/

			//this.prepareChartData([]);
		}
	}

	async loadEventTypes() {
		const eventTypeList = await machineAPI.getEventTypes();
		this.setState({ eventTypeList });
		let language = getLanguage();
		if (!language) {
			language = 'en';
		}
		const eventTypeSeriesSettings = eventTypeList.data.rows.map(row => {
			return {
				typeId: row.id,
				label: row['label_' + language],
				text: row['text_' + language],
				backgroundColor: 'rgba(0,0,216,0.1)',
				borderColor: row.chart_border_color ? row.chart_border_color : 'blue',
				pointHoverBackgroundColor: '#fff',
				borderWidth: 2,
				pointRadius: row.chart_radius ? row.chart_radius : 10,
				pointStyle: row.chart_point_style
					? row.chart_point_style
					: 'rectRounded'
			};
		});
		this.setState({ eventTypeSeriesSettings });
	}

	componentDidMount() {
		let startingDate = new Date(
			new Date().getFullYear(),
			new Date().getMonth(),
			new Date().getDate()
		);
		startingDate = new Date(
			startingDate.setDate(startingDate.getDate() - (this.props.days - 1))
		);

		this.loadEventTypes();
		this.loadData(startingDate);

		this.setState({
			startingDate
		});
	}

	loadData(startingDate) {
		this.setState({ loading: true });
		const machineId = this.props.machine ? this.props.machine.vmID : undefined;
		customerAPI
			.getEvents(startingDate, this.props.days, machineId)
			.then(resp => {
				this.setState({ eventList: resp.data.response }, () =>
					this.provideFiltredDataset()
				);

				this.prepareChartData(resp.data.response);
				this.updateChart();
				this.setState({ loading: false });
			});
	}

	onShiftToPrevious() {
		let prevDate = this.state.startingDate;
		prevDate.setDate(prevDate.getDate() - parseInt(this.props.days));
		this.loadData(prevDate);
		this.setState({ startingDate: prevDate });
	}

	onShiftToNext() {
		let nextDate = this.state.startingDate;
		nextDate.setDate(nextDate.getDate() + parseInt(this.props.days));
		if (nextDate > new Date()) {
			nextDate = new Date();
			nextDate.setDate(nextDate.getDate() - this.props.days);
		}
		this.loadData(nextDate);
		this.setState({ startingDate: nextDate });
	}

	onShowDialog() {
		this.setState({ showDialog: true }, () => {
			//	this.reference.chartInstance.clear();
			this.prepareChartData(this.state.eventList);
		});
	}

	onHideDialog() {
		this.setState({ showDialog: false }, () =>
			this.prepareChartData(this.state.eventList)
		);
	}

	isEventTypeVisible(eventTypeId) {
		let result = true;
		let item = this.state.eventVisibilities.find(
			curType => curType.typeId === eventTypeId
		);
		if (item) {
			result = item.visible;
		}
		return result;
	}

	onToggleEventTypeVisibility(eventType) {
		let eventVisibilities = [...this.state.eventVisibilities];
		let item = eventVisibilities.find(
			curType => curType.typeId === eventType.typeId
		);
		if (!item) {
			item = { typeId: eventType.typeId, visible: false };
			eventVisibilities.push(item);
		} else {
			item.visible = !item.visible;
		}

		this.setState({ eventVisibilities: eventVisibilities }, () => {
			this.prepareChartData(this.state.eventList);
			this.provideFiltredDataset();
		});
	}

	onToggleAllVisibilities() {
		let newVisibilitySettings = [];
		if (
			this.state.eventVisibilities.filter(item => !item.visible).length === 0
		) {
			newVisibilitySettings = this.state.eventTypeSeriesSettings.map(
				eventType => {
					return { typeId: eventType.typeId, visible: false };
				}
			);
		}
		this.setState({ eventVisibilities: newVisibilitySettings }, () => {
			this.prepareChartData(this.state.eventList);
			this.provideFiltredDataset();
		});
	}

	onChartClick(elems) {
		if (!this.props.hideLink && elems && elems.length > 0) {
			this.props.history.push(
				this.datasetLinks[elems[0]._datasetIndex][elems[0]._index]
			);
		}
	}

	renderDialog() {
		const eventSettingsList = this.state.eventTypeSeriesSettings.map(
			(eventType, index) => {
				return (
					<div key={index}>
						<div>
							{eventType.label}
							<Toggle
								onClick={() => this.onToggleEventTypeVisibility(eventType)}
								on="show"
								off="hide"
								active={this.isEventTypeVisible(eventType.typeId)}
								onstyle="info"
								offstyle="primary"
								size="sm"
								style={{
									width: '75px',
									height: '30px',
									borderRadius: '25px',
									float: 'right'
								}}
							/>
						</div>
						<hr />
					</div>
				);
			}
		);

		return (
			<Modal
				show={this.state.showDialog}
				onHide={() => this.onHideDialog()}
				scrollable="true"
			>
				<Modal.Header>
					<Modal.Title>{this.props.t('eventList.visibleEvents')}</Modal.Title>
				</Modal.Header>
				<Modal.Body max-height="260px">{eventSettingsList}</Modal.Body>
				<Modal.Footer>
					<Button
						variant="success"
						onClick={() => this.onToggleAllVisibilities()}
					>
						{this.state.eventVisibilities.filter(item => !item.visible)
							.length === 0
							? this.props.t('eventList.hideAll')
							: this.props.t('eventList.showAll')}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	dateFormatter = (cell, row) => {
		if (cell) {
			const dateFromTimeStamp = dateFromMYSQLTimeStamp(cell);
			const stringRep =
				dateFromTimeStamp.toLocaleDateString() +
				' ' +
				dateFromTimeStamp.toLocaleTimeString();

			return stringRep;
		} else {
			return cell;
		}
	};

	eventTypeFormatter = (cell, row) => {
		return this.getEventStringFromEventType(cell, row.params);
	};

	onRowClik(row) {
		this.props.history.push(
			'vms/' + VMUtil.encodeVMID(row.machineId) + '/detailer'
		);
	}

	editFormatter = (cell, row) => {
		return (
			<>
				<Button
					onClick={e => this.onRowClik(row)}
					variant="success"
					className={classes.EditButton}
				>
					<i className="fa fa-external-link" />
				</Button>
			</>
		);
	};

	provideFiltredDataset() {
		const filteredData = this.state.eventList
			.filter(
				row => !this.unvisibleEvents.find(event => event === row.eventType)
			)
			.filter(row => this.isEventTypeVisible(row.eventType))
			.map((row, index) => {
				return { ...row, index };
			});
		this.setState({ filteredData: filteredData });
	}

	renderGrid() {
		const tableOptions = {
			hideSizePerPage: false,
			paginationSize: 3,
			sizePerPageList: [5, 10, 25, 50, 100],
			sizePerPage: this.props.recordPerPage || 100,
			withFirstAndLast: true,
			paginationPosition: 'bottom',
			alwaysShowAllBtns: false,
			clearSearch: true,
			hidePageListOnlyOnePage: true,
			sortName: 'date',
			sortOrder: 'desc'
			/*
			sortIndicator: true,
			sortName: 'privilege',
			sortOrder: 'asc'
			*/
		};
		// const { t } = this.props;
		return (
			<BootstrapTable
				data={this.state.proxyData}
				version="4"
				striped
				hover
				pagination={!this.props.hidePager}
				insertRow={false}
				options={tableOptions}
				multiColumnSearch
				condensed
				bordered={false}
				dataAlign={'center'}
				size="sm"
			>
				<TableHeaderColumn
					isKey
					width="1"
					dataAlign="center"
					dataField="index"
					hidden
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					tdStyle={{ paddingTop: '15px' }}
				>
					Index
				</TableHeaderColumn>
				<TableHeaderColumn
					width="10"
					dataAlign="center"
					dataField="date"
					dataFormat={this.dateFormatter}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					tdStyle={{ paddingTop: '15px' }}
					dataSort
				>
					{this.props.t('eventList.table.date')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20"
					dataAlign="center"
					dataField="machineName"
					//	dataFormat={this.userFormatter}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					tdStyle={{ paddingTop: '15px' }}
					dataSort
				>
					{this.props.t('eventList.table.machine')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="30"
					dataAlign="center"
					dataField="eventType"
					dataFormat={this.eventTypeFormatter}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					tdStyle={{ paddingTop: '15px' }}
					dataSort
				>
					{this.props.t('eventList.table.eventType')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="10"
					dataAlign="right"
					dataField="userID"
					dataFormat={this.editFormatter}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					hidden={this.props.hideLink}
				/>
			</BootstrapTable>
		);
	}

	overlayTimeout = null;
	scrollUsed = false;

	handleChartZoom(e) {
		if (e.shiftKey) {
			if (this.reference && this.reference.chartInstance) {
				this.reference.chartInstance.options.plugins.zoom.zoom.enabled = true;
				this.reference.chartInstance.update();
			}

			if (this.scrollOverlayRef) {
				this.scrollOverlayRef.hidden = true;
				this.scrollUsed = true;
			}
			clearTimeout(this.overlayTimeout);
		} else {
			if (this.reference && this.reference.chartInstance) {
				this.reference.chartInstance.options.plugins.zoom.zoom.enabled = false;
				this.reference.chartInstance.update();
			}
			if (!this.scrollUsed) {
				setTimeout(() => {
					if (this.scrollOverlayRef) {
						this.scrollOverlayRef.hidden = false;
					}
					clearTimeout(this.overlayTimeout);
					this.overlayTimeout = setTimeout(() => {
						if (this.scrollOverlayRef) {
							this.scrollOverlayRef.hidden = true;
						}
					}, 3000);
				}, 300);
			}
		}
	}

	renderContent() {}

	render() {
		const { t } = this.props;

		let isNextButtonEnabled = false;
		if (this.state && this.state.startingDate) {
			let nextDate = new Date(this.state.startingDate);
			nextDate.setDate(nextDate.getDate() + parseInt(this.props.days));
			isNextButtonEnabled = nextDate < new Date();
		}

		let grid = null;
		if (this.props.showGrid) {
			grid = this.renderGrid();
		}

		return (
			<>
				{this.renderDialog()}

				<Card className={this.props.className} style={this.props.style}>
					<CardBody>
						<Row style={{ justifyContent: 'space-between' }}>
							<Col sm="5">
								<CardTitle className="mb-0 text-muted">
									<i
										className="fa fa-bar-chart fa-lg"
										style={{ marginRight: '10px' }}
									></i>
									{t('eventList.title')}
								</CardTitle>

								<div className="small text-muted"></div>
							</Col>
							<Col sm="5">
								<div
									style={{
										float: 'right'
									}}
								>
									<Button
										variant={
											this.state.eventVisibilities.filter(item => !item.visible)
												.length === 0
												? 'success'
												: 'primary'
										}
										className={'DefaultButton ' + classes.NavigationButton}
										onClick={() => this.onShowDialog()}
									>
										<i
											className="fa fa-eye"
											style={{ verticalAlign: 'top' }}
											aria-hidden="true"
										></i>
										&nbsp;
									</Button>
									<Button
										variant="success"
										className={'DefaultButton ' + classes.NavigationButton}
										onClick={() => this.onShiftToPrevious()}
									>
										<i
											className="fa fa-chevron-left"
											style={{ verticalAlign: 'top' }}
											aria-hidden="true"
										></i>
										&nbsp;
									</Button>
									<Button
										variant="success"
										className={'DefaultButton ' + classes.NavigationButton}
										onClick={() => this.onShiftToNext()}
										disabled={!isNextButtonEnabled}
									>
										<i
											className="fa fa-chevron-right"
											style={{ verticalAlign: 'top' }}
											aria-hidden="true"
										></i>
									</Button>
								</div>
							</Col>
						</Row>
						{this.state.loading ? (
							<Spinner />
						) : (
							<>
								{grid}
								<div
									className="chart-wrapper"
									style={{
										height: 600 + 'px',
										marginTop: 10 + 'px',
										position: 'relative'
									}}
									onWheel={e => this.handleChartZoom(e)}
									onMouseLeave={e => {
										this.reference.chartInstance.options.plugins.zoom.zoom.enabled = false;
										this.reference.chartInstance.update();
									}}
								>
									<div
										className={classes.ScrollOverlay}
										ref={ref => {
											this.scrollOverlayRef = ref;
										}}
										hidden
									>
										{t('eventList.scrollHint')}
									</div>
									<Scatter
										data={this.mainChart}
										options={this.mainChartOpts}
										height={600}
										ref={reference => {
											this.reference = reference;
											this.updateChart();
										}}
										redraw={!this.state.showDialog}
										onElementsClick={elems => this.onChartClick(elems)}
									/>
								</div>
							</>
						)}
					</CardBody>
				</Card>
			</>
		);
	}
}

function mapStateToProps(state) {
	const { language, user } = state;
	return { language, user };
}

export default connect(mapStateToProps)(
	withRouter(withTranslation()(EventListCard))
);
/*
	<div
										className={classes.ScrollOverlay}
										ref={this.ScrollOverlayRef}
										hidden
									>
										Use ctrl plus zoom to zoom
									</div>*/
