import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './CustomerGeneral.module.css';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class CustomerGeneral extends Component {
	state = {
		person: {
			cmID: '',
			companyName: '',
			taxNumber: '',
			contactPerson: '',
			contactPhoneNumber: '',
			address: '',
			webpage: '',
			emailaddress: '',
			apiKey: ''
		},
		changed: false
	};

	componentDidMount() {
		if (this.props.company) {
			this.setState({
				person: {
					cmID: this.props.company.cmID,
					companyName: this.props.company.company,
					taxNumber: this.props.company.taxNumber,
					contactPerson: this.props.company.contactPerson,
					contactPhoneNumber: this.props.company.contactPhoneNumber,
					address: this.props.company.address,
					webpage: this.props.company.webpage,
					emailaddress: this.props.company.emailaddress,
					currencyID: this.props.company.currencyID,
					apiKey: this.props.company.apiKey
				}
			});
		}
	}

	inputUpdate(e) {
		let newPerson = { ...this.state.person };
		newPerson[e.target.id] = e.target.value;

		if (this.props.onChange) {
			this.props.onChange(newPerson);
		}
		this.setState({ person: newPerson, changed: true });
	}

	handleFormSubmit(e) {
		e.preventDefault();
		this.setState({ changed: false });
		this.props.onSave(this.state.person);
	}

	render() {
		const currencyOptions = this.props.currencies
			? this.props.currencies.map(currency => {
					return <option value={currency.id}>{currency.name}</option>;
			  })
			: null;

		return (
			<Form id="companyForm" onSubmit={e => this.handleFormSubmit(e)}>
				<Form.Label>
					{this.props.t('partnerManagement.customerGeneral.companyName')}
				</Form.Label>
				<Form.Control
					id="companyName"
					type="text"
					placeholder={this.props.t(
						'partnerManagement.customerGeneral.companyNamePlaceholder'
					)}
					value={this.state.person.companyName}
					onChange={e => this.inputUpdate(e)}
					disabled={this.props.disabled}
				/>
				<Form.Label>
					{this.props.t('partnerManagement.customerGeneral.taxNumber')}
				</Form.Label>
				<Form.Control
					id="taxNumber"
					type="text"
					placeholder={this.props.t(
						'partnerManagement.customerGeneral.taxNumberPlaceholder'
					)}
					value={this.state.person.taxNumber || ''}
					onChange={e => this.inputUpdate(e)}
					prepend="#"
					disabled={this.props.disabled}
				/>
				<Form.Label>
					{this.props.t('partnerManagement.customerGeneral.contactPerson')}
				</Form.Label>
				<Form.Control
					id="contactPerson"
					type="text"
					placeholder={this.props.t(
						'partnerManagement.customerGeneral.contactPersonPlaceholder'
					)}
					value={this.state.person.contactPerson || ''}
					onChange={e => this.inputUpdate(e)}
					disabled={this.props.disabled}
				/>
				<Form.Label>
					{this.props.t('partnerManagement.customerGeneral.contactPhoneNumber')}
				</Form.Label>
				<Form.Control
					id="contactPhoneNumber"
					type="text"
					placeholder={this.props.t(
						'partnerManagement.customerGeneral.contactPhoneNumberPlaceholder'
					)}
					value={this.state.person.contactPhoneNumber || ''}
					onChange={e => this.inputUpdate(e)}
					disabled={this.props.disabled}
				/>
				<Form.Label>
					{this.props.t('partnerManagement.customerGeneral.address')}
				</Form.Label>
				<Form.Control
					id="address"
					type="text"
					placeholder={this.props.t(
						'partnerManagement.customerGeneral.addressPlaceholder'
					)}
					value={this.state.person.address || ''}
					onChange={e => this.inputUpdate(e)}
					disabled={this.props.disabled}
				/>
				<Form.Label>
					{this.props.t('partnerManagement.customerGeneral.webpage')}
				</Form.Label>
				<Form.Control
					id="webpage"
					type="text"
					placeholder={this.props.t(
						'partnerManagement.customerGeneral.webpagePlaceholder'
					)}
					value={this.state.person.webpage || ''}
					onChange={e => this.inputUpdate(e)}
					disabled={this.props.disabled}
				/>
				<Form.Label>
					{this.props.t('partnerManagement.customerGeneral.email')}
				</Form.Label>
				<Form.Control
					id="emailaddress"
					type="text"
					placeholder={this.props.t(
						'partnerManagement.customerGeneral.emailPlaceholder'
					)}
					value={this.state.person.emailaddress || ''}
					onChange={e => this.inputUpdate(e)}
					disabled={this.props.disabled}
				/>

				<Form.Label>
					{this.props.t('partnerManagement.customerGeneral.currency')}
				</Form.Label>
				<Form.Control
					id="currencyID"
					as="select"
					value={this.state.person.currencyID}
					onChange={e => this.inputUpdate(e)}
					disabled={
						this.props.disabled ||
						(this.state.person && !!this.state.person.cmID)
					}
				>
					{currencyOptions}
				</Form.Control>

				<Form.Label>
					{this.props.t('partnerManagement.customerGeneral.apiKey')}
				</Form.Label>
				<Form.Control
					id="apiKey"
					type="text"
					placeholder={this.props.t(
						'partnerManagement.customerGeneral.apiKeyPlaceholder'
					)}
					value={this.state.person.apiKey || ''}
					onChange={e => this.inputUpdate(e)}
					disabled={true}
				/>
				<div className={classes.ToolbarDiv}>
					{this.props.showDeleteButton ? (
						<Button
							variant="primary"
							onClick={() => this.props.onDelete(this.state.person)}
							className={classes.DeleteButton + '  DefaultButton'}
						>
							<i className="fa fa-minus" aria-hidden="true"></i>&nbsp;
							{this.props.t('partnerManagement.customerGeneral.delete')}
						</Button>
					) : null}
					{this.props.showSaveButton ? (
						<Button
							variant="success"
							type="submit"
							disabled={
								this.state.person.companyName.trim() === '' ||
								!this.state.changed
							}
							className="DefaultButton"
						>
							<i className="fa fa-floppy-o" aria-hidden="true"></i>&nbsp;
							{this.props.t('partnerManagement.customerGeneral.save')}
						</Button>
					) : null}
				</div>
			</Form>
		);
	}
}

export default withTranslation()(CustomerGeneral);
