import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';

import HelperTooltip from '../../../components/UI/HelperTooltip/HelperTooltip';
import machineApi from '../../../services/api/machines';

//Todo: make API conect

class NewDeviceForm extends Component {
	state = {
		validated: false,
		customMachineName: '',
		machineName: '',
		isVendingMachineNameUnique: true
	};

	validationHandler = () => {
		machineApi
			.isVendingMachineNameUnique(this.state.machineName)
			.then(res =>
				this.setState({ isVendingMachineNameUnique: res.result }, () =>
					this.isValid()
				)
			);
	};

	isValid = () => {
		const form = document.getElementById('addPromotionForm');
		if (form.checkValidity() === true) {
			!this.state.isVendingMachineNameUnique
				? this.setState({ validated: true })
				: this.setNewDevice();
		} else {
			this.setState({ validated: true });
		}
	};

	setNewDevice = () => {
		const param = {
			machineName: this.state.machineName,
			customMachineName: this.state.customMachineName
		};
		machineApi
			.createCostumeMachine(param)
			.then(res => this.props.onSave())
			.catch(e => console.log(e));
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		return (
			<Form noValidate validated={this.state.validated} id={'addPromotionForm'}>
				<Form.Group controlId="deviceName">
					<Row style={{ paddingLeft: '15px' }}>
						<Form.Label>
							{this.props.t('newDeviceForm.deviceName.title')}
						</Form.Label>
						<HelperTooltip
							height={'100px'}
							width={'200px'}
							direction={'right'}
							id={'customMachineName'}
							title={this.props.t('newDeviceForm.deviceName.tooltip.title')}
							content={this.props.t('newDeviceForm.deviceName.tooltip.content')}
						></HelperTooltip>
					</Row>
					<Form.Control
						required
						name="customMachineName"
						type="text"
						placeholder={this.props.t('newDeviceForm.deviceName.placeholder')}
						value={this.state.customMachineName || ''}
						onChange={this.inputChangeHandler}
					/>
					<Form.Control.Feedback type="invalid">
						{this.props.t('newDeviceForm.validation')}
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group controlId="account">
					<Row style={{ paddingLeft: '15px' }}>
						<Form.Label>
							{this.props.t('newDeviceForm.account.title')}
						</Form.Label>
						<HelperTooltip
							height={'100px'}
							width={'200px'}
							direction={'right'}
							id={'machineName'}
							title={this.props.t('newDeviceForm.account.tooltip.title')}
							content={this.props.t('newDeviceForm.account.tooltip.content')}
						></HelperTooltip>
					</Row>
					<Form.Control
						required
						isInvalid={!this.state.isVendingMachineNameUnique}
						placeholder={this.props.t('newDeviceForm.account.placeholder')}
						name="machineName"
						type="text"
						value={this.state.machineName || ''}
						onChange={this.inputChangeHandler}
					/>
					{!this.state.isVendingMachineNameUnique ? (
						<Form.Control.Feedback type="invalid">
							{'Azonosító foglalt'}
						</Form.Control.Feedback>
					) : (
						<Form.Control.Feedback type="invalid">
							{this.props.t('newDeviceForm.validation')}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				<hr></hr>
				<Button
					variant="success"
					className="DefaultButton"
					onClick={() => this.validationHandler()}
				>
					<i className="fa fa-floppy-o mr-2" />
					&nbsp;
					{this.props.t('customerlist.addbutton.savebutton')}
				</Button>
			</Form>
		);
	}
}

export default withTranslation()(NewDeviceForm);
