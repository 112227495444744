import styled from 'styled-components';

export const SectionTitle = styled.div`
	font-size: 20px;
	font-weight: bold;
	text-transform: uppercase;
`;

export const SectionSubTitle = styled.div`
	font-size: 24px;
`;

export const SectionSmallTitle = styled.div`
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
`;

export const SectionSmallSubTitle = styled.div`
	font-size: 16px;
	font-weight: 600;
`;
export const SectionText = styled.div`
	border-bottom: ${props => (props.last ? 'none' : '1px solid  #dedede')};
	font-size: 14px;
	font-weight: 400;
	padding: 6px 10px;
`;

export const SectionList = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
`;

export const Section = styled.li`
	border-bottom: ${props => (props.last ? 'none' : '1px solid #909090')};
	padding: 12px 20px;
`;

export const UnavailableSection = styled.div`
	position: relative;
	background: ${props =>
		props.unavailable ? 'rgba(0, 0, 0, 0.1) ' : 'transparent'};
	border-radius: 10px
	z-index: 1;

	:before {
		visibility: ${props => (props.unavailable ? 'visible' : 'hidden')};
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-weight: bold;
		padding: 10px;
		content: '${props =>
			props.unavailableMassage ? props.unavailableMassage : ' '}';
		position: absolute;
		border-radius: 10px
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		background: rgba(255, 255, 255, 0.8);
		color: var(--label-red)
	}
`;
