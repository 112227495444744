import axios from './api';

export default {
	login: async (email: string, password: string) => {
		const params = {
			email,
			password
		};
		const { data } = await axios.get().post(`/login`, params);
		return data;
	},
	registration: async (email: string, customerId: string) => {
		const param = {
			email,
			cmID: customerId
		};
		return await axios.get().post(`/regUser`, param);
	},
	forgotPassword: async (emailTo: string) => {
		const param = {
			emailTo
		};
		return await axios.get().post(`/users/sendPasswordRenewal`, param);
	},
	setPassword: async (newpassw, passKey) => {
		const param = {
			newpassw,
			passKey
		};
		return await axios.get().post(`/changePassw`, param);
	},
	getProfile: async () => {
		return await axios.get().get(`/profile`);
	},
	getNotificationSettings: async () => axios.get().get(`/notifications`),
	postNotificationSettings: async notificationSettings =>
		axios.get().post(`/notifications`, notificationSettings),
	changePassword: async (oldPassword, newPassword) => {
		const param = {
			oldPassw: oldPassword,
			newPassw: newPassword
		};
		return await axios.get().post(`/SetNewPassword`, param);
	}
};
