import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	NavItem,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from 'reactstrap';

import { connect } from 'react-redux';

import { setLanguage as setLanguageInStore } from '../../store/langauge';
import { getLanguage, setLanguage } from '../../localization';
import { withTranslation } from 'react-i18next';

import usersAPI from '../../services/api/users';
import Auth from '../../services/api/auth';

const propTypes = {
	langSelect: PropTypes.bool
};
const defaultProps = {
	langSelect: false
};

class HeaderDropdown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dropdownOpen: false,
			update: false
		};
	}

	componentDidMount() {
		this.refrehsProfile();
	}

	refrehsProfile() {
		Auth.getProfile().then(result => {
			this.setState({ profile: result.data.response });
		});
	}

	toggle = () => {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	};

	setLanguage = languageCode => {
		setLanguage(languageCode);

		// Save the change into profile data
		let profileToEdit = { ...this.state.profile };
		profileToEdit.languageCode = languageCode.toLowerCase();
		profileToEdit.insertedAt = undefined;
		usersAPI.updateUser(profileToEdit);

		this.props.setLanguage(languageCode);
	};

	//Select language
	selectLanguage = event => {
		//Kiválasztott nyelv
		let value = event.target.value;

		this.setLanguage(value);
	};

	//Get flag
	getFlag = language => {
		if (language === 'hu') {
			return <i className="flag-icon flag-icon-hu" />;
		} else {
			return <i className="flag-icon flag-icon-gb" />;
		}
	};

	langSelect = () => {
		const { t } = this.props;
		const language = getLanguage();
		return (
			<Dropdown
				nav
				className={this.props.className}
				isOpen={this.state.dropdownOpen}
				toggle={this.toggle}
			>
				<DropdownToggle nav className="text-uppercase ">
					{this.getFlag(language)} {language}
				</DropdownToggle>
				<DropdownMenu right className={this.props.dropDownClassName}>
					<DropdownItem header tag="div" className="text-center">
						<strong>{t('languageselector.title')}</strong>
					</DropdownItem>
					<DropdownItem onClick={this.selectLanguage} value="hu">
						<i className="flag-icon flag-icon-hu" />
						HU
					</DropdownItem>
					<DropdownItem onClick={this.selectLanguage} value="en">
						<i className="flag-icon flag-icon-gb" />
						EN
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	};

	render() {
		const { langSelect } = this.props;
		return langSelect ? this.langSelect() : null;
	}
}

HeaderDropdown.propTypes = propTypes;
HeaderDropdown.defaultProps = defaultProps;

function mapStateToProps(state) {
	const { language, user } = state;
	return { language, user };
}

function mapDispatchToProps(dispatch) {
	return {
		setLanguage: language => dispatch(setLanguageInStore(language))
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(HeaderDropdown));
