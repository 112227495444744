import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
//import { withRouter } from 'react-router-dom';
import salesAndIncomeUtils from '../../services/util/salesAndIncomeUtils';
import machineGroupApi from '../../services/api/machineGroup';
import statisticsAPI from '../../services/api/statistics';
import machineApi from '../../services/api/machines';
import { Card, Button, Row, Col, CardTitle } from 'reactstrap';
import PeriodChartFilterPanel from './PeriodChartFiltePanel';
import Spinner from '../../components/UI/Spinner/Spinner';
import IncomeAndSalesChart from './IncomeAndSalesChart';
import classes from './IncomeChart.module.css';
import { Console } from 'winston/lib/winston/transports';

const chart = {
	DAILY_INCOME: 'DAILY_INCOME',
	MONTHLY_INCOME: 'MONTHLY_INCOME',
	YEARLY_INCOME: 'YEARLY_INCOME',
	PERIOD_INCOME: 'PERIOD_INCOME',
	DAILY_PRICE: 'DAILY_PRICE',
	MONTHLY_PRICE: 'MONTHLY_PRICE',
	YEARLY_PRICE: 'YEARLY_PRICE',
	PERIOD_PRICE: 'PERIOD_PRICE'
};

class ChartByType extends Component {
	state = {
		incomeStartDate: this.props.startDate,
		incomeEndDate: this.props.endDate,
		salesStartDate: this.props.startDate,
		salesEndDate: this.props.endDate,
		incomeBreakdown: salesAndIncomeUtils.breakDownOptions.HOURLY,
		salesBreakdown: salesAndIncomeUtils.breakDownOptions.HOURLY,
		loading: true,
		isIncomeGroupSelected: false,
		isSalesGroupSelected: false,
		incomeSelectedMachines: { assignedGroupID: null, assignedVMID: null },
		salesSelectedMachines: { assignedGroupID: null, assignedVMID: null },
		machines: [],
		machineGroups: [],
		optionMachine: null,
		optionGroup: null
	};

	componentDidMount() {
		Promise.all([this.loadMachineGroups(), this.loadMachines()]).then(val => {
			this.setMachineData(val, () => {
				Promise.all([
					this.loadDailyData(),
					this.loadMonthlyData(),
					this.loadYearlyData(),
					this.loadBasicPeriodData()
				]).then(values => {
					this.setData(values);
				});
			});
		});
	}
	loadDailyData = () => {
		let d = new Date();
		let date = d.getDate();
		let month = d.getMonth();
		let year = d.getFullYear();

		let param = {
			fromDate: new Date(year, month, date, '0', '0'),
			toDate: new Date(year, month, date, '23', '59')
		};

		return statisticsAPI.getSalesandIncome(param);
	};
	loadMonthlyData = () => {
		let d = new Date();
		let month = d.getMonth();
		let year = d.getFullYear();

		let param = {
			fromDate: new Date(year, month, '01'),
			toDate: new Date(year, month, '30')
		};

		return statisticsAPI.getSalesandIncome(param);
	};
	loadYearlyData = () => {
		let d = new Date();
		let year = d.getFullYear();

		let param = {
			fromDate: new Date(year, '0', '01'),
			toDate: new Date(year, '11', '31')
		};

		return statisticsAPI.getSalesandIncome(param);
	};
	loadBasicPeriodData = () => {
		let param = {
			fromDate: this.props.startDate,
			toDate: this.props.endDate
		};

		return statisticsAPI.getSalesandIncome(param);
	};
	setPeriodData = (income, state) => {
		if (state.incomeSelectedMachines.assignedVMID) {
			return this.loadPeriodData(
				income,
				state.incomeSelectedMachines.assignedVMID,
				state
			);
		}
		if (state.incomeSelectedMachines.assignedGroupID) {
			return machineGroupApi
				.getAssignedVms(state.incomeSelectedMachines.assignedGroupID)
				.then(r => {
					let v = r.data.vms
						.filter(e => e.telemetryServiceEnabled === 'Y')
						.map(e => e.vmID);

					this.setState({ groupMachines: v }, () =>
						this.loadPeriodData(income, this.state.groupMachines.join(), state)
					);
				});
		}
		if (state.salesSelectedMachines.assignedVMID) {
			return this.loadPeriodData(
				income,
				state.salesSelectedMachines.assignedVMID,
				state
			);
		}
		if (state.salesSelectedMachines.assignedGroupID) {
			return machineGroupApi
				.getAssignedVms(state.salesSelectedMachines.assignedGroupID)
				.then(r => {
					let v = r.data.vms
						.filter(e => e.telemetryServiceEnabled === 'Y')
						.map(e => e.vmID);

					this.setState({ groupMachines: v }, () =>
						this.loadPeriodData(income, this.state.groupMachines.join(), state)
					);
				});
		}
		return this.loadPeriodData(income, null, state);
	};

	loadPeriodData = (income, m, state) => {
		let br = income ? state.incomeBreakdown : state.salesBreakdown;
		let machinesIDString = m ? m : null;
		let param = {
			fromDate: income ? state.incomeStartDate : state.salesStartDate,
			toDate: income ? state.incomeEndDate : state.salesEndDate,
			machinesIDString: machinesIDString ? machinesIDString : ''
		};
		return statisticsAPI.getSalesandIncome(param).then(res => {
			let dataWithoutVmidNull = res.data;
			if (m) {
				dataWithoutVmidNull = res.data.filter(e => e.vmID !== null);
			}
			let period = salesAndIncomeUtils.formatData(dataWithoutVmidNull, br);
			let currency = res.data[0] ? res.data[0].currency : '';
			income
				? this.setState({
						incomePeriodData: period,
						currency: currency
				  })
				: this.setState({
						salesPeriodData: period,
						currency: currency
				  });
		});
	};
	loadMachineGroups = () => {
		return machineGroupApi.getMachineGroups();
	};
	loadMachines = () => {
		return machineApi.getVisibleVendingMachines();
	};
	setMachineData = (values, promise) => {
		let machineGroups = values[0] ? values[0].data.groups : [];
		let machines = values[1]
			? values[1].data.response.filter(e => e.telemetryServiceEnabled === 'Y')
			: [];

		this.setState(
			{
				machineGroups: machineGroups,
				machines: machines
			},
			() => promise()
		);
	};
	setData = values => {
		let daily = salesAndIncomeUtils.formatData(
			values[0].data,
			salesAndIncomeUtils.breakDownOptions.HOURLY
		);
		let monthly = salesAndIncomeUtils.formatData(
			values[1].data,
			salesAndIncomeUtils.breakDownOptions.DAILY
		);
		let yearly = salesAndIncomeUtils.formatData(
			values[2].data,
			salesAndIncomeUtils.breakDownOptions.MONTHLY
		);
		let period = salesAndIncomeUtils.formatData(
			values[3].data,
			salesAndIncomeUtils.breakDownOptions.DAILY
		);

		let currency = '';
		if (values[0].data[0]) {
			currency = values[0].data[0].currency;
		}
		if (values[1].data[0]) {
			currency = values[1].data[0].currency;
		}
		if (values[2].data[0]) {
			currency = values[2].data[0].currency;
		}
		if (values[3].data[0]) {
			currency = values[3].data[0].currency;
		}
		let incomeChartType = chart.MONTHLY_INCOME;
		let salesChartType = chart.MONTHLY_PRICE;
		if (this.props.chartType) {
			switch (this.props.chartType) {
				case 'dayly':
					incomeChartType = chart.DAILY_INCOME;
					salesChartType = chart.DAILY_PRICE;
					break;
				case 'monthly':
					incomeChartType = chart.MONTHLY_INCOME;
					salesChartType = chart.MONTHLY_PRICE;
					break;
				case 'yearly':
					incomeChartType = chart.YEARLY_INCOME;
					salesChartType = chart.YEARLY_PRICE;
					break;
				case 'period':
					incomeChartType = chart.PERIOD_INCOME;
					salesChartType = chart.PERIOD_PRICE;
					break;
			}
		}
		let optionMachine = this.state.machines.map(e => ({
			key: e.vmID,
			value: e.vmID,
			label: e.customName
		}));
		let optionGroup = this.state.machineGroups.map(e => ({
			key: e.id,
			value: e.id,
			label: e.name
		}));
		this.setState({
			dailyData: daily,
			monthlyData: monthly,
			yearlyData: yearly,
			incomePeriodData: period,
			salesPeriodData: period,
			incomeChartType: incomeChartType,
			salesChartType: salesChartType,
			currency: currency,
			optionMachine: optionMachine,
			optionGroup: optionGroup
		});
	};

	incomeChartChangeHandler = chart => {
		this.setState({ incomeChartType: chart });
	};
	salesChartChangeHandler = chart => {
		this.setState({ salesChartType: chart });
	};

	// Render chart and select type -------------

	chartType = income => {
		const { t } = this.props;
		let chartType = <Spinner />;
		if (
			this.state.dailyData &&
			this.state.monthlyData &&
			this.state.yearlyData &&
			this.state.incomeChartType &&
			this.state.salesChartType
		) {
			switch (income ? this.state.incomeChartType : this.state.salesChartType) {
				case income ? chart.DAILY_INCOME : chart.DAILY_PRICE:
					chartType = (
						<IncomeAndSalesChart
							finalData={this.state.dailyData}
							income={income}
							currency={this.state.currency}
							title={
								income
									? t('cashFlow.chartbyTypes.title.daily.income')
									: t('cashFlow.chartbyTypes.title.daily.sales')
							}
						/>
					);
					break;
				case income ? chart.MONTHLY_INCOME : chart.MONTHLY_PRICE:
					chartType = (
						<IncomeAndSalesChart
							finalData={this.state.monthlyData}
							income={income}
							currency={this.state.currency}
							title={
								income
									? t('cashFlow.chartbyTypes.title.monthly.income')
									: t('cashFlow.chartbyTypes.title.monthly.sales')
							}
						/>
					);
					break;
				case income ? chart.YEARLY_INCOME : chart.YEARLY_PRICE:
					chartType = (
						<IncomeAndSalesChart
							finalData={this.state.yearlyData}
							income={income}
							currency={this.state.currency}
							title={
								income
									? t('cashFlow.chartbyTypes.title.yearly.income')
									: t('cashFlow.chartbyTypes.title.yearly.sales')
							}
						/>
					);
					break;
				case income ? chart.PERIOD_INCOME : chart.PERIOD_PRICE:
					chartType = (
						<>
							{this.state.optionMachine && this.state.optionGroup ? (
								<PeriodChartFilterPanel
									optionMachine={this.state.optionMachine}
									optionGroup={this.state.optionGroup}
									onFilter={this.setPeriodData}
									income={income}
									startDate={this.props.startDate}
									endDate={this.props.endDate}
								/>
							) : null}

							<IncomeAndSalesChart
								finalData={
									income
										? this.state.incomePeriodData
										: this.state.salesPeriodData
								}
								income={income}
								currency={this.state.currency}
								title={
									income
										? t('cashFlow.chartbyTypes.title.period.income')
										: t('cashFlow.chartbyTypes.title.period.sales')
								}
							/>
						</>
					);
					break;
			}
		}

		return chartType;
	};

	chart = income => {
		const { t } = this.props;
		const { incomeChartType, salesChartType } = this.state;
		return (
			<Card body className={this.props.className} style={this.props.style}>
				<Col>
					<CardTitle className="mb-0 text-muted">
						<i
							className="fa fa-line-chart fa-lg"
							style={{ marginRight: '10px' }}
						></i>
						{income
							? t('cashFlow.chartbyTypes.cardtitle.income')
							: t('cashFlow.chartbyTypes.cardtitle.sales')}
					</CardTitle>
					<hr></hr>
				</Col>
				<Row className={classes.ButtonGroup}>
					<Button
						className={
							income
								? chart.DAILY_INCOME === incomeChartType
									? classes.ChartButtonActive
									: classes.ChartButton
								: chart.DAILY_PRICE === salesChartType
								? classes.ChartButtonActive
								: classes.ChartButton
						}
						onClick={
							income
								? () => this.incomeChartChangeHandler(chart.DAILY_INCOME)
								: () => this.salesChartChangeHandler(chart.DAILY_PRICE)
						}
						id={income ? chart.DAILY_INCOME : chart.DAILY_PRICE}
					>
						<i className="fa fa-line-chart mr-2"></i>
						{income
							? t('cashFlow.chartbyTypes.button.daily.income')
							: t('cashFlow.chartbyTypes.button.daily.sales')}
					</Button>
					<Button
						className={
							income
								? chart.MONTHLY_INCOME === incomeChartType
									? classes.ChartButtonActive
									: classes.ChartButton
								: chart.MONTHLY_PRICE === salesChartType
								? classes.ChartButtonActive
								: classes.ChartButton
						}
						onClick={
							income
								? () => this.incomeChartChangeHandler(chart.MONTHLY_INCOME)
								: () => this.salesChartChangeHandler(chart.MONTHLY_PRICE)
						}
						id={income ? chart.MONTHLY_INCOME : chart.MONTHLY_PRICE}
					>
						<i className="fa fa-line-chart mr-2"></i>
						{income
							? t('cashFlow.chartbyTypes.button.monthly.income')
							: t('cashFlow.chartbyTypes.button.monthly.sales')}
					</Button>
					<Button
						className={
							income
								? chart.YEARLY_INCOME === incomeChartType
									? classes.ChartButtonActive
									: classes.ChartButton
								: chart.YEARLY_PRICE === salesChartType
								? classes.ChartButtonActive
								: classes.ChartButton
						}
						onClick={
							income
								? () => this.incomeChartChangeHandler(chart.YEARLY_INCOME)
								: () => this.salesChartChangeHandler(chart.YEARLY_PRICE)
						}
						id={income ? chart.YEARLY_INCOME : chart.YEARLY_PRICE}
					>
						<i className="fa fa-line-chart mr-2"></i>
						{income
							? t('cashFlow.chartbyTypes.button.yearly.income')
							: t('cashFlow.chartbyTypes.button.yearly.sales')}
					</Button>
					<Button
						className={
							income
								? chart.PERIOD_INCOME === incomeChartType
									? classes.ChartButtonActive
									: classes.ChartButton
								: chart.PERIOD_PRICE === salesChartType
								? classes.ChartButtonActive
								: classes.ChartButton
						}
						onClick={
							income
								? () => this.incomeChartChangeHandler(chart.PERIOD_INCOME)
								: () => this.salesChartChangeHandler(chart.PERIOD_PRICE)
						}
						id={income ? chart.PERIOD_INCOME : chart.PERIOD_PRICE}
					>
						<i className="fa fa-line-chart mr-2"></i>
						{income
							? t('cashFlow.chartbyTypes.button.period.income')
							: t('cashFlow.chartbyTypes.button.period.sales')}
					</Button>
				</Row>
				{this.chartType(income)}
			</Card>
		);
	};

	render() {
		const income = this.props.income;
		return this.chart(income);
	}
}

export default withTranslation()(ChartByType);
