import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import {
	Section,
	SectionSmallTitle,
	SectionSubTitle,
	SectionText
} from '../../../../components/Section';
import { withTranslation } from 'react-i18next';
import classes from './Datasheet.module.css';
import ConfimationDialog from '../../../ConfirmationDialog/ConfirmationDialog';
import moment from 'moment';

class NfdHardwareSheet extends Component {
	state = {
		saveInProgress: false,
		editMode: null,
		peripherial: [],
		protocol: null,
		waterHardnes: null,
		softReplaced: null,
		filterReplaced: null,
		capacity: null
	};
	componentDidMount() {
		let peripherial = { ...this.props.peripherial };

		this.setState({
			peripherial: peripherial
		});
	}
	hardwareNameFormater = name => {
		return name.replace(/_/, ' ');
	};

	hideConfirmationDialog() {
		this.setState({
			deletePeripheralConfirmationVisible: false
		});
	}

	showDeleteConfirmation() {
		this.setState({
			deletePeripheralConfirmationVisible: true
		});
	}

	renderDeleteDeviceConfirmationDialog = () => {
		return (
			<ConfimationDialog
				title="Confirmation"
				confirmationText={
					<>
						<Col>
							{this.props.t('device.details.datasheet.peripherals.delConf')}
						</Col>
						<Col>
							<Row>
								<Col>
									{this.props.t('device.details.datasheet.peripherals.delPer')}
								</Col>
								<Col style={{ fontWeight: 'bold' }}>
									{this.hardwareNameFormater(this.props.peripherial.name)}
								</Col>
							</Row>
						</Col>
					</>
				}
				okText={this.props.t('device.details.invisibleTouch.ok')}
				cancelText={this.props.t('device.details.invisibleTouch.cancel')}
				isOpen={this.state.deletePeripheralConfirmationVisible}
				onToggle={() => {
					this.hideConfirmationDialog();
				}}
				onCancel={() => {
					this.hideConfirmationDialog();
				}}
				onOk={() => {
					this.hideConfirmationDialog();
					this.props.onDelet(this.props.peripherial.partID);
				}}
			/>
		);
	};

	dateFormatter = cell => {
		return moment(cell).format('YYYY. MM. DD. HH:mm:ss');
	};

	render() {
		const { peripherial, t, root, companyAdmin } = this.props;
		return (
			<>
				{this.renderDeleteDeviceConfirmationDialog()}

				<Section className={classes.SubTitleRow}>
					<Row
						style={{
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<Col xs={12} sm={6} md={12} lg={6}>
							<SectionSubTitle>
								{peripherial.name
									? this.hardwareNameFormater(peripherial.name)
									: t('device.details.datasheet.noData')}
							</SectionSubTitle>
						</Col>
						{root || companyAdmin ? (
							<Col xs={12} sm={6} md={12} lg={6}>
								<Row
									style={{ alignItems: 'center', justifyContent: 'flex-end' }}
								>
									<Button
										color="primary"
										className="DefaultButton"
										onClick={() => this.showDeleteConfirmation()}
									>
										<i className={'fa fa-trash mr-2'}></i>
										{t('partnerManagement.customerGeneral.delete')}
									</Button>
								</Row>
							</Col>
						) : null}
					</Row>
				</Section>
				<Section last>
					<Section last>
						<SectionSmallTitle>
							{t('device.details.datasheet.nfd.nfdData.kitSN')}
						</SectionSmallTitle>
						<SectionText>
							{peripherial.electronicsItemNumber ||
								t('device.details.datasheet.noData')}
						</SectionText>
					</Section>

					<Section last>
						<SectionSmallTitle>
							{t('device.details.datasheet.peripherals.paymentSys.account')}
						</SectionSmallTitle>
						<SectionText>
							{peripherial.hardwareID || t('device.details.datasheet.noData')}
						</SectionText>
					</Section>
					<Section last>
						<SectionSmallTitle>
							{t('device.details.datasheet.nfd.nfdData.startup')}
						</SectionSmallTitle>

						<SectionText>
							{peripherial.fromDate
								? this.dateFormatter(peripherial.fromDate)
								: t('device.details.datasheet.noData')}
						</SectionText>
					</Section>
					<Section last>
						<SectionSmallTitle>
							{t('device.details.datasheet.nfd.nfdData.manDate')}
						</SectionSmallTitle>

						<SectionText>
							{peripherial.insertedAt
								? this.dateFormatter(peripherial.insertedAt)
								: t('device.details.datasheet.noData')}
						</SectionText>
					</Section>

					<Section last>
						<SectionSmallTitle>
							{t('device.details.datasheet.nfd.nfdData.state')}
						</SectionSmallTitle>

						<SectionText last>
							{peripherial.hardwareState ||
								t('device.details.datasheet.noData')}
						</SectionText>
					</Section>
				</Section>
			</>
		);
	}
}

export default withTranslation()(NfdHardwareSheet);
