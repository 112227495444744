//@flow
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import ProductInfoDialog from './ProductInfoDialog';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import logo from '../../../../assets/images/logo.png';
import empty from '../../../../assets/images/empty.png';
import machineApi from '../../../../services/api/machines';
import productApi from '../../../../services/api/products';
import classes from './VMProductCard.module.css';

//Base64 URL
// --- telemetry currently not receiving information from Stock ---
// import Stock from '../../Stock';

const CardName = styled.h4`
	min-height: 50px;
	margin: 1px;
`;

const CardCustomName = styled.h6`
	min-height: 30px;
	margin: 1px;
`;

type Props = {
	VMItem: Object,
	t: (id: string) => string
};
type States = {};

class VMProductCard extends React.Component<Props, States> {
	state = { showInfoDialog: false };

	//Get image src from Base64 image
	getImgSrc = t => {
		let imageSRC = logo;
		if (this.props.VMItem && this.props.VMItem.image) {
			//imageSRC = 'data:image/jpeg;base64,' + this.props.VMItem.image;
			imageSRC = this.props.VMItem.image;
		}
		if (!this.props.VMItem) {
			imageSRC = empty;
		}
		return (
			<img
				style={t ? { borderColor: 'transparent' } : null}
				alt=""
				src={imageSRC}
				id="imgSet"
			/>
		);
	};

	getPrice = () => {
		let price = '-';
		if (this.props.VMItem && this.props.VMItem.price) {
			if (isNaN(this.props.VMItem.price)) {
				return (price = '-');
			}
			return (price = this.props.VMItem.price);
		}
		return price;
	};

	hideInfoDialog = () => {
		this.setState({ showInfoDialog: false });
	};

	showInfoDialog = async pID => {
		const resp = await productApi.getProductDetails(
			this.props.vmID,
			this.props.location,
			pID
		);

		if (resp && resp.data && resp.data.response) {
			const VMItem = resp.data.response.map((Item: any) => {
				Item.price = Math.round(parseFloat(Item.price) * 100) / 100;
				return Item;
			});

			const newVMItem = { ...this.props.VMItem, ...VMItem[0] };

			this.setState({ VMItem: newVMItem, showInfoDialog: true });
		}
	};

	handleCountChange = (vmItem, maxCount, currentCount, notifyThreshold) => {
		productApi
			.editCountFields(vmItem.vmID, vmItem.pID, maxCount, notifyThreshold)
			.then();
		if (vmItem.currentCount != currentCount)
			productApi.editAmounts(vmItem.vmID, vmItem.pID, currentCount).then();

		const newVMItem = { ...vmItem };
		newVMItem.currentCount = currentCount;
		newVMItem.maxCount = maxCount;
		newVMItem.notifyThreshold = notifyThreshold;
		this.setState({ VMItem: newVMItem });
	};

	renderInfoDialog = () => {
		const { t, VMItem, flags, location } = this.props;
		const localisedName =
			VMItem && VMItem.localisedName
				? VMItem.localisedName
				: t('promotionManager.promotionTable.noData');
		return this.state.VMItem && this.state.VMItem.details ? (
			<ProductInfoDialog
				show={this.state.showInfoDialog}
				VMItem={this.state.VMItem}
				location={location}
				flags={flags}
				name={localisedName}
				currencySymbol={this.props.currencySymbol}
				price={this.state.VMItem.price}
				image={() => this.getImgSrc(true)}
				onHide={this.hideInfoDialog}
				onCountChanges={(maxCount, currentCount, notifiyThreshold) =>
					this.handleCountChange(
						this.state.VMItem,
						maxCount,
						currentCount,
						notifiyThreshold
					)
				}
			/>
		) : null;
	};

	getShortenedText(text, maxLength) {
		if (text.length <= maxLength) return text;

		return text.slice(0, maxLength - 3).trim() + '...';
	}

	//Render
	render() {
		const { t } = this.props;
		const VMItem = this.state.VMItem ? this.state.VMItem : this.props.VMItem;

		const cardName = (
			<CardName align="left">
				{this.getShortenedText(
					VMItem && VMItem.localisedName
						? VMItem.localisedName
						: t('layoutManager.noProduct'),
					21
				)}
			</CardName>
		);

		const cardCustomName = (
			<CardCustomName align="left">
				{this.getShortenedText(
					VMItem && VMItem.name ? '(' + VMItem.name + ')' : '',
					21
				)}
			</CardCustomName>
		);

		const image = this.getImgSrc();
		const price = this.getPrice();
		const amount = VMItem
			? `${
					VMItem.currentCount || VMItem.currentCount == 0
						? VMItem.currentCount
						: 0
			  }/${VMItem.maxCount || VMItem.maxCount == 0 ? VMItem.maxCount : 0}`
			: undefined;

		const renderCard = () => {
			return (
				<Card className={classes.ProductCard} color={'light'}>
					{this.renderInfoDialog()}
					<CardBody
						className={classes.ThinMargin}
						onClick={e =>
							this.props.VMItem && this.props.VMItem.pID
								? this.showInfoDialog(this.props.VMItem.pID)
								: null
						}
					>
						<div
							className={
								classes.ProductHeader + ', ' + classes.MobileVisibility
							}
						>
							{cardName}
							{cardCustomName}
						</div>
						{image}
						<h6 style={{ display: 'inline-block' }}>
							<a className={classes.MobileVisibility}>
								{t('layoutManager.productInfoCard.position')}:{' '}
							</a>
							<b>{this.props.positionString}</b>
						</h6>
						{VMItem ? (
							<h6
								style={{ display: 'inline-block' }}
								id="promCardPrice"
								className={classes.MobileVisibility}
							>
								{' '}
								{t('layoutManager.productInfoCard.price')}
								{': '}
								<strong>
									{price}
									{this.props.currencySymbol}
								</strong>
							</h6>
						) : (
							undefined
						)}
						<br />
						{VMItem ? (
							<h6
								style={{
									display: 'inline-block',
									color:
										VMItem &&
										VMItem.notifyThreshold &&
										VMItem.currentCount <= VMItem.notifyThreshold
											? 'red'
											: undefined
								}}
								id="promCardAmount"
							>
								<a className={classes.MobileVisibility}>
									{t('layoutManager.productInfoCard.amount')}
									{': '}
								</a>
								<strong>{amount}</strong>
							</h6>
						) : (
							undefined
						)}
					</CardBody>
				</Card>
			);
		};

		return renderCard();
	}
}

export default withTranslation()(VMProductCard);
