import axios from './api';
import qs from 'qs';
import { mysqlDateTimeStampFromDate } from '../util/dateUtil.js';
import { now } from 'moment';

export default {
	saveMachineGalery: async (vmID, formData) => {
		return await axios.get().put(`/machines/${vmID}/galery`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' }
		});
	},

	getCustomerVM: async () => {
		return await axios.get().get(`/machines`);
	},

	getHardwareTypes: () => {
		return axios.get().get(`/machines/types`);
	},

	getHIDHardwares: onlyUnassigned => {
		const param = {
			onlyUnassigned
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios.get().get(`/machines/hardwares/HID${query}`);
	},

	getMIHardwares: onlyUnassigned => {
		const param = {
			onlyUnassigned: onlyUnassigned ? 'Y' : 'N'
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios.get().get(`/machines/hardwares/parts${query}`);
	},

	getMIHardwareTypes: () => {
		return axios.get().get(`/machines/hardwares/parts/types`);
	},

	getHardwareStates: () => {
		return axios.get().get(`/machines/hardwareStates`);
	},

	getIDStates: () => {
		return axios.get().get(`/machines/idStates`);
	},

	getVMDetail: async vmID => {
		const param = {
			vmID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/getVmByVmID${query}`);
	},

	getVisibleVendingMachines: async () => {
		return await axios.get().get(`/getVisibleVendingMachines`);
	},

	getVmBycmID: async cmID => {
		const param = {
			cmID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/getVmBycmID${query}`);
	},

	getNewestVendingMachines: async limit => {
		const param = {
			limit
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/machines/newest${query}`);
	},

	getVMsByOperator: async (cmID, userID) => {
		const param = {
			cmID,
			userID,
			isVmRequest: true
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/operators${query}`);
	},

	getEventTypes: async (cmID, userID) => {
		return await axios.get().get(`/machines/eventTypes`);
	},

	getVMPeripherials: async vmID => {
		const param = {
			machineID: vmID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/machines/getVMPeripherials${query}`);
	},

	deleteVM: async vmID => {
		const param = {
			vmID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().delete(`/deleteVM${query}`);
	},
	isCustomerVM: async vmID => {
		const param = {
			vmID,
			isCustomer: true
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/GetVMPrivilege${query}`);
	},
	isOperatorVM: async vmID => {
		const param = {
			vmID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/GetVMPrivilege${query}`);
	},

	insertMI: async miData => {
		let newMIData = { ...miData };
		newMIData.manufactureDate = mysqlDateTimeStampFromDate(
			miData.manufactureDate
		);
		return await axios
			.get()
			.put(`/machines/hardwares/parts`, { mi: { ...newMIData } });
	},

	updateHardware: hardware => {
		let newData = {
			id: hardware.id,
			machineType: hardware.machineType,
			hardwareState: hardware.hardwareState,
			softwareVersion: hardware.softwareVersion,
			company: hardware.customerID,
			invisibleTouchCapable: hardware.invisibleTouchCapable
		};

		return axios.get().post(`/machines/edit`, { hid: { ...newData } });
	},

	updateMI: miData => {
		let newMIData = {
			...miData,
			insertedAt: undefined,
			insertedBy: undefined,
			updatedAt: undefined,
			updatedBy: undefined,
			nr: undefined
		};
		if (newMIData.manufactureDate) {
			newMIData.manufactureDate = mysqlDateTimeStampFromDate(
				miData.manufactureDate
			);
		}

		return axios
			.get()
			.post(`/machines/hardwares/parts/edit`, { mi: { ...newMIData } });
	},

	printMIZebra: (label1, label2, id) => {
		const param = { label1, label2, id };
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios.get().get(`/machines/hardwares/parts/print${query}`);
	},

	printHIDZebraSmall: id => {
		const param = { id };
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios.get().get(`/machines/hardwares/hid/print/small${query}`);
	},

	printHIDZebraLarge: ids => {
		const param = { ids };
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios.get().get(`/machines/hardwares/hid/print/large${query}`);
	},

	getMachinePartHistory: id => {
		return axios.get().get(`/machines/${id}/partshistory`);
	},

	getPartHistory: id => {
		return axios.get().get(`/machines/hardwares/parthistory/${id}`);
	},

	getHardwareIDSByMachineID: async machineID => {
		const hardwareIDS = (await axios
			.get()
			.get(`/machines/${machineID}/hardware/`)).data.hardwareIDS;

		return hardwareIDS;
	},

	getVendingMachineByHardwareID: async hardwareID => {
		const vendingMachineID = (await axios
			.get()
			.get(`/machines/getMachineByHardwareID/${hardwareID}`)).data
			.vendingMachineID;
		return vendingMachineID;
	},

	getVMConnectionStartByHardwareID: async hardwareID => {
		const fromDate = (await axios
			.get()
			.get(`/machines/getMachineByHardwareID/${hardwareID}`)).data.fromDate;
		return fromDate;
	},

	updateVendingMachine: async (
		vmID,
		customName,
		country,
		zipcode,
		city,
		address,
		lng,
		lat,
		vendorName,
		partnerClientID
	) => {
		const param = {
			vmID,
			customName,
			country,
			zipcode,
			city,
			address,
			lng,
			lat,
			vendorName,
			partnerClientID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		await axios.get().post(`/vendingmachine${query}`);
	},

	updateInvisibleTouchSettings: async (vmID, preTransCollection) => {
		const param = { vmID, preTransCollection };
		const query = qs.stringify(param, { addQueryPrefix: true });
		await axios.get().post(`/vendingmachine/invisibleTouchSettings${query}`);
	},

	updateSupportAccess: async (hardwareID, supportAccessEnabled) => {
		const param = { hardwareID, supportAccessEnabled };
		const query = qs.stringify(param, { addQueryPrefix: true });

		await axios.get().post(`/machines/updateSupportAccess${query}`);
	},

	updateInvisibleTouch: async (vmID, invisibleTouchServiceEnabled) => {
		const param = { invisibleTouchServiceEnabled, vmID };
		const query = qs.stringify(param, { addQueryPrefix: true });

		await axios.get().post(`/machines/updateServiceInvisibleTouch${query}`);
	},

	updateInvisibleTouchVisibleOnMap: async (
		vmID,
		invisibleTouchVisibleOnMap
	) => {
		const param = { invisibleTouchVisibleOnMap, vmID };
		const query = qs.stringify(param, { addQueryPrefix: true });

		await axios
			.get()
			.post(`/machines/updateServiceInvisibleTouchVisibleOnMap${query}`);
	},

	updateInvisibleTouchOperation: async (vmID, invTouchOperation) => {
		const param = { invisibleTouchOperationEnabled: invTouchOperation, vmID };
		const query = qs.stringify(param, { addQueryPrefix: true });

		await axios.get().post(`/machines/updateInvisibleTouchOperation${query}`);
	},

	updateNfdUgdState: async (vmID, nfdUpgraded) => {
		const param = { nfdUpgraded, vmID };
		const query = qs.stringify(param, { addQueryPrefix: true });

		await axios.get().post(`/machines/updateNFDUpgradedState${query}`);
	},

	updateTelemetryService: async (vmID, telemetryServiceEnabled) => {
		const param = { telemetryServiceEnabled, vmID };
		const query = qs.stringify(param, { addQueryPrefix: true });

		await axios.get().post(`/machines/updateServiceTelemetry${query}`);
	},

	updateRemoteControlService: async (vmID, remoteControlServiceEnabled) => {
		const param = { remoteControlServiceEnabled, vmID };
		const query = qs.stringify(param, { addQueryPrefix: true });

		await axios.get().post(`/machines/updateServiceRemoteControl${query}`);
	},

	addMaintenanceEvent: async (hardwareID, date) => {
		date = date ? date : now();
		const data = { hardwareID, eventType: 12, date, level: 0 };
		await axios.get().put(`/machines/hardwares/event`, data);
	},

	updateMachineState: async (vendingMachineID, newState) => {
		await axios
			.get()
			.post(`/machines/${vendingMachineID}/state`, { state: newState });
	},

	updateHardwareConnection: async (hardwareID, toDate, machineName) => {
		await axios.get().post(`/machines/hardwares/machineConnection`, {
			hardwareID,
			toDate,
			machineName
		});
	},

	getLastSaleForVendingMachines: async vmIDs => {
		return (await axios
			.get()
			.post(`/machines/getLastSaleForVendingMachines`, { vmIDs })).data;
	},

	getLastErrorForVendingMachines: async vmIDs => {
		return (await axios
			.get()
			.post(`/machines/getLastErrorForVendingMachines`, { vmIDs })).data;
	},

	getAssignedUserForVendingMachines: async vmIDs => {
		return (await axios
			.get()
			.post(`/machines/getAssignedUserForVendingMachines`, { vmIDs })).data;
	},

	getDailyIncomeFromMachine: async vmIDs => {
		return (await axios
			.get()
			.post(`/machines/getDailyIncomeFromMachine`, { vmIDs })).data;
	},

	getAvailableLayouts: async vmID => {
		const query = qs.stringify({ vmID }, { addQueryPrefix: true });
		return (await axios.get().get(`/vendingmachine/layouts/${query}`)).data;
	},

	setActiveLayout: async (vmID, layoutID) => {
		const query = qs.stringify({ vmID, layoutID }, { addQueryPrefix: true });
		await axios.get().post(`/vendingmachine/layout/${query}`);
	},

	getProducts: async (vmID, langCode) => {
		const query = qs.stringify({ langCode }, { addQueryPrefix: true });
		return (await axios.get().get(`/machines/${vmID}/products${query}`)).data;
	},

	getProductLocations: async vmID => {
		return (await axios.get().get(`/machines/${vmID}/product/languages`)).data;
	},

	getProducts: async (vmID, langCode) => {
		const query = qs.stringify({ langCode }, { addQueryPrefix: true });
		return (await axios.get().get(`/machines/${vmID}/products${query}`)).data;
	},

	getProductImage: (vmID, productID) => {
		const query = qs.stringify(
			{ productID, token: axios.getToken() },
			{ addQueryPrefix: true }
		);

		return `${
			axios.getConfig().baseURL
		}/machines/${vmID}/product/image${query}`;
		//return (await axios.get().get(`/machines/${vmID}/product/image${query}`))	.data;
	},

	createInvTouchMachine: async (customerID, hardwareID, partID) => {
		await axios.get().post(`/machines/createInvTouchMachine`, {
			customerID,
			hardwareID,
			partID
		});
	},

	setConnectedPeripherals: async (machineID, parts) => {
		const param = { machineID };
		const query = qs.stringify(param, { addQueryPrefix: true });
		await axios.get().post(`/machines/updateConnectedPeripherals${query}`, {
			parts
		});
	},

	isVendingMachineNameUnique: async value => {
		const param = {
			name: value
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return (await axios.get().get(`/machines/isUniqueMachineName${query}`))
			.data;
	},

	createCostumeMachine: async value => {
		const param = {
			machineName: value.machineName,
			customMachineName: value.customMachineName,
			createDefaultCustomHardwareSet: 'Y'
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		await axios.get().post(`/machines/createCustomMachine${query}`);
	},

	deleteMachine: async value => {
		const param = {
			vmID: value
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		await axios
			.get()
			.delete(`/machines/${query}`)
			.then()
			.catch(e => console.log(e));
	},

	createMachineHardwarePart: async value => {
		const param = {
			partType: value.partType,
			customerID: value.customerID,
			serialNumber: value.serialNumber,
			hardwareState: value.hardwareState,
			orderNumber: value.orderNumber,
			firmwareName: value.firmwareName,
			firmwareFilename: value.firmwareFilename,
			microControllerType: value.microControllerType,
			customManufacturerName: value.customManufacturerName,
			customType: value.customType,
			customIdentifier: value.customIdentifier,
			customSWVersion: value.customSWVersion,
			customData: value.customData
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return (await axios.get().put(`/machines/hardwares/parts${query}`)).data;
	},

	updateMachineHardwarePart: async value => {
		const param = {
			id: value.partID,
			partType: value.partType,
			customerID: value.customerID,
			serialNumber: value.serialNumber,
			hardwareState: value.hardwareState,
			orderNumber: value.orderNumber,
			firmwareName: value.firmwareName,
			firmwareFilename: value.firmwareFilename,
			microControllerType: value.microControllerType,
			customManufacturerName: value.customManufacturerName,
			customType: value.customType,
			customIdentifier: value.customIdentifier,
			customSWVersion: value.customSWVersion,
			customData: value.customData
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		await axios.get().post(`/machines/hardwares/parts${query}`);
	},

	createSlave: async value => {
		const param = {
			vmID: value.vmID,
			prefix: value.prefix,
			vendor: value.vendor,
			machineType: value.machineType
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		await axios.get().post(`/vendingmachine/slave${query}`);
	},

	deletSlave: async value => {
		const param = {
			vmID: value.vmID,
			slaveID: value.slaveID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		await axios.get().delete(`/vendingmachine/slave${query}`);
	},

	getConectedHardwares: async value => {
		const param = {
			machineID: value
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/machines/getVMPeripherials${query}`);
	}
};
