import React, { Component } from 'react';
// import * as name from 'chartjs-plugin-colorschemes';
import { Doughnut } from 'react-chartjs-2';

class DoughnutChart extends Component {
	prepareData() {
		return {
			labels: [...this.props.xValues],
			datasets: [
				{
					data: [...this.props.yValues],
					fill: false
				}
			]
		};
	}

	options = {
		plugins: {
			colorschemes: {
				scheme: 'tableau.HueCircle19'
			}
		}
	};
	render() {
		const data = this.prepareData();
		return <Doughnut data={data} options={{ ...this.options }} />;
	}
}

/*

*/
export default DoughnutChart;
