import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import UserList from '../../views/UserList/UserList.js';
import { ROLES } from '../../services/util/consts';

class Users extends Component {
	getPossibleRolesForCreation() {
		// HVend admin can only create HVend admin and HVend technican on this page
		if (this.props.user.privilege === ROLES.root) {
			const adminPageList = [ROLES.root, ROLES.hvendTechnican];
			return adminPageList;
		}
		// Company admin on it's user page can add any sub user role
		if (this.props.user.privilege === ROLES.companyAdmin) {
			const adminPageList = [
				ROLES.companyAdmin,
				ROLES.regionalCompanyAdmin,
				ROLES.technician
			];
			return adminPageList;
		}

		// Regional Company admin on it's user page can add any sub user role
		if (this.props.user.privilege === ROLES.regionalCompanyAdmin) {
			const adminPageList = [ROLES.technician];
			return adminPageList;
		}

		return [];
	}

	getPossibleRolesForEdit() {
		// HVend admin can assign any of the roles to already existing user
		if (this.props.user.privilege === ROLES.root) {
			const adminPageList = [
				ROLES.root,
				ROLES.companyAdmin,
				ROLES.technician,
				ROLES.regionalCompanyAdmin,
				ROLES.hvendTechnican
			];
			return adminPageList;
		}
		// Company admin on it's user page can assign any sub user role
		if (this.props.user.privilege === ROLES.companyAdmin) {
			const adminPageList = [
				ROLES.companyAdmin,
				ROLES.technician,
				ROLES.regionalCompanyAdmin
			];
			return adminPageList;
		}

		// Company admin on it's user page can assign any sub user role
		if (this.props.user.privilege === ROLES.regionalCompanyAdmin) {
			const adminPageList = [ROLES.technician];
			return adminPageList;
		}

		return [];
	}

	getHiddenColumns() {
		// We only show the company column in Admin mode
		if (this.props.user.privilege === ROLES.root) {
			return [];
		} else {
			return ['company'];
		}
	}

	render() {
		return (
			<div>
				<Switch>
					<Route
						path="/users"
						name="Users"
						render={props => (
							<UserList
								{...props}
								title={this.props.t('sidebarmenu.users')}
								renderDocked={true}
								possibleRolesForCreation={this.getPossibleRolesForCreation()}
								possibleRolesEdit={this.getPossibleRolesForEdit()}
								hiddenColumns={this.getHiddenColumns()}
								showInviteEmailSettings={
									this.props.user.privilege === ROLES.root
								}
								cmId={
									this.props.user.privilege === ROLES.companyAdmin ||
									this.props.user.privilege === ROLES.regionalCompanyAdmin
										? this.props.user.cmID
										: undefined
								}
								showMobileUsers={this.props.user.privilege == ROLES.root}
							/>
						)}
					/>

					<Redirect from="/" to="/users" />
				</Switch>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user
});

export default connect(mapStateToProps)(withTranslation()(Users));
