import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import moment from 'moment';
import classes from './PromotionUserSelectorDialog.module.css';

class PromotionUserSelectorDialog extends Component {
	state = {
		validated: false,
		startDate: new Date().setDate(-30),
		endDate: new Date()
	};

	componentDidMount() {
		this.setState({
			selectedID:
				this.props.machines && this.props.machines.length > 0
					? this.props.machines[0].value
					: undefined
		});
	}

	getMachineOptionList() {
		if (this.props.machines) {
			return this.props.machines.map((e, index) => (
				<option key={e.label + index} label={e.label} value={e.value}>
					{e.label}
				</option>
			));
		}

		return null;
	}

	inputChangeHandler = event => {
		let state = { ...this.state };
		state[event.target.name] = event.target.value;

		this.setState({ ...state });
	};

	render() {
		const { t } = this.props;

		return (
			<Form
				noValidate
				validated={this.state.validated}
				id={'SelectPromotionUser'}
			>
				<Row style={{ paddingLeft: '15px' }}>
					<Form.Label style={{ fontWeight: 'bold' }}>
						{t(
							'promotionManager.addPromotion.promotionUsers.wizard.selectedMachine'
						)}
					</Form.Label>
				</Row>
				<Row style={{ paddingBottom: '10px' }}>
					<Col xs={12} sm={12} md={12} lg={12}>
						<Form.Control
							style={
								this.props.incomeRow
									? {
											border: '1px solid var(--message)',
											borderRadius: '3px',
											color: ' var(--message)'
									  }
									: {}
							}
							name="selectedMachienOrGroupIDS"
							type="select"
							as="select"
							value={this.props.selectedID}
							onChange={e => {
								this.setState({
									selectedID: e.target.value
								});
								//	this.props.onSelectionChanged(e.target.value);
							}}
						>
							{this.getMachineOptionList()}
						</Form.Control>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={6}>
						<Form.Group controlId="startDate">
							<Row style={{ paddingLeft: '15px' }}>
								<Form.Label style={{ fontWeight: 'bold' }}>
									{t('promotionManager.addPromotion.startDate.title')}
								</Form.Label>
							</Row>
							<Form.Control
								required
								name="startDate"
								type="date"
								value={moment(this.state.startDate).format('YYYY-MM-DD') || ''}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} sm={12} md={12} lg={6}>
						<Form.Group controlId="endDate">
							<Row style={{ paddingLeft: '15px' }}>
								<Form.Label style={{ fontWeight: 'bold' }}>
									{t('promotionManager.addPromotion.endDate.title')}
								</Form.Label>
							</Row>
							<Form.Control
								name="endDate"
								type="date"
								value={moment(this.state.endDate).format('YYYY-MM-DD') || ''}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
				</Row>
				<hr></hr>
				<Button
					variant="success"
					className="DefaultButton"
					onClick={() =>
						this.props.onGenerate(
							this.state.selectedID,
							this.state.startDate,
							this.state.endDate
						)
					}
				>
					<i className="fa fa-floppy-o mr-2" />
					&nbsp;
					{t('promotionManager.addPromotion.savebutton')}
				</Button>
			</Form>
		);
	}
}

export default withTranslation()(PromotionUserSelectorDialog);
