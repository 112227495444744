import React, { Component } from 'react';
import { Card, Row, CardBody, Col, CardTitle } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import FilterBar from '../../../../views/FilterBar/FilterBar';
import HelperTooltip from '../../../../components/UI/HelperTooltip/HelperTooltip';
import ConfimationDialog from '../../../ConfirmationDialog/ConfirmationDialog';
import AttachedFilesTable from './AttachedFilesTable/AttachedFilesTable';
import attachmentsApi from '../../../../services/api/attachments';

// ------------- Attached Files table prototype ----------------

class PackageManager extends Component {
	state = {
		data: [],
		filteredFiles: [],
		toDelete: null
	};
	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		if (this.props.vmId) {
			attachmentsApi.getFiles(this.props.vmId).then(res =>
				this.setState(
					{ data: res.data },

					() => this.filterPackages()
				)
			);
		}
	};
	//Render Filterbar
	renderFilterBar = () => {
		return (
			<FilterBar
				key="filterbar"
				onFilterChanged={value => this.onFilterChange(value)}
			/>
		);
	};

	deleteFileHandling = id => {
		attachmentsApi.deleteFile(id).then(res => {
			const newData = this.state.data.filter(data => data.fileID != id);
			this.setState({ data: newData });
			this.filterPackages();
			this.hideConfirmationDialog();
		});
	};

	filterPackages() {
		let filteredFiles = [...this.state.data];
		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredFiles = this.state.data.filter(data => {
				let curdata = { ...data };
				let DataFieldArray = Object.values(curdata);
				return DataFieldArray.some(
					val =>
						String(val)
							.toUpperCase()
							.indexOf(filterValue) >= 0
				);
			});
		}

		this.setState({ filteredFiles: filteredFiles });
	}

	onFilterChange(filteValue) {
		this.setState({ currentFilterValue: filteValue }, () =>
			this.filterPackages()
		);
	}
	// ---------- Delete confirmation handling -------------

	hideConfirmationDialog = () => {
		this.setState({
			deleteConfirmationVisible: false
		});
	};

	showDeleteConfirmation = id => {
		let fileToDel = this.state.data.filter(e => e.fileID === id);

		this.setState({
			deleteConfirmationVisible: true,
			fileToDel: fileToDel
		});
	};

	renderDeleteConfirmationDialog() {
		const confirmationText = (
			<>
				<Col>{this.props.t('attachedFiles.delConf')}</Col>
				<Col>
					<Row>
						<Col>{this.props.t('attachedFiles.fileName')}</Col>
						<Col style={{ fontWeight: 'bold' }}>
							{this.state.fileToDel && this.state.fileToDel.length > 0
								? this.state.fileToDel[0].filename
								: ''}
						</Col>
					</Row>
				</Col>
			</>
		);
		return (
			<ConfimationDialog
				title="Confirmation"
				confirmationText={confirmationText}
				okText={this.props.t('device.details.invisibleTouch.ok')}
				cancelText={this.props.t('device.details.invisibleTouch.cancel')}
				isOpen={this.state.deleteConfirmationVisible}
				onToggle={() => {
					this.hideConfirmationDialog();
				}}
				onCancel={() => {
					this.hideConfirmationDialog();
				}}
				onOk={() => {
					this.deleteFileHandling(this.state.fileToDel[0].fileID);
					this.hideConfirmationDialog();
				}}
			/>
		);
	}

	//Render Table
	renderTable() {
		return (
			<AttachedFilesTable
				data={this.state.filteredFiles}
				onDel={id => this.showDeleteConfirmation(id)}
				renderFilterBar={() => this.renderFilterBar()}
				onFilterChange={() => this.onFilterChange}
			/>
		);
	}

	//Render Title
	renderTitle() {
		return (
			<Row style={{ alignItems: 'center' }}>
				<CardTitle
					className="text-muted"
					style={{ cursor: 'pointer', marginLeft: '20px', marginRight: '10px' }}
				>
					<i className={'fa fa-file'} style={{ marginRight: '10px' }} />
					{this.props.t('attachedFiles.title')}
				</CardTitle>
			</Row>
		);
	}
	render() {
		const { t } = this.props;

		return (
			<div className="animated fadeIn">
				{this.renderDeleteConfirmationDialog()}
				<Card className={this.props.className} style={this.props.style}>
					<CardBody>
						{this.renderTitle()} {this.renderTable()}
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default withTranslation()(PackageManager);
