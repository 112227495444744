import React from 'react'
import { StyledComponent } from 'styled-components'
import { Col, Tooltip } from 'reactstrap'
import {
  ButtonContainer,
  MachineLabel,
  MachineButton,
} from '../../../../components/Machines/MachineComponents'

type Props = {
  buttonContent: any,
  labelContent?: any,
  buttonOnClick?: () => void,
  ButtonComponent?: StyledComponent<'button', any, {}>,
  buttonAlign?: 'left' | 'right',
  transparentBackground?: boolean,
  hasTooltip?: boolean,
  display: 'none' | 'block'
}

export default class AzkoyenButtonWithLabelHorizontal extends React.Component<Props> {
  state = { tooltipOpen: false }

  toggleTooltip = () => this.setState({ tooltipOpen: !this.state.tooltipOpen })
  render() {
    const {
      buttonContent,
      buttonID,
      slavePattern,
      labelContent = null,
      onButtonClick = (buttonId: string, slavePattern: ?string) => () => {},
      ButtonComponent = MachineButton,
      buttonAlign = 'left',
      hideButton = false,
      transparentBackground = false,
      hasTooltip = true,
      ...rest
    } = this.props

    const Label = (
      <Col
        sm="3"
        md="3"
        lg="3"
        xl="3"
        style={{ padding: '2px' }}
        className="d-none d-sm-block"
      >
        {labelContent && (
          <MachineLabel style={{ height: '100%' }}>{labelContent}</MachineLabel>
        )}
      </Col>
    )

    const Button = (
      <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ padding: '0px', margin: '0px' }}>
        {!hideButton && (
          <ButtonComponent
            id={`button-tooltip-${buttonID}-${slavePattern}`}
            onClick={onButtonClick(buttonID, slavePattern)}
            style={{ height: '100%', display: this.props.display }}
          >
            {buttonContent}{' '}
          </ButtonComponent>
        )}
      </Col>
    )

    return (
      <ButtonContainer
        className="d-flex justify-content-center"
        style={{ height: '100%', padding: '0px', margin: '0px' }}
        transparentBackground={transparentBackground}
        {...rest}
      >
        {buttonAlign === 'left' ? (
          <>
            {Button} {Label}
          </>
        ) : (
          <>
            {Label} {Button}
          </>
        )}
        {labelContent && hasTooltip && !hideButton && (
          <Tooltip
            placement="right"
            target={`button-tooltip-${buttonID}-${slavePattern}`}
            isOpen={this.state.tooltipOpen}
            toggle={this.toggleTooltip}
          >
            {labelContent}
          </Tooltip>
        )}
      </ButtonContainer>
    )
  }
}
