//@flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { RedMachineButton } from '../../../../components/Machines/MachineComponents'
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent'
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard'

type Props = {
  vmID: string,
  history: Object,
  products?: Array<Object>,
}
type States = {}

class Venezia extends React.Component<Props, States> {
  keyboardConfig = [
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <i className="fa fa-long-arrow-up mr-1" />1
          </>
        ),
        buttonID: '1',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <i className="fa fa-long-arrow-down mr-1" />7
          </>
        ),
        buttonID: '7',
      },
    ],
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <span style={{ fontSize: 'medium' }}>EXIT</span> <br />2
          </>
        ),
        buttonID: '2',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <span style={{ fontSize: 'medium' }}>ENTER</span> <br />8
          </>
        ),
        buttonID: '8',
      },
    ],

    [
      {
        buttonContent: <>3</>,
        buttonID: '3',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <span style={{ fontSize: 'medium' }}>SELECT</span> <br />9
          </>
        ),
        buttonID: '9',
      },
    ],
    [
      {
        buttonContent: <>4</>,
        buttonID: '4',
      },
      {
        buttonContent: <>10</>,
        buttonID: '10',
      },
    ],
    [
      {
        buttonContent: <>5</>,
        buttonID: '5',
      },
      {
        buttonContent: <>11</>,
        buttonID: '11',
      },
    ],
    [
      {
        buttonContent: <>6</>,
        buttonID: '6',
      },
      {
        buttonContent: <>12</>,
        buttonID: '12',
      },
    ],
  ]

  render() {
    return (
      <DirectChooseMachineBaseComponent
        machineTitle="Venezia"
        lineNumber={1}
        lineLength={16}
        keyboard={
          <DirectChooseKeyboard
            programmingButtonVisible={false}
            keyboardConfig={this.keyboardConfig}
            {...this.props}
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(Venezia)
