//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';

import { RedMachineButton } from '../../../../components/Machines/MachineComponents';
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard';
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent';

type Props = {
	vmID: string,
	history: Object,
	products?: Array<Object>,
	isSlave: ?boolean
};
type States = {};

class Bvm972 extends React.Component<Props, States> {
	keyboardConfig = [
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						1<br />
						INCREASE
					</>
				),
				buttonID: '1'
			},
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						2<br />
						ESCAPE
					</>
				),
				buttonID: '2'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						3<br />
						DECREASE
					</>
				),
				buttonID: '3'
			},
			{
				buttonContent: 4,
				buttonID: '4'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						5<br />
						SHIFT
					</>
				),
				buttonID: '5'
			},
			{
				buttonContent: 6,
				buttonID: '6'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						7<br />
						ENTER
					</>
				),
				buttonID: '7'
			},
			{
				buttonContent: 8,
				buttonID: '8'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						9<br />
						PROD. NAME
					</>
				),
				buttonID: '9'
			},
			{
				buttonContent: 10,
				buttonID: '10'
			}
		],
		[
			{
				buttonContent: 11,
				buttonID: '11'
			},
			{
				buttonContent: 12,
				buttonID: '12'
			}
		],
		[
			{
				buttonContent: 13,
				buttonID: '13'
			},
			{
				buttonID: '14',
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						14
						<br />
						POWDER TEST
					</>
				)
			}
		],
		[
			{
				buttonContent: (
					<>
						15
						<br />
						WATER TEST
					</>
				),
				ButtonComponent: RedMachineButton,
				buttonID: '15'
			},
			{
				buttonContent: (
					<>
						16
						<br />
						FULL TEST
					</>
				),
				ButtonComponent: RedMachineButton,
				buttonID: '16'
			}
		],
		[
			{
				buttonContent: 17,
				buttonID: '17'
			},
			{
				buttonContent: 18,
				buttonID: '18'
			}
		],
		[
			{
				buttonContent: 19,
				buttonID: '19'
			},
			{
				buttonContent: 20,
				buttonID: '20'
			}
		],
		[
			{
				buttonContent: 21,
				buttonID: '21'
			},
			{
				buttonContent: 22,
				buttonID: '22'
			}
		],
		[
			{
				buttonContent: 23,
				buttonID: '23'
			},
			{
				buttonContent: 24,
				buttonID: '24'
			}
		],
		[
			{
				buttonContent: 25,
				buttonID: '25'
			},
			{
				buttonContent: 26,
				buttonID: '26'
			}
		],
		[
			{
				buttonContent: 27,
				buttonID: '27'
			},
			{
				buttonContent: 28,
				buttonID: '28'
			}
		],
		[
			{
				buttonContent: 29,
				buttonID: '29'
			},
			{
				buttonContent: 30,
				buttonID: '30'
			}
		]
	];

	render() {
		const { products = [] } = this.props;

		return (
			<DirectChooseMachineBaseComponent
				machineTitle="Bvm972"
				lineNumber={2}
				lineLength={16}
				ledNumbers={0}
				keyboard={
					<DirectChooseKeyboard
						keyboardConfig={this.keyboardConfig}
						products={products}
						programmingButtonVisible={false}
					/>
				}
				{...this.props}
			/>
		);
	}
}

export default withRouter(Bvm972);
