import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
// import * as name from 'chartjs-plugin-colorschemes';
import { Line } from 'react-chartjs-2';

class StateLineChart extends Component {
	prepareData() {
		return {
			datasets: this.props.series.map(series => {
				const xySet = series.xValues.map((value, index) => {
					return { x: value, y: series.yValues[index] };
				});
				return {
					label: series.name,
					data: xySet,
					fill: false,
					lineTension: 0,
					steppedLine: true
				};
			})
		};
	}

	render() {
		const data = this.prepareData();
		const self = this;

		const options = {
			plugins: {
				colorschemes: {
					scheme: 'tableau.HueCircle19'
				},
				datalabels: {
					display: false
				}
			},
			scales: {
				xAxes: [
					{
						type: 'time',
						time: {
							unit: 'minutes',
							displayFormats: {
								second: 'MMM D h:mm a'
							}
						},
						ticks: {
							stepSize: 1
						}
					}
				],
				yAxes: [
					{
						ticks: {
							callback: function (value, index, values) {
								switch (value) {
									case 0:
										return self.props.t('salesList.stateLevels.error');
									case 1:
										return self.props.t('salesList.stateLevels.warning');
									case 2:
										return self.props.t('salesList.stateLevels.operational');
									case 3:
										return self.props.t('salesList.stateLevels.programming');
									default:
										return '';
								}
							},
							min: 0,
							max: 3
						}
					}
				]
			},

			tooltips: {
				callbacks: {
					label: function (tooltipItem) {
						return tooltipItem.yLabel;
					}
				}
			}
		};
		return <Line data={data} options={{ ...options }} />;
	}
}

export default withTranslation()(StateLineChart);
