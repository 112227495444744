import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Card, Badge, CardTitle, Row, CardBody } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Toggle from 'react-bootstrap-toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import customerAPI from '../../../services/api/customers';
import machineAPI from '../../../services/api/machines';
import { getDate } from '../../../services/util/dateUtil';

import { getLanguage } from '../../../localization';
import 'chartjs-plugin-zoom';
import classes from './LogsCard.module.css';

import LogsTable from '../LogsTable/LogsTable';
// import LogsFilterbar from '../LogsFilterbar/LogsFilterbar';
import proxy from '../../PromotionManager/proxy';

import moment from 'moment';
import dayjs from 'dayjs';
import realtime from '../../../services/api/realtime';

const eventTypes = {
	UNCATEGORIZED: 0,
	RUNTIME_ERROR: 1,
	MACHINE_HAS_STARTED: 2,
	MACHINE_MALFUNCTION: 3,
	PRODUCTS_DiD_NOT_VEND: 4,
	PRODUCT_RUN_OUT_OF_STOCK: 5,
	STOCK_WARNING: 6,
	PRODUCTS_DISABLED: 7,
	MACHINE_IS_HEATING: 8,
	OUT_OF_CUPS: 9,
	OUT_OF_SMALL_CUPS: 10,
	OUT_OF_ESPRESSO: 11,
	OUT_OF_CHANGE: 12,
	MAINTENANCE: 13,
	PRODUCT_PURCHASED: 14,
	MACHINE_STATE_CHANGE: 15, // This event type is not show on the event listing views,
	// because It is not provided by the event system.
	// Instead it is the result of the keep alive/hearbeat message.
	INVISIBLE_TOUCH_MOBILE_ERROR: 16,
	MACHINE_WENT_OFFLINE: 17,
	NO_WATER_ERROR: 19,
	TURBINE_ERROR: 20,
	HARDWARE_TIME_ERROR: 21
};
class LogsCard extends Component {
	state = {
		loading: true,
		showDialog: false,
		eventVisibilities: [],
		eventList: [],
		eventTypeSeriesSettings: [],
		proxyData: proxy.filteredData,
		filteredDays: this.props.days
	};
	unvisibleEvents = [15, 18];
	async loadEventTypes() {
		const eventTypeList = await machineAPI.getEventTypes();
		this.setState({ eventTypeList });
		let language = getLanguage();
		if (!language) {
			language = 'en';
		}
		const eventTypeSeriesSettings = eventTypeList.data.rows.map(row => {
			return {
				typeId: row.id,
				label: row['label_' + language],
				text: row['text_' + language],
				backgroundColor: 'rgba(0,0,216,0.1)',
				borderColor: row.chart_border_color ? row.chart_border_color : 'blue',
				pointHoverBackgroundColor: '#fff',
				borderWidth: 2,
				pointRadius: row.chart_radius ? row.chart_radius : 10,
				pointStyle: row.chart_point_style
					? row.chart_point_style
					: 'rectRounded'
			};
		});
		this.setState({ eventTypeSeriesSettings, language });
	}

	listeners = [];
	handleNewEvent = () => {
		setTimeout(() => {
			let startDate = getDate(-this.props.days);
			let endDate = getDate();
			this.setState(
				{ startDate: startDate, endDate: endDate },
				async () => await this.loadData()
			);
		}, 3000);
	};

	async componentDidMount() {
		let startDate = getDate(-this.props.days);
		let endDate = getDate();
		this.setState(
			{ startDate: startDate, endDate: endDate },
			async () => await this.loadData()
		);
		this.loadEventTypes();

		if (this.props.machine && this.props.machine.hardwareID) {
			this.listeners.push(
				await realtime.subscribeStatusReportForVM(
					this.props.machine.hardwareID,
					this.handleNewEvent
				)
			);
		}
	}

	componentWillUnmount = () => {
		this.listeners.forEach(listener => listener.unsubscribe());
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (prevState.language && prevState.language !== this.props.i18n.language) {
			this.loadEventTypes();
		}
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	async loadData() {
		let days = moment(this.state.endDate).diff(
			moment(this.state.startDate),
			'days'
		);

		const machineId = this.props.machine ? this.props.machine.vmID : undefined;
		this.setState({ loading: true });
		let vmId = [];
		if (!this.props.machine) {
			vmId = await machineAPI.getVisibleVendingMachines().then(res =>
				res.data.response
					.filter(e => e.telemetryServiceEnabled === 'Y')
					.map(e => {
						return e.vmID;
					})
			);
		} else {
			vmId = [machineId];
		}

		let eventList = [];
		customerAPI
			.getEvents(
				dayjs(this.state.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
				days,
				machineId
			)
			.then(resp => {
				eventList = resp.data.response.filter(event => {
					return vmId.includes(event.machineId);
				});
				this.setState({ eventList: eventList }, () =>
					this.provideFiltredDataset()
				);
				this.setState({ loading: false });
			});
	}

	renderFilterButton = () => {
		return (
			<Button
				className={classes.FilterButton}
				onClick={() => this.onShowDialog()}
			>
				<i className="fa fa-search mr-2" />
				{this.props.t('eventList.filterByEvent')}
			</Button>
		);
	};

	renderLoadButton = () => {
		return (
			<Button className={classes.FilterButton} onClick={() => this.loadData()}>
				<i className="fa fa-check mr-2" />
				&nbsp;
				{this.props.t('device.details.sales.timeRange')}
			</Button>
		);
	};

	onShowDialog() {
		this.setState({ showDialog: true });
	}

	onHideDialog() {
		this.setState({ showDialog: false });
	}

	isEventTypeVisible(eventTypeId) {
		let result = true;
		let item = this.state.eventVisibilities.find(
			curType => curType.typeId === eventTypeId
		);
		if (item) {
			result = item.visible;
		}
		return result;
	}

	onToggleEventTypeVisibility(eventType) {
		let eventVisibilities = [...this.state.eventVisibilities];
		let item = eventVisibilities.find(
			curType => curType.typeId === eventType.typeId
		);
		if (!item) {
			item = { typeId: eventType.typeId, visible: false };
			eventVisibilities.push(item);
		} else {
			item.visible = !item.visible;
		}

		this.setState({ eventVisibilities: eventVisibilities }, () => {
			this.provideFiltredDataset();
		});
	}

	onToggleAllVisibilities() {
		let newVisibilitySettings = [];
		if (
			this.state.eventVisibilities.filter(item => !item.visible).length === 0
		) {
			newVisibilitySettings = this.state.eventTypeSeriesSettings.map(
				eventType => {
					return { typeId: eventType.typeId, visible: false };
				}
			);
		}
		this.setState({ eventVisibilities: newVisibilitySettings }, () => {
			this.provideFiltredDataset();
		});
	}

	getStatusBadge = value => {
		switch (value) {
			case eventTypes.UNCATEGORIZED:
				return (
					<Badge className={classes.WarningBadge}>
						<i className="fa fa-question-circle"></i>
					</Badge>
				);
			case eventTypes.RUNTIME_ERROR:
				return (
					<Badge className={classes.ErrorBadge}>
						<i className="fa fa-exclamation-circle"></i>
					</Badge>
				);
			case eventTypes.MACHINE_HAS_STARTED:
				return (
					<Badge className={classes.SuccesBadge}>
						<i className="fa fa-check-circle"></i>
					</Badge>
				);
			case eventTypes.MACHINE_MALFUNCTION:
				return (
					<Badge className={classes.ErrorBadge}>
						<i className="fa fa-times-circle"></i>
					</Badge>
				);
			case eventTypes.PRODUCTS_DiD_NOT_VEND:
				return (
					<Badge className={classes.ErrorBadge}>
						<i className="fa fa-shopping-cart"></i>
						{/* <i className="fa fa-exclamation-triangle"></i> */}
					</Badge>
				);
			case eventTypes.PRODUCT_RUN_OUT_OF_STOCK:
				return (
					<Badge className={classes.WarningBadge}>
						<i className="fa fa-ban"></i>
					</Badge>
				);
			case eventTypes.STOCK_WARNING:
				return (
					<Badge className={classes.WarningBadge}>
						<i className="fa fa-percent"></i>
					</Badge>
				);
			case eventTypes.PRODUCTS_DISABLED:
				return (
					<Badge className={classes.InfoBadge}>
						<i className="fa fa-minus-circle"></i>
					</Badge>
				);
			case eventTypes.MACHINE_IS_HEATING:
				return (
					<Badge className={classes.InfoBadge}>
						<i className="fa fa-fire"></i>
					</Badge>
				);
			case eventTypes.OUT_OF_CUPS:
				return (
					<Badge className={classes.WarningBadge}>
						<i className="fa fa-bitbucket"></i>
					</Badge>
				);
			case eventTypes.OUT_OF_SMALL_CUPS:
				return (
					<Badge className={classes.WarningBadge}>
						<i className="fa fa-bitbucket"></i>
					</Badge>
				);
			case eventTypes.OUT_OF_ESPRESSO:
				return (
					<Badge className={classes.WarningBadge}>
						<i className="fa fa-coffee"></i>
					</Badge>
				);
			case eventTypes.OUT_OF_CHANGE:
				return (
					<Badge className={classes.WarningBadge}>
						<i className="fa fa-database"></i>
					</Badge>
				);
			case eventTypes.MAINTENANCE:
				return (
					<Badge className={classes.WarningBadge}>
						<i className="fa fa-wrench"></i>
					</Badge>
				);
			// -------------- to do when backend uptodate
			// case 15:
			// 	return (
			// 		<Badge className={classes.InfoBadge}>
			// 			<i className="fa fa-random"></i>
			// 		</Badge>
			// 	);
			case eventTypes.INVISIBLE_TOUCH_MOBILE_ERROR:
				return (
					<Badge className={classes.ErrorBadge}>
						<i className="fa fa-id-badge"></i>
					</Badge>
				);
			case eventTypes.MACHINE_WENT_OFFLINE:
				return (
					<Badge className={classes.WarningBadge}>
						<i className="fa fa-wrench"></i>
					</Badge>
				);
			default:
				return (
					<Badge className={classes.WarningBadge}>
						<i className="fa fa-question-circle"></i>
					</Badge>
				);
		}
	};

	renderDialog() {
		const eventSettingsList = this.state.eventTypeSeriesSettings.map(
			(eventType, index) => {
				return (
					<div key={index}>
						<div>
							{this.getStatusBadge(eventType.typeId)}
							<strong style={{ marginLeft: '30px' }}>{eventType.label}</strong>
							<Toggle
								onClick={() => this.onToggleEventTypeVisibility(eventType)}
								on="show"
								off="hide"
								active={this.isEventTypeVisible(eventType.typeId)}
								onstyle="info"
								offstyle="warning"
								size="sm"
								style={{
									width: '75px',
									height: '30px',
									borderRadius: '25px',
									float: 'right'
								}}
							/>
						</div>
						<hr />
					</div>
				);
			}
		);

		return (
			<Modal
				show={this.state.showDialog}
				onHide={() => this.onHideDialog()}
				scrollable="true"
			>
				<Modal.Header>
					<Modal.Title>{this.props.t('eventList.visibleEvents')}</Modal.Title>
				</Modal.Header>
				<Modal.Body max-height="260px">{eventSettingsList}</Modal.Body>
				<Modal.Footer>
					<Button
						variant={
							this.state.eventVisibilities.filter(item => !item.visible)
								.length === 0
								? 'warning'
								: 'info'
						}
						onClick={() => this.onToggleAllVisibilities()}
					>
						{this.state.eventVisibilities.filter(item => !item.visible)
							.length === 0
							? this.props.t('eventList.hideAll')
							: this.props.t('eventList.showAll')}
					</Button>

					<Button variant="success" onClick={() => this.onHideDialog()}>
						{this.props.t('eventList.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	provideFiltredDataset() {
		const filteredData = this.state.eventList
			.filter(
				row => !this.unvisibleEvents.find(event => event == row.eventType)
			)
			.filter(row => this.isEventTypeVisible(row.eventType))
			.map((row, index) => {
				return { ...row, index };
			});
		this.setState({ filteredData: filteredData });
	}

	onFilterChange = value => {
		let newDays = value.daysInPeriod;
		if (newDays) {
			this.loadStartingDate(newDays);
			this.setState({
				filteredDays: newDays
			});
		}
	};
	renderTitle = () => {
		return (
			<Row style={{ alignItems: 'center' }}>
				<CardTitle
					className="text-muted"
					style={{ cursor: 'pointer', marginLeft: '20px', marginRight: '10px' }}
				>
					<i className={'fa fa-bell'} style={{ marginRight: '10px' }} />
					{this.props.t('eventList.title')}
				</CardTitle>
			</Row>
		);
	};

	render() {
		const { t } = this.props;
		let isNextButtonEnabled = false;
		if (this.state && this.state.startingDate) {
			let nextDate = new Date(this.state.startingDate);
			nextDate.setDate(nextDate.getDate() + parseInt(this.state.filteredDays));
			isNextButtonEnabled = nextDate < new Date();
		}

		let grid = null;
		if (this.props.showGrid) {
			grid = (
				<LogsTable
					history={this.props.history}
					data={this.state.filteredData}
					eventTypeSeriesSettings={this.state.eventTypeSeriesSettings}
					getStatusBadge={this.getStatusBadge}
					hideLink={this.props.hideLink}
					startDate={this.state.startDate}
					endDate={this.state.endDate}
					filterButton={this.renderFilterButton()}
					onDateChange={this.inputChangeHandler}
					loading={this.state.loading}
					loadButton={this.renderLoadButton()}
				/>
			);
		}

		return (
			<div
				className="animated fadeIn"
				style={{
					paddingLeft: '20px',
					paddingRight: '20px',
					paddingTop: '10px'
				}}
			>
				{this.renderDialog()}

				<Card className={this.props.className} style={this.props.style} body>
					<CardBody>
						{this.renderTitle()}
						{grid}
					</CardBody>
				</Card>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { language, user } = state;
	return { language, user };
}

export default connect(mapStateToProps)(
	withRouter(withTranslation()(LogsCard))
);
