import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import classes from './PromotionTable.module.css';
import { ButtonGroup, Badge, Row, Col } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import DescriptionTooltip from '../../GroupManager/DeviceGroupManagerTab/DeviceGroupTable/DescriptionTooltip';
import { withTranslation } from 'react-i18next';
import PromotionDetailsTable from './PromotionDetailsTable';
import moment from 'moment';

const PromotionTable = props => {
	const { t } = props;
	const isColumnsVisibile = columName => {
		return !props.hiddenColumns || props.hiddenColumns.indexOf(columName) < 0;
	};

	const statusFormater = (cell, row) => {
		switch (cell) {
			case 'A':
				return (
					<p
						style={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							color: '#4682B4',
							fontWeight: 'bold'
						}}
					>
						{t('promotionManager.promotionTable.stateBadge.active')}
					</p>
				);
			case 'P':
				return (
					<p
						style={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							color: '#FFA500',
							fontWeight: 'bold'
						}}
					>
						{t('promotionManager.promotionTable.stateBadge.pending')}
					</p>
				);
			case 'E':
				return (
					<p
						style={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							color: '#db021d',
							fontWeight: 'bold'
						}}
					>
						{t('promotionManager.promotionTable.stateBadge.expired')}
					</p>
				);
			case 'I':
				return (
					<p
						style={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							color: '#800000',
							fontWeight: 'bold'
						}}
					>
						{t('promotionManager.promotionTable.stateBadge.inactive')}
					</p>
				);
		}
	};

	const defaultEditFormater = (cell, row) => {
		return (
			<ButtonGroup vertical>
				<Button
					onClick={e => props.editProfil(row)}
					variant="success"
					className={classes.EditButton}
				>
					<i className="fa fa-pencil mr-2" />
					&nbsp;
					{t('promotionManager.promotionTable.edit.edit')}
				</Button>
				<Button
					onClick={e => props.onDeletePromotion(row)}
					className={classes.DeleteButton}
				>
					<i className="fa fa-trash mr-2" />
					&nbsp;
					{t('promotionManager.promotionTable.edit.delete')}
				</Button>
			</ButtonGroup>
		);
	};
	const editFormatter = (cell, row) => {
		const endDateNull = !row.endDate;
		const inactive = row.inactive;
		if (row.state === 'A' && endDateNull) {
			return (
				<ButtonGroup vertical>
					{defaultEditFormater(cell, row)}
					<Button
						onClick={e => props.statusSwitchHandler(row)}
						className={classes.DeactivateButton}
					>
						<i className="fa fa-toggle-on mr-2" />
						&nbsp;
						{t('promotionManager.promotionTable.edit.deactive')}
					</Button>
				</ButtonGroup>
			);
		} else if (row.state === 'I') {
			return (
				<ButtonGroup vertical>
					{defaultEditFormater(cell, row)}
					<Button
						onClick={e => props.statusSwitchHandler(row)}
						className={classes.ActivateButton}
					>
						<i className="fa fa-toggle-off mr-2" />
						&nbsp;
						{t('promotionManager.promotionTable.edit.active')}
					</Button>
				</ButtonGroup>
			);
		} else {
			return defaultEditFormater(cell, row);
		}
	};

	const userFormatter = (cell, row) => {
		return <i className="fa fa-bullhorn" id="userIcon" />;
	};
	const promotionTypeBadge = value => {
		return (
			<>
				<strong>{value}</strong>
				<h3>
					<Badge className={classes.ActivateButton}>
						<i className="fa fa-list-alt" />
					</Badge>
				</h3>
			</>
		);
	};
	const promotionTypeFormater = cell => {
		switch (cell) {
			case 0:
				return promotionTypeBadge(t('promotionManager.promotionTable.general'));
			case 1:
				return promotionTypeBadge(
					t('promotionManager.promotionTable.firstBuy')
				);
			case 2:
				return promotionTypeBadge(
					t('promotionManager.promotionTable.sequental')
				);
			case 3:
				return promotionTypeBadge(
					t('promotionManager.promotionTable.creditBonus')
				);
			case 4:
				return promotionTypeBadge(t('promotionManager.promotionTable.product'));
			case 5:
				return promotionTypeBadge(
					t('promotionManager.promotionTable.onlineCreditBonus')
				);
			case 6:
				return promotionTypeBadge(
					t('promotionManager.promotionTable.monthlyBonusCredit')
				);
		}
	};

	const dateFormater = cell => {
		if (cell) {
			return moment(cell).format('YYYY-MM-DD');
		}
	};

	const periodFormater = (cell, row) => {
		if (cell === row.dailyPeriodEnd) {
			return (
				<strong>
					{t('promotionManager.promotionTable.period.continuous')}
				</strong>
			);
		} else {
			return cell + ' - ' + row.dailyPeriodEnd;
		}
	};

	const isExpandableRow = () => {
		return true;
	};

	const expandComponent = row => {
		let data = [];
		data.push(row);
		return (
			<PromotionDetailsTable
				key={row.id}
				data={data}
				machines={props.machines}
				machineGroups={props.machineGroups}
			/>
		);
	};

	const aDaySorter = cell => {
		let days = cell
			.split(',')
			.sort()
			.map(e => {
				let day;
				switch (e) {
					case '1':
						return (day = t(
							'promotionManager.promotionTable.activeDays.days.mon'
						));

					case '2':
						return (day = t(
							'promotionManager.promotionTable.activeDays.days.tue'
						));

					case '3':
						return (day = t(
							'promotionManager.promotionTable.activeDays.days.wed'
						));

					case '4':
						return (day = t(
							'promotionManager.promotionTable.activeDays.days.thu'
						));

					case '5':
						return (day = t(
							'promotionManager.promotionTable.activeDays.days.fri'
						));

					case '6':
						return (day = t(
							'promotionManager.promotionTable.activeDays.days.sut'
						));

					case '7':
						return (day = t(
							'promotionManager.promotionTable.activeDays.days.sun'
						));
				}
			});
		return days;
	};

	const activeDaysFormater = cell => {
		let adf = null;
		if (cell) {
			if (cell.split(',').length === 7) {
				adf = (
					<strong>
						{t('promotionManager.promotionTable.activeDays.everyDay')}
					</strong>
				);
			} else {
				let days = aDaySorter(cell);
				let badge = days.map(e => {
					return (
						<Row>
							<Col style={{ display: 'flex', justifyContent: 'center' }}>
								<strong>{e}</strong>
							</Col>
						</Row>
					);
				});
				const btn = (
					<>
						<i className="fa fa-angle-left" />
						&nbsp;
						{t('promotionManager.promotionTable.details')}
					</>
				);
				adf = (
					<DescriptionTooltip
						title={t('promotionManager.promotionTable.activeDays.title')}
						content={badge}
						btnTitle={btn}
						placement="left"
						className={classes.DeactivateButton}
					/>
				);
			}
		} else {
			adf = (
				<strong>
					{t('promotionManager.promotionTable.activeDays.everyDay')}
				</strong>
			);
		}

		return adf;
	};
	//Table options

	const tableOptions = {
		sortIndicator: true,
		hideSizePerPage: false,
		paginationSize: 3,
		sizePerPageList: [10],
		sizePerPage: props.recordPerPage || 10,
		withFirstAndLast: true,
		alwaysShowAllBtns: true,
		pageStartIndex: 1,
		paginationPosition: 'bottom',
		clearSearch: true,
		hidePageListOnlyOnePage: true,
		sortName: 'id',
		sortOrder: 'asc',
		searchPanel: props.renderFilterBar,
		onSearchChange: props.onFilterChange,
		expandRowBgColor: '#fff',
		expandBy: 'column'
	};

	let table = <Spinner />;
	if (props.data) {
		table = (
			<>
				{!props.hideToolbar ? props.renderFilterBar() : null}
				<BootstrapTable
					key="bootstrapTable"
					data={props.data}
					version="4"
					striped
					hover
					pagination={!props.hidePager}
					insertRow={false}
					options={tableOptions}
					multiColumnSearch
					condensed
					bordered={false}
					dataAlign={'center'}
					size="sm"
					expandComponent={expandComponent}
					expandableRow={isExpandableRow}
				>
					{isColumnsVisibile('userIcon') ? (
						<TableHeaderColumn
							isKey
							width="30"
							dataAlign="center"
							dataField="id"
							dataFormat={userFormatter}
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px' }}
							expandable={false}
						/>
					) : null}
					{isColumnsVisibile('name') ? (
						<TableHeaderColumn
							dataField="name"
							width="130"
							dataAlign="center"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal',
								fontWeight: 'bold'
							}}
							expandable={false}
						>
							{t('promotionManager.promotionTable.promotionName')}
						</TableHeaderColumn>
					) : null}

					{isColumnsVisibile('insertedAt') ? (
						<TableHeaderColumn
							dataField="insertedAt"
							width="120"
							dataAlign="center"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
							expandable={false}
							dataFormat={dateFormater}
						>
							{t('promotionManager.promotionTable.creation')}
						</TableHeaderColumn>
					) : null}
					{isColumnsVisibile('updatedAt') ? (
						<TableHeaderColumn
							dataField="updatedAt"
							dataSort
							dataAlign="center"
							width="110"
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
							expandable={false}
							dataFormat={dateFormater}
						>
							{t('promotionManager.promotionTable.modified')}
						</TableHeaderColumn>
					) : null}
					{isColumnsVisibile('promotionTypeID') ? (
						<TableHeaderColumn
							dataField="promotionTypeID"
							dataSort
							dataAlign="center"
							width="120"
							dataFormat={promotionTypeFormater}
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal'
							}}
						>
							{t('promotionManager.promotionTable.type')}
						</TableHeaderColumn>
					) : null}
					{isColumnsVisibile('startDate') ? (
						<TableHeaderColumn
							dataField="startDate"
							dataSort
							dataAlign="center"
							width="120"
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal'
							}}
							expandable={false}
							dataFormat={dateFormater}
						>
							{t('promotionManager.promotionTable.startDate')}
						</TableHeaderColumn>
					) : null}
					{isColumnsVisibile('endDate') ? (
						<TableHeaderColumn
							dataField="endDate"
							dataSort
							dataAlign="center"
							width="120"
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
							expandable={false}
							dataFormat={dateFormater}
						>
							{t('promotionManager.promotionTable.endDate')}
						</TableHeaderColumn>
					) : null}

					{isColumnsVisibile('state') ? (
						<TableHeaderColumn
							dataField="state"
							dataFormat={statusFormater}
							dataSort
							dataAlign="center"
							width="120"
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							expandable={false}
						>
							{t('promotionManager.promotionTable.status')}
						</TableHeaderColumn>
					) : null}
					{isColumnsVisibile('dailyPeriodStart') ? (
						<TableHeaderColumn
							dataField="dailyPeriodStart"
							dataSort
							dataAlign="center"
							width="110"
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
							expandable={false}
							dataFormat={periodFormater}
						>
							{t('promotionManager.promotionTable.period.title')}
						</TableHeaderColumn>
					) : null}
					{isColumnsVisibile('activeDays') ? (
						<TableHeaderColumn
							dataField="activeDays"
							dataSort
							dataFormat={activeDaysFormater}
							dataAlign="center"
							width="110"
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
							expandable={false}
						>
							{t('promotionManager.promotionTable.activeDays.title')}
						</TableHeaderColumn>
					) : null}
					{isColumnsVisibile('action') ? (
						<TableHeaderColumn
							dataAlign="center"
							width="100"
							dataField="userID"
							dataFormat={editFormatter}
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							expandable={false}
						>
							{t('promotionManager.promotionTable.edit.title')}
						</TableHeaderColumn>
					) : null}
				</BootstrapTable>
			</>
		);
	}

	return table;
};

export default withTranslation()(PromotionTable);
