import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import Modal from 'react-bootstrap/Modal';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

import classes from './UserList.module.css';

//BootstrapTable
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import '../DeviceGroups/AddMachineGroupForm/node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import usersApi from '../../services/api/users';
import userUtil from '../../services/util/userUtil';
import notificationAPI from '../../services/api/notifications.js';
import logger from '../../services/logger';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import getRoleTextId from '../../i18n/userRole';
import { dateFromMYSQLTimeStamp } from '../../services/util/dateUtil';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';

import InviteUserDialog from './InviteUserDialog/InviteUserDialog';
import EditUserDialog from '../../views/UserList/EditUserDialog/EditUserDialog';
import FilterBar from '../../views/FilterBar/FilterBar';
import { ROLES } from '../../services/util/consts';
import Auth from '../../services/api/auth';

class UserList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showComponent: false,
			danger: false,
			deleteID: '',
			update: false,
			delVM: false,
			users: [],
			passwordChangeState: 0
		};
	}

	//Komponens betölt
	componentDidMount = () => {
		this.refreshUsers();
		this.refreshProfile();
	};

	refreshProfile() {
		Auth.getProfile().then(result => {
			this.setState({ profile: result.data.response });
		});
	}

	//Update table data
	refreshUsers = async () => {
		try {
			const response = this.props.cmId
				? await usersApi.getUsersByCmId(this.props.cmId)
				: this.props.limit
				? await usersApi.getNewestUsers(this.props.limit)
				: await usersApi.getAllUser();

			var myObject = response.data.response;
			const users = myObject ? Object.values(myObject) : [];

			this.setState({ users }, () => this.filterUsers());
		} catch (e) {
			this.setState({ users: [] });
			logger.error(e);
		}
	};

	//Modell show-hide
	toggleDanger = () => {
		this.setState({
			danger: !this.state.danger
		});
	};

	//editProfil
	editProfil = user => {
		this.showEditDialog(user);
	};

	onDeleteUser = user => {
		this.setState({ userToDelete: user });
	};

	//Edit ikon klikk
	editFormatter = (cell, row) => {
		const { t } = this.props;
		const editableRow = !!this.props.possibleRolesEdit.find(
			role => role === row.privilege
		);
		if (editableRow) {
			return (
				<>
					<Button
						onClick={e => this.editProfil(row)}
						variant="success"
						className={classes.EditButton}
					>
						<i className="fa fa-pencil mr-2" />
						{t('groupManager.mainTable.edit.edit')}
					</Button>
					<Button
						onClick={e => this.onDeleteUser(row)}
						className={classes.DeleteButton}
					>
						<i className="fa fa-trash mr-2" />{' '}
						{t('groupManager.mainTable.edit.delete')}
					</Button>
				</>
			);
		} else {
			return null;
		}
	};

	dateFormatter = (cell, row) => {
		if (cell) {
			const dateFromTimeStamp = dateFromMYSQLTimeStamp(cell);
			return dateFromTimeStamp.toLocaleDateString();
		} else {
			return cell;
		}
	};

	//User ikon
	userFormatter = (cell, row) => {
		return <i className="icon-user" id="userIcon" />;
	};

	userClassFormatter = (cell, row) => {
		return this.props.t(getRoleTextId(cell));
	};

	//Add user button action
	handleAddButtonClick = () => {
		this.showInviteDialog();
	};

	onInviteUser(personToInvite) {
		this.hideInviteDialog();
		usersApi.inviteUser(personToInvite).then(() => {
			this.refreshUsers();
			this.showInviteInfoToast();
		});
	}

	showInviteDialog() {
		this.setState({ showInviteUserDialog: true });
	}

	hideInviteDialog() {
		this.setState({ showInviteUserDialog: false });
	}

	renderInviteUserDialog() {
		return (
			<Modal
				show={this.state.showInviteUserDialog}
				onHide={() => this.hideInviteDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.props.t('userManagement.userList.messages.inviteUser')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<InviteUserDialog
						cmID={this.props.cmId}
						email=""
						possibleRoles={this.props.possibleRolesForCreation}
						showInviteEmailSettings={this.props.showInviteEmailSettings}
						onSave={personToInvite => this.onInviteUser(personToInvite)}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	showEditDialog(user) {
		this.setState({
			showEditUserDialog: true,
			userToEdit: user,
			passwordChangeState: 0
		});
	}

	hideEditDialog() {
		this.setState({
			showEditUserDialog: false,
			userToEdit: null,
			passwordChangeState: 0
		});
	}

	onUserDataSave(userToUpdate, notificationSettings, assignedMachineList) {
		usersApi.updateUser(userToUpdate).then(() => {
			this.refreshUsers();
			this.hideEditDialog();
		});

		notificationAPI.saveNotificationSettings(
			userToUpdate.uID,
			notificationSettings
		);

		usersApi.setAssignedMachines(userToUpdate.uID, assignedMachineList);
	}

	async changePassword(oldPassword, newPassword) {
		try {
			if (
				this.state.profile &&
				this.state.profile.uID != this.state.userToEdit.uID
			) {
				await usersApi.setNewPasswordForUser(
					this.state.userToEdit.uID,
					newPassword
				);
			} else {
				await usersApi.setNewPassword(
					this.state.userToEdit.uID,
					oldPassword,
					newPassword
				);
			}
			this.setState({ passwordChangeState: 1 });
		} catch (error) {
			this.setState({ passwordChangeState: 2 });
		}
	}

	renderEditUserDialog() {
		return (
			<Modal
				show={this.state.showEditUserDialog}
				onHide={() => this.hideEditDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i
							class="icon-user"
							style={{ marginRight: '10px', width: '640px' }}
						></i>
						{this.props.t('userManagement.userList.messages.editUser')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: '0px' }}>
					<EditUserDialog
						cmID={this.props.cmId}
						user={this.state.userToEdit}
						possibleRoles={this.props.possibleRolesEdit}
						passwordChangeState={this.state.passwordChangeState}
						hideAssignmentSettings={
							this.state.userToEdit &&
							this.props.usePrivilege.uID === this.state.userToEdit.uID
						}
						hidePasswordEdit={
							!this.state.profile || this.state.profile.privilege != ROLES.root
						}
						hideOriginalPassword={
							this.state.userToEdit &&
							this.state.profile &&
							this.state.profile.uID != this.state.userToEdit.uID
						}
						onSave={(userToUpdate, notificationSettings, assignedMachines) =>
							this.onUserDataSave(
								userToUpdate,
								notificationSettings,
								assignedMachines
							)
						}
						onSavePassword={(oldPassword, newPassword) => {
							this.changePassword(oldPassword, newPassword).then();
						}}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	showInviteInfoToast() {
		this.setState({ showInviteInfoToast: true });
	}

	hideInviteInfoToast() {
		this.setState({ showInviteInfoToast: false });
	}

	renderInviteInfoToast() {
		return (
			<div className={classes.absoluteDiv}>
				<Toast
					className={classes.toastStyle}
					onClose={() => this.hideInviteInfoToast()}
					show={!!this.state.showInviteInfoToast}
					delay={3000}
					autohide
				>
					<Toast.Body>
						{this.props.t(
							'userManagement.userList.messages.userHasBeenInvited'
						)}
					</Toast.Body>
				</Toast>
			</div>
		);
	}

	onDeleteUserConfirmed = () => {
		usersApi.deleteUser(this.state.userToDelete.uID).then(() => {
			this.refreshUsers().then(() => {
				this.setState({ userToDelete: null });
			});
		});
	};

	renderDeleteConfirmation() {
		if (this.state.userToDelete) {
			return (
				<ConfirmationDialog
					isOpen={!!this.state.userToDelete}
					onToggle={() => this.setState({ userToDelete: null })}
					title={this.props.t('userManagement.userList.messages.confirmation')}
					confirmationText={this.props.t(
						'userManagement.userList.messages.deleteConfirmation'
					)}
					cancelText={this.props.t('userManagement.userList.messages.cancel')}
					okText={this.props.t('userManagement.userList.messages.ok')}
					onCancel={() => this.setState({ userToDelete: null })}
					onOk={() => this.onDeleteUserConfirmed()}
				/>
			);
		} else {
			return null;
		}
	}

	renderFilterBar = () => {
		const additionalProps = this.props.showMobileUsers
			? {
					switchButtons: ['Telemetry Users', 'Mobile Users'],
					activeSwitch: this.state.activeSwitchIndex
						? this.state.activeSwitchIndex
						: 0,
					onSwitchClick: index =>
						this.setState({ activeSwitchIndex: index }, () =>
							this.filterUsers()
						)
			  }
			: {};
		return (
			<FilterBar
				key="filterbar"
				showAddButton
				onShowCreateItem={() => this.handleAddButtonClick()}
				onFilterChanged={value => this.onFilterChange(value)}
				{...additionalProps}
			/>
		);
	};

	filterUsers() {
		let filteredUsers = [...this.state.users];
		if (!this.state.activeSwitchIndex) {
			filteredUsers = filteredUsers.filter(
				user => user.privilege != ROLES.mobileUser
			);
		} else {
			filteredUsers = filteredUsers.filter(
				user => user.privilege == ROLES.mobileUser
			);
		}

		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredUsers = filteredUsers.filter(user => {
				let curUser = { ...user };
				curUser.privilege = this.props.t(getRoleTextId(curUser.privilege));
				let userFieldArray = Object.values(curUser);
				return userFieldArray.some(
					val =>
						String(val)
							.toUpperCase()
							.indexOf(filterValue) >= 0
				);
			});
		}

		this.setState({ filteredUsers: filteredUsers });
	}

	onFilterChange(filteValue) {
		this.setState({ currentFilterValue: filteValue }, () => this.filterUsers());
	}

	isColumnsVisibile(columName) {
		return (
			!this.props.hiddenColumns ||
			this.props.hiddenColumns.indexOf(columName) < 0
		);
	}

	renderTable() {
		//Table options
		const tableOptions = {
			sortIndicator: true,
			hideSizePerPage: false,
			paginationSize: 3,
			sizePerPageList: [10],
			sizePerPage: this.props.recordPerPage || 10,
			withFirstAndLast: true,
			alwaysShowAllBtns: true,
			pageStartIndex: 1,
			paginationPosition: 'bottom',
			clearSearch: true,
			hidePageListOnlyOnePage: true,
			sortName: 'privilege',
			sortOrder: 'asc',
			searchPanel: this.renderFilterBar,
			onSearchChange: this.onFilterChange
		};
		const { t } = this.props;
		return (
			<>
				{!this.props.hideToolbar ? this.renderFilterBar() : null}
				<BootstrapTable
					key="bootstrapTable"
					data={this.state.filteredUsers}
					version="4"
					striped
					hover
					pagination={!this.props.hidePager}
					insertRow={false}
					options={tableOptions}
					multiColumnSearch
					condensed
					bordered={false}
					dataAlign={'center'}
					size="sm"
				>
					{this.isColumnsVisibile('userIcon') ? (
						<TableHeaderColumn
							width="50"
							dataAlign="center"
							dataField="userID"
							dataFormat={this.userFormatter}
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px' }}
						/>
					) : null}
					{this.isColumnsVisibile('privilege') ? (
						<TableHeaderColumn
							width="100"
							dataField="privilege"
							dataSort
							dataFormat={this.userClassFormatter}
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{t('usersettings.basicdata.label.role')}
						</TableHeaderColumn>
					) : null}
					{this.isColumnsVisibile('email') ? (
						<TableHeaderColumn
							width="200"
							isKey
							dataField="email"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{t('usersettings.basicdata.label.email')}
						</TableHeaderColumn>
					) : null}
					{this.isColumnsVisibile('firstName') ? (
						<TableHeaderColumn
							width="150"
							dataField="firstName"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{t('usersettings.contact.label.firstname')}
						</TableHeaderColumn>
					) : null}
					{this.isColumnsVisibile('lastName') ? (
						<TableHeaderColumn
							width="150"
							dataField="lastName"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{t('usersettings.contact.label.lastname')}
						</TableHeaderColumn>
					) : null}

					{this.isColumnsVisibile('company') ? (
						<TableHeaderColumn
							width="150"
							dataField="company"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal',
								fontWeight: 'bold'
							}}
						>
							{t('usersettings.basicdata.label.company')}
						</TableHeaderColumn>
					) : null}

					{this.isColumnsVisibile('connPhone') ? (
						<TableHeaderColumn
							width="200"
							dataField="connPhone"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{t('usersettings.contact.label.phone')}
						</TableHeaderColumn>
					) : null}

					{this.isColumnsVisibile('insertedAt') ? (
						<TableHeaderColumn
							width="200"
							dataField="insertedAt"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
							dataFormat={this.dateFormatter}
						>
							{t('usersettings.contact.label.regDate')}
						</TableHeaderColumn>
					) : null}
					{this.isColumnsVisibile('action') ? (
						<TableHeaderColumn
							width="100"
							dataField="userID"
							dataFormat={this.editFormatter}
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						/>
					) : null}
				</BootstrapTable>
			</>
		);
	}

	renderTitle() {
		const { titleOnClick = () => {} } = this.props;

		return (
			<Row>
				<Col>
					<CardTitle
						className="text-muted"
						style={{ cursor: 'pointer' }}
						onClick={titleOnClick}
					>
						<i className={`icon-user`} style={{ marginRight: '10px' }} />
						{this.props.title}
					</CardTitle>
				</Col>
			</Row>
		);
	}

	//Render
	render() {
		return (
			<div className="animated fadeIn">
				<DashboardCardContainerWithFlow>
					<Card body>
						{!userUtil.isRoot() ? (
							<PageTiltleCard
								title={this.props.t('sidebarmenu.partnerManager')}
							/>
						) : null}
						{this.renderInviteInfoToast()}
						{this.renderEditUserDialog()}
						{this.renderInviteUserDialog()}
						{this.renderDeleteConfirmation()}
						{this.props.renderDocked ? (
							<Card className={this.props.className} style={this.props.style}>
								<CardBody>
									{this.props.title && this.props.title.length > 0
										? this.renderTitle()
										: null}
									{this.renderTable()}
								</CardBody>
							</Card>
						) : (
							this.renderTable()
						)}
					</Card>
				</DashboardCardContainerWithFlow>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	usePrivilege: state.user
});

export default withRouter(
	connect(mapStateToProps)(withTranslation()(UserList))
);
