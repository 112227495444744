//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import SnakkyBaseMachineKeyboard from './Base/SnakkyBaseMachineKeyboard';
import AlphanumericMachineBaseComponent from '../AlphanumericMachine/AlphanumericMachineBaseComponent';

type Props = {
	vmID: string,
	history: Object,
	products?: Array<Object>,
	isSlave: ?boolean
};
type States = {};

class Snakky extends React.Component<Props, States> {
	render() {
		return (
			<AlphanumericMachineBaseComponent
				lineNumber={2}
				lineLength={16}
				machineTitle="Snakky"
				keyboard={
					<SnakkyBaseMachineKeyboard
						hasProgrammingButton={true}
						{...this.props}
					/>
				}
				{...this.props}
			/>
		);
	}
}

export default withRouter(Snakky);
