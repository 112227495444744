import React, { Component } from 'react';
import NotificationList from '../../components/NotificationList/NotificationList';
import { Card, CardBody } from 'reactstrap';

class Notifications extends Component {
	render() {
		return (
			<Card className={this.props.className} style={this.props.style}>
				<CardBody>
					<NotificationList maxCount="100"></NotificationList>
				</CardBody>
			</Card>
		);
	}
}

export default Notifications;
