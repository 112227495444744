import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SalesList from '../../views/Sales/SalesList.js';

class SalesRoute extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route path="/sales" name="Sales" component={SalesList} />
					<Redirect from="/" to="/sales" />
				</Switch>
			</div>
		);
	}
}

export default SalesRoute;
