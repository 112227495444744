//@flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import SnakkyBaseMachineKeyboard from './Base/SnakkyBaseMachineKeyboard'
import AlphanumericMachineBaseComponent from '../AlphanumericMachine/AlphanumericMachineBaseComponent'

type Props = {
  vmID: string,
  history: Object,
  products?: Array<Object>,
  isSlave: ?boolean,
}
type States = {}

class SnakkyMax extends React.Component<Props, States> {
  render() {
    return (
      <AlphanumericMachineBaseComponent
        lineNumber={4}
        lineLength={20}
        machineTitle="SnakkyMax"
        keyboard={
          <SnakkyBaseMachineKeyboard
            hasProgrammingButton={true}
            {...this.props}
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(SnakkyMax)
