import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CustomerDetailer from '../../views/InvisibleTouchManager/CustomerDetailer/CustomerDetailer';
import InvisibleTouchManager from '../../views/InvisibleTouchManager/InvisibleTouchManager';

class InvisibleTouchManagerPageRoute extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route
						path="/invisible_touch_manager/customerDetailer"
						name="CustomerDetailer"
						component={CustomerDetailer}
					/>
					<Route
						path="/invisible_touch_manager"
						name="InvisibleTouchManager"
						component={InvisibleTouchManager}
					/>
					<Redirect from="/" to="/invisible_touch_manager" />
				</Switch>
			</div>
		);
	}
}

export default InvisibleTouchManagerPageRoute;
