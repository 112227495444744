import axios from './api';
import qs from 'qs';

export default {
	getPartnerWallet: async cmID => {
		const param = {
			cmID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/customer/wallet${query}`);
	},

	getPurchasers: async walletID => {
		const param = {
			walletID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/customer/wallet/users${query}`);
	},

	getPurchaserWallets: async userID => {
		const param = {
			userID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/users/wallets${query}`);
	},

	getTransactionsCountOfWallet: async (userID, customerWalletID) => {
		const param = {
			userID,
			customerWalletID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/users/wallets/transactions/count${query}`);
	},

	getTransactionsOfWallet: async (userID, customerWalletID, recordCount) => {
		const param = {
			userID,
			customerWalletID,
			startIndex: 0,
			recordCount: recordCount
		};

		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/users/wallets/transactions${query}`);
	},

	getBalanceOfWallet: async (userID, customerWalletID) => {
		const param = {
			userID,
			customerWalletID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/users/wallets/balance${query}`);
	},

	addCreditToAnAccount: async transaction => {
		const body = { ...transaction };

		return await axios.get().post(`/users/wallets/transaction`, body);
	},

	refundTransaction: async (transactionIndex, remarkText) => {
		const body = { transactionIndex, remarkText };

		return await axios.get().put(`/customer/refundTransaction`, body);
	},

	editBillingState: async transaction => {
		const body = { ...transaction };

		return await axios.get().put(`/customer/invoiceRequests`, body);
	},

	getTransactionsOfPartnerWallet: async value => {
		const param = {
			walletID: value.walletID,
			fromDate: value.fromDate,
			toDate: value.toDate
		};

		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/customer/wallet/transactions${query}`);
	}
};
