import React from 'react';
import { withRouter } from 'react-router-dom';
// import { Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { Record } from 'immutable';

import DashboardCardSalesData from '../CardHandling/DashboardCardSalesData';
import DashboardCardContainerWithFlow from '../CardHandling/DashboardCardContainerWithFlow';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { SystemStaticsDataType } from '../CardHandling/SystemStaticsDataType';

import IncomeCard from '../CardHandling/IncomeCard/IncomeCard';
// import EventListCard from '../CardHandling/EventListCard/EventListCard';
import MapViewCard from '../CardHandling/MapViewCard/MapViewCard';
// import NotificationCard from '../CardHandling/NotificationsCard/NotificationCard';

import classes from './CustomerDashboard.module.css';
import MachineStateCard from '../CardHandling/MachineStateCard/MachineStateCard';
import MachineStateLabelCard from '../CardHandling/MachineStateCard/MachineStateLabelCard';
import MachinePerformanceCard from '../CardHandling/MachinePerformanceCard/MachinePerformanceCard';
import PageTitleCard from '../CardHandling/PageTitleCard/PageTitleCard';
import ProductLineChart from '../CardHandling/ProductLineChart/ProductLineChart';

import customersAPI from '../../../services/api/customers';

type Props = {
	t: TFunction,
	user: Record
};
type States = {
	vms: Array<Object>,
	users: Array<Object>
};

const mapStateToProps = state => ({
	user: state.user
});

class CustomerDashboard extends React.Component<Props, States> {
	constructor(props) {
		super(props);

		this.state = {
			daysCount: 31,
			showComponent: false
		};
	}

	componentDidMount() {
		if (this.props.user) {
			customersAPI
				.getCustomerCurrencySymbol(this.props.user.cmID)
				.then(currencySymbol => {
					this.setState({ currencySymbol: currencySymbol });
				});
		}
	}

	render() {
		const statRefreshInteval = 60000;
		const { t } = this.props;

		let curDayStart = new Date();
		curDayStart.setHours(0, 0, 0, 0);

		let curMonthStart = new Date();
		curMonthStart.setDate(0);
		curMonthStart.setHours(0, 0, 0, 0);

		let curYearStart = new Date();
		curYearStart.setMonth(0);
		curYearStart.setDate(0);
		curYearStart.setHours(0, 0, 0, 0);

		const dayTitle =
			curDayStart.toLocaleDateString(undefined, {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric'
			}) +
			' ' +
			curDayStart.toLocaleDateString(undefined, { weekday: 'long' });

		const monthTitle = curDayStart.toLocaleDateString(undefined, {
			year: 'numeric',
			month: 'long'
		});

		const yearTitle = curDayStart
			.toLocaleDateString(undefined, {
				year: 'numeric'
			})
			.replace('.', '');

		return (
			<div className="animated fadeIn">
				<DashboardCardContainerWithFlow>
					<div className={classes.RootCardTraffic}>
						<PageTitleCard title={t('customerDashboard.title')} />
					</div>
					<div className={classes.HeaderCard}>
						<DashboardCardSalesData
							title={dayTitle}
							className={classes.RootCard + ' bg-info'}
							subTitleTop={t('customerDashboard.incomeOfDay.title')}
							subTitleBottom={t('customerDashboard.incomeOfDay.subTitle')}
							iconClass="fa fa-money"
							refreshInterval={statRefreshInteval}
							titleOnClick={() =>
								this.props.history.push('/cashFlow?chartType=dayly')
							}
							periodStart={curDayStart}
							currency={this.state.currencySymbol}
						/>
					</div>
					<div className={classes.HeaderCard}>
						<DashboardCardSalesData
							title={monthTitle}
							className={classes.RootCard + ' bg-info'}
							subTitleTop={t('customerDashboard.incomeOfMonth.title')}
							subTitleBottom={t('customerDashboard.incomeOfMonth.subTitle')}
							iconClass="fa fa-money"
							refreshInterval={statRefreshInteval}
							titleOnClick={() =>
								this.props.history.push('/cashFlow?chartType=monthly')
							}
							periodStart={curMonthStart}
							currency={this.state.currencySymbol}
						/>
					</div>

					<div className={classes.HeaderCard}>
						<DashboardCardSalesData
							title={yearTitle}
							className={classes.RootCard + ' bg-info'}
							subTitleTop={t('customerDashboard.incomeOfYear.title')}
							subTitleBottom={t('customerDashboard.incomeOfYear.subTitle')}
							iconClass="fa fa-money"
							refreshInterval={statRefreshInteval}
							titleOnClick={() =>
								this.props.history.push('/cashFlow?chartType=yearly')
							}
							periodStart={curYearStart}
							currency={this.state.currencySymbol}
						/>
					</div>

					<div className={classes.HeaderCard}>
						<DashboardCardSalesData
							className={classes.RootCard + ' bg-info'}
							subTitleTop={t('customerDashboard.incomeOfPeriod.title')}
							subTitleBottom={t('customerDashboard.incomeOfPeriod.subTitle')}
							iconClass="fa fa-money"
							refreshInterval={statRefreshInteval}
							titleOnClick={() =>
								this.props.history.push('/cashFlow?chartType=period')
							}
							periodStart={curDayStart}
							configurable="true"
							currency={this.state.currencySymbol}
						/>
					</div>
					<MachineStateLabelCard className={classes.RootCardTraffic} />
					{/* <MachineStateCard className={classes.RootCardTraffic} /> */}
					<IncomeCard
						IncomeSwitchButton={classes.IncomeSwitchButton}
						className={classes.RootCardTraffic}
						onDayCountChange={daysCount => {
							this.setState({ daysCount });
						}}
						currency={this.state.currencySymbol}
					/>

					<MachinePerformanceCard
						className={classes.RootCardTraffic}
						daysCount={this.state.daysCount}
					/>
					<MapViewCard className={classes.MachineMapCard} />
				</DashboardCardContainerWithFlow>
			</div>
		);
	}
}

export default connect(mapStateToProps)(
	withRouter(withTranslation()(CustomerDashboard))
);
