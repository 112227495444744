import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import LogsList from '../../views/Logs/LogsList.js'

class ProfileRout extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/Logs" name="LogsList" component={LogsList} />

          <Redirect from="/" to="/logs" />
        </Switch>
      </div>
    )
  }
}

export default ProfileRout
