import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Button, Row, Col } from 'reactstrap';
import classes from './IncomeChart.module.css';
import MachineSelector from '../../components/MachineGroupSelector/MachineGroupSelector';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import salesAndIncomeUtils from '../../services/util/salesAndIncomeUtils';

class PeriodChartFilterPanel extends Component {
	state = {
		incomeStartDate: this.props.startDate,
		incomeEndDate: this.props.endDate,
		salesStartDate: this.props.startDate,
		salesEndDate: this.props.endDate,
		incomeBreakdown: salesAndIncomeUtils.breakDownOptions.DAILY,
		salesBreakdown: salesAndIncomeUtils.breakDownOptions.DAILY,
		isIncomeGroupSelected: false,
		isSalesGroupSelected: false,
		incomeSelectedMachines: { assignedGroupID: null, assignedVMID: null },
		salesSelectedMachines: { assignedGroupID: null, assignedVMID: null },
		optionMachine: null,
		optionGroup: null
	};

	componentDidMount() {
		let optionMachine = this.props.optionMachine;
		let optionGroup = this.props.optionGroup;
		this.setState({
			optionMachine: optionMachine,
			optionGroup: optionGroup
		});
	}

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	setBreakdownoptions = value => {
		return value.map((e, index) => (
			<option key={e.label + index} label={e.label} value={e.value}>
				{e.label}
			</option>
		));
	};

	switchDeviceFilter = income => {
		if (income) {
			if (
				!this.state.incomeSelectedMachines.assignedVMID &&
				!this.state.incomeSelectedMachines.assignedGroupID
			) {
				let incomeSelectedMachines = { ...this.state.incomeSelectedMachines };
				incomeSelectedMachines.assignedVMID = this.state.optionMachine[0].value;
				return this.setState({
					incomeSelectedMachines: incomeSelectedMachines,
					isIncomeGroupSelected: false
				});
			} else {
				let incomeSelectedMachines = { ...this.state.incomeSelectedMachines };
				incomeSelectedMachines.assignedVMID = null;
				incomeSelectedMachines.assignedGroupID = null;
				return this.setState({
					incomeSelectedMachines: incomeSelectedMachines,
					isIncomeGroupSelected: false
				});
			}
		} else {
			if (
				!this.state.salesSelectedMachines.assignedVMID &&
				!this.state.salesSelectedMachines.assignedGroupID
			) {
				let salesSelectedMachines = { ...this.state.salesSelectedMachines };
				salesSelectedMachines.assignedVMID = this.state.optionMachine[0].value;
				return this.setState({
					salesSelectedMachines: salesSelectedMachines,
					isSalesGroupSelected: false
				});
			} else {
				let salesSelectedMachines = { ...this.state.salesSelectedMachines };
				salesSelectedMachines.assignedVMID = null;
				salesSelectedMachines.assignedGroupID = null;
				return this.setState({
					salesSelectedMachines: salesSelectedMachines,
					isSalesGroupSelected: false
				});
			}
		}
	};
	// machhine selector ----------------
	isIncomeGroupSelectedChanged = (groupSelected, newItemSelection) => {
		const newselectedMachines = {
			...this.state.incomeSelectedMachines
		};
		if (groupSelected) {
			newselectedMachines.assignedVMID = null;
			newselectedMachines.assignedGroupID = newItemSelection;
		} else {
			newselectedMachines.assignedVMID = newItemSelection;
			newselectedMachines.assignedGroupID = null;
		}
		this.setState({
			incomeSelectedMachines: newselectedMachines,
			isIncomeGroupSelected: groupSelected
		});
	};
	isSalesGroupSelectedChanged = (groupSelected, newItemSelection) => {
		const newselectedMachines = {
			...this.state.salesSelectedMachines
		};
		if (groupSelected) {
			newselectedMachines.assignedVMID = null;
			newselectedMachines.assignedGroupID = newItemSelection;
		} else {
			newselectedMachines.assignedVMID = newItemSelection;
			newselectedMachines.assignedGroupID = null;
		}
		this.setState({
			salesSelectedMachines: newselectedMachines,
			isSalesGroupSelected: groupSelected
		});
	};
	incomeSelectionChanged = value => {
		const newselectedMachines = {
			...this.state.incomeSelectedMachines
		};
		if (this.state.isIncomeGroupSelected) {
			newselectedMachines.assignedVMID = null;
			newselectedMachines.assignedGroupID = value;
		} else {
			newselectedMachines.assignedVMID = value;
			newselectedMachines.assignedGroupID = null;
		}
		this.setState({
			incomeSelectedMachines: newselectedMachines
		});
	};

	salesSelectionChanged = value => {
		const newselectedMachines = {
			...this.state.salesSelectedMachines
		};
		if (this.state.isSalesGroupSelected) {
			newselectedMachines.assignedVMID = null;
			newselectedMachines.assignedGroupID = value;
		} else {
			newselectedMachines.assignedVMID = value;
			newselectedMachines.assignedGroupID = null;
		}
		this.setState({
			salesSelectedMachines: newselectedMachines
		});
	};
	clearFilter = income => {
		let incomeSelectedMachines = { ...this.state.incomeSelectedMachines };
		incomeSelectedMachines.assignedGroupID = null;
		incomeSelectedMachines.assignedVMID = null;
		let salesSelectedMachines = { ...this.state.salesSelectedMachines };
		salesSelectedMachines.assignedGroupID = null;
		salesSelectedMachines.assignedVMID = null;

		income
			? this.setState({
					incomeStartDate: new Date(),
					incomeEndDate: new Date(),
					incomeBreakdown: salesAndIncomeUtils.breakDownOptions.HOURLY,
					incomeSelectedMachines: incomeSelectedMachines
			  })
			: this.setState({
					salesStartDate: new Date(),
					salesEndDate: new Date(),
					salesBreakdown: salesAndIncomeUtils.breakDownOptions.HOURLY,
					salesSelectedMachines: salesSelectedMachines
			  });
	};

	render() {
		const { t, product } = this.props;
		const income = this.props.income;

		const breakDownOptions = [
			{
				value: salesAndIncomeUtils.breakDownOptions.HOURLY,
				label: t('cashFlow.periodFilterPanel.breakDownOptions.hourly')
			},
			{
				value: salesAndIncomeUtils.breakDownOptions.DAILY,
				label: t('cashFlow.periodFilterPanel.breakDownOptions.daily')
			},
			{
				value: salesAndIncomeUtils.breakDownOptions.MONTHLY,
				label: t('cashFlow.periodFilterPanel.breakDownOptions.monthly')
			},
			{
				value: salesAndIncomeUtils.breakDownOptions.YEARLY,
				label: t('cashFlow.periodFilterPanel.breakDownOptions.yearly')
			}
		];
		return (
			<>
				<Form>
					<Row className={classes.FilterRow}>
						<Col xs={12} sm={8} md={8} lg={8} xl={10} xxl={10}>
							<Row className={classes.PanelRow}>
								{!product ? (
									<Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
										<Row className={classes.DateRow}>
											<Col xs={12} sm={12} md={12} lg={3} xl={12} xxl={12}>
												<Form.Label>
													{t('cashFlow.periodFilterPanel.breakDown')}
												</Form.Label>
											</Col>
											<Col xs={12} sm={12} md={12} lg={9} xl={12} xxl={12}>
												<Form.Control
													className={classes.FilterInput}
													name={income ? 'incomeBreakdown' : 'salesBreakdown'}
													as="select"
													onChange={this.inputChangeHandler}
													value={
														income
															? this.state.incomeBreakdown
															: this.state.salesBreakdown
													}
												>
													{this.setBreakdownoptions(breakDownOptions)}
												</Form.Control>
											</Col>
										</Row>
									</Col>
								) : null}
								<Col
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={product ? 6 : 4}
									xxl={product ? 6 : 4}
								>
									<Row className={classes.DateRow}>
										<Col xs={12} sm={12} md={12} lg={3} xl={12} xxl={12}>
											<Form.Label>
												{t('cashFlow.periodFilterPanel.startDate')}
											</Form.Label>
										</Col>
										<Col xs={12} sm={12} md={12} lg={9} xl={12} xxl={12}>
											<Form.Control
												className={classes.FilterInput}
												name={income ? 'incomeStartDate' : 'salesStartDate'}
												type={'datetime-local'}
												value={moment(
													income
														? this.state.incomeStartDate
														: this.state.salesStartDate
												).format('YYYY-MM-DDTHH:mm')}
												onChange={this.inputChangeHandler}
											></Form.Control>
										</Col>
									</Row>
								</Col>
								<Col
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={product ? 6 : 4}
									xxl={product ? 6 : 4}
								>
									<Row className={classes.DateRow}>
										<Col xs={12} sm={12} md={12} lg={3} xl={12} xxl={12}>
											<Form.Label>
												{t('cashFlow.periodFilterPanel.endDate')}
											</Form.Label>
										</Col>
										<Col xs={12} sm={12} md={12} lg={9} xl={12} xxl={12}>
											<Form.Control
												className={classes.FilterInput}
												name={income ? 'incomeEndDate' : 'salesEndDate'}
												type={'datetime-local'}
												value={moment(
													income
														? this.state.incomeEndDate
														: this.state.salesEndDate
												).format('YYYY-MM-DDTHH:mm')}
												onChange={this.inputChangeHandler}
											></Form.Control>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className={classes.PanelRowDown}>
								<Col xs={12} sm={12} md={12} lg={12}>
									<MachineSelector
										disabled={
											income
												? !this.state.incomeSelectedMachines.assignedVMID &&
												  !this.state.incomeSelectedMachines.assignedGroupID
												: !this.state.salesSelectedMachines.assignedVMID &&
												  !this.state.salesSelectedMachines.assignedGroupID
										}
										incomeRow={'classes.IncomeChart'}
										optionGroup={this.state.optionGroup}
										optionMachine={this.state.optionMachine}
										isGroupSelected={
											income
												? this.state.isIncomeGroupSelected
												: this.state.isSalesGroupSelected
										}
										selectedIDS={
											income
												? this.state.isIncomeGroupSelected
													? this.state.incomeSelectedMachines.assignedGroupID
													: this.state.incomeSelectedMachines.assignedVMID
												: this.state.isSalesGroupSelected
												? this.state.salesSelectedMachines.assignedGroupID
												: this.state.salesSelectedMachines.assignedVMID
										}
										onIsGroupSelectedChanged={
											income
												? (groupSelected, newItemSelection) =>
														this.isIncomeGroupSelectedChanged(
															groupSelected,
															newItemSelection
														)
												: (groupSelected, newItemSelection) =>
														this.isSalesGroupSelectedChanged(
															groupSelected,
															newItemSelection
														)
										}
										onSelectionChanged={
											income
												? value => this.incomeSelectionChanged(value)
												: value => this.salesSelectionChanged(value)
										}
									/>
								</Col>
							</Row>
						</Col>
						<Col
							xs={12}
							sm={3}
							md={4}
							lg={4}
							xl={2}
							xxl={2}
							className={classes.BtnPanelRow}
						>
							<Button
								className={classes.ClearButton}
								onClick={
									income
										? () => this.clearFilter(income)
										: () => this.clearFilter()
								}
								style={{ margin: '0.25rem' }}
							>
								<i className="fa fa-ban mr-2" />
								{t('cashFlow.periodFilterPanel.button.clear')}
							</Button>

							<Button
								className={classes.FilterButton}
								onClick={
									income
										? () => this.props.onFilter(income, this.state)
										: () => this.props.onFilter(income, this.state)
								}
								style={{ margin: '0.25rem' }}
							>
								<i className="fa fa-search mr-2" />
								{t('cashFlow.periodFilterPanel.button.filter')}
							</Button>
							<Button
								style={{ margin: '0.25rem' }}
								className={
									income
										? this.state.incomeSelectedMachines.assignedVMID ||
										  this.state.incomeSelectedMachines.assignedGroupID
											? classes.DeviceFilterButtonOff
											: classes.DeviceFilterButton
										: this.state.salesSelectedMachines.assignedVMID ||
										  this.state.salesSelectedMachines.assignedGroupID
										? classes.DeviceFilterButtonOff
										: classes.DeviceFilterButton
								}
								onClick={() => this.switchDeviceFilter(income)}
							>
								<i className="fa fa-search mr-2" />
								{income
									? this.state.incomeSelectedMachines.assignedVMID ||
									  this.state.incomeSelectedMachines.assignedGroupID
										? t('cashFlow.periodFilterPanel.button.deviceFilter.off')
										: t('cashFlow.periodFilterPanel.button.deviceFilter.on')
									: this.state.salesSelectedMachines.assignedVMID ||
									  this.state.salesSelectedMachines.assignedGroupID
									? t('cashFlow.periodFilterPanel.button.deviceFilter.off')
									: t('cashFlow.periodFilterPanel.button.deviceFilter.on')}
							</Button>
						</Col>
					</Row>
				</Form>
			</>
		);
	}
}

export default withTranslation()(PeriodChartFilterPanel);
