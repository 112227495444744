import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import classes from './ProductGroupTable.module.css';
import { ButtonGroup } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import DescriptionTooltip from '../../DeviceGroupManagerTab/DeviceGroupTable/DescriptionTooltip';

import { withTranslation } from 'react-i18next';

const ProductGroupTable = props => {
	const { t } = props;

	const isColumnsVisibile = columName => {
		return !props.hiddenColumns || props.hiddenColumns.indexOf(columName) < 0;
	};

	const showListFormater = (cell, row) => {
		return (
			<Button
				onClick={e => props.showList(row)}
				variant="success"
				className={classes.ActivateButton}
			>
				<i className="fa fa-list" />
				&nbsp;
				{t('groupManager.mainTable.display')}
			</Button>
		);
	};

	const editFormatter = (cell, row) => {
		return (
			<ButtonGroup vertical>
				<Button
					onClick={e => props.editProfil(row)}
					variant="success"
					className={classes.EditButton}
				>
					<i className="fa fa-pencil" /> {t('groupManager.mainTable.edit.edit')}
				</Button>
				<Button
					onClick={e => props.onDeleteGroup(row)}
					className={classes.DeleteButton}
				>
					<i className="fa fa-trash" />
					&nbsp;
					{t('groupManager.mainTable.edit.delete')}
				</Button>
			</ButtonGroup>
		);
	};

	const dateFormater = (cell, row) => {
		return (cell = row.creationDate.slice(0, 10));
	};

	const userFormatter = (cell, row) => {
		return <i className="fa fa-cubes" id="userIcon" />;
	};

	const descriptionFormater = cell => {
		const btn = (
			<>
				<i className="fa fa-angle-left" /> {t('groupManager.mainTable.details')}
			</>
		);

		return (
			<DescriptionTooltip
				placement={'left'}
				title={t('groupManager.mainTable.detailsOverlay.product')}
				content={cell}
				btnTitle={btn}
				className={classes.DescriptionButton}
			/>
		);
	};

	//Table options

	const tableOptions = {
		sortIndicator: true,
		hideSizePerPage: false,
		paginationSize: 3,
		sizePerPageList: [4],
		sizePerPage: props.recordPerPage || 4,
		withFirstAndLast: true,
		paginationPosition: 'bottom',
		alwaysShowAllBtns: false,
		clearSearch: true,
		hidePageListOnlyOnePage: true,
		sortName: 'ID',
		sortOrder: 'asc',
		searchPanel: props.renderFilterBar,
		onSearchChange: props.onFilterChange
	};

	return (
		<>
			{!props.hideToolbar ? props.renderFilterBar() : null}
			<BootstrapTable
				key="bootstrapTable"
				data={props.data}
				version="4"
				striped
				hover
				pagination={!props.hidePager}
				insertRow={false}
				options={tableOptions}
				multiColumnSearch
				condensed
				bordered={false}
				dataAlign={'center'}
				size="sm"
			>
				{isColumnsVisibile('id') ? (
					<TableHeaderColumn
						isKey
						width="60"
						dataAlign="center"
						dataField="ID"
						dataFormat={userFormatter}
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px' }}
					/>
				) : null}
				{isColumnsVisibile('groupName') ? (
					<TableHeaderColumn
						dataField="groupName"
						dataAlign="center"
						width="150"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{t('groupManager.mainTable.groupname')}
					</TableHeaderColumn>
				) : null}

				{isColumnsVisibile('creationDate') ? (
					<TableHeaderColumn
						dataField="creationDate"
						width="150"
						dataAlign="center"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						dataFormat={dateFormater}
					>
						{t('groupManager.mainTable.creation')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('description') ? (
					<TableHeaderColumn
						width="150"
						dataField="description"
						dataAlign="center"
						dataFormat={descriptionFormater}
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal'
						}}
					>
						{t('groupManager.mainTable.description')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('showList') ? (
					<TableHeaderColumn
						dataField="showList"
						dataAlign="center"
						width="150"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal'
						}}
						dataFormat={showListFormater}
					>
						{t('groupManager.mainTable.productList')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('action') ? (
					<TableHeaderColumn
						width="100"
						dataField="userID"
						dataFormat={editFormatter}
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					>
						{t('groupManager.mainTable.edit.title')}
					</TableHeaderColumn>
				) : null}
			</BootstrapTable>
		</>
	);
};

export default withTranslation()(ProductGroupTable);
