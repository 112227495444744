import React, { Component } from 'react';
import { Card, CardTitle, Button, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import classes from './InvoicePanel.module.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Badge } from 'react-bootstrap';
import Spinner from '../../../components/UI/Spinner/Spinner';
import moment from 'moment';
import { ButtonGroup } from 'react-bootstrap';

class InvoicePanel extends Component {
	isColumnsVisibile = columName => {
		return (
			!this.props.hiddenColumns ||
			this.props.hiddenColumns.indexOf(columName) < 0
		);
	};

	stateFormater = (cell, row) => {
		if (!row) return;
		let state = this.props.t('invisibleTouchManager.controlpanel.noData');
		let style = {
			color: 'var(--purchases-dark)',
			backgroundColor: 'var(--purchases-light)'
		};
		let i = 'fa fa-exclamation';

		switch (row.billingStateID) {
			case 3:
				state = this.props.t('invisibleTouchManager.invoicePanel.declained');
				style = { color: 'var(--purchases-dark)' };
				i = 'fa fa-times-circle';
				break;
			case 2:
				state = this.props.t('invisibleTouchManager.invoicePanel.provided');
				style = { color: 'var(--balance)' };
				i = 'fa fa-check-circle';
				break;
			case 1:
				state = this.props.t('invisibleTouchManager.invoicePanel.requested');
				style = { color: 'var(--inv-tuch-error)' };
				i = 'fa fa-exclamation-circle';
				break;
		}
		return (
			<>
				<i className={i} style={style} />
				<Badge style={style}>{state}</Badge>
			</>
		);
	};

	approvalhandler = (bsID, row) => {
		if (bsID && row) {
			this.props.onEditBillingState(bsID, row, this.props.data);
		}
	};

	approvalFormater = (cell, row) => {
		if (!row) return;
		let disabled = false;
		if (row.billingStateID !== 1) {
			disabled = true;
		}

		return (
			<ButtonGroup>
				<Col>
					<Button
						onClick={e => this.approvalhandler(2, row)}
						className={classes.ApproveButton}
						disabled={disabled}
					>
						<i className="fa fa-check-circle mr-2" />
						&nbsp;
						{this.props.t('invisibleTouchManager.invoicePanel.providing')}
					</Button>
				</Col>
				<Col>
					<Button
						onClick={e => this.approvalhandler(3, row)}
						className={classes.DeclainButton}
						disabled={disabled}
					>
						<i className="fa fa-times-circle mr-2" />
						&nbsp;
						{this.props.t('invisibleTouchManager.invoicePanel.declaining')}
					</Button>
				</Col>
			</ButtonGroup>
		);
	};

	dateFormater = cell => {
		if (!cell) return;
		return (
			<p className={classes.Date}>
				{moment(cell).format('YYYY-MM-DD HH:mm:ss')}
			</p>
		);
	};

	amountFormater = cell => {
		let data = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (cell || cell === 0) {
			data = <p>{cell.toFixed(2)}</p>;
		}
		return data;
	};

	userFormatter = (cell, row) => {
		return <i className="fa fa-file" id="userIcon" />;
	};

	renderTable = () => {
		const { t } = this.props;
		const tableOptions = {
			sortIndicator: false,
			hideSizePerPage: false,
			paginationSize: 3,
			sizePerPageList: [8],
			sizePerPage: this.props.recordPerPage || 8,
			paginationPosition: 'bottom',
			withFirstAndLast: true,
			alwaysShowAllBtns: true,
			pageStartIndex: 1,
			clearSearch: true,
			hidePageListOnlyOnePage: true,
			defaultSortName: 'insertedAt',
			defaultSortOrder: 'desc',
			searchPanel: this.props.renderFilterBar,
			onSearchChange: this.props.onFilterChange
		};

		let table = <Spinner />;
		if (this.props.transactions) {
			let billing = [];
			billing = this.props.transactions.filter(e => e.billingStateID);
			table = (
				<>
					{!this.props.hideToolbar ? this.props.onRenderFilterBar() : null}
					<BootstrapTable
						key="bootstrapTable"
						data={billing}
						version="4"
						pagination={!this.props.hidePager}
						insertRow={false}
						options={tableOptions}
						multiColumnSearch
						condensed
						bordered={false}
						dataAlign={'center'}
						size="sm"
						trClassName={classes.TableBody}
						height="320"
					>
						{this.isColumnsVisibile('id') ? (
							<TableHeaderColumn
								isKey
								width="100"
								dataAlign="center"
								dataField="id"
								dataFormat={this.userFormatter}
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
							/>
						) : null}
						{this.isColumnsVisibile('index') ? (
							<TableHeaderColumn
								dataField="index"
								dataAlign="center"
								width="200"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
							>
								{t('invisibleTouchManager.purchasesPanel.account')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('insertedAt') ? (
							<TableHeaderColumn
								dataField="insertedAt"
								width="200"
								dataAlign="center"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
								dataFormat={this.dateFormater}
							>
								{t('invisibleTouchManager.purchasesPanel.date')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('amount') ? (
							<TableHeaderColumn
								dataField="amount"
								dataAlign="center"
								width="200"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
								dataFormat={this.amountFormater}
							>
								{t('invisibleTouchManager.purchasesPanel.amount')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('state') ? (
							<TableHeaderColumn
								dataField="state"
								width="200"
								dataAlign="center"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
								dataFormat={this.stateFormater}
							>
								{t('invisibleTouchManager.purchasesPanel.state')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('approval') ? (
							<TableHeaderColumn
								width="600"
								dataAlign="center"
								dataField="approval"
								dataFormat={this.approvalFormater}
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
							>
								{t('invisibleTouchManager.invoicePanel.approval')}
							</TableHeaderColumn>
						) : null}
					</BootstrapTable>
				</>
			);
		}
		return table;
	};

	render() {
		const { t } = this.props;
		return (
			<Card body className={this.props.panel}>
				<CardTitle className="mb-0 text-muted">
					<i className="fa fa-file" />
					&nbsp;
					{t('invisibleTouchManager.invoicePanel.title')}
				</CardTitle>
				<Row
					style={{
						marginTop: '2rem'
					}}
				>
					<Col>{this.renderTable()}</Col>&nbsp;
				</Row>
			</Card>
		);
	}
}

export default withTranslation()(InvoicePanel);
