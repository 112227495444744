import React, { Component } from 'react';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';
// React select
import Select from 'react-select';
import '../../assets/scss/vendors/react-select/react-select.scss';
//Toast for alerts
import { toast } from 'react-toastify';
//Server resp axios

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

//Pörgős gomb
import 'ladda/dist/ladda-themeless.min.css';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { withTranslation } from 'react-i18next';

import VMApis from '../../services/api/machines';
import OperatorsApis from '../../services/api/operators';
import logger from '../../services/logger';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
	user: state.user
});

class EditOperator extends Component {
	constructor() {
		super();

		this.state = {
			email: '',
			expLeft: false, //a loding button-nak kell az állapot felméréséhez, hogy pörögjön e
			modal: false,
			success: false,
			selectValue: '',
			cmID: '',
			deleteID: '',
			viewLCDChecked: false,
			editVMChecked: false,
			showComponent: false,
			danger: false,
			vms: [],
			options: []
		};

		//Table options
		this.tableOption = {
			hideSizePerPage: true
		};
	}

	//Komponens betöltése
	componentDidMount = () => {
		this.getVMS();
		this.getOperatorVMS();
	};

	//Modal success
	toggleSuccess = () => {
		this.setState({
			success: !this.state.success
		});
	};

	//Modell show-hide
	toggleDanger = () => {
		this.setState({
			danger: !this.state.danger
		});
	};

	//Szükséges mező
	needKeyError = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('error.missingFields'));
	};

	//Ez az email cím már van
	emailExist = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('error.emailAlreadyExist'));
	};

	//Reg gomb event-je
	handleClick = () => {
		this.toggleSuccess();

		//Kiválasztott vmk
		var vms = this.state.selectValue;

		for (var i = 0; i < vms.length; i++) {
			this.postConnection(vms[i].value);
		}

		//Post OpAccess
		this.postOpAccess();

		//Frissíteni kell
		this.setState({ update: true });
	};

	//getOwnerVMS
	getVMS = async () => {
		var cmID = this.props.user.cmID;

		try {
			const response = await VMApis.getVmBycmID(cmID);
			const options = response.data.response;
			this.setState({ options });
		} catch (e) {
			logger.error(e);
		}
	};

	//getOperatorVMS
	getOperatorVMS = async () => {
		var cmID = this.props.user.cmID;
		var userID = this.props.match.params.userID;

		try {
			const response = await VMApis.getVMsByOperator(cmID, userID);
			if (response.data.hasOwnProperty('response')) {
				const vms = response.data.response;
				this.setState({
					vms,
					viewLCDChecked: vms[0].viewLCD === '1' ? true : false,
					editVMChecked: vms[0].editVM === '1' ? true : false
				});
			} else {
				this.setState({
					vms: [],
					viewLCDChecked: false,
					editVMChecked: false
				});
			}
		} catch (e) {
			logger.error(e);
		}
	};

	//postConnection
	postConnection = async vmID => {
		var cmID = this.props.user.cmID;
		var email = this.props.match.params.userEmail;

		try {
			await OperatorsApis.postOperatorConnections(cmID, vmID, email);
		} catch (e) {
			logger.error(e);
		}
	};

	//postOperatorAccess
	postOpAccess = async () => {
		const userID = this.props.match.params.userID;
		//checkboxes
		const editVM = this.state.editVMChecked;
		const viewLCD = this.state.viewLCDChecked;

		try {
			await OperatorsApis.postOperatorAccess(userID, editVM, viewLCD);
		} catch (error) {
			logger.error(error);
		}
	};

	//Delete VM confirm
	deleteConn = async () => {
		//Alerd hide
		this.toggleDanger();
		var connID = this.state.deleteID;

		try {
			//Delete operatorConn
			const response = await OperatorsApis.deleteOperatorConnection(connID);
			if (response.data) {
				this.getOperatorVMS();
				this.getVMS();
			}
		} catch (error) {
			logger.error(error);
		}
	};

	//Cancel
	cancel = () => {
		this.props.history.push('customerUsers');
	};

	//OK alaphelyzetbe állít
	cancel2 = () => {
		this.setState({
			selectValue: '',
			success: !this.state.success,
			expLeft: false
		});

		this.getOperatorVMS();
		this.getVMS();
	};

	//Selected update cmID
	updateValue = newValue => {
		this.setState({
			selectValue: newValue
		});
	};

	//deteleProfil
	deleteAction = connID => {
		//vmID tárolása
		this.setState({
			deleteID: connID
		});

		this.toggleDanger();
	};

	//Checked boxes

	//ViewLCD checked
	checkedViewLCD = () => {
		this.setState({
			viewLCDChecked: !this.state.viewLCDChecked
		});
	};

	//EditVM checked
	checkedEditVM = () => {
		this.setState({
			editVMChecked: !this.state.editVMChecked
		});
	};

	//TABLE FORMATTERS

	//Delete ikon klikk
	editFormatter = (cell, row) => {
		return (
			<Button onClick={e => this.deleteAction(cell)} id="editButton2">
				<i className="fa fa-trash" />
			</Button>
		);
	};

	//User ikon
	userFormatter = (cell, row) => {
		return <i className="icon-people" id="userIcon2" />;
	};

	//cmID list, selector View
	getCMView = () => {
		let options = this.state.options.map(category => ({
			value: category.vmID,
			label: category.cmID + ' - ' + category.vmID
		}));
		const { t } = this.props;
		return (
			<Col xs="10" sm="8" md="8" lg="12" xl="5">
				<Card>
					<CardHeader>
						<i className="icon-settings" />
						<b>{t('editoperator.title1')}</b>
					</CardHeader>
					<CardBody className="p-8">
						<Row>
							<BootstrapTable
								data={this.state.vms}
								version="4"
								striped
								hover
								options={this.tableOption}
								condensed
							>
								<TableHeaderColumn
									width="30"
									dataField=""
									dataAlign="center"
									dataFormat={this.userFormatter}
									thStyle={{
										backgroundColor: 'rgb(223,228,233)',
										fontSize: '13px'
									}}
									tdStyle={{ paddingTop: '15px' }}
								/>
								<TableHeaderColumn
									dataField="cmID"
									isKey
									thStyle={{
										backgroundColor: 'rgb(223,228,233)',
										fontSize: '13px'
									}}
									tdStyle={{
										paddingTop: '15px',
										whiteSpace: 'normal',
										fontSize: '13px'
									}}
								>
									Customer ID
								</TableHeaderColumn>
								<TableHeaderColumn
									width="100"
									dataField="vmID"
									dataSort
									thStyle={{
										backgroundColor: 'rgb(223,228,233)',
										fontSize: '13px'
									}}
									tdStyle={{
										paddingTop: '15px',
										whiteSpace: 'normal',
										fontWeight: 'bold',
										fontSize: '13px'
									}}
								>
									Vm ID
								</TableHeaderColumn>

								<TableHeaderColumn
									width="50"
									dataField="subConnID"
									dataFormat={this.editFormatter}
									thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								/>
							</BootstrapTable>
						</Row>
					</CardBody>
				</Card>
				<Card>
					<CardHeader>
						<i className="fa fa-plus" />
						<b>{t('editoperator.title2')}</b>
					</CardHeader>
					<CardBody className="p-8">
						<h6>vmID:</h6>
						<Row>
							<Col>
								<Select
									multi={true}
									name="form-field-name2"
									value={this.state.selectValue}
									onChange={this.updateValue}
									options={options}
									labelKey="label"
									valueKey="value"
									clearable={true}
								/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Col>
		);
	};

	//Previliges selector view
	getPrevView = () => {
		const { t } = this.props;
		const { viewLCDChecked, editVMChecked } = this.state;
		return (
			<Col xs="10" sm="8" md="8" lg="12" xl="5">
				<Card>
					<CardHeader>
						<i className="fa fa-lock" />
						<b>{t('creatoperator.card2.title')}</b>
					</CardHeader>
					<CardBody className="p-8">
						<Row>
							<Col xs="12">
								<FormGroup row>
									<Col md="3">
										<Label>{t('creatoperator.vmsprivilege.title')}</Label>
									</Col>
									<Col md="9">
										<FormGroup check className="checkbox">
											<Input
												className="form-check-input"
												type="checkbox"
												id="viewLCDChecked"
												name="viewLCDChecked"
												checked={viewLCDChecked}
												onChange={this.checkedViewLCD}
											/>
											<Label
												check
												className="form-check-label"
												htmlFor="viewLCDChecked"
											>
												{t('creatoperator.vmsprivilege.checkbox1')}
											</Label>
										</FormGroup>
										<FormGroup check className="checkbox">
											<Input
												className="form-check-input"
												type="checkbox"
												id="editVMChecked"
												name="editVMChecked"
												checked={editVMChecked}
												onChange={this.checkedEditVM}
											/>
											<Label
												check
												className="form-check-label"
												htmlFor="editVMChecked"
											>
												{t('creatoperator.vmsprivilege.checkbox2')}
											</Label>
										</FormGroup>
									</Col>
								</FormGroup>
							</Col>
						</Row>

						<FormGroup className="form-actions pt-4">
							<Button
								type="button"
								size="xl"
								color="danger"
								className="mr-3"
								onClick={this.cancel}
							>
								{t('setpromotion.modal.cancel')}
							</Button>

							<LaddaButton
								className="btn btn-success btn-ladda"
								loading={this.state.expLeft}
								data-style={EXPAND_LEFT}
								onClick={this.handleClick}
							>
								{t('userSettings.notiSetting.buttonLabel')}
							</LaddaButton>
						</FormGroup>
					</CardBody>
				</Card>
			</Col>
		);
	};

	render() {
		return (
			<div>
				<Modal
					isOpen={this.state.danger}
					toggle={this.toggleDanger}
					className={'modal-danger ' + this.props.className}
				>
					<ModalHeader toggle={this.toggleDanger}>Delete</ModalHeader>
					<ModalBody>Biztosan törlöd a VM-et?</ModalBody>
					<ModalFooter>
						<Button color="secundary" onClick={this.toggleDanger}>
							Mégsem
						</Button>
						<Button color="danger" onClick={this.deleteConn}>
							Igen
						</Button>{' '}
					</ModalFooter>
				</Modal>

				<Modal
					isOpen={this.state.success}
					toggle={this.toggleSuccess}
					className={'modal-success ' + this.props.className}
				>
					<ModalHeader toggle={this.toggleSuccess}>
						Sikeres szerkesztés
					</ModalHeader>
					<ModalBody>
						Operátor adatatinak módosítása sikeresen megtörtént
					</ModalBody>
					<ModalFooter>
						<Button color="success" onClick={this.cancel2}>
							OK
						</Button>{' '}
					</ModalFooter>
				</Modal>

				<Row>{this.getCMView()}</Row>
				<Row>{this.getPrevView()}</Row>
			</div>
		);
	}
}

export default connect(mapStateToProps)(
	withRouter(withTranslation()(EditOperator))
);
