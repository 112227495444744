import React from 'react';
import { withTranslation } from 'react-i18next';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { dateFromMYSQLTimeStamp } from '../../../services/util/dateUtil';
import VMUtil from '../../../services/util/vmUtil';
import EventListCardToolTip from '../../Dashboard/CardHandling/EventListCard/ToolTip/EventListTooltip';
import Popover from 'react-bootstrap/Popover';
import TableToolBar from '../../../components/SalesAndInomeCard/SalesAndIncomeTable/TableToolBar';
import classes from '../LogsCard/LogsCard.module.css';
import Spinner from '../../../components/UI/Spinner/Spinner';
import './Logstable.css';

const LogsTable = props => {
	const templateResolver = (templateString, templateVars) => {
		return new Function('return `' + templateString + '`;').call(templateVars);
	};

	const renderMalfunctionTooltip = (params, title) => {
		try {
			const parsedValues = JSON.parse(params.m);
			if (
				!parsedValues ||
				parsedValues.length <= 0 ||
				!parsedValues[0].display
			) {
				throw new Error('Incorrect error message');
			}
			return (
				<Tooltip
					style={{
						borderRadius: '10px',
						padding: '0px',
						overflow: 'hidden'
					}}
				>
					<EventListCardToolTip screens={parsedValues} />
				</Tooltip>
			);
		} catch (error) {
			return (
				<Popover id={'test'}>
					<Popover.Title as="h3">{title}</Popover.Title>
					<Popover.Content>{params.m}</Popover.Content>
				</Popover>
			);
		}
	};

	const renderBasicTooltip = (mappedParam, label, title) => {
		return (
			<Popover id={'test'}>
				<Popover.Title as="h3">{title}</Popover.Title>
				<Popover.Content>
					{new Function('return `' + label + '`;').call(mappedParam)}
				</Popover.Content>
			</Popover>
		);
	};

	const getStatusBadge = cell => {
		return props.getStatusBadge(cell);
	};

	const getEventLabel = (cell, row) => {
		const eventType = props.eventTypeSeriesSettings.find(
			curEventType => curEventType.typeId === cell
		);
		const label = eventType ? eventType.label : '';
		return <strong>{label}</strong>;
	};

	const getEventStringFromEventType = (eventTypeId, params) => {
		const eventType = props.eventTypeSeriesSettings.find(
			curEventType => curEventType.typeId === eventTypeId
		);
		let mappedParam = {};
		params.forEach(param => {
			mappedParam[param.parameterName] = param.parameterValue;
		});
		const label = eventType ? eventType.text : '';
		const title = eventType ? eventType.label : '';
		const id = eventType ? eventType.typeId : '';
		try {
			const resolvedText = templateResolver(label, mappedParam);
			let result = resolvedText;
			if (
				(id == 3 || id == 22 || id == 23) &&
				(mappedParam.m && mappedParam.m.length > 0)
			) {
				const tooltip = renderMalfunctionTooltip(mappedParam, title);
				result = (
					<OverlayTrigger
						placement="right"
						overlay={tooltip}
						trigger="click"
						rootClose
						transition={null}
					>
						<Badge
							className={id == 3 || id == 22 ? classes.MFBadge : classes.IBadge}
						>
							<i
								className="fa fa-book"
								style={{ padding: '2px', margin: 'auto' }}
							></i>
						</Badge>
					</OverlayTrigger>
				);
			} else {
				if (
					id == 0 ||
					id == 1 ||
					id == 4 ||
					id == 5 ||
					id == 6 ||
					id == 7 ||
					id == 16
				) {
					const tooltip = renderBasicTooltip(mappedParam, label, title);
					result = (
						<OverlayTrigger
							placement="right"
							overlay={tooltip}
							trigger="click"
							rootClose
							transition={null}
						>
							<Badge className={classes.IBadge}>
								<i
									className="fa fa-info-circle"
									style={{ padding: '2px', margin: 'auto' }}
								></i>
							</Badge>
						</OverlayTrigger>
					);
				} else {
					result = <></>;
				}
			}
			return <>{result}</>;
		} catch (error) {
			console.error('Could not resolve text: ' + label);
			return '';
		}
	};

	const dateFormatter = (cell, row) => {
		if (cell) {
			const dateFromTimeStamp = dateFromMYSQLTimeStamp(cell);
			const stringRep =
				dateFromTimeStamp.toLocaleDateString() +
				' ' +
				dateFromTimeStamp.toLocaleTimeString();

			return stringRep;
		} else {
			return cell;
		}
	};

	const eventTypeFormatter = (cell, row) => {
		return getEventStringFromEventType(cell, row.params);
	};

	const onRowClik = row => {
		props.history.push('vms/' + VMUtil.encodeVMID(row.machineId) + '/detailer');
	};

	const editFormatter = (cell, row) => {
		return (
			<>
				<Button
					onClick={e => onRowClik(row)}
					variant="success"
					className={classes.EditButton}
				>
					<i className="fa fa-external-link" />
				</Button>
			</>
		);
	};
	const createCustomToolBar = () => {
		return (
			<TableToolBar
				startDate={props.startDate}
				endDate={props.endDate}
				btnGroup={props.filterButton}
				onInputChange={props.onDateChange}
				loadButton={props.loadButton}
			/>
		);
	};
	const tableOptions = {
		hideSizePerPage: false,
		paginationSize: 3,
		sizePerPageList: [10],
		sizePerPage: props.recordPerPage || 100,
		withFirstAndLast: true,
		alwaysShowAllBtns: true,
		pageStartIndex: 1,
		paginationPosition: 'bottom',
		alwaysShowAllBtns: false,
		clearSearch: true,
		hidePageListOnlyOnePage: true,
		sortName: 'date',
		sortOrder: 'desc',
		toolBar: createCustomToolBar
	};

	return props.loading ? (
		<Spinner />
	) : (
		<>
			<BootstrapTable
				data={props.data}
				version="4"
				striped
				hover
				pagination={!props.hidePager}
				insertRow={false}
				options={tableOptions}
				multiColumnSearch
				condensed
				bordered={false}
				dataAlign={'center'}
				size="sm"
				key="index"
				headerContainerClass={classes.HeaderContainerClass}
				tableHeaderClass={classes.TableHeaderClass}
			>
				<TableHeaderColumn
					isKey
					width="1"
					dataAlign="center"
					dataField="index"
					key="index"
					hidden
					thStyle={{
						borderColor: 'transparent'
					}}
					tdStyle={{ paddingTop: '15px' }}
				>
					{'Index'}
				</TableHeaderColumn>

				<TableHeaderColumn
					width="100"
					dataAlign="center"
					dataField="eventType"
					dataFormat={getStatusBadge}
					thStyle={{
						borderColor: 'transparent'
					}}
					tdStyle={{ paddingTop: '10px' }}
				/>

				<TableHeaderColumn
					width="250"
					dataAlign="left"
					dataField="eventType"
					dataFormat={getEventLabel}
					thStyle={{
						borderColor: 'transparent'
					}}
					tdStyle={{ paddingTop: '15px' }}
					dataSort
				>
					{props.t('eventList.table.eventType')}
				</TableHeaderColumn>

				<TableHeaderColumn
					width="250"
					dataAlign="left"
					dataField="date"
					dataFormat={dateFormatter}
					thStyle={{
						borderColor: 'transparent'
					}}
					tdStyle={{ paddingTop: '15px' }}
					dataSort
				>
					{props.t('eventList.table.date')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="250"
					dataAlign="left"
					dataField="machineName"
					thStyle={{
						borderColor: 'transparent'
					}}
					tdStyle={{ paddingTop: '15px' }}
					dataSort
				>
					{props.t('eventList.table.machine')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="300"
					dataAlign="left"
					dataField="eventType"
					dataFormat={eventTypeFormatter}
					thStyle={{
						borderColor: 'transparent'
					}}
					tdStyle={{ paddingTop: '10px' }}
				>
					{props.t('eventList.table.details')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="200"
					dataAlign="right"
					dataField="userID"
					dataFormat={editFormatter}
					thStyle={{
						borderColor: 'transparent'
					}}
					hidden={props.hideLink}
				>
					{props.t('eventList.table.machineDetails')}
				</TableHeaderColumn>
			</BootstrapTable>
		</>
	);
};

export default withTranslation()(LogsTable);
