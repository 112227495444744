//@flow
import React from 'react'

import { Col, Row } from 'reactstrap'

import styled from 'styled-components'

import { MachineButton } from '../../../../../components/Machines/MachineComponents'
const ColP2 = styled(Col)`
  padding: 2px;
`

const Klix900KeyboardHeader = ({
  onButtonClick = (key: string) => async () => {},
}: {
  onButtonClick: (key: string) => () => Promise<void>,
}) => (
  <Row>
    <ColP2 xs="3" sm="3" md="3" lg="3" xl="3">
      <MachineButton style={{ height: '100%' }} onClick={onButtonClick('17')}>
        <i className="fa fa-long-arrow-left mr-1" />
      </MachineButton>
    </ColP2>
    <ColP2 xs="3" sm="3" md="3" lg="3" xl="3">
      <Row>
        <Col>
          <MachineButton onClick={onButtonClick('18')}>
            <i className="fa fa-long-arrow-up mr-1" />
          </MachineButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <MachineButton onClick={onButtonClick('20')}>
            <i className="fa fa-long-arrow-down mr-1" />
          </MachineButton>
        </Col>
      </Row>
    </ColP2>
    <ColP2 xs="3" sm="3" md="3" lg="3" xl="3">
      <MachineButton style={{ height: '100%' }} onClick={onButtonClick('19')}>
        <i className="fa fa-long-arrow-right mr-1" />
      </MachineButton>
    </ColP2>
    <ColP2 xs="3" sm="3" md="3" lg="3" xl="3">
      <Row>
        <Col>
          <MachineButton onClick={onButtonClick('28')}>
            <i className="fa fa-info" aria-hidden="true"></i>
          </MachineButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <MachineButton onClick={onButtonClick('22')}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </MachineButton>
        </Col>
      </Row>
    </ColP2>
  </Row>
)

export default Klix900KeyboardHeader
