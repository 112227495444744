import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';

class HVendTechnicanDashboard extends Component {
	render() {
		return (
			<Switch>
				<Redirect from="/" to="/hardwareManagement" />
				<Redirect from="/dashboard" to="/hardwareManagement" />
			</Switch>
		);
	}
}

export default HVendTechnicanDashboard;
