import { Button } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import React, { Component } from 'react';
import NewDeviceForm from './NewDeviceForm';
import { withTranslation } from 'react-i18next';

class NewDeviceButton extends Component {
	state = {};
	showAddDialog() {
		this.setState({ showAddPromotionDialog: true });
	}

	hideAddDialog() {
		this.setState({ showAddPromotionDialog: false });
	}

	saveHandling = () => {
		this.setState({ showAddPromotionDialog: false }, () => this.props.onSave());
	};

	renderAddPromotionrDialog = () => {
		return (
			<Modal
				show={this.state.showAddPromotionDialog}
				onHide={() => this.hideAddDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i className="fa fa-bullhorn" style={{ marginRight: '10px' }}></i>
						{this.props.t('newDeviceForm.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<NewDeviceForm onSave={() => this.saveHandling()} />
				</Modal.Body>
			</Modal>
		);
	};
	render() {
		return (
			<div>
				{this.renderAddPromotionrDialog()}
				<Button
					color="success"
					className="DefaultButton"
					onClick={() => this.showAddDialog()}
				>
					<i className="fa fa-plus mr-2" aria-hidden="true"></i>&nbsp;
					{this.props.t('filterToolbar.add')}
				</Button>
			</div>
		);
	}
}

export default withTranslation()(NewDeviceButton);
