import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './EditMachineGroupForm.module.css';

import { Row, Col } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import MachineSelectedTable from '../AddMachineGroupForm/MachineSelectedTable';
import MachineSelectorTable from '../AddMachineGroupForm/MachineSelectorTable';
import HelperTooltip from '../../../../components/UI/HelperTooltip/HelperTooltip';

import groupApi from '../../../../services/api/machineGroup';
import Spinner from '../../../../components/UI/Spinner/Spinner';

class EditMachineGroupForm extends Component {
	state = {
		editedGroup: {
			machines: [],
			name: '',
			description: '',
			creationDate: new Date()
		},
		allMachines: [],
		groupMachines: [],
		validated: false,
		ready: false
	};

	componentDidMount() {
		groupApi.getAssignedVms(this.props.group.id).then(vm => {
			const allMachines = { ...this.props.allMachines };
			const group = { ...this.props.group };
			const resID = vm.data.vms.map(e => ({ vmID: e.vmID }));
			const groupMachines = this.loadData(allMachines, resID);
			this.setState({
				groupMachines: groupMachines,
				editedGroup: group,
				allMachines: allMachines,
				ready: true
			});
		});
	}

	loadData = (allMachines, resID) => {
		let am = Object.values(allMachines);
		let groupMachines = [];
		for (let i = 0; i < am.length; i++) {
			for (let j = 0; j < resID.length; j++) {
				if (am[i].vmID === resID[j].vmID) {
					groupMachines.push(am[i]);
				}
			}
		}
		return groupMachines;
	};

	inputChangeHandler = event => {
		let editedGroup = { ...this.state.editedGroup };
		editedGroup[event.target.name] = event.target.value;
		this.setState({ editedGroup: editedGroup });
	};

	selectedMachines = value => {
		if (this.state.groupMachines.length !== value.length) {
			let valueGroupMachines = { ...this.state.groupMachines };
			valueGroupMachines = value;
			this.setState({ groupMachines: valueGroupMachines });
		}
	};

	loadSelectorTabel = value => {
		let machineSelector = null;
		if (value) {
			machineSelector = (
				<>
					<MachineSelectorTable
						height={this.props.selected ? '180' : '450'}
						selected={this.state.groupMachines}
						data={this.props.allMachines}
						onSelectedMachines={this.selectedMachines}
					/>
				</>
			);
		} else {
			machineSelector = <Spinner />;
		}
		return machineSelector;
	};

	loadSelectedTabel = () => {
		return (
			<>
				<MachineSelectedTable data={this.state.groupMachines} height="180" />
			</>
		);
	};

	onSubmitHandler = () => {
		let vms = [...this.state.groupMachines];
		let vmids = vms.map(vm => {
			return vm.vmID;
		});
		let vmIDS = vmids.join(',');
		let gID = this.props.group.id;
		let group = this.state.editedGroup;
		groupApi.updateGroup(gID, group.name, group.description).then(request => {
			groupApi.setAssignedVms(gID, vmIDS).then(request => this.props.onHide());
		});
	};

	validationHandler = () => {
		const form = document.getElementById('editMachinegroupForm');
		if (
			form.checkValidity() === true &&
			this.state.groupMachines &&
			this.state.groupMachines.length > 0
		) {
			this.onSubmitHandler();
		} else {
			this.setState({ validated: true });
		}
	};

	//Render-----------------------------------------------------------------

	render() {
		const { t } = this.props;
		return (
			<Form
				noValidate
				validated={this.state.validated}
				id={'editMachinegroupForm'}
			>
				<Row>
					<Col>
						<Form.Group
							className={classes.FormGroup}
							controlId="name"
							style={{ padding: '15px' }}
						>
							<Row style={{ alignItems: 'flex-start' }}>
								<Form.Label className={classes.Label}>
									{t('groupManager.addGroup.listName.title')}
								</Form.Label>
								<HelperTooltip
									height={'100px'}
									width={'250px'}
									direction={'right'}
									id={'name'}
									title={t('groupManager.addGroup.listName.tooltip.title')}
									content={t('groupManager.addGroup.listName.tooltip.content')}
								></HelperTooltip>
							</Row>
							<Form.Control
								required
								name="name"
								type="text"
								value={this.state.editedGroup.name}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group
							className={classes.FormGroup}
							controlId="description"
							style={{ padding: '15px' }}
						>
							<Row style={{ alignItems: 'flex-start' }}>
								<Form.Label className={classes.Label}>
									{t('groupManager.addGroup.listDescription.title')}
								</Form.Label>
								<HelperTooltip
									height={'100px'}
									width={'250px'}
									direction={'right'}
									id={'groupDescription'}
									title={t(
										'groupManager.addGroup.listDescription.tooltip.title'
									)}
									content={t(
										'groupManager.addGroup.listDescription.tooltip.content'
									)}
								></HelperTooltip>
							</Row>
							<Form.Control
								required
								name="description"
								type="text"
								value={this.state.editedGroup.description}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Form.Group
					className={classes.FormGroupTable}
					controlId="addNewMachines"
					style={{ padding: '15px' }}
				>
					<Row style={{ alignItems: 'flex-start' }}>
						<Form.Label className={classes.Label}>
							{t('groupManager.addGroup.selectorTable.title')}
						</Form.Label>
						<HelperTooltip
							height={'100px'}
							width={'400px'}
							direction={'right'}
							id={'machineSelector'}
							title={t('groupManager.addGroup.selectorTable.tooltip.title')}
							content={t(
								'groupManager.addGroup.selectorTable.tooltip.device.content'
							)}
						></HelperTooltip>
					</Row>
					{this.loadSelectorTabel(this.state.ready)}
				</Form.Group>
				{this.props.selected ? (
					<Form.Group
						className={classes.FormGroupTable}
						controlId="NewMachineGroup"
						style={this.props.style}
					>
						<Form.Label className={classes.Label}>
							<strong>{t('groupManager.addGroup.selectedTable')}</strong>
						</Form.Label>
						{this.loadSelectedTabel()}
					</Form.Group>
				) : null}
				<hr></hr>
				<Button
					variant="success"
					className="DefaultButton"
					onClick={() => this.validationHandler()}
				>
					<i className="fa fa-floppy-o mr-2" />
					&nbsp;
					{t('groupManager.addGroup.savebutton')}
				</Button>
				{this.state.validated &&
				this.state.groupMachines &&
				this.state.groupMachines.length === 0 ? (
					<Form.Label style={{ color: 'red', marginTop: '10px' }}>
						{t('groupManager.addGroup.validationError')}
					</Form.Label>
				) : null}
			</Form>
		);
	}
}

export default withTranslation()(EditMachineGroupForm);
