import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import React from 'react';

class DeviceDetailsTabs extends React.Component {
	state = {
		activeTab: 0
	};

	renderTab = (child, key) => {
		const { title, tabClassName } = child.props;
		if (title == null) {
			return null;
		}

		return (
			<NavItem key={key} className={tabClassName}>
				<NavLink
					active={this.props.activetab === key}
					onClick={() => this.props.onChangeActivePage(key)}
					style={
						this.props.activetab === key
							? {
									fontWeight: 'bold'
							  }
							: null
					}
				>
					{title}
				</NavLink>
			</NavItem>
		);
	};

	render() {
		const { children, ...props } = this.props;
		return (
			<div>
				<Nav {...props} role="tablist" as="nav" tabs>
					{children.filter(child => child).map(this.renderTab)}
				</Nav>
				<TabContent activeTab={this.props.activetab}>
					{children
						.filter(child => child)
						.map((child, key) => {
							const { children, ...childProps } = child.props;
							delete childProps.title;
							delete childProps.disabled;
							delete childProps.tabClassName;

							return (
								<TabPane
									tabId={key}
									key={key}
									children={this.props.activetab === key ? children : null}
									{...childProps}
								/>
							);
						})}
				</TabContent>
			</div>
		);
	}
}

export default DeviceDetailsTabs;
