//@flow
import React from 'react'

import { Col, Row } from 'reactstrap'

import styled from 'styled-components'

import {
  MachineButton,
  MachineLabel as BaseMachineLabel,
} from '../../../../../components/Machines/MachineComponents'

const ButtonLed = styled.i`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: small;
  color: ${props => (props.isLedOn ? 'lime' : 'black')};
`

const MachineLabel = styled(BaseMachineLabel)`
  min-width: 100%;
  height: 100%;
`

const ColP2 = styled(Col)`
  padding: 2px;
`

const Klix900ButtonGroup = ({
  buttonID,
  buttonContent,
  labelContent,
  isLedOn = false,
  onButtonClick = (key: string) => async () => {},
}: {
  buttonID: string,
  buttonContent: any,
  labelContent: any,
  isLedOn?: boolean,
  onButtonClick: (key: string) => () => Promise<void>,
}) => (
  <>
    <Row>
      <ColP2 xs="4" sm="4" md="4" lg="4" xl="4">
        <MachineButton>
          <i className="fa fa-long-arrow-up mr-1" />
        </MachineButton>
      </ColP2>
      <ColP2 xs="4" sm="4" md="4" lg="4" xl="4">
        <MachineButton onClick={onButtonClick(buttonID)}>
          {buttonContent}
          <ButtonLed isLedOn={isLedOn} className="fa fa-circle" />
        </MachineButton>
      </ColP2>
      <ColP2 xs="4" sm="4" md="4" lg="4" xl="4">
        <MachineButton>
          <i className="fa fa-long-arrow-down mr-1" />
        </MachineButton>
      </ColP2>
    </Row>
    <Row
      style={{
        flex: '1',
        display: 'flex',
        minWidth: '100%',
      }}
    >
      <ColP2>
        <MachineLabel>{labelContent}</MachineLabel>
      </ColP2>
    </Row>
  </>
)

export default Klix900ButtonGroup
