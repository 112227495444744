import React, { Component } from 'react';
import { Card } from 'reactstrap';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';
import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import { withTranslation } from 'react-i18next';
import HardwareCard from './HardwareCard';

class HardwareRiport extends Component {
	render() {
		const { t } = this.props;
		return (
			<div className="animated fadeIn">
				<DashboardCardContainerWithFlow>
					<Card body>
						<PageTiltleCard title={this.props.t('hardwareRiport.title')} />
						<HardwareCard />
					</Card>
				</DashboardCardContainerWithFlow>
			</div>
		);
	}
}

export default withTranslation()(HardwareRiport);
