import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
// import Toggle from 'react-bootstrap-toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import PLChart from './PLChart';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import machineAPI from '../../../../services/api/machines';
import statistics from '../../../../services/api/statistics';
import { getDate } from '../../../../services/util/dateUtil';

class ProductLineChart extends Component {
	state = { showMonth: false, visibleDayCount: 31 };
	componentDidMount() {
		// this.loadTestData();
		this.setData();
	}
	setData = () => {
		this.loadMachines();
	};

	loadMachines = async () => {
		let vmId = await machineAPI.getVisibleVendingMachines().then(res =>
			res.data.response
				.filter(e => e.telemetryServiceEnabled === 'Y')
				.map(e => {
					return e.vmID;
				})
		);
		let startDate = getDate(-1);
		let endDate = getDate();

		this.loadData(startDate, endDate, vmId);
	};

	loadData = (startDate, endDate, vmId) => {
		let param = {
			fromDate: startDate,
			toDate: endDate,
			machinesIDString: vmId.join()
		};
		statistics
			.getSalesandIncome(param)
			.then(res => this.formatData(res.data))
			.catch(err => console.log(err));
	};

	mapDate = (data, dateFormat) => {
		let d = data;
		d.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));
		d.forEach(e => {
			e.createdAt = moment(e.createdAt).format(dateFormat);
		});
		return d;
	};

	groupBy = (array, key) => {
		return array.reduce((rv, x) => {
			(rv[x[key]] = rv[x[key]] || []).push(x);

			return rv;
		}, {});
	};

	generateRealDate = value => {
		const ARRAY_LENGTH = value;
		const date = [];
		let d = new Date();
		let month = d.getMonth();
		let year = d.getFullYear();

		for (let i = 0; i < ARRAY_LENGTH; i++) {
			date.push(moment(new Date(year - 1, month - 2, i + 1)));
		}
		return date;
	};

	formatData = value => {
		let formatData = [];
		formatData = value
			.filter(e => e.productName != null)
			.filter(e => e.productName != '');
		let maptoDate = this.mapDate(formatData, 'YYYY.MM.DD').map(e => {
			return {
				createdAt: e.createdAt,
				productName: e.productName
			};
		});

		let date = this.generateRealDate(this.state.visibleDayCount);

		let groupedByProduct = Object.values(
			this.groupBy(maptoDate, 'productName')
		).map(e => {
			const name = e.map(el => el.productName)[0];

			let v = [];

			for (let i = 0; i < date.length; i++) {
				v.push([]);
			}

			let value = [];

			Object.values(this.groupBy(e, 'createdAt')).map(elem => {
				elem.map(el => {
					for (let i = 0; i < date.length; i++) {
						if (date[i].format('YYYY.MM.DD') === el.createdAt) {
							v[i].push(el.createdAt);
						}
					}
				});
			});

			value.push(
				v.map(e => {
					return e.length;
				})
			);

			return {
				name: name,
				values: value[0],
				average: this.average(value[0])
			};
		});

		let data = this.shortByAverage({
			y: 'DB',
			series: groupedByProduct,
			dates: date
		});
		this.setState({ data: data });
	};

	//------------ test data ----------------

	loadTestData = () => {
		let data = [];
		data = this.shortByAverage(
			this.withAverage(this.generateTestData(this.state.visibleDayCount))
		);
		this.setState({ data: data });
	};
	generateValue = () => {
		const ARRAY_LENGTH = 31;
		const randomArray = [];
		const randomNum = i => {
			let a = Math.random().toFixed(2) * 100;
			let b = Math.random().toFixed(2) * 100;
			return Math.floor((a - 50 + (b - 50)) / 2);
		};
		for (let i = 0; i < ARRAY_LENGTH; i++) {
			let r = randomNum(i);
			if (r < 0) {
				r = 0;
			}
			randomArray.push(r);
		}
		return randomArray;
	};
	generateDate = value => {
		const ARRAY_LENGTH = value;
		const date = [];
		let d = new Date();
		let month = d.getMonth();
		let year = d.getFullYear();

		for (let i = 0; i < ARRAY_LENGTH; i++) {
			date.push(moment(new Date(year, month, i + 1)));
		}
		return date;
	};

	average = arr => arr.reduce((a, b) => a + b, 0) / arr.length;

	withAverage = data => {
		return {
			y: data.y,
			series: data.series.map(e => {
				return {
					name: e.name,
					values: e.values,
					average: this.average(e.values)
				};
			}),
			dates: data.dates
		};
	};
	shortByAverage = withAverage => {
		return {
			y: withAverage.y,
			series: withAverage.series
				.sort((a, b) => {
					return b.average - a.average;
				})
				.slice(0, 10),

			dates: withAverage.dates
		};
	};

	generateTestData = day => {
		return {
			y: 'DB',
			series: [
				{
					name: 'Esspresso',
					values: this.generateValue()
				},
				{
					name: 'Americano',
					values: this.generateValue()
				},
				{
					name: 'Latte',
					values: this.generateValue()
				},
				{
					name: 'Tea',
					values: this.generateValue()
				},
				{
					name: 'Chapucchino',
					values: this.generateValue()
				},
				{
					name: 'Chocco',
					values: this.generateValue()
				},
				{
					name: 'Ristretto',
					values: this.generateValue()
				},
				{
					name: 'Esspresso2',
					values: this.generateValue()
				},
				{
					name: 'Americano2',
					values: this.generateValue()
				},
				{
					name: 'Latte2',
					values: this.generateValue()
				},
				{
					name: 'Tea2',
					values: this.generateValue()
				},
				{
					name: 'Chapucchino2Chapucchino2',
					values: this.generateValue()
				},
				{
					name: 'Chocco2',
					values: this.generateValue()
				},
				{
					name: 'Ristretto2',
					values: this.generateValue()
				}
			],
			dates: this.generateDate(day)
		};
	};

	//------------ test data ----------------

	chart = () => {
		const { t } = this.props;
		let chartType = <Spinner />;
		if (this.state.data) {
			chartType = (
				<PLChart data={this.state.data} days={this.state.visibleDayCount} />
			);
		}
		return chartType;
	};

	render() {
		console.log('state.', this.state);
		const { t } = this.props;
		return (
			<Card className={this.props.className} style={this.props.style}>
				<CardBody>
					<Row>
						<Col sm="4">
							<CardTitle className="mb-0 text-muted">
								<i
									className="fa fa-line-chart fa-lg"
									style={{ marginRight: '10px' }}
								></i>
								{'A legnépszerűbb termékek'}
								{/* {t('customerDashboard.income.title')} */}
							</CardTitle>

							<div className="small text-muted">
								{this.state.visibleDayCount === 7
									? t('customerDashboard.income.subTitle.daily7')
									: t('customerDashboard.income.subTitle.daily31')}
							</div>
						</Col>
						<Col sm="4"></Col>
						<Col sm="4"></Col>
					</Row>
					<div
						className="chart-wrapper"
						style={{ height: 300 + 'px', marginTop: '10px' }}
					>
						{this.chart()}
					</div>
				</CardBody>
			</Card>
		);
	}
}

export default withTranslation()(ProductLineChart);
