import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import VisibilitySensor from 'react-visibility-sensor';
import GoogleMapsComponent from '../../Maps/GoogleMapsComponent';
import machineAPi from '../../../../services/api/machines';

class MapViewCard extends Component {
	state = { machines: [] };
	componentDidMount() {
		if (!this.props.machine) {
			machineAPi.getVisibleVendingMachines().then(resp => {
				this.setState({ machines: resp.data.response });
			});
		}
	}

	render() {
		const { t } = this.props;

		return (
			<VisibilitySensor
				onChange={isVisible => {
					if (isVisible) {
						this.setState({ mapVisible: true });
					}
				}}
			>
				<Card className={this.props.className} style={this.props.style}>
					<CardBody>
						<Row>
							<Col sm="5">
								<CardTitle className="mb-0 text-muted">
									<i
										className="fa fa-map fa-lg"
										style={{ marginRight: '10px' }}
									></i>
									{t('mapView.machineLocations')}
								</CardTitle>

								<div className="small text-muted"></div>
							</Col>
						</Row>
						<div
							className="chart-wrapper"
							style={{ height: '400px', marginTop: '10px' }}
						>
							{this.state.mapVisible ? (
								<GoogleMapsComponent
									machines={
										this.props.machine
											? [{ ...this.props.machine }]
											: this.state.machines
									}
								></GoogleMapsComponent>
							) : null}
						</div>
					</CardBody>
				</Card>
			</VisibilitySensor>
		);
	}
}

export default withTranslation()(MapViewCard);
