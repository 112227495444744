import { withTranslation } from 'react-i18next';

import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import FilterBar from '../../../FilterBar/FilterBar';

class MachineSelectedTable extends Component {
	state = {
		selected: [],
		data: []
	};

	componentDidMount() {
		this.setState({ data: this.props.data });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.data !== this.props.data) {
			this.setState({ data: this.props.data });
		}
		if (prevState.data !== this.state.data) {
			this.filterData();
		}
	}

	isColumnsVisibile = columName => {
		return (
			!this.props.hiddenColumns ||
			this.props.hiddenColumns.indexOf(columName) < 0
		);
	};

	userFormatter = (cell, row) => {
		return <i className="fa fa-calculator" id="userIcon" />;
	};

	typeFormatter = (cell, row) => {
		if (cell === 'coffe') {
			return (
				<p>
					<i className="fa fa-coffee mr-2 " />
					{cell}
				</p>
			);
		} else if (cell === 'snack') {
			return (
				<p>
					<i className="fa fa-cutlery mr-2 " />
					{cell}
				</p>
			);
		} else if (cell === 'bottle') {
			return (
				<p>
					<i className="fa fa-glass mr-2 " />
					{cell}
				</p>
			);
		} else {
			//---Típus logikát még meg kell csinálni. Dummy beállítás tesztadatokhoz:
			return (
				<p>
					<i className="fa fa-coffee mr-2 " />
					{'coffee'}
				</p>
			);
			//---Slave eszközökhöz esetleges beállítás:
			// return <p><i className="fa fa-columns mr-2 " />
			//     {cell}</p>
		}
	};

	renderFilterBar = () => {
		return (
			<FilterBar
				key="filterbar"
				onFilterChanged={value => this.onFilterChange(value)}
			/>
		);
	};

	filterData() {
		let filteredData = [...this.state.data];
		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredData = this.state.data.filter(data => {
				let curData = { ...data };
				let promotionFieldArray = Object.values(curData);
				return promotionFieldArray.some(
					val =>
						String(val)
							.toUpperCase()
							.indexOf(filterValue) >= 0
				);
			});
		}

		this.setState({ filteredData: filteredData });
	}

	onFilterChange(filteValue) {
		this.setState({ currentFilterValue: filteValue }, () => this.filterData());
	}

	backgroundColorFormater = () => {
		let bgColor = null;
		if (this.props.bgColor) {
			bgColor = { backgroundColor: this.props.bgColor };
		}
		return bgColor;
	};

	//Table options

	render() {
		const tableOptions = {
			sortIndicator: true,
			hideSizePerPage: true,
			withFirstAndLast: true,
			alwaysShowAllBtns: false,
			clearSearch: true,
			hidePageListOnlyOnePage: true,
			sortName: 'vmID',
			sortOrder: 'asc',
			searchPanel: this.renderFilterBar,
			onSearchChange: this.onFilterChange
		};
		const { t } = this.props;
		return (
			<>
				{!this.props.hideToolbar ? this.renderFilterBar() : null}
				<BootstrapTable
					key="bootstrapTable"
					data={this.state.filteredData}
					version="4"
					hover
					insertRow={false}
					options={tableOptions}
					multiColumnSearch
					condensed
					bordered={false}
					size="sm"
					height={this.props.height}
					trStyle={this.backgroundColorFormater()}
				>
					{this.isColumnsVisibile('vmID') ? (
						<TableHeaderColumn
							isKey
							width="30"
							keyField="vmID"
							dataAlign="center"
							dataField="vmID"
							dataFormat={this.userFormatter}
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								backgroundColor: this.props.backgroundColor
							}}
						/>
					) : null}
					{this.isColumnsVisibile('name') ? (
						<TableHeaderColumn
							dataField="name"
							keyField="vmID"
							width="100"
							dataAlign="center"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal',
								fontWeight: 'bold',
								backgroundColor: this.props.backgroundColor
							}}
						>
							{t('groupManager.selectorTable.deviceName')}
						</TableHeaderColumn>
					) : null}

					{this.isColumnsVisibile('address') ? (
						<TableHeaderColumn
							dataField="address"
							keyField="vmID"
							width="120"
							dataAlign="center"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal',
								backgroundColor: this.props.backgroundColor
							}}
						>
							{t('groupManager.selectorTable.location')}
						</TableHeaderColumn>
					) : null}
					{this.isColumnsVisibile('type') ? (
						<TableHeaderColumn
							dataField="type"
							keyField="vmID"
							dataAlign="center"
							width="70"
							dataSort
							dataFormat={this.typeFormatter}
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal',
								backgroundColor: this.props.backgroundColor
							}}
						>
							{t('groupManager.selectorTable.type')}
						</TableHeaderColumn>
					) : null}
					{this.isColumnsVisibile('name') ? (
						<TableHeaderColumn
							dataField="name"
							keyField="vmID"
							dataAlign="center"
							width="120"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal',
								backgroundColor: this.props.backgroundColor
							}}
						>
							{t('groupManager.selectorTable.account')}
						</TableHeaderColumn>
					) : null}
				</BootstrapTable>
			</>
		);
	}
}

export default withTranslation()(MachineSelectedTable);
