import React, { Component } from 'react'
import { Progress } from 'reactstrap'
import getPercent from './percent'

function getColor(percent) {
  if (percent < 25) {
    return 'danger'
  } else if (percent < 75) {
    return 'warning'
  }

  return 'success'
}

class Stock extends Component {
  render() {
    const { maxCount, currentCount } = this.props
    const percent = getPercent(currentCount, maxCount)
    return (
      <div className="my-2">
        <div>
          <i className="fa fa-cubes"></i>&nbsp;&nbsp;{percent}%
        </div>
        <div className="mt-1">
          <Progress
            className="progress-xs bg-secondary"
            color={getColor(percent)}
            value={percent}
          />
        </div>
      </div>
    )
  }
}

export default Stock
