import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import {
	Section,
	SectionSmallTitle,
	SectionSubTitle,
	SectionText
} from '../../../../components/Section';
import { withTranslation } from 'react-i18next';

import ConfimationDialog from '../../../ConfirmationDialog/ConfirmationDialog';
import classes from './Datasheet.module.css';
import CustomPeripherialsCard from './CustomPeripherialsCard';

class SlaveSheet extends Component {
	state = {
		saveInProgress: false,
		editMode: null,
		peripherial: [],
		protocol: null,
		waterHardnes: null,
		softReplaced: null,
		filterReplaced: null,
		capacity: null
	};
	// targetRef = React.createRef();
	componentDidMount() {
		let peripherial = { ...this.props.peripherial };

		this.setState({
			peripherial: peripherial
		});
	}
	hardwareNameFormater = idx => {
		return 'Slave ' + idx + ' ';
	};

	hideConfirmationDialog() {
		this.setState({
			deletePeripheralConfirmationVisible: false
		});
	}

	showDeleteConfirmation() {
		this.setState({
			deletePeripheralConfirmationVisible: true
		});
	}

	renderDeleteDeviceConfirmationDialog = () => {
		return (
			<ConfimationDialog
				title="Confirmation"
				confirmationText={
					<>
						<Col>
							{this.props.t('device.details.datasheet.peripherals.delConf')}
						</Col>
						<Col>
							<Row>
								<Col>
									{this.props.t('device.details.datasheet.peripherals.delPer')}
								</Col>
								<Col style={{ fontWeight: 'bold' }}>
									{this.hardwareNameFormater(this.props.index)}
								</Col>
							</Row>
						</Col>
					</>
				}
				okText={this.props.t('device.details.invisibleTouch.ok')}
				cancelText={this.props.t('device.details.invisibleTouch.cancel')}
				isOpen={this.state.deletePeripheralConfirmationVisible}
				onToggle={() => {
					this.hideConfirmationDialog();
				}}
				onCancel={() => {
					this.hideConfirmationDialog();
				}}
				onOk={() => {
					this.hideConfirmationDialog();
					this.props.onDelet(this.props.peripherial.id);
				}}
			/>
		);
	};

	renderSlavePeriperials = value => {
		const {
			root,
			companyAdmin,
			regionalAdmin,
			peripherial,
			index
		} = this.props;
		return (
			<CustomPeripherialsCard
				vmId={this.props.vmId}
				machine={this.props.machine}
				root={root}
				companyAdmin={companyAdmin}
				regionalAdmin={regionalAdmin}
				slave={peripherial.prefix}
				name={this.hardwareNameFormater(index)}
			/>
		);
	};

	onHideHandle = () => {
		this.setState({ showSlaveperipherials: false });
		this.props.hide();
	};

	render() {
		const { peripherial, t, root, index } = this.props;
		return (
			<>
				{this.renderDeleteDeviceConfirmationDialog()}

				<Section className={classes.SubTitleRow}>
					<Row
						style={{
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<Col xs={12} sm={6} md={12} lg={6}>
							<SectionSubTitle>
								{index
									? this.hardwareNameFormater(index)
									: t('device.details.datasheet.noData')}
							</SectionSubTitle>
						</Col>
						{root ? (
							<Col xs={12} sm={6} md={12} lg={6}>
								<Row
									style={{ alignItems: 'center', justifyContent: 'flex-end' }}
								>
									<Button
										color="primary"
										className="DefaultButton"
										onClick={() => this.showDeleteConfirmation()}
									>
										<i className={'fa fa-trash mr-2'}></i>
										{t('partnerManagement.customerGeneral.delete')}
									</Button>
								</Row>
							</Col>
						) : null}
					</Row>
				</Section>
				<Section last>
					<Section last>
						<SectionSmallTitle>
							{t('device.details.datasheet.nfd.nfdData.slave.prefix.title')}
						</SectionSmallTitle>
						<SectionText>
							{peripherial.prefix || t('device.details.datasheet.noData')}
						</SectionText>
					</Section>
					<Section last>
						<SectionSmallTitle>
							{t('device.details.datasheet.nfd.nfdData.slave.vendor.title')}
						</SectionSmallTitle>
						<SectionText>
							{peripherial.vendor || t('device.details.datasheet.noData')}
						</SectionText>
					</Section>
					<Section last>
						<SectionSmallTitle>
							{t(
								'device.details.datasheet.nfd.nfdData.slave.machineType.title'
							)}
						</SectionSmallTitle>

						<SectionText>
							{peripherial.machineType || t('device.details.datasheet.noData')}
						</SectionText>
					</Section>
					{!this.state.showSlaveperipherials ? (
						<Section last>
							<Row style={{ justifyContent: 'flex-end' }}>
								<label
									className="mr-3"
									style={{
										display: 'felx',
										alignItems: 'center',
										justifyContent: 'center',
										fontWeight: 'bold',
										fontSize: 'large'
									}}
								>
									{this.hardwareNameFormater(index)}
								</label>
								<Button
									color="info"
									className="DefaultButtonXl"
									onClick={() => this.setState({ showSlaveperipherials: true })}
								>
									<i className={'fa fa-eye mr-2'} />
									{t('device.details.datasheet.nfd.nfdData.slave.show')}
								</Button>
							</Row>
						</Section>
					) : null}

					{this.state.showSlaveperipherials ? (
						<Section last>
							{this.renderSlavePeriperials()}
							<Row style={{ justifyContent: 'flex-end' }}>
								<label
									className="mr-3"
									style={{
										display: 'felx',
										alignItems: 'center',
										justifyContent: 'center',
										fontWeight: 'bold',
										fontSize: 'large'
									}}
								>
									{this.hardwareNameFormater(index)}
								</label>
								<Button
									color="info"
									className="DefaultButtonLg"
									onClick={() => this.onHideHandle()}
								>
									<i className={'fa fa-times mr-2'} />
									{t('device.details.datasheet.nfd.nfdData.slave.hide')}
								</Button>
							</Row>
						</Section>
					) : null}
				</Section>
			</>
		);
	}
}

export default withTranslation()(SlaveSheet);
