import React, { Component } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { withTranslation } from 'react-i18next';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import classes from './MachineStateCard.module.css';
import { Link } from 'react-router-dom';
import vmApi from '../../../../services/api/machines';
import proxy from '../../../PromotionManager/proxy';

class MachineStateLabelCard extends Component {
	state = { machineList: [] };

	componentDidMount() {
		this.loadData();
	}

	async loadData() {
		// Test Data

		// this.setState({
		// 	machineList: [
		// 		{ name: 'Valami', state: 0, connectionState: 1, type: 0 },
		// 		{ name: 'Valami1', state: 1, connectionState: 1, type: 1 },
		// 		{ name: 'Valami2', state: 2, connectionState: 0, type: 2 },
		// 		{ name: 'Valami3', state: 3, connectionState: 1, type: 3 },
		// 		{ name: 'Valami4', state: 2, connectionState: 1, type: 4 },
		// 		{ name: 'Matcha Latte', state: 2, connectionState: 1, type: 4 }
		// 	]
		// });
		// let p = proxy.machineList;
		// this.setState({
		// 	machineList: p
		// });

		const response = await vmApi.getVisibleVendingMachines();

		if (response.data.response) {
			const vms = Object.values(response.data.response);
			const machineList = vms.map(vm => {
				return {
					key: vm.vmID,
					name: vm.customName || vm.name,
					state: vm.vendingMachineState,
					type: vm.machine,
					connectionState: vm.onlineState === 'offline' ? 0 : 1
				};
			});

			this.setState({ machineList });
		}
	}
	maxMachineInTooltip = 10;

	renderTooltip(title, machines) {
		const tooltipCount =
			machines.length > this.maxMachineInTooltip
				? this.maxMachineInTooltip
				: machines.length;
		let toolTips = [];
		for (let index = 0; index < tooltipCount; index++) {
			toolTips.push(
				<div key={index} style={{ display: 'block', marginBottom: '10px' }}>
					<span>{machines[index].name} </span>
					<span>{machines[index].type}</span>
				</div>
			);
		}
		if (machines.length > this.maxMachineInTooltip) {
			toolTips.push(
				<div key={'...'} style={{ display: 'block' }}>
					<span>...</span>
				</div>
			);
		}

		return (
			<Tooltip
				id={'tooltip_' + title}
				style={{ borderColor: 'transparent', background: 'transparent' }}
			>
				<span>{title}</span>
				{toolTips}
			</Tooltip>
		);
	}

	renderMachineDiv(machineList, className, state, tooltiptitle, link) {
		return (
			<OverlayTrigger
				placement="bottom"
				delay={{ show: 250, hide: 400 }}
				overlay={this.renderTooltip(tooltiptitle, machineList)}
			>
				<Link to={link}>
					<Row
						className={classes.Label + ' ' + className}
						style={{ justifyContent: 'center' }}
					>
						<Col
							className={classes.LabelColTit}
							xs={11}
							sm={11}
							md={11}
							lg={11}
						>
							{state}
						</Col>
						<Col className={classes.LabelColNum} lg={12}>
							{machineList.length > 0 ? machineList.length : '0'}
						</Col>
					</Row>
				</Link>
			</OverlayTrigger>
		);
	}

	render() {
		const okMachines = this.state.machineList.filter(
			machine => machine.state === 0 && machine.connectionState === 1
		);
		const programmingMachines = this.state.machineList.filter(
			machine => machine.state === 1 && machine.connectionState === 1
		);
		const warningMachines = this.state.machineList.filter(
			machine => machine.state === 2 && machine.connectionState === 1
		);

		const malfunctionMachines = this.state.machineList.filter(
			machine => machine.state === 3 && machine.connectionState === 1
		);
		const offlineMachines = this.state.machineList.filter(
			machine => machine.connectionState === 0
		);
		const onlineMachines = this.state.machineList.filter(
			machine => machine.connectionState === 1
		);

		const numberOfMachines = this.state.machineList.length;

		const okMachineDiv = this.renderMachineDiv(
			okMachines,
			classes.OkMachinesDiv,
			this.props.t('customerDashboard.stateCard.labels.okMachines'),
			this.props.t('customerDashboard.stateCard.tooltips.okMachines'),
			'/vms?machinestate=ok'
		);
		const warningMachineDiv = this.renderMachineDiv(
			warningMachines,
			classes.WarningMachinesDiv,
			this.props.t('customerDashboard.stateCard.labels.warningMachines'),
			this.props.t('customerDashboard.stateCard.tooltips.warningMachines'),
			'/vms?machinestate=warning'
		);
		const programmingMachineDiv = this.renderMachineDiv(
			programmingMachines,
			classes.ProgrammingMachinesDiv,
			this.props.t('customerDashboard.stateCard.labels.programmingMachines'),
			this.props.t('customerDashboard.stateCard.tooltips.programmingMachines'),
			'/vms?machinestate=programming'
		);
		const malfunctionMachineDiv = this.renderMachineDiv(
			malfunctionMachines,
			classes.MalfunctionMachinesDiv,
			this.props.t('customerDashboard.stateCard.labels.malfunctionMachines'),
			this.props.t('customerDashboard.stateCard.tooltips.malfunctionMachines'),
			'/vms?machinestate=error'
		);
		const offlineMachineDiv = this.renderMachineDiv(
			offlineMachines,
			classes.OfflineMachinesDiv,
			this.props.t('customerDashboard.stateCard.labels.offlineMachines'),
			this.props.t('customerDashboard.stateCard.tooltips.offlineMachines'),
			'/vms?offline=true'
		);

		return (
			<Card className={this.props.className} style={this.props.style}>
				<CardBody>
					<Row>
						<Col sm="12">
							<div style={{ textAlign: 'center', textDecoration: 'bold' }}>
								<h4>
									<b>{this.props.t('customerDashboard.stateCard.title')}</b>
								</h4>
							</div>
						</Col>
					</Row>

					<div className={classes.TopLegends}>
						<span>
							{this.props.t('customerDashboard.stateCard.allMachines')}:&nbsp;
							{numberOfMachines}
						</span>
						<span>
							{this.props.t('customerDashboard.stateCard.onlineMachines')}
							:&nbsp;
							{onlineMachines.length}
						</span>
						<span>
							{this.props.t('customerDashboard.stateCard.offlineMachines')}
							:&nbsp;
							{offlineMachines.length}
						</span>
					</div>

					<Row
						noGutters
						style={{
							backgroundColor: 'var(--control-dark)',
							borderRadius: '5px'
						}}
					>
						<Col xs={12} sm={12} md lg>
							{okMachineDiv}
						</Col>
						<Col xs={12} sm={4} md lg>
							{warningMachineDiv}
						</Col>
						<Col xs={12} sm={4} md lg>
							{programmingMachineDiv}
						</Col>
						<Col xs={12} sm={4} md lg>
							{malfunctionMachineDiv}
						</Col>
						<Col xs={12} sm={12} md lg>
							{offlineMachineDiv}
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

export default withTranslation()(MachineStateLabelCard);
