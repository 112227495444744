import React, { Component } from 'react';

class Footer extends Component {
	render() {
		const footerValue =
			' 2020 H-Vend Service Kft. Version: ' +
			process.env.REACT_APP_VERSION_NUMBER;

		return (
			<footer className="app-footer">
				<span>&copy; {footerValue}</span>
			</footer>
		);
	}
}

export default Footer;
