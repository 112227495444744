import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ProductSales from '../../views/ProductSales/ProductSales.js';

class ProductSalesRoute extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route
						path="/productSales"
						name="ProductSales"
						component={ProductSales}
					/>
					<Redirect from="/" to="/productSales" />
				</Switch>
			</div>
		);
	}
}

export default ProductSalesRoute;
