import React, { Component } from 'react';
import { Card, CardTitle, Row, Col, Button, Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import classes from './MessagePanel.module.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Spinner from '../UI/Spinner/Spinner';
import Form from 'react-bootstrap/Form';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import moment from 'moment';

class MessagePanel extends Component {
	state = {
		messages: true,
		message: ' ',
		subject: ' ',
		openIdx: null,
		newMessage: null,
		newSubject: null,
		validated: false
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	validationHandler = () => {
		const form = document.getElementById('addMessageForm');
		if (form.checkValidity() === true) {
			this.sendMessageHandler();
		} else {
			this.setState({ validated: true });
		}
	};

	sendMessageHandler = () => {
		const body = {
			targetUserID: this.props.data.userID,
			topic: this.state.newSubject,
			message: this.state.newMessage
		};

		this.props.onSendMessage(body, this.props.data);
		this.setState({ newSubject: null, newMessage: null, messages: true });
	};

	subjectLabelFormater = value => {
		let subject = value.toLowerCase();
		if (value.length > 20) {
			subject = value.toLowerCase().slice(0, 19) + '...';
		}

		return subject;
	};

	massageFormatter = (cell, row) => {
		if (!row) return;
		let className = classes.MessageRow;
		let envelop = 'fa fa-envelope';
		if (this.state.openIdx === row.id) {
			className = classes.MessageRowOpen;
			envelop = 'fa fa-envelope-open';
		}

		return (
			<Row className={className} onClick={() => this.openMassageHandler(row)}>
				<Col xs={12} sm={12} md={1} lg={1}>
					<i className={envelop} />
				</Col>
				<Col xs={12} sm={12} md={5} lg={5}>
					<label className={classes.Data}>
						{row.subject
							? this.subjectLabelFormater(row.subject)
							: this.subjectLabelFormater(row.message)}
					</label>
				</Col>
				<Col xs={12} sm={12} md={5} lg={5}>
					<label className={classes.Data}>
						{moment(row.insertedAt).format('YYYY-MM-DD HH:mm:ss')}
					</label>
				</Col>
			</Row>
		);
	};

	isColumnsVisibile = columName => {
		return (
			!this.props.hiddenColumns ||
			this.props.hiddenColumns.indexOf(columName) < 0
		);
	};

	openMassageHandler = row => {
		if (!row) return;
		let subject = row.message;
		if (row.subject) {
			subject = row.subject;
		}
		this.setState({
			subject: subject,
			message: row.message,
			openIdx: row.id
		});
	};

	renderTable = () => {
		const { t } = this.props;
		const tableOptions = {
			sortIndicator: false,
			hideSizePerPage: false,
			paginationSize: 3,
			sizePerPageList: [7],
			sizePerPage: this.props.recordPerPage || 7,
			withFirstAndLast: true,
			paginationPosition: 'bottom',
			alwaysShowAllBtns: false,
			clearSearch: true,
			hidePageListOnlyOnePage: true,
			sortName: 'id',
			sortOrder: 'desc',
			searchPanel: this.props.renderFilterBar,
			onSearchChange: this.props.onFilterChange
		};

		let table = <Spinner />;
		if (this.props.messages) {
			table = (
				<>
					<BootstrapTable
						key="bootstrapTable"
						data={this.props.messages}
						version="4"
						pagination={!this.props.hidePager}
						insertRow={false}
						options={tableOptions}
						multiColumnSearch
						condensed
						bordered={false}
						dataAlign={'center'}
						size="sm"
						tableHeaderClass={classes.TableHeader}
						tableContainerClass={classes.TableContainerClass}
						trClassName={classes.TableBody}
						height="320"
					>
						{this.isColumnsVisibile('id') ? (
							<TableHeaderColumn
								isKey
								width="200"
								dataAlign="center"
								dataField="id"
								dataFormat={this.massageFormatter}
								thStyle={{ borderColor: 'transparent' }}
								tdStyle={{ borderColor: 'transparent', borderRadius: '20px' }}
							>
								<Row>
									<Col sm={1}></Col>
									<Col sm={7}>
										{t('invisibleTouchManager.messagePanel.subject')}{' '}
									</Col>
									<Col sm={3}>
										{t('invisibleTouchManager.purchasesPanel.date')}
									</Col>
								</Row>
							</TableHeaderColumn>
						) : null}
					</BootstrapTable>
				</>
			);
		}
		return table;
	};

	switchMessagesHandler = () => {
		this.setState({ messages: true });
	};

	switchNewMassageHandler = () => {
		this.setState({ messages: false });
	};

	renderNewMessages = () => {
		return (
			<Col className={classes.MessagePanel}>
				<Form
					style={{ paddingTop: '20px' }}
					noValidate
					validated={this.state.validated}
					id={'addMessageForm'}
				>
					<Col>
						<FormGroup>
							<Row>
								<Col xs={12} sm={12} md={2} lg={2}>
									<FormLabel className={classes.SubjectLabel}>
										{this.props.t(
											'invisibleTouchManager.messagePanel.addressee'
										)}
									</FormLabel>
								</Col>
								<Col xs={12} sm={12} md={10} lg={10}>
									<FormControl
										className={classes.AdField}
										type="email"
										readOnly
										defaultValue={this.props.data.email}
									></FormControl>
								</Col>
							</Row>
						</FormGroup>
						<FormGroup>
							<Row>
								<Col xs={12} sm={12} md={2} lg={2}>
									<FormLabel className={classes.SubjectLabel}>
										{this.props.t('invisibleTouchManager.messagePanel.subject')}
									</FormLabel>
								</Col>
								<Col xs={12} sm={12} md={10} lg={10}>
									<FormControl
										required
										className={classes.AdField}
										type="text"
										value={this.state.newSubject}
										name="newSubject"
										onChange={this.inputChangeHandler}
									></FormControl>
								</Col>
							</Row>
						</FormGroup>
					</Col>
					<FormGroup>
						<Col>
							<FormLabel className={classes.SubjectLabel}>
								{this.props.t('invisibleTouchManager.messagePanel.message')}
							</FormLabel>
						</Col>
						<Col style={{ paddingTop: '20px', paddingBottom: '20px' }}>
							<FormControl
								required
								as="textarea"
								type="text"
								className={classes.AdField}
								rows={7}
								value={this.state.newMessage}
								name="newMessage"
								onChange={this.inputChangeHandler}
							></FormControl>
						</Col>
						<Col>
							<Button
								className={classes.Send}
								onClick={() => this.validationHandler()}
							>
								<i className="fa fa-floppy-o mr-2" />
								{this.props.t('invisibleTouchManager.blancePanel.send')}
							</Button>
						</Col>
					</FormGroup>
				</Form>
			</Col>
		);
	};

	renderMessages = () => {
		let messages = <Spinner />;
		if (this.state.message) {
			messages = (
				<Row className={classes.MessagesRow}>
					<Col sm={6}>{this.renderTable()}</Col>
					<Col sm={6} className={classes.MessagePanel}>
						<Row className={classes.MessageHeader}>
							<Col sm={2}>
								<FormLabel className={classes.SubjectLabel}>
									{this.props.t('invisibleTouchManager.messagePanel.subject')}
								</FormLabel>
							</Col>
							<Col sm={8} className={classes.SubjectPanel}>
								<FormLabel className={classes.Subject}>
									{this.subjectLabelFormater(this.state.subject)}
								</FormLabel>
							</Col>
						</Row>

						<Col className={classes.MessageBody}>
							<Col>
								<FormLabel className={classes.MessageLabel}>
									{this.props.t('invisibleTouchManager.messagePanel.message')}
								</FormLabel>
							</Col>
							<hr></hr>
							<Col>
								<FormLabel className={classes.Message}>
									{this.state.message}
								</FormLabel>
							</Col>
						</Col>
					</Col>
				</Row>
			);
		}
		return messages;
	};

	render() {
		const { t } = this.props;
		return (
			<Card body className={this.props.panel}>
				<Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
					<Col xs={12} sm={12} md={5} lg={5}>
						<CardTitle style={{ color: '#6b9bc3 ' }}>
							<i className="fa fa-envelope" />
							&nbsp;
							{t('invisibleTouchManager.messagePanel.title')}
						</CardTitle>
					</Col>
					<Row>
						<Col>
							<Button
								className={classes.TitleButton}
								onClick={() => this.switchMessagesHandler()}
							>
								<i className="fa fa-envelope mr-2" />
								&nbsp;
								{t('invisibleTouchManager.messagePanel.messages')}
							</Button>
						</Col>
						<Col>
							<Button
								className={classes.TitleButton}
								onClick={() => this.switchNewMassageHandler()}
							>
								<i className="fa fa-envelope mr-2" />
								&nbsp;
								{t('invisibleTouchManager.messagePanel.new')}
							</Button>
						</Col>
					</Row>
				</Row>
				{this.state.messages ? this.renderMessages() : this.renderNewMessages()}
			</Card>
		);
	}
}

export default withTranslation()(MessagePanel);
