import { Col, Row, Button } from 'reactstrap';
import React from 'react';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { withTranslation } from 'react-i18next';

const RenderButtonPanel = props => {
	const { saveInProgress, editMode, machine, t } = props;
	return (
		<Row style={{ margin: ' 10px', justifyContent: 'flex-end' }}>
			{editMode ? (
				<>
					<LaddaButton
						className="btn btn-success btn-ladda DefaultButton"
						loading={saveInProgress}
						data-style={EXPAND_LEFT}
						onClick={props.onHandleSave}
						style={{ margin: '0.25rem' }}
						disabled={!(machine.customName && machine.customName.length > 0)}
					>
						<i className="fa fa-floppy-o mr-2" />
						{t('device.details.save')}
					</LaddaButton>
					<LaddaButton
						className="btn btn-primary btn-ladda DefaultButton"
						loading={saveInProgress}
						data-style={EXPAND_LEFT}
						style={{ margin: '0.25rem' }}
						onClick={props.onHandleEdit}
					>
						<i className="fa fa-check-circle mr-2" />
						{t('device.details.cancel')}
					</LaddaButton>
					{props.deleteButton ? (
						<Button
							color="primary"
							className="DefaultButton"
							style={{ margin: '0.25rem' }}
							onClick={props.onDel}
						>
							<i className={'fa fa-trash mr-2'}></i>
							{t('partnerManagement.customerGeneral.delete')}
						</Button>
					) : null}
				</>
			) : (
				<LaddaButton
					className={`btn btn-success btn-ladda ${
						props.i18n && props.i18n.language === 'en'
							? 'DefaultButton'
							: 'DefaultButtonMd'
					}`}
					loading={saveInProgress}
					data-style={EXPAND_LEFT}
					onClick={props.onEdit}
				>
					<i className="fa fa-pencil mr-2" />
					{t('device.details.edit')}
				</LaddaButton>
			)}
		</Row>
	);
};
export default withTranslation()(RenderButtonPanel);
