import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import './EditPart.css';
import classes from './EditPart.module.css';

class EditMI extends Component {
	state = {
		part: {
			id: '',
			partType: 0,
			manufactureDate: new Date(),
			electronicsItemNumber: ''
		}
	};

	componentDidMount() {
		if (this.props.part) {
			this.setState({
				part: {
					...this.props.part,
					manufactureDate: new Date(this.props.part.manufactureDate)
				}
			});
		}
	}

	hardwareStateSelectUpdate(e) {
		let part = { ...this.state.part };
		part.hardwareState = e.target.value;
		this.setState({ part });
	}

	machineTypeSelectUpdate(e) {
		let part = { ...this.state.part };
		part.partType = e.target.value;
		this.setState({ part });
	}

	customerChange(e) {
		let part = { ...this.state.part };
		part.customerID = e.target.value != '' ? e.target.value : null;
		this.setState({ part });
	}

	dateSelectionUpdate(newDate) {
		let part = { ...this.state.part };
		part.manufactureDate = newDate;
		this.setState({ part });
	}

	inputUpdate(e) {
		let part = { ...this.state.part };
		part[e.target.id] = e.target.value;
		this.setState({ part });
	}

	getEditedIDs() {
		return this.props.editedParts.map(part => part.id).join();
	}

	handleFormSubmit(e) {
		e.preventDefault();
		this.props.onSave(this.state.part, this.props.editedParts);
	}

	renderPartType() {
		if (this.props.editedParts.length <= 1) {
			const partTypeOptions = this.props.machineInterfaceTypes.map(item => (
				<option key={item.id} value={item.id}>
					{item.name}
				</option>
			));
			return (
				<Form.Row>
					<Form.Label>
						{this.props.t('hardwareManagement.hardwareList.part.machineType')}
					</Form.Label>
					<Form.Control
						id="partType"
						as="select"
						onChange={e => this.machineTypeSelectUpdate(e)}
						value={this.state.part.partType}
					>
						{partTypeOptions}
					</Form.Control>
				</Form.Row>
			);
		} else {
			return null;
		}
	}

	renderCustomerSelection() {
		const customerOptions = this.props.customers.map(item => (
			<option key={item.cID} value={item.cID}>
				{item.company}
			</option>
		));
		customerOptions.unshift(<option key={-1} value={null}></option>);
		return (
			<Form.Row>
				<Form.Label>Partner</Form.Label>
				<Form.Control
					id="customerID"
					as="select"
					onChange={e => this.customerChange(e)}
					value={this.state.part.customerID}
				>
					{customerOptions}
				</Form.Control>
			</Form.Row>
		);
	}

	renderElectronicsItemNumber() {
		if (this.props.editedParts.length <= 1) {
			return (
				<Form.Row>
					<Form.Label>
						{this.props.t(
							'hardwareManagement.hardwareList.part.electronicsItemNumber'
						)}
					</Form.Label>
					<Form.Control
						id="electronicsItemNumber"
						type="electronicsItemNumber"
						placeholder={this.props.t(
							'hardwareManagement.hardwareList.part.electronicsItemNumberPlaceHolder'
						)}
						value={this.state.part.electronicsItemNumber}
						onChange={e => this.inputUpdate(e)}
					/>
				</Form.Row>
			);
		} else {
			return null;
		}
	}

	render() {
		const hardwareStateOptions = this.props.hardwareStates.map(item => (
			<option key={item.id} value={item.id}>
				{item.statename}
			</option>
		));

		return (
			<Form onSubmit={e => this.handleFormSubmit(e)}>
				<Form.Row>
					<Form.Label>
						{this.props.t('hardwareManagement.hardwareList.part.nr')}
					</Form.Label>
					<Form.Control
						id="id"
						type="text"
						placeholder={this.props.t(
							'partnerManagement.customerGeneral.companyNamePlaceholder'
						)}
						disabled
						value={this.getEditedIDs()}
						onChange={e => this.inputUpdate(e)}
					/>
				</Form.Row>
				<Form.Row>
					<Form.Label>
						{this.props.t('hardwareManagement.hardwareList.part.state')}
					</Form.Label>
					<Form.Control
						id="hardwareState"
						as="select"
						onChange={e => this.hardwareStateSelectUpdate(e)}
						value={this.state.part.hardwareState}
					>
						{hardwareStateOptions}
					</Form.Control>
				</Form.Row>

				{this.renderPartType()}
				{this.renderCustomerSelection()}
				{/*
				<Form.Row>
					<Form.Label>
						{this.props.t(
							'hardwareManagement.hardwareList.part.manufactureDate'
						)}
					</Form.Label>
					<InputGroup className={classes.DatePicker}>
						<DatePicker
							id="manufactureDatePicker"
							className={'form-control ' + classes.datePicker}
							selected={this.state.part.manufactureDate}
							dateFormat="yyyy MM dd"
							disabled
							onChange={newDate => this.dateSelectionUpdate(newDate)}
						/>
					</InputGroup>
				</Form.Row>
				*/}
				{this.renderElectronicsItemNumber()}
				<div className={classes.ToolbarDiv}>
					{this.props.showDeleteButton ? (
						<Button
							variant="primary"
							onClick={() => this.props.onDelete(this.state.person)}
							className={classes.DeleteButton + '  DefaultButton'}
						>
							<i className="fa fa-minus mr-2" aria-hidden="true"></i>&nbsp;
							{this.props.t('partnerManagement.customerGeneral.delete')}
						</Button>
					) : null}
					<Button
						variant="success"
						type="submit"
						disabled={
							!this.state.part.id || String(this.state.part.id).trim() === ''
						}
						className="DefaultButton"
					>
						<i className="fa fa-floppy-o mr-2" aria-hidden="true"></i>&nbsp;
						{this.props.t('partnerManagement.customerGeneral.save')}
					</Button>
				</div>
			</Form>
		);
	}
}

export default withTranslation()(EditMI);
