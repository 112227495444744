import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './EditNotificationSettings.module.css';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Toggle from 'react-bootstrap-toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

class EditNotification extends Component {
	constructor(props) {
		super(props);

		this.state = {
			contentVisible: false,
			toggleNotification: !this.props.notification.notificationDisabled,
			toggleEmailNotification: !this.props.notification
				.emailNotificationDisabled,
			togglePushNotifications: !this.props.notification.pushNotificationDisabled
		};
	}

	toggleAccrodionContent() {
		this.setState({ contentVisible: !this.state.contentVisible });
	}

	callSettingsChanged() {
		this.props.onSettingsChange({
			id: this.props.notification.id,
			notificationDisabled: !this.state.toggleNotification,
			emailNotificationDisabled: !this.state.toggleEmailNotification,
			pushNotificationDisabled: !this.state.togglePushNotifications
		});
	}

	onToggleNotification() {
		const newSettings = !this.state.toggleNotification;
		if (!newSettings) {
			this.setState(
				{
					toggleNotification: newSettings,
					toggleEmailNotification: false,
					togglePushNotifications: false
				},
				() => this.callSettingsChanged()
			);
		} else {
			this.setState(
				{ toggleNotification: !this.state.toggleNotification },
				() => this.callSettingsChanged()
			);
		}
	}

	onToggleEmailNotification() {
		let newSettings = !this.state.toggleEmailNotification;
		if (newSettings) {
			this.setState(
				{
					toggleNotification: true,
					toggleEmailNotification: newSettings
				},
				() => this.callSettingsChanged()
			);
		} else {
			this.setState(
				{
					toggleEmailNotification: newSettings
				},
				() => this.callSettingsChanged()
			);
		}
	}

	onTogglePushNotification() {
		let newSettings = !this.state.togglePushNotifications;
		if (newSettings) {
			this.setState(
				{
					toggleNotification: true,
					togglePushNotifications: newSettings
				},
				() => this.callSettingsChanged()
			);
		} else {
			this.setState(
				{
					togglePushNotifications: newSettings
				},
				() => this.callSettingsChanged()
			);
		}
	}

	renderAccordionContent() {
		return (
			<div className={classes.MainDiv}>
				{this.props.t('notifications.notification')}
				<Toggle
					onClick={() => this.onToggleNotification()}
					on={'On'}
					off={'Off'}
					active={this.state.toggleNotification}
					onstyle="info"
					offstyle="primary"
					size="sm"
					style={{
						width: '75px',
						height: '30px',
						borderRadius: '25px',
						float: 'right'
					}}
				/>
				<hr className={classes.HR} />
				{this.props.t('notifications.notificationByEmail')}
				<Toggle
					onClick={() => this.onToggleEmailNotification()}
					on={'On'}
					off={'Off'}
					active={this.state.toggleEmailNotification}
					onstyle="info"
					offstyle="primary"
					size="sm"
					style={{
						width: '75px',
						height: '30px',
						borderRadius: '25px',
						float: 'right'
					}}
				/>
				<hr className={classes.HR} />
				{this.props.t('notifications.pushNotification')}
				<Toggle
					onClick={() => this.onTogglePushNotification()}
					on={'On'}
					off={'Off'}
					active={this.state.togglePushNotifications}
					onstyle="info"
					offstyle="primary"
					size="sm"
					style={{
						width: '75px',
						height: '30px',
						borderRadius: '25px',
						float: 'right'
					}}
				/>
			</div>
		);
	}

	render() {
		return (
			<>
				<Accordion.Toggle
					eventKey={this.props.notification.id}
					onClick={() => this.toggleAccrodionContent()}
					as={Card.Header}
					className={classes.CardHeader}
				>
					<div className={classes.IconDiv}>
						<i className={this.props.notification.icon}></i>
					</div>
					<div className={classes.TextDiv}>
						{this.props.notification.caption}
					</div>
					<i className="fa fa-chevron-down" style={{ float: 'right' }}></i>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey={this.props.notification.id}>
					{this.renderAccordionContent()}
				</Accordion.Collapse>
			</>
		);
	}
}

export default withTranslation()(EditNotification);
