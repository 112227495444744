import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import classes from './HardwareRiport.module.css';
import { withTranslation } from 'react-i18next';

const TableToolBar = props => {
	return (
		<Container fluid className={classes.TableToolbarContainer}>
			<Row className={classes.TableToolbar}>
				<Col xs={12} sm={12} md={12} lg={12} className={classes.btnCol}>
					{props.btnGroup}
					{props.excelExportButton ? props.excelExportButton() : null}
				</Col>
			</Row>
		</Container>
	);
};

export default withTranslation()(TableToolBar);
