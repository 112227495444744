//@flow
import React from 'react'

import { Col, Row } from 'reactstrap'

import styled from 'styled-components'

import { RedMachineButton } from '../../../../../components/Machines/MachineComponents'
const ColP5 = styled(Col)`
  padding: 5px;
`

type AntaresTrollSimpleKeyboardLineProps = {
  left: { buttonID: string, buttonContent: any },
  right: { buttonID: string, buttonContent: any },
  onButtonClick: (key: string) => () => Promise<void>,
}

const AntaresTrollSimpleKeyboardLine = ({
  left,
  right,
  onButtonClick,
}: AntaresTrollSimpleKeyboardLineProps) => (
  <Row>
    <ColP5
      xs={{ size: 4, offset: 2 }}
      sm={{ size: 4, offset: 2 }}
      md={{ size: 2, offset: 4 }}
      lg={{ size: 2, offset: 4 }}
      xl={{ size: 2, offset: 4 }}
    >
      <RedMachineButton onClick={onButtonClick(left.buttonID)}>
        {left.buttonContent}
      </RedMachineButton>
    </ColP5>
    <ColP5
      xs={{ size: 4 }}
      sm={{ size: 4 }}
      md={{ size: 2 }}
      lg={{ size: 2 }}
      xl={{ size: 2 }}
    >
      <RedMachineButton onClick={onButtonClick(right.buttonID)}>
        {right.buttonContent}
      </RedMachineButton>
    </ColP5>
  </Row>
)

export default AntaresTrollSimpleKeyboardLine
