import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import classes from './HardwareRiport.module.css';

class SummaryPanel extends Component {
	state = {};

	render() {
		const { t } = this.props;
		return (
			<Form>
				<Form.Group>
					<Row className={classes.SummaryRow}>
						<Col xs={12} sm={6} md={4} lg={4}>
							<Form.Label>
								{t('hardwareRiport.hardwartable.summary.elements')}
							</Form.Label>
						</Col>
						<Col xs={12} sm={6} md={2} lg={2}>
							<Row noGutters>
								<Col
									xs={6}
									sm={6}
									md={6}
									lg={6}
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										marginRight: '5px'
									}}
								>
									{this.props.transactionSum}
								</Col>
								<Col xs={5} sm={5} md={5} lg={5}>
									{t('hardwareRiport.hardwartable.summary.piece')}
								</Col>
							</Row>
						</Col>
					</Row>
				</Form.Group>
			</Form>
		);
	}
}

export default withTranslation()(SummaryPanel);
