//@flow
import React from 'react'

import { withRouter } from 'react-router-dom'

import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent'

import Klix900Keyboard from './Keyboard/Klix900Keyboard'

type Props = {
  products?: Array<Object>,
}

class Klix900 extends React.Component<Props> {
  render() {
    const { products = [] } = this.props
    return (
      <DirectChooseMachineBaseComponent
        machineTitle="Klix900"
        lineNumber={2}
        lineLength={40}
        ledNumbers={12}
        keyboard={<Klix900Keyboard products={products} />}
        {...this.props}
      />
    )
  }
}

export default withRouter(Klix900)
