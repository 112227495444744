//@flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { RedMachineButton } from '../../../../components/Machines/MachineComponents'
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent'
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard'

type Props = {
  vmID: string,
  history: Object,
  products?: Array<Object>,
}
type States = {}
class Zeta extends React.Component<Props, States> {
  keyboardConfig = [
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            ENTER <br />1
          </>
        ),
        buttonID: '1',
      },
    ],
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            NEXT <br />2
          </>
        ),
        buttonID: '2',
      },
    ],
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <i className="fa fa-long-arrow-up mr-1" /> 3
          </>
        ),
        buttonID: '3',
      },
    ],
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <i className="fa fa-long-arrow-down mr-1" /> 4
          </>
        ),
        buttonID: '4',
      },
    ],
    [
      {
        buttonContent: <>5</>,
        buttonID: '5',
      },
    ],

    [
      {
        buttonContent: <>6</>,
        buttonID: '6',
      },
    ],

    [
      {
        buttonContent: <>7</>,
        buttonID: '7',
      },
    ],

    [
      {
        buttonContent: <>8</>,
        buttonID: '8',
      },
    ],

    [
      {
        buttonContent: <>9</>,
        buttonID: '9',
      },
    ],

    [
      {
        buttonContent: <>10</>,
        buttonID: '10',
      },
    ],

    [
      {
        buttonContent: <>11</>,
        buttonID: '11',
      },
    ],

    [
      {
        buttonContent: <>12</>,
        buttonID: '12',
      },
    ],
  ]

  render() {
    return (
      <DirectChooseMachineBaseComponent
        machineTitle="Zeta"
        lineNumber={1}
        lineLength={5}
        keyboard={
          <DirectChooseKeyboard
            keyboardConfig={this.keyboardConfig}
            {...this.props}
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(Zeta)
