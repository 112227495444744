import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Card, CardBody } from 'reactstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TabContent from 'react-bootstrap/TabContent';

import classes from './HardwareManagement.module.css';
import HIDList from '../../views/HardwareList/HID/HIDList';
import MIList from '../../views/HardwareList/Parts/PartList';

import './TabsColoring.css';

class HardwareManagement extends Component {
	render() {
		return (
			<Card className={this.props.className} style={this.props.style}>
				<CardBody>
					<Tabs
						className={classes.navTabs}
						defaultActiveKey="general"
						id="uncontrolled-tab-example"
					>
						<Tab
							eventKey="general"
							title={this.props.t('hardwareManagement.hardwareList.HID.title')}
						>
							<TabContent
								style={{ border: '0px' }}
								className={classes.tabContent}
							>
								<HIDList></HIDList>
							</TabContent>
						</Tab>
						<Tab
							eventKey="machines"
							title={this.props.t('hardwareManagement.hardwareList.part.title')}
						>
							<TabContent className={classes.tabContent}>
								<MIList></MIList>
							</TabContent>
						</Tab>
					</Tabs>
				</CardBody>
			</Card>
		);
	}
}
//<Container fluid>
export default withTranslation()(HardwareManagement);

/*
<Row>
						<Col>
							<CardTitle className="text-muted" style={{ cursor: 'pointer' }}>
								<i
									className={`icon-people ` + classes.IconMargin}
									style={{ marginRight: '10px' }}
								/>
								{this.props.t('hardwareManagement.title')}
							</CardTitle>
						</Col>
					</Row>
*/
