//@flow
import React from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	ListGroupItem,
	Row,
	ListGroup
} from 'reactstrap';
import styled from 'styled-components';

import MachinaBaseComponent from '../MachinaBaseComponent';

const AlphanumericMachineListGroup = styled(ListGroup)`
	border-radius: 5px;
	color: white;
	background-color: #808080;
	height: 465px;
	overflow: scroll;
`;

class AlphanumericMachineBaseComponent extends MachinaBaseComponent {
	getLCDLineElement = (line: string, idx: number) => {
		return (
			<>
				<snap className="lcd_font pb-2 pt-2 pl-5 pr-5 text-white">{line}</snap>
				<br />
			</>
		);
	};

	render() {
		const {
			products = [],
			machineTitle = '',
			keyboard = null,
			isSlave = false
		} = this.props;

		const filteredProducts = products.filter(
			product =>
				isSlave ===
				!(product.slavePattern === null || product.slavePattern === undefined)
		);

		const LCD_lines = (
			<div className="machine-led-background ">
				<div
					className="machine-led-background "
					style={{
						width: 'fit-content',
						maxWidth: '100%',
						margin: 'auto'
					}}
				>
					<br />
					{this.state.LCDLines.map((line, idx) =>
						this.getLCDLineElement(line, idx)
					)}
				</div>
			</div>
		);

		return (
			<Card className="machine-container klix-bg">
				<CardHeader className="border-dark bg-dark color-light" id="cardHeight">
					<h3 id="lcdLabel">{machineTitle}</h3>
				</CardHeader>
				<CardBody className="klix-bg">
					<Row className="machine-lcd-40">
						<Col xs="12" md="12" lg="12" xl="12">
							{LCD_lines}
						</Col>
					</Row>
					<br />
					<Row>
						<Col xs="12" md="6" lg="6" xl="6">
							{keyboard && (
								<keyboard.type
									{...keyboard.props}
									onButtonClick={this.bntPressHandler}
								/>
							)}
						</Col>
						<Col xs="12" md="6" lg="6" xl="6">
							<AlphanumericMachineListGroup>
								{filteredProducts.map((product, idx) => (
									<ListGroupItem
										key={`product-list-${idx}`}
										style={{
											backgroundColor: idx % 2 ? '#808080' : '#909090'
										}}
									>
										<Row>
											<Col xs="3" md="3" lg="3" xl="3">
												#{product.productID}
											</Col>
											<Col xs="5" md="5" lg="5" xl="5">
												{product.name}
											</Col>
											<Col xs="4" md="4" lg="4" xl="4">
												{product.price} {product.currency}
											</Col>
										</Row>
									</ListGroupItem>
								))}
							</AlphanumericMachineListGroup>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

export default AlphanumericMachineBaseComponent;
