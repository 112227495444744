import React from 'react'
import { Col, Row } from 'reactstrap'
import { GreenMachineButton } from '../../../../components/Machines/MachineComponents'

import DirectChooseHorizontalKeyboard from './DirectChooseHorizontalKeyboard'
import DirectChooseVerticalKeyboard from './DirectChooseVerticalKeyboard'

type Props = {
  products: Array<Object>,
  type?: 'horizontal' | 'vertical',
  programmingButtonVisible?: boolean,
  keyboardHeaderLine?: any,
  onButtonClick?: (buttonId: string, isSlave: ?boolean) => () => Promise<void>,
}

export default class DirectChooseKeyboard extends React.Component<Props> {
  defaultKeyboardHeaderLine = () => {
    const { programmingButtonVisible = true } = this.props
    return (
      <>
        {programmingButtonVisible && (
          <Row className="machine-lcd-40">
            <Col
              xs={{ size: 8, offset: 2 }}
              md={{ size: 6, offset: 3 }}
              lg={{ size: 6, offset: 3 }}
              xl={{ size: 6, offset: 3 }}
            >
              <GreenMachineButton onClick={this.props.onButtonClick('100')}>
                <i className="fa fa-mobile  mr-1 fa-lg" /> | Programming
              </GreenMachineButton>
            </Col>
          </Row>
        )}
      </>
    )
  }

  render() {
    const { keyboardHeaderLine = null, type = 'horizontal' } = this.props
    return (
      <>
        {keyboardHeaderLine && (
          <keyboardHeaderLine.type
            {...keyboardHeaderLine.props}
            {...this.props}
          />
        )}

        {!keyboardHeaderLine && this.defaultKeyboardHeaderLine()}

        {type === 'vertical' && (
          <DirectChooseVerticalKeyboard {...this.props} />
        )}
        {type === 'horizontal' && (
          <DirectChooseHorizontalKeyboard {...this.props} />
        )}
      </>
    )
  }
}
