import React, { useState, useEffect } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import classes from './DeviceGroupTable.module.css';
import { ButtonGroup } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import DescriptionTooltip from './DescriptionTooltip';
import moment from 'moment';

import { withTranslation } from 'react-i18next';

const DeviceGroupTable = props => {
	const { t } = props;
	const [idx, setIdx] = useState(null);

	useEffect(() => {
		if (props.groupToDel) setIdx(props.firstId);
		if (!idx) setIdx(props.firstId);
		if (idx) props.onShowList(idx);
	}, [idx, props.groupToDel, props.firstId]);

	const isColumnsVisibile = columName => {
		return !props.hiddenColumns || props.hiddenColumns.indexOf(columName) < 0;
	};

	const showListFormater = (cell, row) => {
		return (
			<Button
				onClick={() => setIdx(row.id)}
				variant="success"
				className={
					idx === row.id ? classes.ActivateButtonActive : classes.ActivateButton
				}
			>
				<i className="fa fa-list mr-2" /> {t('groupManager.mainTable.display')}
			</Button>
		);
	};

	const editFormatter = (cell, row) => {
		return (
			<ButtonGroup vertical>
				<Button
					onClick={e => props.onEditProfil(row)}
					variant="success"
					className={classes.EditButton}
				>
					<i className="fa fa-pencil mr-2" />
					&nbsp;
					{t('groupManager.mainTable.edit.edit')}
				</Button>
				<Button
					onClick={e => props.onDeleteGroup(row)}
					className={classes.DeleteButton}
				>
					<i className="fa fa-trash mr-2" />
					&nbsp;
					{t('groupManager.mainTable.edit.delete')}
				</Button>
			</ButtonGroup>
		);
	};

	const dateFormater = (cell, row) => {
		let date = ' ';
		if (cell) {
			date = moment(cell).format('YYYY-MM-DD');
		}
		return (cell = date);
	};

	const userFormatter = (cell, row) => {
		return <i className="fa fa-cube" id="userIcon" />;
	};

	const descriptionFormater = cell => {
		const btn = (
			<>
				<i className="fa fa-angle-left" /> {t('groupManager.mainTable.details')}
			</>
		);

		return (
			<DescriptionTooltip
				placement={'left'}
				title={t('groupManager.mainTable.detailsOverlay.device')}
				content={cell}
				btnTitle={btn}
				className={classes.DescriptionButton}
			/>
		);
	};

	const backgroundColorFormater = row => {
		let bgColor = ' ';
		if (idx === row.id) {
			bgColor = classes.ActiveBackGround;
		}
		return bgColor;
	};

	//Table options

	const tableOptions = {
		sortIndicator: true,
		hideSizePerPage: false,
		paginationSize: 3,
		sizePerPageList: [4],
		sizePerPage: props.recordPerPage || 4,
		withFirstAndLast: true,
		alwaysShowAllBtns: true,
		pageStartIndex: 1,
		paginationPosition: 'bottom',
		clearSearch: true,
		hidePageListOnlyOnePage: true,
		sortName: 'id',
		sortOrder: 'asc',
		searchPanel: props.renderFilterBar,
		onSearchChange: props.onFilterChange
	};

	return (
		<>
			{!props.hideToolbar ? props.onRenderFilterBar() : null}
			<BootstrapTable
				key="bootstrapTable"
				data={props.data}
				version="4"
				hover
				pagination={!props.hidePager}
				insertRow={false}
				options={tableOptions}
				multiColumnSearch
				condensed
				bordered={false}
				dataAlign={'center'}
				size="sm"
				trClassName={backgroundColorFormater}
			>
				{isColumnsVisibile('id') ? (
					<TableHeaderColumn
						isKey
						width="40"
						dataAlign="center"
						dataField="id"
						dataFormat={userFormatter}
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px' }}
					/>
				) : null}
				{isColumnsVisibile('name') ? (
					<TableHeaderColumn
						dataField="name"
						dataAlign="center"
						width="110"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{t('groupManager.mainTable.groupname')}
					</TableHeaderColumn>
				) : null}

				{isColumnsVisibile('insertedAt') ? (
					<TableHeaderColumn
						dataField="insertedAt"
						width="90"
						dataAlign="center"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						dataFormat={dateFormater}
					>
						{t('groupManager.mainTable.creation')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('updatedAt') ? (
					<TableHeaderColumn
						dataField="updatedAt"
						width="90"
						dataAlign="center"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						dataFormat={dateFormater}
					>
						{t('groupManager.mainTable.update')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('description') ? (
					<TableHeaderColumn
						dataField="description"
						dataAlign="center"
						width="110"
						dataSort
						dataFormat={descriptionFormater}
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal'
						}}
					>
						{t('groupManager.mainTable.description')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('showList') ? (
					<TableHeaderColumn
						dataField="showList"
						width="120"
						dataAlign="center"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal'
						}}
						dataFormat={showListFormater}
					>
						{t('groupManager.mainTable.deviceList')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('action') ? (
					<TableHeaderColumn
						width="100"
						dataAlign="center"
						dataField="userID"
						dataFormat={editFormatter}
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					>
						{t('groupManager.mainTable.edit.title')}
					</TableHeaderColumn>
				) : null}
			</BootstrapTable>
		</>
	);
};

export default withTranslation()(DeviceGroupTable);
