//@flow
import React from 'react'

import { Col, Row } from 'reactstrap'

import { MachineButton as BaseButton } from '../../../../components/Machines/MachineComponents'
import styled from 'styled-components'
const MachineButton = styled(BaseButton)`
  font-size: small;
`

const JedeLynxKeyboardHeader = ({
  onButtonClick = (key: string) => async () => {},
}: {
  onButtonClick?: (key: string) => () => Promise<void>,
}) => {
  return (
    <Row>
      <Col xs="12" sm="12" md="3" xl="4">
        <Row>
          <Col
            xs={{ size: 6, offset: 3 }}
            sm={{ size: 6, offset: 3 }}
            md={{ size: 6, offset: 3 }}
            xl={{ size: 6, offset: 3 }}
          >
            <MachineButton onClick={onButtonClick('9')}>
              <i className="fa fa-long-arrow-up mr-1" />
            </MachineButton>
          </Col>
        </Row>

        <Row>
          <Col
            xs={{ size: 6 }}
            sm={{ size: 6 }}
            md={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <MachineButton onClick={onButtonClick('12')}>BACK</MachineButton>
          </Col>
          <Col
            xs={{ size: 6 }}
            sm={{ size: 6 }}
            md={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <MachineButton onClick={onButtonClick('10')}>SELECT</MachineButton>
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ size: 6, offset: 3 }}
            sm={{ size: 6, offset: 3 }}
            md={{ size: 6, offset: 3 }}
            xl={{ size: 6, offset: 3 }}
          >
            <MachineButton onClick={onButtonClick('11')}>
              <i className="fa fa-long-arrow-down mr-1" />
            </MachineButton>
          </Col>
        </Row>
      </Col>
      <Col xs="12" sm="12" md="9" xl="8">
        <Row style={{ paddingBottom: '60px' }}>
          <Col xs="3" sm="2" md="2" xl="2">
            <MachineButton onClick={onButtonClick('13')}>
              TM <br /> 1
            </MachineButton>
          </Col>
          <Col xs="3" sm="2" md="2" xl="2">
            <MachineButton onClick={onButtonClick('14')}>
              TM <br />2
            </MachineButton>
          </Col>
          <Col xs="3" sm="2" md="2" xl="2">
            <MachineButton onClick={onButtonClick('15')}>
              TM <br />3
            </MachineButton>
          </Col>
          <Col xs="3" sm="2" md="2" xl="2">
            <MachineButton onClick={onButtonClick('16')}>
              TM <br />4
            </MachineButton>
          </Col>
          <Col xs="3" sm="2" md="2" xl="2">
            <MachineButton onClick={onButtonClick('17')}>
              TM <br />5
            </MachineButton>
          </Col>
          <Col xs="3" sm="2" md="2" xl="2">
            <MachineButton onClick={onButtonClick('18')}>
              TM <br />6
            </MachineButton>
          </Col>
        </Row>

        <Row>
          <Col>
            <MachineButton onClick={onButtonClick('19')}>
              CLEANING
            </MachineButton>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default JedeLynxKeyboardHeader
