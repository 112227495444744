import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import dateFormat from 'dateformat';

import { ListGroup, ListGroupItem } from 'reactstrap';
import { toast } from 'react-toastify';
import notificationsAPI from '../../services/api/notifications';
import machines from '../../services/api/machines';
import vmUtil from '../../services/util/vmUtil';

import classes from './NotificationList.module.css';

class NotificationList extends Component {
	state = {
		notifications: []
	};

	offset = 0;
	loadSize = 10;

	componentDidMount() {
		this.loadNotificationCount();
		this.loadNextBatch();
	}

	loadNotificationCount() {
		notificationsAPI.getNotificationCount().then(count => {
			this.setState({ notificationCount: count.data });
		});
	}

	loadNotifications() {
		notificationsAPI
			.getNotifications(this.offset, this.loadSize)
			.then(notifications => {
				let nots = [...this.state.notifications];
				nots = nots.concat(notifications.data);
				this.setState({ notifications: nots });
			});
	}

	loadNextBatch = () => {
		this.loadNotifications();
		this.offset += this.loadSize;
	};

	async onNotificationClick(notification) {
		const parsedValues = JSON.parse(notification.values);
		if (parsedValues.hardwareID) {
			try {
				const vendingMachineID = await machines.getVendingMachineByHardwareID(
					parsedValues.hardwareID
				);
				if (vendingMachineID) {
					const encodedVMID = vmUtil.encodeVMID(vendingMachineID);
					this.props.history.push('vms/' + encodedVMID + '/detailer');
				}
			} catch (error) {
				return toast.error(this.props.t('common.couldNotOpen'));
			}
		}
	}

	renderNotifications() {
		let result = this.state.notifications.map(notification => {
			const textDate = dateFormat(
				new Date(notification.insertedAt ? notification.insertedAt : 0),
				'yyyy. mm. dd. HH:MM'
			);

			return (
				<ListGroupItem
					onClick={() => this.onNotificationClick(notification)}
					className={classes.NotificationListItem}
				>
					<div className={classes.IconDiv}>
						<i
							class={notification.icon}
							style={{
								fontSize: '1.5rem'
							}}
							aria-hidden="true"
						></i>
					</div>
					<div className={classes.TextDiv}>
						<p>{notification.resolvedText}</p>
						<p className={classes.DateParagraph}>{textDate}</p>
					</div>
				</ListGroupItem>
			);
		});

		return result;
	}

	render() {
		return (
			<>
				<InfiniteScroll
					dataLength={this.state.notifications.length}
					next={this.loadNextBatch}
					hasMore={
						this.state.notifications.length < this.state.notificationCount
					}
					loader={<h4>{this.props.t('notifications.loading')}</h4>}
					endMessage={
						<p style={{ textAlign: 'center' }}>
							<b>{this.props.t('notifications.seenall')}</b>
						</p>
					}
				>
					<ListGroup>{this.renderNotifications()}</ListGroup>
				</InfiniteScroll>
			</>
		);
	}
}

export default withRouter(withTranslation()(NotificationList));
