import React from 'react';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import classes from './AttachedFilesTable.module.css';
import attachmentsApi from '../../../../../services/api/attachments';
import { saveAs } from 'file-saver';

const AttachedFilesTable = props => {
	const { t } = props;

	const dateFormater = cell => {
		let date = '';
		if (cell) {
			date = moment(cell).format('YYYY.MM.DD hh:mm:ss');
		}
		return date;
	};

	const userFormatter = (cell, row) => {
		return <i className="fa fa-file" id="userIcon" />;
	};

	const getFileById = async row => {
		if (!row && !row.fileID) return;
		const res = await attachmentsApi.getFileById(row.fileID);
		if (res && res.data) {
			let contentType = '';
			let fileName = '';
			if (row.filename) {
				fileName = row.filename;
			}
			if (res.headers && res.headers['content-type']) {
				contentType = res.headers['content-type'];
			}
			const attachment = new Blob([res.data], {
				type: contentType
			});
			saveAs(attachment, fileName);
		}
		return;
	};
	const downloadFormater = (cell, row) => {
		if (row) {
			return (
				<Button
					onClick={() => getFileById(row)}
					variant="success"
					className={classes.ActivateButton}
				>
					<i className="fa fa-floppy-o mr-2" /> {t('attachedFiles.download')}
				</Button>
			);
		}
	};

	const deleteFormater = (cell, row) => {
		if (row && row.fileID) {
			return (
				<Button
					onClick={() => props.onDel(row.fileID)}
					className={classes.DeleteButton}
				>
					<i className="fa fa-trash mr-2" />
					{t('promotionManager.promotionTable.edit.delete')}
				</Button>
			);
		}
	};

	//Render Table
	const isColumnsVisibile = columName => {
		return !props.hiddenColumns || props.hiddenColumns.indexOf(columName) < 0;
	};

	//Table options
	const tableOptions = {
		sortIndicator: true,
		hideSizePerPage: false,
		paginationSize: 3,
		sizePerPageList: [10],
		sizePerPage: props.recordPerPage || 10,
		paginationPosition: 'bottom',
		withFirstAndLast: true,
		alwaysShowAllBtns: true,
		pageStartIndex: 1,
		clearSearch: true,
		hidePageListOnlyOnePage: true,
		sortName: 'insertedAt',
		sortOrder: 'desc',
		searchPanel: props.renderFilterBar,
		onSearchChange: props.onFilterChange
	};
	// const { t } = props;

	return (
		<>
			{!props.hideToolbar ? props.renderFilterBar() : null}
			<BootstrapTable
				key="bootstrapTable"
				data={props.data}
				version="4"
				striped
				hover
				pagination={!props.hidePager}
				insertRow={false}
				options={tableOptions}
				multiColumnSearch
				condensed
				bordered={false}
				dataAlign={'center'}
				size="sm"
			>
				{isColumnsVisibile('userIcon') ? (
					<TableHeaderColumn
						isKey
						width="100"
						dataAlign="center"
						dataField="ID"
						dataFormat={userFormatter}
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px' }}
					/>
				) : null}
				{isColumnsVisibile('filename') ? (
					<TableHeaderColumn
						width="200"
						dataField="filename"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{props.t('attachedFiles.fileName')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('fileID') ? (
					<TableHeaderColumn
						width="100"
						dataField="fileID"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{props.t('packageManager.packageTable.identiifer')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('insertedAt') ? (
					<TableHeaderColumn
						width="600"
						dataField="insertedAt"
						dataFormat={dateFormater}
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
					>
						{props.t('packageManager.packageTable.createdAt')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('layouts') ? (
					<TableHeaderColumn
						width="150"
						dataField="layouts"
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						dataFormat={downloadFormater}
					>
						{props.t('attachedFiles.download')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('layouts') ? (
					<TableHeaderColumn
						width="100"
						dataField="layouts"
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						dataFormat={deleteFormater}
					></TableHeaderColumn>
				) : null}
			</BootstrapTable>
		</>
	);
};

export default withTranslation()(AttachedFilesTable);
