import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './AddProductGroupForm.module.css';

import { Row, Col } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ProductSelectedTable from './ProductSelectedTable';
import ProductSelectorTable from './ProductSelectorTable';
import HelperTooltip from '../../../../components/UI/HelperTooltip/HelperTooltip';

import t from '../../../PromotionManager/tooltip.json';

class AddMachineGroupForm extends Component {
	state = {
		selectedProducts: [],
		newGroupName: '',
		description: '',
		creationDate: new Date(),
		validated: false
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.products !== this.props.products) {
			this.setState({});
		}
	}

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	onSubmitHandler = () => {
		// too do
	};

	selectedProducts = value => {
		if (this.state.selectedProducts.length !== value.length) {
			this.setState({ selectedProducts: value });
		}
	};

	loadSelectorTabel = () => {
		return (
			<>
				<ProductSelectorTable
					data={this.props.products}
					selectedProducts={this.selectedProducts}
				/>
			</>
		);
	};

	loadSelectedTabel = () => {
		return (
			<>
				<ProductSelectedTable data={this.state.selectedProducts} height="180" />
			</>
		);
	};

	validationHandler = () => {
		const form = document.getElementById('addProductgroupForm');
		if (form.checkValidity() === true) {
			this.onSubmitHandler();
		} else {
			this.setState({ validated: true });
		}
	};

	//Render-----------------------------------------------------------------

	render() {
		const { t } = this.props;
		return (
			<Form
				noValidate
				validated={this.state.validated}
				id={'addProductgroupForm'}
			>
				<Row>
					<Col>
						<Form.Group
							className={classes.FormGroup}
							controlId="newGroupName"
							style={{ padding: '15px' }}
						>
							<Row style={{ paddingLeft: '15px', alignItems: 'center' }}>
								<Form.Label className={classes.Label}>
									{t('groupManager.addGroup.listName.title')}
								</Form.Label>
								<HelperTooltip
									height={'100px'}
									width={'250px'}
									direction={'right'}
									id={'groupName'}
									title={t('groupManager.addGroup.listName.tooltip.title')}
									content={t('groupManager.addGroup.listName.tooltip.content')}
								></HelperTooltip>
							</Row>
							<Form.Control
								required
								name="newGroupName"
								type="text"
								placeholder={t('groupManager.addGroup.listName.placeholder')}
								value={this.state.newGroupName}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group
							className={classes.FormGroup}
							controlId="description"
							style={{ padding: '15px' }}
						>
							<Row style={{ paddingLeft: '15px', alignItems: 'center' }}>
								<Form.Label className={classes.Label}>
									{t('groupManager.addGroup.listDescription.title')}
								</Form.Label>
								<HelperTooltip
									height={'100px'}
									width={'250px'}
									direction={'right'}
									id={'groupDescription'}
									title={t(
										'groupManager.addGroup.listDescription.tooltip.title'
									)}
									content={t(
										'groupManager.addGroup.listDescription.tooltip.content'
									)}
								></HelperTooltip>
							</Row>
							<Form.Control
								required
								name="description"
								type="text"
								placeholder={t(
									'groupManager.addGroup.listDescription.placeholder'
								)}
								value={this.state.description}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Form.Group
					className={classes.FormGroupTable}
					controlId="addNewMachines"
					style={{ padding: '15px' }}
				>
					<Row style={{ paddingLeft: '15px', alignItems: 'center' }}>
						<Form.Label className={classes.Label}>
							{t('groupManager.addGroup.selectorTable.title')}
						</Form.Label>
						<HelperTooltip
							height={'100px'}
							width={'400px'}
							direction={'right'}
							id={'machineSelector'}
							title={t('groupManager.addGroup.selectorTable.tooltip.title')}
							content={t(
								'groupManager.addGroup.selectorTable.tooltip.product.content'
							)}
						></HelperTooltip>
					</Row>
					{this.loadSelectorTabel()}
				</Form.Group>
				<hr></hr>
				<Form.Group
					className={classes.FormGroupTable}
					controlId="NewMachineGroup"
					style={this.props.style}
				>
					<Form.Label className={classes.Label}>
						<strong>{t('groupManager.addGroup.selectedTable')}</strong>
					</Form.Label>
					{this.loadSelectedTabel()}
				</Form.Group>
				<hr></hr>
				<Button variant="primary" onClick={e => this.validationHandler()}>
					{t('groupManager.addGroup.savebutton')}
				</Button>
			</Form>
		);
	}
}

export default withTranslation()(AddMachineGroupForm);
