import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import CustomersApi from '../../../services/api/customers';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class InvisibleTouchSettings extends Component {
	state = { barionSettings: {} };

	componentDidMount() {
		CustomersApi.getBarionSettings(this.props.customer.cmID).then(result => {
			this.setState({ barionSettings: result.data });
			console.log(result);
		});
	}

	handleFormSubmit(e) {
		e.preventDefault();
	}

	inputUpdate(e) {
		const barionSettings = { ...this.state.barionSettings };
		if (e.target.type != 'checkbox') {
			barionSettings[e.target.id] = e.target.value;
		} else {
			barionSettings[e.target.id] = !e.target.checked;
		}
		this.setState({ barionSettings, changed: true });
	}

	saveChanges() {
		if (this.props.customer.cmID) {
			CustomersApi.setBarionSettings(
				this.props.customer.cmID,
				this.state.barionSettings.barionEmailSettings,
				this.state.barionSettings.barionPercentageSettings,
				this.state.barionSettings.barionDisabled,
				this.state.barionSettings.cashUploadDisabled,
				this.state.barionSettings.invTouchDisabled
			).then(() => this.setState({ changed: false }));
		}
	}

	render() {
		return (
			<Form id="companyForm" onSubmit={e => this.handleFormSubmit(e)}>
				<Form.Check
					id="invTouchDisabled"
					type="checkbox"
					label={this.props.t(
						'partnerManagement.invisibleTouchSettings.invisibleTouchEnabled'
					)}
					checked={
						this.state.barionSettings
							? !this.state.barionSettings.invTouchDisabled
							: true
					}
					onChange={e => this.inputUpdate(e)}
					disabled={this.props.disabled}
					style={{ display: 'inline', marginRight: '10px' }}
				/>
				<br />
				<Form.Check
					id="cashUploadDisabled"
					type="checkbox"
					label={this.props.t(
						'partnerManagement.invisibleTouchSettings.cashupload'
					)}
					checked={
						this.state.barionSettings
							? !this.state.barionSettings.cashUploadDisabled
							: true
					}
					onChange={e => this.inputUpdate(e)}
					disabled={this.props.disabled}
					style={{ display: 'inline', marginRight: '10px' }}
				/>

				<hr></hr>

				<Form.Check
					id="barionDisabled"
					type="checkbox"
					label={this.props.t(
						'partnerManagement.invisibleTouchSettings.barionEnabled'
					)}
					checked={
						this.state.barionSettings
							? !this.state.barionSettings.barionDisabled
							: true
					}
					onChange={e => this.inputUpdate(e)}
					disabled={this.props.disabled}
					style={{ display: 'inline', marginRight: '10px' }}
				/>
				<br />
				<Form.Label>
					{this.props.t('partnerManagement.invisibleTouchSettings.barionEmail')}
				</Form.Label>
				<Form.Control
					id="barionEmailSettings"
					type="text"
					value={
						this.state.barionSettings
							? this.state.barionSettings.barionEmailSettings
							: ''
					}
					onChange={e => this.inputUpdate(e)}
					disabled={this.props.disabled}
				/>
				<Form.Label>
					{this.props.t(
						'partnerManagement.invisibleTouchSettings.barionPercentage'
					)}
				</Form.Label>
				<Form.Control
					id="barionPercentageSettings"
					type="number"
					value={
						this.state.barionSettings
							? this.state.barionSettings.barionPercentageSettings
							: 0
					}
					onChange={e => this.inputUpdate(e)}
					disabled={this.props.disabled}
					min={0}
					max={100}
				/>
				<Button
					variant="success"
					type="submit"
					disabled={!this.state.changed}
					className="DefaultButton"
					style={{ marginTop: '10px' }}
					onClick={() => this.saveChanges()}
				>
					<i className="fa fa-floppy-o" aria-hidden="true"></i>&nbsp;
					{this.props.t('partnerManagement.customerGeneral.save')}
				</Button>
			</Form>
		);
	}
}
export default withTranslation()(InvisibleTouchSettings);
