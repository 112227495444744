//@flow
import React from 'react';
import { connect } from 'react-redux';
import { getColorForState } from '../../../services/util/colors';
import { Button } from 'reactstrap';
import { Row, Col, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import AddDeviceDialog from './AddDeviceDialog/AddDeviceDialog';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { ROLES } from '../../../services/util/consts';
import NewDeviceButton from './NewDeviceButton';
import classes from './DeviceListSearchPanel.module.css';
import respUtil from '../../../services/util/responsibilityUtil';

const CheckBox = ({
	active,
	label,
	title,
	color,
	value,
	name,
	handleClick,
	style,
	...rest
}) => {
	return (
		<div
			className={
				name == active ? classes.ActiveStateBtnDiv : classes.StateBtnDiv
			}
		>
			<Button
				className={classes.StateBtn}
				color={color}
				onClick={e => handleClick(e)}
				{...rest}
				name={name}
			>
				{title}
			</Button>
			<label
				className={
					name == active ? classes.ActiveStateLabel : classes.StateLabel
				}
			>
				{label}
			</label>
		</div>
	);
};

type Props = {
	t: TFunction,
	status: string[],
	onSearch: (search: Object) => void
};
type States = {
	text: string,
	status: string[]
};

class DeviceListSearchPanel extends React.Component<Props, States> {
	state = {
		text: '',
		status: this.props.status || [],
		showAddDeviceDialog: false,
		machineList: [],
		active: `all-checkbox-filter`
	};
	componentDidMount() {
		if (this.props.status) {
			let active = `all-checkbox-filter`;
			if (this.props.status.length > 0) {
				active = `${this.props.status[0]}-checkbox-filter`;
			}
			this.setState({ active });
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.status && this.props.status !== prevProps.status) {
			let active = `all-checkbox-filter`;
			if (this.props.status.length > 0) {
				active = `${this.props.status[0]}-checkbox-filter`;
			}
			this.setState({ active });

			this.setState({
				status: this.props.status,
				active
			});
		}
		if (this.props.vms !== prevProps.vms) {
			this.loadData();
		}
	}

	loadData = () => {
		const vmsProps = this.props.vms;
		if (vmsProps) {
			const machineList = vmsProps.map(vm => {
				return {
					key: vm.vmID,
					name: vm.customName || vm.name,
					state: vm.vendingMachineState,
					type: vm.machine,
					connectionState: vm.onlineState == 'offline' ? 0 : 1
				};
			});

			this.setState({ machineList: machineList });
		}
	};

	labelValidator = value => {
		if (value <= 999) {
			return value;
		} else {
			return '999+';
		}
	};

	renderLabel = (value: string) => {
		const ok = this.state.machineList.filter(machine => machine.state == 0);
		const programming = this.state.machineList.filter(
			machine => machine.state == 1
		);
		const warning = this.state.machineList.filter(
			machine => machine.state == 2
		);
		const error = this.state.machineList.filter(machine => machine.state == 3);
		const offline = this.state.machineList.filter(
			machine => machine.connectionState == 0
		);
		const online = this.state.machineList.filter(
			machine => machine.connectionState == 1
		);
		switch (value) {
			case 'ok':
				return this.labelValidator(ok.length);
			case 'programming':
				return this.labelValidator(programming.length);
			case 'warning':
				return this.labelValidator(warning.length);
			case 'error':
				return this.labelValidator(error.length);
			case 'offline':
				return this.labelValidator(offline.length);
			case 'online':
				return this.labelValidator(online.length);
		}
	};

	onChangeFilter = () => this.props.onSearch(this.state);

	handleTextSearchChange = (e: SyntheticInputEvent<*>) =>
		this.setState(
			{
				text: e.target.value
			},
			this.onChangeFilter
		);

	handleStatusClick = (value: string) => e => {
		const allState = [
			'all',
			'online',
			'offline',
			'error',
			'warning',
			'programming',
			'ok'
		];

		const active = e.target.name;

		let newStatusFilter = [...this.state.status];

		// Only machine state or connection state can be set at once, they can not be mixed!
		if (allState.includes(value)) {
			newStatusFilter = newStatusFilter.filter(curState =>
				allState.includes(curState)
			);
		}

		//Remove if already contained
		if (newStatusFilter.includes(value)) {
			newStatusFilter = newStatusFilter.filter(curState => curState != value);
		} else {
			if (allState.includes(value)) {
				if (allState.includes(value)) {
					newStatusFilter = [];
				}
				newStatusFilter.push(value);
			} else {
				newStatusFilter.push(value);
			}
		}

		//Remove if all contained
		if (value === 'all') {
			newStatusFilter = [];
		}

		this.setState(
			{ status: newStatusFilter, active: active },
			this.onChangeFilter
		);
	};

	handleOnCreateMachine(hid, mi) {
		this.props.onCreateMachine(hid, mi);
		this.hideAddDeviceDialog();
	}

	hideAddDeviceDialog() {
		this.setState({ showAddDeviceDialog: false });
	}

	showAddDeviceDialog() {
		this.setState({ showAddDeviceDialog: true });
	}

	renderAddDeviceDialog() {
		return (
			<Modal
				show={this.state.showAddDeviceDialog}
				onHide={() => this.hideAddDeviceDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i
							className="fa fa-cog"
							aria-hidden="true"
							style={{ marginRight: '10px' }}
						></i>
						{this.props.t('addDeviceDialog.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: '0px' }}>
					<AddDeviceDialog
						onCreateMachine={(hid, mi) => this.handleOnCreateMachine(hid, mi)}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	getFilterWidth() {
		let filterWidth = 'calc(100% ';
		let defButtonWidth = 7.25;
		if (respUtil.breakpoints.md) defButtonWidth = 0;
		filterWidth = `calc(100% - ${defButtonWidth}rem)`;
		return filterWidth;
	}

	render() {
		const { t } = this.props;

		const allStateBoxes = [
			{
				title: t('device.status.all'),
				status: 'all'
			},
			{
				title: t('device.status.online'),
				status: 'online'
			},
			{
				title: t('device.status.offline'),
				status: 'offline'
			},
			{
				title: t('device.status.ok'),
				status: 'ok'
			},
			{
				title: t('device.status.programming'),
				status: 'programming'
			},
			{
				title: t('device.status.warning'),
				status: 'warning'
			},
			{
				title: t('device.status.error'),
				status: 'error'
			}
		];
		const machineSelector = this.renderAddDeviceDialog();

		let addDeviceButton = null;
		if (this.props.user.privilege === ROLES.root) {
			addDeviceButton = (
				<Col xs={12} sm={4} md lg>
					<Button
						color="success"
						className="DefaultButton"
						onClick={() => this.showAddDeviceDialog()}
					>
						<i className="fa fa-plus mr-2" />
						&nbsp;
						{this.props.t('filterToolbar.add')}
					</Button>
				</Col>
			);
		} else {
			addDeviceButton = (
				<Col xs={12} sm={4} md lg>
					{' '}
					<NewDeviceButton onSave={this.props.refressTable} />
				</Col>
			);
		}

		return (
			<div>
				{machineSelector}

				<div className={classes.Panel}>
					<Container fluid>
						<Row noGutters>
							<Col>
								<Row noGutters className="mb-1 mt-1">
									{addDeviceButton}
								</Row>
							</Col>
							<div
								style={{
									display: 'inline-block',
									height: '42px',
									width: this.getFilterWidth()
								}}
							>
								<InputGroup className="mb-3">
									<InputGroup.Prepend>
										<InputGroup.Text
											id="basic-addon1"
											style={{
												width: '2.5rem',
												paddingBottom: '9px',
												marginBottom: '4px',
												height: '36px',
												marginTop: '3px'
											}}
										>
											<i className="fa fa-search" />
										</InputGroup.Text>
									</InputGroup.Prepend>

									<FormControl
										style={{ marginTop: '3px' }}
										className="form-control"
										type="text"
										placeholder={t(
											'devicelist.tableheader.filter.text.placeholder'
										)}
										onChange={this.handleTextSearchChange}
									/>
								</InputGroup>
							</div>
						</Row>
					</Container>

					<Row className={classes.BtnPanel}>
						{allStateBoxes.map((checkBox, idx) => {
							return (
								<CheckBox
									label={
										checkBox.status === 'all'
											? this.state.machineList.length
											: this.renderLabel(checkBox.status)
									}
									key={idx}
									name={`${checkBox.status}-checkbox-filter`}
									title={checkBox.title.toUpperCase()}
									color={getColorForState(checkBox.status)}
									value={this.state.status.indexOf(checkBox.status) !== -1}
									handleClick={this.handleStatusClick(checkBox.status)}
									active={this.state.active}
								/>
							);
						})}
					</Row>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user
});

export default connect(mapStateToProps)(
	withTranslation()(DeviceListSearchPanel)
);
