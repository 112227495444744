//@flow
import React from 'react'

import { withTranslation } from 'react-i18next'
import AddUserContainer from '../Users/AddUserContainer'
import { ROLES } from '../../services/util/consts'

type Props = {
  t: (key: string) => string,
  className: string,
}
type States = {}
class AddNewAdmin extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    const title = this.props.t('addnewadmin.card1.title')

    return (
      <div>
        <AddUserContainer
          title={title}
          privileges={[
            {
              label: 'Admin',
              value: ROLES.companyAdmin,
            },
          ]}
          {...this.props}
        />
      </div>
    )
  }
}

export default withTranslation()(AddNewAdmin)
