// @flow
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
	Button,
	Col,
	Row,
	TabPane,
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import DashboardCardContainerWithFlow from '../../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import Spinner from '../../../components/UI/Spinner/Spinner';
import DeviceDetailsTabs from '../../../components/Tabs';
import walletApi from '../../../services/api/wallet';
import messagesApi from '../../../services/api/messages';
import PurchasesPanel from '../PurchasesPanel/PurchasesPanel';
import BalancePanel from '../BalancePanel/BalancePanel';
import InvoicePanel from '../InvoicePanel/InvoicePanel';
import MessagePanel from '../../../components/MessagePanel/MessagePanel';
import machineApi from '../../../services/api/machines';
import InvisibleTouchutil from '../../../services/util/invisibleTouchUtil';
import qs from 'qs';
import Overview from '../OverviewCard/OverviewCard';
import ConfimationDialog from '../../ConfirmationDialog/ConfirmationDialog';
import FilterBar from '../../../views/FilterBar/FilterBar';

class CustomerDetailer extends Component {
	state = {
		loading: true,
		loadedPurchaser: [],
		activePageKey: 0,
		transactions: [],
		currentFilterValue: ''
	};
	componentDidMount() {
		if (
			this.props.loadedCustomer &&
			this.props.loadedCustomer.userID &&
			this.props.loadedCustomer.walletID
		) {
			this.loadData();
		}
	}

	getActivePage = () => {
		const urlParams = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true
		});
		let pageIndex = 0;
		if (urlParams && urlParams.page) {
			try {
				pageIndex = parseInt(urlParams.page);
			} catch (error) {}
		}
		return pageIndex;
	};

	loadData = () => {
		const { loadedCustomer } = this.props;
		let machines = [];
		let activePageKey = this.getActivePage() ? this.getActivePage() : 0;
		machineApi.getVisibleVendingMachines().then(mg => {
			if (mg && mg.data && mg.data.response) {
				machines = mg.data.response;
			}
		});
		let transactions = walletApi
			.getTransactionsCountOfWallet(
				loadedCustomer.userID,
				loadedCustomer.walletID
			)
			.then(trans => {
				if (trans && trans.data && trans.data.transactionCount) {
					return walletApi.getTransactionsOfWallet(
						loadedCustomer.userID,
						loadedCustomer.walletID,
						trans.data.transactionCount
					);
				}
			});
		let balances = walletApi.getBalanceOfWallet(
			loadedCustomer.userID,
			loadedCustomer.walletID
		);
		let messages = messagesApi.getMessagesFromUser(loadedCustomer.userID);
		Promise.all([transactions, balances, messages]).then(values => {
			let transactions = [];
			let balances = [];
			let messages = [];
			if (values && values.length > 0) {
				if (values[0] && values[0].data && values[0].data.transactions) {
					transactions = values[0].data.transactions;
				}
				if (values[1] && values[1].data && values[1].data.walletBalance) {
					balances = values[1].data.walletBalance;
				}
				if (values[2] && values[2].data && values[2].data.messages) {
					messages = values[2].data.messages;
				}
			}
			const addValidated = false;
			const removeValidated = false;
			const addAmount = '';
			const removeAmount = '';
			const addRemarkText = '';
			const removeRemarkText = '';
			this.setState(
				{
					loadedPurchaser: loadedCustomer,
					openIdx: loadedCustomer.userID,
					transactions: transactions,
					balance: balances,
					messages: messages,
					loading: false,
					machines,
					activePageKey,
					addValidated,
					removeValidated,
					addAmount,
					removeAmount,
					addRemarkText,
					removeRemarkText,
					refund: false
				},
				() => this.filterTransactions()
			);
		});
	};
	//-------------------- Balance hadling --------------

	sendMessagehandler = (body, row) => {
		messagesApi
			.sendEmailMessage(body)
			.then(res => this.loadingPurchaserhandler(row))
			.catch(error => console.log(error));
	};

	sendMessagehandler = (body, row) => {
		messagesApi
			.sendEmailMessage(body)
			.then(res => this.loadingPurchaserhandler(row))
			.catch(error => console.log(error));
	};

	sendMessagehandler = (body, row) => {
		if ((body, row)) {
			messagesApi
				.sendEmailMessage(body)
				.then(res => this.loadData())
				.catch(error => console.log(error));
		}
	};

	//-------------------- Balance hadling --------------
	validationHandler = add => {
		const form = document.getElementById(
			add
				? InvisibleTouchutil.formID.ADD_BALANCE_FORM
				: InvisibleTouchutil.formID.REMOVE_BALANCE_FORM
		);

		if (form.checkValidity() === true) {
			add ? this.showConfirmationDialog(add) : this.showConfirmationDialog();
		} else {
			add
				? this.setState({ addValidated: true })
				: this.setState({ removeVlidated: true });
		}
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	onSaveHandler = (add, refund) => {
		if (add && refund) {
			return this.showConfirmationDialog(add, refund);
		} else if (add) {
			return this.validationHandler(add);
		} else {
			return this.validationHandler();
		}
	};
	refundHandler = () => {
		if (this.state.transactionIndex && this.state.addRemarkText) {
			walletApi
				.refundTransaction(
					this.state.transactionIndex,
					this.state.addRemarkText
				)
				.then(res => this.loadData());
		}
	};

	addCreditHandler = add => {
		const body = {
			userID: this.props.loadedCustomer.userID,
			customerWalletID: this.props.loadedCustomer.walletID,
			amount: add ? this.state.addAmount : -Math.abs(this.state.removeAmount),
			remarkText: add ? this.state.addRemarkText : this.state.removeRemarkText
		};
		walletApi.addCreditToAnAccount(body).then(res => this.loadData(add));
	};
	hideConfirmationDialog = () => {
		let a;
		if (this.state.add) {
			a = false;
		}
		let r;
		if (this.state.refund) {
			r = false;
		}
		this.setState({
			balanceeConfirmationDialog: false,
			add: a,
			refund: r,
			addAmount: null,
			addRemarkText: null
		});
	};
	showConfirmationDialog = (add, refund) => {
		let a = add;
		let r = refund;
		this.setState({ balanceeConfirmationDialog: true, add: a, refund: r });
	};
	renderBalanceeConfirmationDialog = () => {
		let qestion = '';
		let title = '';
		let credit = '';
		if (this.state.add) {
			qestion =
				'Biztosan hozzá kívánja adni az alábbi Cereditet a vásárló egyenlegéhez?';
			title = 'Credit :';
			credit = this.state.addAmount ? this.state.addAmount : 0;
		} else {
			qestion =
				'Biztosan vissza kívánja vonni az alábbi Cereditet a vásárló egyenlegéből?';
			title = 'Credit :';
			credit = this.state.removeAmount ? this.state.removeAmount : 0;
		}
		return (
			<ConfimationDialog
				title="Confirmation"
				confirmationText={
					<>
						<Col>{qestion}</Col>
						<Col>
							<Row>
								<Col>{title}</Col>
								<Col style={{ fontWeight: 'bold' }}>{credit}</Col>
							</Row>
						</Col>
					</>
				}
				okText={this.props.t('device.details.invisibleTouch.ok')}
				cancelText={this.props.t('device.details.invisibleTouch.cancel')}
				isOpen={this.state.balanceeConfirmationDialog}
				onToggle={() => {
					this.hideConfirmationDialog();
				}}
				onCancel={() => {
					this.hideConfirmationDialog();
				}}
				onOk={() => {
					this.hideConfirmationDialog();
					this.state.refund
						? this.refundHandler()
						: this.addCreditHandler(this.state.add);
				}}
			/>
		);
	};
	// ------ Refund handling ----------------

	handleRefund = (value, remark, transactionIndex) => {
		if (value && remark && transactionIndex) {
			this.setState(
				{
					balanceeConfirmationDialog: true,
					add: true,
					refund: true,
					addAmount: value,
					addRemarkText: remark,
					transactionIndex
				},
				() => this.onSaveHandler(true, true)
			);
		}
	};

	// ------ Refund handling ----------------

	// ------ BillingState handling ----------------

	editBillingStateHandler = (bsID, row, walletID) => {
		if (bsID && row && row.id && walletID) {
			let body = {
				transactionID: row.id,
				billingStateID: bsID
			};

			walletApi.editBillingState(body).then(res => this.loadData());
		}
	};

	// ------ BillingState handling ----------------

	//-------------------- Balance hadling --------------

	handleActivePageChange(newPageKey) {
		this.setState({ activePageKey: newPageKey }, () => {
			const newURL = `#/invisible_touch_manager/customerDetailer?page=${newPageKey}`;
			// We only want to replace the current url without any navigation event,
			// since we have already loaded every neccesary data
			window.history.pushState({}, null, newURL);
		});
	}

	renderFilterBar = () => {
		return (
			<FilterBar
				key="filterbar"
				onFilterChanged={value => this.onFilterChange(value)}
			/>
		);
	};

	filterTransactions() {
		let filteredTransactions = [...this.state.transactions];
		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredTransactions = this.state.transactions.filter(transactions => {
				let curTransactions = { ...transactions };
				let promotionFieldArray = Object.values(curTransactions);
				return promotionFieldArray.some(
					val =>
						String(val)
							.toUpperCase()
							.indexOf(filterValue) >= 0
				);
			});
		}

		this.setState({ filteredTransactions: filteredTransactions });
	}

	onFilterChange(filteValue) {
		this.setState({ currentFilterValue: filteValue }, () =>
			this.filterTransactions()
		);
	}

	renderTabs = () => {
		const { t, loadedCustomer } = this.props;
		const { machines, balance, filteredTransactions, messages } = this.state;
		return (
			<Row style={{ justifyContent: 'space-between' }}>
				{this.renderBalanceeConfirmationDialog()}
				<Col sm="12">
					<DeviceDetailsTabs
						onChangeActivePage={newPageKey =>
							this.handleActivePageChange(newPageKey)
						}
						loadedPurchaser={this.state.loadedPurchaser}
						activetab={this.state.activePageKey}
					>
						<TabPane title={t('invisibleTouchManager.controlpanel.overview')}>
							{machines &&
							loadedCustomer &&
							filteredTransactions &&
							balance &&
							!this.state.loading ? (
								<Overview
									machines={machines}
									balance={balance}
									data={loadedCustomer}
									transactions={filteredTransactions}
									removeAmount={this.state.removeAmount}
									addAmount={this.state.addAmount}
									removeRemarkText={this.state.removeRemarkText}
									addRemarkText={this.state.addRemarkText}
									addValidated={this.state.addValidated}
									removeValidated={this.state.removeValidated}
									onSave={this.onSaveHandler}
									onInputChange={this.inputChangeHandler}
								/>
							) : (
								<Spinner />
							)}
						</TabPane>
						<TabPane
							title={t('invisibleTouchManager.controlpanel.purchaseTrans')}
						>
							{machines && loadedCustomer && filteredTransactions ? (
								<PurchasesPanel
									machines={machines}
									data={loadedCustomer}
									transactions={filteredTransactions}
									onRefund={this.handleRefund}
									onRenderFilterBar={this.renderFilterBar}
									onFilterChange={this.onFilterChange}
								/>
							) : (
								<Spinner />
							)}
						</TabPane>
						<TabPane
							title={t('invisibleTouchManager.controlpanel.balanceTrans')}
						>
							{true &&
							loadedCustomer &&
							loadedCustomer.currency &&
							filteredTransactions &&
							balance ? (
								<BalancePanel
									currency={loadedCustomer.currency}
									balance={balance}
									data={loadedCustomer}
									transactions={filteredTransactions}
									onAddCredit={this.props.onAddCredit}
									onRenderFilterBar={this.renderFilterBar}
									onFilterChange={this.onFilterChange}
								/>
							) : (
								<Spinner />
							)}
						</TabPane>
						<TabPane title={t('invisibleTouchManager.controlpanel.invoices')}>
							{loadedCustomer && filteredTransactions ? (
								<InvoicePanel
									data={loadedCustomer}
									transactions={filteredTransactions}
									onEditBillingState={this.editBillingStateHandler}
									onRenderFilterBar={this.renderFilterBar}
									onFilterChange={this.onFilterChange}
								/>
							) : (
								<Spinner />
							)}
						</TabPane>
						<TabPane title={t('invisibleTouchManager.controlpanel.messages')}>
							{loadedCustomer && filteredTransactions && messages ? (
								<MessagePanel
									data={loadedCustomer}
									messages={messages}
									onSendMessage={this.sendMessagehandler}
								/>
							) : (
								<Spinner />
							)}
						</TabPane>
					</DeviceDetailsTabs>
				</Col>
			</Row>
		);
	};

	renderPage() {
		const { t, loadedCustomer } = this.props;
		let loadedEmail = t('invisibleTouchManager.controlpanel.noData');
		if (loadedCustomer && loadedCustomer.email)
			loadedEmail = loadedCustomer.email;

		return (
			<div className="animated fadeIn">
				<Row>
					<DashboardCardContainerWithFlow>
						<Card
							className={this.props.className}
							style={{
								...this.props.style,
								minWidth: '100%'
							}}
						>
							<CardBody>
								<Row
									style={{
										justifyContent: 'space-between',
										marginBottom: '10px'
									}}
								>
									<Col xs={12} sm={6} md={6} lg={3}>
										<CardTitle className="mb-0 text-muted">
											<i
												className="icon-settings"
												style={{ marginRight: '10px' }}
											></i>
											{t('invisibleTouchManager.controlpanel.title')}
										</CardTitle>

										<div className="small text-muted"></div>
									</Col>
									<Col xs={12} sm={12} md={12} lg={6}>
										<Row>
											<Col xs={12} sm={12} md={12} lg={12}>
												<label
													style={{ marginRight: '10px', fontSize: 'large' }}
												>
													{t('invisibleTouchManager.controlpanel.label')}
													{': '}
												</label>
												<label
													style={{
														marginRight: '10px',
														fontSize: 'large',
														fontWeight: 'bold'
													}}
												>
													{loadedEmail}{' '}
												</label>
											</Col>
										</Row>
									</Col>
									<Col
										xs={12}
										sm={12}
										md={12}
										lg={3}
										style={{ display: 'flex', justifyContent: 'flex-end' }}
									>
										<Button
											style={{ height: '40px' }}
											className="DefaultButtonMd"
											color="info"
											onClick={() =>
												this.props.history.push('/invisible_touch_manager')
											}
										>
											<i className={'fa fa-reply mr-2'}></i>&nbsp;
											{t('invisibleTouchManager.controlpanel.backButton')}
										</Button>
									</Col>
								</Row>
								<> {this.state.loading ? <Spinner /> : this.renderTabs()}</>
							</CardBody>
						</Card>
					</DashboardCardContainerWithFlow>
				</Row>
			</div>
		);
	}

	render() {
		console.log('state', this.state);
		return <> {this.renderPage()}</>;
	}
}

const mapStateToProps = state => ({
	loadedCustomer: state.invisibleTouch.currentCustomer
});

export default connect(mapStateToProps)(
	withRouter(withTranslation()(CustomerDetailer))
);
