export default {
	CUSTOM_COOLING_SYSTEM: {
		name: 'CUSTOM_COOLING_SYSTEM',
		id: 36,
		localisation: 'device.details.datasheet.peripherals.cooling',
		type: 0
	},
	CUSTOM_SODA_UNIT: {
		name: 'CUSTOM_SODA_UNIT',
		id: 37,
		localisation: 'device.details.datasheet.peripherals.soda',
		type: 0
	},
	CUSTOM_MOBILE_PAYMENT_SYSTEM: {
		name: 'CUSTOM_MOBILE_PAYMENT_SYSTEM',
		id: 43,
		localisation: 'device.details.datasheet.peripherals.paymentSys.mobilPay',
		type: 1
	},
	CUSTOM_TAX_MONITORING_SYSTEM: {
		name: 'CUSTOM_TAX_MONITORING_SYSTEM',
		id: 41,
		localisation: 'device.details.datasheet.peripherals.paymentSys.taxMonitor',
		type: 1
	},
	CUSTOM_WATER_SYSTEM: {
		name: 'CUSTOM_WATER_SYSTEM',
		id: 38,
		localisation: 'device.details.datasheet.peripherals.waterSys.title',
		type: 0
	},
	CUSTOM_COIN_VALIDATOR: {
		name: 'CUSTOM_COIN_VALIDATOR',
		id: 39,
		localisation:
			'device.details.datasheet.peripherals.paymentSys.coinValidator',
		type: 1
	},
	CUSTOM_BANKNOTE_VALIDATOR: {
		name: 'CUSTOM_BANKNOTE_VALIDATOR',
		id: 40,
		localisation:
			'device.details.datasheet.peripherals.paymentSys.banknoteValidator',
		type: 1
	},
	CUSTOM_CASHLESS_PAYMENT_SYSTEM: {
		name: 'CUSTOM_CASHLESS_PAYMENT_SYSTEM',
		id: 42,
		localisation: 'device.details.datasheet.peripherals.paymentSys.cashless',
		type: 1
	},
	CUSTOM_SNAKY_PRODUCT_STORAGE: {
		name: 'CUSTOM_SNAKY_PRODUCT_STORAGE',
		id: 44,
		localisation: 'device.details.datasheet.peripherals.snakyStorage.title',
		type: 0
	}
};
