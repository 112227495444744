import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MediaAppearances from '../../views/Media/MediaAppearances';

class MediaRoute extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/media" name="Media" component={MediaAppearances} />
                    <Redirect from="/" to="/media" />
                </Switch>
            </div>
        );
    }
}

export default MediaRoute;