import React, { Component } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
// import { HorizontalBar } from 'react-chartjs-2';
// import Dropdown from 'react-bootstrap/Dropdown';
import MachinePerformanceMonitor from './MachinePerformanceMonitor';

import statisticsAPI from '../../../../services/api/statistics';

class MachinePerformanceCard extends Component {
	state = { statisticsData: { days7: null, days31: null } };

	componentDidMount() {
		this.loadData();
	}

	loadData() {
		statisticsAPI.getBestAndWorstMachines().then(resp => {
			this.setState({ statisticsData: resp.data });
		});
	}

	render() {
		return (
			<Card className={this.props.className} style={this.props.style}>
				<CardBody>
					<Row>
						<Col sm="6" xs="12">
							<MachinePerformanceMonitor
								title={this.props.t(
									'customerDashboard.machinePerformanceCard.titleBest'
								)}
								statisticsData={
									this.props.daysCount == 7
										? this.state.statisticsData.days7
										: this.state.statisticsData.days31
								}
								datasets={['bestSalesSum', 'bestSalesCount']}
							/>
						</Col>
						<Col sm="6" xs="12">
							<MachinePerformanceMonitor
								title={this.props.t(
									'customerDashboard.machinePerformanceCard.titleWorst'
								)}
								statisticsData={
									this.props.daysCount == 7
										? this.state.statisticsData.days7
										: this.state.statisticsData.days31
								}
								datasets={['worstSalesSum', 'worstSalesCount']}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

export default withTranslation()(MachinePerformanceCard);
