import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card, TabPane, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';
import machineApi from '../../services/api/machines';
import Modal from 'react-bootstrap/Modal';
import walletApi from '../../services/api/wallet';
import messagesApi from '../../services/api/messages';
import systemApi from '../../services/api/system';
import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import DeviceDetailsTabs from '../../components/Tabs';
import CustomerList from './CustomerList/CustomerList';
import FilterBar from '../FilterBar/FilterBar';
import store from '../../store/index';
import { setLoadedCustomer } from '../../store/invisibleTouch';
import respUtil from '../../services/util/responsibilityUtil';
import TransactionList from './TransactionList/TransactionList';
import InvoiceList from './InvoiceList/InvoiceList';

class InvisibleTouchManager extends Component {
	state = {
		currency: [],
		partnerWalet: [],
		purchasers: [],
		filteredPurchasers: [],
		panel: null,
		openIdx: null,
		transactions: [],
		machines: [],
		messages: [],
		filtered: true,
		activePageKey: 0,
		currentFilterValue: '',
		filteredCustomers: []
	};
	componentDidMount = () => {
		this.loadPartnerWalletData(this.props.usePrivilege.cmID);
		this.loadMachines(() => this.loadPurchasers());
	};

	loadMachines = promise => {
		machineApi.getVisibleVendingMachines().then(mg => {
			let machines = mg ? mg.data.response : [];
			this.setState(
				{
					machines: machines
				},
				() => promise()
			);
		});
	};

	loadPartnerWalletData = cmID => {
		if (cmID) {
			walletApi.getPartnerWallet(cmID).then(res => {
				if (
					res &&
					res.data &&
					res.data.wallets &&
					res.data.wallets.length > 0 &&
					res.data.wallets[0].currencyID
				) {
					systemApi.getCurrencies().then(cur => {
						if (
							cur &&
							cur.data &&
							cur.data.currencies &&
							cur.data.currencies.length > 0
						) {
							let currency = cur.data.currencies.filter(
								e => e.id === res.data.wallets[0].currencyID
							);
							this.setState({
								partnerWalet: res.data.wallets,
								currency: currency
							});
						}
					});
				}
			});
		}
	};

	loadPurchasers = () => {
		walletApi.getPartnerWallet(this.props.usePrivilege.cmID).then(res => {
			if (
				res &&
				res.data &&
				res.data.wallets &&
				res.data.wallets.length > 0 &&
				res.data.wallets[0].id
			) {
				walletApi.getPurchasers(res.data.wallets[0].id).then(res => {
					if (res && res.data && res.data.users && res.data.users.length > 0) {
						this.setState(
							{
								purchasers: res.data.users,
								filteredPurchasers: res.data.users.filter(e => e.email != null)
							},
							() => this.filterCustomers()
						);
					}
				});
			}
		});
	};

	loadingPurchaserhandler = row => {
		const currency = this.state.currency;
		let param = { ...row, currency };
		store.dispatch(setLoadedCustomer(param));
		this.props.history.push('/invisible_touch_manager/customerDetailer?page=0');
	};

	linkToPage = (page, row) => {
		const currency = this.state.currency;
		let param = { ...row, currency };
		store.dispatch(setLoadedCustomer(param));
		this.props.history.push(
			`/invisible_touch_manager/customerDetailer?page=${page}`
		);
	};

	handleActivePageChange(newPageKey) {
		this.setState({ activePageKey: newPageKey }, () => {
			const newURL = `#/invisible_touch_manager?page=${newPageKey}`;
			// We only want to replace the current url without any navigation event,
			// since we have already loaded every neccesary data
			window.history.pushState({}, null, newURL);
		});
	}

	addCredithandler = (body, row) => {
		walletApi
			.addCreditToAnAccount(body)
			.then(res => this.loadingPurchaserhandler(row), this.showAlertModal());
	};

	showAlertModal = () => {
		this.setState({
			showAlertModal: true
		});
	};

	hideAlertModal = () => {
		this.setState({
			showAlertModal: false
		});
	};

	renderAlertModal = () => {
		return (
			<Modal
				show={this.state.showAlertModal}
				onHide={() => this.hideAlertModal()}
				centered
			>
				<Modal.Header
					closeButton
					style={{
						backgroundColor: 'var(--balance-mid)',
						color: 'var(--balance)'
					}}
				>
					<Modal.Title>
						{this.props.t('invisibleTouchManager.blancePanel.creditAlert')}
					</Modal.Title>
				</Modal.Header>
			</Modal>
		);
	};

	sendMessagehandler = (body, row) => {
		messagesApi
			.sendEmailMessage(body)
			.then(res => this.loadingPurchaserhandler(row))
			.catch(error => console.log(error));
	};

	editBillingStateHandler = (bsID, row, walletID) => {
		let body = {
			transactionID: row.id,
			billingStateID: bsID
		};

		walletApi
			.editBillingState(body)
			.then(res => this.loadingPurchaserhandler(walletID));
	};

	filterCustomers() {
		let customers = this.state.filtered
			? [...this.state.filteredPurchasers]
			: [...this.state.purchasers];
		let filteredCustomers = customers;
		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredCustomers = customers.filter(customer => {
				let curCustomers = { ...customer };
				let promotionFieldArray = Object.values(curCustomers);
				return promotionFieldArray.some(
					val =>
						String(val)
							.toUpperCase()
							.indexOf(filterValue) >= 0
				);
			});
		}

		this.setState({ filteredCustomers: filteredCustomers });
	}
	renderFilterBar = () => {
		return (
			<FilterBar
				filtered={this.state.filtered}
				key="filterbar"
				showFilterDeletedCustomersButton
				isFiltered={filtered =>
					this.setState({ filtered: filtered }, () => this.filterCustomers())
				}
				onFilterChanged={value => this.onFilterChange(value)}
			/>
		);
	};
	onFilterChange(filteValue) {
		this.setState({ currentFilterValue: filteValue }, () =>
			this.filterCustomers()
		);
	}

	render() {
		const { t } = this.props;
		return (
			<div className="animated fadeIn">
				<DashboardCardContainerWithFlow>
					<Card body>
						<PageTiltleCard
							title={this.props.t('invisibleTouchManager.title')}
						/>
						<Col sm="12">
							<DeviceDetailsTabs
								onChangeActivePage={newPageKey =>
									this.handleActivePageChange(newPageKey)
								}
								activetab={this.state.activePageKey}
							>
								<TabPane
									title={t('invisibleTouchManager.purchasersTable.title')}
								>
									<CustomerList
										data={this.state.filteredCustomers}
										openIdx={this.state.openIdx}
										onLoadPurchaser={this.loadingPurchaserhandler}
										onFilterDeleted={this.filterDeletedPurchasers}
										renderFilterBar={this.renderFilterBar}
										onFilterChange={this.onFilterChange}
										onLink={this.linkToPage}
										hideFilter={respUtil.breakpoints.sm}
									/>
								</TabPane>
								<TabPane
									title={t('invisibleTouchManager.transactionTable.title')}
								>
									<TransactionList
										partnerWalet={this.state.partnerWalet}
										currency={this.state.currency}
										data={this.state.filteredCustomers}
									/>
								</TabPane>
								<TabPane title={t('invisibleTouchManager.invoicesTable.title')}>
									<InvoiceList
										partnerWalet={this.state.partnerWalet}
										currency={this.state.currency}
										data={this.state.filteredCustomers}
									/>
								</TabPane>
							</DeviceDetailsTabs>
						</Col>
					</Card>
				</DashboardCardContainerWithFlow>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	usePrivilege: state.user,
	IVTState: state.invisibleTouch
});

export default connect(mapStateToProps)(
	withRouter(withTranslation()(InvisibleTouchManager))
);
