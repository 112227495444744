//@flow
import React from 'react'

import { withRouter } from 'react-router-dom'
import { RedMachineButton } from '../../../../components/Machines/MachineComponents'

import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard'
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent'

import LuceX2KeyboardHeader from './LuceX2KeyboardHeader'

type Props = {
  products?: Array<Object>,
}

class LuceX2 extends React.Component<Props> {
  keyboardConfig = [
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            1 <br />
            <i className="fa fa-long-arrow-up mr-1" />
            <i className="fa fa-long-arrow-up mr-1" />
          </>
        ),
        buttonID: '1',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            5 <br />-
          </>
        ),
        buttonID: '1',
      },
      {
        buttonContent: <>9</>,
        buttonID: '9',
      },
      {
        buttonContent: <>13</>,
        buttonID: '13',
      },
      {
        buttonContent: <>17</>,
        buttonID: '17',
      },
      {
        buttonContent: <>21</>,
        buttonID: '21',
      },
    ],
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            2 <br />
            <i className="fa fa-long-arrow-up mr-1" />
          </>
        ),
        buttonID: '2',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            6 <br />
            <i className="fa fa-long-arrow-down mr-1" />
            <i className="fa fa-long-arrow-down mr-1" />
          </>
        ),
        buttonID: '6',
      },
      {
        buttonContent: <>10</>,
        buttonID: '10',
      },
      {
        buttonContent: <>14</>,
        buttonID: '14',
      },
      {
        buttonContent: <>18</>,
        buttonID: '18',
      },
      {
        buttonContent: <>22</>,
        buttonID: '22',
      },
    ],
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            3 <br />
            <i className="fa fa-long-arrow-down mr-1" />
          </>
        ),
        buttonID: '3',
      },
      {
        buttonContent: <>7</>,
        buttonID: '7',
      },
      {
        buttonContent: <>11</>,
        buttonID: '11',
      },
      {
        buttonContent: <>15</>,
        buttonID: '15',
      },
      {
        buttonContent: <>19</>,
        buttonID: '19',
      },
      {
        buttonContent: <>23</>,
        buttonID: '23',
      },
    ],

    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            4 <br />+
          </>
        ),
        buttonID: '4',
      },
      {
        buttonContent: <>8</>,
        buttonID: '8',
      },
      {
        buttonContent: <>12</>,
        buttonID: '12',
      },
      {
        buttonContent: <>16</>,
        buttonID: '16',
      },
      {
        buttonContent: <>20</>,
        buttonID: '20',
      },
      {
        buttonContent: <>24</>,
        buttonID: '24',
      },
    ],
  ]

  render() {
    const { products = [] } = this.props

    return (
      <DirectChooseMachineBaseComponent
        machineTitle="LuceX2"
        lineNumber={2}
        lineLength={16}
        ledNumbers={0}
        keyboard={
          <DirectChooseKeyboard
            keyboardHeaderLine={<LuceX2KeyboardHeader />}
            keyboardConfig={this.keyboardConfig}
            products={products}
            type="vertical"
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(LuceX2)
