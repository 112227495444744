import React, { Component } from 'react';
import { Card, Col, Row, Button } from 'reactstrap';
import {
	Section,
	SectionList,
	SectionTitle,
	SectionSmallTitle
} from '../../../../components/Section';
import machineAPI from '../../../../services/api/machines';
import { Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import CustomPeripherialSheet from './CustomPeripherialSheet';
import ConfimationDialog from '../../../ConfirmationDialog/ConfirmationDialog';
import classes from './Datasheet.module.css';
import customHardwareTypes from '../../../../services/types/customHardwareTypes';

class CostumPeripherialsCard extends Component {
	state = {
		machine: null,
		peripherials: null,
		paymentSys: null,
		peripherialType: 36,
		paymantSysType: 39
	};

	componentDidMount() {
		this.loadPeriperials();
	}

	loadPeriperials = () => {
		machineAPI.getVMPeripherials(this.props.vmId).then(resp => {
			let machine = { ...this.props.machine };
			let peripherials = resp.data.connectedParts
				.filter(e =>
					Object.values(customHardwareTypes)
						.filter(pt => pt.type == 0)
						.map(pt => pt.name)
						.includes(e.name)
				)
				.filter(e => !e.slavePrefix);
			let paymentSys = resp.data.connectedParts.filter(e =>
				Object.values(customHardwareTypes)
					.filter(pt => pt.type == 1)
					.map(pt => pt.name)
					.includes(e.name)
			);
			let slavePeripherials = resp.data.connectedParts
				.filter(e =>
					Object.values(customHardwareTypes)
						.filter(pt => pt.type == 0)
						.map(pt => pt.name)
						.includes(e.name)
				)
				.filter(e => e.slavePrefix);
			let parts = [];
			parts = resp.data.connectedParts.map(e => {
				return { partID: e.partID };
			});
			this.setState({
				data: resp.data.connectedParts,
				peripherials: peripherials,
				paymentSys: paymentSys,
				machine: machine,
				parts: parts,
				slavePeripherials: slavePeripherials
			});
		});
	};

	renderPeriperials = value => {
		const { root, companyAdmin, regionalAdmin } = this.props;
		return value.map(e => {
			return (
				<Col xs={12} sm={12} md={12} lg={12}>
					<CustomPeripherialSheet
						editMode={this.state.editMode}
						peripherial={e}
						onDelet={this.handleDelete}
						machine={this.props.machine}
						reload={this.loadPeriperials}
						root={root}
						companyAdmin={companyAdmin}
						regionalAdmin={regionalAdmin}
						name={e.name ? this.peripherialNameFormater(e.name) : null}
					/>
				</Col>
			);
		});
	};
	peripherialNameFormater = name => {
		const { t } = this.props;
		let convertedName = t('device.details.datasheet.noData');

		const part = Object.values(customHardwareTypes).find(pt => pt.name == name);
		if (part) convertedName = t(part.localisation);

		return convertedName;
	};
	handleDelete = partID => {
		let parts = [];
		this.state.parts.map(e => {
			if (e.partID !== partID) {
				parts.push(e);
			}
		});
		machineAPI
			.setConnectedPeripherals(this.props.vmId, parts)
			.then(res => this.loadPeriperials());
	};

	addPeripherial = (type, prefix) => {
		let param = {
			partType: type,
			customerID: this.state.machine.cID,
			serialNumber: 0,
			hardwareState: 1,
			orderNumber: 1,
			firmwareName: '-',
			firmwareFilename: '-',
			microControllerType: '-',
			customManufacturerName: null,
			customType: null,
			customIdentifier: null,
			customSWVersion: null,
			customData: null
		};
		machineAPI.createMachineHardwarePart(param).then(res => {
			let parts = this.state.parts;
			parts.push(
				prefix ? { partID: res.id, slavePrefix: prefix } : { partID: res.id }
			);
			machineAPI
				.setConnectedPeripherals(this.props.vmId, parts)
				.then(res => this.loadPeriperials());
		});
	};

	setOptions = value => {
		return value.map((e, index) => (
			<option key={e.label + index} label={e.label} value={e.value}>
				{e.label}
			</option>
		));
	};
	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	hideConfirmationDialog() {
		this.setState({
			addPeripheralConfirmationVisible: false
		});
	}

	showConfirmation(value) {
		this.setState({
			addPeripheralConfirmationVisible: true,
			add: value
		});
	}
	renderConfirmationDialog = (PeripherialOptions, PaymentSysOptions) => {
		let peripherial = PeripherialOptions.concat(PaymentSysOptions).find(
			e => e.value == this.state.add
		);
		return (
			<ConfimationDialog
				title="Confirmation"
				confirmationText={
					<>
						<Col>
							{this.props.t('device.details.datasheet.peripherals.addConf')}
						</Col>
						<Col>
							<Row>
								<Col>
									{this.props.t('device.details.datasheet.peripherals.addPer')}
								</Col>
								<Col style={{ fontWeight: 'bold' }}>
									{peripherial ? peripherial.label : null}
								</Col>
							</Row>
						</Col>
					</>
				}
				okText={this.props.t('device.details.invisibleTouch.ok')}
				cancelText={this.props.t('device.details.invisibleTouch.cancel')}
				isOpen={this.state.addPeripheralConfirmationVisible}
				onToggle={() => {
					this.hideConfirmationDialog();
				}}
				onCancel={() => {
					this.hideConfirmationDialog();
				}}
				onOk={() => {
					this.hideConfirmationDialog();
					this.addPeripherial(this.state.add, this.props.slave);
				}}
			/>
		);
	};

	render() {
		const { t, root, companyAdmin, regionalAdmin, slave } = this.props;

		const peripherialOptions = Object.values(customHardwareTypes)
			.filter(pt => pt.type == 0)
			.map(pt => {
				return {
					label: t(pt.localisation),
					value: pt.id
				};
			});
		const paymentSysOptions = Object.values(customHardwareTypes)
			.filter(pt => pt.type == 1)
			.map(pt => {
				return {
					label: t(pt.localisation),
					value: pt.id
				};
			});

		const { machine } = this.state;
		if (!machine) return null;
		return (
			<>
				{this.renderConfirmationDialog(peripherialOptions, paymentSysOptions)}
				<Card>
					<SectionList>
						<Row>
							<Col>
								<Section
									className={classes.TitleRow}
									style={{ borderRadius: '10px 10px 0px 0px' }}
								>
									<Col>
										<SectionTitle>
											{slave
												? this.props.name +
												  t('device.details.datasheet.peripherals.title')
												: t('device.details.datasheet.peripherals.title')}
										</SectionTitle>
									</Col>
									{root || companyAdmin ? (
										<Row style={{ margin: '10px' }}>
											<Col xs={12} sm={12} md={12} lg={12}>
												<SectionSmallTitle>
													{t('device.details.datasheet.peripherals.new')}
												</SectionSmallTitle>
											</Col>
											<Col xs={12} sm={12} md={12} lg={8}>
												<Form.Control
													type="text"
													as="select"
													name="peripherialType"
													value={this.state.peripherialType}
													onChange={this.inputChangeHandler}
												>
													{this.setOptions(peripherialOptions)}
												</Form.Control>
											</Col>
											<Col xs={12} sm={12} md={12} lg={3}>
												<Button
													color={'success'}
													className="DefaultButton"
													onClick={() =>
														this.showConfirmation(this.state.peripherialType)
													}
												>
													<i class={'fa fa-plus mr-2'}></i>&nbsp;
													{t('device.details.peripheral.addPeripheral')}
												</Button>
											</Col>
										</Row>
									) : null}
								</Section>
							</Col>
						</Row>
						{!slave ? (
							<Row>
								{this.state.peripherials
									? this.renderPeriperials(this.state.peripherials)
									: null}
							</Row>
						) : (
							<Row>
								{this.state.slavePeripherials
									? this.renderPeriperials(
											this.state.slavePeripherials.filter(
												e => e.slavePrefix === slave
											)
									  )
									: null}
							</Row>
						)}
						{!slave ? (
							<>
								<Section className={classes.TitleRow}>
									<Col>
										<SectionTitle>
											{t(
												'device.details.datasheet.peripherals.paymentSys.title'
											)}
										</SectionTitle>
									</Col>
									{root || companyAdmin ? (
										<Row
											style={{
												margin: '10px'
											}}
										>
											<Col xs={12} sm={12} md={12} lg={12}>
												<SectionSmallTitle>
													{t(
														'device.details.datasheet.peripherals.paymentSys.new'
													)}
												</SectionSmallTitle>
											</Col>
											<Col xs={12} sm={12} md={8} lg={8}>
												<Form.Control
													type="text"
													as="select"
													name="paymantSysType"
													value={this.state.paymantSysType}
													onChange={this.inputChangeHandler}
												>
													{this.setOptions(paymentSysOptions)}
												</Form.Control>
											</Col>
											<Col xs={12} sm={12} md={4} lg={4}>
												<Button
													color={'success'}
													className="DefaultButton"
													onClick={() =>
														this.showConfirmation(this.state.paymantSysType)
													}
												>
													<i class={'fa fa-plus mr-2'}></i>&nbsp;
													{t('device.details.peripheral.addPeripheral')}
												</Button>
											</Col>
										</Row>
									) : null}
								</Section>
								<Row>
									{this.state.paymentSys
										? this.renderPeriperials(this.state.paymentSys)
										: null}
								</Row>
							</>
						) : null}
					</SectionList>
				</Card>
			</>
		);
	}
}

export default withTranslation()(CostumPeripherialsCard);
