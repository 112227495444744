import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import classes from './DeviceMonitor.module.css';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {
	GreenAccessButton,
	RedAccessButton
} from '../../../components/Machines/MachineComponents';
import realtime from '../../../services/api/realtime';
import DatePicker from 'react-datepicker';
import { now } from 'moment';
import { ROLES } from '../../../services/util/consts';

//--------- Command Panel prototype ------------

class CommandPanel extends Component {
	state = {
		infoText: '',
		logDate: now()
	};
	subscriptions = [];

	//StandByInfoTextDialog rendering and handleing

	showStandByInfoTextDialog() {
		this.setState({ showStandByInfoTextDialog: true });
	}

	hideStandByInfoTextDialog() {
		this.setState({ showStandByInfoTextDialog: false, infoText: '' });
		//	this.loadData();
	}

	showGetLogDialog() {
		this.setState({ showGetLogDialog: true });
	}

	hideGetLogDialog() {
		this.setState({ showGetLogDialog: false });
	}

	showRebootConfirmationDialog() {
		this.setState({
			showRebootConfirmationDialog: true
		});
	}

	hideRebootConfirmationDialog() {
		this.setState({
			showRebootConfirmationDialog: false
		});
	}

	sendInfotext = () => {
		realtime.sendCommand(
			this.props.machine.hardwareID,
			realtime.remoteCommands.setInfoText,
			this.state.infoText
		);
		this.hideStandByInfoTextDialog();
	};

	handleStartStopDebug() {
		if (this.state.isDebugging) {
			realtime.sendCommand(
				this.props.machine.hardwareID,
				realtime.remoteCommands.stopDebug,
				''
			);
			this.setState({
				isDebugging: false
			});
		} else {
			realtime.sendCommand(
				this.props.machine.hardwareID,
				realtime.remoteCommands.startDebug,
				''
			);
			this.setState({
				isDebugging: true
			});
		}
	}

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	renderSetStandbyText() {
		const { t } = this.props;
		return (
			<Modal
				show={this.state.showStandByInfoTextDialog}
				onHide={() => this.hideStandByInfoTextDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i class="icon-clipboard" style={{ marginRight: '10px' }}></i>
						{t('commandPanel.stbyInfo')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group>
							<Form.Label>{t('commandPanel.stbyInfo') + ' :'}</Form.Label>
							<Form.Control
								name="infoText"
								type="text"
								as="textarea"
								rows={10}
								placeholder={t('commandPanel.stbyInfo')}
								value={this.state.infoText}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Form>
					<Row>
						<Col>
							<Button variant="success" onClick={() => this.sendInfotext()}>
								{t('commandPanel.send')}
							</Button>
						</Col>
						<Col
							style={{
								display: 'flex',
								felxDirection: 'collumn',
								justifyContent: 'flex-end'
							}}
						>
							<Button
								variant="primary"
								onClick={() => this.hideStandByInfoTextDialog()}
							>
								{t('commandPanel.cancel')}
							</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		);
	}

	renderGetLogFileDialog() {
		const { t } = this.props;
		return (
			<Modal
				show={this.state.showGetLogDialog}
				onHide={() => this.hideGetLogDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i class="icon-clipboard" style={{ marginRight: '10px' }}></i>
						{t('commandPanel.getLogFile')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group>
							<div style={{ textAlign: 'center' }}>
								<Form.Label>{t('commandPanel.getLogFile') + ' :'}</Form.Label>

								<DatePicker
									selected={this.state.logDate}
									dateFormat="yyyy MMMM d"
									onChange={date => {
										this.setState({ logDate: date });
									}}
								/>
							</div>
						</Form.Group>
					</Form>
					<Row>
						<Col>
							<Button
								variant="success"
								onClick={() => {
									this.sendGetLogCommand();
									this.hideGetLogDialog();
								}}
							>
								{t('commandPanel.send')}
							</Button>
						</Col>
						<Col
							style={{
								display: 'flex',
								felxDirection: 'collumn',
								justifyContent: 'flex-end'
							}}
						>
							<Button variant="primary" onClick={() => this.hideGetLogDialog()}>
								{t('commandPanel.cancel')}
							</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		);
	}

	renderRebootConfirmation() {
		const { t } = this.props;
		return (
			<Modal
				show={this.state.showRebootConfirmationDialog}
				onHide={() => this.hideRebootConfirmationDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i class="icon-clipboard" style={{ marginRight: '10px' }}></i>
						{t('commandPanel.rebootRunner')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group>
							<Form.Label>
								{t('commandPanel.rebootRunnerConfirmation')}
							</Form.Label>
						</Form.Group>
					</Form>
					<Row>
						<Col>
							<Button
								variant="success"
								onClick={() => this.sendRebootCommand()}
							>
								{t('commandPanel.yes')}
							</Button>
						</Col>
						<Col
							style={{
								display: 'flex',
								felxDirection: 'collumn',
								justifyContent: 'flex-end'
							}}
						>
							<Button
								variant="primary"
								onClick={() => this.hideRebootConfirmationDialog()}
							>
								{t('commandPanel.no')}
							</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		);
	}

	sendGetScreenshotCommand() {
		realtime.sendCommand(
			this.props.machine.hardwareID,
			realtime.remoteCommands.takeScreenshot
		);
	}

	leadingZero(num, size) {
		num = num.toString();
		while (num.length < size) num = '0' + num;
		return num;
	}

	sendGetLogCommand() {
		const date = new Date(this.state.logDate);
		const dateString =
			date.getFullYear() +
			'.' +
			this.leadingZero(date.getMonth() + 1, 2) +
			'.' +
			this.leadingZero(date.getDate(), 2);
		realtime.sendCommand(
			this.props.machine.hardwareID,
			realtime.remoteCommands.getLogFile,
			dateString
		);
	}

	sendRebootCommand() {
		realtime.sendCommand(
			this.props.machine.hardwareID,
			realtime.remoteCommands.rebootRunner
		);
		this.hideRebootConfirmationDialog();
	}

	render() {
		const { t, machine } = this.props;

		let debugButton = null;
		if (
			this.props.usePrivilege &&
			this.props.usePrivilege.privilege == ROLES.root
		) {
			if (!this.state.isDebugging) {
				debugButton = (
					<GreenAccessButton onClick={() => this.handleStartStopDebug()}>
						<i className="fa fa-play mr-3" />
						{t('commandPanel.startDebug')}
					</GreenAccessButton>
				);
			} else {
				debugButton = (
					<RedAccessButton onClick={() => this.handleStartStopDebug()}>
						<i className="fa fa-stop mr-3" />
						{t('commandPanel.stopDebug')}
					</RedAccessButton>
				);
			}
		}

		return (
			<Container style={{ maxWidth: '100%' }}>
				{this.renderSetStandbyText()}
				{this.renderGetLogFileDialog()}
				{this.renderRebootConfirmation()}
				<Row className={classes.CommandRow}>
					<Col md="4" xs="12">
						{t('commandPanel.title')}
					</Col>
					<Col
						md="1"
						xs="12"
						style={{ textAlign: 'right', minHeight: '1rem' }}
					></Col>
					<Col
						md="7"
						xs="12"
						style={{ textAlign: 'center' }}
						className={classes.BtnCol}
					>
						<GreenAccessButton onClick={() => this.showStandByInfoTextDialog()}>
							<i className="fa fa-clipboard mr-2" />
							{t('commandPanel.stbyInfo')}
						</GreenAccessButton>
						<GreenAccessButton onClick={() => this.sendGetScreenshotCommand()}>
							<i className="fa fa-desktop mr-3" />
							{t('commandPanel.scrShot')}
						</GreenAccessButton>
						<GreenAccessButton onClick={() => this.showGetLogDialog()}>
							<i className="fa fa-file mr-3" />
							{t('commandPanel.getLog')}
						</GreenAccessButton>
						<GreenAccessButton
							onClick={() => this.showRebootConfirmationDialog()}
						>
							<i className="fa fa-repeat mr-3" />
							{t('commandPanel.rebootRunner')}
						</GreenAccessButton>
						{debugButton}
					</Col>
				</Row>
			</Container>
		);
	}
}

const mapStateToProps = state => ({
	usePrivilege: state.user
});

export default withRouter(
	connect(mapStateToProps)(withTranslation()(CommandPanel))
);
