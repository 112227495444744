import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Footer from '../../components/Footer/Footer';

import Dashboard from '../../views/Dashboard/Dashboard';

import Logout from '../../views/Logout/Logout';
//Devices
import DebugConsol from '../../views/RoomSocket/VMDebugConsol';
//PrivRouter
import PrivateRoute from '../../views/PrivateRoute/PrivateRoute.js';
import SafePrivateRoute from '../../views/PrivateRoute/SafePrivateRoute.js';

import VMS from '../Vms/Vms.js';
import CUSTOMERUSERS from '../CustomerUsers/CustomerUsers.js';
import ROOTUSERS from '../RootUsers/RootUsers.js';
import HardwareManagement from '../HardwareManagement/HardwareManagement';
import PartnerManagement from '../PartnerManagement/PartnerManagement';
import USERS from '../Users/Users.js';
import CompanyProfile from '../CompanyProfile/CompanyProfile.js';
import LOGSLIST from '../Logs/Logs.js';
import SALES from '../Sales/Sales.js';
import Notifications from '../../containers/Notifications/Notifications';
import { ToastContainer } from 'react-toastify';

import ProductSalesRoute from '../ProductSales/ProductSales';
import CashFlowRoute from '../CashFlow/CashFlow';
import MediaRoute from '../Media/Media';
import CostumerListPageRoute from '../CostumerListPage/CostumerListPage';
import InvisibleTouchManagerPageRoute from '../InvisibleTouchManagerPage/InvisibleTouchManagerPage';
// import ProductManagerPageRoute from '../ProductManagerPage/ProductManagerPage'
import GroupManagerPageRoute from '../GroupManagerPage/GroupManagerPage';
import CampaningManagerPageRoute from '../CampaningManagerPage/CampaningManagerPage';
import PromotionManagerPageRoute from '../PromotionManagerPage/PromotionManagerPage';
import NewLogsPageRoute from '../NewLogsPage/NewLogsPage.js';
import SalesAndIncomePageRoute from '../SalesAndIncomePage/SalesAndIncomePage';
import HardwareRiportRoute from '../HardwareRiport/HardwareRiportRoute';
import PackageManagerPageRoute from '../PackageManagerPage/PackageManagerPage';

class Full extends Component {
	render() {
		return (
			<div className="app">
				<ToastContainer
					position="top-right"
					autoClose={5000}
					style={{ zindex: 1999 }}
				/>
				<Header />
				<div className="app-body">
					<Sidebar {...this.props} />
					<main className="main">
						<Container fluid>
							<Switch>
								<SafePrivateRoute
									path="/companyprofile"
									name="CompanyProfile"
									component={CompanyProfile}
								/>

								<SafePrivateRoute
									path="/logs"
									name="LogsList"
									component={LOGSLIST}
								/>

								<SafePrivateRoute
									path="/users"
									name="Users"
									component={USERS}
								/>

								<SafePrivateRoute
									path="/rootUsers"
									name="RootUsers"
									component={ROOTUSERS}
								/>

								<SafePrivateRoute
									path="/hardwareManagement"
									name="HardwareManagement"
									component={HardwareManagement}
								/>

								<SafePrivateRoute
									path="/partnerManagement"
									name="PartnerManagement"
									component={PartnerManagement}
								/>

								<SafePrivateRoute path="/vms" name="Vms" component={VMS} />

								<SafePrivateRoute
									path="/customerUsers"
									name="CustomerUsers"
									component={CUSTOMERUSERS}
								/>

								<Route path="/sales" name="Sales" component={SALES} />

								<Route
									path="/notifications"
									name="notifications"
									component={Notifications}
								/>

								<Route
									path="/productSales"
									name="ProductSales"
									component={ProductSalesRoute}
								/>
								<Route
									path="/cashFlow"
									name="CashFlow"
									component={CashFlowRoute}
								/>
								<Route path="/media" name="Media" component={MediaRoute} />
								<Route
									path="/costumer_list"
									name="CostumerList"
									component={CostumerListPageRoute}
								/>
								<Route
									path="/invisible_touch_manager"
									name="InvisibleTouchManager"
									component={InvisibleTouchManagerPageRoute}
								/>
								{/* <Route path="/product_manager" name="ProductManager" component={ProductManagerPageRoute} /> */}
								<Route
									path="/group_manager"
									name="GroupManager"
									component={GroupManagerPageRoute}
								/>
								<Route
									path="/campaning_manager"
									name="CampaningManager"
									component={CampaningManagerPageRoute}
								/>
								<Route
									path="/promotion_manager"
									name="PromotionManager"
									component={PromotionManagerPageRoute}
								/>
								<Route
									path="/newLogs"
									name="NewLogs"
									component={NewLogsPageRoute}
								/>
								<Route
									path="/sales_and_income"
									name="SalesAndIncome"
									component={SalesAndIncomePageRoute}
								/>
								<Route
									path="/hardware_report"
									name="HardwareRiport"
									component={HardwareRiportRoute}
								/>
								<Route
									path="/package_manager"
									name="PackageManager"
									component={PackageManagerPageRoute}
								/>
								<SafePrivateRoute
									path="/vmDebug"
									name="users"
									component={DebugConsol}
								/>

								<PrivateRoute path="/dashboard" component={Dashboard} />

								<Route path="/Logout" name="Logout" component={Logout} />

								<Redirect from="/" to="/dashboard" />
							</Switch>
						</Container>
					</main>
				</div>
				<Footer />
			</div>
		);
	}
}

export default Full;
