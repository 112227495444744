import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row, CardTitle } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import HelperTooltip from '../../../components/UI/HelperTooltip/HelperTooltip';
import classes from './CustomerList.module.css';
import '../InvisibleTouchManager.css';
import Spinner from '../../../components/UI/Spinner/Spinner';
import store from '../../../store/index';
import { page } from '../../../store/invisibleTouch';
import respUtil from '../../../services/util/responsibilityUtil';

class CustomerList extends Component {
	tdStyle = {
		padding: '5px',
		fontWeight: 400,
		verticalAlign: 'middle',
		outline: 'none'
	};

	thStyle = {
		backgroundColor: 'rgb(223,228,233)'
	};

	gettdStyle = (cell, row) => {
		let result = { ...this.tdStyle };
		/*if (this.isMachineDetailsEnabled(row)) {
			result.cursor = 'pointer';
		}*/
		return result;
	};

	isColumnsVisibile = columName => {
		return (
			!this.props.hiddenColumns ||
			this.props.hiddenColumns.indexOf(columName) < 0
		);
	};

	labelFormater = (cell, row) => {
		if (!row) return;
		let label = (
			<span
				style={{ cursor: 'pointer' }}
				onClick={() => this.props.onLoadPurchaser(row)}
			>
				{this.props.t('invisibleTouchManager.controlpanel.noData')}
			</span>
		);
		if (cell) {
			label = (
				<span
					style={{ cursor: 'pointer', fontWeight: 'bold' }}
					onClick={() => this.props.onLoadPurchaser(row)}
				>
					{cell}
				</span>
			);
		}
		return label;
	};

	emailFormater = (cell, row) => {
		if (!row) return;
		let label = (
			<span
				style={{ cursor: 'pointer' }}
				onClick={() => this.props.onLoadPurchaser(row)}
			>
				{this.props.t('invisibleTouchManager.purchasersTable.deleted')}
			</span>
		);

		if (cell) {
			label = (
				<span
					style={{ cursor: 'pointer', fontWeight: 'bold' }}
					onClick={() => this.props.onLoadPurchaser(row)}
				>
					{cell}
				</span>
			);
		}
		return label;
	};

	renderPaginationPanel = props => {
		return (
			<Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
				{props.components.pageList}
			</Col>
		);
	};

	renderCustomerTableTitle() {
		const { t } = this.props;

		return (
			<Row
				style={{
					paddingLeft: '15px',
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					marginBottom: '10px'
				}}
			>
				<CardTitle className="mb-0 text-muted">
					<i className={`fa fa-id-badge`} style={{ marginRight: '10px' }} />
					{t('invisibleTouchManager.purchasersTable.title')}
				</CardTitle>
				<Col>
					<HelperTooltip
						height={'90px'}
						width={'300px'}
						direction={'right'}
						id={'machineGroupmanager'}
						title={t('invisibleTouchManager.purchasersTable.tooltip.title')}
						content={t('invisibleTouchManager.purchasersTable.tooltip.content')}
					></HelperTooltip>
				</Col>
			</Row>
		);
	}

	linkButton = (param, row, classname, icon, label) => {
		return (
			<Button
				className={classname}
				onClick={() => this.props.onLoadPurchaser(row)}
				onClick={() => this.props.onLink(param, row)}
			>
				<i className={icon} />
				{label}
			</Button>
		);
	};

	renderQuickPageButtons = (cell, row) => {
		const { t } = this.props;
		if (!row) return;
		return (
			<Row style={{ justifyContent: 'center', alignItems: 'center' }}>
				{this.linkButton(
					page.purchases,
					row,
					classes.PurchasesButton,
					'fa fa-shopping-cart mr-2',
					t('invisibleTouchManager.purchasersTable.purchases')
				)}
				{this.linkButton(
					page.balances,
					row,
					classes.BalanceButton,
					'fa fa-dollar mr-2',
					t('invisibleTouchManager.purchasersTable.balance')
				)}
				{this.linkButton(
					page.invoices,
					row,
					classes.InvoliceButton,
					'fa fa-file mr-2',
					t('invisibleTouchManager.purchasersTable.invoice')
				)}
				{this.linkButton(
					page.messages,
					row,
					classes.MessageButton,
					'fa fa-envelope mr-2',
					t('invisibleTouchManager.purchasersTable.massage')
				)}
			</Row>
		);
	};

	userFormatter = (cell, row) => {
		return <i className="fa fa-id-badge" id="userIcon" />;
	};

	renderTable = () => {
		const { t } = this.props;

		//Table options

		const tableOptions = {
			sortIndicator: true,
			paginationSize: 3,
			sizePerPageList: [10],
			hidePageListOnlyOnePage: true,
			sizePerPage: this.props.recordPerPage || 10,
			clearSearch: true,
			withFirstAndLast: true,
			alwaysShowAllBtns: true,
			pageStartIndex: 1,
			clearSearch: true,
			defaultSortName: 'email',
			defaultSortOrder: 'asc',
			searchPanel: this.props.renderFilterBar,
			onSearchChange: this.props.onFilterChange
		};

		let table = <Spinner />;
		if (this.props.data) {
			table = (
				<Col>
					{!this.props.hideFilter ? this.props.renderFilterBar() : null}
					<BootstrapTable
						key="bootstrapTable"
						data={this.props.data}
						version="4"
						striped
						pagination={!this.props.hidePager}
						insertRow={false}
						options={tableOptions}
						multiColumnSearch
						condensed
						bordered={false}
						dataAlign={'center'}
						className={'react-bs-table-gray-border '}
						size="sm"
					>
						{this.isColumnsVisibile('userID') ? (
							<TableHeaderColumn
								isKey
								width="100"
								dataAlign="center"
								dataField="userID"
								dataFormat={this.userFormatter}
								thStyle={this.thStyle}
								tdStyle={(cell, row) => this.gettdStyle(cell, row)}
								hidden={respUtil.breakpoints.sm}
							/>
						) : null}
						{this.isColumnsVisibile('email') ? (
							<TableHeaderColumn
								width="200"
								dataAlign="center"
								dataField="email"
								dataFormat={this.emailFormater}
								thStyle={this.thStyle}
								tdStyle={(cell, row) => this.gettdStyle(cell, row)}
							>
								{t('invisibleTouchManager.purchasersTable.email')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('firstName') ? (
							<TableHeaderColumn
								width="150"
								dataAlign="center"
								dataField="firstName"
								dataFormat={this.labelFormater}
								thStyle={this.thStyle}
								tdStyle={(cell, row) => this.gettdStyle(cell, row)}
								hidden={respUtil.breakpoints.md}
							>
								{t('invisibleTouchManager.purchasersTable.firstName')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('lastName') ? (
							<TableHeaderColumn
								width="150"
								dataAlign="center"
								dataField="lastName"
								dataFormat={this.labelFormater}
								thStyle={this.thStyle}
								tdStyle={(cell, row) => this.gettdStyle(cell, row)}
								hidden={respUtil.breakpoints.md}
							>
								{t('invisibleTouchManager.purchasersTable.lastName')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('') ? (
							<TableHeaderColumn
								width="800"
								dataAlign="center"
								dataField=""
								dataFormat={this.renderQuickPageButtons}
								thStyle={this.thStyle}
								tdStyle={(cell, row) => this.gettdStyle(cell, row)}
								hidden={respUtil.breakpoints.md}
							>
								{t('devicelist.tableheader.quick')}
							</TableHeaderColumn>
						) : null}
					</BootstrapTable>
				</Col>
			);
		}
		return table;
	};

	render() {
		return (
			<>
				<Row>
					<Col>
						<Card>
							<CardBody>
								{this.renderCustomerTableTitle()}
								{this.renderTable()}
								{/* <RightArrowButton {...this.props} /> */}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</>
		);
	}
}

const mapStateToProps = state => ({
	usePrivilege: state.user,
	IVTState: state.invisibleTouch
});

export default connect(mapStateToProps)(
	withRouter(withTranslation()(CustomerList))
);
