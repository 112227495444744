import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
import classes from '../PromotionTypeSelector/PromotionTypeSelector.module.css';
import promotionsUtil from '../../../../services/util/promotionsUtil';

const SelectedUsers = props => {
	const { t } = props;
	const selectedUsers = promotionsUtil.getParamValueByName(
		props.promotion,
		promotionsUtil.promotionParameters.PROMOTION_SELECTED_USERS
	);

	const handleOnChange = e => {
		const promotion = { ...props.promotion };
		promotionsUtil.setParamValue(
			promotion,
			promotionsUtil.promotionParameters.PROMOTION_SELECTED_USERS,
			e.target.value
		);
		props.onChange(promotion);
	};

	return (
		<Form.Group controlId="activeDays">
			<Col>
				<Row style={{ alignItems: 'center' }}>
					<Form.Control
						as="textarea"
						value={selectedUsers}
						onChange={e => handleOnChange(e)}
						style={{ width: '80%' }}
					></Form.Control>
					<Button
						variant="success"
						className="DefaultButton"
						style={{ marginLeft: '10px' }}
						onClick={() => props.onShowUserSelector()}
					>
						<i className="fa fa-plus" style={{ marginRight: '10px' }} />
						{t(
							'promotionManager.addPromotion.promotionUsers.wizard.buttonCaption'
						)}
					</Button>
				</Row>
			</Col>
		</Form.Group>
	);
};

export default withTranslation()(SelectedUsers);
