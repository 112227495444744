import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import walletApi from '../../../services/api/wallet';
import TransactionTable from './TransactionTable';
import HelperTooltip from '../../../components/UI/HelperTooltip/HelperTooltip';
import Spinner from '../../../components/UI/Spinner/Spinner';
import machineAPI from '../../../services/api/machines';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Toggle from 'react-bootstrap-toggle';
import Button from 'react-bootstrap/Button';
import classes from './TransactionList.module.css';

const filterParams = {
	DEVICE: 1,
	EMAIL: 2,
	PRODUCT: 3,
	TRANSACTION: 4,
	STATE: 5
};

class TransactionList extends Component {
	state = {
		data: [],
		elementVisibilities: [],
		filteredData: []
	};

	componentDidMount() {
		this.loadMachines();
	}

	loadMachines = async () => {
		let vmId = [];
		let machines = [];
		if (!this.props.vmId) {
			await machineAPI.getVisibleVendingMachines().then(res => {
				if (
					res &&
					res.data &&
					res.data.response &&
					res.data.response.length > 0
				) {
					machines = res.data.response;
					vmId = res.data.response.map(e => {
						return e.vmID;
					});
				}
			});
		}
		let startDate = this.getToday(-1);
		let endDate = this.getToday(+1);
		this.setState(
			{ startDate: startDate, endDate: endDate, vmId: vmId, machines },
			() => this.loadData()
		);
	};

	loadData = () => {
		if (
			this.props.partnerWalet &&
			this.props.partnerWalet.length > 0 &&
			this.props.partnerWalet[0].id
		) {
			let param = {
				walletID: this.props.partnerWalet[0].id,
				fromDate: this.state.startDate,
				toDate: this.state.endDate
			};

			this.setState({ loading: true }, () => {
				walletApi.getTransactionsOfPartnerWallet(param).then(res => {
					try {
						if (res && res.data && res.data.transactions)
							this.setState({ data: res.data.transactions }, () => {
								this.provideFiltredDataset();
								this.setState({ loading: false });
							});
					} catch (error) {
						this.setState({ loading: false });
					}
				});
			});
		}
	};

	getToday = day => {
		let today = moment();
		let todayAddDays = today.add(day, 'days').format('YYYY.MM.DD');
		return todayAddDays;
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};
	renderLoadButton = () => {
		return (
			<Button
				className={classes.DefaultFilterButton}
				onClick={() => this.loadData()}
			>
				<i className="fa fa-check mr-2"></i>&nbsp;
				{this.props.t('device.details.sales.timeRange')}
			</Button>
		);
	};

	// ------------ FilterDialog------------

	onShowDialog(value1) {
		this.setState(value1);
	}

	onHideDialog(value2) {
		this.setState(value2);
	}
	stateTypeFormater = cell => {
		const { t } = this.props;
		let state = t('invisibleTouchManager.controlpanel.noData');
		if (cell === 0)
			state = t('invisibleTouchManager.purchasesPanel.massage.PCEntry');
		switch (cell) {
			case 1:
				state = t('invisibleTouchManager.purchasesPanel.barionStart');
				break;
			case 2:
				state = t('invisibleTouchManager.purchasesPanel.barionStarted');
				break;
			case 3:
				state = t('invisibleTouchManager.purchasesPanel.barionStartFailed');
				break;
			case 4:
				state = t(
					'invisibleTouchManager.purchasesPanel.barionTransactionFailed'
				);
				break;
			case 5:
				state = t('invisibleTouchManager.purchasesPanel.barionCreditEntry');
				break;
			case 6:
				state = t('invisibleTouchManager.purchasesPanel.massage.VMCEntry');
				break;
			case 7:
				state = t('invisibleTouchManager.purchasesPanel.success');
				break;
			case 9:
				state = t('invisibleTouchManager.purchasesPanel.withdrawn');
				break;
			case 8:
				state = t('invisibleTouchManager.purchasesPanel.massage.promApplyed');
				break;
			case 9:
				state = t('invisibleTouchManager.purchasesPanel.withdrawn');
				break;
			case 10:
				state = t('invisibleTouchManager.purchasesPanel.massage.promApplyed');
				break;
		}
		return state;
	};

	transactionTypeFormater = cell => {
		const { t } = this.props;
		let state = t('invisibleTouchManager.controlpanel.noData');
		if (cell === 0) state = t('invisibleTouchManager.transactionTable.balance');
		if (cell) {
			state = t('invisibleTouchManager.transactionTable.balance');
			switch (cell) {
				case 7:
					state = t('invisibleTouchManager.transactionTable.purchase');

				case 9:
					state = t('invisibleTouchManager.transactionTable.purchase');
			}
		}
		return state;
	};

	getDeviceName = element => {
		let device = null;
		let name = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (element)
			device = this.state.machines.find(el => element.vmID === el.vmID);

		if (device && device.customName) name = device.customName;
		return name;
	};

	getEmail = element => {
		let name = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (element && element.user && element.user.email)
			name = element.user.email;
		return name;
	};

	getproductID = value => {
		let productID = null;
		if (value && value.productID) productID = value.productID;
		return productID;
	};

	getproductName = value => {
		let productName = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (value && value.details && value.details.productName)
			productName = value.details.productName;
		return productName;
	};

	getSetOptions = param => {
		const distinct = array => {
			return [...new Map(array.map(item => [item['label'], item])).values()];
		};

		let product = [];
		switch (param) {
			case filterParams.DEVICE:
				let deviceSet = this.state.data
					.filter(e => e.billingStateID === null)
					.map(e => {
						let label = this.getDeviceName(e);

						return { value: e.vmID, label: label };
					});
				product = distinct(deviceSet);

				return [product, param];
			case filterParams.EMAIL:
				let emailSet = this.state.data
					.filter(e => e.billingStateID === null)
					.filter(e => e.userWalletID !== null)
					.map(e => {
						let label = this.getEmail(e);
						return { value: e.userWalletID, label: label };
					});
				product = distinct(emailSet);
				return [product, param];
			case filterParams.PRODUCT:
				let productSet = this.state.data
					.filter(e => e.billingStateID === null)
					.filter(e => e.details !== null)
					.map(e => {
						let value = this.getproductID(e);
						let label = this.getproductName(e);
						return { value: value, label: label };
					});
				product = distinct(productSet);

				return [product, param];
			case filterParams.TRANSACTION:
				let transactionSet = this.state.data
					.filter(e => e.billingStateID === null)
					.map(e => {
						let name = this.props.t(
							'invisibleTouchManager.controlpanel.noData'
						);

						if (e) name = this.transactionTypeFormater(e.transactionTypeID);
						return { value: e.userWalletID, label: name };
					});

				product = distinct(transactionSet);
				return [product, param];
			case filterParams.STATE:
				let stateSet = this.state.data
					.filter(e => e.billingStateID === null)
					.map(e => {
						let name = this.props.t(
							'invisibleTouchManager.controlpanel.noData'
						);
						if (e) name = this.stateTypeFormater(e.transactionTypeID);
						return { value: e.userWalletID, label: name };
					});
				product = distinct(stateSet);
				return [product, param];
		}
	};

	onToggleElementVisibility(element, param) {
		let elementVisibilities = [...this.state.elementVisibilities];
		let item = elementVisibilities.find(e => e.element === element);
		let set = elementVisibilities.filter(e => e.param === param);
		if (!item) {
			item = { element, visible: false, param };
			elementVisibilities.push(item);
		} else if (set && set.length === 1 && !set[0].visible) {
			elementVisibilities = elementVisibilities.filter(e => e.param !== param);
		} else {
			item.visible = !item.visible;
		}
		this.setState({ elementVisibilities: elementVisibilities }, () => {
			this.provideFiltredDataset();
		});
	}

	isElementTypeVisible(element) {
		let result = true;
		let item = this.state.elementVisibilities.find(e => e.element === element);
		if (item) {
			result = item.visible;
		}
		return result;
	}

	onToggleAllVisibilities(set, param) {
		let newVisibilitySettings = [];

		let targetVisibility = false;
		const paramSet = this.state.elementVisibilities
			.filter(e => e.param == param)
			.map(e => e.visible);
		if (paramSet && paramSet.length > 0) {
			targetVisibility = !paramSet[0].visible;
		}

		if (targetVisibility) {
			newVisibilitySettings = this.state.elementVisibilities.filter(
				e => e.param != param
			);
		} else {
			newVisibilitySettings = this.state.elementVisibilities.filter(
				e => e.param != param
			);
			newVisibilitySettings.push(
				...set[0].map(e => {
					return { element: e.label, visible: false, param };
				})
			);
		}
		this.setState({ elementVisibilities: newVisibilitySettings }, () => {
			this.provideFiltredDataset();
		});
	}

	provideFiltredDataset() {
		let filteredData = this.state.data
			.filter(e => this.isElementTypeVisible(this.getDeviceName(e)))
			.filter(e => this.isElementTypeVisible(this.getproductName(e)))
			.filter(e => this.isElementTypeVisible(this.getEmail(e)))
			.filter(e =>
				this.isElementTypeVisible(
					this.transactionTypeFormater(e.transactionTypeID)
				)
			)
			.filter(e =>
				this.isElementTypeVisible(this.stateTypeFormater(e.transactionTypeID))
			)
			.map((e, index) => {
				return { ...e, index };
			});
		if (this.props.vmId) {
			filteredData = filteredData.filter(e => e.vmID != null);
		}
		this.setState({ filteredData: filteredData });
	}

	renderDialog(set, value2, stateValue) {
		const param = set[1];

		const elementList = set[0]
			.sort((a, b) => a.label.localeCompare(b.label))
			.map((element, index) => {
				return (
					<div key={index}>
						<div>
							<strong style={{ marginLeft: '30px' }}>{element.label}</strong>
							<Toggle
								onClick={() =>
									this.onToggleElementVisibility(element.label, param)
								}
								on={this.props.t('eventList.show')}
								off={this.props.t('eventList.hide')}
								active={this.isElementTypeVisible(element.label)}
								onstyle="info"
								offstyle="warning"
								size="sm"
								style={{
									width: '75px',
									height: '30px',
									borderRadius: '25px',
									float: 'right'
								}}
							/>
						</div>
						<hr />
					</div>
				);
			});

		return (
			<Modal
				show={stateValue}
				onHide={() => this.onHideDialog(value2)}
				scrollable="true"
			>
				<Modal.Header>
					<Modal.Title>
						{this.props.t('salesSnadIncome.table.visible')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body max-height="260px">{elementList}</Modal.Body>
				<Modal.Footer>
					<Button
						variant={
							this.state.elementVisibilities.filter(item => !item.visible)
								.length === 0
								? 'warning'
								: 'info'
						}
						className="DefaultButtonMd"
						onClick={() => this.onToggleAllVisibilities(set, param)}
					>
						<i className="fa fa-eye mr-2"></i>&nbsp;
						{this.state.elementVisibilities.find(
							item => item.param == param && !item.visible
						)
							? this.props.t('eventList.showAll')
							: this.props.t('eventList.hideAll')}
					</Button>

					<Button
						variant="success"
						className="DefaultButton"
						onClick={() => this.onHideDialog(value2)}
					>
						<i className="fa fa-times-circle mr-2"></i>&nbsp;
						{this.props.t('eventList.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	resetFilter = () => {
		let newVisibilitySettings = [];

		this.setState({ elementVisibilities: newVisibilitySettings }, () => {
			this.provideFiltredDataset();
		});
	};

	// ------------ FilterDialog------------

	renderTable = () => {
		return !this.state.filteredData || this.state.loading ? (
			<Spinner />
		) : (
			<TransactionTable
				data={this.state.filteredData}
				currency={this.props.currency}
				customers={this.props.data}
				priceSum={this.state.priceSum}
				incomeSum={this.state.incomeSum}
				transactionSum={this.state.transactionSum}
				startDate={this.state.startDate}
				endDate={this.state.endDate}
				onFilterChange={this.afterColumnFilter}
				elementVisibilities={this.state.elementVisibilities}
				machines={this.state.machines}
				onDateChange={this.inputChangeHandler}
				onResetFilter={this.resetFilter}
				showDeviceFilterDialog={() => this.onShowDialog({ deviceDialog: true })}
				showEmailFilterDialog={() => this.onShowDialog({ emailDialog: true })}
				showProductFilterDialog={() =>
					this.onShowDialog({ productDialog: true })
				}
				showTransactionTypeFilterDialog={() =>
					this.onShowDialog({ transactionTypeDialog: true })
				}
				showStateFilterDialog={() => this.onShowDialog({ stateDialog: true })}
				loadButton={this.renderLoadButton()}
			/>
		);
	};

	renderTitle() {
		return (
			<Row style={{ alignItems: 'flex-start' }}>
				<CardTitle
					className="text-muted"
					style={{ cursor: 'pointer', marginLeft: '20px' }}
				>
					<i
						className={'fa fa-shopping-cart'}
						style={{ marginRight: '10px' }}
					/>
					{this.props.t('invisibleTouchManager.transactionTable.title')}
				</CardTitle>
				<HelperTooltip
					height={'100px'}
					width={'600px'}
					direction={'right'}
					id={'SalesAndIncomeHelperTooltip'}
					title={this.props.t('salesSnadIncome.tooltip.title')}
					content={this.props.t('salesSnadIncome.tooltip.content')}
				></HelperTooltip>
			</Row>
		);
	}

	render() {
		const { t } = this.props;
		return (
			<Card>
				{this.renderDialog(
					this.getSetOptions(filterParams.DEVICE),
					{ deviceDialog: false },
					this.state.deviceDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.EMAIL),
					{ emailDialog: false },
					this.state.emailDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.PRODUCT),
					{ productDialog: false },
					this.state.productDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.TRANSACTION),
					{ transactionTypeDialog: false },
					this.state.transactionTypeDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.STATE),
					{ stateDialog: false },
					this.state.stateDialog
				)}
				<CardBody>
					{this.renderTitle()}
					{this.renderTable()}
				</CardBody>
			</Card>
		);
	}
}

export default withTranslation()(TransactionList);
