import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { deleteUser } from '../../store/user'

const mapDispatchToProps = dispatch => {
  return {
    deleteUser: () => {
      dispatch(deleteUser())
    },
  }
}

class Logout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showComponent: false,
    }
  }

  render() {
    const { from } = this.props.location.state || {
      from: { pathname: '/login' },
    }

    const { deleteUser } = this.props

    deleteUser()

    return <Redirect to={from} />
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Logout)
