//@flow
import React from 'react';
import {
	Button,
	Card,
	CardBody,
	CardGroup,
	CardImg,
	Col,
	Container,
	Input,
	InputGroup,
	Row
} from 'reactstrap';
import { Redirect, withRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Logo from '../../assets/images/logo.png';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import classes from './Login.module.css';

import Auth from '../../services/api/auth';
//URL paraméter elkapáshoz
import queryString from 'query-string';
//Base64
import base64url from 'base64url';
//Pörgős gomb
import 'ladda/dist/ladda-themeless.min.css';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { TFunction } from 'i18next';
import { getLanguage, setLanguage } from '../../localization';
import { setLanguage as setLanguageInStore } from '../../store/langauge';
import { createUser } from '../../store/user';
import { Record } from 'immutable';

type Props = {
	location: {
		search?: string,
		state?: any
	},
	history: Object,
	t: TFunction,
	createUser: createUser,
	user: Record | null
};
type States = {
	email: string,
	password: string,
	language: string,
	expLeft: boolean,
	redirect: string,
	progress?: number
};

class Login extends React.Component<Props, States> {
	constructor(props: Props) {
		super(props);

		const language = getLanguage();
		const { redirect } = queryString.parse(this.props.location.search);
		this.state = {
			email: '',
			password: '',
			language,
			redirect,
			expLeft: false //a loding button-nak kell az állapot felméréséhez, hogy pörögjön e
		};
	}

	//Szökséges KEY error
	needKeyError = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('error.missingFields'));
	};

	//Szökséges KEY error
	networkError = () => {
		// add type: 'error' to options
		return toast.warn('Hiba a lekérés során');
	};

	//Szökséges KEY error
	wrongKeyError = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('login.wrongLogin'));
	};

	//gomb event-je
	handleClick = async () => {
		const { email, password } = this.state;
		const { createUser } = this.props;
		if (email !== '') {
			//Loginbutton töltés állapot beállítása
			this.setState({
				expLeft: true,
				progress: 0.5
			});
			try {
				const {
					token,
					menu,
					cmID,
					permission,
					privilege,
					languageCode
				} = await Auth.login(email, password);

				createUser(
					token,
					JSON.stringify(menu),
					cmID,
					JSON.stringify(permission),
					privilege
				);

				setLanguage(!!languageCode ? languageCode.toLowerCase() : 'en');
				this.setState({
					expLeft: false,
					progress: 1.0
				});
			} catch (e) {
				this.setState({
					expLeft: false,
					progress: 1.0
				});
				if (e.request.status === 500) {
					//Rossz kulcsot adtunk meg , alert feldobása
					this.wrongKeyError();
				} else {
					//Hiba a lekérés során , alert feldobása
					this.networkError();
				}
			}
		} else {
			this.setState({
				expLeft: false,
				progress: 1.0
			});

			this.needKeyError();
		}
	};

	redirectToRegistration = () => {
		this.props.history.push('registration/1');
	};

	handleInputOnChange = (key: string) => (event: SyntheticInputEvent<any>) =>
		this.setState({ [key]: event.target.value });

	//Nyelv beállítása
	handleLanguageSelect = async (event: SyntheticInputEvent<any>) => {
		const language = event.target.value;

		await setLanguage(language);

		this.setState({ language });
	};

	handleRedirectToForgetPassword = () => {
		this.props.history.push('forgotPassw');
	};

	//Enter gomb akciója
	handleKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			this.handleClick();
		}
	};

	render = () => {
		const { email = '', password = '', language = 'hu', redirect } = this.state;
		const { user } = this.props;

		//Ha "null", tehát nincs user mentve akkor nem vagyunk bejelentkezve
		const logged = !!user;

		const { from } = this.props.location.state || {
			from: { pathname: '/app' }
		};

		//Vizsgálat hogy mehetünk e a dashboardra vagy renderelni kell a nézetet tovább
		if (logged) {
			//Ha van redirect paraméter
			if (redirect) {
				//Redirect paraméter base64 visszafejtése
				const redirectBase64 = base64url.decode(redirect);

				//Ha a redirect link a logs
				if (redirectBase64 === 'http://manager.hvend.hu/WEBAPP/#/logs') {
					//Átirányítás
					this.props.history.push('logs');
				}
			} else {
				return <Redirect to={from} />;
			}
		}

		const { t } = this.props;

		//Ez az alerteknek kell
		const containerStyle = {
			zIndex: 1999
		};

		return (
			<div className="app flex-row align-items-center">
				<ToastContainer
					position="top-right"
					autoClose={5000}
					style={containerStyle}
				/>
				<Container>
					<Row className="justify-content-center">
						<Col md="10">
							<CardGroup className="mb-4">
								<Card className={'p-2 ' + classes.PanelLeft}>
									<CardBody>
										<div className="text-left">
											<CardImg
												className="card-img-top"
												id="mainLogo"
												src={Logo}
												alt="Card image cap"
											/>
										</div>
										<h1>{t('login.label')}</h1>
										<p className="text-left">{t('login.info')}</p>
										<InputGroup className="mb-3">
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i
														style={{ minWidth: '14px' }}
														className="fa fa-at"
													/>
												</span>
											</div>
											<Input
												type="text"
												placeholder={t('login.email')}
												value={email}
												onChange={this.handleInputOnChange('email')}
												onKeyPress={this.handleKeyPress}
											/>
										</InputGroup>
										<InputGroup className="mb-4">
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="icon-lock" />
												</span>
											</div>
											<Input
												type="password"
												placeholder={t('login.password')}
												value={password}
												onChange={this.handleInputOnChange('password')}
												onKeyPress={this.handleKeyPress}
											/>
										</InputGroup>
										<Row>
											<Col xs="6">
												<LaddaButton
													id="redButton"
													className="btn btn-success btn-ladda"
													loading={this.state.expLeft}
													data-style={EXPAND_LEFT}
													onClick={this.handleClick}
												>
													{t('login.loginButton')}
												</LaddaButton>
											</Col>
											<Col xs="6">
												<div className={'input-group ' + classes.LanguageBlock}>
													<span
														className={
															'input-group-prepend ' + classes.LanguageIcon
														}
													>
														<span className="input-group-text">
															<i className="fa fa-globe" />
														</span>
													</span>
													<form className={classes.LanguageCombo}>
														<select
															id="select2-1"
															className="form-control select2-single"
															onChange={this.handleLanguageSelect}
															value={language}
														>
															<option value="en">EN</option>
															<option value="hu">HU</option>
														</select>
													</form>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								<Card
									className={'text-white bg-primary p-2 ' + classes.PanelRight}
								>
									<CardBody className="text-center">
										<div>
											<h2>{t('login.registration.label')}</h2>
											<p>{t('login.registration.notRegistered')}</p>
											<p>{t('login.registration.info1')}</p>
											<p>{t('login.registration.info2')}</p>

											<div id="forgetPass">
												<Button
													color="link"
													id="forgetPass"
													className="px-0"
													onClick={this.handleRedirectToForgetPassword}
												>
													{t('login.forgetPassword')}
												</Button>
											</div>
										</div>
									</CardBody>
								</Card>
							</CardGroup>
						</Col>
					</Row>
				</Container>
			</div>
		);
	};
}

const mapStateToProps = state => ({
	user: state.user
});

const mapDispatchToProps = dispatch => {
	return {
		createUser: (privatekey, menu, cmID, permission, privilege) => {
			dispatch(createUser(privatekey, menu, cmID, permission, privilege));
		},
		setLanguage: language => dispatch(setLanguageInStore(language))
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withTranslation()(Login)));
