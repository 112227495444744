import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './InviteUserDialog.module.css';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import UsersApi from '../../../services/api/users';
import getRoleTextId from '../../../i18n/userRole';
import languageList from '../../../i18n/languageList';

class InviteUserDialog extends Component {
	state = {
		personToInvite: {
			cmID: '',
			email: '',
			userClass: '',
			currentEmailIsCorrect: false,
			sendInvitation: true
		}
	};

	componentDidMount() {
		const userClass = this.props.userClass
			? this.props.userClass
			: this.props.possibleRoles[0];
		this.setState({
			personToInvite: {
				cmID: this.props.cmID,
				email: this.props.email,
				userClass: userClass,
				languageCode: languageList[0]
			}
		});
	}

	inputUpdate(e) {
		let personToInvite = { ...this.state.personToInvite };
		personToInvite[e.target.id] = e.target.value;
		this.setState({ personToInvite }, () => this.isEmailCorrect());
	}

	async isEmailCorrect() {
		// We should clear the time out every time we type
		if (this.emailCheckTimeout) {
			clearTimeout(this.emailCheckTimeout);
		}

		this.setState(
			{
				currentEmailIsCorrect: false
			},
			() => {
				//eslint-disable-next-line
				const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (re.test(String(this.state.personToInvite.email).toLowerCase())) {
					// If the format is correct then try to check the email though the backend.
					// But of course we do not want to ask the server after every keypress so we do it in a timeout.
					this.emailCheckTimeout = setTimeout(async () => {
						const response = await UsersApi.checkUserByEmail(
							this.state.personToInvite.email
						);
						const emailAlreadyExists = response && response.data.result == 'ok';

						this.setState({
							currentEmailIsCorrect: !emailAlreadyExists
						});
					}, 300);
				}
			}
		);
	}

	selectUpdate(e) {
		let personToInvite = { ...this.state.personToInvite };
		personToInvite.userClass = this.props.possibleRoles[e.target.selectedIndex];
		this.setState({ personToInvite });
	}

	renderRoleCombobox() {
		if (this.props.possibleRoles) {
			const roleOtions = this.props.possibleRoles.map(roleId => (
				<option key={roleId}>{this.props.t(getRoleTextId(roleId))}</option>
			));
			return (
				<>
					<Form.Label>
						{this.props.t('userManagement.inviteDialog.userRole')}
					</Form.Label>
					<Form.Control as="select" onChange={e => this.selectUpdate(e)}>
						{roleOtions}
					</Form.Control>
				</>
			);
		} else {
			return null;
		}
	}

	languageSelectUpdate(e) {
		let personToInvite = { ...this.state.personToInvite };
		personToInvite.languageCode = languageList[e.target.selectedIndex];
		this.setState({ personToInvite });
	}

	renderLanguageCombobox() {
		if (languageList) {
			const languageOptions = languageList.map(language => (
				<option key={language.code}>{language.name}</option>
			));
			return (
				<>
					<Form.Label>
						{this.props.t('userManagement.inviteDialog.language')}
					</Form.Label>
					<Form.Control
						as="select"
						onChange={e => this.languageSelectUpdate(e)}
					>
						{languageOptions}
					</Form.Control>
				</>
			);
		} else {
			return null;
		}
	}

	handleFormSubmit(e) {
		e.preventDefault();
		this.props.onSave(this.state.personToInvite);
	}

	render() {
		return (
			<Form onSubmit={e => this.handleFormSubmit(e)}>
				{this.renderRoleCombobox()}
				{this.renderLanguageCombobox()}
				<Form.Label>
					{this.props.t('userManagement.inviteDialog.emailAddress')}
				</Form.Label>
				<Form.Control
					id="email"
					type="email"
					placeholder={this.props.t(
						'userManagement.inviteDialog.emailAddressPlaceholder'
					)}
					value={this.state.personToInvite.email}
					onChange={e => this.inputUpdate(e)}
				/>
				{this.props.showInviteEmailSettings ? (
					<>
						<Form.Label>
							{this.props.t('userManagement.inviteDialog.sendInvitation')}
						</Form.Label>
						<Form.Check
							id="sendInvitation"
							checked={this.state.personToInvite.sendInvitation}
							onChange={e => {
								let personToInvite = { ...this.state.personToInvite };
								personToInvite.sendInvitation = e.target.checked;
								this.setState({ personToInvite });
							}}
						/>
					</>
				) : null}
				<div className={classes.ToolbarDiv}>
					<Button
						variant="success"
						className="DefaultButton"
						type="submit"
						disabled={!this.state.currentEmailIsCorrect}
					>
						<i className="fa fa-floppy-o mr-2" />
						{this.props.t('userManagement.inviteDialog.save')}
					</Button>
				</div>
			</Form>
		);
	}
}

export default withTranslation()(InviteUserDialog);
