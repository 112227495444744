import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import GroupManager from '../../views/GroupManager/GroupManager';

class GroupManagerPageRoute extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/group_manager" name="GroupManager" component={GroupManager} />
                    <Redirect from="/" to="/group_manager" />
                </Switch>
            </div>
        );
    }
}

export default GroupManagerPageRoute;