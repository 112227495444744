import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
	user: state.user
});

const SafePrivateRoute = ({ component: Component, user, path, ...rest }) => {
	var value = user !== null;

	if (value === true) {
		//Convert to string
		const permission = JSON.stringify(user.permission);

		//Vizsgálat hogy a menu tartalmazza e a path -et
		if (!permission.includes(path)) {
			//Ha nem akkor vissza állítjuk a value-t false ra , ez majd vissza dob a loginra, de mivel a login meg látja hogy már be vagyok jelentezve ezért átdob a dasboardra. Tehát a login, reg kivételével mindenüt át kell írni ilyenre a privátrout-ot
			value = false;
		}

		//Szerintem csinálhatunk 2 külön js file-t az agyik az a "PrivateRoute.js" a másik meg a "SafePrivateRoute.js" . Az elsőbe a mostani privát route van a másikban bedig ami itt van , csak át kell nevezni. Utána elvileg csak be kell importálni azokba a fájlokba amelyikekbe használjuk és tudjuk úgy használni nem kell majd az összes fájba bele írni külön külön a privátroute constanst
	}

	return (
		<Route
			{...rest}
			component={props =>
				value === true ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: '/dashboard', state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

export default connect(mapStateToProps)(SafePrivateRoute);
