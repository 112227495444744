//@flow
import React from 'react'

import { withRouter } from 'react-router-dom'

import AzkoyenKeyboardHeader from './AzkoyenKeyboardHeader';
import AzkoyenKeyboard from './AzkoyenKeyboard';
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent';

type Props = {
  products?: Array<Object>,
}

class Azkoyen extends React.Component<Props> {
  keyboardConfig = [
    [
      {
        buttonContent: <>1</>,
        buttonID: '1',
      },
      {
        buttonContent: <>2</>,
        buttonID: '2',
      },
      {
        buttonContent: <>3</>,
        buttonID: '3',
      }
    ],
    [
      {
        buttonContent: <>4</>,
        buttonID: '4',
      },
      {
        buttonContent: <>5</>,
        buttonID: '5',
      },
      {
        buttonContent: <>6</>,
        buttonID: '6',
      }
    ],
    [
      {
        buttonContent: <>7</>,
        buttonID: '7',
      },
      {
        buttonContent: <>8</>,
        buttonID: '8',
      },
      {
        buttonContent: <>9</>,
        buttonID: '9',
      }
    ],
    [
      {
        buttonContent: <>C</>,
        buttonID: '12',
      },
      {
        buttonContent: <>0</>,
        buttonID: '10',
      }, {}
    ],
  ]

  render() {
    const { products = [] } = this.props

    return (
      <DirectChooseMachineBaseComponent
        machineTitle="Azkoyen"
        lineNumber={2}
        lineLength={16}
        ledNumbers={0}
        keyboard={
          <AzkoyenKeyboard
            keyboardConfig={this.keyboardConfig}
            keyboardHeaderLine={<AzkoyenKeyboardHeader />}
            programmingButtonVisible={true}
            {...this.props}
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(Azkoyen)
