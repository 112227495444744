// @flow
import i18n from './i18n';
import * as moment from 'moment';
import 'moment/locale/hu';

export function getLanguage() {
	return i18n.language.substr(0, 2);
}

export async function setLanguage(languageCode: string) {
	moment.locale(languageCode);
	await i18n.changeLanguage(languageCode);
}
