import React, { Component } from 'react';
import { Card, CardTitle, Button, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import classes from './PurchasePanel.module.css';
import { Badge } from 'react-bootstrap';
import moment from 'moment';

class PurchasesPanel extends Component {
	isColumnsVisibile = columName => {
		return (
			!this.props.hiddenColumns ||
			this.props.hiddenColumns.indexOf(columName) < 0
		);
	};

	stateFormater = (cell, row) => {
		if (!cell) return;
		const { t } = this.props;
		let state = t('invisibleTouchManager.controlpanel.noData');
		let style = { color: '#b27300', backgroundColor: '#fff6e6' };
		let i = 'fa fa-exclamation';
		let massage = '';

		switch (cell) {
			case 7:
				state = t('invisibleTouchManager.purchasesPanel.success');
				style = { color: 'var(--balance)' };
				i = 'fa fa-check-circle';
				massage = t('invisibleTouchManager.purchasesPanel.massage.purchase');
				break;
			case 9:
				state = t('invisibleTouchManager.purchasesPanel.withdrawn');
				style = { color: 'var(--purchases-dark)' };
				i = 'fa fa-exclamation-triangle';
				break;
		}
		return (
			<Col>
				<Col>
					<Row style={{ justifyContent: 'center' }}>
						<i className={i} style={style} />

						<Badge style={style}>{state}</Badge>
					</Row>
				</Col>
				<Col style={{ fontSize: 'smaller' }}>
					<Badge style={style}>{massage}</Badge>
				</Col>
			</Col>
		);
	};

	refundButtonFormater = (cell, row) => {
		if (!row || !row.amount || row.refunded == 'Y' || !row.index) return;

		let remark = 'Refund! Transaction account: ' + row.index;

		return (
			<Button
				onClick={e =>
					this.props.onRefund(Math.abs(row.amount), remark, row.index)
				}
				className={classes.RefundButton}
				disabled={row.amount >= 0}
			>
				<i className="fa fa-exclamation" />
			</Button>
		);
	};

	dateFormater = cell => {
		if (!cell) return;
		return (
			<p className={classes.Date}>
				{moment(cell).format('YYYY-MM-DD HH:mm:ss')}
			</p>
		);
	};
	amountFormater = cell => {
		let data = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (cell || cell === 0) {
			data = <p>{cell.toFixed(2)}</p>;
		}
		return data;
	};

	productFormater = (cell, row) => {
		if (!row) return;
		return row.details && row.details.productName
			? row.details.productName
			: this.props.t('invisibleTouchManager.controlpanel.noData');
	};

	deviceFormater = (cell, row) => {
		let data = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (cell) {
			let ms = this.props.machines.find(ms => ms.vmID === cell);
			if (ms) {
				data = <p className={classes.Date}>{ms.customName}</p>;
			}
		}
		return data;
	};

	userFormatter = (cell, row) => {
		return <i className="fa fa-shopping-cart" id="userIcon" />;
	};

	renderTable = () => {
		const { t } = this.props;
		const tableOptions = {
			sortIndicator: false,
			hideSizePerPage: false,
			paginationSize: 5,
			sizePerPageList: [8],
			sizePerPage: this.props.recordPerPage || 10,
			withFirstAndLast: true,
			alwaysShowAllBtns: true,
			pageStartIndex: 1,
			paginationPosition: 'bottom',
			clearSearch: true,
			hidePageListOnlyOnePage: true,
			defaultSortName: 'insertedAt',
			defaultSortOrder: 'desc',
			searchPanel: this.props.renderFilterBar,
			onSearchChange: this.props.onFilterChange
		};

		let table = <Spinner />;
		if (this.props.data) {
			table = (
				<>
					{!this.props.hideToolbar ? this.props.onRenderFilterBar() : null}
					<BootstrapTable
						key="bootstrapTable"
						data={this.props.transactions.filter(
							e => e.transactionTypeID === 7 || e.transactionTypeID === 9
						)}
						version="4"
						pagination={!this.props.hidePager}
						insertRow={false}
						options={tableOptions}
						multiColumnSearch
						condensed
						bordered={false}
						dataAlign={'center'}
						size="sm"
						trClassName={classes.TableBody}
					>
						{this.isColumnsVisibile('id') ? (
							<TableHeaderColumn
								isKey
								width="100"
								dataAlign="center"
								dataField="id"
								dataFormat={this.userFormatter}
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
							/>
						) : null}
						{this.isColumnsVisibile('index') ? (
							<TableHeaderColumn
								dataField="index"
								dataAlign="center"
								width="100"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
							>
								{t('invisibleTouchManager.purchasesPanel.account')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('insertedAt') ? (
							<TableHeaderColumn
								dataField="insertedAt"
								width="200"
								dataAlign="center"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
								dataFormat={this.dateFormater}
							>
								{t('invisibleTouchManager.purchasesPanel.date')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('vmID') ? (
							<TableHeaderColumn
								dataField="vmID"
								width="300"
								dataAlign="center"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
								dataFormat={this.deviceFormater}
							>
								{t('invisibleTouchManager.purchasesPanel.device')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('product') ? (
							<TableHeaderColumn
								dataField="product"
								width="200"
								dataAlign="center"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
								dataFormat={this.productFormater}
							>
								{t('invisibleTouchManager.purchasesPanel.product')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('amount') ? (
							<TableHeaderColumn
								dataField="amount"
								width="100"
								dataAlign="center"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
								dataFormat={this.amountFormater}
							>
								{t('invisibleTouchManager.purchasesPanel.amount')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('transactionTypeID') ? (
							<TableHeaderColumn
								dataField="transactionTypeID"
								width="300"
								dataAlign="center"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
								dataFormat={this.stateFormater}
							>
								{t('invisibleTouchManager.purchasesPanel.state')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('refund') ? (
							<TableHeaderColumn
								width="150"
								dataAlign="center"
								dataField="refund"
								dataFormat={this.refundButtonFormater}
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
							>
								{t('invisibleTouchManager.purchasesPanel.refund')}
							</TableHeaderColumn>
						) : null}
					</BootstrapTable>
				</>
			);
		}
		return table;
	};

	render() {
		const { t } = this.props;
		return (
			<Card body className={this.props.panel}>
				<CardTitle className="mb-0 text-muted">
					<i className="fa fa-shopping-cart" />
					&nbsp;
					{t('invisibleTouchManager.purchasesPanel.title')}
				</CardTitle>
				<Row
					style={{
						marginTop: '2rem'
					}}
				>
					<Col>{this.renderTable()}</Col>
				</Row>
			</Card>
		);
	}
}

export default withTranslation()(PurchasesPanel);
