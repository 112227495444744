//@flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Col, Row, Card, CardBody, CardTitle } from 'reactstrap';
import moment from 'moment';
import DeviceList from './DeviceList';
import DashboardCardContainerWithFlow from '../../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import PageTitleCard from '../../Dashboard/CardHandling/PageTitleCard/PageTitleCard';

// import '../../DeviceGroups/AddMachineGroupForm/node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import VMApi from '../../../services/api/machines';
import logger from '../../../services/logger';
import UserUtil from '../../../services/util/userUtil';
import realtime from '../../../services/api/realtime';
import type { Subscription } from '../../../services/api/realtime';
import machines from '../../../services/api/machines';
import NewDeviceButton from './NewDeviceButton';

import classes from './DevicesScreen.module.css';

const ONE_MINUTE = 60 * 1000;

type Props = {};
type States = {
	vms: Array<Object>,
	now: moment
};

class DevicesScreen extends React.Component<Props, States> {
	state = {
		vms: [],
		now: moment()
	};

	listeners: Array<Subscription> = [];
	interval = null;

	componentDidMount = async () => {
		await this.loadVMS();

		this.listeners.push(
			await realtime.subscribeKeepAlive(this.keepAliveHandler)
		);

		this.listeners.push(
			await realtime.subscribeStatusReport(this.statusReportHandler)
		);

		this.listeners.push(
			await realtime.subscribeProductInfo(this.productInfoHandler)
		);

		this.interval = setInterval(this.refreshStatus, ONE_MINUTE);
	};

	componentWillUnmount = () => {
		clearInterval(this.interval);
		this.listeners.forEach(listener => listener.unsubscribe());
	};

	loadAllVmDetailInfo = () => {
		const vmIDList = this.state.vms.map(vm => vm.vmID);
		// Load the assigned users for the machines
		VMApi.getAssignedUserForVendingMachines(vmIDList).then(responseData => {
			const newVms = this.state.vms.map(vm => {
				const newVM = { ...vm };
				const assignedUsersIndex = responseData.userList.findIndex(
					curUserGroup => vm.vmID === curUserGroup.VMID
				);
				if (assignedUsersIndex > -1) {
					newVM.assignedUsers = responseData.userList[assignedUsersIndex].users;
				} else {
					newVM.assignedUsers = undefined;
				}

				return newVM;
			});
			this.setState({ vms: newVms });
		});

		// Load the last errors for the machines
		VMApi.getLastErrorForVendingMachines(vmIDList).then(responseData => {
			const newVms = this.state.vms.map(vm => {
				const newVM = { ...vm };

				const assignedErrorIndex = responseData.events.findIndex(
					lastError => vm.vmID === lastError.vendingMachineID
				);
				if (assignedErrorIndex > -1) {
					newVM.lastError = responseData.events[assignedErrorIndex];
				} else {
					newVM.lastError = undefined;
				}
				return newVM;
			});
			this.setState({ vms: newVms });
		});

		// Load the daily income for the machine
		VMApi.getDailyIncomeFromMachine(vmIDList).then(responseData => {
			const newVms = this.state.vms.map(vm => {
				const newVM = { ...vm };

				const dailyIncomeIndex = responseData.incomeList.findIndex(
					lastError => vm.vmID === lastError.vendingMachineID
				);

				if (dailyIncomeIndex > -1) {
					newVM.dailyIncome = responseData.incomeList[dailyIncomeIndex];
				} else {
					newVM.dailyIncome = undefined;
				}

				return newVM;
			});
			this.setState({ vms: newVms });
		});

		VMApi.getLastSaleForVendingMachines(vmIDList).then(responseData => {
			const newVms = this.state.vms.map(vm => {
				const newVM = { ...vm };

				const lastSaleIndex = responseData.lastSales.findIndex(
					lastError => vm.vmID === lastError.vendingMachineID
				);
				if (lastSaleIndex > -1) {
					newVM.lastSale = responseData.lastSales[lastSaleIndex];
				} else {
					newVM.lastSale = undefined;
				}
				return newVM;
			});
			this.setState({ vms: newVms });
		});
	};

	refreshStatus = () => {
		const vms = this.state.vms.map(vm => {
			if (
				!vm.statusUpdatedAt ||
				(vm.statusUpdatedAt &&
					moment(vm.statusUpdatedAt).isBefore(moment().subtract(10, 'minutes')))
			) {
				vm.onlineState = 'offline';
			} else {
				vm.onlineState = 'online';
			}
			return vm;
		});

		this.setState({ now: moment(), vms });
	};

	productInfoHandler = (message: {}, params: { vmID: string }) => {
		try {
			this.refreshVmById(params.vmID);
		} catch (e) {
			logger.error(e);
		}
	};

	keepAliveHandler = (
		message: { status: string, timestamp: number },
		params: { vmID: string }
	) => {
		try {
			this.updateVmById(params.vmID, {
				idState: message.status,
				statusUpdatedAt: message.timestamp
			});
		} catch (e) {
			logger.error(e);
		}
	};

	statusReportHandler = (
		message: { type: string, date: number },
		params: { vmID: string }
	) => {
		try {
			this.updateVmById(params.vmID, {
				idState: message.type,
				statusUpdatedAt: message.date
			});
		} catch (e) {
			logger.error(e);
		}
	};

	updateVmById = async (vmID: string, params: Object) => {
		try {
			const vms = this.state.vms.map(vm => {
				if (vm.vmID === vmID) {
					return {
						...vm,
						...params
					};
				}
				return vm;
			});
			this.setState({ vms });
		} catch (e) {}
	};

	refreshVmById = async (vmID: string) => {
		try {
			const response = await VMApi.getVMDetail(vmID);
			const newVM = response.data.response;
			this.updateVmById(vmID, newVM);
		} catch (e) {}
	};

	//Update table data
	loadVMS = async () => {
		try {
			const response =
				UserUtil.isTechnican() || UserUtil.isRegionalCompanyAdmin
					? await VMApi.getVisibleVendingMachines()
					: await VMApi.getCustomerVM();
			const vms = Object.values(response.data.response);
			this.setState(
				{ vms }
				//, () => this.loadAllVmDetailInfo()
			);
		} catch (e) {
			logger.error(e);
		}
	};

	render() {
		const { t } = this.props;
		const { vms } = this.state;

		return (
			<div className="animated fadeIn">
				<Row>
					<DashboardCardContainerWithFlow>
						<Card body>
							<div className={classes.RootCardTraffic}>
								<PageTitleCard title={t('devicelist.title')} />
							</div>

							<Card className={this.props.className} style={this.props.style}>
								<CardBody>
									<Row
										style={{
											justifyContent: 'space-between',
											marginBottom: '10px'
										}}
									>
										<Col>
											<CardTitle className="mb-0 text-muted">
												<i
													className="icon-settings"
													style={{ marginRight: '10px' }}
												></i>
												{t('devicelist.table.header')}
											</CardTitle>

											<div className="small text-muted"></div>
										</Col>
									</Row>

									<DeviceList reload={this.loadVMS} renderDocked vms={vms} />
								</CardBody>
							</Card>
						</Card>
					</DashboardCardContainerWithFlow>
				</Row>
			</div>
		);
	}
}

export default withRouter(withTranslation()(DevicesScreen));
