import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// import VMIDDecoder from './VMIDDecoder';

import DevicesScreen from '../../views/VMlistComponens/Devices/DevicesScreen';
import DeviceDetailer from '../../views/VMlistComponens/DeviceDetailer/DeviceDetailer';

//Machines
import AntaresTroll from '../../views/VMlistComponens/Machines/AntaresTroll/AntaresTroll';
import Astro from '../../views/VMlistComponens/Machines/Astro/Astro';
import AstroP from '../../views/VMlistComponens/Machines/AstroP/AstroP';
import Kikko from '../../views/VMlistComponens/Machines/Kikko/Kikko';
import KikkoMax from '../../views/VMlistComponens/Machines/KikkoMax/KikkoMax';
import SnakkyMax from '../../views/VMlistComponens/Machines/Snakky/SnakkyMax';
import Snakky from '../../views/VMlistComponens/Machines/Snakky/Snakky';
import Zeta from '../../views/VMlistComponens/Machines/Zeta/Zeta';
import Canto from '../../views/VMlistComponens/Machines/Canto/Canto';
import Wurlitzer from '../../views/VMlistComponens/Machines/Wurlitzer/Wurlitzer';
import Bvm971 from '../../views/VMlistComponens/Machines/Bvm971/Bvm971';
import Bvm972 from '../../views/VMlistComponens/Machines/Bvm972/Bvm972';
import SpazioRD from '../../views/VMlistComponens/Machines/SpazioRD/SpazioRD';
import Spazio from '../../views/VMlistComponens/Machines/Spazio/Spazio';
import Group500 from '../../views/VMlistComponens/Machines/Group500/Group500';
import Luce18 from '../../views/VMlistComponens/Machines/Luce18/Luce18';
import Luce12 from '../../views/VMlistComponens/Machines/Luce12/Luce12';
import Luce12v2 from '../../views/VMlistComponens/Machines/Luce12v2/Luce12v2';
import Quarzo500 from '../../views/VMlistComponens/Machines/Quarzo500/Quarzo500';
import CaffeEuropa from '../../views/VMlistComponens/Machines/CaffeEuropa/CaffeEuropa';
import Venezia from '../../views/VMlistComponens/Machines/Venezia/Venezia';
import VeneziaPlus from '../../views/VMlistComponens/Machines/VeneziaPlus/VeneziaPlus';
import Zenith from '../../views/VMlistComponens/Machines/Zenith/Zenith';
import JedeLynx from '../../views/VMlistComponens/Machines/JedeLynx/JedeLynx';
import Lazio from '../../views/VMlistComponens/Machines/Lazio/Lazio';
import LucePortofino from '../../views/VMlistComponens/Machines/LucePortofino/LucePortofino';
import Brio3 from '../../views/VMlistComponens/Machines/Brio3/Brio3';
import LuceX2 from '../../views/VMlistComponens/Machines/LuceX2/LuceX2';
import Brio3MiniSnakky from '../../views/VMlistComponens/Machines/Brio3MiniSnakky/Brio3MiniSnakky';
import Azkoyen from '../../views/VMlistComponens/Machines/Azkoyen/Azkoyen';

class Vms extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route
						path="/vms/:vmID/detailer/antarestroll/"
						name="AntaresTroll"
						component={AntaresTroll}
					/>
					<Route
						path="/vms/:vmID/detailer/brio3minisnakky/"
						name="Brio3MiniSnakky"
						component={Brio3MiniSnakky}
					/>
					<Route
						path="/vms/:vmID/detailer/luceX2/"
						name="LuceX2"
						component={LuceX2}
					/>
					<Route
						path="/vms/:vmID/detailer/brio3/"
						name="Brio3"
						component={Brio3}
					/>
					<Route
						path="/vms/:vmID/detailer/azkoyen/"
						name="Azkoyen"
						component={Azkoyen}
					/>
					<Route
						path="/vms/:vmID/detailer/luceportofino/"
						name="LucePortofino"
						component={LucePortofino}
					/>
					<Route
						path="/vms/:vmID/detailer/lazio/"
						name="Lazio"
						component={Lazio}
					/>
					<Route
						path="/vms/:vmID/detailer/jedelynx/"
						name="JedeLynx"
						component={JedeLynx}
					/>
					<Route
						path="/vms/:vmID/detailer/zenith/"
						name="Zenith"
						component={Zenith}
					/>
					<Route
						path="/vms/:vmID/detailer/venezia/"
						name="Venezia"
						component={Venezia}
					/>
					<Route
						path="/vms/:vmID/detailer/veneziaplus/"
						name="VeneziaPlus"
						component={VeneziaPlus}
					/>

					<Route
						path="/vms/:vmID/detailer/quarzo500/"
						name="Quarzo500"
						component={Quarzo500}
					/>
					<Route
						path="/vms/:vmID/detailer/caffeeuropa/"
						name="CaffeEuropa"
						component={CaffeEuropa}
					/>
					<Route
						path="/vms/:vmID/detailer/luce18/"
						name="Luce18"
						component={Luce18}
					/>
					<Route
						path="/vms/:vmID/detailer/luce12/"
						name="Luce12"
						component={Luce12}
					/>
					<Route
						path="/vms/:vmID/detailer/luce12v2/"
						name="Luce12v2"
						component={Luce12v2}
					/>

					<Route
						path="/vms/:vmID/detailer/group500/"
						name="Group500"
						component={Group500}
					/>
					<Route
						path="/vms/:vmID/detailer/spazioRD/"
						name="SpazioRD"
						component={SpazioRD}
					/>
					<Route
						path="/vms/:vmID/detailer/spazio/"
						name="Spazio"
						component={Spazio}
					/>
					<Route
						path="/vms/:vmID/detailer/bvm972/"
						name="Bvm972"
						component={Bvm972}
					/>
					<Route
						path="/vms/:vmID/detailer/bvm971/"
						name="Bvm971"
						component={Bvm971}
					/>
					<Route
						path="/vms/:vmID/detailer/wurlitzer/"
						name="Wurlitzer"
						component={Wurlitzer}
					/>
					<Route
						path="/vms/:vmID/detailer/snakky/"
						name="Snakky"
						component={Snakky}
					/>
					<Route
						path="/vms/:vmID/detailer/snakkymax/"
						name="SnakkyMay"
						component={SnakkyMax}
					/>
					<Route
						path="/vms/:vmID/detailer/kikko/"
						name="Kikko"
						component={Kikko}
					/>
					<Route
						path="/vms/:vmID/detailer/kikkomax/"
						name="KikkoMax"
						component={KikkoMax}
					/>

					<Route
						path="/vms/:vmID/detailer/astro/"
						name="Astro"
						component={Astro}
					/>
					<Route
						path="/vms/:vmID/detailer/astrop/"
						name="AstroP"
						component={AstroP}
					/>

					<Route
						path="/vms/:vmID/detailer/zeta/"
						name="Zeta"
						component={Zeta}
					/>
					<Route
						path="/vms/:vmID/detailer/canto/"
						name="Canto"
						component={Canto}
					/>

					<Route
						path="/vms/:vmID/detailer"
						name="Detailer"
						component={DeviceDetailer}
					/>

					<Route path="/vms" name="Vms" component={DevicesScreen} />

					<Redirect from="/" to="/vms" />
				</Switch>
			</div>
		);
	}
}
//<Container fluid>
export default Vms;
