//@flow
import React from 'react'

import { withRouter } from 'react-router-dom'

import KlixLCD from './KlixLCD'
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard'
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent'

type Props = {
  products?: Array<Object>,
}
class Klix extends React.Component<Props> {
  keyboardConfig = [
    [
      {
        buttonContent: (
          <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
        ),
        buttonID: '17',
      },
      {
        buttonContent: (
          <i className="fa fa-long-arrow-up" aria-hidden="true"></i>
        ),
        buttonID: '18',
      },
      {
        buttonContent: (
          <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
        ),
        buttonID: '19',
      },
      {
        buttonContent: <>&#9166;</>,
        buttonID: '21',
      },
    ],
    [
      {
        buttonContent: '?',
        buttonID: '23',
      },
      {
        buttonContent: (
          <i className="fa fa-long-arrow-down" aria-hidden="true"></i>
        ),
        buttonID: '20',
      },
      {
        buttonContent: <i className="fa fa-info" aria-hidden="true"></i>,
        buttonID: '28',
      },
      {
        buttonContent: <i className="fa fa-times" aria-hidden="true"></i>,
        buttonID: '22',
      },
    ],
    [
      {
        buttonContent: '1',
        buttonID: '1',
        ledIndex: 0,
      },
      {
        buttonContent: '2',
        buttonID: '2',
        ledIndex: 1,
      },
      {
        buttonContent: '3',
        buttonID: '3',
        ledIndex: 2,
      },
      {
        buttonContent: '4',
        buttonID: '4',
        ledIndex: 3,
      },
    ],
    [
      {
        buttonContent: '5',
        buttonID: '5',
        ledIndex: 4,
      },
      {
        buttonContent: '6',
        buttonID: '6',
        ledIndex: 5,
      },
      {
        buttonContent: '7',
        buttonID: '7',
        ledIndex: 6,
      },
      {
        buttonContent: '8',
        buttonID: '8',
        ledIndex: 7,
      },
    ],

    [
      {
        buttonContent: '9',
        buttonID: '9',
        ledIndex: 8,
      },
      {
        buttonContent: '10',
        buttonID: '10',
        ledIndex: 9,
      },
      {
        buttonContent: '11',
        buttonID: '11',
        ledIndex: 10,
      },
      {
        buttonContent: '12',
        buttonID: '12',
        ledIndex: 11,
      },
    ],
    [
      {
        buttonContent: '13',
        buttonID: '13',
        ledIndex: 12,
      },
      {
        buttonContent: '14',
        buttonID: '14',
        ledIndex: 13,
      },
      {
        buttonContent: '15',
        buttonID: '15',
        ledIndex: 14,
      },
      {
        buttonContent: '16',
        buttonID: '16',
        ledIndex: 15,
      },
    ],
  ]

  render() {
    const { products = [] } = this.props

    return (
      <DirectChooseMachineBaseComponent
        machineTitle="Klix"
        lineNumber={2}
        lineLength={40}
        ledNumbers={16}
        lcd={<KlixLCD />}
        keyboard={
          <DirectChooseKeyboard
            keyboardHeaderLine={<br />}
            keyboardConfig={this.keyboardConfig}
            products={products}
            type="vertical"
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(Klix)
