import store from '../../store/index';
import { ROLES } from '../util/consts';

export default class UserUtil {
	static isRole = role => {
		const { user } = store.getState();
		if (!user) {
			return false;
		}
		return user.get('privilege', null) === role;
	};

	static isRoot = () => this.isRole(ROLES.root);
	static isHVendTechnican = () => this.isRole(ROLES.hvendTechnican);
	static isMediaManager = () => this.isRole(ROLES.contentManager);
	static isTechnican = () => this.isRole(ROLES.technician);
	static isRegionalCompanyAdmin = () => this.isRole(ROLES.regionalCompanyAdmin);
	static isAdministrator = () => this.isRole(ROLES.companyAdmin);
}
