import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';
import ChartByType from './ChartByType';
import userAPI from '../../services/api/users';
import qs from 'qs';

class CashFlow extends Component {
	state = { chartType: 'monthly' };
	componentDidMount = async () => {
		const urlParams = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true
		});
		let chartType = this.state.chartType;
		let startDate = null;
		let endDate = null;
		if (urlParams && urlParams.chartType) {
			chartType = urlParams.chartType;
			this.setState({ chartType });
		}
		try {
			const resp = await userAPI.getDashboardPeriod();
			if (resp && resp.data && resp.data.periodStart) {
				startDate = new Date(resp.data.periodStart);
			}
			if (resp && resp.data && resp.data.periodEnd) {
				endDate = new Date(resp.data.periodEnd);
			}
			this.setState({
				startDate,
				endDate,
				chartType
			});
		} catch {
			this.setState({
				startDate: new Date(),
				endDate: new Date(),
				chartType
			});
		}
	};
	render() {
		const income = true;
		const { chartType, startDate, endDate } = this.state;
		return (
			<div
				className="animated fadeIn"
				style={{
					paddingLeft: '20px',
					paddingRight: '20px',
					paddingTop: '10px'
				}}
			>
				<Card body>
					<PageTiltleCard title={this.props.t('chasFlowChart.title')} />
				</Card>
				{startDate && endDate ? (
					<>
						<ChartByType
							chartType={chartType}
							startDate={startDate}
							endDate={endDate}
							income
						/>
						<ChartByType
							chartType={chartType}
							startDate={startDate}
							endDate={endDate}
						/>
					</>
				) : null}
			</div>
		);
	}
}

export default withRouter(withTranslation()(CashFlow));
