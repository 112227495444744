import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const getRouteName = url => {
	var name = url;

	url.split('/').reduce((prev, curr, index) => {
		name = '' + curr;
		return null;
	});

	return name;
};

const getPaths = pathname => {
	const paths = ['/'];

	if (pathname === '/') return paths;

	pathname.split('/').reduce((prev, curr, index) => {
		const currPath = `${prev}/${curr}`;
		paths.push(currPath);
		return currPath;
	});
	return paths;
};

const BreadcrumbsItem = withTranslation()(({ t, match }) => {
	const routes = {
		'/': 'Home',
		dashboard: t('breadcrumb.dashboard'),
		settings: t('breadcrumb.settings'),
		users: t('breadcrumb.users'),

		vms: t('breadcrumb.devices'),
		list: t('breadcrumb.vms'),
		detailer: t('breadcrumb.detailer'),
		vmDebug: 'Debug',
		astro: 'Astro',
		kikko: 'Kikko',
		snakky: 'SnakkyMax',

		setPromotion: t('breadcrumb.setPromotion'),
		remoteMonitoring: t('breadcrumb.remoteMonitoring'),
		useredit: t('breadcrumb.editprofile'),
		editprofile: t('breadcrumb.editprofile'),
		addUser: t('breadcrumb.addnewuser'),

		logs: t('breadcrumb.logs'),
		customerVMS: t('breadcrumb.customerVms'),
		customerUsers: t('breadcrumb.customerUsers'),
		operatorVMS: t('breadcrumb.operatorVms'),
		CreatOperator: t('breadcrumb.creatOperator'),
		editOperator: t('breadcrumb.editOperator'),

		rootUsers: t('breadcrumb.rootUsers'),
		addAdmin: t('breadcrumb.addAdmin'),

		hardwareManagement: t('breadcrumb.hardwareManagement'),
		partnerManagement: t('breadcrumb.partnerManagement'),

		notifications: t('breadcrumb.notifications')
	};
	const routeName = getRouteName(match.url);
	const routeText = routes[routeName] || routeName;
	return match.isExact || !routes[routeName] ? (
		<BreadcrumbItem active>{routeText}</BreadcrumbItem>
	) : (
		<BreadcrumbItem>
			<Link to={match.url || ''}>{routeText}</Link>
		</BreadcrumbItem>
	);
});

const Breadcrumbs = ({ location: { pathname } }) => {
	const paths = getPaths(pathname);

	const items = paths.map((path, i) => (
		<Route key={i++} path={path} component={BreadcrumbsItem} />
	));
	return <Breadcrumb style={{ marginBottom: '0px' }}>{items}</Breadcrumb>;
};

export default props => (
	<div>
		<Route path="/:path" component={Breadcrumbs} {...props} />
	</div>
);
