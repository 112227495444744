import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import classes from './PromotionTable.module.css';
import Spinner from '../../../components/UI/Spinner/Spinner';
import DescriptionTooltip from '../../GroupManager/DeviceGroupManagerTab/DeviceGroupTable/DescriptionTooltip';
import { withTranslation } from 'react-i18next';
import promotionsUtil from '../../../services/util/promotionsUtil';

const PromotionDetailsTable = props => {
	const { t } = props;
	const userFormatter = (cell, row) => {
		return (
			<i
				className="fa fa-bookmark"
				style={{ backgroundColor: 'transparent', color: '#800000' }}
			/>
		);
	};

	const containParameterName = paramName => {
		return promotionsUtil.hasParamByName(props.data[0], paramName);
	};

	const NameValidator = paramName => {
		return promotionsUtil.hasParamByName(props.data[0], paramName);
	};

	const descriptionFormater = cell => {
		return <strong>{cell}</strong>;
	};

	const promotionNameFormater = cell => {
		switch (cell) {
			case 0:
				return <strong>{t('promotionManager.promotionTable.general')}</strong>;
			case 1:
				return <strong>{t('promotionManager.promotionTable.firstBuy')}</strong>;
			case 2:
				return (
					<strong>{t('promotionManager.promotionTable.sequental')}</strong>
				);
			case 3:
				return (
					<strong>{t('promotionManager.promotionTable.creditBonus')}</strong>
				);
			case 4:
				return <strong>{t('promotionManager.promotionTable.product')}</strong>;

			case 5:
				return (
					<strong>
						{t('promotionManager.promotionTable.onlineCreditBonus')}
					</strong>
				);
			case 6:
				return (
					<strong>
						{t('promotionManager.promotionTable.monthlyBonusCredit')}
					</strong>
				);
		}
	};

	const promotionDiscountValueFormater = row => {
		let badge = null;
		if (row.length > 0) {
			const value = row.filter(
				p =>
					p.paramname ===
					promotionsUtil.promotionParameters.PROMOTION_GENERAL_DISCOUNT
			);

			badge = (
				<>
					<strong>{value[0].paramvalue}</strong>&nbsp;
					<i
						className={
							promotionsUtil.isFixAmount(
								props.data[0],
								promotionsUtil.promotionParameters
									.PROMOTION_GENERAL_DISCOUNT_FIX_AMOUNT
							)
								? 'fa fa-dollar'
								: 'fa fa-percent'
						}
					/>
				</>
			);
		}
		return badge;
	};

	const promotionSequentalNumberFormater = cell => {
		let badge = null;
		if (cell.length > 1 && NameValidator('PROMOTION_SEQUENTIAL_NUMBER')) {
			badge = (
				<>
					<strong>
						{
							cell.find(
								param => param.paramname === 'PROMOTION_SEQUENTIAL_NUMBER'
							).paramvalue
						}
					</strong>
				</>
			);
		}
		return badge;
	};

	const promotedMachineFormater = cell => {
		const name = props.machines.find(e => e.vmID === cell);

		return name ? (
			<strong>{name.customName}</strong>
		) : (
			t('promotionManager.promotionTable.noData')
		);
	};

	const promotedMachineGroupFormater = cell => {
		const name = props.machineGroups.find(e => e.id === cell);
		return name ? (
			<strong>{name.name}</strong>
		) : (
			t('promotionManager.promotionTable.noData')
		);
	};

	const promotedProductFormater = cell => {
		let badge = null;
		if (cell.length > 1 && NameValidator('PROMOTION_PRODUCT_TAGS')) {
			badge = (
				<strong>
					{
						cell.find(param => param.paramname === 'PROMOTION_PRODUCT_TAGS')
							.paramvalue
					}
				</strong>
			);
		}
		return badge;
	};

	const requierdCreditFormater = cell => {
		let badge = null;
		if (
			cell.length > 1 &&
			NameValidator(
				promotionsUtil.promotionParameters.PROMOTION_MINIMUM_CREDIT_UPLOAD
			)
		) {
			badge = (
				<strong>
					{
						cell.find(
							param =>
								param.paramname ===
								promotionsUtil.promotionParameters
									.PROMOTION_MINIMUM_CREDIT_UPLOAD
						).paramvalue
					}
				</strong>
			);
		}
		return badge;
	};

	const tableOptions = {
		sortIndicator: true,
		hideSizePerPage: false,
		withFirstAndLast: true,
		alwaysShowAllBtns: false,
		clearSearch: true,
		hidePageListOnlyOnePage: true,
		sortName: 'id',
		sortOrder: 'asc'
	};

	let table = <Spinner />;
	if (props.data && props.data.length > 0) {
		table = (
			<>
				<BootstrapTable
					key="bootstrapTable"
					data={props.data}
					version="4"
					//	striped
					//hover
					insertRow={false}
					options={tableOptions}
					multiColumnSearch
					condensed
					bordered={false}
					dataAlign={'center'}
					size="sm"
					tableBodyClass={classes.TableClass}
					tableHeaderClass={classes.HeaderClass}
					containerStyle={{
						margin: '-5px',
						marginBottom: '0px'
					}}
				>
					<TableHeaderColumn
						isKey
						width="30"
						dataAlign="center"
						dataField="id"
						dataFormat={userFormatter}
						tdStyle={{ paddingTop: '15px' }}
					/>
					<TableHeaderColumn
						dataField="name"
						width="130"
						dataAlign="center"
						dataSort
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
						expandable={false}
					>
						{t('promotionManager.promotionTable.promotionName')}
					</TableHeaderColumn>

					<TableHeaderColumn
						dataField="promotionTypeID"
						dataFormat={promotionNameFormater}
						width="130"
						dataAlign="center"
						dataSort
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{t('promotionManager.promotionTable.promotionType')}
					</TableHeaderColumn>
					{props.data[0].description ? (
						<TableHeaderColumn
							dataField="description"
							dataSort
							width="120"
							dataAlign="center"
							dataFormat={descriptionFormater}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal'
							}}
						>
							{t('promotionManager.promotionTable.description')}
						</TableHeaderColumn>
					) : null}
					{containParameterName(
						promotionsUtil.promotionParameters.PROMOTION_GENERAL_DISCOUNT
					) ? (
						<TableHeaderColumn
							dataField="params"
							dataFormat={promotionDiscountValueFormater}
							width="120"
							dataAlign="center"
							dataSort
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{promotionsUtil.isFixAmount(
								props.data[0],
								promotionsUtil.promotionParameters
									.PROMOTION_GENERAL_DISCOUNT_FIX_AMOUNT
							)
								? t('promotionManager.promotionTable.value')
								: t('promotionManager.promotionTable.percentage')}
						</TableHeaderColumn>
					) : null}

					{containParameterName('PROMOTION_SEQUENTIAL_NUMBER') ? (
						<TableHeaderColumn
							dataField="params"
							dataFormat={promotionSequentalNumberFormater}
							width="120"
							dataAlign="center"
							dataSort
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{t('promotionManager.promotionTable.sequentalNum')}
						</TableHeaderColumn>
					) : null}
					{props.data[0].assignedVMID ? (
						<TableHeaderColumn
							dataField="assignedVMID"
							dataFormat={promotedMachineFormater}
							width="120"
							dataAlign="center"
							dataSort
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{t('promotionManager.promotionTable.promotionDevice')}
						</TableHeaderColumn>
					) : null}
					{props.data[0].assignedGroupID ? (
						<TableHeaderColumn
							dataField="assignedGroupID"
							dataFormat={promotedMachineGroupFormater}
							width="120"
							dataAlign="center"
							dataSort
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{t('promotionManager.promotionTable.promotionDeviceGroup')}
						</TableHeaderColumn>
					) : null}

					{containParameterName('PROMOTION_PRODUCT_TAGS') ? (
						<TableHeaderColumn
							dataField="params"
							dataFormat={promotedProductFormater}
							width="120"
							dataAlign="center"
							dataSort
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{t('promotionManager.promotionTable.promotionProduct')}
						</TableHeaderColumn>
					) : null}

					{containParameterName(
						promotionsUtil.promotionParameters.PROMOTION_MINIMUM_CREDIT_UPLOAD
					) ? (
						<TableHeaderColumn
							dataField="params"
							dataFormat={requierdCreditFormater}
							width="120"
							dataAlign="center"
							dataSort
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{t(
								'promotionManager.addPromotion.promotionType.credit.label.required'
							)}
						</TableHeaderColumn>
					) : null}
				</BootstrapTable>
			</>
		);
	}

	return table;
};

export default withTranslation()(PromotionDetailsTable);
