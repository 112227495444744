import React, { Component } from 'react';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	FormGroup,
	Input,
	InputGroup,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';
// React select
import Select from 'react-select';
import '../../assets/scss/vendors/react-select/react-select.scss';
//Toast for alerts
import { toast } from 'react-toastify';

//Pörgős gomb
import 'ladda/dist/ladda-themeless.min.css';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { withTranslation } from 'react-i18next';

import OperatorApi from '../../services/api/operators';
import VMApi from '../../services/api/machines';
import logger from '../../services/logger';
import { connect } from 'react-redux';
import { ROLES } from '../../services/util/consts';

const mapStateToProps = state => ({
	user: state.user
});

class CreateOperator extends Component {
	constructor() {
		super();

		this.state = {
			email: '',
			expLeft: false, //a loding button-nak kell az állapot felméréséhez, hogy pörögjön e
			modal: false,
			success: false,
			selectedVMs: '',
			cmID: '',
			viewLCD: false,
			editVM: false,
			options: []
		};
	}

	//Komponens betöltése
	componentDidMount = () => {
		this.getVMS();
	};

	//Modal success
	toggleSuccess = () => {
		this.setState({
			success: !this.state.success
		});
	};

	//Szükséges mező
	needKeyError = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('error.missingFields'));
	};

	//Ez az email cím már van
	emailExist = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('error.emailAlreadyExist'));
	};

	//Reg gomb event-je
	handleClick = async () => {
		if (this.state.email !== '' && this.state.selectedVMs !== '') {
			//Regist töltés állapot beállítása
			this.setState({
				expLeft: true,
				progress: 0.5
			});

			const operatorData = {
				userMenu: 0,
				debugMenu: 0,
				vmsMenu: 0,
				regMenu: 0,
				privilege: ROLES.regionalCompanyAdmin,
				cmID: this.props.user.cmID,
				email: this.state.email,
				viewLCD: this.state.viewLCD,
				editVM: this.state.editVM
			};

			try {
				const response = await OperatorApi.operatorRegistration(operatorData);
				if (response.data.hasOwnProperty('response')) {
					//Kiválasztott vmk
					const vms = this.state.selectedVMs;

					for (var i = 0; i < vms.length; i++) {
						await this.postConnection(vms[i].value);
					}

					//Success
					this.toggleSuccess();

					//Ha error jön vissza
				} else {
					this.setState({
						expLeft: false,
						progress: 1.0
					});

					//Ez az email cím már van , alert feldobása
					this.emailExist();
				}
			} catch (error) {
				logger.error(error);
			}
		} else {
			this.setState({
				expLeft: false,
				progress: 1.0
			});

			//Mezők kitöltése kötelező
			this.needKeyError();
		}
	};

	//getVMS
	getVMS = async () => {
		var cmID = this.props.user.cmID;

		try {
			const response = await VMApi.getVmBycmID(cmID);
			const options = response.data.response; //{0:'Kasun', 1:'columbo',2: '29'}

			//Frissíteni kell
			this.setState({ options });
		} catch (error) {
			logger.error(error);
		}
	};

	//postConnection
	postConnection = async vmID => {
		var cmID = this.props.user.cmID;
		var email = this.state.email;
		try {
			await OperatorApi.postOperatorConnections(cmID, vmID, email);
		} catch (error) {
			logger.error(error);
		}
	};

	//OK alaphelyzetbe állít
	cancel = () => {
		this.setState({
			email: '',
			cmID: '',
			success: !this.state.success,
			expLeft: false,
			viewLCD: false,
			editVM: false
		});
	};

	//Email field változás
	handleEmailChange = event => {
		this.setState({ email: event.target.value });
	};

	//Selected update cmID
	selectedVMsChanged = newValue => {
		this.setState({
			selectedVMs: newValue
		});
	};

	//Checked boxes

	//ViewLCD checked
	checkedViewLCD = () => {
		this.setState({
			viewLCD: !this.state.viewLCD
		});
	};

	//EditVM checked
	checkedEditVM = () => {
		this.setState({
			editVM: !this.state.editVM
		});
	};

	//cmID selector View
	getCMView = () => {
		const options = this.state.options.map(category => ({
			value: category.vmID,
			label: category.machine + ' - ' + category.company + ' - ' + category.vmID
		}));
		const { t } = this.props;
		return (
			<Col xs="10" sm="8" md="8" lg="12" xl="5">
				<Card>
					<CardHeader>
						<i className="fa fa-plus" />
						<b>{t('creatoperator.card3.title')}</b>
					</CardHeader>
					<CardBody className="p-8">
						<h6>vmID:</h6>
						<Row>
							<Col>
								<Select
									multi={true}
									name="form-field-name2"
									value={this.state.selectedVMs}
									onChange={this.selectedVMsChanged}
									options={options}
									labelKey="label"
									valueKey="value"
									clearable
								/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Col>
		);
	};

	//Previliges selector view
	getPrevView = () => {
		const { t } = this.props;
		return (
			<Col xs="10" sm="8" md="8" lg="12" xl="5">
				<Card>
					<CardHeader>
						<i className="fa fa-lock" />
						<b>{t('creatoperator.card2.title')}</b>
					</CardHeader>
					<CardBody className="p-8">
						<Row>
							<Col xs="12">
								<FormGroup row>
									<Col md="3">
										<Label>{t('creatoperator.vmsprivilege.title')}</Label>
									</Col>
									<Col md="9">
										<FormGroup check className="checkbox">
											<Input
												className="form-check-input"
												type="checkbox"
												id="viewLCD"
												name="viewLCD"
												checked={this.state.viewLCD}
												onChange={this.checkedViewLCD}
											/>
											<Label
												check
												className="form-check-label"
												htmlFor="viewLCD"
											>
												{t('creatoperator.vmsprivilege.checkbox1')}
											</Label>
										</FormGroup>
										<FormGroup check className="checkbox">
											<Input
												className="form-check-input"
												type="checkbox"
												id="editVM"
												name="editVM"
												checked={this.state.editVM}
												onChange={this.checkedEditVM}
											/>
											<Label
												check
												className="form-check-label"
												htmlFor="editVM"
											>
												{t('creatoperator.vmsprivilege.checkbox2')}
											</Label>
										</FormGroup>
									</Col>
								</FormGroup>
							</Col>
						</Row>

						<Row>
							<Col xs="6">
								<LaddaButton
									id="redButton"
									className="btn btn-success btn-ladda"
									loading={this.state.expLeft}
									data-style={EXPAND_LEFT}
									onClick={this.handleClick}
								>
									{t('login.registration.button')}
								</LaddaButton>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Col>
		);
	};

	render() {
		//userhez tartozó cmID
		const cmID = this.props.user.cmID;

		const { t } = this.props;
		return (
			<div>
				<Modal
					isOpen={this.state.success}
					toggle={this.toggleSuccess}
					className={'modal-success ' + this.props.className}
				>
					<ModalHeader toggle={this.toggleSuccess}>
						{t('login.registration.modal.title')}
					</ModalHeader>
					<ModalBody>{t('login.registration.modal.info')}</ModalBody>
					<ModalFooter>
						<Button color="success" onClick={this.cancel}>
							OK
						</Button>{' '}
					</ModalFooter>
				</Modal>

				<Row>
					<Col xs="10" sm="8" md="8" lg="10" xl="5">
						<Card>
							<CardHeader>
								<i className="icon-people" />
								<b>{t('creatoperator.card1.title')}</b>
							</CardHeader>
							<CardBody className="p-8">
								<Row>
									<Col xs="12">
										<FormGroup>
											<h6>Email:</h6>
											<InputGroup className="mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="fa fa-at" />
													</span>
												</div>
												<Input
													type="email"
													placeholder={t('login.email')}
													value={this.state.email}
													onChange={this.handleEmailChange}
												/>
											</InputGroup>
										</FormGroup>
									</Col>
								</Row>

								<Row>
									<Col xs="12">
										<FormGroup>
											<h6>Customer ID:</h6>
											<InputGroup className="mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="icon-user-follow" />
													</span>
												</div>
												<Input
													type="text"
													placeholder="cmID"
													value={cmID}
													disabled
												/>
											</InputGroup>
										</FormGroup>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>

					{this.getCMView()}
					{this.getPrevView()}
				</Row>
			</div>
		);
	}
}

export default connect(mapStateToProps)(withTranslation()(CreateOperator));
