import transactionTypes from './transactionTypes';

export default class {
	static formID = {
		ADD_BALANCE_FORM: 'addBlanceForm',
		REMOVE_BALANCE_FORM: 'removeBlanceForm'
	};

	static getCalculationTypes = () => {
		const allCalculationTypes = { ...transactionTypes };
		allCalculationTypes.ALL = 'ALL';
		return allCalculationTypes;
	};
	static getLocalStorage = () => {
		const allCalculationTypes = this.getCalculationTypes();

		Object.keys(allCalculationTypes).forEach(key => {
			if (document.getElementById(key + '_SUM')) {
				document.getElementById(key + '_SUM').innerHTML = Number(
					localStorage.getItem(key + '_SUM')
				);
			}

			if (document.getElementById(key + '_COUNT')) {
				document.getElementById(key + '_COUNT').innerHTML = Number(
					localStorage.getItem(key + '_COUNT')
				);
			}
		});
	};

	static amountFormatter = amount => {
		return amount ? (amount * 100).toFixed() / 100 : '';
	};

	static setParamToStore = value => {
		const allCalculationTypes = this.getCalculationTypes();

		Object.keys(allCalculationTypes).forEach(toCalculate => {
			if (toCalculate != 'ALL') {
				const transactions = value.filter(
					e => e.transactionTypeID == allCalculationTypes[toCalculate]
				);

				localStorage.setItem(toCalculate + '_COUNT', transactions.length);

				let sum = this.amountFormatter(
					transactions
						.map(e => (e.amount ? e.amount : 0))
						.reduce((a, b) => a + b, 0)
				);

				localStorage.setItem(toCalculate + '_SUM', sum);
			} else {
				localStorage.setItem(toCalculate + '_COUNT', value.length);

				localStorage.setItem(
					toCalculate + '_SUM',
					value.map(e => (e.amount ? e.amount : 0)).reduce((a, b) => a + b, 0)
				);
			}
		});

		this.getLocalStorage();
	};
}
