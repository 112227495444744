import React from 'react';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import '../DeviceGroups/AddMachineGroupForm/node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import classes from './CostumerListTable.module.css';

const CustomerListTable = props => {
	//Edit ikon klikk
	const editFormatter = (cell, row) => {
		const { t } = props;
		return (
			<>
				<Button
					onClick={e => props.editProfil(row)}
					variant="success"
					className={classes.EditButton}
				>
					<i className="fa fa-pencil mr-2" />
					{t('groupManager.mainTable.edit.edit')}
				</Button>
				<Button
					onClick={e => props.onDeleteUser(row)}
					className={classes.DeleteButton}
				>
					<i className="fa fa-trash mr-2" />
					{t('groupManager.mainTable.edit.delete')}
				</Button>
			</>
		);
	};

	const userFormatter = (cell, row) => {
		return <i className="icon-people" id="userIcon" />;
	};

	//Render Table
	const isColumnsVisibile = columName => {
		return !props.hiddenColumns || props.hiddenColumns.indexOf(columName) < 0;
	};

	//Table options
	const tableOptions = {
		sortIndicator: true,
		hideSizePerPage: false,
		paginationSize: 3,
		sizePerPageList: [10],
		sizePerPage: props.recordPerPage || 10,
		paginationPosition: 'bottom',
		withFirstAndLast: true,
		alwaysShowAllBtns: true,
		pageStartIndex: 1,
		clearSearch: true,
		hidePageListOnlyOnePage: true,
		sortName: 'ID',
		sortOrder: 'asc',
		searchPanel: props.renderFilterBar,
		onSearchChange: props.onFilterChange
	};

	return (
		<>
			{!props.hideToolbar ? props.renderFilterBar() : null}
			<BootstrapTable
				key="bootstrapTable"
				data={props.data}
				version="4"
				striped
				hover
				pagination={!props.hidePager}
				insertRow={false}
				options={tableOptions}
				multiColumnSearch
				condensed
				bordered={false}
				dataAlign={'center'}
				size="sm"
			>
				{isColumnsVisibile('userIcon') ? (
					<TableHeaderColumn
						isKey
						width="30"
						dataAlign="center"
						dataField="ID"
						dataFormat={userFormatter}
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px' }}
					/>
				) : null}
				{isColumnsVisibile('companyName') ? (
					<TableHeaderColumn
						width="150"
						dataField="companyName"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{props.t('customerlist.basicdata.label.companyname')}
					</TableHeaderColumn>
				) : null}

				{isColumnsVisibile('address') ? (
					<TableHeaderColumn
						width="150"
						dataField="address"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
					>
						{props.t('customerlist.basicdata.label.location')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('taxNumber') ? (
					<TableHeaderColumn
						width="150"
						dataField="taxNumber"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
					>
						{props.t('customerlist.basicdata.label.taxnumber')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('contactPerson') ? (
					<TableHeaderColumn
						width="150"
						dataField="contactPerson"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal'
						}}
					>
						{props.t('customerlist.contact.label.contactperson')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('contactEmail') ? (
					<TableHeaderColumn
						width="150"
						// isKey
						dataField="contactEmail"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
					>
						{props.t('customerlist.contact.label.email')}
					</TableHeaderColumn>
				) : null}

				{isColumnsVisibile('contactPhone') ? (
					<TableHeaderColumn
						width="150"
						dataField="contactPhone"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
					>
						{props.t('customerlist.contact.label.phone')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('action') ? (
					<TableHeaderColumn
						width="90"
						dataField="userID"
						dataFormat={editFormatter}
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					>
						{props.t('customerlist.editbutton.title')}
					</TableHeaderColumn>
				) : null}
			</BootstrapTable>
		</>
	);
};

export default withTranslation()(CustomerListTable);
