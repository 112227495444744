//@flow
import React from 'react'

import { Col, Row } from 'reactstrap'

import { MachineButton as BaseButton } from '../../../../components/Machines/MachineComponents'
import styled from 'styled-components'
const MachineButton = styled(BaseButton)`
  font-size: small;
`

const Brio3KeyboardHeader = ({
  onButtonClick = (key: string) => async () => {},
}: {
  onButtonClick?: (key: string) => () => Promise<void>,
}) => {
  return (
    <Row>
      <Col>
        <MachineButton onClick={onButtonClick('100')}>
          Programming
        </MachineButton>
      </Col>
      <Col>
        <MachineButton>Mixer Wash</MachineButton>
      </Col>{' '}
      <Col>
        <MachineButton>Failure reset</MachineButton>
      </Col>{' '}
      <Col>
        <MachineButton>Static reading</MachineButton>
      </Col>{' '}
      <Col>
        <MachineButton>Cup release</MachineButton>
      </Col>
    </Row>
  )
}

export default Brio3KeyboardHeader
