import axios from './api';
import qs from 'qs';

export default {
	getFiles: vmID => {
		const param = {
			vmID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios.get().get(`/vendingmachine/attachedfiles${query}`);
	},

	getFileById: fileID => {
		const param = {
			fileID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios
			.getBinary()
			.get(`/vendingmachine/attachedfiles/getFileByID${query}`);
	},

	deleteFile: fileID => {
		const param = {
			fileID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios
			.get()
			.delete(`/vendingmachine/attachedfiles${query}`)
			.then()
			.catch(e => console.log(e));
	}
};
