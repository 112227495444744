//@flow
import React from 'react';
import {
	Button,
	Card,
	CardBody,
	CardGroup,
	CardImg,
	Col,
	Container,
	Input,
	InputGroup,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';
import { Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from '../../assets/images/logo.png';

import Auth from '../../services/api/auth';
import logger from '../../services/logger';
//Pörgős gomb
import 'ladda/dist/ladda-themeless.min.css';
import LaddaButton, { EXPAND_LEFT, EXPAND_RIGHT } from 'react-ladda';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { connect } from 'react-redux';
import { Record } from 'immutable';

type Props = {
	className: string,
	location: {
		state: {
			from: string
		}
	},
	history: Object,
	t: TFunction,
	user: Record | null
};

type States = {
	email: string,
	expLeft: boolean,
	modal: boolean,
	success: boolean,
	progress?: number
};

const mapStateToProps = state => ({
	user: state.user
});

class ForgotPassw extends React.Component<Props, States> {
	constructor(props: Props) {
		super(props);

		this.state = {
			email: '',
			expLeft: false,
			modal: false,
			success: false
		};
	}

	//Modal success
	toggleSuccess = () => {
		this.setState({
			success: !this.state.success
		});
	};

	//Szökséges KEY error
	needKeyError = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('error.missingFields'));
	};

	//Szökséges KEY error
	emailExist = () => {
		// add type: 'error' to options
		return toast.error(this.props.t('error.emailNotFound'));
	};

	//gomb event-je
	handleClick = async () => {
		const { email } = this.state;
		this.setState({
			expLeft: true,
			progress: 0.5
		});
		try {
			const response = await Auth.forgotPassword(email);
			if (response.data.hasOwnProperty('response')) {
				this.toggleSuccess();
			} else {
				this.setState({
					expLeft: false,
					progress: 1.0
				});

				//Rossz kulcsot adtunk meg , alert feldobása
				this.emailExist();
			}
		} catch (e) {
			logger.error(e);
			this.setState({
				expLeft: false,
				progress: 1.0
			});

			this.needKeyError();
		}
	};

	//Loginra vissza
	handleCancel = () => {
		this.props.history.replace('login');
	};

	//Email field változás
	handleEmailChange = (event: SyntheticInputEvent<any>) => {
		this.setState({ email: event.target.value });
	};

	render() {
		const { email = '' } = this.state;

		//Ha "null", tehát nincs user mentve akkor nem vagyunk bejelentkezve
		const logged = !!this.props.user;

		const { from } = this.props.location.state || {
			from: { pathname: '/app' }
		};

		//Vizsgálat hogy mehetünk e a dashboardra vagy renderelni kell a nézetet tovább
		if (logged) {
			return <Redirect to={from} />;
		}

		const { t } = this.props;

		return (
			<div className="app flex-row align-items-center">
				<Modal
					isOpen={this.state.success}
					toggle={this.toggleSuccess}
					className={'modal-success ' + this.props.className}
				>
					<ModalHeader toggle={this.toggleSuccess}>
						{t('login.forgotPassword.modal.title')}
					</ModalHeader>
					<ModalBody>{t('login.forgotPassword.modal.info')}</ModalBody>
					<ModalFooter>
						<Button color="success" onClick={this.handleCancel}>
							{t('login.continueToLoginButton')}
						</Button>
					</ModalFooter>
				</Modal>

				<Container>
					<Row className="justify-content-center">
						<Col md="5">
							<CardGroup className="mb-4">
								<Card className="p-8">
									<CardBody>
										<div className="text-left">
											<CardImg
												className="card-img-top"
												id="mainLogo"
												src={Logo}
												alt="Card image cap"
											/>
										</div>
										<h1>{t('login.forgotPassword.label')}</h1>
										<p className="text-left">
											{t('login.forgotPassword.info')}
										</p>
										<InputGroup className="mb-3">
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-at" />
												</span>
											</div>
											<Input
												type="email"
												placeholder={t('login.email')}
												value={email}
												onChange={this.handleEmailChange}
											/>
										</InputGroup>

										<Row>
											<Col xs="6">
												<LaddaButton
													id="redButton"
													className="btn btn-success btn-ladda"
													loading={this.state.expLeft}
													data-style={EXPAND_LEFT}
													onClick={this.handleClick}
												>
													{t('login.forgotPassword.button')}
												</LaddaButton>
											</Col>
											<Col xs="6" className="text-right">
												<LaddaButton
													id="forgotButton2"
													className="btn btn-success btn-ladda"
													loading={this.state.expLeft}
													data-style={EXPAND_RIGHT}
													onClick={() => this.handleCancel()}
												>
													{t('login.loginButton')}
												</LaddaButton>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</CardGroup>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default connect(mapStateToProps)(
	withRouter(withTranslation()(ForgotPassw))
);
