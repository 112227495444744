//@flow
import React from 'react'

import { Col, Row } from 'reactstrap'

import { MachineButton as BaseButton } from '../../../../components/Machines/MachineComponents'
import styled from 'styled-components'
const MachineButton = styled(BaseButton)`
  font-size: small;
`

const AzkoyenKeyboardHeader = ({
  onButtonClick = (key: string) => async () => {},
}: {
  onButtonClick?: (key: string) => () => Promise<void>,
}) => {
  return (
    <Row className="mb-3 mt-3">
      <Col>
        <MachineButton onClick={onButtonClick('101')}>
          A
        </MachineButton>
      </Col>
      <Col>
        <MachineButton onClick={onButtonClick('103')}>B</MachineButton>
      </Col>{' '}
      <Col>
        <MachineButton onClick={onButtonClick('100')}>C</MachineButton>
      </Col>{' '}
      <Col>
        <MachineButton onClick={onButtonClick('102')}>D</MachineButton>
      </Col>
    </Row>
  )
}

export default AzkoyenKeyboardHeader
