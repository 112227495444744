import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import respUtil from '../../services/util/responsibilityUtil';
import classes from './FilterBar.module.css';

const CheckBox = ({
	title,
	color,
	value,
	name,
	handleClick,
	style,
	...rest
}) => {
	return (
		<Button outline={!value} color={color} onClick={handleClick} {...rest}>
			<label className="form-check-label">{title}</label>
		</Button>
	);
};

class FilterBar extends Component {
	state = {
		currentFilterValue: ''
	};

	onFilterChanged(e) {
		this.setState({ currentFilterValue: e.target.value });

		clearTimeout(this.filterTimer);
		this.filterTimer = setTimeout(
			() => this.props.onFilterChanged(this.state.currentFilterValue),
			this.props.filterTime ? this.props.filterTime : 300
		);
	}

	filterTimer = null;
	clearFilter() {
		this.setState({ currentFilterValue: '' }, () =>
			this.props.onFilterChanged()
		);
	}

	renderFilterDeletedCustomers = filtered => {
		return (
			<Col xs={12} sm={4} md lg>
				<Button
					variant="success"
					className={'DefaultButtonXxl'}
					onClick={() => this.props.isFiltered(filtered ? false : true)}
				>
					<i
						className={`fa fa-${filtered ? 'eye' : 'eye-slash'} mr-2 `}
						aria-hidden="true"
					></i>
					&nbsp;
					{this.props.t(
						`invisibleTouchManager.purchasersTable.${
							filtered ? 'filterOn' : 'filterOff'
						}`
					)}
				</Button>
			</Col>
		);
	};

	renderAddButton() {
		return (
			<Col xs={12} sm={4} md lg>
				<Button
					variant="success"
					onClick={this.props.onShowCreateItem}
					className={'DefaultButton'}
				>
					<i className="fa fa-plus mr-2 " aria-hidden="true"></i>&nbsp;
					{this.props.t('filterToolbar.add')}
				</Button>
			</Col>
		);
	}

	renderEditButton() {
		return (
			<Col xs={12} sm={4} md lg>
				<Button
					variant="success"
					onClick={this.props.onShowEditItem}
					className={'DefaultButton'}
					disabled={
						this.props.onCheckEditButtonDisabled
							? this.props.onCheckEditButtonDisabled()
							: true
					}
				>
					<i className="fa fa-pencil mr-2" aria-hidden="true"></i>&nbsp;
					{this.props.t('filterToolbar.edit')}
				</Button>
			</Col>
		);
	}

	renderPrintButton() {
		/*return (
			<Col xs={12} sm={4} md lg>
				<Button
					variant="success"
					onClick={this.props.onShowPrintItem}
					className={'DefaultButton'}					
					disabled={
						this.props.onCheckPrintButtonDisabled
							? this.props.onCheckPrintButtonDisabled()
							: true
					}
				>
					<i className="fa fa-print mr-2" aria-hidden="true"></i>&nbsp;
					{this.props.t('filterToolbar.print')}
				</Button>
			</Col>
		);*/
	}

	renderSwitchButton(switchButton, index) {
		let swithButtonStyle =
			index == this.props.activeSwitch ? 'info' : 'success';
		return (
			<Col xs={12} sm={4} md lg className={'mr-1'}>
				<Button
					variant={swithButtonStyle}
					onClick={() => this.props.onSwitchClick(index)}
					className={'DefaultButtonMd'}
					on
				>
					{switchButton}
				</Button>
			</Col>
		);
	}
	getFilterWidth() {
		let filterWidth = 'calc(100% ';
		const buttonWidth = 7.25;
		const switchButtonWidth = 9.25;
		const deletedCustomersWith = 17.25;
		let defButtonWidth = 0;

		if (this.props.showAddButton) defButtonWidth = defButtonWidth + buttonWidth;
		if (this.props.showEditButton)
			defButtonWidth = defButtonWidth + buttonWidth;
		if (this.props.showPrintButton)
			defButtonWidth = defButtonWidth + buttonWidth;
		if (this.props.showFilterDeletedCustomersButton)
			defButtonWidth = defButtonWidth + deletedCustomersWith;
		if (this.props.switchButtons) {
			this.props.switchButtons.forEach(
				swithcButton => (defButtonWidth = defButtonWidth + switchButtonWidth)
			);
		}
		if (respUtil.breakpoints.md) defButtonWidth = 0;

		filterWidth = `calc(100% - ${defButtonWidth}rem)`;

		return filterWidth;
	}

	render() {
		const switchButtons = this.props.switchButtons
			? this.props.switchButtons.map((switchButton, index) =>
					this.renderSwitchButton(switchButton, index)
			  )
			: null;
		return (
			<Container fluid>
				<Row noGutters>
					<Col>
						<Row noGutters className="mb-1">
							{this.props.showAddButton ? this.renderAddButton() : null}

							{this.props.showEditButton ? this.renderEditButton() : null}

							{this.props.showPrintButton ? this.renderPrintButton() : null}

							{this.props.showFilterDeletedCustomersButton
								? this.renderFilterDeletedCustomers(this.props.filtered)
								: null}

							{switchButtons}
						</Row>
					</Col>
					<div
						className={classes.FilterDiv}
						style={{
							width: this.getFilterWidth()
						}}
					>
						<InputGroup className="mb-3">
							<InputGroup.Prepend>
								<InputGroup.Text
									id="basic-addon1"
									className={classes.FilterClearButton}
								>
									<i className="fa fa-search"></i>
								</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl
								placeholder={this.props.t('filterToolbar.filterPlaceholder')}
								aria-label="Filter"
								className={classes.FilterClearInput}
								aria-describedby="basic-addon1"
								value={this.state.currentFilterValue}
								onChange={e => this.onFilterChanged(e)}
							/>
							<Button
								variant="light"
								onClick={() => this.clearFilter()}
								className={classes.FilterClearButton}
							>
								<i className="fa fa-times" aria-hidden="true"></i>
							</Button>
						</InputGroup>
					</div>
				</Row>
			</Container>
		);
	}
}

export default withTranslation()(FilterBar);
