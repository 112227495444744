//@flow
import React, { Component } from 'react';
import { Card, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import Files from 'react-butterfiles';
import api from '../../../services/api/api';

const API_URL: any = process.env.REACT_APP_API_BASE_URL;
type Props = {
	t: TFunction,
	machine: Object,
	gallery: Array<any>,
	onAddImage: (files: Array<{ file: File, base64: string }>) => void,
	onRemoveImage: (file: { index?: number, id?: number }) => void
};

type States = {
	dragging?: boolean
};

const MachineImage = ({ index, handleRemoveFiles, src, editable }) => (
	<Col md="6" style={{ padding: '20px' }} key={index}>
		{src && (
			<img
				className="rounded"
				style={{ width: '100%' }}
				src={src}
				alt="Device"
			/>
		)}
		{editable ? (
			<button
				className="btn btn-danger pmd-btn-fab"
				style={{
					position: 'absolute',
					top: '20px',
					right: '20px'
				}}
				onClick={handleRemoveFiles}
			>
				<i className="fa fa-trash" />
			</button>
		) : null}
	</Col>
);
class DeviceImageUploader extends Component<Props, States> {
	state = {
		dragging: false
	};

	handleErrors = error => {
		console.error(error);
	};

	handleAddFiles = files => {
		const { onAddImage } = this.props;
		onAddImage(files.map(({ src: { file, base64 } }) => ({ file, base64 })));
	};

	handleRemoveFiles = ({
		index,
		id
	}: {
		index?: number,
		id?: number
	}) => () => {
		const { onRemoveImage } = this.props;
		onRemoveImage({
			index,
			id
		});
	};

	render() {
		const { dragging = false } = this.state;
		const { machine, t, gallery } = this.props;
		const jwtToken = api.getToken() || '';
		return (
			<Files
				multiple
				convertToBase64
				accept={['image/jpg', 'image/jpeg', 'image/png']}
				onError={this.handleErrors}
				onSuccess={files => this.props.editable && this.handleAddFiles(files)}
			>
				{({ browseFiles, getDropZoneProps }) => (
					<Card
						{...getDropZoneProps({
							className: dragging ? ' dragging' : '',
							onDragEnter: () => this.setState({ dragging: true }),
							onDragLeave: () => this.setState({ dragging: false }),
							onDrop: () => this.setState({ dragging: false })
						})}
					>
						<br />
						<Row>
							{gallery.map((image, index) => (
								<MachineImage
									key={index}
									src={image.base64}
									index={index}
									handleRemoveFiles={this.handleRemoveFiles({ index })}
									editable={this.props.editable}
								/>
							))}
							{machine &&
								machine.images &&
								machine.images.map((image, index) => (
									<MachineImage
										key={index}
										src={`${API_URL}${image.src}?token=${jwtToken}`}
										index={index}
										editable={this.props.editable}
										handleRemoveFiles={this.handleRemoveFiles({
											id: image.id
										})}
									/>
								))}
						</Row>
						{this.props.editable ? (
							<Row>
								<Col md="6" style={{ padding: '20px' }}>
									<button
										type="button"
										className="btn btn-secondary"
										onClick={() => {
											browseFiles({
												onErrors: this.handleErrors,
												onSuccess: files => {
													this.handleAddFiles(files);
												}
											});
										}}
									>
										{t('device.details.attach')}
									</button>
								</Col>
							</Row>
						) : null}
						<br />
					</Card>
				)}
			</Files>
		);
	}
}

export default withTranslation()(DeviceImageUploader);
