//@flow
import React from 'react'

import { Row } from 'reactstrap'

import {
  MachineButton,
  RedMachineButton,
} from '../../../../../components/Machines/MachineComponents'
import AntaresTrollKeyboardLine from './AntaresTrollKeyboardLine'
import AntaresTrollSimpleKeyboardLine from './AntaresTrollSimpleKeyboardLine'

const keyboardConfig = [
  [
    {
      hasLabel: true,
      buttonID: '31',
      buttonContent: '31',
    },
    {
      ButtonComponent: RedMachineButton,
      buttonID: '1',
      buttonContent: (
        <>
          1 <br /> +
        </>
      ),
    },
    {
      hasLabel: true,
      buttonID: '32',
      buttonContent: '32',
    },
    {
      buttonID: '2',
      buttonContent: '2',
    },
  ],
  [
    {
      hasLabel: true,
      buttonID: '33',
      buttonContent: '33',
    },
    {
      ButtonComponent: RedMachineButton,
      buttonID: '3',
      buttonContent: (
        <>
          3 <br /> -
        </>
      ),
    },
    {
      hasLabel: true,
      buttonID: '34',
      buttonContent: '34',
    },
    {
      buttonID: '4',
      buttonContent: '4',
    },
  ],
  [
    {
      hasLabel: true,
      buttonID: '35',
      buttonContent: '35',
    },
    {
      ButtonComponent: RedMachineButton,
      buttonID: '5',
      buttonContent: (
        <>
          5 <br /> NUMBER
        </>
      ),
    },
    {
      hasLabel: true,
      buttonID: '36',
      buttonContent: '36',
    },
    {
      buttonID: '6',
      buttonContent: '6',
    },
  ],
  [
    {
      hasLabel: true,
      buttonID: '51',
      buttonContent: '51',
    },
    {
      ButtonComponent: RedMachineButton,
      buttonID: '21',
      buttonContent: (
        <>
          21 <br /> ENTER
        </>
      ),
    },
    {
      hasLabel: true,
      buttonID: '52',
      buttonContent: '52',
    },
    {
      buttonID: '22',
      buttonContent: '22',
    },
  ],

  [
    {
      hasLabel: true,
      buttonID: '53',
      buttonContent: '53',
    },
    {
      buttonID: '23',
      buttonContent: '23',
    },
    {
      hasLabel: true,
      buttonID: '54',
      buttonContent: '54',
    },
    {
      buttonID: '24',
      buttonContent: '24',
    },
  ],
  [
    {
      hasLabel: true,
      buttonID: '55',
      buttonContent: '55',
    },
    {
      buttonID: '25',
      buttonContent: '25',
    },
    {
      hasLabel: true,
      buttonID: '56',
      buttonContent: '56',
    },
    {
      buttonID: '26',
      buttonContent: '26',
    },
  ],
  [
    {
      hasLabel: true,
      buttonID: '7',
      hideButton: true,
    },
    {
      buttonID: '7',
      buttonContent: '7',
    },
    {
      hasLabel: true,
      hideButton: true,
      buttonID: '8',
      buttonContent: '8',
    },
    {
      buttonID: '8',
      buttonContent: '8',
    },
  ],
  [
    {
      hasLabel: true,
      buttonID: '9',
      hideButton: true,
    },
    {
      buttonID: '9',
      buttonContent: '9',
    },
    {
      hasLabel: true,
      hideButton: true,
      buttonID: '10',
      buttonContent: '10',
    },
    {
      buttonID: '10',
      buttonContent: '10',
    },
  ],
  [
    {
      hasLabel: true,
      buttonID: '11',
      hideButton: true,
    },
    {
      buttonID: '11',
      buttonContent: '11',
    },
    {
      hasLabel: true,
      hideButton: true,
      buttonID: '12',
    },
    {
      buttonID: '12',
      buttonContent: '12',
    },
  ],
  [
    {
      hasLabel: true,
      buttonID: '13',
      hideButton: true,
    },
    {
      buttonID: '13',
      buttonContent: '13',
    },
    {
      hasLabel: true,
      hideButton: true,
      buttonID: '14',
    },
    {
      buttonID: '14',
      buttonContent: '14',
    },
  ],
  [
    {
      hasLabel: true,
      buttonID: '15',
      hideButton: true,
    },
    {
      buttonID: '15',
      buttonContent: '15',
    },
    {
      hasLabel: true,
      hideButton: true,
      buttonID: '16',
    },
    {
      buttonID: '16',
      buttonContent: '16',
    },
  ],
]

type AntaresTrollKeyboardProps = {
  onButtonClick?: (key: string) => () => Promise<void>,
  products: Array<Object>,
}

const AntaresTrollKeyboard = ({
  onButtonClick = (key: string) => async () => {},
  products = [],
}: AntaresTrollKeyboardProps) => {
  return (
    <>
      <AntaresTrollSimpleKeyboardLine
        onButtonClick={onButtonClick}
        left={{ buttonID: '60', buttonContent: 'A' }}
        right={{ buttonID: '62', buttonContent: 'C' }}
      />

      <AntaresTrollSimpleKeyboardLine
        onButtonClick={onButtonClick}
        left={{ buttonID: '61', buttonContent: 'B' }}
        right={{
          buttonID: '63',
          buttonContent: (
            <>
              D <br /> ESC
            </>
          ),
        }}
      />

      {keyboardConfig.map(line => (
        <Row>
          {line.map((button, idx) => {
            const ButtonComponent = button.ButtonComponent
              ? button.ButtonComponent
              : MachineButton
            return (
              <AntaresTrollKeyboardLine
                button={button}
                products={products}
                onButtonClick={onButtonClick}
                ButtonComponent={ButtonComponent}
              />
            )
          })}
        </Row>
      ))}
    </>
  )
}

export default AntaresTrollKeyboard
