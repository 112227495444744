import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import Modal from 'react-bootstrap/Modal';
import classes from './ProductInfoDialog.module.css';
import LayoutUtil from '../../../../services/util/layoutUtil';
import { useState } from 'react';
import { findLastIndex } from 'lodash';

const ProductInfoDialog = props => {
	const { flags, t, VMItem } = props;
	const [maxCount, setMaxCount] = useState(0);
	const [currentCount, setCurrentCount] = useState(0);
	const [notifyThreshold, setNotifyThreshold] = useState(0);
	const [editing, setEditing] = useState(false);

	const enterIntoEditMode = () => {
		setMaxCount(props.VMItem.maxCount);
		setCurrentCount(props.VMItem.currentCount);
		setNotifyThreshold(props.VMItem.notifyThreshold);
		setEditing(true);
	};

	const renderNutriAtributes = attributes => {
		let attribute = attributes.map(e => {
			return (
				<Col lg="5" className={classes.AttrCont}>
					<Row
						style={{
							fontWeight: '700',
							fontSize: 'smaller'
						}}
					>
						{e && e.name ? (
							<Col>
								{' '}
								{e.name}
								{':'}
							</Col>
						) : null}
					</Row>
					<Col>
						<Row style={{ justifyContent: 'center' }}>
							{e && e.value ? e.value : ''} {e && e.unit ? e.unit : ''}
						</Row>
					</Col>
				</Col>
			);
		});

		return attribute;
	};

	const renderNutritionInfo = product => {
		if (product && product.attributes && product.attributes.length > 0) {
			const energy = product.attributes[0];
			const atributes = product.attributes.slice(1, product.attributes.length);

			return (
				<Col xs="12" sm="12" lg="4" className={classes.NutInf}>
					<Row className={classes.NutInfHeader}>
						<Col lg="10">
							{t('layoutManager.productInfoCard.nutritionInfo')}
						</Col>
					</Row>
					<Row>
						<Col lg="10" className={classes.EnergyeCont}>
							<Col style={{ fontWeight: 'bold' }}>
								{energy && energy.name ? energy.name : ''}
							</Col>
							<Col>
								<Row style={{ justifyContent: 'center' }}>
									{energy && energy.value ? energy.value : ''}{' '}
									{energy && energy.unit ? energy.unit : ''}
								</Row>
							</Col>
						</Col>
					</Row>
					{atributes && atributes.length > 0 ? (
						<Row>{renderNutriAtributes(atributes)}</Row>
					) : null}
				</Col>
			);
		}
	};
	const renderAllegens = VMItem => {
		if (VMItem && VMItem.details && VMItem.details.alergyInfos) {
			let alergenList = VMItem.details.alergyInfos.map(e => {
				const alergen = LayoutUtil.alergens.find(
					a => a.name.toLocaleUpperCase() == e.name.toLocaleUpperCase()
				);
				return (
					<Row className={classes.AlergeneRow}>
						<Col xs="6" sm="6">
							<Row>
								<Col xs="3" sm="3" lg="3">
									<Row style={{ justifyContent: 'center' }}>
										{alergen && alergen.badge ? (
											<img src={alergen.badge} width="50" height="50"></img>
										) : null}
									</Row>
								</Col>
								<Col xs="7" sm="7" lg="7">
									<Col className={classes.AlergenTitle}>
										{t('layoutManager.productInfoCard.contains') + ':'}
									</Col>
									<Col className={classes.AlergenName}>
										{e.localisedName
											? e.localisedName
											: t('promotionManager.promotionTable.noData')}
									</Col>
								</Col>
							</Row>
						</Col>
					</Row>
				);
			});

			return (
				<Col xs="12" sm="12" lg="4" className={classes.TextBox}>
					{' '}
					<Col className={classes.TextTitle}>
						{t('layoutManager.productInfoCard.alergens')}
						{` (${VMItem.details.alergyInfos.length}) `}
						{':'}
					</Col>
					<Col className={classes.TextBody}>{alergenList}</Col>{' '}
				</Col>
			);
		}
	};

	const renderInformations = product => {
		if (product && product.informationList) {
			let informations = product.informationList.map(e => {
				let topic =
					e && e.topic ? e.topic : t('promotionManager.promotionTable.noData');
				let description =
					e && e.description
						? e.description
						: t('promotionManager.promotionTable.noData');
				return (
					<>
						<Col xs="12" sm="12" lg="4" className={classes.TextBox}>
							<Col className={classes.TextTitle}>
								{topic}
								{':'}
							</Col>
							<Col className={classes.TextBody}>{description}</Col>
						</Col>
					</>
				);
			});
			return informations;
		}
	};

	const renderTags = product => {
		if (product && product.tags) {
			return (
				<>
					<Col xs="12" sm="12" lg="4" className={classes.TextBox}>
						<Col className={classes.TextTitle}>
							{t('layoutManager.productInfoCard.tags')}:
						</Col>
						<Col className={classes.TextBody}>{product.tags}</Col>
					</Col>
				</>
			);
		}
	};

	const servingSizeFormater = product => {
		const serviceSize =
			product && product.servingSize ? product.servingSize : '';
		const servingSizeUnit =
			product && product.servingSizeUnit ? product.servingSizeUnit : '';
		return (
			<Col className={classes.ServingSize}>
				{serviceSize + ' ' + servingSizeUnit}
			</Col>
		);
	};

	const priceFormater = () => {
		const price = props.price;
		const unit = props.currencySymbol;

		return <Col className={classes.Price}>{price + ' ' + unit}</Col>;
	};

	const maxAmountFormatter = () => {
		if (editing) {
			return (
				<input
					style={{ width: '100%' }}
					type="number"
					value={maxCount}
					onChange={e => setMaxCount(parseInt(e.target.value))}
				></input>
			);
		} else {
			const maxCount = props.VMItem.maxCount;
			const stringValue = maxCount || maxCount == 0 ? maxCount : '-';
			return <Col className={classes.Price}>{stringValue}</Col>;
		}
	};

	const currentCountFormatter = () => {
		if (editing) {
			return (
				<input
					style={{ width: '100%' }}
					type="number"
					value={currentCount}
					onChange={e =>
						e.target.value <= maxCount && e.target.value >= 0
							? setCurrentCount(e.target.value)
							: setCurrentCount(currentCount ? currentCount : 0)
					}
				></input>
			);
		} else {
			const currentCount = props.VMItem.currentCount;
			const stringValue =
				currentCount || currentCount == 0 ? currentCount : '-';
			return <Col className={classes.Price}>{stringValue}</Col>;
		}
	};

	const notificationThresholdFormatter = () => {
		if (editing) {
			return (
				<input
					style={{ width: '100%' }}
					type="number"
					value={notifyThreshold}
					onChange={e =>
						e.target.value < maxCount && e.target.value >= 0
							? setNotifyThreshold(e.target.value)
							: setNotifyThreshold(notifyThreshold ? notifyThreshold : 0)
					}
				></input>
			);
		} else {
			const notifyThreshold = props.VMItem.notifyThreshold;
			const stringValue =
				notifyThreshold || notifyThreshold == 0 ? notifyThreshold : '-';
			return <Col className={classes.Price}>{stringValue}</Col>;
		}
	};

	const renderAmountEditingButton = () => {
		if (!editing) {
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					<LaddaButton
						className={
							'btn btn-success btn-ladda DefaultButton' + classes.ButtonMargins
						}
						style={{
							width: '10rem'
						}}
						onClick={() => enterIntoEditMode()}
					>
						<i className="fa fa-pencil mr-2" aria-hidden="true"></i>
						&nbsp;
						{props.t('layoutManager.productInfoCard.edit')}
					</LaddaButton>
					<LaddaButton
						className={
							'btn btn-success btn-ladda DefaultButton ' + classes.ButtonMargins
						}
						style={{
							width: '10rem'
						}}
						onClick={() => {
							if (props.VMItem.maxCount && props.VMItem.maxCount > 0) {
								props.onCountChanges(
									props.VMItem.maxCount,
									props.VMItem.maxCount,
									props.VMItem.notifyThreshold
								);
							}
						}}
						disabled={
							!(
								props.VMItem.maxCount &&
								props.VMItem.maxCount > 0 &&
								props.VMItem.maxCount != props.VMItem.currentCount
							)
						}
					>
						<i className="fa fa-arrow-up mr-2" aria-hidden="true"></i>
						&nbsp;
						{props.t('layoutManager.productInfoCard.topUp')}
					</LaddaButton>
				</div>
			);
		} else {
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					<LaddaButton
						className={
							'btn btn-success btn-ladda DefaultButton ' + classes.ButtonMargins
						}
						style={{
							width: '10rem'
						}}
						onClick={() => {
							props.onCountChanges(maxCount, currentCount, notifyThreshold);
							setEditing(false);
						}}
					>
						<i className="fa fa-floppy-o mr-2" aria-hidden="true"></i>
						&nbsp;
						{props.t('layoutManager.productInfoCard.save')}
					</LaddaButton>
					<LaddaButton
						className={
							'btn btn-ladda DefaultButton btn-primary ' + classes.ButtonMargins
						}
						style={{
							width: '10rem'
						}}
						onClick={() => setEditing(false)}
					>
						<i className="fa fa-times mr-2" aria-hidden="true"></i>
						&nbsp;
						{props.t('layoutManager.productInfoCard.cancel')}
					</LaddaButton>
				</div>
			);
		}
	};

	const renderInformationModal = () => {
		const flag = flags.find(
			f => (props.location && f.language === props.location) || !props.location
		);

		return (
			<Modal show={props.show} onHide={() => props.onHide()} size={'lg'}>
				<Modal.Header closeButton>
					<Modal.Title>
						<Row>
							<Col>{props.name}</Col>{' '}
							{flag && flag.flag ? (
								<img
									className={'ml-5 '}
									src={flag.flag}
									width="40"
									height="40"
								/>
							) : null}
						</Row>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row style={{ justifyContent: 'center', margin: '0px' }}>
						{renderNutritionInfo(VMItem.details)}
						<Col xs="12" sm="12" lg="4">
							<Row style={{ justifyContent: 'center' }}>{props.image()}</Row>
						</Col>

						<Col xs="12" sm="12" lg="3" className={classes.TextBox}>
							<Col className={classes.TextBox}>
								<Col className={classes.TextHeader}>
									{t('layoutManager.productInfoCard.servingSize')}
								</Col>
								{servingSizeFormater(VMItem.details)}
							</Col>
							<Col className={classes.TextBox}>
								<Col className={classes.TextHeader}>
									{t('layoutManager.productInfoCard.price')}
								</Col>
								{priceFormater()}
							</Col>

							<Col className={classes.TextBox}></Col>
						</Col>
					</Row>
					<hr></hr>
					<Row style={{ justifyContent: 'center' }}>
						<Col className={classes.TextBox} xs={12} sm={6} md={4} lg={4}>
							<Col className={classes.TextHeader}>
								{t('layoutManager.productInfoCard.maxAmount')}
							</Col>
							{maxAmountFormatter()}
						</Col>
						<Col className={classes.TextBox} xs={12} sm={6} md={4} lg={4}>
							<Col className={classes.TextHeader}>
								{t('layoutManager.productInfoCard.currentAmount')}
							</Col>
							{currentCountFormatter()}
						</Col>
						<Col className={classes.TextBox} xs={12} sm={6} md={4} lg={4}>
							<Col className={classes.TextHeader}>
								{t('layoutManager.productInfoCard.notifiyThreshold')}
							</Col>
							{notificationThresholdFormatter()}
						</Col>
						<Col lg={12} style={{ justifyContent: 'center' }}>
							{renderAmountEditingButton()}
						</Col>
					</Row>

					<hr></hr>
					<Row>
						{renderInformations(VMItem.details)}
						{renderAllegens(VMItem.details)}
						{renderTags(VMItem.details)}
					</Row>
				</Modal.Body>
			</Modal>
		);
	};
	return renderInformationModal();
};

export default withTranslation()(ProductInfoDialog);
