const IVT = {
	SET_LOADED_CUSTOMER: 'SET_LOADED_CUSTOMER',
	SET_PAGE_KEY: 'SET_PAGE_KEY'
};

const local = {
	IVT_STATE: 'IVT_STATE'
};

export const page = {
	purchases: 1,
	balances: 2,
	invoices: 3,
	messages: 4
};

let state = {
	currentCustomer: {
		email: '',
		firstName: '',
		lastName: '',
		userID: null,
		walletID: '',
		walletName: '',
		currency: ''
	},
	pageKey: 0
};

export const setLoadedCustomer = loadedCustomer => {
	return {
		type: IVT.SET_LOADED_CUSTOMER,
		loadedCustomer
	};
};

export const setPageKey = pageKey => {
	return {
		type: IVT.SET_PAGE_KEY,
		pageKey
	};
};

const getInitialState = () => {
	const IVTStateJSON = localStorage.getItem(local.IVT_STATE);
	let IVTState = IVTStateJSON ? JSON.parse(IVTStateJSON) : state;
	return IVTState;
};

const IVTState = (state = getInitialState(), action) => {
	switch (action.type) {
		case IVT.SET_LOADED_CUSTOMER:
			let newCustomer = {
				email: action.loadedCustomer.email,
				firstName: action.loadedCustomer.firstName,
				lastName: action.loadedCustomer.lastName,
				userID: action.loadedCustomer.userID,
				walletID: action.loadedCustomer.walletID,
				walletName: action.loadedCustomer.walletName,
				currency: action.loadedCustomer.currency
			};
			state.currentCustomer = newCustomer;
			localStorage.setItem(local.IVT_STATE, JSON.stringify(state));
			return state;
		case IVT.SET_PAGE_KEY:
			state.pageKey = action.pageKey;
			localStorage.setItem(local.IVT_STATE, JSON.stringify(state));
			return action.pageKey;

		default:
			return state;
	}
};

export default IVTState;
