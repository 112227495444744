//@flow
import axios, {
	Axios,
	AxiosXHRConfigBase,
	AxiosRequestTransformer
} from 'axios';
import merge from 'lodash.merge';
import logger from '../logger';

const API_URL = process.env.REACT_APP_API_BASE_URL;

const apiLogger = logger.child({ category: 'api' });

type PublicInterface = {
	get: () => Axios,
	getBinary: () => Axios,
	setToken: string => void,
	getToken: () => string | null,
	setLang: string => void,
	setUnauthorizedCallback: Function => void,
	getConfig: Function => Object
};

const dateTransformer: AxiosRequestTransformer = data => {
	if (data instanceof Date) {
		// do your specific formatting here
		const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		const localISOTime = new Date(data - tzoffset).toISOString().slice(0, -1);

		return localISOTime;
	}
	if (Array.isArray(data)) {
		return data.map(val => dateTransformer(val));
	}
	if (typeof data === 'object' && data !== null) {
		return Object.fromEntries(
			Object.entries(data).map(([key, val]) => [key, dateTransformer(val)])
		);
	}
	return data;
};

export default (function axiosInstanceModul(): PublicInterface {
	var axiosInstance: ?Axios = null;
	var axiosConfig: AxiosXHRConfigBase<*> = {
		// https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
		baseURL: API_URL,
		headers: {
			key: process.env.REACT_APP_API_KEY
		},
		transformRequest: [dateTransformer].concat(axios.defaults.transformRequest)
	};
	var unauthorizedCallback: ?(any) => void = null;
	var token: string | null = null;
	var lang: ?string = null;

	const getToken = (): string | null => token;

	function setToken(tkn: string): void {
		if (tkn !== token) {
			token = tkn;
			mergeConfig({
				headers: {
					key: process.env.REACT_APP_API_KEY,
					token: token
				}
			});
			axiosInstance = createInstance();
		}
	}

	function setLang(lng: string) {
		if (lng !== lang) {
			lang = lng;
			mergeConfig({
				headers: {
					'Accept-Language': lng
				}
			});
			axiosInstance = createInstance();
		}
	}

	function getBinary(): Axios {
		axiosConfig = merge({}, axiosConfig, {
			responseType: 'arraybuffer'
		});
		axiosInstance = createInstance();
		return axiosInstance;
	}

	function get(): Axios {
		if (axiosInstance) {
			return axiosInstance;
		}
		axiosInstance = createInstance();
		return axiosInstance;
	}

	function mergeConfig(configUpdate: AxiosXHRConfigBase<*>): void {
		axiosConfig = merge({}, axiosConfig, configUpdate);
		axiosInstance = createInstance();
	}

	function createInstance() {
		const instance = axios.create(axiosConfig);

		instance.interceptors.request.use(request => {
			apiLogger.debug(
				'Starting Request: ' +
					request.method.toUpperCase() +
					' ' +
					request.baseURL +
					request.url
			);
			return request;
		});

		instance.interceptors.response.use(response => {
			apiLogger.debug(
				'Response: ' +
					response.status +
					' ' +
					response.config.method.toUpperCase() +
					' ' +
					response.config.url
			);
			return response;
		});

		instance.interceptors.response.use(undefined, function(error: any) {
			if (error.response && error.response.status === 401) {
				if (unauthorizedCallback) {
					unauthorizedCallback(error);
				}
			}
			return Promise.reject(error);
		});
		return instance;
	}

	function getConfig() {
		return { ...axiosConfig };
	}

	function setUnauthorizedCallback(callback) {
		unauthorizedCallback = callback;
	}

	return {
		get,
		getConfig,
		getToken,
		setToken,
		setLang,
		setUnauthorizedCallback,
		getBinary
	};
})();
