import React, { Component, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import * as d3 from 'd3';
import proxy from '../PromotionManager/proxy';
import './IncomeChart.css';
import { duration } from 'moment';

const IncomeLineChart = props => {
	const getWidth = () => {
		return d3Chart.current.parentElement.offsetWidth;
	};
	const getHeight = () => {
		return d3Chart.current.parentElement.offsetHeight;
	};

	useEffect(() => {
		const { t } = props;
		const margin = { top: 80, right: 60, bottom: 150, left: 60 };
		const width = getWidth() - margin.left - margin.right;
		const height = getHeight() - margin.top - margin.bottom;

		const colors = {
			purchaseCashIncome: ['var(--purchases-dark)', 'var(--purchases-dark)'],
			purchaseCashlessIncome: ['var(--purchases)', 'var(--purchases-dark)'],
			topUpCashIncome: ['var(--balance-dark)', 'var(--balance-dark)'],
			topUpCashlessIncome: ['var(--balance)', 'var(--balance-dark)'],
			purchaseCashPrice: ['var(--purchases-dark)', 'var(--purchases-dark)'],
			purchaseCashlessPrice: ['var(--purchases)', 'var(--purchases-dark)'],
			walletPrice: ['var(--message)', 'var(--message-dark)']
		};
		const titles = {
			purchaseCashIncome: t('cashFlow.incomeAndSalesChart.titles.purchaseCash'),
			purchaseCashlessIncome: t(
				'cashFlow.incomeAndSalesChart.titles.purchaseCashless'
			),
			topUpCashIncome: t('cashFlow.incomeAndSalesChart.titles.topUpCash'),
			topUpCashlessIncome: t(
				'cashFlow.incomeAndSalesChart.titles.topUpCashless'
			),
			purchaseCashPrice: t('cashFlow.incomeAndSalesChart.titles.purchaseCash'),
			purchaseCashlessPrice: t(
				'cashFlow.incomeAndSalesChart.titles.purchaseCashless'
			),
			walletPrice: t('cashFlow.incomeAndSalesChart.titles.purchaseWallet')
		};

		const data2 = props.finalData;
		const keys2 = [
			'purchaseCashIncome',
			'purchaseCashlessIncome',
			'topUpCashIncome',
			'topUpCashlessIncome'
		];
		const keys3 = ['purchaseCashPrice', 'purchaseCashlessPrice', 'walletPrice'];
		const keys = ['value2', 'value3', 'value4', 'value5'];
		const stackGenerator = d3.stack().keys(props.income ? keys2 : keys3);
		const layers = stackGenerator(data2).map(
			d => (d.forEach(v => (v.key = d.key)), d)
		);
		const extent = [
			0,
			d3.max(layers, layer => d3.max(layer, sequence => sequence[1]))
		];

		const incomeValue = d => {
			return (
				d.purchaseCashIncome +
				d.purchaseCashlessIncome +
				d.topUpCashIncome +
				d.topUpCashlessIncome
			).toFixed(2);
		};
		const salesValue = d => {
			return (
				d.purchaseCashPrice +
				d.purchaseCashlessPrice +
				d.walletPrice
			).toFixed(2);
		};

		const x = d3
			.scaleBand()

			.domain(data2.map(d => d.createdAt))
			.range([0, width])
			.padding(0.25);
		const max = d3.max(data2, d =>
			props.income ? incomeValue(d) : salesValue(d)
		);

		const x2 = d3
			.scaleTime()
			.domain(d3.extent(data2, d => d.createdAt))
			.range(0, width);
		const y = d3
			.scaleLinear()

			.domain(extent)
			.range([height, 0]);
		const y2 = d3
			.scaleLinear()
			.domain(
				d3.extent(data2, d => (props.income ? incomeValue(d) : salesValue(d)))
			)
			.rangeRound([height, 0]);
		const line = d3
			.line()
			.curve(d3.curveCatmullRom)
			.x(d => x(d.createdAt) + x.bandwidth() / 2)
			.y(d => y((props.income ? incomeValue(d) : salesValue(d)) / 2));
		const length = path =>
			d3
				.create('svg:path')
				.attr('d', path)
				.node()
				.getTotalLength();
		const l = length(line(data2));

		let active_link = '0'; //to control legend selections and hover
		let legendClicked; //to control legend selections
		let legendClassArray = []; //store legend classes to select bars in plotSingle()

		// totel revenue Tooltip
		const tooldiv = d3
			.select('#chart')
			.append('div')
			.style('opacity', 0)
			.style('z-index', 10)
			.attr('class', 'totalTooltip');
		// totel revenue Tooltip
		const lbelTooltip = d3
			.select('#chart')
			.append('div')
			.style('opacity', 0)
			.attr('class', 'tooltip');

		const svg = d3
			.select(d3Chart.current)
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.bottom + margin.top);
		//.style('background-color', 'ghostwhite')
		svg.selectAll('*').remove();
		const group = svg
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		// x Axis

		group
			.append('g')
			.attr('transform', 'translate(0,' + height + ')')
			.call(
				d3
					.axisBottom(x)
					.ticks(width / 50)
					.tickSizeOuter(0)
					.tickFormat(d => d)
					.tickPadding([10])
			)
			.call(g => g.selectAll('.tick line').attr('stroke', 'none'))
			.call(g => g.selectAll('.tick').attr('class', 'tick'))
			.call(g => g.selectAll('.domain').attr('stroke', 'var(--invoice-mid)'))
			.selectAll('text')
			.style('text-anchor', data2.length <= 6 && width < 300 ? '' : 'end')
			.style('text-anchor', data2.length > 6 && width < 300 ? 'end' : '')
			.style(
				'text-anchor',
				width > 300
					? data2.length > 12
						? 'end'
						: ''
					: data2.length > 6
					? 'end'
					: ''
			)
			.attr('transform', data2.length <= 6 && width < 300 ? '' : 'rotate(-35)')
			.attr(
				'transform',
				width > 300
					? data2.length > 12
						? 'rotate(-35)'
						: ''
					: data2.length > 6
					? 'rotate(-80)'
					: ''
			);

		// Y Axis

		group
			.append('g')
			.call(d3.axisLeft(y).tickFormat(d => d + ' ' + props.currency))

			.call(g =>
				g
					.selectAll('.tick line')
					.attr('stroke', 'none')
					.clone()
					.attr('class', 'tickLine')
					.attr('stroke-opacity', d => (d === 1 ? null : 0.5))
					.attr('x2', width)
			)
			.call(g => g.selectAll('.tick').attr('class', 'tick'))
			.call(g => g.selectAll('.domain').attr('stroke', 'none'));
		// bar
		let bar = group
			.selectAll('.layer')
			.data(layers)
			.join('g')
			.attr('stroke', 'none')
			.attr('fill', 'none');
		// .attr('class', 'layer' )
		// .attr('class', layer => layer.key)
		//.attr('fill', layer => colors[layer.key][0]);
		// .attr('opacity', 0.5);
		let bar_enter = bar.selectAll('rect').data(layer => layer);
		let label = bar_enter
			.join('rect')
			.attr('x', sequence => x(sequence.data.createdAt))
			.attr('width', x.bandwidth())
			.attr('y', sequence => y(sequence[1]))
			.attr('height', sequence => y(sequence[0]) - y(sequence[1]))
			.on('mouseover', (e, d) => {
				lbelTooltip
					.transition()
					.duration(200)
					.style('opacity', 0.9)
					.text((d[1] - d[0]).toFixed(2) + ' ' + props.currency);
				//.attr('fill', d => colors.d[0]);
			})
			.on('mousemove', (e, d) => {
				lbelTooltip
					.style('left', e.pageX - 300 + 'px')
					.style('top', e.pageY - 260 + 'px');
			})
			.on('mouseout', (event, d) => {
				lbelTooltip
					.transition()
					.duration(500)
					.style('opacity', 0);
			})
			.transition()
			.delay((d, i) => i * 50)
			.duration(800)
			.attr('height', sequence => y(sequence[0]) - y(sequence[1]))
			.attr('opacity', 0.5)
			.attr('stroke', 'var(--invoice-dark)')
			.attr('fill', sequence => colors[sequence.key][0]);

		// Trend curve
		let trendCurve = group
			.append('path')
			.datum(data2)
			.attr('fill', 'none')
			.attr('stroke', 'var(--invoice)')
			.attr('stroke-width', 3)
			.attr('opacity', 0.3)
			//.attr('visibility', 'visible')
			.attr('stroke-dasharray', `0,${l}`)
			.attr('d', line)
			.transition()
			.delay((d, i) => i * 50)
			.duration(1500)
			.ease(d3.easeLinear)
			.attr('stroke-dasharray', `${l},${l}`);
		// Total revenue dots
		let totalRevenue = group
			.append('g')
			.selectAll('dot')
			.data(data2)
			.enter()
			.append('circle')

			.attr('class', 'circle')
			.attr('cx', d => x(d.createdAt) + x.bandwidth() / 2)
			.attr('cy', d => y((props.income ? incomeValue(d) : salesValue(d)) / 2))
			.attr('r', 5)
			.on('mouseover', (e, d) => {
				tooldiv
					.transition()
					.duration(200)
					.style('opacity', 0.9)
					.text(
						props.income
							? t('cashFlow.incomeAndSalesChart.total.income') +
									' : ' +
									incomeValue(d) +
									' ' +
									props.currency
							: t('cashFlow.incomeAndSalesChart.total.sales') +
									' : ' +
									salesValue(d) +
									' ' +
									props.currency
					);
			})
			.on('mousemove', (e, d) => {
				tooldiv
					.style('left', e.pageX - 300 + 'px')
					.style('top', e.pageY - 260 + 'px');
			})
			.on('mouseout', (event, d) => {
				tooldiv
					.transition()
					.duration(500)
					.style('opacity', 0);
			})
			.style('opacity', 0)
			.transition()
			.delay((d, i) => i * 50)
			.duration(200)
			.style('opacity', 1);

		// Title text
		group
			.append('text')
			.attr('x', width / 2)
			.attr('y', margin.top / 2 - 80)
			.attr('text-anchor', 'middle')
			.attr('class', 'titleText')
			.text(props.title);
		// Legend
		const lw = 30;
		let legend = svg.append('g');
		// rect legends
		let rectLegend = legend
			.selectAll('.legend')
			.data(layers)
			.join('rect')
			.attr('visibility', width < 290 ? 'hidden' : 'vissible')
			.attr('class', 'rectlegend')
			.attr('transform', 'translate(0,' + height + ')')
			.attr('fill', layer => colors[layer.key][0])
			.attr('x', (d, i) =>
				width > 600
					? width < 770
						? width / 2 - lw * 2.5 + i * (lw * 4.5)
						: width / 2 - lw * 4 + i * (lw * 6)
					: width / 2 - lw * 2 + i * (lw * 2)
			)
			.attr('y', margin.top * 2)
			.attr('width', lw)
			.on('click', (e, d) => {
				label
					.select('rect')
					.transition()
					.duration(0)
					.attr('opacity', 0);
			});

		let rectLegendText = legend
			.selectAll('.label')
			.data(layers)
			.join('text')
			.attr('visibility', width < 600 ? 'hidden' : 'vissible')
			.attr('transform', 'translate(0,' + height + ')')
			.attr('fill', 'var(--invoice-dark)')
			.style('font-size', 'smaller')
			.attr('x', (d, i) =>
				width < 770
					? width / 2 - lw * 3.5 + i * (lw * 4.5)
					: width / 2 - lw * 2.5 + i * (lw * 6)
			)
			.attr('y', width < 770 ? margin.top * 2.3 + 13 : margin.top * 2 + 13)
			.text(layer => titles[layer.key]);
		//line legend

		let lineLegend_active_link = '0';
		let lineLegend = legend

			.selectAll('.legend')
			.data([0])
			.join('rect')
			.attr('visibility', width < 290 ? 'hidden' : 'vissible')
			.attr('transform', 'translate(0,' + height + ')')
			.attr('x', (d, i) =>
				width > 600 ? width / 5 + lw / 2 : width / 2 + lw * 2
			)
			.attr('y', width > 600 ? margin.top * 2 + 5 : margin.top * 2.3 + 5)
			.attr('width', lw * 2)
			.attr('height', 5)
			.attr('fill', 'var(--invoice)')
			.attr('class', 'lineLegend')
			.on('click', (e, d) => {
				if (lineLegend_active_link === '0') {
					group
						.selectAll('path')
						.transition()
						.duration(0)
						.style('opacity', 0);
					lineLegend_active_link = '1';
				} else {
					group
						.selectAll('path')
						.transition()
						.duration(0)
						.style('opacity', 0.3);
					lineLegend_active_link = '0';
				}
			});

		let lineLabelText = legend
			.selectAll('.label')
			.data([0])
			.join('text')
			.attr('visibility', width < 600 ? 'hidden' : 'vissible')
			.attr('transform', 'translate(0,' + height + ')')
			.attr('fill', 'var(--invoice-dark)')
			.attr('x', (d, i) => (width > 770 ? width / 4 + lw : width / 4 - lw))
			.attr('y', width < 770 ? margin.top * 2.3 + 10 : margin.top * 2 + 13)
			.style('font-size', 'smaller')
			.text(t('cashFlow.incomeAndSalesChart.trendCurve'));
		//dot legend
		let dotLegend_active_link = '0';
		let dotLegend = legend
			.selectAll('.legend')
			.data([0])
			.join('circle')
			.attr('visibility', width < 290 ? 'hidden' : 'vissible')
			.attr('class', 'circleLegend')
			.attr('cx', (d, i) => (width > 600 ? width / 9 : width / 2))
			.attr('cy', width > 600 ? margin.top * 2 + 7 : margin.top * 2.3 + 7)
			.attr('r', 5)
			.attr('transform', 'translate(0,' + height + ')')
			.on('click', (e, d) => {
				if (dotLegend_active_link === '0') {
					group
						.selectAll('circle')
						.transition()
						.duration(0)
						.style('opacity', 0);
					dotLegend_active_link = '1';
				} else {
					group
						.selectAll('circle')
						.transition()
						.duration(0)
						.style('opacity', 1);
					dotLegend_active_link = '0';
				}
			});

		let dotLabelText = legend
			.selectAll('.label')
			.data([0])
			.join('text')
			.attr('visibility', width < 600 ? 'hidden' : 'vissible')
			.attr('transform', 'translate(0,' + height + ')')
			.attr('fill', 'var(--invoice-dark)')
			.attr('x', (d, i) => (width > 770 ? width / 9 + lw / 2 : width / 9 - lw))
			.attr('y', width < 770 ? margin.top * 2.3 + 10 : margin.top * 2 + 13)
			.style('font-size', 'smaller')
			.text(
				props.income
					? t('cashFlow.incomeAndSalesChart.total.income')
					: t('cashFlow.incomeAndSalesChart.total.sales')
			);
	});

	const d3Chart = useRef();

	return (
		<div id="chart">
			<svg ref={d3Chart} style={{ height: '600px', width: '100%' }}></svg>
		</div>
	);
};
export default withTranslation()(IncomeLineChart);
