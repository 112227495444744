import styled from 'styled-components'

const KlixButtonContainer = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #607d8b;
  color: white;
  border-radius: 5px;
  border: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;

  &:active {
    background-color: #61ce8b;
  }
`

export default KlixButtonContainer
