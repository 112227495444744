import axios from './api';
import qs from 'qs';

export default {
	getMessages: async cmID => {
		const param = {
			cmID,
			startIndex: 0,
			recordCount: 10
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios
			.get()
			.get(`/messages${query}`)
			.then()
			.catch(e => console.log(e));
	},

	getMessagesFromUser: async body => {
		const param = {
			fromUserID: body,
			startIndex: 0,
			recordCount: 10
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios
			.get()
			.get(`/messages/fromUser${query}`)
			.then()
			.catch(e => console.log(e));
	},

	getUnreadMessagesCount: async cmID => {
		const param = {
			cmID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios
			.get()
			.get(`/messages/unreadCount ${query}`)
			.then()
			.catch(e => console.log(e));
	},

	sendMessage: async body => {
		const param = {
			targetUserID: body.targetUserID,
			message: body.message
		};

		return await axios
			.get()
			.put(`/messages/sendMessage`, param)
			.then()
			.catch(e => console.log(e));
	},

	sendEmailMessage: async body => {
		const param = {
			targetUserID: body.targetUserID,
			message: body.message,
			topic: body.topic
		};
		return await axios
			.get()
			.put(`/messages/sendEmail`, param)
			.then()
			.catch(e => console.log(e));
	},

	readMessage: async body => {
		const param = {
			emailID: body.emailID
		};
		return await axios
			.get()
			.post(`/messages/read`, param)
			.then()
			.catch(e => console.log(e));
	}
};
