import { Record } from 'immutable';
import API from '../services/api/api';
import EditorAPI from '../services/api/apiEditor';

const UserActions = {
	CREATE_USER: 'CREATE_USER',
	DELETE_USER: 'DELETE_USER'
};

export const User = Record({
	privatekey: '',
	menu: '',
	cmID: '',
	permission: '',
	privilege: ''
});

function getInitialState() {
	const userJSON = localStorage.getItem('user');
	try {
		const user = userJSON ? new User(JSON.parse(userJSON)) : null;
		const token = user.privatekey;
		API.setToken(token);
		EditorAPI.setToken(token);
		return user;
	} catch (error) {}

	return null;
}

export function createUser(privatekey, menu, cmID, permission, privilege) {
	return {
		type: UserActions.CREATE_USER,
		privatekey,
		menu,
		cmID,
		permission,
		privilege
	};
}

export function deleteUser() {
	return {
		type: UserActions.DELETE_USER
	};
}

const user = (state = getInitialState(), action) => {
	switch (action.type) {
		//Felhasználó létrehozása
		case UserActions.CREATE_USER:
			// Ha nincs se email vagy pass akkor nem hozzuk létre
			if (!action.privatekey) {
				return state;
			}
			API.setToken(action.privatekey);
			EditorAPI.setToken(action.privatekey);
			const userDate = {
				privatekey: action.privatekey,
				menu: action.menu,
				cmID: action.cmID,
				permission: action.permission,
				privilege: action.privilege
			};
			localStorage.setItem('user', JSON.stringify(userDate));
			return new User(userDate);
		case UserActions.DELETE_USER:
			localStorage.removeItem('user');
			return null;
		default:
			return state;
	}
};

export default user;
