import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CompanyProfileView from '../../views/Customer/CompanyProfileView';

class ProfileRoute extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route
						path="/companyprofile"
						name="CompanyProfile"
						component={CompanyProfileView}
					/>

					<Redirect from="/" to="/companyprofile" />
				</Switch>
			</div>
		);
	}
}

export default ProfileRoute;
