function getStatusId(status) {
	const statuses = {
		info: 'device.status.info',
		online: 'device.status.online',
		ok: 'device.status.ok',
		programming: 'device.status.programming',
		warning: 'device.status.warning',
		error: 'device.status.error',
		offline: 'device.status.offline'
	};
	return statuses[status];
}

export default getStatusId;
