import React, { Component } from 'react';
//Dashboards
import RootDashboard from './Components/RootDashboard';
import HVendTechnicanDashboard from './Components/HVendTechnicanDashboard';
// import AdminDashboard from './Components/AdminDashboard';
import CustomerDashboard from './Components/CustomerDashboard';
import TechnicanDashboard from './Components/TechnicanDashboard';
// import OperatorDashboard from './Components/OperatorDashboard';

import { connect } from 'react-redux';

import UserUtil from '../../services/util/userUtil';

const mapStateToProps = state => ({
	user: state.user
});

class Dashboard extends Component {
	//GET DASHBOARD ELEMENT
	getDashboardElement() {
		switch (true) {
			case UserUtil.isRoot():
				return <RootDashboard />;
			case UserUtil.isHVendTechnican():
				return <HVendTechnicanDashboard />;
			case UserUtil.isAdministrator() || UserUtil.isRegionalCompanyAdmin():
				return <CustomerDashboard />;
			case UserUtil.isTechnican():
				return <TechnicanDashboard />;
			//	case UserUtil.isBusinessAnalyst():
			//		return <OperatorDashboard />;
			default:
				return null;
		}
	}

	render() {
		return <div>{this.getDashboardElement()}</div>;
	}
}

export default connect(mapStateToProps)(Dashboard);
