import React, { Component } from 'react';
import { Card } from 'reactstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';

import DeviceGroupManagerTab from './DeviceGroupManagerTab/DeviceGroupManagerTab';
import ProductGroupManagerTab from './ProductGroupManagerTab/ProductGroupManagerTab';

import { withTranslation } from 'react-i18next';

class DeviceGroups extends Component {
	state = {
		activeKey: 'DeviceGroups'
	};

	onTabChange = eventKey => {
		this.setState({ activeKey: eventKey });
	};

	defaultTab = e => {
		this.setState({ activeKey: e });
	};

	//Render!
	render() {
		const { t } = this.props;
		return (
			<div className="animated fadeIn">
				<DashboardCardContainerWithFlow>
					<Card body>
						<PageTiltleCard title={t('groupManager.title')} />
						<Tabs
							activeKey={this.state.activeKey}
							id="GroupManager"
							onSelect={this.onTabChange}
						>
							<Tab
								eventKey="DeviceGroups"
								title={t('groupManager.deviceTab.title')}
							>
								<DeviceGroupManagerTab defaultTab={this.defaultTab} />
							</Tab>
							<Tab
								eventKey="ProductGroups"
								title={t('groupManager.productsTab.title')}
							>
								<ProductGroupManagerTab dev defaultTab={this.defaultTab} />
							</Tab>
						</Tabs>
					</Card>
				</DashboardCardContainerWithFlow>
			</div>
		);
	}
}

export default withTranslation()(DeviceGroups);
