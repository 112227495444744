import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';
import classes from './MachineGroupSelector.module.css';

const MachineGroupSelector = props => {
	const { t } = props;

	const isGroupSelected = () => {
		return props.isGroupSelected;
	};

	const colBackground = () => {
		const edit = document.getElementById('edit');

		if (edit) {
			return classes.ColEdit;
		} else {
			return classes.Col;
		}
	};
	const placeholderSelect = () => {
		const edit = document.getElementById('edit');
		if (edit) {
			return t('promotionManager.editPromotion.placeholder');
		} else {
			return t('promotionManager.addPromotion.promotionType.placeholder');
		}
	};

	const radioHandler = e => {
		const isGroupSelected = e.target.id != 'machines';
		let selection = null;
		if (isGroupSelected) {
			selection =
				props.optionGroup.length > 0 ? props.optionGroup[0].value : null;
		} else {
			selection =
				props.optionMachine.length > 0 ? props.optionMachine[0].value : null;
		}
		props.onIsGroupSelectedChanged(isGroupSelected, selection);
	};

	const machineGroupNameFormater = () => {
		let option = [];
		if (!isGroupSelected()) {
			if (props.optionMachine) {
				return props.optionMachine.map((e, index) => (
					<option key={e.label + index} label={e.label} value={e.value}>
						{e.label}
					</option>
				));
			}
		} else {
			if (props.optionGroup) {
				return props.optionGroup.map((e, index) => (
					<option key={e.label + index} label={e.label} value={e.value}>
						{e.label}
					</option>
				));
			}
		}
		return null;
	};

	let machineSelector = (
		<Form.Control
			style={
				props.incomeRow
					? {
							border: '1px solid var(--message-mid)',
							borderRadius: '3px',
							color: ' var(--message-mid)'
					  }
					: {}
			}
			id={props.id}
			name={'defaultSelectNameMachine'}
			type="text"
			as="input"
			readOnly
			placeholder={placeholderSelect()}
		/>
	);
	if (!props.disabled) {
		machineSelector = (
			<Form.Control
				style={
					props.incomeRow
						? {
								border: '1px solid var(--message)',
								borderRadius: '3px',
								color: ' var(--message)'
						  }
						: {}
				}
				name="selectedMachienOrGroupIDS"
				type="select"
				as="select"
				value={props.selectedIDS}
				onChange={e => {
					props.onSelectionChanged(e.target.value);
				}}
			>
				{machineGroupNameFormater()}
			</Form.Control>
		);
	}
	return (
		<Form.Group controlId="machineGroups">
			<Col
				style={props.incomeRow ? { marginTop: '20px' } : {}}
				className={props.incomeRow || colBackground()}
			>
				<Row
					style={{
						alignItems: 'center',
						justifyContent: 'space-around'
					}}
				>
					<Col style={{ marginBottom: '5px' }} xs={8} sm={8} md={8} lg={8}>
						<Form.Check
							required
							default
							type="radio"
							label={t(
								'promotionManager.addPromotion.promotionDevice.label.deviceGroup'
							)}
							name="radioSelect"
							id="machineGroup"
							checked={isGroupSelected()}
							onChange={e => radioHandler(e)}
						/>
					</Col>
					<Col style={{ marginBottom: '5px' }} xs={4} sm={4} md={4} lg={4}>
						<Form.Check
							required
							type="radio"
							label={t(
								'promotionManager.addPromotion.promotionDevice.label.device'
							)}
							name="radioSelect"
							id="machines"
							checked={!isGroupSelected()}
							onChange={e => radioHandler(e)}
						/>
					</Col>
					{machineSelector}
				</Row>
			</Col>
		</Form.Group>
	);
};

export default withTranslation()(MachineGroupSelector);
