import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import ProductSelector from './ProductSelector/ProductSelector';
import Badge from 'react-bootstrap/Badge';
import { withTranslation } from 'react-i18next';
import promotionsUtil from '../../../../../services/util/promotionsUtil';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

//____________________FROM DEV:__________________
// Product promotion now only can recive promotion ID manually.
// Commented codeblocks preperd for producthandling when it will be avileble

const ValuePromotion = props => {
	const { t } = props;

	const isFixedAmount = () => {
		const paramValue = promotionsUtil.getParamValueByName(
			props.promotion,
			promotionsUtil.promotionParameters.PROMOTION_GENERAL_DISCOUNT_FIX_AMOUNT
		);
		return paramValue && paramValue == 'Y';
	};

	const getPromotionAmount = props => {
		const paramValue = promotionsUtil.getParamValueByName(
			props.promotion,
			promotionsUtil.promotionParameters.PROMOTION_GENERAL_DISCOUNT
		);
		return paramValue ? paramValue : '';
	};

	const getTagsInArray = () => {
		const paramValue = promotionsUtil.getParamValueByName(
			props.promotion,
			promotionsUtil.promotionParameters.PROMOTION_PRODUCT_TAGS
		);

		return paramValue ? paramValue.split(',') : '';
	};

	let valueType = (
		<InputGroup className="mb-3">
			<Form.Control
				name={'defaultValueSelect'}
				id={'defaultValueSelect'}
				type="text"
				readOnly
				placeholder={t(
					'promotionManager.addPromotion.promotionType.value.placeholder.selector'
				)}
			/>
			<InputGroup.Prepend>
				<InputGroup.Text
					id="basic-addon1"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '2.5rem',
						height: '35px',
						border: '1px #b0c4de solid'
					}}
				>
					<i className="fa fa-hashtag"></i>
				</InputGroup.Text>
			</InputGroup.Prepend>
		</InputGroup>
	);
	if (!isFixedAmount()) {
		valueType = (
			<InputGroup className="mb-3">
				<Form.Control
					required
					name={'discountValue'}
					id={'discountValue'}
					type="number"
					min="1"
					max="100"
					value={getPromotionAmount(props)}
					onChange={e => {
						const newPromotion = { ...props.promotion };
						newPromotion.params = props.promotion.params.map(param => {
							return { ...param };
						});

						promotionsUtil.setParamValue(
							newPromotion,
							promotionsUtil.promotionParameters.PROMOTION_GENERAL_DISCOUNT,
							e.target.value
						);
						props.onChanged(newPromotion);
					}}
				/>
				<InputGroup.Prepend>
					<InputGroup.Text
						id="basic-addon1"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '2.5rem',
							height: '35px',
							border: '1px #b0c4de solid'
						}}
					>
						<i className="fa fa-percent"></i>
					</InputGroup.Text>
				</InputGroup.Prepend>
				<Form.Control.Feedback type="invalid">
					{t('promotionManager.addPromotion.invalideValue')}
				</Form.Control.Feedback>
			</InputGroup>
		);
	} else {
		valueType = (
			<InputGroup className="mb-3">
				<Form.Control
					required
					name={'discountValue'}
					id={'discountValue'}
					type="number"
					step="0.01"
					min="0"
					value={getPromotionAmount(props)}
					onChange={e => {
						const newPromotion = { ...props.promotion };
						newPromotion.params = props.promotion.params.map(param => {
							return { ...param };
						});

						promotionsUtil.setParamValue(
							newPromotion,
							promotionsUtil.promotionParameters.PROMOTION_GENERAL_DISCOUNT,
							e.target.value
						);
						props.onChanged(newPromotion);
					}}
				/>
				<InputGroup.Prepend>
					<InputGroup.Text
						id="basic-addon1"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '2.5rem',
							height: '35px',
							border: '1px #b0c4de solid'
						}}
					>
						<i className="fa fa-dollar"></i>
					</InputGroup.Text>
				</InputGroup.Prepend>
				<Form.Control.Feedback type="invalid">
					{t('promotionManager.addPromotion.invalide')}
				</Form.Control.Feedback>
			</InputGroup>
		);
	}

	const radioHandler = e => {
		const newPromotion = { ...props.promotion };
		newPromotion.params = props.promotion.params.map(param => {
			return { ...param };
		});

		if (e.target.id == 'value') {
			promotionsUtil.setParamValue(
				newPromotion,
				promotionsUtil.promotionParameters.PROMOTION_GENERAL_DISCOUNT,
				null
			);
			promotionsUtil.setParamValue(
				newPromotion,
				promotionsUtil.promotionParameters
					.PROMOTION_GENERAL_DISCOUNT_FIX_AMOUNT,
				'Y'
			);
		} else {
			promotionsUtil.setParamValue(
				newPromotion,
				promotionsUtil.promotionParameters.PROMOTION_GENERAL_DISCOUNT,
				null
			);
			promotionsUtil.setParamValue(
				newPromotion,
				promotionsUtil.promotionParameters
					.PROMOTION_GENERAL_DISCOUNT_FIX_AMOUNT,
				'N'
			);
		}
		props.onChanged(newPromotion);
	};

	const renderForm = () => {
		return (
			<>
				<Col xs={12} sm={12} md={12} lg={props.product || props.credit ? 5 : 8}>
					<Form.Group style={{ marginTop: '5px' }}>
						<Row>
							<Col>
								<Form.Check
									required
									type="radio"
									label={t(
										'promotionManager.addPromotion.promotionType.value.label.value'
									)}
									name="valueRadioSelect"
									id="value"
									style={{
										marginBottom: '5px',
										fontSize: 'small'
									}}
									inline
									checked={isFixedAmount()}
									value
									onChange={radioHandler}
								/>
							</Col>
							{!props.onlyFixedAmount ? (
								<Col>
									<Form.Check
										required
										type="radio"
										label={t(
											'promotionManager.addPromotion.promotionType.value.label.percentage'
										)}
										name="valueRadioSelect"
										id="percentage"
										style={{ marginBottom: '5px' }}
										inline
										checked={!isFixedAmount()}
										onChange={radioHandler}
									/>
								</Col>
							) : null}
						</Row>
						{valueType}
					</Form.Group>
				</Col>
				{props.credit ? (
					<Col xs={12} sm={12} md={12} lg>
						<Form.Group style={{ marginTop: '5px' }}>
							<Form.Label style={{ marginBottom: '5px', fontSize: 'small' }}>
								{t(
									'promotionManager.addPromotion.promotionType.credit.label.required'
								)}
							</Form.Label>
							<Form.Control
								placeholder={t(
									'promotionManager.addPromotion.promotionType.value.placeholder.selector'
								)}
								required
								name={'credit'}
								id={'credit'}
								type="number"
								min="0"
								step="0.01"
								onChange={e => {
									const newPromotion = { ...props.promotion };
									newPromotion.params = props.promotion.params.map(param => {
										return { ...param };
									});
									promotionsUtil.setParamValue(
										newPromotion,
										promotionsUtil.promotionParameters
											.PROMOTION_MINIMUM_CREDIT_UPLOAD,
										e.target.value
									);
									props.onChanged(newPromotion);
								}}
								value={
									promotionsUtil.getParamValueByName(
										props.promotion,
										promotionsUtil.promotionParameters
											.PROMOTION_MINIMUM_CREDIT_UPLOAD
									)
										? promotionsUtil.getParamValueByName(
												props.promotion,
												promotionsUtil.promotionParameters
													.PROMOTION_MINIMUM_CREDIT_UPLOAD
										  )
										: ''
								}
							/>
						</Form.Group>
					</Col>
				) : null}

				{props.product ? (
					<>
						<Col xs={12} sm={12} md={12} lg>
							<Form.Group style={{ marginTop: '5px' }}>
								<Form.Label style={{ marginBottom: '5px', fontSize: 'small' }}>
									{t(
										'promotionManager.addPromotion.promotionType.product.title'
									)}
								</Form.Label>
								<div style={{ display: 'flex' }}>
									<Typeahead
										placeholder={t(
											'promotionManager.addPromotion.promotionType.value.placeholder.selector'
										)}
										required
										name={'productId'}
										id={'productId'}
										multiple
										onChange={e => {
											const newPromotion = { ...props.promotion };
											newPromotion.params = props.promotion.params.map(
												param => {
													return { ...param };
												}
											);
											promotionsUtil.setParamValue(
												newPromotion,
												promotionsUtil.promotionParameters
													.PROMOTION_PRODUCT_TAGS,
												e ? e.join(',') : null
											);
											props.onChanged(newPromotion);
										}}
										selected={getTagsInArray()}
										options={
											props.productTags
												? props.productTags.map(tag => tag.Name)
												: []
										}
									/>
								</div>
							</Form.Group>
						</Col>
					</>
				) : null}
			</>
		);
	};

	return (
		<Row
			style={
				props.product || props.credit
					? { justifyContent: 'space-between' }
					: { justifyContent: 'center' }
			}
		>
			{props.isSequent ? (
				<Col xs={12} sm={12} md={12} lg={8}>
					<Form.Group style={{ marginTop: '5px' }}>
						<Form.Label style={{ marginBottom: '5px', fontSize: 'small' }}>
							{t('promotionManager.addPromotion.promotionType.sequent.title')}
						</Form.Label>
						<Form.Control
							placeholder={t(
								'promotionManager.addPromotion.promotionType.value.placeholder.selector'
							)}
							required
							name={'sequent'}
							id={'sequent'}
							type="number"
							min="1"
							onChange={e => {
								const newPromotion = { ...props.promotion };
								newPromotion.params = props.promotion.params.map(param => {
									return { ...param };
								});

								promotionsUtil.setParamValue(
									newPromotion,
									'PROMOTION_SEQUENTIAL_NUMBER',
									e.target.value
								);
								props.onChanged(newPromotion);
							}}
							value={
								promotionsUtil.getParamValueByName(
									props.promotion,
									'PROMOTION_SEQUENTIAL_NUMBER'
								)
									? promotionsUtil.getParamValueByName(
											props.promotion,
											'PROMOTION_SEQUENTIAL_NUMBER'
									  )
									: ''
							}
						/>
					</Form.Group>
				</Col>
			) : (
				renderForm()
			)}
		</Row>
	);
};

export default withTranslation()(ValuePromotion);
