export function getColorForState(state) {
	switch (state) {
		case 'online':
			return 'success';
		case 'info':
			return 'success';
		case 'ok':
			return 'success';
		case 'programming':
			return 'info';
		case 'warning':
			return 'warning';
		case 'error':
			return 'danger';
		case 'offline':
		default:
			return 'secondary';
	}
}

export function getStateTextClassName(state) {
	if (state === null || state === undefined) return 'text-secondary';
	let result = 'text-success';
	if (state & (state > 1)) result = 'text-danger';
	if (state & (state === 1)) result = 'text-info';
	return result;
}
