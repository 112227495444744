//@flow
import React, { Component } from 'react';
import realtime from '../../../services/api/realtime';
import type { Subscription } from '../../../services/api/realtime';
import machineAPI from '../../../services/api/machines';
import VMUtil from '../../../services/util/vmUtil';

const DEFAULT_LINE_NUMBER = 4;

type Props = {
	vmID: string,
	history: Object,
	lineNumber?: number,
	lineLength?: number,
	ledNumbers?: number,
	products?: Array<Object>,
	isSlave: ?boolean,
	machineTitle: string,
	hasProgrammingButton?: boolean,
	keyboard?: any,
	lcd?: any
};

type States = {
	LCDLines: Array<string>,
	ledStatuses: Array<boolean>,
	message: string
};

class MachinaBaseComponent extends Component<Props, States> {
	listeners: Array<Subscription> = [];

	constructor(props: Props) {
		super(props);

		const { lineNumber = DEFAULT_LINE_NUMBER, ledNumbers = 0 } = this.props;

		let LCDLines = Array(lineNumber).fill('');
		const ledStatuses = Array(ledNumbers).fill(false);
		this.state = {
			LCDLines,
			message: '',
			ledStatuses
		};
	}

	componentDidMount = async () => {
		const vmID = this.props.vmID
			? this.props.vmID
			: VMUtil.decodeVMID(this.props.match.params.vmID);

		const hardwareIDS = await machineAPI.getHardwareIDSByMachineID(vmID);
		const hardwareID = hardwareIDS.length > 0 ? hardwareIDS[0] : null;
		this.setState({ hardwareID: hardwareID });

		this.listeners.push(
			await realtime.subscribeLCDForVM(hardwareID, this.handleLcdMessage)
		);
		this.listeners.push(
			await realtime.subscribeLCDTimeoutForVM(
				hardwareID,
				this.handleLcdTimeoutMessage
			)
		);

		await realtime.turnOnLCD(hardwareID);
		this.setState({ showLCD: true });
	};

	// This function is not used anymore
	getLineHelper = (helper: string) => decodeURIComponent(escape(helper));

	handleLcdMessage = (message: any) => {
		const newMessage = message.toString();
		const { lineNumber = DEFAULT_LINE_NUMBER } = this.props;
		let LCDLines = [...this.state.LCDLines];

		for (let i = 0; i < lineNumber; i++) {
			if (newMessage.indexOf(`LINE${i + 1}`) !== -1) {
				//hex convertálás
				let line = VMUtil.hex2a(newMessage.replace(`LINE${i + 1}=`, ''));
				//const line = this.getLineHelper(helper);
				LCDLines[i] = line;
				this.setState({
					LCDLines
				});
			}
		}

		if (newMessage.indexOf(`LAMPS`) !== -1) {
			const ledStatusesString = newMessage.replace(`LAMPS=`, '');
			const ledStatuses = ledStatusesString
				.split('')
				.map(ledStatus => ledStatus === '1');
			this.setState({ ledStatuses });
		}
		this.setState({ message: newMessage });
	};

	handleLcdTimeoutMessage = () => {
		this.setState({ showLCD: false, remoteAcccess: false });
		realtime.turnOffRemoteControl(this.state.hardwareID);
		if (this.props.onRemoteControlSwitched) {
			this.props.onRemoteControlSwitched(false);
		}
	};

	componentWillUnmount = async () => {
		await realtime.turnOffLCD(this.state.hardwareID);
		this.listeners.forEach(listener => listener.unsubscribe());
	};

	bntPressHandler = (buttonId: string) => async () => {
		await realtime.pressButton(
			this.state.hardwareID,
			buttonId,
			this.props.slavePrefix ? this.props.slavePrefix : ''
		);
	};

	commandHandler = async command => {
		await realtime.sendCommand(this.state.hardwareID, command);
	};

	render() {
		return <div />;
	}
}

export default MachinaBaseComponent;
