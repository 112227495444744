const Table = {
	SET_TABLE_DATA: 'SET_TABLE_DATA'
};

export function setTableData(tableData) {
	return {
		type: Table.SET_TABLE_DATA,
		tableData
	};
}

export function setItem(param, item) {
	return {
		type: param,
		item
	};
}

const table = (state = 0, action) => {
	switch (action.type) {
		case 'SET_TABLE_DATA':
			return action.tableData;

		default:
			return state;
	}
};

export default table;
