import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class ConfirmationDialog extends Component {
	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				onToggle={this.props.onToggle}
				className={
					!this.props.infoDialog ? 'modal-danger ' : '' + this.props.className
				}
			>
				<ModalHeader toggle={this.toggleDanger}>{this.props.title}</ModalHeader>
				<ModalBody>{this.props.confirmationText}</ModalBody>
				<ModalFooter>
					{!this.props.cancelOff ? (
						<Button
							color="secondary"
							className="DefaultButton"
							onClick={() => this.props.onCancel()}
						>
							<i className="fa fa-times-circle mr-2" />
							{this.props.cancelText}
						</Button>
					) : null}
					<Button
						color={this.props.infoDialog ? 'info' : 'danger'}
						className="DefaultButton"
						onClick={() => this.props.onOk(this.prop)}
					>
						<i className="fa fa-check-circle mr-2" /> {this.props.okText}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default ConfirmationDialog;
