import React, { Component } from 'react';
import { Card, Nav, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class MediaAppearances extends Component {
	render() {
		const { t } = this.props;
		return (
			<Nav>
				<Card body>
					<Label className="h1">{t('media.dev.title')}</Label>
					<Label className="h5">{t('media.dev.content')}</Label>
				</Card>
			</Nav>
		);
	}
}

export default withTranslation()(MediaAppearances);
