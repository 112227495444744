import React, { Component } from 'react';
import { Input, Button, Col, Row } from 'reactstrap';
import {
	Section,
	SectionSmallTitle,
	SectionSubTitle,
	SectionText
} from '../../../../components/Section';
import { withTranslation } from 'react-i18next';
import EditButton from './EditButton';
import machineAPI from '../../../../services/api/machines';
import ConfimationDialog from '../../../ConfirmationDialog/ConfirmationDialog';
import classes from './Datasheet.module.css';

import customHardwareTypes from '../../../../services/types/customHardwareTypes';

class CustomPeripherialSheet extends Component {
	state = {
		saveInProgress: false,
		editMode: null,
		peripherial: [],
		protocol: null,
		waterHardnes: null,
		softReplaced: null,
		filterReplaced: null,
		capacity: null
	};
	componentDidMount() {
		let peripherial = { ...this.props.peripherial };

		this.setState({
			peripherial: peripherial
		});
	}

	handleSave = async () => {
		this.setState({ saveInProgress: true }, () => {
			this.saveData();
			this.setState({ saveInProgress: false, editMode: false });
		});
	};
	handleEdit() {
		this.props.reload();
		this.setState({ saveInProgress: false, editMode: false });
	}
	handleDelete(value) {
		this.props.onDelet(value);
		this.setState({ saveInProgress: false, editMode: false });
	}
	saveData = type => {
		const { peripherial } = this.state;

		let param = {
			partID: peripherial.partID,
			partType: peripherial.partType,
			customerID: this.props.machine.cID,
			serialNumber: peripherial.serialNumber,
			hardwareState: 1, // this must be always 1 //peripherial.hardwareState,
			orderNumber: peripherial.orderNumber,
			firmwareName: peripherial.firmwareName,
			firmwareFilename: peripherial.firmwareFilename,
			microControllerType: peripherial.microControllerType,
			customManufacturerName: peripherial.customManufacturerName,
			customType: peripherial.customType,
			customIdentifier: peripherial.customIdentifier,
			customSWVersion: peripherial.customSWVersion,
			customData: peripherial.customData
		};

		machineAPI
			.updateMachineHardwarePart(param)
			.then(res => this.props.reload());

		return;
	};
	inputChangeHandler = event => {
		let peripherial = { ...this.state.peripherial };

		peripherial[event.target.name] = event.target.value;
		this.setState({ peripherial: peripherial });
	};

	customDataInputChangeHandler = event => {
		const peripherial = { ...this.state.peripherial };
		peripherial[event.target.name] = event.target.value;
		this.setState({ peripherial });
	};

	hideConfirmationDialog() {
		this.setState({
			deletePeripheralConfirmationVisible: false
		});
	}

	showDeleteConfirmation() {
		this.setState({
			deletePeripheralConfirmationVisible: true
		});
	}

	renderDeleteConfirmationDialog = value => {
		return (
			<ConfimationDialog
				title="Confirmation"
				confirmationText={
					<>
						<Col>
							{this.props.t('device.details.datasheet.peripherals.delConf')}
						</Col>
						<Col>
							<Row>
								<Col>
									{this.props.t('device.details.datasheet.peripherals.delPer')}
								</Col>
								<Col style={{ fontWeight: 'bold' }}>{this.props.name}</Col>
							</Row>
						</Col>
					</>
				}
				okText={this.props.t('device.details.invisibleTouch.ok')}
				cancelText={this.props.t('device.details.invisibleTouch.cancel')}
				isOpen={this.state.deletePeripheralConfirmationVisible}
				onToggle={() => {
					this.hideConfirmationDialog();
				}}
				onCancel={() => {
					this.hideConfirmationDialog();
				}}
				onOk={() => {
					this.hideConfirmationDialog();
					this.handleDelete(value);
				}}
			/>
		);
	};

	renderSection(editMode, title, valueName, eventHandler = null) {
		const { t } = this.props;
		return (
			<Section last>
				<SectionSmallTitle>{title}</SectionSmallTitle>
				{editMode ? (
					<SectionText last>
						<Input
							name={valueName}
							value={this.state.peripherial[valueName]}
							onChange={
								eventHandler ? eventHandler : this.customDataInputChangeHandler
							}
							placeholder={
								this.state[valueName] || t('device.details.datasheet.noData')
							}
						></Input>
					</SectionText>
				) : (
					<SectionText>
						{this.state.peripherial[valueName] ||
							t('device.details.datasheet.noData')}
					</SectionText>
				)}
			</Section>
		);
	}

	renderCustomWaterSystemParts(editMode) {
		const { t } = this.props;
		return (
			<>
				{this.renderSection(
					editMode,
					t('device.details.datasheet.peripherals.waterSys.waterHardnes'),
					'waterHardnes'
				)}
				{this.renderSection(
					editMode,
					t('device.details.datasheet.peripherals.waterSys.wSoftReplaced'),
					'softReplaced'
				)}

				{this.renderSection(
					editMode,
					t('device.details.datasheet.peripherals.waterSys.wFilterReplaced'),
					'filterReplaced'
				)}
				{this.renderSection(
					editMode,
					t('device.details.datasheet.peripherals.waterSys.capacity'),
					'capacity'
				)}
			</>
		);
	}

	getCustomData(customDataFiled) {
		try {
			const customData = JSON.parse(this.state.peripherial.customData);
			return customData[customDataFiled];
		} catch (error) {}
		return undefined;
	}
	setCustomData(customDataFiled, value) {
		const peripherial = { ...this.state.peripherial };
		let customData;
		try {
			customData = JSON.parse(peripherial.customData);
		} catch (error) {
			customData = {};
		}
		customData[customDataFiled] = value;
		peripherial.customData = JSON.stringify(customData);
		this.setState({ peripherial });
	}

	render() {
		const {
			peripherial,
			t,
			machine,
			root,
			companyAdmin,
			regionalAdmin,
			name
		} = this.props;
		const { editMode, saveInProgress } = this.state;
		return (
			<>
				{this.renderDeleteConfirmationDialog(peripherial.partID)}
				<>
					<Section className={classes.SubTitleRow}>
						<Row
							style={{
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<Col xs={12} sm={6} md={12} lg={7}>
								<SectionSubTitle>
									{name ? name : t('device.details.datasheet.noData')}
								</SectionSubTitle>
							</Col>
							{root || companyAdmin ? (
								<Col xs={12} sm={12} md={12} lg={editMode ? 12 : 5}>
									<Row
										style={{ alignItems: 'center', justifyContent: 'flex-end' }}
									>
										<EditButton
											editMode={editMode}
											saveInProgress={saveInProgress}
											machine={machine}
											deleteButton
											onEdit={() => this.setState({ editMode: true })}
											onHandleEdit={() => this.handleEdit()}
											onHandleSave={this.handleSave}
											onDel={() => this.showDeleteConfirmation()}
										></EditButton>
									</Row>
								</Col>
							) : null}
						</Row>
					</Section>
				</>

				<Section last>
					{peripherial.name !==
					customHardwareTypes.CUSTOM_SNAKY_PRODUCT_STORAGE.name
						? this.renderSection(
								editMode,
								t(
									'device.details.datasheet.peripherals.paymentSys.manufacturer'
								),
								'customManufacturerName'
						  )
						: null}
					{peripherial.name !==
					customHardwareTypes.CUSTOM_SNAKY_PRODUCT_STORAGE.name
						? this.renderSection(
								editMode,
								t('device.details.datasheet.peripherals.paymentSys.type'),
								'customType'
						  )
						: null}
					{peripherial.name !==
					customHardwareTypes.CUSTOM_SNAKY_PRODUCT_STORAGE.name ? (
						<Section last>
							<SectionSmallTitle>
								{t('device.details.datasheet.peripherals.paymentSys.account')}
							</SectionSmallTitle>
							{editMode ? (
								<SectionText last>
									<Input
										name={'customIdentifier'}
										value={this.state.customIdentifier}
										onChange={this.inputChangeHandler}
										placeholder={
											peripherial.customIdentifier ||
											t('device.details.datasheet.noData')
										}
									></Input>
								</SectionText>
							) : (
								<SectionText
									last={
										peripherial.name ===
											customHardwareTypes.CUSTOM_COOLING_SYSTEM.name ||
										peripherial.name ===
											customHardwareTypes.CUSTOM_SODA_UNIT.name ||
										peripherial.name ===
											customHardwareTypes.CUSTOM_TAX_MONITORING_SYSTEM.name
									}
								>
									{peripherial.customIdentifier ||
										t('device.details.datasheet.noData')}
								</SectionText>
							)}
						</Section>
					) : (
						<>
							<Section last>
								<SectionSmallTitle>
									{t(
										'device.details.datasheet.peripherals.snakyStorage.columnCount'
									)}
								</SectionSmallTitle>
								{editMode ? (
									<SectionText last>
										<Input
											name={'columnCount'}
											value={this.getCustomData('snakyColCount')}
											onChange={e => {
												this.setCustomData('snakyColCount', e.target.value);
											}}
											placeholder={'' || t('device.details.datasheet.noData')}
										></Input>
									</SectionText>
								) : (
									<SectionText>
										{this.getCustomData('snakyColCount') ||
											t('device.details.datasheet.noData')}
									</SectionText>
								)}
							</Section>
							<Section last>
								<SectionSmallTitle>
									{t(
										'device.details.datasheet.peripherals.snakyStorage.rowCount'
									)}
								</SectionSmallTitle>
								{editMode ? (
									<SectionText last>
										<Input
											name={'rowCount'}
											value={this.getCustomData('snakyRowCount')}
											onChange={e => {
												this.setCustomData('snakyRowCount', e.target.value);
											}}
											placeholder={
												this.getCustomData('snakyRowCount') ||
												t('device.details.datasheet.noData')
											}
										></Input>
									</SectionText>
								) : (
									<SectionText>
										{this.getCustomData('snakyRowCount') ||
											t('device.details.datasheet.noData')}
									</SectionText>
								)}
							</Section>
						</>
					)}
					{peripherial.name !==
						customHardwareTypes.CUSTOM_COOLING_SYSTEM.name &&
					peripherial.name !== customHardwareTypes.CUSTOM_SODA_UNIT.name &&
					peripherial.name !== customHardwareTypes.CUSTOM_WATER_SYSTEM.name &&
					peripherial.name !==
						customHardwareTypes.CUSTOM_TAX_MONITORING_SYSTEM.name &&
					peripherial.name !==
						customHardwareTypes.CUSTOM_SNAKY_PRODUCT_STORAGE.name
						? this.renderSection(
								editMode,
								t('device.details.datasheet.peripherals.paymentSys.swVersion'),
								'customSWVersion'
						  )
						: null}
					{peripherial.name === customHardwareTypes.CUSTOM_WATER_SYSTEM.name
						? this.renderCustomWaterSystemParts()
						: null}
				</Section>
			</>
		);
	}
}

export default withTranslation()(CustomPeripherialSheet);
