import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import machineApi from '../../services/api/machines';
import HardwareTable from './HardwareTable';
import HelperTooltip from '../../components/UI/HelperTooltip/HelperTooltip';
import Spinner from '../../components/UI/Spinner/Spinner';
import Modal from 'react-bootstrap/Modal';
import Toggle from 'react-bootstrap-toggle';
import Button from 'react-bootstrap/Button';

const filterParams = {
	HARDWARE: 1,
	TYPE: 2,
	MANUFACTURER: 3,
	IDENTFIER: 4,
	DEVICE: 5,
	DOORIDENTIFIER: 6,
	LOCATION: 7
};

class HardwareCard extends Component {
	state = {
		hardwares: [],
		data: [],
		elementVisibilities: [],
		filteredData: []
	};

	componentDidMount() {
		this.loadData();
	}

	loadData = async () => {
		await machineApi.getVisibleVendingMachines().then(async res => {
			let machinesIDList = [];
			machinesIDList = [...res.data.response.filter(e => e.deletedAt === null)];
			let hwList = await this.setHardwares(machinesIDList).then(res => {
				return res;
			});
			let hardwareList = [];
			hwList.map(e => {
				e.map(el => {
					return hardwareList.push(el);
				});
			});
			this.setState({ hardwares: hardwareList }, () =>
				this.provideFiltredDataset()
			);
		});
	};

	loadHardwares = async machine => {
		if (machine && machine.vmID) {
			return await machineApi.getConectedHardwares(machine.vmID).then(res => {
				let hardwares = [];
				if (
					res.data &&
					res.data.connectedParts &&
					res.data.connectedParts.length > 0
				) {
					res.data.connectedParts.map(el => {
						return hardwares.push({
							...el,
							vmID: machine.vmID,
							address: machine.address,
							city: machine.city,
							cmID: machine.cmID,
							company: machine.company,
							vmCustomName: machine.customName,
							customerID: machine.customerID,
							hardwareID: machine.hardwareID,
							zipcode: machine.zipcode,
							country: machine.country,
							telemetryServiceEnabled: machine.telemetryServiceEnabled,
							vmName: machine.name
						});
					});
				}

				return hardwares;
			});
		}
	};

	setHardwares = async value => {
		if (value && value.length > 0) {
			return await Promise.all(value.map(e => this.loadHardwares(e)));
		}
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value }, () =>
			this.loadData()
		);
	};

	renderTable = () => {
		return !this.state.filteredData || this.state.filteredData.length < 0 ? (
			<Spinner />
		) : (
			<HardwareTable
				data={this.state.filteredData}
				startDate={this.state.startDate}
				endDate={this.state.endDate}
				elementVisibilities={this.state.elementVisibilities}
				onResetFilter={this.resetFilter}
				onDateChange={this.inputChangeHandler}
				showHardwareFilterDialog={() =>
					this.onShowDialog({ hardwareDialog: true })
				}
				showTypeFilterDialog={() => this.onShowDialog({ typeDialog: true })}
				showManufacturerFilterDialog={() =>
					this.onShowDialog({ manufacturerDialog: true })
				}
				showIdentifierFilterDialog={() =>
					this.onShowDialog({ identifierDialog: true })
				}
				showDeviceFilterDialog={() => this.onShowDialog({ deviceDialog: true })}
				showDoorIdentifierFilterDialog={() =>
					this.onShowDialog({ doorIdentifierDialog: true })
				}
			/>
		);
	};

	// ------------ FilterDialog------------

	onShowDialog(value1) {
		this.setState(value1);
	}

	onHideDialog(value2) {
		this.setState(value2);
	}

	formatHardwareName = cell => {
		if (cell && cell != '') {
			return cell.replaceAll(/_/g, ' ');
		} else {
			return this.props.t('promotionManager.promotionTable.noData');
		}
	};

	getSetOptions = param => {
		const distinct = (value, index, self) => {
			return self.indexOf(value) === index;
		};
		let product = [];
		switch (param) {
			case filterParams.HARDWARE:
				product = this.state.hardwares
					.map(e => {
						return e.name;
					})
					.filter(e => e !== null)

					.filter(distinct);
				return [product, param];
			case filterParams.TYPE:
				product = this.state.hardwares
					.map(e => {
						return e.customType;
					})
					.filter(e => e !== null)
					.filter(distinct);
				return [product, param];
			case filterParams.MANUFACTURER:
				product = this.state.hardwares
					.map(e => {
						return e.customManufacturerName;
					})
					.filter(e => e !== null)
					.filter(distinct);
				return [product, param];
			case filterParams.IDENTFIER:
				product = this.state.hardwares
					.map(e => {
						return e.customIdentifier;
					})
					.filter(e => e !== null)
					.filter(distinct);
				return [product, param];
			case filterParams.DEVICE:
				product = this.state.hardwares
					.map(e => {
						return e.vmCustomName;
					})
					.filter(e => e !== null)
					.filter(distinct);

				return [product, param];
			case filterParams.DOORIDENTIFIER:
				product = this.state.hardwares
					.map(e => {
						return e.vmName;
					})
					.filter(e => e !== null)
					.filter(distinct);

				return [product, param];
		}
	};

	onToggleElementVisibility(element, param) {
		let elementVisibilities = [...this.state.elementVisibilities];
		let item = elementVisibilities.find(e => e.element === element);
		if (!item) {
			item = { element, visible: false, param };
			elementVisibilities.push(item);
		} else {
			item.visible = !item.visible;
		}

		this.setState({ elementVisibilities: elementVisibilities }, () => {
			this.provideFiltredDataset();
		});
	}

	isElementTypeVisible(element) {
		let result = true;
		let item = this.state.elementVisibilities.find(e => e.element === element);
		if (item) {
			result = item.visible;
		}
		return result;
	}

	onToggleAllVisibilities(set, param) {
		let newVisibilitySettings = [];
		if (
			this.state.elementVisibilities.filter(item => !item.visible).length === 0
		) {
			newVisibilitySettings = set[0].map(e => {
				return { element: e, visible: false, param };
			});
		}
		if (
			this.state.elementVisibilities.filter(item => !item.visible).length !== 0
		) {
			newVisibilitySettings = this.state.elementVisibilities.filter(
				e => e.param !== param
			);
		}
		this.setState({ elementVisibilities: newVisibilitySettings }, () => {
			this.provideFiltredDataset();
		});
	}

	provideFiltredDataset() {
		const filteredData = this.state.hardwares
			.filter(e => this.isElementTypeVisible(e.name))
			.filter(e => this.isElementTypeVisible(e.customType))
			.filter(e => this.isElementTypeVisible(e.customManufacturerName))
			.filter(e => this.isElementTypeVisible(e.customIdentifier))
			.filter(e => this.isElementTypeVisible(e.vmCustomName))
			.filter(e => this.isElementTypeVisible(e.vmName))
			.map((e, index) => {
				return { ...e, index };
			});
		this.setState({ filteredData: filteredData });
	}

	renderDialog(set, value2, stateValue) {
		const param = set[1];
		const elementList = set[0].map((element, index) => {
			return (
				<div key={index}>
					<div>
						<strong style={{ marginLeft: '30px' }}>
							{this.formatHardwareName(element)}
						</strong>
						<Toggle
							onClick={() => this.onToggleElementVisibility(element, param)}
							on="show"
							off="hide"
							active={this.isElementTypeVisible(element)}
							onstyle="info"
							offstyle="warning"
							size="sm"
							style={{
								width: '75px',
								height: '30px',
								borderRadius: '25px',
								float: 'right'
							}}
						/>
					</div>
					<hr />
				</div>
			);
		});

		return (
			<Modal
				show={stateValue}
				onHide={() => this.onHideDialog(value2)}
				scrollable="true"
			>
				<Modal.Header>
					<Modal.Title>
						{this.props.t('salesSnadIncome.table.visible')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body max-height="260px">{elementList}</Modal.Body>
				<Modal.Footer>
					<Button
						variant={
							this.state.elementVisibilities.filter(item => !item.visible)
								.length === 0
								? 'warning'
								: 'info'
						}
						className="DefaultButtonMd"
						onClick={() => this.onToggleAllVisibilities(set, param)}
					>
						<i className="fa fa-eye mr-2"></i>
						{this.state.elementVisibilities.filter(item => !item.visible)
							.length === 0
							? this.props.t('eventList.hideAll')
							: this.props.t('eventList.showAll')}
					</Button>

					<Button
						variant="success"
						className="DefaultButton"
						onClick={() => this.onHideDialog(value2)}
					>
						<i className="fa fa-times-circle mr-2"></i>&nbsp;
						{this.props.t('eventList.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	resetFilter = () => {
		let newVisibilitySettings = [];

		this.setState({ elementVisibilities: newVisibilitySettings }, () => {
			this.provideFiltredDataset();
		});
	};

	// ------------ FilterDialog------------

	renderTitle() {
		return (
			<Row style={{ alignItems: 'felx-start' }}>
				<CardTitle
					className="text-muted"
					style={{ cursor: 'pointer', marginLeft: '20px', marginRight: '10px' }}
				>
					<i className={'fa fa-wrench'} style={{ marginRight: '10px' }} />
					{this.props.t('hardwareRiport.hardwartable.title')}
				</CardTitle>
				<HelperTooltip
					height={'100px'}
					width={'300px'}
					direction={'right'}
					id={'hardwareRiportHelperTooltip'}
					title={this.props.t('hardwareRiport.hardwartable.tooltip.title')}
					content={this.props.t('hardwareRiport.hardwartable.tooltip.content')}
				></HelperTooltip>
			</Row>
		);
	}

	render() {
		const { t } = this.props;
		return (
			<Card>
				{this.renderDialog(
					this.getSetOptions(filterParams.HARDWARE),
					{ hardwareDialog: false },
					this.state.hardwareDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.TYPE),
					{ typeDialog: false },
					this.state.typeDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.MANUFACTURER),
					{ manufacturerDialog: false },
					this.state.manufacturerDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.IDENTFIER),
					{ identifierDialog: false },
					this.state.identifierDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.DEVICE),
					{ deviceDialog: false },
					this.state.deviceDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.DOORIDENTIFIER),
					{ doorIdentifierDialog: false },
					this.state.doorIdentifierDialog
				)}
				<CardBody>
					{this.renderTitle()}
					{this.renderTable()}
				</CardBody>
			</Card>
		);
	}
}

export default withTranslation()(HardwareCard);
