import React, { Component } from 'react';
import { Card, Col, Row, Button } from 'reactstrap';
import {
	Section,
	SectionList,
	SectionTitle,
	SectionSmallTitle
} from '../../../../components/Section';
import machineAPI from '../../../../services/api/machines';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import AddSlaveDialog from './AddSlaveDialog';
import SlaveSheet from './SlaveSheet';
import classes from './Datasheet.module.css';

class NfdHardwareCard extends Component {
	state = {
		machine: null,
		peripherials: null,
		paymentSys: null,
		peripherialType: 36,
		paymantSysType: 39
	};
	targetRef = React.createRef();
	componentDidMount() {
		this.loadPeriperials();
	}
	componentDidUpdate(prevProps) {
		if (this.props.machine !== prevProps.machine) {
			let machine = { ...this.props.machine };
			this.setState({ machine: machine });
		}
	}
	loadPeriperials = () => {
		machineAPI.getVMPeripherials(this.props.vmId).then(resp => {
			let machine = { ...this.props.machine };
			let parts = [];
			parts = resp.data.connectedParts.map(e => {
				return { partID: e.partID };
			});
			this.setState({ parts, machine });
		});
	};

	renderSlave = value => {
		const { root, companyAdmin, regionalAdmin } = this.props;
		return value.slaves.map((e, index) => {
			return (
				<Col xs={12} sm={12} md={12} lg={12}>
					<SlaveSheet
						editMode={this.state.editMode}
						peripherial={e}
						index={index + 1}
						onDelet={this.handleSlaveDelete}
						machine={this.props.machine}
						root={root}
						companyAdmin={companyAdmin}
						regionalAdmin={regionalAdmin}
						peripherials={this.state.peripherials}
						vmId={this.props.vmId}
						machine={this.props.machine}
						hide={this.hidePeripherials}
					/>
				</Col>
			);
		});
	};

	handleDelete = partID => {
		let parts = [];
		this.state.parts.map(e => {
			if (e.partID !== partID) {
				parts.push(e);
			}
		});
		machineAPI
			.setConnectedPeripherals(this.props.vmId, parts)
			.then(res => this.loadPeriperials());
	};

	handleSlaveDelete = slaveID => {
		let param = {
			vmID: this.props.vmId,
			slaveID: slaveID
		};
		machineAPI.deletSlave(param).then(res => this.props.reload());
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	hideAddSlaveDialog() {
		this.setState(
			{
				showAddSlaveDialog: false
			},
			() => this.props.reload()
		);
	}
	async addPeripheral(selectedParts) {
		selectedParts.forEach(part => {
			this.state.parts.push({ partID: part.id });
		});

		await machineAPI.setConnectedPeripherals(this.props.vmId, this.state.parts);

		this.loadPeriperials();
		this.setState({ showAddDeviceDialog: false });
	}

	renderAddSlaveDialog() {
		return (
			<Modal
				show={this.state.showAddSlaveDialog}
				onHide={() => this.hideAddSlaveDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i
							className="fa fa-cog"
							aria-hidden="true"
							style={{ marginRight: '10px' }}
						></i>
						{this.props.t('device.details.datasheet.nfd.nfdData.newSlave')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: '10px' }}>
					<AddSlaveDialog
						vmID={this.props.vmId}
						onHide={() => this.hideAddSlaveDialog()}
					/>
				</Modal.Body>
			</Modal>
		);
	}
	hidePeripherials = () => {
		setTimeout(() => {
			this.targetRef.scrollIntoView({
				behavior: 'smooth',
				block: 'end'
			});
		}, 1);
	};
	render() {
		const { t, root } = this.props;
		const { machine } = this.state;
		const addSlaveDialog = this.renderAddSlaveDialog();
		if (!machine) return null;
		return (
			<Card>
				{addSlaveDialog}
				<SectionList
					ref={ref => {
						this.targetRef = ref;
					}}
				>
					<Row>
						<Col>
							<Section
								className={classes.TitleRow}
								style={{ borderRadius: '10px 10px 0px 0px' }}
							>
								<Col>
									<SectionTitle>
										{t('device.details.datasheet.nfd.nfdData.slave.title')}
									</SectionTitle>
								</Col>

								{machine.slaves.length < 2 ? (
									<Row
										style={{
											margin: '10px',
											justifyContent: 'space-between',
											alignItems: 'center'
										}}
									>
										<Col xs={12} sm={12} md={8} lg={8}>
											<SectionSmallTitle>
												{this.props.t(
													'device.details.datasheet.nfd.nfdData.newSlave'
												)}
											</SectionSmallTitle>
										</Col>

										<Col xs={12} sm={12} md={4} lg={4}>
											<Button
												color="success"
												className="DefaultButton"
												onClick={() =>
													this.setState({ showAddSlaveDialog: true })
												}
											>
												<i class={'fa fa-plus mr-2'}></i>{' '}
												{this.props.t(
													'device.details.peripheral.addPeripheral'
												)}
											</Button>
										</Col>
									</Row>
								) : null}
							</Section>
						</Col>
					</Row>
					<Row>
						{machine && machine.slaves && machine.slaves.length > 0
							? this.renderSlave(machine)
							: null}
					</Row>
				</SectionList>
			</Card>
		);
	}
}

export default withTranslation()(NfdHardwareCard);
