import React from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'

import { GreenMachineButton } from '../../../../../components/Machines/MachineComponents'
const SnakkyBaseButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #78909c;
  color: white;
  border-radius: 5px;
  border: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  margin: 2px;
  min-height: 90px;
  font-weight: 600;
  font-size: xx-large;

  &:active {
    background-color: #61ce8b;
  }
`
const SnakkyBaseButtonContainer = styled(Col)`
  margin: 2px;
  padding: 2px;
`
type Props = {
  products: Array<Object>,
  hasProgrammingButton: boolean,
  onButtonClick?: (key: string) => () => Promise<void>,
}

export default class SnakkyBaseMachineKeyboard extends React.Component<Props> {
  keyboardConfig = []
  constructor(props: Props) {
    super(props)
    this.keyboardConfig = [
      [
        {
          buttonID: '1',
          buttonContent: '1',
        },
        {
          buttonID: '2',
          buttonContent: '2',
        },
        {
          buttonID: '3',
          buttonContent: '3',
        },
      ],
      [
        {
          buttonID: '4',
          buttonContent: '4',
        },
        {
          buttonID: '5',
          buttonContent: '5',
        },
        {
          buttonID: '6',
          buttonContent: '6',
        },
      ],
      [
        {
          buttonID: '7',
          buttonContent: '7',
        },
        {
          buttonID: '8',
          buttonContent: '8',
        },
        {
          buttonID: '9',
          buttonContent: '9',
        },
      ],
      [
        {
          buttonID: '10',
          buttonContent: '0',
        },
        {
          buttonID: '11',
          buttonContent: 'E',
        },
        {
          buttonID: '12',
          buttonContent: 'C',
        },
      ],
    ]
  }
  render() {
    const {
      onButtonClick = (buttonId: string, isSlave: ?boolean) => () => {},
      hasProgrammingButton = true,
      isSlave,
    } = this.props
    return (
      <>
        {hasProgrammingButton && (
          <Row className="machine-lcd-40">
            <Col xs="12" md="12" lg="12" xl="12">
              <GreenMachineButton>
                <i className="fa fa-mobile  mr-1 fa-lg" /> | Programming
              </GreenMachineButton>
            </Col>
          </Row>
        )}

        {this.keyboardConfig.map((configs, lineIndex) => (
          <Row
            key={`machine-line-${lineIndex}`}
            className="justify-content-center"
          >
            {configs.map((config, idx) => (
              <SnakkyBaseButtonContainer
                xs="3"
                md="3"
                lg="3"
                xl="3"
                key={`machine-button-${lineIndex}-${idx}`}
              >
                <SnakkyBaseButton
                  onClick={onButtonClick(config.buttonID, isSlave)}
                >
                  {config.buttonContent}
                </SnakkyBaseButton>
              </SnakkyBaseButtonContainer>
            ))}
          </Row>
        ))}
      </>
    )
  }
}
