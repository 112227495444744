//@flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { RedMachineButton } from '../../../../components/Machines/MachineComponents'
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent'
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard'

type Props = {
  vmID: string,
  history: Object,
  products?: Array<Object>,
}
type States = {}

class LucePortofino extends React.Component<Props, States> {
  keyboardConfig = [
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <span style={{ fontSize: 'medium' }}>NO SUGAR</span> <br />1
          </>
        ),
        buttonID: '1',
      },
      null,
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <span style={{ fontSize: 'medium' }}>EXTRA SUGAR</span> <br />
            13
          </>
        ),
        buttonID: '13',
      },
    ],
    [
      {
        buttonContent: <>2</>,
        buttonID: '2',
      },
      {
        buttonContent: <>8</>,
        buttonID: '8',
      },
      {
        buttonContent: <>14</>,
        buttonID: '14',
      },
    ],
    [
      {
        buttonContent: <>3</>,
        buttonID: '3',
      },
      {
        buttonContent: <>9</>,
        buttonID: '9',
      },
      {
        buttonContent: <>15</>,
        buttonID: '15',
      },
    ],
    [
      {
        buttonContent: <>4</>,
        buttonID: '4',
      },
      {
        buttonContent: <>10</>,
        buttonID: '10',
      },
      {
        buttonContent: <>16</>,
        buttonID: '16',
      },
    ],
    [
      {
        buttonContent: <>5</>,
        buttonID: '5',
      },
      {
        buttonContent: <>11</>,
        buttonID: '11',
      },
      {
        buttonContent: <>17</>,
        buttonID: '17',
      },
    ],
    [
      {
        buttonContent: <>6</>,
        buttonID: '6',
      },
      {
        buttonContent: <>12</>,
        buttonID: '12',
      },
      {
        buttonContent: <>18</>,
        buttonID: '18',
      },
    ],
  ]

  render() {
    return (
      <DirectChooseMachineBaseComponent
        machineTitle="Luce Portofino"
        lineNumber={2}
        lineLength={16}
        keyboard={
          <DirectChooseKeyboard
            keyboardConfig={this.keyboardConfig}
            type="vertical"
            {...this.props}
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(LucePortofino)
