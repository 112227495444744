/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import moment from 'moment';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Button from 'react-bootstrap/Button';
// import '../../DeviceGroups/AddMachineGroupForm/node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import Spinner from '../../../components/UI/Spinner/Spinner';
import FilterBar from '../../FilterBar/FilterBar';
import classes from './HIDList.module.css';
import machineAPI from '../../../services/api/machines';
import customers from '../../../services/api/customers';
import VMUtil from '../../../services/util/vmUtil';
import { dateFromMYSQLTimeStamp } from '../../../services/util/dateUtil';

import EditHID from './EditDialog/EditHID';
import PrintDialog from './PrintDialog/PrintDialog';
import DeviceComponentsHistory from '../DeviceComponentsHistory/DeviceComponentsHistory';
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';
import UserUtil from '../../../services/util/userUtil';

class HIDList extends Component {
	state = { selectedRows: [], currentFilterValue: '' };
	formatterList = {};

	componentDidMount() {
		this.prepareFormatterList();
		this.loadDataFromBackend();
	}

	showEditDialog() {
		this.setState({ showEditDialog: true });
	}

	hideEditDialog() {
		this.setState({ showEditDialog: false });
	}

	showPrintDialog() {
		this.setState({ showPrintDialog: true });
	}

	hidePrintDialog() {
		this.setState({ showPrintDialog: false });
	}

	isMachineDetailsEnabled(row) {
		return row.supportAccessEnabled || !UserUtil.isRoot();
	}

	showVendingMachine(row) {
		const vmID = row.vmID;
		if (this.isMachineDetailsEnabled(row)) {
			this.props.history.push('vms/' + VMUtil.encodeVMID(vmID) + '/detailer');
		}
	}

	showDeviceHistory(row) {
		this.setState({ showDeviceHistory: true, historyMachineID: row.id });
	}

	hideDeviceHistory() {
		this.setState({ showDeviceHistory: false });
	}

	showConfirmationDialog(mi, editedHIDs) {
		this.setState({
			multipleRecordSave: {
				mi,
				editedHIDs
			}
		});
	}

	getFormatterForField(fieldName) {
		if (!this.formatterList.formatters) return null;

		let formatterElement = this.formatterList.formatters.find(formatter =>
			formatter.bindedFieldList.includes(fieldName)
		);
		if (formatterElement) {
			return formatterElement.formatter;
		} else {
			return null;
		}
	}

	formatValue(fieldName, value) {
		const formatter = this.getFormatterForField(fieldName);
		if (formatter) {
			return formatter(value, '');
		} else {
			return value;
		}
	}

	visibleFieldList = [
		'nr',
		'id',
		'name',
		'manufactureDate',
		'hardwareState',
		'firmwareMD5',
		'machineType',
		'customerID',
		'activationDate',
		'softwareVersion'
	];

	prepareFormatterList() {
		this.formatterList = {
			formatters: [
				{
					formatter: this.dateFormatter,
					bindedFieldList: ['manufactureDate', 'activationDate']
				},
				{ formatter: this.customerFormatter, bindedFieldList: ['customerID'] },
				{
					formatter: this.machineTypeFormatter,
					bindedFieldList: ['machineType']
				},
				{ formatter: this.idStateClassFormatter, bindedFieldList: ['idState'] },
				{
					formatter: this.hardwareStateClassFormatter,
					bindedFieldList: ['hardwareState']
				}
			]
		};
	}

	loadDataFromBackend() {
		customers.getCustomers().then(result => {
			this.setState({ customers: [...result.data.response] });
		});
		machineAPI.getHardwareTypes().then(result => {
			this.setState({ hardwareTypes: [...result.data.response] });
		});
		machineAPI.getHardwareStates().then(result => {
			this.setState({ hardwareStates: [...result.data.response] });
		});
		machineAPI.getIDStates().then(result => {
			this.setState({ idStates: [...result.data.response] });
		});
		machineAPI.getHIDHardwares().then(result => {
			let fullMachineList = [...result.data.response];
			fullMachineList = fullMachineList.map((row, index) => {
				return { ...row, nr: index + 1 };
			});
			this.setState({ fullMachineList: fullMachineList }, () =>
				this.filterHardwares()
			);
		});
	}

	filterHardwares() {
		let filteredMachines = [...this.state.fullMachineList];
		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredMachines = this.state.fullMachineList.filter(hardware => {
				let curHardware = { ...hardware };

				this.visibleFieldList.forEach(key => {
					curHardware[key] = this.formatValue(key, curHardware[key]);
				});

				//let userFieldArray = Object.values(curHardware);
				const filterValueForDates = filterValue.replace(/\./g, '. ').trim();
				return this.visibleFieldList.some(
					key =>
						String(curHardware[key])
							.toUpperCase()
							.indexOf(filterValue) >= 0 ||
						String(curHardware[key])
							.toUpperCase()
							.indexOf(filterValueForDates) >= 0
				);
			});
		}

		this.setState({ filteredMachines });
	}

	idStateClassFormatter = (cell, row) => {
		if (this.state.idStates) {
			return this.state.idStates
				.filter(idState => idState.id == cell)
				.map(idState => idState.statename);
		} else {
			return cell;
		}
	};

	hardwareStateClassFormatter = (cell, row) => {
		if (this.state.hardwareStates) {
			return this.state.hardwareStates
				.filter(hardwareState => hardwareState.id == cell)
				.map(hardwareState => hardwareState.statename);
		} else {
			return cell;
		}
	};

	machineTypeFormatter = (cell, row) => {
		if (this.state.hardwareTypes) {
			return this.state.hardwareTypes
				.filter(hardwareType => hardwareType.id == cell)
				.map(hardwareType => hardwareType.machinetype);
		} else {
			return cell;
		}
	};

	customerFormatter = (cell, row) => {
		if (this.state.customers && cell) {
			return this.state.customers
				.filter(customer => customer.cmID == cell)
				.map(customer => customer.company);
		} else {
			return cell;
		}
	};

	dateFormatter = (cell, row) => {
		if (cell) {
			const dateFromTimeStamp = dateFromMYSQLTimeStamp(cell);
			return dateFromTimeStamp.toLocaleDateString();
		} else {
			return cell;
		}
	};

	getHardwareStateColorStyle(fieldValue) {
		let result = {};
		switch (fieldValue) {
			case 1:
				result = {
					...result,
					backgroundColor: '#4dbd74',
					borderColor: '#4dbd74',
					color: 'white'
				};
				break;
			case 2:
				result = {
					...result,
					backgroundColor: '#DB021D',
					borderColor: '#DB021D',
					color: 'white'
				};
				break;
			case 3:
				result = {
					...result,
					backgroundColor: '#7C8797',
					borderColor: '#7C8797',
					color: 'white'
				};
				break;
			default:
				break;
		}

		return result;
	}

	onRowSelect = (row, isSelected) => {
		let selectedRows = [...this.state.selectedRows];
		selectedRows = selectedRows.filter(item => item != row.id);

		if (isSelected) {
			selectedRows = [...selectedRows, row.id];
		}
		this.setState({
			selectedRows
		});
	};

	handleSelectAll = (isSelected, rows) => {
		if (rows && isSelected) {
			this.setState({ selectedRows: rows.map(row => row.id) });
		} else {
			this.setState({ selectedRows: [] });
		}
	};

	onFilterChange(filteValue) {
		this.setState({ currentFilterValue: filteValue }, () =>
			this.filterHardwares()
		);
	}

	getSelectedHIDList() {
		let selectedHID;
		if (this.state.selectedRows.length > 0) {
			selectedHID = this.state.fullMachineList.filter(hid =>
				this.state.selectedRows.includes(hid.id)
			);
		}

		return selectedHID;
	}

	async saveHID(hid, editedHIDList) {
		this.setState({ multipleRecordSave: null });

		for (let targetHID of editedHIDList) {
			let currentHID = {
				...hid,
				id: targetHID.id
			};
			await machineAPI.updateHardware(currentHID);
		}

		this.setState({ showEditDialog: false }, () => {
			this.loadDataFromBackend();
		});
	}

	async printSelectedMIs(printType, numberOfCopies) {
		this.selectedIDs = this.getSelectedHIDList();
		try {
			if (printType == 0) {
				for (let HID of this.selectedIDs) {
					const id = HID.id;

					for (let i = 0; i < numberOfCopies; i++) {
						await machineAPI.printHIDZebraSmall(id);
					}
				}
			} else {
				const ids = this.selectedIDs.map(HID => HID.id);

				await machineAPI.printHIDZebraLarge(ids);
			}

			this.hidePrintDialog();
			toast.info(
				this.props.t(
					'hardwareManagement.hardwareList.common.printingSuccessfull'
				)
			);
		} catch (e) {
			toast.error(
				this.props.t('hardwareManagement.hardwareList.common.printingFailed')
			);
		}
	}

	stateSignFormatter = (cel, row) => {
		let divStyle = { width: '10px', height: '2rem', borderRadius: '5px' };
		if (row.onlineState == 'online') {
			divStyle = { ...divStyle, backgroundColor: 'green' };
		}

		return <div style={divStyle}></div>;
	};

	actionButtonsFormatter = (cell, row) => {
		let showVMStyle = {};
		if (!row.supportAccessEnabled || !row.vmID) {
			showVMStyle = { visibility: 'hidden' };
		}

		return (
			<>
				<Button
					onClick={e => this.showVendingMachine(row)}
					variant="success"
					className={classes.EditButton}
					style={showVMStyle}
				>
					<i className="fa fa-chevron-right" />
				</Button>
				<Button
					onClick={e => this.showDeviceHistory(row)}
					variant="success"
					className={classes.EditButton}
				>
					<i className="fa fa-book" />
				</Button>
			</>
		);
	};

	renderEditHIDDialog() {
		let selectedHID = this.getSelectedHIDList();
		let hidEditValue = selectedHID ? { ...selectedHID[0] } : null;
		if (selectedHID && selectedHID.length > 1) {
			hidEditValue.hardwareState = 0;
			hidEditValue.machineType = 0;
			hidEditValue.customerID = 0;
			hidEditValue.softwareVersion = '';
		}

		return (
			<Modal
				show={this.state.showEditDialog}
				onHide={() => this.hideEditDialog()}
			>
				<Modal.Header>
					<Modal.Title>
						{this.props.t('hardwareManagement.hardwareList.part.editMI')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditHID
						HID={hidEditValue}
						machineTypes={this.state.hardwareTypes}
						hardwareStates={this.state.hardwareStates}
						companies={this.state.customers}
						editedHIDs={selectedHID}
						onSave={(mi, editedHIDs) =>
							editedHIDs.length > 1
								? this.showConfirmationDialog(mi, editedHIDs)
								: this.saveHID(mi, editedHIDs)
						}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	renderSaveConfirmationDialog() {
		if (this.state.multipleRecordSave) {
			return (
				<ConfirmationDialog
					isOpen={this.state.multipleRecordSave}
					onToggle={() => this.setState({ multipleRecordSave: null })}
					title="Confirmation"
					confirmationText={this.props.t(
						'hardwareManagement.hardwareList.common.multipleEditConfirmation'
					)}
					cancelText={this.props.t(
						'hardwareManagement.hardwareList.common.cancel'
					)}
					okText={this.props.t('hardwareManagement.hardwareList.common.ok')}
					onCancel={() => this.setState({ multipleRecordSave: null })}
					onOk={() =>
						this.saveHID(
							this.state.multipleRecordSave.mi,
							this.state.multipleRecordSave.editedHIDs
						)
					}
				/>
			);
		} else {
			return null;
		}
	}

	renderPrintDialog() {
		return (
			<Modal
				show={this.state.showPrintDialog}
				onHide={() => this.hidePrintDialog()}
			>
				<Modal.Header>
					<Modal.Title>
						{this.props.t('hardwareManagement.hardwareList.part.printMI')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PrintDialog
						onPrint={(printType, numberOfCopies) =>
							this.printSelectedMIs(printType, numberOfCopies)
						}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	renderDeviceHistoryDialog() {
		if (this.state.fullMachineList && this.state.historyMachineID) {
			return (
				<Modal
					show={this.state.showDeviceHistory}
					onHide={() => this.hideDeviceHistory()}
				>
					<Modal.Header>
						<Modal.Title>
							{this.props.t('hardwareManagement.hardwareHistory.title')}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<DeviceComponentsHistory
							machineID={this.state.historyMachineID}
							hardwareStates={this.state.hardwareStates}
						/>
					</Modal.Body>
				</Modal>
			);
		}
	}

	renderFilterBar() {
		return (
			<FilterBar
				onFilterChanged={value => this.onFilterChange(value)}
				showEditButton
				onShowEditItem={() => this.showEditDialog()}
				onShowPrintItem={() => this.showPrintDialog()}
				onCheckEditButtonDisabled={() => this.state.selectedRows.length <= 0}
				onCheckPrintButtonDisabled={() => this.state.selectedRows.length <= 0}
			/>
		);
	}

	renderTable() {
		const { t } = this.props;

		const tableOptions = {
			sortIndicator: true,
			hideSizePerPage: false,
			paginationSize: 3,
			sizePerPageList: [10, 15, 25, 50, 100],
			sizePerPage: this.props.recordPerPage || 15,
			withFirstAndLast: true,
			paginationPosition: 'bottom',
			alwaysShowAllBtns: false,
			clearSearch: true,
			hidePageListOnlyOnePage: true,
			sortName: 'nr',
			sortOrder: 'desc',
			searchPanel: this.renderFilterBar,
			onSearchChange: this.onFilterChange
		};

		const tableSelectOptions = {
			mode: 'checkbox',
			clickToSelect: true,
			bgColor: '#2EADD3',
			columnWidth: '10px',
			onSelect: this.onRowSelect,
			onSelectAll: this.handleSelectAll
		};

		return (
			<BootstrapTable
				data={this.state.filteredMachines ? this.state.filteredMachines : null}
				version="4"
				striped
				hover
				pagination={!this.props.hidePager}
				insertRow={false}
				options={tableOptions}
				selectRow={tableSelectOptions}
				multiColumnSearch
				condensed
				bordered={false}
				dataAlign={'center'}
				size="sm"
			>
				<TableHeaderColumn
					width="0"
					dataAlign="left"
					dataField="id"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					dataFormat={this.stateSignFormatter}
				></TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="0"
					dataAlign="center"
					dataField="nr"
					isKey
					hidden="true"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				/>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="id"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.deviceID')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="name"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.connectedVM')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="manufactureDate"
					dataFormat={this.getFormatterForField('manufactureDate')}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.manufactureDate')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="15"
					dataAlign="center"
					dataField="hardwareState"
					dataFormat={this.getFormatterForField('hardwareState')}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					tdStyle={fieldValue => this.getHardwareStateColorStyle(fieldValue)}
				>
					{t('hardwareManagement.hardwareList.HID.state')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="machineType"
					dataFormat={this.getFormatterForField('machineType')}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.machineType')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="customerID"
					dataFormat={this.getFormatterForField('customerID')}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.company')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="activationDate"
					dataFormat={this.getFormatterForField('activationDate')}
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.activationDate')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="softwareVersion"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.softwareVersion')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="orderNumber"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.orderNumber')}
				</TableHeaderColumn>

				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="firmwareName"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.firmwareName')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="firmwareFilename"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.firmwareFilename')}
				</TableHeaderColumn>
				<TableHeaderColumn
					dataSort
					width="30"
					dataAlign="center"
					dataField="microcontrollerType"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
				>
					{t('hardwareManagement.hardwareList.HID.microcontrollerType')}
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20"
					dataAlign="right"
					dataField="id"
					thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
					dataFormat={this.actionButtonsFormatter}
				></TableHeaderColumn>
			</BootstrapTable>
		);
	}

	render() {
		return (
			<>
				{this.renderPrintDialog()}
				{this.renderEditHIDDialog()}
				{this.renderSaveConfirmationDialog()}
				{this.renderDeviceHistoryDialog()}
				{this.renderFilterBar()}

				{this.state.customers &&
				this.state.hardwareTypes &&
				this.state.fullMachineList &&
				this.state.hardwareStates &&
				this.state.idStates ? (
					this.renderTable()
				) : (
					<Spinner />
				)}
			</>
		);
	}
}

export default withRouter(withTranslation()(HIDList));
