import React, { Component } from 'react';

import { Container, Row, Col } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import VMUtil from '../../../../../services/util/vmUtil';

class EventListCardTooltip extends Component {
	state = { curPageIndex: 0 };

	render() {
		const resultString = this.props.screens[this.state.curPageIndex].display
			? this.props.screens[this.state.curPageIndex].display
			: ''.split('<br>').map(line => {
					return <div>{line}</div>;
			  });
		return (
			<Container
				style={{
					padding: '0px',
					marginTop: '-4px',
					marginLeft: '2px'
				}}
			>
				<Row>
					<Col sm="8">
						{this.props.screens[this.state.curPageIndex].datetime}
					</Col>
					<Col sm="4" style={{ padding: '0px' }}>
						<Button
							size="sm"
							variant="success"
							disabled={this.state.curPageIndex === 0}
							style={{ pointerEvents: 'visible' }}
							onClick={() =>
								this.setState({ curPageIndex: this.state.curPageIndex - 1 })
							}
						>
							<i class="fa fa-chevron-left" aria-hidden="true"></i>
						</Button>
						<Button
							size="sm"
							variant="success"
							disabled={
								this.state.curPageIndex === this.props.screens.length - 1
							}
							style={{ pointerEvents: 'visible' }}
							onClick={() =>
								this.setState({ curPageIndex: this.state.curPageIndex + 1 })
							}
						>
							<i class="fa fa-chevron-right" aria-hidden="true"></i>
						</Button>
					</Col>
				</Row>
				<Row style={{ marginTop: '5px' }}>
					<Col sm="12">
						<pre style={{ color: 'white', fontFamily: 'monospace' }}>
							{VMUtil.hex2a(resultString)}
						</pre>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default EventListCardTooltip;
