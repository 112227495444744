import React, { Component } from 'react';
import classes from './DeviceComponentsHistory.module.css';
import { withTranslation } from 'react-i18next';

import machineAPI from '../../../services/api/machines';
import DeviceComponents from './DeviceComponents/DeviceComponents';
import Spinner from '../../../components/UI/Spinner/Spinner';

class DeviceComponentsHistory extends Component {
	state = {};

	componentDidMount() {
		if (!!!this.props.partID) {
			machineAPI
				.getMachinePartHistory(this.props.machineID)
				.then(response => {
					this.setState({ history: response.data.reverse() });
				})
				.catch(() => this.setState({ history: [] }));
		} else {
			machineAPI
				.getPartHistory(this.props.partID)
				.then(response => {
					this.setState({ history: response.data.reverse() });
				})
				.catch(() => this.setState({ history: [] }));
		}
	}

	render() {
		if (!!this.state.history) {
			let historyList = this.state.history.map((item, index) => (
				<DeviceComponents
					key={index}
					historyEvent={item}
					hardwareStates={this.props.hardwareStates}
				/>
			));

			if (historyList.length === 0) {
				historyList = (
					<p>
						{this.props.t('hardwareManagement.hardwareHistory.historyNotFound')}
					</p>
				);
				return <>{historyList}</>;
			} else {
				return <div className={classes.scrollBox}>{historyList}</div>;
			}
		} else {
			return <Spinner />;
		}
	}
}

export default withTranslation()(DeviceComponentsHistory);
