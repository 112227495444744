import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-dates/initialize';
import classes from './TransactionList.module.css';
import { Badge, Row, Col, Input } from 'reactstrap';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import InvisibleTouchutil from '../../../services/util/invisibleTouchUtil';
import SummaryPanel from './SummaryPanel';
import TableToolBar from '../../../components/SalesAndInomeCard/SalesAndIncomeTable/TableToolBar';
import { Button } from 'reactstrap';
import ReactExport from '@ibrahimrahmani/react-export-excel';
import store from '../../../store';
import { setTableData } from '../../../store/table';
import transactionTypes, {
	Transaction
} from '../../../services/util/transactionTypes';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const filterParams = {
	DEVICE: 1,
	EMAIL: 2,
	PRODUCT: 3,
	TRANSACTION: 4,
	STATE: 5
};
class TransactionTable extends Component {
	state = {
		startDate: null,
		endDate: null
	};

	componentDidMount = () => {
		if (this.props.data) {
			store.dispatch(setTableData(JSON.stringify(this.props.data)));

			InvisibleTouchutil.setParamToStore(this.props.data);
			InvisibleTouchutil.getLocalStorage();
		}
	};

	getDetailsSumLabel = param => {
		return <Form.Label id={param}></Form.Label>;
	};

	changeDates = val => {
		this.setState({ startDate: val.startDate, endDate: val.endDate });
	};

	// --------------- Excel export -----------------

	formatExcel = v => {
		let value = [];
		if (v) {
			value = v;
		}
		const { t, filterParamSet } = this.props;
		const header = [
			{
				value: t('device.details.sales.datetime'),
				widthPx: 160,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('invisibleTouchManager.purchasersTable.email'),
				widthPx: 200,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('salesSnadIncome.table.device'),
				widthPx: 180,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('device.details.sales.product'),
				widthPx: 180,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('invisibleTouchManager.purchasesPanel.state'),
				widthPx: 100,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('invisibleTouchManager.purchasesPanel.amount'),
				widthPx: 80,
				style: { font: { sz: '24', bold: true } }
			}
		];
		const val = value.map(e => {
			let el = [];
			if (e) {
				let date = e.insertedAt
					? this.dateFormatter(e.insertedAt)
					: this.props.t('invisibleTouchManager.controlpanel.noData');

				let vmName = e.vmID
					? this.deviceFormater(e.vmID, null, true)
					: this.props.t('invisibleTouchManager.controlpanel.noData');

				let customer = e.user
					? this.customerFormatter(e.user, null, true)
					: this.props.t('invisibleTouchManager.controlpanel.noData');

				let product = e.details
					? this.productFormater(e.details, null, true)
					: this.props.t('invisibleTouchManager.controlpanel.noData');

				let transaction = e.transactionTypeID
					? this.stateFormaterCSV(e.transactionTypeID)
					: this.props.t('invisibleTouchManager.controlpanel.noData');

				let amount = e.amount
					? this.priceFormatter(e.amount, e)
					: this.props.t('invisibleTouchManager.controlpanel.noData');

				el = [
					{ value: date },
					{ value: customer },
					{ value: vmName },
					{ value: product },
					{ value: transaction },
					{ value: amount }
				];
			}

			return el;
		});

		const styledMultiDataSet = [
			{
				columns: header,
				data: val
			}
		];
		return (
			<div>
				<ExcelFile
					element={
						<Button className={classes.DefaultFilterButton}>
							<i className=" fa fa-file mr-2"></i>
							{this.props.t('salesSnadIncome.exportToXLS')}
						</Button>
					}
				>
					<ExcelSheet
						dataSet={styledMultiDataSet}
						name={t('salesSnadIncome.title')}
					/>
				</ExcelFile>
			</div>
		);
	};

	renderExcelExportButton = () => {
		let dataSet = [];
		if (this.props.table && JSON.parse(this.props.table)) {
			dataSet = JSON.parse(this.props.table);
		}
		return this.formatExcel(dataSet);
	};

	// --------------- Excel export -----------------

	createCustomToolBar = props => {
		const getCSVBtn = () => {
			return (
				<Button
					className={classes.DefaultFilterButton}
					onClick={() =>
						props.components.btnGroup.props.children[0].props.onClick()
					}
				>
					<i className="fa fa-download mr-2" />
					{this.props.t('salesSnadIncome.exportToCSV')}
				</Button>
			);
		};

		return (
			<>
				<TableToolBar
					startDate={this.props.startDate}
					endDate={this.props.endDate}
					btnGroup={getCSVBtn()}
					onInputChange={this.props.onDateChange}
					excelExportButton={() => this.renderExcelExportButton()}
					loadButton={this.props.loadButton}
				/>
			</>
		);
	};

	// -------------- Filterbuttons ------------ //
	renderHeaderFilter = (title, value, param) => {
		let noVisibleElements = null;
		noVisibleElements = this.props.elementVisibilities.find(
			e => e.param === param
		);
		return (
			<Row style={{ alignItmes: 'center', justifyContent: 'center' }}>
				{value ? (
					<Badge
						className={
							noVisibleElements
								? classes.ActiveFilterButton
								: classes.FilterButton
						}
						onClick={value}
					>
						<i className="fa fa-search" style={{ margin: '3px' }} />
					</Badge>
				) : (
					undefined
				)}
				{title}
			</Row>
		);
	};
	renderColumnHeader = (title, value, param) => {
		let noVisibleElements = null;
		noVisibleElements = this.props.elementVisibilities.find(
			e => e.param === param
		);
		return (
			<Row
				noGutters
				style={{
					flexDirection: 'column',
					alignItmes: 'center',
					justifyContent: 'center'
				}}
			>
				<Row
					style={{
						alignItmes: 'center',
						justifyContent: 'center'
					}}
				>
					{this.renderHeaderFilter(title, value, param)}
				</Row>
			</Row>
		);
	};

	resetFilter = () => {
		this.props.onResetFilter();
	};

	renderResetFilterButton = (title, value) => {
		return (
			<Button
				className={classes.DefaultFilterButton}
				style={{ width: '100%' }}
				onClick={value}
			>
				<i className="fa fa-search mr-2" />
				{title}
			</Button>
		);
	};

	afterColumnFilter = (filterConds, result) => {
		if (result) {
			store.dispatch(setTableData(JSON.stringify(result)));
			InvisibleTouchutil.setParamToStore(result);
			InvisibleTouchutil.getLocalStorage();
		}
	};

	// -------------- Filterbuttons ------------ //

	// -------------- Dataformaters ------------ //

	stateFormaterCSV = (cell, row) => {
		const { t } = this.props;
		let state = t('invisibleTouchManager.controlpanel.noData');
		let message = [];

		switch (cell) {
			case transactionTypes.PARTNER_CREDIT_ENTRY:
				state = t('invisibleTouchManager.purchasesPanel.massage.PCEntry');
				message = [
					cell && cell.remark
						? cell.remark
						: t('invisibleTouchManager.controlpanel.noData')
				];
				break;
			case transactionTypes.BARION_TRANSACTION_START:
				state = t('invisibleTouchManager.purchasesPanel.barionStart');

				message = [state];
				break;
			case transactionTypes.BARION_TRANSACTION_STARTED:
				state = t('invisibleTouchManager.purchasesPanel.barionStarted');

				message = [state];
				break;
			case transactionTypes.BARION_TRANSACTION_START_FAILED:
				state = t('invisibleTouchManager.purchasesPanel.barionStartFailed');

				message = [state];
				break;
			case transactionTypes.BARION_TRANSACTION_FAILED:
				state = t(
					'invisibleTouchManager.purchasesPanel.barionTransactionFailed'
				);

				message = [state];
				break;
			case transactionTypes.BARION_CREDIT_ENTRY:
				state = t('invisibleTouchManager.purchasesPanel.barionCreditEntry');

				message = [state];
				break;
			case transactionTypes.VENDING_MACHINE_CREDIT_ENTRY:
				state = t('invisibleTouchManager.purchasesPanel.massage.VMCEntry');

				message = [''];
				break;
			case transactionTypes.VENDING_MACHINE_PRODUCT_PURCHASE:
				state = t('invisibleTouchManager.purchasesPanel.success');

				message = [];
				break;
			case transactionTypes.PROMOTION_APPLIED:
				state = t('invisibleTouchManager.purchasesPanel.massage.promApplyed');

				message = [
					cell && cell.typeName
						? cell.typeName
						: t('invisibleTouchManager.controlpanel.noData'),
					cell && cell.promotion && cell.promotion.name
						? cell.promotion.name
						: t('invisibleTouchManager.controlpanel.noData')
				];
				break;
			case transactionTypes.TRANSACTION_WITHDRAWAL:
				state = t('invisibleTouchManager.purchasesPanel.withdrawn');

				message = [
					' VM: ' +
						(row && row.details && row.details.vendingMachineName
							? row.details.vendingMachineName
							: t('invisibleTouchManager.controlpanel.noData'))
				];
				break;
			case transactionTypes.PROMOTION_APPLIED_BONUS_CREDIT:
				state = t('invisibleTouchManager.purchasesPanel.massage.promApplyed');

				message = [
					t('invisibleTouchManager.blancePanel.creditProm'),
					cell && cell.typeName
						? cell.typeName
						: t('invisibleTouchManager.controlpanel.noData'),
					cell && cell.promotion && cell.promotion.name
						? cell.promotion.name
						: t('invisibleTouchManager.controlpanel.noData')
				];
				break;
		}

		return `${state} (${message.join(', ')})`;
	};

	stateFormater = (cell, row) => {
		const { t } = this.props;
		let state = t('invisibleTouchManager.controlpanel.noData');
		let style = { color: '#b27300', backgroundColor: '#fff6e6' };
		let i = 'fa fa-exclamation';
		let message = [];

		switch (cell) {
			case transactionTypes.PARTNER_CREDIT_ENTRY:
				state = t('invisibleTouchManager.purchasesPanel.massage.PCEntry');
				style = {
					color: 'var(--invoice)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-plus-circle';
				message = [''];
				break;
			case transactionTypes.BARION_TRANSACTION_START:
				state = t('invisibleTouchManager.purchasesPanel.barionStart');
				style = {
					color: 'var(--purchases-dark)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-times-circle';
				message = [row && row.id ? row.id : ''];
				break;
			case transactionTypes.BARION_TRANSACTION_STARTED:
				state = t('invisibleTouchManager.purchasesPanel.barionStarted');
				style = {
					color: 'var(--purchases-dark)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-times-circle';
				message = [row && row.id ? row.id : ''];
				break;
			case transactionTypes.BARION_TRANSACTION_START_FAILED:
				state = t('invisibleTouchManager.purchasesPanel.barionStartFailed');
				style = {
					color: 'var(--inv-tuch-error)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-times-circle';
				message = [row && row.id ? row.id : ''];
				break;
			case transactionTypes.BARION_TRANSACTION_FAILED:
				state = t(
					'invisibleTouchManager.purchasesPanel.barionTransactionFailed'
				);
				style = {
					color: 'var(--inv-tuch-error)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-times-circle';
				message = [row && row.id ? row.id : ''];
				break;
			case transactionTypes.BARION_CREDIT_ENTRY:
				state = t('invisibleTouchManager.purchasesPanel.barionCreditEntry');
				style = {
					color: 'var(--balance)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-plus-circle';
				message = [row && row.id ? row.id : ''];
				break;
			case transactionTypes.VENDING_MACHINE_CREDIT_ENTRY:
				state = t('invisibleTouchManager.purchasesPanel.massage.VMCEntry');
				style = { color: 'var(--message)', backgroundColor: 'transparent' };
				i = 'fa fa-plus-circle';
				message = [''];

				break;
			case transactionTypes.VENDING_MACHINE_PRODUCT_PURCHASE:
				state = t('invisibleTouchManager.purchasesPanel.success');
				style = { color: 'var(--balance)', backgroundColor: 'transparent' };
				i = 'fa fa-check-circle';
				message = [''];
				break;
			case transactionTypes.PROMOTION_APPLIED:
				state = t('invisibleTouchManager.purchasesPanel.massage.promApplyed');
				style = { color: 'var(--balance)', backgroundColor: 'transparent' };
				i = 'fa fa-check-circle';
				message = [
					row && row.details && row.details.typeName
						? row.details.typeName
						: t('invisibleTouchManager.controlpanel.noData'),
					row &&
					row.details &&
					row.details.promotion &&
					row.details.promotion.name
						? row.details.promotion.name
						: t('invisibleTouchManager.controlpanel.noData')
				];
				break;
			case transactionTypes.TRANSACTION_WITHDRAWAL:
				state = t('invisibleTouchManager.purchasesPanel.withdrawn');
				style = {
					color: 'var(--purchases-dark)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-exclamation-triangle';

				message = [''];
				break;
			case transactionTypes.PROMOTION_APPLIED_BONUS_CREDIT:
				state = t('invisibleTouchManager.purchasesPanel.massage.promApplyed');
				style = { color: 'var(--balance)', backgroundColor: 'transparent' };
				i = 'fa fa-check-circle';
				message = [
					row && row.details && row.details.typeName
						? row.details.typeName
						: t('invisibleTouchManager.controlpanel.noData'),
					row &&
					row.details &&
					row.details.promotion &&
					row.details.promotion.name
						? row.details.promotion.name
						: t('invisibleTouchManager.controlpanel.noData')
				];
				break;
		}

		return (
			<Col
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItmes: 'center',
					justifyContent: 'center'
				}}
			>
				<Col>
					<Row style={{ justifyContent: 'center' }}>
						<i className={i} style={style} />

						<Badge style={style}>{state}</Badge>
					</Row>
				</Col>
				<Col style={{ fontSize: 'smaller' }}>
					{this.massageFormater(message, style)}
				</Col>
			</Col>
		);
	};

	massageFormater = (massage, style) => {
		let massag = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (massage && massage.length > 0) {
			massag = (
				<Col>
					{massage.map(e => {
						return (
							<Col>
								{' '}
								<Row style={{ justifyContent: 'center' }}>
									<Badge style={style}>{e ? e : ''}</Badge>
								</Row>
							</Col>
						);
					})}
				</Col>
			);
		}

		return massag;
	};

	getCurrency = () => {
		return this.props.currency &&
			this.props.currency[0] &&
			this.props.currency[0].symbol
			? this.props.currency[0].symbol
			: '';
	};

	priceFormatter = (cell, row) => {
		return cell ? `${cell.toFixed(2)} ${this.getCurrency()}` : '';
	};

	deviceFormater = (cell, row) => {
		let data = this.props.t('invisibleTouchManager.controlpanel.noData');

		if (cell && this.props.machines && this.props.machines.length > 0) {
			let ms = this.props.machines.find(ms => ms.vmID === cell);
			if (ms) {
				data = ms.customName;
			}
		}
		return data;
	};

	customerFormatter = (cell, row) => {
		let data = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (cell && cell.email) {
			data = cell.email;
		}
		return data;
	};

	getDeviceStyle = (cell, row) => {
		let style = {
			verticalAlign: 'middle',
			whiteSpace: 'normal',
			fontWeight: 'bold',
			opacity: '0.3'
		};
		if (cell) {
			style = {
				verticalAlign: 'middle',
				whiteSpace: 'normal',
				fontWeight: 'bold'
			};
		}
		return style;
	};

	getCutomerStyle = (cell, row) => {
		let style = {
			verticalAlign: 'middle',
			whiteSpace: 'normal',
			fontWeight: 'bold',
			opacity: '0.3'
		};
		if (cell && (cell.email || cell.productName)) {
			style = {
				verticalAlign: 'middle',
				whiteSpace: 'normal',
				fontWeight: 'bold'
			};
		}
		return style;
	};
	productFormater = (cell, row, excel) => {
		console.log(row);
		let data = this.props.t('invisibleTouchManager.controlpanel.noData');

		if (cell && cell.productName) {
			data = cell.productName;
		}
		return data;
	};

	dateFormatter = cell => {
		return moment(cell).format('YYYY. MM. DD. HH:mm:ss');
	};

	rowClassNameFormat = row => {
		let className = classes.CreditRow;

		if (row.transactionTypeID === 7 || row.transactionTypeID === 9) {
			className = classes.ManualRow;
		}

		return className;
	};

	idFormater = () => {
		return ' ';
	};

	// -------------- Dataformaters ------------ //

	//Render
	render() {
		const { t } = this.props;

		//Table options
		const tableOptions = {
			sortIndicator: true,
			hideSizePerPage: true,
			sizePerPage: [10],
			withFirstAndLast: true,
			alwaysShowAllBtns: true,
			pageStartIndex: 1,
			paginationPosition: 'bottom',
			paginationSize: 3,
			defaultSortName: 'insertedAt',
			defaultSortOrder: 'desc',
			toolBar: this.createCustomToolBar,
			afterColumnFilter: this.afterColumnFilter
		};
		const textFilterOptions = {
			type: 'TextFilter',
			placeholder: t('filterToolbar.filterPlaceholder'),
			style: {
				marginTop: '20px',
				borderRadius: '5px',
				borderColor: 'var(--control-mid)'
			}
		};

		const priceFilterOptions = {
			type: 'TextFilter',
			placeholder: t('filterToolbar.filterPlaceholder'),
			condition: 'eq',
			style: {
				marginTop: '20px',
				borderRadius: '5px',
				borderColor: 'var(--control-mid)'
			}
		};

		return (
			<>
				<BootstrapTable
					data={this.props.data.filter(e => !e.billingStateID)}
					version="4"
					pagination
					options={tableOptions}
					bordered={false}
					condensed
					size="sm"
					trClassName={this.rowClassNameFormat}
					tableHeaderClass={classes.TableHeaderClass}
					headerContainerClass={classes.HeaderContainerClass}
					exportCSV
				>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.datetime')}
						csvFormat={this.dateFormatter}
						width="100"
						dataAlign="center"
						dataField="insertedAt"
						thStyle={{
							borderColor: 'transparent',
							verticalAlign: 'middle'
						}}
						dataFormat={this.dateFormatter}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{this.renderColumnHeader(
							t('device.details.sales.datetime'),
							undefined,
							undefined
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						isKey
						csvHeader={t('invisibleTouchManager.purchasesPanel.account')}
						csvFormat={this.customerFormatter}
						filter={textFilterOptions}
						dataAlign="center"
						width="0"
						dataField="index"
						hidden="true"
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{t('invisibleTouchManager.purchasesPanel.account')}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('invisibleTouchManager.purchasersTable.email')}
						csvFormat={this.customerFormatter}
						dataAlign="center"
						width="300"
						dataField="user"
						dataFormat={this.customerFormatter}
						thStyle={{
							borderColor: 'transparent',
							verticalAlign: 'middle'
						}}
						tdStyle={this.getCutomerStyle}
					>
						{this.renderColumnHeader(
							t('invisibleTouchManager.purchasersTable.email'),
							this.props.showEmailFilterDialog,
							filterParams.EMAIL
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('salesSnadIncome.table.device')}
						width="300"
						dataAlign="center"
						dataField="vmID"
						dataFormat={this.deviceFormater}
						csvFormat={this.deviceFormater}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={this.getDeviceStyle}
					>
						{this.renderColumnHeader(
							t('salesSnadIncome.table.device'),
							this.props.showDeviceFilterDialog,
							filterParams.DEVICE
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.product')}
						dataAlign="center"
						width="150"
						dataField="details"
						dataFormat={this.productFormater}
						csvFormat={this.productFormater}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={this.getCutomerStyle}
					>
						{this.renderColumnHeader(
							t('device.details.sales.product'),
							this.props.showProductFilterDialog,
							filterParams.PRODUCT
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('salesSnadIncome.table.transactionType')}
						dataField="transactionTypeID"
						thStyle={{
							borderColor: 'transparent',
							verticalAlign: 'middle'
						}}
						dataAlign="center"
						dataFormat={this.stateFormater}
						csvFormat={this.stateFormaterCSV}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
						width="200"
					>
						{this.renderColumnHeader(
							t('salesSnadIncome.table.transactionType'),
							this.props.showStateFilterDialog,
							filterParams.STATE
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('invisibleTouchManager.purchasesPanel.amount')}
						csvFormat={this.priceFormatter}
						dataAlign="center"
						width="100"
						dataField="amount"
						dataFormat={this.priceFormatter}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{this.renderColumnHeader(
							t('invisibleTouchManager.purchasesPanel.amount'),
							undefined,
							undefined
						)}
					</TableHeaderColumn>

					<TableHeaderColumn
						csvHeader={t('device.details.sales.id')}
						width="100"
						dataField="id"
						dataFormat={this.idFormater}
						dataAlign="center"
						thStyle={{
							borderColor: 'transparent',
							paddingRight: '15px'
						}}
						tdStyle={{ verticalAlign: 'middle' }}
					>
						{this.renderResetFilterButton(
							this.props.t('salesSnadIncome.reset'),
							this.resetFilter
						)}
					</TableHeaderColumn>
				</BootstrapTable>
				<SummaryPanel
					transactionSum={this.getDetailsSumLabel('ALL_COUNT')}
					cashBalanceCount={this.getDetailsSumLabel(
						'VENDING_MACHINE_CREDIT_ENTRY_COUNT' // Transaction type plus sum or count
					)}
					cashBalanceSum={this.getDetailsSumLabel(
						'VENDING_MACHINE_CREDIT_ENTRY_SUM'
					)}
					barionBalanceCount={this.getDetailsSumLabel(
						'BARION_CREDIT_ENTRY_COUNT'
					)}
					barionBalacnceSum={this.getDetailsSumLabel('BARION_CREDIT_ENTRY_SUM')}
					purchaseSum={this.getDetailsSumLabel(
						'VENDING_MACHINE_PRODUCT_PURCHASE_SUM'
					)}
					purchaseCount={this.getDetailsSumLabel(
						'VENDING_MACHINE_PRODUCT_PURCHASE_COUNT'
					)}
					withdrawnTransactionCount={this.getDetailsSumLabel(
						'TRANSACTION_WITHDRAWAL_COUNT'
					)}
					withdrawnTransactionSum={this.getDetailsSumLabel(
						'TRANSACTION_WITHDRAWAL_SUM'
					)}
					currency={this.getCurrency()}
				/>
			</>
		);
	}
}

const mapStateToProps = state => ({
	table: state.table
});
export default connect(mapStateToProps)(withTranslation()(TransactionTable));
