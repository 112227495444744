//@flow
import React from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	ListGroupItem,
	Row,
	ListGroup
} from 'reactstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import MachinaBaseComponent from '../MachinaBaseComponent';
import classes from './AlphanumericSlaveComponent.module.css';

class AlphanumericMachineSlaveComponent extends MachinaBaseComponent {
	getStringFromSlaveMachineIndex(index) {
		const machineTypeString = this.props.machine.slaves[index].machineType;
		const machinePrefixString = this.props.machine.slaves[index].prefix;
		return `${machineTypeString}  prefix: ${machinePrefixString}`;
	}

	handleDropdownClick(index) {
		this.props.onSlaveIndexChange(index);
	}

	render() {
		const dropDownItems = this.props.machine.slaves
			? this.props.machine.slaves.map((machineType, index) => {
					return (
						<Dropdown.Item
							onClick={() => this.handleDropdownClick(index)}
							className="btn-block"
							block="true"
						>{`${this.getStringFromSlaveMachineIndex(index)}`}</Dropdown.Item>
					);
			  })
			: null;

		const dropdown = (
			<Dropdown menuAlign={{ xs: 'right', lg: 'right' }}>
				<Dropdown.Toggle
					variant="success"
					id="dropdown-basic"
					className="btn-block"
					block="true"
				>
					{this.getStringFromSlaveMachineIndex(this.props.selectedSlaveIndex)}
				</Dropdown.Toggle>
				<Dropdown.Menu>{dropDownItems}</Dropdown.Menu>
			</Dropdown>
		);

		return (
			<Card className={`${classes.SlaveKeyboardContainer} klix-bg`}>
				<CardHeader className="border-dark bg-dark color-light" id="cardHeight">
					{this.props.machine.slaves.length <= 1 ? (
						<h3 id="lcdLabel">{this.getStringFromSlaveMachineIndex(0)}</h3>
					) : (
						dropdown
					)}
				</CardHeader>
				<CardBody className="klix-bg">
					<Row className={classes.RelativeRow}>
						{this.props.remoteControlEnabled ? null : (
							<div className={classes.Overlay}></div>
						)}
						<Col xs="12">
							{
								<this.props.keyboard.type
									{...this.props.keyboard.props}
									onButtonClick={this.bntPressHandler}
								/>
							}
						</Col>
					</Row>
				</CardBody>
			</Card>
		);
	}
}

export default AlphanumericMachineSlaveComponent;
