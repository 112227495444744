import React, { Component } from 'react';
import { Card, Row, CardBody, CardTitle } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import FilterBar from '../../views/FilterBar/FilterBar';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import AddCustomerDialog from './AddCustomerDialog/AddCustomerDialog';
import EditCostumerDialog from './EditCostumerDialog/EditCostumerDialog';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';
import { withTranslation } from 'react-i18next';
import CustomerListTable from './CostumerListTable/CostumerListTable';
import HelperTooltip from '../../components/UI/HelperTooltip/HelperTooltip';
import clientApi from '../../services/api/client';

class CustomerList extends Component {
	state = {
		customers: []
	};

	componentDidMount() {
		this.loadData();
	}

	loadData = () => {
		clientApi.getClients().then(res =>
			this.setState(
				{
					customers:
						res.data &&
						res.data.partnerClients &&
						res.data.partnerClients.length > 0
							? res.data.partnerClients
							: []
				},
				() => this.filterCustomers()
			)
		);
	};

	//Render Filterbar with ADDButton
	renderFilterBar = () => {
		return (
			<FilterBar
				key="filterbar"
				showAddButton
				onShowCreateItem={() => this.handleAddButtonClick()}
				onFilterChanged={value => this.onFilterChange(value)}
			/>
		);
	};

	filterCustomers() {
		let filteredCustomers = [...this.state.customers];
		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredCustomers = this.state.customers.filter(customers => {
				let curCustomers = { ...customers };
				let CostumerFieldArray = Object.values(curCustomers);
				return CostumerFieldArray.some(
					val =>
						String(val)
							.toUpperCase()
							.indexOf(filterValue) >= 0
				);
			});
		}

		this.setState({ filteredCustomers: filteredCustomers });
	}

	onFilterChange(filteValue) {
		this.setState({ currentFilterValue: filteValue }, () =>
			this.filterCustomers()
		);
	}

	handleAddButtonClick = () => {
		this.showAddDialog();
	};

	//AddCostumer rendering and handleing

	showAddDialog() {
		this.setState({ showAddCustomerDialog: true });
	}

	hideAddDialog() {
		this.setState({ showAddCustomerDialog: false });
		this.loadData();
	}

	renderAddCostumerrDialog() {
		return (
			<Modal
				show={this.state.showAddCustomerDialog}
				onHide={() => this.hideAddDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i
							class="icon-people"
							style={{ marginRight: '10px', width: '640px' }}
						></i>
						{this.props.t('customerlist.addbutton.label')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AddCustomerDialog onHide={() => this.hideAddDialog()} />
				</Modal.Body>
			</Modal>
		);
	}

	//editProfil------------------------------------------
	editProfil = customer => {
		this.showEditDialog(customer);
	};

	showEditDialog(customer) {
		this.setState({ showEditCostumerDialog: true, costumerToEdit: customer });
	}

	hideEditDialog() {
		this.setState({ showEditCostumerDialog: false, costumerToEdit: null });
		this.loadData();
	}

	onDeleteUser = customer => {
		this.setState({ customerToDelete: customer });
	};

	onDeleteUserConfirmed = () => {
		clientApi
			.deleteClient(this.state.customerToDelete.id)
			.then(customers => this.loadData());
	};

	//render DeletConfirmation
	renderDeleteConfirmation() {
		if (this.state.customerToDelete) {
			return (
				<ConfirmationDialog
					isOpen={this.state.customerToDelete}
					onToggle={() => this.setState({ customerToDelete: null })}
					title={this.props.t('customerlist.deletebutton.title')}
					confirmationText={this.props.t('customerlist.deletebutton.label')}
					cancelText={this.props.t('customerlist.deletebutton.cancel')}
					okText={this.props.t('customerlist.deletebutton.ok')}
					onCancel={() => this.setState({ customerToDelete: null })}
					onOk={() => this.onDeleteUserConfirmed()}
				/>
			);
		} else {
			return null;
		}
	}

	// render EditDialog
	renderEditUserDialog() {
		return (
			<Modal
				show={this.state.showEditCostumerDialog}
				onHide={() => this.hideEditDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i
							class="icon-people"
							style={{ marginRight: '10px', width: '640px' }}
						></i>
						{this.props.t('customerlist.editbutton.label')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: 'GhostWhite' }}>
					<EditCostumerDialog
						customer={this.state.costumerToEdit}
						onHide={() => this.hideEditDialog()}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	renderTable() {
		return (
			<CustomerListTable
				data={this.state.filteredCustomers}
				onDeleteUser={this.onDeleteUser}
				editProfil={this.editProfil}
				renderFilterBar={this.renderFilterBar}
				onFilterChange={this.onFilterChange}
			/>
		);
	}

	//Render Title
	renderTitle() {
		const { titleOnClick = () => {} } = this.props;

		return (
			<Row style={{ alignItems: 'flex-start' }}>
				<CardTitle
					className="text-muted"
					style={{ cursor: 'pointer' }}
					onClick={titleOnClick}
				>
					<i
						className={`icon-people`}
						style={{ marginRight: '10px', marginLeft: '20px' }}
					/>
					{this.props.t('customerlist.label')}
				</CardTitle>
				<HelperTooltip
					height={'100px'}
					width={'600px'}
					direction={'right'}
					id={'PromotionManagerHelperTooltip'}
					title={this.props.t('customerlist.tooltip.title')}
					content={this.props.t('customerlist.tooltip.content')}
				></HelperTooltip>
			</Row>
		);
	}

	//Render!
	render() {
		return (
			<div className="animated fadeIn">
				<DashboardCardContainerWithFlow>
					<Card body>
						<PageTiltleCard title={this.props.t('customerlist.title')} />
						{this.renderEditUserDialog()}
						{this.renderAddCostumerrDialog()}
						{this.renderDeleteConfirmation()}
						<Card className={this.props.className} style={this.props.style}>
							<CardBody>
								{this.renderTitle()}
								{this.renderTable()}
							</CardBody>
						</Card>
					</Card>
				</DashboardCardContainerWithFlow>
			</div>
		);
	}
}

export default withTranslation()(CustomerList);
