import React, { Component } from 'react';
import { Card, Col, Row, Input } from 'reactstrap';
import {
	Section,
	SectionList,
	SectionSmallTitle,
	SectionSubTitle,
	SectionTitle,
	SectionText
} from '../../../../components/Section';
import { withTranslation } from 'react-i18next';
import EditButton from './EditButton';
import machineAPI from '../../../../services/api/machines';
import classes from './Datasheet.module.css';

class AutoInfCard extends Component {
	state = {
		saveInProgress: false,
		editMode: null,
		machine: null
	};
	componentDidMount() {
		this.loadData();
	}
	loadData = () => {
		let machine = { ...this.props.machine };
		let activeClient = null;
		if (
			machine &&
			machine.partnerClientID &&
			this.props.clients &&
			this.props.clients.length > 0
		) {
			activeClient = this.props.clients.find(
				e => e.id === machine.partnerClientID
			);
		}
		let clientOptions = [{ label: '', value: '' }];
		if (this.props.clients && this.props.clients.length > 0) {
			const options = this.props.clients.map(clients => {
				return { label: clients.companyName, value: clients.id };
			});
			clientOptions = [...clientOptions, ...options];
		}
		this.setState({
			machine: machine,

			clientOptions,
			activeClient
		});
	};

	getClientOptions = () => {
		const { activeClient } = this.state;
		return this.state.clientOptions.map((e, index) => (
			<option
				key={e.label + index}
				label={e.label}
				value={e.value}
				selected={
					activeClient && activeClient.id ? e.value == activeClient.id : ''
				}
			>
				{e.label}
			</option>
		));
	};
	setCurrentSelectedClient = e => {
		const selectedClient = this.props.clients.find(
			clientOption => e.target.value == clientOption.id
		);
		this.setState({
			activeClient: selectedClient
		});
	};
	inputChangeHandler = event => {
		let machine = { ...this.state.machine };
		machine[event.target.name] = event.target.value;
		this.setState({ machine: machine });
	};

	handleSave = async () => {
		this.setState({ saveInProgress: true });
		const { machine = {}, activeClient } = this.state;
		const { vmID = '' } = machine;
		let lng = machine.lng ? machine.lng : '';
		let lat = machine.lat ? machine.lat : '';
		const partnerClientID =
			activeClient && activeClient.id ? activeClient.id : null;
		await machineAPI.updateVendingMachine(
			vmID,
			machine.customName,
			machine.country,
			machine.zipcode,
			machine.city,
			machine.address,
			lng,
			lat,
			machine.vendorName,
			partnerClientID
		);

		this.setState({ saveInProgress: false, editMode: false });
	};
	handleEdit() {
		this.loadData();
		this.setState({ saveInProgress: false, editMode: false });
	}
	render() {
		const { t, root, companyAdmin, regionalAdmin } = this.props;

		const {
			editMode,
			saveInProgress,
			machine,
			activeClient,
			clientOptions
		} = this.state;
		if (!machine) return null;
		return (
			<Card>
				<SectionList>
					<Section
						className={classes.TitleRow}
						style={{ borderRadius: '10px 10px 0px 0px' }}
					>
						<Row>
							<Col xs={12} sm={12} md={6} lg={6}>
								<SectionTitle>
									{t('device.details.datasheet.autoinf')}
								</SectionTitle>
							</Col>
							{root || companyAdmin ? (
								<Col xs={12} sm={12} md={6} lg={6}>
									<EditButton
										onEdit={() => this.setState({ editMode: true })}
										onHandleEdit={() => this.handleEdit()}
										onHandleSave={this.handleSave}
										editMode={editMode}
										saveInProgress={saveInProgress}
										machine={machine}
									></EditButton>
								</Col>
							) : null}
						</Row>
					</Section>
					<Row>
						<Col xs={12} sm={12} md={6} lg={6}>
							<Section className={classes.SubTitleRow}>
								<SectionSubTitle>
									{t('device.details.datasheet.mainData')}
								</SectionSubTitle>
							</Section>
							<Section last>
								<Section last>
									<SectionSmallTitle>
										{t('device.details.datasheet.vendor')}
									</SectionSmallTitle>
									{editMode ? (
										<SectionText last>
											<Input
												name={'vendorName'}
												value={machine.vendorName}
												onChange={this.inputChangeHandler}
											></Input>
										</SectionText>
									) : (
										<SectionText>
											{machine.vendorName
												? machine.vendorName
												: t('promotionManager.promotionTable.noData')}
										</SectionText>
									)}
								</Section>
								<Section last>
									<SectionSmallTitle>
										{t('device.details.type')}
									</SectionSmallTitle>
									<SectionText>
										{machine.machine
											? machine.machine
											: t('promotionManager.promotionTable.noData')}
									</SectionText>
								</Section>
								<Section last>
									<SectionSmallTitle>
										{t('device.details.name')}
									</SectionSmallTitle>
									{editMode ? (
										<SectionText last>
											<Input
												name={'customName'}
												value={machine.customName}
												onChange={this.inputChangeHandler}
											></Input>
										</SectionText>
									) : (
										<SectionText>
											{machine.customName
												? machine.customName
												: t('promotionManager.promotionTable.noData')}
										</SectionText>
									)}
								</Section>
								<Section last>
									<SectionSmallTitle>
										{t('devicelist.tableheader.devicePartnerid')}
									</SectionSmallTitle>
									<SectionText last>{machine.name}</SectionText>
								</Section>
							</Section>
						</Col>
						<Col xs={12} sm={12} md={6} lg={6}>
							<Section className={classes.SubTitleRow}>
								<SectionSubTitle>
									{t('device.details.datasheet.placement')}
								</SectionSubTitle>
							</Section>
							<Section last>
								<Section last>
									<SectionSmallTitle>
										{t('device.details.datasheet.costumerName')}
									</SectionSmallTitle>
									{editMode ? (
										<SectionText last>
											<Input
												id="selectedClient"
												type="select"
												name="selectedClient"
												placeholder={
													activeClient && activeClient.companyName
														? activeClient.companyName
														: ''
												}
												onChange={e => this.setCurrentSelectedClient(e)}
											>
												{clientOptions ? this.getClientOptions() : null}
											</Input>
										</SectionText>
									) : (
										<SectionText>
											{activeClient && activeClient.companyName
												? activeClient.companyName
												: t('promotionManager.promotionTable.noData')}
										</SectionText>
									)}
								</Section>
								<Section last>
									<SectionSmallTitle>
										{t('device.details.country')}
									</SectionSmallTitle>
									{editMode ? (
										<SectionText last>
											<Input
												name={'country'}
												value={machine.country}
												onChange={this.inputChangeHandler}
											></Input>
										</SectionText>
									) : (
										<SectionText>
											{' '}
											{machine.country
												? machine.country
												: t('promotionManager.promotionTable.noData')}
										</SectionText>
									)}
								</Section>
								<Section last>
									<SectionSmallTitle>
										{t('device.details.zipcode')}
									</SectionSmallTitle>
									{editMode ? (
										<SectionText last>
											<Input
												name={'zipcode'}
												value={machine.zipcode}
												onChange={this.inputChangeHandler}
											></Input>
										</SectionText>
									) : (
										<SectionText>
											{machine.zipcode
												? machine.zipcode
												: t('promotionManager.promotionTable.noData')}
										</SectionText>
									)}
								</Section>
								<Section last>
									<SectionSmallTitle>
										{t('device.details.city')}
									</SectionSmallTitle>
									{editMode ? (
										<SectionText last>
											<Input
												name={'city'}
												value={machine.city}
												onChange={this.inputChangeHandler}
											></Input>
										</SectionText>
									) : (
										<SectionText>
											{machine.city
												? machine.city
												: t('promotionManager.promotionTable.noData')}
										</SectionText>
									)}
								</Section>
								<Section last>
									<SectionSmallTitle>
										{t('device.details.address')}
									</SectionSmallTitle>
									{editMode ? (
										<SectionText last>
											<Input
												name={'address'}
												value={machine.address}
												onChange={this.inputChangeHandler}
											></Input>
										</SectionText>
									) : (
										<SectionText last>
											{machine.address
												? machine.address
												: t('promotionManager.promotionTable.noData')}
										</SectionText>
									)}
								</Section>
							</Section>
						</Col>
					</Row>
				</SectionList>
			</Card>
		);
	}
}

export default withTranslation()(AutoInfCard);
