import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class AddCampaningDialog extends Component {
	state = {};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	onSubmitHandler = () => {
		//too do
	};

	render() {
		return (
			<Form onSubmit={this.onSubmitHandler}>
				<Form.Group controlId="id">
					<Form.Label></Form.Label>
					<Form.Control
						name="id"
						type="text"
						placeholder="..."
						value={this.state.id}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>
				<Form.Group controlId="companyName">
					<Form.Label>{'...'}</Form.Label>
					<Form.Control
						name="companyName"
						type="text"
						placeholder={'...'}
						value={this.state.companyName}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="location">
					<Form.Label>{'...'}</Form.Label>
					<Form.Control
						name="location"
						type="text"
						placeholder={'...'}
						value={this.state.location}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="taxNuber">
					<Form.Label>{'...'}</Form.Label>
					<Form.Control
						name="taxNuber"
						type="text"
						placeholder={'...'}
						value={this.state.taxNuber}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="accountNumber">
					<Form.Label>{'...'}</Form.Label>
					<Form.Control
						name="accountNumber"
						type="text"
						placeholder={'...'}
						value={this.state.accountNumber}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="contactPerson">
					<Form.Label>{'...'}</Form.Label>
					<Form.Control
						name="contactPerson"
						type="text"
						placeholder={'...'}
						value={this.state.contactPerson}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="contactEmail">
					<Form.Label>{'...'}</Form.Label>
					<Form.Control
						name="contactEmail"
						type="text"
						placeholder={'...'}
						value={this.state.contactEmail}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Form.Group controlId="contactPhone">
					<Form.Label>{'...'}</Form.Label>
					<Form.Control
						name="contactPhone"
						type="text"
						placeholder={'...'}
						value={this.state.contactPhone}
						onChange={this.inputChangeHandler}
					/>
				</Form.Group>

				<Button variant="primary" type="submit">
					{this.props.t('customerlist.addbutton.savebutton')}
				</Button>
			</Form>
		);
	}
}

export default withTranslation()(AddCampaningDialog);
