import { Button, Col, Row, Card } from 'reactstrap';
import ConfimationDialog from '../../../ConfirmationDialog/ConfirmationDialog';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import classes from '../DeviceList.module.css';
import machineApi from '../../../../services/api/machines';
import {
	Section,
	SectionTitle,
	SectionList
} from '../../../../components/Section';

class DeleteDeviceButton extends Component {
	state = {
		confirmationVisible: false
	};

	deleteMachine = () => {
		machineApi
			.deleteMachine(this.props.vmID)
			.then(res =>
				this.props.root
					? this.props.history.push('/partnerManagement')
					: this.props.history.push('/vms')
			);
	};

	hideConfirmationDialog() {
		this.setState({
			confirmationVisible: false
		});
	}

	showConfirmation() {
		this.setState({
			confirmationVisible: true
		});
	}

	renderConfirmationDialog = () => {
		return (
			<ConfimationDialog
				title={this.props.t('device.details.delete.title')}
				confirmationText={
					<>
						<Col>{this.props.t('device.details.delete.text')}</Col>
						<Col>
							<Row>
								<Col>{this.props.t('device.details.delete.name')}</Col>
								{this.props.machine.customName ? (
									<Col>
										<label style={{ fontWeight: 'bold' }}>
											{this.props.machine.customName}
										</label>
									</Col>
								) : null}
							</Row>
						</Col>
					</>
				}
				okText={this.props.t('device.details.invisibleTouch.ok')}
				cancelText={this.props.t('device.details.invisibleTouch.cancel')}
				isOpen={this.state.confirmationVisible}
				onToggle={() => {
					this.hideConfirmationDialog();
				}}
				onCancel={() => {
					this.hideConfirmationDialog();
				}}
				onOk={() => {
					this.hideConfirmationDialog();
					this.deleteMachine();

					//this.props.onDelet(this.props.peripherial.partID);
				}}
			/>
		);
	};

	deleteDeviceButton = () => {
		return (
			<Button
				{...this.props}
				color="primary"
				className={classes.DeleteButton}
				onClick={() => this.showConfirmation()}
			>
				<i className="fa fa-trash mr-2" /> {this.props.t('devicelist.delete')}
			</Button>
		);
	};
	render() {
		return (
			<Card>
				{this.renderConfirmationDialog()}
				<SectionList>
					<Section>
						<SectionTitle>{this.props.t('devicelist.delete')}</SectionTitle>
					</Section>
					<Col>{this.deleteDeviceButton()}</Col>
				</SectionList>
			</Card>
		);
	}
}
export default withTranslation()(DeleteDeviceButton);
