import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import promotionsUtil from '../../../../services/util/promotionsUtil';

import classes from '../PromotionTypeSelector/PromotionTypeSelector.module.css';
import { withTranslation } from 'react-i18next';

const TimePeriodLimit = props => {
	const { t } = props;

	const typeOptions = Object.keys(promotionsUtil.promotionLimitType).map(
		key => (
			<option value={promotionsUtil.promotionLimitType[key]}>
				{t('promotionManager.addPromotion.timePeriod.' + key)}
			</option>
		)
	);

	return (
		<Form.Group controlId="timePeriodLimit">
			<Col>
				<Row style={{ alignItems: 'center' }}>
					<Col lg={6}>
						<Form.Group>
							<Form.Label className={classes.Label}>
								{t('promotionManager.addPromotion.timePeriod.title')}
							</Form.Label>
							<Form.Control
								type="select"
								as="select"
								value={props.timePeriodType}
								onChange={e => {
									props.onTimePeriodTypeChange(e.target.value);
								}}
							>
								{typeOptions}
							</Form.Control>
						</Form.Group>
					</Col>
					{props.timePeriodType &&
					props.timePeriodType !=
						promotionsUtil.promotionLimitType.NOTLIMITED ? (
						<Col lg={6}>
							<Form.Group>
								<Form.Label className={classes.Label}>
									{t(
										'promotionManager.addPromotion.timePeriodApplicationLimit.title'
									)}
								</Form.Label>
								<Form.Control
									type="input"
									value={props.timePeriodValue}
									onChange={e => {
										props.onTimePeriodValueChange(e.target.value);
									}}
								></Form.Control>
							</Form.Group>
						</Col>
					) : null}
				</Row>
			</Col>
		</Form.Group>
	);
};

export default withTranslation()(TimePeriodLimit);
