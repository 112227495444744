import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import { Card, CardBody } from 'reactstrap';
// import '../../views/DeviceGroups/AddMachineGroupForm/node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import classes from './PartnerManagement.module.css';

import CustomersApi from '../../services/api/customers';
import UsersApi from '../../services/api/users';
import SystemApi from '../../services/api/system';

import Customer from '../../views/Customer/Customer';
import FilterBar from '../../views/FilterBar/FilterBar';
import CustomerGeneral from '../../views/Customer/General/CustomerGeneral';
import InviteUserDialog from '../../views/UserList/InviteUserDialog/InviteUserDialog';
import Spinner from '../../components/UI/Spinner/Spinner';

class PartnerManagement extends Component {
	state = {
		customers: null,
		showInviteDialog: false,
		showInviteUserDialog: false,
		createdPartner: null,
		filteredCustomers: null,
		currentFilterValue: '',
		currencies: null
	};

	filterTimer = null;

	componentDidMount() {
		SystemApi.getCurrencies().then(res => {
			this.setState({ currencies: res.data.currencies });
		});
		this.refreshPartnerList();
	}

	refreshPartnerList() {
		this.setState({ customers: null });
		CustomersApi.getCustomers().then(res => {
			let customers = [...res.data.response];
			customers.sort((a, b) => a.company.localeCompare(b.company));
			this.setState({ customers: customers });
			this.filterCustomers();
		});
	}

	filterCustomers() {
		let filteredCustomers = this.state.customers
			? [...this.state.customers]
			: [];
		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredCustomers = this.state.customers.filter(
				item => item.company.toUpperCase().indexOf(filterValue) >= 0
			);
		}

		this.setState({ filteredCustomers });
	}

	changeFilter(filterValue) {
		this.setState({ currentFilterValue: filterValue }, () =>
			this.filterCustomers()
		);
	}

	savePartnerAndShowUserInviteDialog(partner) {
		this.hideCreatePartnerDialog();
		CustomersApi.createCustomer(partner).then(response => {
			let newPartner = { ...partner };
			newPartner.cmID = response.data.cmID;
			this.setState({ createdPartner: newPartner });

			this.refreshPartnerList();
			this.showInviteDialog();
		});
	}

	inviteUser(personToInvite) {
		this.hideInviteDialog();
		UsersApi.inviteUser(personToInvite).then(() => {
			this.showInviteInfoToast();
		});
	}

	showCreatePartnerDialog() {
		this.setState({ showCreatePartnerDialog: true });
	}

	hideCreatePartnerDialog() {
		this.setState({ showCreatePartnerDialog: false });
	}

	renderCreatePartnerDialog() {
		return (
			<Modal
				show={this.state.showCreatePartnerDialog}
				onHide={() => this.hideCreatePartnerDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.props.t('partnerManagement.messages.createPartner')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<CustomerGeneral
						showSaveButton
						currencies={this.state.currencies}
						onSave={newPartner => {
							this.savePartnerAndShowUserInviteDialog(newPartner);
						}}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	showInviteDialog() {
		this.setState({ showInviteUserDialog: true });
	}

	hideInviteDialog() {
		this.setState({ showInviteUserDialog: false });
	}

	renderInviteUserDialog() {
		if (this.state.createdPartner) {
			return (
				<Modal
					show={this.state.showInviteUserDialog && this.state.createdPartner}
					onHide={() => this.hideInviteDialog()}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{this.props.t('partnerManagement.messages.inviteUser')}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<InviteUserDialog
							cmID={this.state.createdPartner.cmID}
							email=""
							userClass="class2"
							onSave={personToInvite => this.inviteUser(personToInvite)}
						/>
					</Modal.Body>
				</Modal>
			);
		} else return null;
	}

	showInviteInfoToast() {
		this.setState({ showInviteInfoToast: true });
	}

	hideInviteInfoToast() {
		this.setState({ showInviteInfoToast: false });
	}

	renderInviteInfoToast() {
		return (
			<div className={classes.absoluteDiv}>
				<Toast
					className={classes.toastStyle}
					onClose={() => this.hideInviteInfoToast()}
					show={!!this.state.showInviteInfoToast}
					delay={3000}
					autohide
				>
					<Toast.Body>
						{this.props.t('partnerManagement.messages.userHasBeenInvited')}
					</Toast.Body>
				</Toast>
			</div>
		);
	}

	renderToolbar() {
		return (
			<FilterBar
				showAddButton
				onShowCreateItem={() => this.showCreatePartnerDialog()}
				onFilterChanged={value => this.changeFilter(value)}
			/>
		);
	}

	render() {
		if (this.state.filteredCustomers) {
			let customerList = this.state.filteredCustomers.map(customer => (
				<Customer
					key={customer.cmID}
					Customer={customer}
					onPartnerChanged={() => this.refreshPartnerList()}
				/>
			));

			return (
				<>
					{this.renderInviteInfoToast()}
					{this.renderCreatePartnerDialog()}
					{this.renderInviteUserDialog()}

					<Card className={this.props.className} style={this.props.style}>
						<CardBody>
							{this.renderToolbar()}
							<Accordion>{customerList}</Accordion>
						</CardBody>
					</Card>
				</>
			);
		} else {
			return <Spinner />;
		}
	}
}

export default withTranslation()(PartnerManagement);
