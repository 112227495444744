import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Row, NavLink } from 'reactstrap';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import dateFormat from 'dateformat';

import machinesAPI from '../../../../services/api/machines';
import notificationsAPI from '../../../../services/api/notifications';
import vmUtil from '../../../../services/util/vmUtil';

import classes from './NotificationCard.module.css';

class NotificationCard extends Component {
	state = {
		notifications: []
	};
	componentDidMount() {
		this.loadData();
	}

	loadData() {
		notificationsAPI.getNotifications(0, 5).then(notifications => {
			this.setState({ notifications: notifications.data });
		});
	}

	async onNotificationClick(notification) {
		const parsedValues = JSON.parse(notification.values);
		if (parsedValues.hardwareID) {
			try {
				const vendingMachineID = await machinesAPI.getVendingMachineByHardwareID(
					parsedValues.hardwareID
				);
				if (vendingMachineID) {
					const encodedVMID = vmUtil.encodeVMID(vendingMachineID);
					this.props.history.push('vms/' + encodedVMID + '/detailer');
				}
			} catch (error) {
				return toast.error(this.props.t('common.couldNotOpen'));
			}
		}
	}

	render() {
		const { t } = this.props;
		const notificaitonList = this.state.notifications.map(
			(notification, index) => {
				const textDate = dateFormat(
					new Date(notification.insertedAt ? notification.insertedAt : 0),
					'yyyy. mm. dd. HH:MM'
				);
				return (
					<div key={index}>
						<div className={classes.IconDiv}>
							<i
								className={notification.icon}
								style={{ fontSize: '1.5rem' }}
								aria-hidden="true"
							></i>
						</div>
						<div
							className={classes.TextDiv}
							href
							onClick={() => this.onNotificationClick(notification)}
						>
							<p>{notification.resolvedText}</p>
							<p className={classes.DateParagraph}>{textDate}</p>
						</div>
						<hr />
					</div>
				);
			}
		);

		return (
			<>
				<Card className={this.props.className} style={this.props.style}>
					<CardBody>
						<Row style={{ justifyContent: 'space-between' }}>
							<Col sm="5">
								<CardTitle className="mb-0 text-muted">
									<i
										className="fa fa-bell fa-lg"
										style={{ marginRight: '10px' }}
									></i>
									{t('customerDashboard.notifications')}
								</CardTitle>

								<div className="small text-muted"></div>
							</Col>
							<Col sm="5"></Col>
						</Row>
						<div
							className="chart-wrapper"
							style={{ height: '300 + px', width: '100%', marginTop: '10px' }}
						>
							{notificaitonList}
							<NavLink href="/#/notifications" className="nav-link">
								<strong>{this.props.t('notifications.seeAll')}</strong>
							</NavLink>
						</div>
					</CardBody>
				</Card>
			</>
		);
	}
}

export default withRouter(withTranslation()(NotificationCard));
