//@flow
import React from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
//BootstrapTable
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
// import '../DeviceGroups/AddMachineGroupForm/node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'

import UserAPI from '../../services/api/users'
import logger from '../../services/logger'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

type Props = {
  history: Object,
  t: (key: string) => string,
}
type States = {
  users: Array<Object>,
}

class AdminList extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props)

    this.state = {
      users: [],
    }
  }

  //Komponens betölt
  componentDidMount = () => {
    this.getAdminUser()
  }

  //Get Admin users
  getAdminUser = async () => {
    try {
      const response = await UserAPI.GetAdminUser()
      const users = Object.values(response.data.response)
      this.setState({ users })
    } catch (e) {
      logger.error(e)
    }
  }

  //TABLE FORMATTERS

  //User ikon
  userFormatter = (cell, row) => <i className="icon-user" id="userIcon" />

  //Add user button action
  handleAddButtonClick = () => {
    this.props.history.push('rootUsers/addAdmin')
  }

  //Create add user button
  createAddButton = () => <div />

  //Render
  render() {
    //Table options
    const tableOptions = {
      sortIndicator: true,
      hideSizePerPage: false,
      paginationSize: 3,
      sizePerPageList: [5, 10, 25, 50, 100],
      sizePerPage: 10,
      hidePageListOnlyOnePage: true,
      clearSearch: true,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,
      paginationPosition: 'top',
      insertBtn: this.createAddButton,
    }

    const { t } = this.props

    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="icon-menu" />
              {t('adminlist.table.header')}
              <div className="card-actions" />
            </CardHeader>
            <CardBody>
              <BootstrapTable
                data={this.state.users}
                version="4"
                striped
                hover
                pagination
                search
                insertRow={true}
                options={tableOptions}
                condensed
              >
                <TableHeaderColumn
                  width="30"
                  dataField=""
                  dataAlign="center"
                  dataFormat={this.userFormatter}
                  thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
                  tdStyle={{ paddingTop: '15px' }}
                />

                <TableHeaderColumn
                  dataField="email"
                  dataSort
                  thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
                  tdStyle={{
                    paddingTop: '15px',
                    whiteSpace: 'normal',
                    fontWeight: 'bold',
                  }}
                >
                  {t('adminlist.tableheader.email')}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="cmID"
                  isKey
                  dataSort
                  thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
                  tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
                >
                  {t('adminlist.tableheader.cmID')}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="privilege"
                  thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
                  tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
                >
                  {t('adminlist.tableheader.privilege')}
                </TableHeaderColumn>
              </BootstrapTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default withRouter(withTranslation()(AdminList))
