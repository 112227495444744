import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { Row, Button, Col } from 'reactstrap';
import classes from '../CashFlow/IncomeChart.module.css';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { FaEye } from 'react-icons/fa';

const TitleContainer = styled(Row)`
	align-items: center
	margin-left: 60px
	margin-right: 60px

`;

const Title = styled.div`
width: 80%;
	display: felx
	justify-content: flex-start
	align-items: center
	color: var(--invoice);
	font-size: 32px
	font-weight: 600
	text-align: center

	@media screen and (max-width: 992px) {
		width: 100%;
		justify-content: center
	}


`;

const QVTitle = styled.div`
	width: 20%;
	display: felx
	justify-content: flex-end
	align-items: center
	padding: 8px
	font-size: 16px
	font-weight: 400
	text-align: center

	@media screen and (max-width: 992px) {
		display: none;
	}

	color: var(--invoice);
`;

const ChartContainer = styled(Col)`
	width: 100%;

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

const LegendContainer = styled(Col)`
	display: none
	width: 100%;

		@media screen and (max-width: 992px) {
		display: block 
		
	}
`;

const quickViewContainer = styled(Col)`
	width: 100%;

	@media screen and (max-width: 992px) {
		display: none;
	}
`;
const coffeColorShame = [
	'#a5691a',
	'#bb7d1a',
	'#d19218',
	'#e6a813',
	'#f9bf0b',
	'#e6a813',
	'#d19218',
	'#bb7d1a',
	'#a5691a',
	'#8e5719',
	'#774517',
	'#5f3514',
	'#482610',
	'#311807',
	'#482610',
	'#5f3514',
	'#774517',
	'#8e5719'
];
const sortParam = {
	ALPHA: 1,
	DESC: 2,
	ASC: 3
};

const IncomeLineChart = props => {
	const [sortData, setSortData] = useState(props.data);
	const [sort, setSort] = useState(0);
	const [loading, setLoading] = useState(false);
	const [qickView, setQickView] = useState(false);

	const setAlpha = value => {
		return (
			setSortData(
				value.sort((a, b) =>
					a.date ? (b.date ? a.date.localeCompare(b.date) * -1 : -1) : 1
				)
			),
			setSort(sortParam.ALPHA)
		);
	};

	const setDesc = value => {
		return (
			setSortData(value.sort((a, b) => b.value - a.value)),
			setSort(sortParam.DESC)
		);
	};

	const setAsc = value => {
		return (
			setSortData(value.sort((a, b) => a.value - b.value)),
			setSort(sortParam.ASC)
		);
	};

	const shortPanel = () => {
		const { t } = props;
		return (
			<Row style={{ justifyContent: 'center' }} className={classes.ButtonGroup}>
				<Button
					className={
						sort === sortParam.ALPHA
							? classes.ChartButtonActive
							: classes.ChartButton
					}
					onClick={() => setAlpha(sortData)}
				>
					<i className="fa fa-sort mr-2"></i>
					{t('productSales.chartbyTypes.button.alpha')}
				</Button>
				<Button
					className={
						sort === sortParam.DESC
							? classes.ChartButtonActive
							: classes.ChartButton
					}
					onClick={() => setAsc(sortData)}
				>
					<i className="fa fa-sort mr-2"></i>
					{t('productSales.chartbyTypes.button.desc')}
				</Button>
				<Button
					className={
						sort === sortParam.ASC
							? classes.ChartButtonActive
							: classes.ChartButton
					}
					onClick={() => setDesc(sortData)}
				>
					<i className="fa fa-sort mr-2"></i>
					{t('productSales.chartbyTypes.button.asc')}
				</Button>
			</Row>
		);
	};

	const getWidth = () => {
		return d3Chart.current ? d3Chart.current.parentElement.offsetWidth : null;
	};
	const getWidth2 = () => {
		return d3LegendBox.current
			? d3LegendBox.current.parentElement.offsetWidth
			: null;
	};
	const getHeight = data => {
		return data.length * 50;
	};

	useEffect(() => {
		(async () => {
			setLoading(false);
			setSortData(props.data);
			setLoading(true);
		})();

		const { t } = props;
		const data = sortData;
		const margin = { top: 20, right: 60, bottom: 60, left: 10 };
		const width = getWidth2() - margin.left - margin.right;
		const height = getHeight(data) + margin.bottom;

		const color = d3
			.scaleOrdinal()
			.domain([0, 18])
			.range(coffeColorShame);

		const textFormater = value => {
			let text = value;
			if (value) {
				if (width < 340 && value.length > 18) {
					text = value.slice(0, 17) + '...';
				}
				if (width > 340 && width < 450 && value.length > 48) {
					text = value.slice(0, 47) + '...';
				}
				if (width > 450 && width < 510 && value.length > 58) {
					text = value.slice(0, 57) + '...';
				}
				if (width > 510 && value.length > 68) {
					text = value.slice(0, 67) + '...';
				}
			}
			return text;
		};

		const y = d3
			.scaleBand()
			.domain(d3.range(data.length))
			.range([height / 1.5, margin.top])
			.padding(0.2);

		const x = d3
			.scaleLinear()
			.domain(d3.extent(data, d => d.value))
			.nice()
			.range([margin.left, width - margin.right]);

		const svg = d3
			.select(d3LegendBox.current)
			.attr('viewBox', [0, 0, width, height / 1.5]);
		svg.selectAll('*').remove();
		const bars = svg
			.append('g')
			.selectAll('rect')
			.data(data);
		const bar = bars
			.join('rect')
			.attr('fill', d => color(d))
			.attr('stroke', 'var(--invoice)')
			.attr('x', 0)
			.attr('y', (d, i) => y(i))
			.attr('height', y.bandwidth())
			.attr('width', width);

		const valueText = svg
			.append('g')
			.selectAll('text')
			.data(data)
			.join('text')
			.attr('fill', '#fff')
			.attr('text-anchor', 'end')
			.attr('font-family', 'sans-serif')
			.attr('font-size', 12)

			.attr('x', width - margin.right)
			.attr('y', (d, i) => y(i) + y.bandwidth() / 2)
			.attr('dy', '0.35em')
			.attr('dx', 40)
			.text(d => d.value + ' ' + props.currencySymbol);

		const productText = svg
			.append('g')
			.selectAll('text')
			.data(data)
			.join('text')
			.attr('fill', '#fff')
			.attr('text-anchor', 'start')
			.attr('font-family', 'sans-serif')
			.attr('font-size', 12)
			.attr('x', 50)
			.attr('y', (d, i) => y(i) + y.bandwidth() / 2)
			.attr('dy', '0.35em')
			.attr('dx', -30)
			.attr('visibility', false)
			.text(d => textFormater(d.date));
	});

	useEffect(() => {
		(async () => {
			setLoading(false);
			setSortData(props.data);
			setLoading(true);
		})();

		const { t } = props;
		const data = sortData;
		const margin = { top: 20, right: 50, bottom: 20, left: 20 };
		const width = getWidth() - margin.left - margin.right;
		const height = getHeight(data) + margin.bottom;
		const third = d3.max(data.map(e => e.value)) / 2;
		const color = d3
			.scaleOrdinal()
			.domain([0, 18])
			.range(coffeColorShame);

		const textFormater = value => {
			let text = value;

			if (value) {
				if (width > 510 && value.length > 38) {
					text = value.slice(0, 37) + '...';
				}
			}
			return text;
		};

		const y = d3
			.scaleBand()
			.domain(d3.range(data.length))
			.range([height, margin.top])
			.padding(0.1);

		const x = d3
			.scaleLinear()
			.domain([0, d3.max(data, d => d.value)])
			.nice()
			.range([margin.left, width - margin.right]);

		const xAxis = g =>
			g
				.attr('transform', `translate(0,${margin.bottom})`)
				.call(
					d3
						.axisBottom(x)
						.tickFormat('')
						.tickSize(height - margin.top)
				)
				.call(g => g.selectAll('.tick line').attr('stroke', 'none'))
				.call(g => g.select('.domain').remove());

		const yAxis = g =>
			g
				.attr('transform', `translate(${margin.left},0)`)
				.call(
					d3
						.axisLeft(y)
						.tickFormat('')
						.tickSizeOuter(0)
				)
				.call(g => g.selectAll('.tick').attr('class', 'tick'))
				.call(g => g.selectAll('.tick line').attr('stroke', 'none'))
				.call(g => g.select('.domain').remove());

		const svg = d3
			.select(d3Chart.current)
			.attr('viewBox', [0, 0, width, height]);
		svg.selectAll('*').remove();
		const bars = svg
			.append('g')
			.selectAll('rect')
			.data(data);

		const bar = bars
			.join('rect')
			.attr('fill', color)
			.attr('stroke', 'var(--invoice)')
			.attr('x', 50)
			.attr('y', (d, i) => y(i))
			.attr('height', y.bandwidth())
			.attr('width', d => x(0))
			.transition()
			.duration(800)
			.attr('width', d => x(d.value));

		const valueText = svg
			.append('g')
			.selectAll('text')
			.data(data)
			.join('text')
			.attr('fill', '#fff')
			.attr('text-anchor', 'end')
			.attr('font-family', 'sans-serif')
			.attr('font-size', 12)
			.attr('x', d => 0)
			.attr('y', (d, i) => y(i) + y.bandwidth() / 2)
			.attr('dy', '0.35em')
			.attr('dx', 40)
			.text(d => d.value + ' ' + props.currencySymbol)
			.transition()
			.duration(800)
			.attr('x', d => x(d.value))
			.call(text =>
				text
					.filter(d => d.value < third) // short bars
					.attr('dx', +70)
					.attr('dy', '1em')
					.attr('fill', 'var(--invoice-dark)')
					.attr('text-anchor', 'start')
					.attr('font-weight', 'bold')
					.attr('opacity', '0')
					.transition()
					.duration(200)
					.attr('opacity', '1')
			);

		const productText = svg
			.append('g')
			.selectAll('text')
			.data(data)
			.join('text')

			.attr('fill', 'var(--invoice-light)')
			.attr('text-anchor', 'start')
			.attr('font-family', 'sans-serif')
			.attr('font-size', 12)
			.attr('x', 100)
			.attr('y', (d, i) => y(i) + y.bandwidth() / 2)
			.attr('dy', '0.35em')
			.attr('dx', -30)
			.attr('visibility', false)
			.text(d => textFormater(d.date))
			.transition()
			.duration(800)
			.attr('visibility', true)
			.call(text =>
				text
					.filter(d => d.value < third) // short bars
					.attr('x', d => x(d.value))
					.attr('dx', +70)
					.attr('dy', '-0.5em')
					.attr('fill', 'var(--invoice-dark)')
					.attr('text-anchor', 'start')
					.attr('font-weight', 'bold')
					.attr('opacity', '0')
					.transition()
					.duration(200)
					.attr('opacity', '1')
			);

		const gy = svg.append('g').call(yAxis);
	});

	const d3Chart = useRef();
	const d3LegendBox = useRef();
	const chart = () => {
		let chart = null;

		if (loading) {
			chart = (
				<svg
					ref={d3Chart}
					style={{
						width: '100%'
					}}
				></svg>
			);
		}
		return chart;
	};
	const legendBox = () => {
		let legendBox = null;

		if (loading) {
			legendBox = (
				<svg
					ref={d3LegendBox}
					style={{
						width: '100%'
					}}
				></svg>
			);
		}
		return legendBox;
	};

	const renderQuickView = () => {
		return (
			<Modal show={qickView} onHide={() => setQickView(!qickView)}>
				<Modal.Header closeButton>
					<Title>{props.title}</Title>
				</Modal.Header>
				<Modal.Body>
					<quickViewContainer id="quickView">{legendBox()}</quickViewContainer>
				</Modal.Body>
			</Modal>
		);
	};
	return (
		<>
			{renderQuickView()}
			<div>{shortPanel()}</div>
			<TitleContainer>
				<Title>{props.title}</Title>
				<QVTitle>
					{props.t('productSales.chartbyTypes.button.quickView')}
					<Button
						className={classes.QVBtn}
						onClick={() => setQickView(!qickView)}
					>
						<FaEye />
					</Button>
				</QVTitle>
			</TitleContainer>

			<ChartContainer id="chart">{chart()}</ChartContainer>
			<LegendContainer id="legend">{legendBox()}</LegendContainer>
		</>
	);
};
export default withTranslation()(IncomeLineChart);
