import React, { Component } from 'react';
import { Card, CardTitle, Badge } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Spinner from '../../../components/UI/Spinner/Spinner';
import classes from './BalancePanel.module.css';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

class BalancePanel extends Component {
	state = {
		balanceForm: true,
		amount: '',
		remarkText: '',
		validated: false
	};

	remarkFormater = (cell, row) => {
		if (!row) return;
		const { t } = this.props;
		let state = t('invisibleTouchManager.controlpanel.noData');
		let style = { color: '#b27300', backgroundColor: '#fff6e6' };
		let i = 'fa fa-exclamation';
		let massage = '';

		switch (row.transactionTypeID) {
			case 0:
				state = t('invisibleTouchManager.purchasesPanel.massage.PCEntry');
				style = {
					color: 'var(--invoice)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-plus-circle';
				massage = [
					cell && cell.remark
						? cell.remark
						: t('invisibleTouchManager.controlpanel.noData')
				];
				break;
			case 1:
				state = t('invisibleTouchManager.purchasesPanel.massage.start');
				style = {
					color: 'var(--inv-tuch-error)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-times-circle';
				massage = [];
				break;
			case 2:
				state = t('invisibleTouchManager.purchasesPanel.massage.started');
				style = {
					color: 'var(--inv-tuch-error)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-times-circle';
				massage = [];
				break;
			case 3:
				state = t('invisibleTouchManager.purchasesPanel.massage.startFailed');
				style = {
					color: 'var(--inv-tuch-error)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-times-circle';
				massage = ['Barion Transaction ID: ' + row.foreignID];
				break;
			case 4:
				state = t(
					'invisibleTouchManager.purchasesPanel.barionTransactionFailed'
				);
				style = {
					color: 'var(--inv-tuch-error)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-times-circle';
				massage = ['Barion Transaction ID: ' + row.foreignID];
				break;
			case 5:
				state = t('invisibleTouchManager.purchasesPanel.barionCreditEntry');
				style = { color: 'var(--massage)', backgroundColor: 'transparent' };
				i = 'fa fa-times-circle';
				massage = ['Barion Transaction ID: ' + row.foreignID];
				break;
			case 6:
				state = t('invisibleTouchManager.purchasesPanel.massage.VMCEntry');
				style = { color: 'var(--message)', backgroundColor: 'transparent' };
				i = 'fa fa-plus-circle';
				massage = [
					' VM: ' +
						(cell && cell.uID
							? cell.uID
							: t('invisibleTouchManager.controlpanel.noData'))
				];
				break;

			case 8:
				state = t('invisibleTouchManager.purchasesPanel.massage.promApplyed');
				style = { color: 'var(--balance)', backgroundColor: 'transparent' };
				i = 'fa fa-check-circle';
				massage = [
					cell && cell.typeName
						? cell.typeName
						: t('invisibleTouchManager.controlpanel.noData'),
					cell && cell.promotion.name
						? cell.promotion.name
						: t('invisibleTouchManager.controlpanel.noData')
				];
				break;
			case 9:
				state = t('invisibleTouchManager.purchasesPanel.withdrawn');
				style = {
					color: 'var(--purchases-dark)',
					backgroundColor: 'transparent'
				};
				i = 'fa fa-exclamation-triangle';
				massage = [
					' VM: ' +
						(cell && cell.uID
							? cell.uID
							: t('invisibleTouchManager.controlpanel.noData'))
				];
				break;
			case 10:
				state = t('invisibleTouchManager.purchasesPanel.massage.promApplyed');
				style = { color: 'var(--balance)', backgroundColor: 'transparent' };
				i = 'fa fa-check-circle';
				massage = [
					t('invisibleTouchManager.blancePanel.creditProm'),
					cell && cell.typeName
						? cell.typeName
						: t('invisibleTouchManager.controlpanel.noData'),
					cell && cell.promotion.name
						? cell.promotion.name
						: t('invisibleTouchManager.controlpanel.noData')
				];
				break;
		}
		return (
			<Col>
				<Col>
					<Row style={{ justifyContent: 'center' }}>
						<i className={i} style={style} />

						<Badge style={style}>{state}</Badge>
					</Row>
				</Col>
				<Col style={{ fontSize: 'smaller' }}>
					{this.massageFormater(massage, style)}
				</Col>
			</Col>
		);
	};

	massageFormater = (massage, style) => {
		let massag = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (massage && massage.length > 0) {
			massag = (
				<Col>
					{massage.map(e => {
						return (
							<Col>
								{' '}
								<Badge style={style}>{e ? e : ''}</Badge>
							</Col>
						);
					})}
				</Col>
			);
		}

		return massag;
	};

	dateFormater = cell => {
		if (!cell) return;
		return (
			<p className={classes.Date}>
				{moment(cell).format('YYYY-MM-DD HH:mm:ss')}
			</p>
		);
	};

	amountFormater = cell => {
		let data = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (cell) {
			data = <p>{cell.toFixed(2)}</p>;
		}
		return data;
	};

	userFormatter = (cell, row) => {
		return <i className="fa fa-dollar" id="userIcon" />;
	};

	isColumnsVisibile = columName => {
		return (
			!this.props.hiddenColumns ||
			this.props.hiddenColumns.indexOf(columName) < 0
		);
	};

	renderTable = () => {
		const { t } = this.props;
		const tableOptions = {
			sortIndicator: false,
			hideSizePerPage: false,
			paginationSize: 3,
			sizePerPageList: [8],
			sizePerPage: this.props.recordPerPage || 10,
			paginationPosition: 'bottom',
			withFirstAndLast: true,
			alwaysShowAllBtns: true,
			pageStartIndex: 1,
			clearSearch: true,
			hidePageListOnlyOnePage: true,
			defaultSortName: 'insertedAt',
			defaultSortOrder: 'desc',
			searchPanel: this.props.renderFilterBar,
			onSearchChange: this.props.onFilterChange
		};

		let table = <Spinner />;
		if (this.props.data) {
			table = (
				<>
					{!this.props.hideToolbar ? this.props.onRenderFilterBar() : null}
					<BootstrapTable
						key="bootstrapTable"
						data={this.props.transactions.filter(
							e => e.transactionTypeID !== 7 && e.transactionTypeID !== 9
						)}
						version="4"
						pagination={!this.props.hidePager}
						insertRow={false}
						options={tableOptions}
						multiColumnSearch
						condensed
						bordered={false}
						dataAlign={'center'}
						size="sm"
						trClassName={classes.TableBody}
					>
						{this.isColumnsVisibile('id') ? (
							<TableHeaderColumn
								isKey
								width="50"
								dataAlign="center"
								dataField="id"
								dataFormat={this.userFormatter}
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
							/>
						) : null}
						{this.isColumnsVisibile('index') ? (
							<TableHeaderColumn
								dataField="index"
								dataAlign="center"
								width="100"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
							>
								{t('invisibleTouchManager.purchasesPanel.account')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('insertedAt') ? (
							<TableHeaderColumn
								dataField="insertedAt"
								width="200"
								dataAlign="center"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
								dataFormat={this.dateFormater}
							>
								{t('invisibleTouchManager.purchasesPanel.date')}
							</TableHeaderColumn>
						) : null}

						{this.isColumnsVisibile('amount') ? (
							<TableHeaderColumn
								dataField="amount"
								width="200"
								dataAlign="center"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
								dataFormat={this.amountFormater}
							>
								{t('invisibleTouchManager.purchasesPanel.amount')}
							</TableHeaderColumn>
						) : null}
						{this.isColumnsVisibile('details') ? (
							<TableHeaderColumn
								dataField="details"
								width="600"
								dataAlign="center"
								dataSort
								thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
								tdStyle={{
									paddingTop: '15px'
								}}
								dataFormat={this.remarkFormater}
							>
								{t('invisibleTouchManager.blancePanel.remark')}
							</TableHeaderColumn>
						) : null}
					</BootstrapTable>
				</>
			);
		}
		return table;
	};

	render() {
		const { t } = this.props;
		return (
			<Card body className={this.props.panel}>
				<CardTitle className="mb-0 text-muted">
					<i
						className="fa fa-dollar
							"
					/>
					&nbsp;
					{t('invisibleTouchManager.blancePanel.table')}
				</CardTitle>
				<Row
					style={{
						marginTop: '2rem'
					}}
				>
					<Col>{this.renderTable()}</Col>
				</Row>
			</Card>
		);
	}
}

export default withTranslation()(BalancePanel);
