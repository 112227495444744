//@flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { RedMachineButton } from '../../../../components/Machines/MachineComponents'
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent'
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard'

type Props = {
  vmID: string,
  history: Object,
  products?: Array<Object>,
}
type States = {}

class Zenith extends React.Component<Props, States> {
  keyboardConfig = [
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <i className="fa fa-long-arrow-down mr-1" />
            25
          </>
        ),
        buttonID: '25',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <i className="fa fa-long-arrow-up mr-1" />
            26
          </>
        ),
        buttonID: '26',
      },
      {
        buttonContent: <>27</>,
        buttonID: '27',
      },
    ],
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: <>A1</>,
        buttonID: '1',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: <>B9</>,
        buttonID: '9',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            ENTER <br />
            17
          </>
        ),
        buttonID: '17',
      },
    ],
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: <>C2</>,
        buttonID: '2',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: <>D10</>,
        buttonID: '10',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            EXIT <br />
            18
          </>
        ),
        buttonID: '18',
      },
    ],
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: <>E3</>,
        buttonID: '3',
      },
      {
        buttonContent: <>11</>,
        buttonID: '11',
      },
      {
        buttonContent: <>19</>,
        buttonID: '19',
      },
    ],
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: <>F4</>,
        buttonID: '4',
      },
      {
        buttonContent: <>12</>,
        buttonID: '12',
      },
      {
        buttonContent: <>20</>,
        buttonID: '20',
      },
    ],
    [
      {
        buttonContent: <>5</>,
        buttonID: '5',
      },
      {
        buttonContent: <>13</>,
        buttonID: '13',
      },
      {
        buttonContent: <>21</>,
        buttonID: '21',
      },
    ],
    [
      {
        buttonContent: <>6</>,
        buttonID: '6',
      },
      {
        buttonContent: <>14</>,
        buttonID: '14',
      },
      {
        buttonContent: <>22</>,
        buttonID: '22',
      },
    ],
    [
      {
        buttonContent: <>7</>,
        buttonID: '7',
      },
      {
        buttonContent: <>15</>,
        buttonID: '15',
      },
      {
        buttonContent: <>23</>,
        buttonID: '23',
      },
    ],
    [
      {
        buttonContent: <>8</>,
        buttonID: '8',
      },
      {
        buttonContent: <>16</>,
        buttonID: '16',
      },
      {
        buttonContent: <>24</>,
        buttonID: '24',
      },
    ],
  ]

  render() {
    return (
      <DirectChooseMachineBaseComponent
        machineTitle="Zenith"
        lineNumber={4}
        lineLength={20}
        keyboard={
          <DirectChooseKeyboard
            type="vertical"
            keyboardConfig={this.keyboardConfig}
            {...this.props}
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(Zenith)
