import React from 'react';
import Tooltip from './Tooltip';
import Badge from 'react-bootstrap/Badge';
import './HelperTooltip.module.css';
import classes from './HelperTooltip.module.css';

const HelperTooltip = props => {
	return (
		<div className="App">
			<div className="example-wrapper">
				<Tooltip
					height={props.height}
					width={props.width}
					content={props.content}
					title={props.title}
					direction={props.direction}
				>
					<div className={classes.Badge}>
						<i className={'fa fa-info'} />
					</div>
				</Tooltip>
			</div>
		</div>
	);
};
export default HelperTooltip;
