import React, { Component } from 'react';

import { Nav, NavbarBrand, NavbarToggler, Row, Col } from 'reactstrap';

import HeaderDropdown from './HeaderDropdown';
import UserDropdown from './UserDropdown';
import NotificationDropdown from './NotificationsDropdown';
import LogoutButton from './LogoutButton';
import MassageDropdown from './MassageDropdowm';

class Header extends Component {
	sidebarMinimize() {
		document.body.classList.toggle('sidebar-minimized');
	}

	brandMinimize() {
		document.body.classList.toggle('brand-minimized');
	}

	mobileSidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle('sidebar-mobile-show');
	}

	sidebarToggle = e => {
		this.sidebarMinimize();
		this.brandMinimize();
	};

	render() {
		return (
			<header className="app-header navbar">
				<NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
					<span className="navbar-toggler-icon"></span>
				</NavbarToggler>
				<NavbarBrand href="#"></NavbarBrand>
				<Nav className=" d-lg-none mr-auto" navbar>
					{/* {' mobil'} */}

					<HeaderDropdown
						langSelect
						className={'ml-2'}
						dropDownClassName={'mt-3'}
					/>
				</Nav>
				<Nav className=" d-lg-none ml-auto" navbar>
					<NotificationDropdown className={'mr-2'} dropDownClassName={'mt-2'} />
					<LogoutButton className={'nav-link nav-dropdown-toggle mr-3 mb-1'} />
				</Nav>
				<Nav className=" d-md-down-none ml-auto" navbar>
					{/* {'not mobil'} */}
					<UserDropdown className={'d-md-down-none '} />

					<NotificationDropdown className={'mr-2 ml-2'} />

					<MassageDropdown dev className={'mr-2 ml-2'} />

					<HeaderDropdown langSelect className={'mr-auto'} />

					<LogoutButton className={'nav-link nav-dropdown-toggle mr-3 ml-5'} />
				</Nav>
			</header>
		);
	}
}

export default Header;
