//@flow
import React from 'react'
import type { Node } from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'

import VMUtil from '../../../../services/util/vmUtil'
import AzkoyenButtonWithLabelHorizontal from './AzkoyenButtonWithLabelHorizontal';

const AzkoyenButtonContainer = styled(Col)`
  margin: 2px;
  padding: 2px;
`

const AzkoyenButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #78909c;
  color: white;
  border-radius: 5px;
  border: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  margin: 2px;
  min-height: 90px;
  font-weight: 600;
  font-size: xx-large;

  &:active {
    background-color: #61ce8b;
  }
`

const DNoneButton = styled.button`
	display: none;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  vertical-align: middle;
  margin: 2px;
  min-height: 90px;

  &:active {
    background-color: #61ce8b;
  }
`

type Props = {
  products: Array<Object>,
  keyboardConfig: Array<
    Array<{
      buttonContent: any,
      buttonID: string,
      ledIndex?: number,
      ButtonComponent?: Node,
    }>
  >,
  programmingButtonVisible?: boolean,
  onButtonClick?: (buttonId: string, isSlave: ?boolean) => () => Promise<void>,
}

export default class AzkoyenHorizontalKeyboard extends React.Component<Props> {
  render() {
    const {
      onButtonClick = (buttonId: string, isSlave: ?boolean) => () => {},
      keyboardConfig = [],
      products = [],
    } = this.props
    return (
      <>
        {keyboardConfig.map((line, idx) => (
          <Row
            key={`machine-keyboard-line-${idx}`}
            className="d-flex justify-content-center m-0"
          >
            {line.map((button, columnIdx) => (
              <AzkoyenButtonContainer
                xs="3"
                md="3"
                lg="3"
                xl="3"
                key={`machine-button-${columnIdx}-${idx}`}
              >
                {idx === 3 && columnIdx === 2
                  ? <DNoneButton />
                  : <AzkoyenButtonWithLabelHorizontal
                    {...button}
                    onButtonClick={onButtonClick}
                    labelContent={VMUtil.getButtonLabel(button, products)}
                    display={ idx === 3 && columnIdx === 2 ? 'none' : 'block' }
                  >
                    {button.buttonContent}
                  </AzkoyenButtonWithLabelHorizontal>}
              </AzkoyenButtonContainer>
            ))}
          </Row>
        ))}
      </>
    )
  }
}
