import axios from './api';
import qs from 'qs';

export default {
	getNotifications: (start, count) => {
		const query = qs.stringify({ limitStart: start, limitCount: count });
		return axios.get().get(`/notifications?${query}`);
	},

	getUnreadNotifications: () => {
		return axios.get().get(`/notifications/unreadcount`);
	},

	getNotificationCount: () => {
		return axios.get().get(`/notifications/count`);
	},

	getNotificationSettings: uID => {
		const query = qs.stringify({ uID }, { addQueryPrefix: true });
		return axios.get().get(`/notifications/settings${query}`);
	},

	saveNotificationSettings: (userID, settings) => {
		const reqBody = { userID, notificationSettings: settings };
		return axios.get().post(`/notifications/settings`, reqBody);
	}
};
