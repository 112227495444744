//@flow
import React from 'react'
import EditUserContainer from './EditUserContainer'

type Props = {
  match: {
    params: { uID: string },
  },
  className: string,
}
type States = {}
class UserEditScreen extends React.Component<Props, States> {
  constructor() {
    super()
    this.state = {}
  }

  //Render
  render() {
    const userId = this.props.match.params.uID
    return (
      <div>
        <EditUserContainer
          uID={userId}
          pinWhenPhoneUpdate={true}
          className={this.props.className}
        />
      </div>
    )
  }
}

export default UserEditScreen
