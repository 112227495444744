import React, { Component } from 'react';
import { Card, Nav, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import classes from './TelemetryAccessDenied.module.css';

class TelemetryAccessDenied extends Component {
	render() {
		const { t } = this.props;
		return (
			<Card body className={classes.Card}>
				<Label className={classes.Header}>
					{this.props.t('access.telemetry.header')}
				</Label>
				<div className={classes.Logo}>
					<i
						className="fa fa-exclamation-circle"
						style={{ fontSize: '2000%' }}
					></i>
				</div>
				<Label className={classes.Text}>
					{this.props.t('access.telemetry.text')}
				</Label>
			</Card>
		);
	}
}

export default withTranslation()(TelemetryAccessDenied);
