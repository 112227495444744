import React, { Component } from 'react';
import { Card, Row, CardBody, Col, CardTitle } from 'reactstrap';

import Modal from 'react-bootstrap/Modal';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import FilterBar from '../../FilterBar/FilterBar';
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';
import AddMachineGroupForm from './AddMachineGroupForm/AddMachineGroupForm';
import EditMachineGroupForm from './EditMachineGroupForm/EditMachineGroupForm';
import DeviceGroupTable from './DeviceGroupTable/DeviceGroupTable';
import MachineSelectedTable from './AddMachineGroupForm/MachineSelectedTable';
import HelperTooltip from '../../../components/UI/HelperTooltip/HelperTooltip';

import { withTranslation } from 'react-i18next';

// for API test
import VmsApi from '../../../services/api/machines';
import groupApi from '../../../services/api/machineGroup';

class DeviceGroupManagerTab extends Component {
	state = {
		machineGroups: [],
		selectedMachines: [],
		machines: []
	};

	componentDidMount() {
		this.loadData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.showEditMachineGroupsDialog !==
			this.state.showEditMachineGroupsDialog
		) {
			this.showList(this.state.groupToEdit.id);
		}
	}

	loadData = () => {
		VmsApi.getCustomerVM().then(machines => {
			this.setState({ machines: machines.data.response }, () =>
				this.filterMachineGroups()
			);
		});

		groupApi.getMachineGroups().then(machineGroups => {
			this.setState({ machineGroups: machineGroups.data.groups }, () =>
				this.filterMachineGroups()
			);
		});
	};

	//Render Filterbar with ADDButton
	renderFilterBar = () => {
		return (
			<FilterBar
				groupManager
				key="filterbar"
				showAddButton
				onShowCreateItem={() => this.handleAddButtonClick()}
				onFilterChanged={value => this.onFilterChange(value)}
			/>
		);
	};

	filterMachineGroups() {
		let filteredMachineGroups = [...this.state.machineGroups];
		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredMachineGroups = this.state.machineGroups.filter(machineGroups => {
				let curMachineGroups = { ...machineGroups };
				let MachineGroupsFieldArray = Object.values(curMachineGroups);
				return MachineGroupsFieldArray.some(
					val =>
						String(val)
							.toUpperCase()
							.indexOf(filterValue) >= 0
				);
			});
		}
		let firstId = null;
		if (filteredMachineGroups && filteredMachineGroups.length > 0)
			firstId = filteredMachineGroups[0].id;

		this.setState({ filteredMachineGroups: filteredMachineGroups, firstId });
	}

	onFilterChange(filterValue) {
		this.setState({ currentFilterValue: filterValue }, () =>
			this.filterMachineGroups()
		);
	}

	handleAddButtonClick = () => {
		this.showAddDialog();
	};

	//AddMachineGroups rendering and handleing

	showAddDialog() {
		this.setState({ showAddMachineGroupsDialog: true });
	}

	hideAddDialog() {
		this.props.defaultTab('DeviceGroups');
		this.loadData();
		this.setState({ showAddMachineGroupsDialog: false });
	}

	renderAddMachineGroupsDialog() {
		return (
			<Modal
				size="lg"
				show={this.state.showAddMachineGroupsDialog}
				onHide={() => this.hideAddDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i class="fa fa-archive" style={{ marginRight: '10px' }}></i>
						{this.props.t('groupManager.addGroup.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AddMachineGroupForm
						// selected
						onHide={() => this.hideAddDialog()}
						machines={this.state.machines}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	//editProfil------------------------------------------
	showList = value => {
		if (value) {
			groupApi.getAssignedVms(value).then(machines => {
				this.setState({ selectedMachines: machines.data.vms });
			});
		}
	};

	editProfil = group => {
		this.showEditDialog(group);
	};

	showEditDialog(group) {
		this.setState({ showEditMachineGroupsDialog: true, groupToEdit: group });
	}

	hideEditDialog(value) {
		this.props.defaultTab('DeviceGroups');
		this.setState({
			showEditMachineGroupsDialog: false
		});
		this.loadData();
	}

	onDeleteGroup = group => {
		this.setState({ groupToDelete: group });
	};

	onDeleteGroupConfirmed = () => {
		groupApi
			.deleteGroup(this.state.groupToDelete.id)
			.then(e => this.loadData());
		this.setState({ groupToDelete: null, selectedMachines: [] });
	};

	//  render DeletConfirmation
	renderDeleteConfirmation() {
		if (this.state.groupToDelete) {
			return (
				<ConfirmationDialog
					isOpen={this.state.groupToDelete}
					title={this.props.t('groupManager.deleteGroup.title')}
					confirmationText={this.props.t('groupManager.deleteGroup.label')}
					cancelText={this.props.t('groupManager.deleteGroup.cancelButton')}
					okText={this.props.t('groupManager.deleteGroup.okButton')}
					onToggle={() => this.setState({ groupToDelete: null })}
					onCancel={() => this.setState({ groupToDelete: null })}
					onOk={() => this.onDeleteGroupConfirmed()}
				/>
			);
		} else {
			return null;
		}
	}

	// render EditDialog
	renderEditMachineGroupsDialog() {
		return (
			<Modal
				size="lg"
				show={this.state.showEditMachineGroupsDialog}
				onHide={() => this.hideEditDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i class="fa fa-archive" style={{ marginRight: '10px' }}></i>
						{this.props.t('groupManager.editGroup.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: 'GhostWhite' }}>
					<EditMachineGroupForm
						// selected
						onHide={() => this.hideEditDialog()}
						group={this.state.groupToEdit}
						allMachines={this.state.machines}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	//-----------------------------------------------------------------
	renderDeviceTable() {
		return (
			<DeviceGroupTable
				firstId={this.state.firstId}
				data={this.state.filteredMachineGroups}
				groupToDel={this.state.groupToDelete}
				onDeleteGroup={this.onDeleteGroup}
				onEditProfil={this.editProfil}
				onRenderFilterBar={this.renderFilterBar}
				onFilterChange={this.onFilterChange}
				onShowList={this.showList}
			/>
		);
	}

	//Render Title
	renderDeviceTitle() {
		const { t, titleOnClick = () => {} } = this.props;

		return (
			<Row style={{ alignItems: 'flex-start' }}>
				<CardTitle
					className="text-muted"
					style={{ cursor: 'pointer', marginLeft: '20px' }}
					onClick={titleOnClick}
				>
					<i className={`fa fa-archive`} style={{ marginRight: '10px' }} />
					{t('groupManager.deviceTab.label')}
				</CardTitle>
				<HelperTooltip
					height={'100px'}
					width={'500px'}
					direction={'right'}
					id={'machineGroupmanager'}
					title={t('groupManager.deviceTab.tooltip.title')}
					content={t('groupManager.deviceTab.tooltip.content')}
				></HelperTooltip>
			</Row>
		);
	}

	//Render!
	render() {
		return (
			<div className="animated fadeIn">
				{this.renderEditMachineGroupsDialog()}
				{this.renderAddMachineGroupsDialog()}
				{this.renderDeleteConfirmation()}
				<Card className={this.props.className} style={this.props.style}>
					<CardBody>
						<Row>
							<Col xs={12} sm={12} md={12} lg={12} xl={7} xxl={7}>
								{this.renderDeviceTitle()}
								{this.renderDeviceTable()}
							</Col>
							<Col s xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
								<CardTitle className="text-muted" style={{ cursor: 'pointer' }}>
									{this.props.t('groupManager.deviceTab.displayedLabel')}
								</CardTitle>
								<MachineSelectedTable
									data={this.state.selectedMachines}
									height="360"
									bgColor="#eef4f8"
								/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default withTranslation()(DeviceGroupManagerTab);
