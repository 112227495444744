//@flow
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import SalesFilter from './SalesFilter/SalesFilter';
import SalesData from './SalesData';
import machineAPI from '../../services/api/machines';
import statisticsAPI from '../../services/api/statistics';
import { dateFromMYSQLTimeStamp } from '../../services/util/dateUtil';

import Spinner from '../../components/UI/Spinner/Spinner';

import qs from 'qs';

class SalesList extends Component {
	state = {
		allVendingMachines: [],
		selectedVendingMachines: [],
		data: {},
		fromDate: new Date(),
		toDate: new Date(),
		loading: true
	};

	constructor(props) {
		super(props);

		const urlParams = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true
		});

		machineAPI.getVisibleVendingMachines().then(vms => {
			const toDate = new Date();
			const fromDate = new Date();
			if (urlParams && urlParams.chartType && urlParams.chartType === 6) {
				fromDate.setDate(fromDate.getDate() - 7);
			} else {
				fromDate.setDate(fromDate.getDate() - 30);
			}
			this.setState(
				{
					allVendingMachines: vms.data.response ? vms.data.response : [],
					selectedVendingMachines: vms.data.response ? vms.data.response : [],
					toDate,
					fromDate,
					chartType: urlParams && urlParams.chartType ? urlParams.chartType : 1
				},
				() => this.loadData()
			);
		});
	}

	componentDidMount() {}

	setNewFilter(newFilter) {
		this.setState(
			{
				selectedVendingMachines: newFilter.machines,
				fromDate: newFilter.fromDate,
				toDate: newFilter.toDate,
				chartType: newFilter.chartType,
				data:
					this.state.chartType !== newFilter.chartType ? {} : this.state.data
			},
			() => this.loadData()
		);
	}

	resolveMachineID(data) {
		const newData = { ...data };
		if (newData && newData.series && newData.series.length > 0) {
			newData.series[0].xValues = newData.series[0].xValues.map(xValue => {
				const vendingMachine = this.state.allVendingMachines.find(
					vendingMachine => vendingMachine.vmID === xValue
				);
				return vendingMachine ? vendingMachine.customName : xValue;
			});
		}
		return newData;
	}

	resolveMachineState(data) {
		const newData = { ...data };
		if (newData && newData.series && newData.series.length > 0) {
			newData.series = newData.series.map(series => {
				series.yValues = series.yValues.map(yValue => {
					switch (yValue) {
						case 'error':
							return 0;
						case 'warning':
							return 1;
						case 'operational':
							return 2;
						case 'ok':
							return 2;
						case 'programming':
							return 3;
						default:
							return 0;
					}
				});

				return series;
			});
		}
		return newData;
	}

	resolveXDate(data) {
		const newData = { ...data };
		if (newData && newData.series && newData.series.length > 0) {
			newData.series = newData.series.map(series => {
				series.xValues = series.xValues.map(xValue =>
					dateFromMYSQLTimeStamp(xValue)
				);

				return series;
			});
		}

		return newData;
	}

	resolveSeriesName(data) {
		const newData = { ...data };
		if (newData && newData.series && newData.series.length > 0) {
			newData.series = newData.series.map(series => {
				const vendingMachine = this.state.allVendingMachines.find(
					vendingMachine => vendingMachine.vmID === series.seriesName
				);
				series.name = vendingMachine ? vendingMachine.name : series.seriesName;
				return series;
			});
		}
		return newData;
	}

	loadMachineIncomePercent(machineIDList) {
		this.setState({ loading: true }, () => {
			statisticsAPI
				.getMachineIncomePercents(
					machineIDList,
					this.state.fromDate,
					this.state.toDate
				)
				.then(result => {
					if (result.data && result.data.series) {
						const resolvedData = this.resolveMachineID(result.data);
						this.setState({ data: resolvedData, loading: false });
					} else {
						this.setState({ data: {}, loading: false });
					}
				});
		});
	}

	loadProductCountPercent(machineIDList) {
		this.setState({ loading: true }, () => {
			statisticsAPI
				.getProductCountPercents(
					machineIDList,
					this.state.fromDate,
					this.state.toDate
				)
				.then(result => {
					if (result.data && result.data.series) {
						//const resolvedData = this.resolveMachineID(result.data);
						this.setState({ data: result.data, loading: false });
					} else {
						this.setState({ data: {}, loading: false });
					}
				});
		});
	}

	loadProductIncomePercent(machineIDList) {
		this.setState({ loading: true }, () => {
			statisticsAPI
				.getProductIncomePercents(
					machineIDList,
					this.state.fromDate,
					this.state.toDate
				)
				.then(result => {
					if (result.data && result.data.series) {
						//const resolvedData = this.resolveMachineID(result.data);
						this.setState({ data: result.data, loading: false });
					} else {
						this.setState({ data: {}, loading: false });
					}
				});
		});
	}

	loadMachineStateChanges(machineIDList) {
		this.setState({ loading: true }, () => {
			statisticsAPI
				.getMachineStateChanges(
					machineIDList,
					this.state.fromDate,
					this.state.toDate
				)
				.then(result => {
					if (result.data && result.data.series) {
						const resolvedData = this.resolveSeriesName(
							this.resolveXDate(this.resolveMachineState(result.data))
						);

						this.setState({ data: resolvedData, loading: false });
					} else {
						this.setState({ data: {}, loading: false });
					}
				});
		});
	}

	loadDailyIncome(machineIDList) {
		this.setState({ loading: true }, () => {
			statisticsAPI
				.getMachineDailyIncomes(
					machineIDList,
					this.state.fromDate,
					this.state.toDate
				)
				.then(result => {
					if (result.data && result.data.series) {
						const resolvedData = this.resolveSeriesName(
							this.resolveXDate(result.data)
						);

						this.setState({ data: resolvedData, loading: false });
					} else {
						this.setState({ data: {}, loading: false });
					}
				});
		});
	}

	loadHourlyIncome(machineIDList) {
		this.setState({ loading: true }, () => {
			statisticsAPI
				.getMachineHourlyIncomes(
					machineIDList,
					this.state.fromDate,
					this.state.toDate
				)
				.then(result => {
					if (result.data && result.data.series) {
						const resolvedData = this.resolveSeriesName(
							this.resolveXDate(result.data)
						);

						this.setState({ data: resolvedData, loading: false });
					} else {
						this.setState({ data: {}, loading: false });
					}
				});
		});
	}

	loadData() {
		const machineIDList = this.state.selectedVendingMachines.map(
			vendingMachine => vendingMachine.vmID
		);
		const chartTypeAsString = this.state.chartType
			? this.state.chartType.toString()
			: '';
		switch (chartTypeAsString) {
			case '1':
				this.loadMachineIncomePercent(machineIDList);
				break;
			case '2':
				this.loadProductCountPercent(machineIDList);
				break;
			case '3':
				this.loadProductIncomePercent(machineIDList);
				break;
			case '4':
				this.loadMachineStateChanges(machineIDList);
				break;
			case '5':
				this.loadDailyIncome(machineIDList);
				break;
			case '6':
				this.loadHourlyIncome(machineIDList);
				break;
			default:
				break;
		}
	}

	render() {
		// const { t } = this.props;

		const chartTypeAsString = this.state.chartType
			? this.state.chartType.toString()
			: '';
		let salesData = null;
		if (
			!this.state.loading &&
			this.state.data &&
			this.state.data.series &&
			this.state.data.series.length > 0
		) {
			salesData = (
				<SalesData
					chartType={chartTypeAsString}
					xValues={this.state.data.series[0].xValues}
					yValues={this.state.data.series[0].yValues}
					series={this.state.data.series}
				/>
			);
		} else {
			salesData = <Spinner />;
		}

		return (
			<div
				className="animated fadeIn"
				style={{
					paddingLeft: '20px',
					paddingRight: '20px',
					paddingTop: '10px'
				}}
			>
				<SalesFilter
					allVendingMachines={this.state.allVendingMachines}
					selectedVendingMachines={this.state.selectedVendingMachines}
					onFilterChange={newFilter => this.setNewFilter(newFilter)}
					toDate={this.state.toDate}
					fromDate={this.state.fromDate}
					chartType={this.state.chartType}
				/>

				{salesData}
			</div>
		);
	}
}

export default withRouter(withTranslation()(SalesList));
