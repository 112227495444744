import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classes from './PromotionTypeSelector.module.css';

import ValuePromotion from './ValuePromotion/ValuePromotion';
import HelperTooltip from '../../../../components/UI/HelperTooltip/HelperTooltip';
import { withTranslation } from 'react-i18next';
import promotionsUtil from '../../../../services/util/promotionsUtil';
import { Alert } from 'reactstrap';
//____________________FROM DEV:__________________
// Product promotion now only can recive promotion ID manually.
// Commented codeblocks preperd for producthandling when it will be avileble

const PromotionTypeSelector = props => {
	const { t } = props;

	const placeholderSelect = () => {
		const edit = document.getElementById('edit');
		if (edit) {
			return t('promotionManager.editPromotion.placeholder');
		} else {
			return t('promotionManager.addPromotion.promotionType.placeholder');
		}
	};

	const promotionTypes = [
		{
			label: t('promotionManager.addPromotion.promotionType.value.title'),
			value: 0,
			valueSelector: (
				<>
					<Alert variant="info">
						{t('promotionManager.addPromotion.promotionType.value.description')}
					</Alert>
					<ValuePromotion
						promotion={props.promotion}
						onChanged={newPromotion => {
							props.onChanged(newPromotion);
						}}
					/>
				</>
			)
		},
		{
			label: t('promotionManager.addPromotion.promotionType.daily.title'),
			value: 1,
			valueSelector: (
				<>
					<Alert variant="info">
						{t('promotionManager.addPromotion.promotionType.daily.description')}
					</Alert>
					<ValuePromotion
						promotion={props.promotion}
						onChanged={newPromotion => {
							props.onChanged(newPromotion);
						}}
					/>
				</>
			)
		},
		{
			label: t('promotionManager.addPromotion.promotionType.sequent.title'),
			value: 2,
			valueSelector: (
				<>
					<Alert variant="info">
						{t(
							'promotionManager.addPromotion.promotionType.sequent.description'
						)}
					</Alert>
					<ValuePromotion
						promotion={props.promotion}
						onChanged={newPromotion => {
							props.onChanged(newPromotion);
						}}
						isSequent
					/>
				</>
			)
		},
		{
			label: t('promotionManager.addPromotion.promotionType.credit.title'),
			value: 3,
			valueSelector: (
				<>
					<Alert variant="info">
						{t(
							'promotionManager.addPromotion.promotionType.credit.description'
						)}
					</Alert>
					<ValuePromotion
						promotion={props.promotion}
						onChanged={newPromotion => {
							props.onChanged(newPromotion);
						}}
						credit
					/>
				</>
			)
		},
		{
			label: t(
				'promotionManager.addPromotion.promotionType.onlineCredit.title'
			),
			value: 5,
			valueSelector: (
				<>
					<Alert variant="info">
						{t(
							'promotionManager.addPromotion.promotionType.onlineCredit.description'
						)}
					</Alert>
					<ValuePromotion
						promotion={props.promotion}
						onChanged={newPromotion => {
							props.onChanged(newPromotion);
						}}
						credit
					/>
				</>
			)
		},
		{
			label: t('promotionManager.addPromotion.promotionType.product.title'),
			value: 4,
			valueSelector: (
				<>
					<Alert variant="info">
						{t(
							'promotionManager.addPromotion.promotionType.product.description'
						)}
					</Alert>
					<ValuePromotion
						promotion={props.promotion}
						onChanged={newPromotion => {
							props.onChanged(newPromotion);
						}}
						product
						productTags={props.productTags}
					/>
				</>
			)
		},
		{
			label: t(
				'promotionManager.addPromotion.promotionType.monthlyBonusCredit.title'
			),
			value: 6,
			valueSelector: (
				<>
					<Alert variant="info">
						{t(
							'promotionManager.addPromotion.promotionType.monthlyBonusCredit.description'
						)}
					</Alert>
					<ValuePromotion
						onlyFixedAmount={true}
						promotion={props.promotion}
						onChanged={newPromotion => {
							props.onChanged(newPromotion);
						}}
					/>
				</>
			)
		}
	];

	const colBackground = () => {
		const edit = document.getElementById('edit');

		if (edit) {
			return classes.ColEdit;
		} else {
			return classes.Col;
		}
	};

	let machineSelector = (
		<Row>
			<Form.Control
				className={classes.Machineselector}
				readOnly
				id={props.id}
				placeholder={placeholderSelect()}
			></Form.Control>
		</Row>
	);
	if (props.promotion && props.promotion.promotionTypeID >= 0) {
		machineSelector = promotionTypes
			.filter(promType => promType.value == props.promotion.promotionTypeID)
			.map(promType => promType.valueSelector)[0];
	}

	const typeCheckboxes = promotionTypes.map((promtype, index) => {
		return (
			<Col xs={12} sm={12} md={12} lg>
				<Form.Check
					required
					type="radio"
					label={promtype.label}
					name="typeRadioSelect"
					id={'productDiscount' + index}
					className={classes.Check}
					value={promtype.value}
					checked={props.promotion.promotionTypeID == promtype.value}
					onChange={e => {
						const newPromotion = { ...props.promotion };
						newPromotion.promotionTypeID = parseInt(e.target.value);
						newPromotion.params = [];
						props.onChanged(newPromotion);
					}}
				/>
			</Col>
		);
	});

	return (
		<Form>
			<Form.Group>
				<Row style={{ paddingLeft: '20px' }}>
					<Form.Label style={{ fontWeight: 'bold' }}>
						{t('promotionManager.addPromotion.promotionType.title')}
					</Form.Label>
					<HelperTooltip
						height={'90px'}
						width={'300px'}
						direction={'top'}
						id={'promotionTypeSelector'}
						title={t(
							'promotionManager.addPromotion.promotionType.tooltip.title'
						)}
						content={t(
							'promotionManager.addPromotion.promotionType.tooltip.content'
						)}
					></HelperTooltip>
				</Row>
				<Row>
					<Col xs={6} sm={6} md={6} lg={12}>
						<Row style={{ margin: '5px' }}>{typeCheckboxes}</Row>
					</Col>
					<Col
						className={colBackground()}
						id={props.id}
						xs={6}
						sm={6}
						md={6}
						lg={12}
					>
						{machineSelector}
					</Col>
				</Row>
			</Form.Group>
		</Form>
	);
};

export default withTranslation()(PromotionTypeSelector);
