//@flow
import React from 'react'
import Brio3 from '../Brio3/Brio3'
import Snakky from '../Snakky/Snakky'

type Props = {}
class Brio3MiniSnakky extends React.Component<Props> {
  render() {
    return (
      <>
        <Brio3 {...this.props} />
        <Snakky isSlave {...this.props} />
      </>
    )
  }
}

export default Brio3MiniSnakky
