import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Logs from '../../views/NewLogs/Logs';

class NewLogsPageRoute extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/newLogs" name="NewLogs" component={Logs} />
                    <Redirect from="/" to="/newLogs" />
                </Switch>
            </div>
        );
    }
}

export default NewLogsPageRoute;