import { createLogger, format, transports } from 'winston'
const { combine, timestamp, printf } = format

const myFormat = printf(({ level, message, category, timestamp }) => {
  return `${timestamp} [${category || 'app'}] ${level}: ${message}`
})

export const logger = createLogger({
  format: combine(timestamp(), myFormat),
  transports: [
    new transports.Console({
      level: process.env.NODE_ENV !== 'production' ? 'debug' : 'info',
      stderrLevels: ['error'],
      consoleWarnLevels: ['warn', 'debug'],
    }),
  ],
})

export default logger
