// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
	Button,
	Col,
	Row,
	TabPane,
	Card,
	CardBody,
	CardTitle
} from 'reactstrap';
import Modal from 'react-bootstrap/Modal';

import { TFunction } from 'i18next';

import { toast, ToastContainer } from 'react-toastify';
import 'ladda/dist/ladda-themeless.min.css';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

// APIs
import type { Subscription } from '../../../services/api/realtime';
import machineAPI from '../../../services/api/machines';
import clientAPI from '../../../services/api/client';
import LogsApi from '../../../services/api/logs';
import ProductApi from '../../../services/api/products';
import LayoutApi from '../../../services/api/layout';
import realtime from '../../../services/api/realtime';

// Utils
import { withRouter, Match, History } from 'react-router-dom';
import UserUtil from '../../../services/util/userUtil';
import VMUtil from '../../../services/util/vmUtil';
import { ROLES } from '../../../services/util/consts';
import logger from '../../../services/logger';

// Components
import VMProductMatrix from './VMProductMatrix/VMProductMatrix.js';
import DashboardCardContainerWithFlow from '../../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import ServicesCard from './ServicesCard';
import ProductCard from './ProductCard';
import StateCard from './StateCard';
import LogsCard from '../../NewLogs/LogsCard/LogsCard';
import SalesAndIncomeCard from '../../../components/SalesAndInomeCard/SalesAndIncomeCard';
import Datasheet from './Datasheet/Datasheet';
import TelemetryAccessDenied from '../../../components/AccessDenied/TelemetryAccessDenied';
import AttachedFilesCard from './AttachedFiles/AttachedFilesCard';
import Spinner from '../../../components/UI/Spinner/Spinner';
import DeleteDeviceButton from '../Devices/AddDeviceDialog/DeleteDeviceButton';
import DeviceMonitor from './DeviceMonitor';
import DeviceDetailsTabs from '../../../components/Tabs';

// Dialogs
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';
import HardwareConflictDialog from '../../HardwareConflictDialog/HardwareConflictDialog';

import qs from 'qs';

const mapStateToProps = state => ({
	user: state.user
});

type Props = {
	className: string,
	t: TFunction,
	match: Match,
	history: History
};
type State = {
	isPromoOpen: boolean,
	isPriceOpen: boolean,
	newPrice: number,
	products: Array<mixed>,
	machine?: Object,
	logs: Array<mixed>,
	refreshing: boolean,
	gallery: Array<any>,
	delete_gallery: Array<string>,
	saveInProgress?: boolean,
	editMode: false
};

class DeviceDetailer extends Component<Props, State> {
	state = {
		isPromoOpen: false,
		isPriceOpen: false,
		newPrice: 0.0,
		products: [],
		logs: [],
		refreshing: false,
		gallery: [],
		machine: undefined,
		saveInProgress: false,
		delete_gallery: [],
		hardwareConflict: {
			showDialog: false
		},
		loading: false,
		activePageKey: 0,
		showScreenshotDialog: false,
		clients: []
	};
	listeners: Array<Subscription> = [];
	isSelectedProductPromotion = 0;
	selectedItem = Object();

	componentDidMount = async () => {
		const decodedvmID = VMUtil.decodeVMID(this.props.match.params.vmID);
		this.setState({ vmID: decodedvmID }, async () => {
			await this.loadData(true);
			// Handle the URL Params
			const urlParams = qs.parse(this.props.location.search, {
				ignoreQueryPrefix: true
			});

			if (urlParams && urlParams.page) {
				let pageIndex = 0;
				try {
					pageIndex = parseInt(urlParams.page);
				} catch (error) {}

				this.setState({ activePageKey: pageIndex });
			}
		});
	};

	componentWillUnmount = () => {
		this.listeners.forEach(listener => listener.unsubscribe());
	};

	loadData = async doSubscribe => {
		this.setState({ loading: true }, async () => {
			const { vmID = '' } = this.state;
			const hardwareID = await this.updateHardwareList(vmID);
			if (doSubscribe) {
				await this.subscribeOnListeners(hardwareID);
			}
			//VM jogosultság
			await this.checkVMPrivilege();
			await this.updateLogs();
			await this.loadVMDetails();
			await this.updateActiveLayout();
			await realtime.turnOffLCD(hardwareID);

			this.setState({ loading: false });
		});
	};

	updateHardwareList = async vmID => {
		const hardwareIDS = await machineAPI.getHardwareIDSByMachineID(vmID);
		const hardwareID = hardwareIDS.length >= 1 ? hardwareIDS[0] : null;
		this.setState({ hardwareID: hardwareID });

		// Load the hardware machine connection's from date.
		// It is neccesary for the conflict handling dialog
		if (hardwareIDS.length > 1) {
			Promise.all(
				hardwareIDS.map(async curID => {
					const fromDate = await machineAPI.getVMConnectionStartByHardwareID(
						curID
					);
					return { hardwareID: curID, fromDate };
				})
			).then(results => {
				this.setState({ hardwareIDS: results });
			});
		} else {
			this.setState({ hardwareIDS: undefined });
		}
		return hardwareID;
	};

	subscribeOnListeners = async hardwareID => {
		this.listeners.push(
			await realtime.subscribeStatusReportForVM(
				hardwareID,
				this.handleStatusReportTopic
			)
		);
		this.listeners.push(
			await realtime.subscribeLayoutRefresh(
				hardwareID,
				this.handleLayoutRefresh
			)
		);
		this.listeners.push(
			await realtime.subscribeCommandResult(
				hardwareID,
				this.handleCommandResult
			)
		);
		this.listeners.push(
			await realtime.subscribeSale(hardwareID, this.handleNewSale)
		);
	};

	updateActiveLayout = async () => {
		const { machine } = this.state;
		if (!machine) return;

		// Load available layouts
		const activeLayoutSet = await machineAPI.getAvailableLayouts(machine.vmID);
		const activeLayout = machine.appliedLayoutID;
		this.setState({
			activeLayoutSet: activeLayoutSet ? activeLayoutSet.layouts : [],
			activeLayout
		});
	};

	handleRealtimeLayoutID = async newLayoutID => {
		await realtime.sendCommand(
			this.state.hardwareID,
			realtime.remoteCommands.setLayout,
			newLayoutID
		);
		this.setState({
			refreshing: true
		});
	};

	refresLayoutPage = async () => {
		await this.loadData();
	};

	isMachineDetailsEnabledForUser = row => {
		return row.supportAccessEnabled || !UserUtil.isRoot();
	};

	handleStatusReportTopic = log => {
		setTimeout(() => {
			this.newLogAlert();
			this.setState({
				logs: [log, ...this.state.logs]
			});
		}, 3000);
	};

	handleLayoutRefresh = async () => {
		await this.loadData();
		this.setState({
			refreshing: false
		});
	};

	handleCommandResult = async commandResult => {
		switch (commandResult.c.toLocaleUpperCase()) {
			case 'TAKESCREENSHOT':
				if (commandResult.raw) {
					this.setState({
						showScreenshotDialog: true,
						screenShot: `data:image/png;base64,${commandResult.raw}`
					});
				}
				break;
			case 'GETLOGFILE':
				toast.info(this.props.t('commandPanel.newLogFile'));
				break;
			case 'STOPDEBUG':
				toast.info(this.props.t('commandPanel.newLogFile'));
				break;
			default:
				break;
		}
	};

	handleNewSale = () => {
		setTimeout(() => toast.info('New Sale'), 3000); // Delay to wait for record insertion
	};

	hideScreenshotDialog() {
		this.setState({ showScreenshotDialog: false, screenShot: null });
		//	this.loadData();
	}

	async getClients() {
		let clients = await clientAPI.getClients(this.state.machine.cmID);
		if (clients && clients.data && clients.data.partnerClients)
			return clients.data.partnerClients;
	}

	async handleFixMachine() {
		await machineAPI.updateMachineState(this.state.vmID, 0);
		this.loadVMDetails();
		this.setState({ showMaintenanceDialog: true });
	}

	async handleAddMaintenance() {
		this.addMaintenanceEvent();
	}

	handleShowConflictHandlingDialogClick() {
		this.showHardwareConflictDialog();
	}

	async handleConflicts(hardwaresToChange) {
		Promise.all(
			hardwaresToChange.map(async hardware => {
				const newMachineName =
					hardware.machineNameToConnect &&
					hardware.machineNameToConnect.trim().length > 0
						? hardware.machineNameToConnect
						: null;
				await machineAPI.updateHardwareConnection(
					hardware.hardwareID,
					hardware.toDate,
					newMachineName
				);

				if (newMachineName)
					realtime.updateMachineName(hardware.hardwareID, newMachineName);
			})
		).then(() => {
			this.hideHardwareConflictDialog();
			this.loadData();
		});
	}

	handleActivePageChange(newPageKey) {
		this.setState({ activePageKey: newPageKey }, () => {
			const newURL = `#/vms/${this.props.match.params.vmID}/detailer?page=${newPageKey}`;
			// We only want to replace the current url without any navigation event,
			// since we have already loaded every neccesary data
			window.history.pushState({}, null, newURL);
		});
	}

	showHardwareConflictDialog() {
		this.setState({ hardwareConflict: { showDialog: true } });
	}

	hideHardwareConflictDialog() {
		this.setState({ hardwareConflict: { showDialog: false } });
	}

	newLogAlert = () => {
		return toast.warn('New log!');
	};

	updateLogs = async () => {
		this.setState({ logs: [] });

		var vmID = this.state.vmID;

		try {
			const response = await LogsApi.getLogs(vmID);
			if (!response.data.hasOwnProperty('response')) {
				this.setState({ logs: [] });
			} else {
				const responseLogs = Object.values(response.data.response);
				const logs = responseLogs.reduce((logs, responseLog: any) => {
					try {
						const log = JSON.parse(responseLog.log);
						logs.push(log);
					} catch (error) {}
					return logs;
				}, []);
				this.setState({ logs });
			}
		} catch (error) {
			logger.error(error);
		}
	};

	//GET VM DETAIL
	loadVMDetails = () => {
		return new Promise(async (resolve, reject) => {
			logger.info('VM details loadin started');
			var vmID = this.state.vmID;

			try {
				const response = await machineAPI.getVMDetail(vmID);
				const machine = Object(response.data.response);
				this.setState(
					{
						machine
					},
					() => resolve()
				);
			} catch (error) {
				logger.error(error);
				reject();
			}
		});
	};

	//VM jogosultság vizsgálata
	checkVMPrivilege = async () => {
		if (UserUtil.isRoot()) return;

		//Customer és Operátor esetén van-e jogosultsága a nézethez
		if (!UserUtil.isTechnican()) {
			await this.isCustomerVM();
		} else if (UserUtil.isRegionalCompanyAdmin()) {
			await this.isOperatorVM();
		}
	};

	//IS CUSTOMER VM
	isCustomerVM = async () => {
		//VmID az url-ből
		const vmID = this.state.vmID;
		try {
			const response = await machineAPI.isCustomerVM(vmID);
			if (response.data.hasOwnProperty('error')) {
				this.props.history.push('logout');
			}
		} catch (error) {
			logger.error(error);
		}
	};

	//IS OPERATOR VM
	isOperatorVM = async () => {
		const vmID = this.state.vmID;
		try {
			const response = await machineAPI.isOperatorVM(vmID);
			if (response.data.hasOwnProperty('error')) {
				this.props.history.push('logout');
			}
		} catch (error) {
			logger.error(error);
		}
	};

	hiddeAllModal = () => {
		this.setState({
			isPromoOpen: false,
			isPriceOpen: false
		});
	};

	showPromoModel = () => {
		this.setState({
			isPromoOpen: true
		});
	};

	showPriceModel = () => {
		this.setState({
			isPriceOpen: true
		});
	};

	onAddImage = files => {
		this.setState({
			gallery: [...files, ...this.state.gallery]
		});
	};

	onRemoveImage = ({ index = null, id = null }) => {
		const { images = [] } = this.state.machine || {};
		const gallery = this.state.gallery.filter((image, idx) => {
			return idx !== index;
		});

		const delete_gallery = id
			? [id, ...this.state.delete_gallery]
			: this.state.delete_gallery;

		const machine = {
			...this.state.machine,
			images: images.filter(({ id }) => !delete_gallery.includes(id))
		};
		this.setState({
			machine,
			gallery,
			delete_gallery
		});
	};

	handleNameChange = (name: string) => {
		this.setState({
			machine: {
				...this.state.machine,
				name
			}
		});
	};

	handleAddressChange = target => {
		let machine = { ...this.state.machine };
		machine[target.id] = target.value;
		this.setState({
			machine
		});
	};

	handleLongitudeChange = (lng: string) => {
		this.setState({
			machine: {
				...this.state.machine,
				lng
			}
		});
	};

	handleLattitudeChange = (lat: string) => {
		this.setState({
			machine: {
				...this.state.machine,
				lat
			}
		});
	};

	handleAccessChange = newValue => {
		this.setState({
			machine: {
				...this.state.machine,
				supportAccessEnabled: newValue ? 'Y' : 'N'
			}
		});
	};

	handleMapVisibilityChange = newValue => {
		this.setState({
			machine: {
				...this.state.machine,
				invisibleTouchVisibleOnMap: newValue ? 'Y' : 'N'
			}
		});
	};

	handleInvTouchVisiblityChange = newValue => {
		this.setState({
			machine: {
				...this.state.machine,
				invisibleTouchOperationEnabled: newValue ? 'Y' : 'N'
			}
		});
	};

	handleNfdUpgStateChange = newValue => {
		this.setState({
			machine: {
				...this.state.machine,
				nfdUpgraded: newValue ? 'Y' : 'N'
			}
		});
	};

	handleCustomNameChange = newValue => {
		this.setState({
			machine: {
				...this.state.machine,
				customName: newValue
			}
		});
	};

	handleInvisibleChange = newValue => {
		this.setState({
			machine: {
				...this.state.machine,
				invisibleTouchServiceEnabled: newValue ? 'Y' : 'N'
			}
		});
	};
	handleTelemtryChange = newValue => {
		this.setState({
			machine: {
				...this.state.machine,
				telemetryServiceEnabled: newValue ? 'Y' : 'N'
			}
		});
	};
	handleRemoteChange = newValue => {
		this.setState({
			machine: {
				...this.state.machine,
				remoteControlServiceEnabled: newValue ? 'Y' : 'N'
			}
		});
	};

	async addMaintenanceEvent() {
		await machineAPI.addMaintenanceEvent(this.state.machine.hardwareID);
		toast.info(this.props.t('device.details.maintenanceAdded'));
	}

	saveMachine = async () => {
		this.setState({ saveInProgress: true });

		const { gallery = [], delete_gallery = [], machine = {} } = this.state;
		const { vmID = '', name } = machine;

		await machineAPI.updateVendingMachine(
			vmID,
			machine.customName,
			machine.country,
			machine.zipcode,
			machine.city,
			machine.address,
			machine.lng,
			machine.lat
		);

		await machineAPI.updateSupportAccess(
			machine.hardwareID,
			machine.supportAccessEnabled
		);
		await machineAPI.updateInvisibleTouchVisibleOnMap(
			machine.vmID,
			machine.invisibleTouchVisibleOnMap
		);
		await machineAPI.updateInvisibleTouchOperation(
			machine.vmID,
			machine.invisibleTouchOperationEnabled
		);
		await machineAPI.updateInvisibleTouchOperation(
			machine.vmID,
			machine.invisibleTouchOperationEnabled
		);
		if (this.props.user.privilege === ROLES.root) {
			await machineAPI.updateInvisibleTouch(
				machine.vmID,
				machine.invisibleTouchServiceEnabled
			);
			await machineAPI.updateTelemetryService(
				machine.vmID,
				machine.telemetryServiceEnabled
			);
			await machineAPI.updateRemoteControlService(
				machine.vmID,
				machine.remoteControlServiceEnabled
			);
		}

		await machineAPI.updateNfdUgdState(machine.vmID, machine.nfdUpgraded);

		const formData = new FormData();
		gallery.forEach(({ file }) => formData.append('gallery', file));
		delete_gallery.forEach(id => formData.append('delete_gallery', id));
		formData.append('name', name);

		try {
			const response = await machineAPI.saveMachineGalery(vmID, formData);

			var newMachine = Object(response.data);
			newMachine = { ...this.state.machine };

			this.setState({
				machine: newMachine,
				gallery: [],
				delete_gallery: []
			});
		} catch (e) {
			console.error(e);
		}

		await this.loadVMDetails();
		this.setState({ saveInProgress: false, editMode: false });
	};

	cancelLayoutEdit = () => {
		this.setState({ saveInProgress: false, editMode: false });
	};

	setActiveLayout = layout => {
		if (this.state.machine.telemetryServiceEnabled === 'Y') {
			realtime.sendCommand(
				this.state.machine.hardwareID,
				realtime.remoteCommands.setLayout,
				layout.uniqueID
			);
			this.setState({
				refreshing: true
			});
		} else {
			machineAPI.setActiveLayout(this.state.machine.vmID, layout.layoutID);
			this.setState({
				refreshing: true
			});
			setTimeout(async () => {
				await this.loadData();
				this.setState({
					refreshing: false
				});
			}, 5000);
		}

		this.setState({ saveInProgress: false, editMode: false });
	};

	async cancelEdit() {
		try {
			await this.loadVMDetails();
		} catch (e) {
			console.error(e);
		}
		this.setState({ saveInProgress: false, editMode: false });
	}

	setOfflineLayout = param => {
		LayoutApi.SetlayoutForofflineMachine(param);
		this.setState({ saveInProgress: false, editMode: false });
	};

	copyKeyboardSettings() {
		return this.state.machineKeyboardList.map(keyboard => {
			const newKeyboard = { ...keyboard };
			newKeyboard.buttons = [...keyboard.buttons];
			return newKeyboard;
		});
	}

	renderMaintenanceConfirmationModal() {
		const { t } = this.props;
		return (
			<ConfirmationDialog
				className="modal-info"
				isOpen={this.state.showMaintenanceDialog}
				onToggle={() =>
					this.setState({
						showMaintenanceDialog: !this.state.showMaintenanceDialog
					})
				}
				title={t('device.details.question')}
				confirmationText={t('device.details.questionText')}
				cancelText={t('device.details.no')}
				okText={t('device.details.yes')}
				onCancel={() =>
					this.setState({
						showMaintenanceDialog: !this.state.showMaintenanceDialog
					})
				}
				onOk={() => {
					this.setState({
						showMaintenanceDialog: !this.state.showMaintenanceDialog
					});
					this.addMaintenanceEvent();
				}}
				infoDialog
			/>
		);
	}

	renderHardwareConflictDialog() {
		return (
			<HardwareConflictDialog
				hardwareIDS={this.state.hardwareIDS}
				show={this.state.hardwareConflict.showDialog}
				onOk={hardwaresToChange => this.handleConflicts(hardwaresToChange)}
				onCancel={() => this.hideHardwareConflictDialog()}
			/>
		);
	}

	renderScreenshotDialog() {
		const { t } = this.props;
		let img = '';
		if (this.state.screenShot) {
			img = this.state.screenShot;
		}

		return (
			<Modal
				show={this.state.showScreenshotDialog}
				onHide={() => this.hideScreenshotDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i class="icon-desktop" style={{ marginRight: '10px' }}></i>
						{t('commandPanel.scrShot')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col
							style={{
								display: 'flex',
								felxDirection: 'collumn',
								justifyContent: 'center'
							}}
						>
							<img src={img} width="350px"></img>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		);
	}

	renderPage() {
		const { t } = this.props;

		const {
			products,
			machine = {},
			refreshing,
			gallery = [],
			layoutRefreshing,
			saveInProgress = false,
			clients
		} = this.state;

		const multipleHardwareAlert = (
			<Row style={{ justifyContent: 'Center' }}>
				<div
					className="alert alert-primary"
					role="alert"
					onClick={() => this.handleShowConflictHandlingDialogClick()}
					style={{ cursor: 'pointer' }}
				>
					<div style={{ display: 'inline-block', marginRight: '1rem' }}>
						<span className="fa fa-exclamation" />
					</div>
					{this.props.t('device.hardwareConflict')}
				</div>
			</Row>
		);

		const noAssignedHardwareAlert = (
			<Row style={{ justifyContent: 'Center' }}>
				<div
					className="alert alert-primary"
					role="alert"
					style={{ cursor: 'pointer' }}
				>
					<div style={{ display: 'inline-block', marginRight: '1rem' }}>
						<span className="fa fa-exclamation" />
					</div>
					{this.props.t('device.missingHardware')}
				</div>
			</Row>
		);

		//const DeviceMonitor = machine ? createDeviceMonitor(machine.machine) : null;
		const deviceMonitor =
			machine && machine.machine ? (
				<DeviceMonitor machine={machine} products={products} />
			) : null;
		let location =
			(machine.zipcode ? machine.zipcode : '') +
			' ' +
			(machine.address ? machine.address : '') +
			' ' +
			(machine.city ? machine.city : '') +
			' ' +
			(machine.country ? machine.country : '');

		return (
			<div className="animated fadeIn">
				<Row>
					<DashboardCardContainerWithFlow>
						{this.renderMaintenanceConfirmationModal()}
						{this.renderHardwareConflictDialog()}
						{this.renderScreenshotDialog()}
						<div
							style={{
								...this.props.style,
								minWidth: '100%'
							}}
						>
							<Row
								style={{
									justifyContent: 'space-between',
									marginBottom: '10px'
								}}
							>
								<Col xs={12} sm={6} md={6} lg={3}>
									<CardTitle className="mb-0 text-muted">
										<i
											className="icon-settings"
											style={{ marginRight: '10px' }}
										></i>
										{t('deviceDetailer.title')}
									</CardTitle>

									<div className="small text-muted"></div>
								</Col>
								<Col xs={12} sm={12} md={12} lg={6}>
									<Row>
										<Col xs={12} sm={12} md={6} lg={6}>
											<label style={{ marginRight: '10px', fontSize: 'large' }}>
												{t('device.details.name')}
												{': '}
											</label>
											<label
												style={{
													marginRight: '10px',
													fontSize: 'large',
													fontWeight: 'bold'
												}}
											>
												{machine.customName}{' '}
											</label>
										</Col>
										<Col xs={12} sm={12} md={6} lg={6}>
											<label style={{ marginRight: '10px', fontSize: 'large' }}>
												{t('device.details.geoLocation')}
												{': '}
											</label>
											<label
												style={{
													marginRight: '10px',
													fontSize: 'large',
													fontWeight: 'bold'
												}}
											>
												{location && location.length > 3
													? location
													: t('promotionManager.promotionTable.noData')}
											</label>
										</Col>
									</Row>
								</Col>
								<Col
									xs={12}
									sm={12}
									md={12}
									lg={3}
									style={{ display: 'flex', justifyContent: 'flex-end' }}
								>
									<Button
										style={{ height: '40px' }}
										className="DefaultButtonMd"
										color="info"
										onClick={
											this.props.user.privilege === ROLES.root
												? () => this.props.history.push('/partnerManagement')
												: () => this.props.history.push('/vms')
										}
									>
										<i className={'fa fa-reply mr-2'}></i>{' '}
										{t('device.details.backButton')}
									</Button>
								</Col>
							</Row>
							{this.state.hardwareIDS && this.state.hardwareIDS.length > 1
								? multipleHardwareAlert
								: null}
							{!this.state.hardwareID ? noAssignedHardwareAlert : null}
							<Row style={{ justifyContent: 'space-between' }}>
								<Col sm="12">
									<DeviceDetailsTabs
										onChangeActivePage={newPageKey =>
											this.handleActivePageChange(newPageKey)
										}
										activetab={this.state.activePageKey}
									>
										<TabPane title={t('device.details.overview')}>
											<Row>
												<Col md="6" style={{ display: 'flex' }}>
													{this.state.editMode ? (
														<>
															<LaddaButton
																className="btn btn-success btn-ladda DefaultButton"
																loading={saveInProgress}
																data-style={EXPAND_LEFT}
																onClick={this.saveMachine}
																style={{ marginRight: '0.5rem' }}
																disabled={
																	!(
																		this.state.machine.customName &&
																		this.state.machine.customName.length > 0
																	)
																}
															>
																<i className="fa fa-floppy-o mr-2" />
																{t('device.details.save')}
															</LaddaButton>
															<LaddaButton
																className="btn btn-primary btn-ladda DefaultButton"
																loading={saveInProgress}
																data-style={EXPAND_LEFT}
																onClick={() => this.cancelEdit()}
															>
																<i className="fa fa-check-circle mr-2" />
																{t('device.details.cancel')}
															</LaddaButton>
														</>
													) : (
														<LaddaButton
															className={`btn btn-success btn-ladda ${
																this.props.i18n &&
																this.props.i18n.language === 'en'
																	? 'DefaultButton'
																	: 'DefaultButtonMd'
															}`}
															loading={saveInProgress}
															data-style={EXPAND_LEFT}
															onClick={() => this.setState({ editMode: true })}
														>
															<i className="fa fa-pencil mr-2" />
															{t('device.details.edit')}
														</LaddaButton>
													)}
													<LaddaButton
														className={`btn btn-success btn-ladda ${
															this.props.i18n &&
															this.props.i18n.language === 'en'
																? 'DefaultButtonXl'
																: 'DefaultButtonXxl'
														}`}
														loading={saveInProgress}
														data-style={EXPAND_LEFT}
														style={{ marginLeft: '0.5rem' }}
														onClick={() => this.handleAddMaintenance()}
														disabled // to do
													>
														<i className="fa fa-wrench mr-2" />
														{t('device.details.addMaintenanceEvent')}
													</LaddaButton>
												</Col>
											</Row>
											<br />

											{machine && (
												<Row>
													<Col md="6">
														<StateCard
															machine={machine}
															gallery={gallery}
															onAddImage={this.onAddImage}
															onRemoveImage={this.onRemoveImage}
															handleNameChange={this.handleNameChange}
															handleAddressChange={this.handleAddressChange}
															handleLongitudeChange={this.handleLongitudeChange}
															handleLattitudeChange={this.handleLattitudeChange}
															handleCustomNameChange={
																this.handleCustomNameChange
															}
															handleAccessChange={this.handleAccessChange}
															handleMapVisibilityChange={
																this.handleMapVisibilityChange
															}
															handleToggleInvTouchOperation={
																this.handleInvTouchVisiblityChange
															}
															handleNfdUpgStateChange={
																this.handleNfdUpgStateChange
															}
															editMode={this.state.editMode}
															onFixMachine={() => this.handleFixMachine()}
														/>
													</Col>

													<Col md="6">
														{this.props.user.privilege === ROLES.root ? (
															<ServicesCard
																machine={machine}
																handleToggleInvisibleTouch={
																	this.handleInvisibleChange
																}
																handleToggleTelemetry={
																	this.handleTelemtryChange
																}
																handleToggleRemote={this.handleRemoteChange}
																editMode={this.state.editMode}
																root={this.props.user.privilege === ROLES.root}
																companyAdmin={
																	this.props.user.privilege ===
																	ROLES.companyAdmin
																}
																regionalAdmin={
																	this.props.user.privilege ===
																	ROLES.regionalCompanyAdmin
																}
															/>
														) : null}
														{this.state.editMode ? (
															<DeleteDeviceButton
																{...this.props}
																root={this.props.user.privilege === ROLES.root}
																vmID={machine.vmID}
																reload={this.props.reload}
																machine={machine}
															/>
														) : null}
														<ProductCard machine={machine} />
													</Col>
												</Row>
											)}
										</TabPane>

										{machine && this.isMachineDetailsEnabledForUser(machine) ? (
											<TabPane title={t('device.details.sales.title')}>
												{/* {machine && <SalesTable vmId={machine.vmID} />} */}
												{machine &&
													(machine.telemetryServiceEnabled === 'Y' ||
													machine.invisibleTouchServiceEnabled == 'Y' ? (
														<SalesAndIncomeCard day={1} vmId={machine.vmID} />
													) : (
														<TelemetryAccessDenied />
													))}
											</TabPane>
										) : null}
										{machine && this.isMachineDetailsEnabledForUser(machine) ? (
											<TabPane title={t('device.details.events.title')}>
												{machine.telemetryServiceEnabled === 'Y' ? (
													<LogsCard
														days={1}
														showGrid={true}
														machine={machine}
														hideLink={true}
													/>
												) : (
													<TelemetryAccessDenied />
												)}

												{/* <EventListCard
													days={7}
													showGrid={true}
													machine={machine}
													hideLink={true}
												/> */}
											</TabPane>
										) : null}
										{machine && this.isMachineDetailsEnabledForUser(machine) ? (
											<TabPane
												title={t('device.details.productList.title')}
												//	title={'Layout manager'}
											>
												{machine && machine.vmID && (
													<VMProductMatrix
														products={products}
														loading={refreshing}
														onFreshLayout={layoutRefreshing}
														saveInProgress={refreshing}
														editable={machine.onlineState === 'online'}
														machine={machine}
														editMode={this.state.editMode}
														activeLayout={this.state.activeLayout}
														activeLayoutSet={this.state.activeLayoutSet}
														activePackages={this.state.activePackages}
														refreshOnClick={this.refresLayoutPage}
														onSave={layout => this.setActiveLayout(layout)}
														onCancel={this.cancelLayoutEdit}
														onEdit={() => {
															this.setState({ editMode: true });
														}}
													/>
												)}
											</TabPane>
										) : null}
										{machine && this.isMachineDetailsEnabledForUser(machine) ? (
											<TabPane title={t('device.details.datasheet.title')}>
												<Datasheet
													clients={clients}
													updateMachine={this.updateVMDetails}
													machine={machine}
													vmId={machine.vmID}
													root={this.props.user.privilege === ROLES.root}
													companyAdmin={
														this.props.user.privilege === ROLES.companyAdmin
													}
													regionalAdmin={
														this.props.user.privilege ===
														ROLES.regionalCompanyAdmin
													}
												></Datasheet>
											</TabPane>
										) : null}

										{DeviceMonitor &&
											machine &&
											(machine &&
											this.isMachineDetailsEnabledForUser(machine) &&
											machine.hardwareID &&
											machine.hardwareID.toLocaleUpperCase()[0] != 'V' ? (
												<TabPane
													title={t('device.details.deviceMonitor.title')}
												>
													{deviceMonitor}
												</TabPane>
											) : null)}
										{machine && this.isMachineDetailsEnabledForUser(machine) ? (
											<TabPane title={t('attachedFiles.title')}>
												<AttachedFilesCard vmId={machine.vmID} />
											</TabPane>
										) : null}
									</DeviceDetailsTabs>
								</Col>
							</Row>
						</div>
					</DashboardCardContainerWithFlow>
				</Row>
			</div>
		);
	}

	render() {
		return <> {this.state.loading ? <Spinner /> : this.renderPage()}</>;
	}
}

export default connect(mapStateToProps)(
	withRouter(withTranslation()(DeviceDetailer))
);
