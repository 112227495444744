import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Toggle from 'react-bootstrap-toggle';

import classes from './EditHID.module.css';

class EditHID extends Component {
	state = {};

	componentDidMount() {
		if (this.props.HID) {
			this.setState({
				HID: {
					machineType: this.props.HID.machineType,
					softwareVersion: this.props.HID.softwareVersion,
					hardwareState: this.props.HID.hardwareState,
					invisibleTouchCapable: this.props.HID.invisibleTouchCapable
				}
			});
		}
	}

	hardwareStateSelectUpdate(e) {
		let HID = { ...this.state.HID };
		HID.hardwareState = e.target.value;
		this.setState({ HID: HID });
	}

	machineTypeSelectUpdate(e) {
		let HID = { ...this.state.HID };
		HID.machineType = e.target.value;
		this.setState({ HID: HID });
	}

	dateSelectionUpdate(newDate) {
		let HID = { ...this.state.HID };
		HID.manufactureDate = newDate;
		this.setState({ HID });
	}

	inputUpdate(e) {
		let HID = { ...this.state.HID };
		HID[e.target.id] = e.target.value;
		this.setState({ HID: HID });
	}

	getEditedIDs() {
		return this.props.editedHIDs.map(HID => HID.id).join();
	}

	handleFormSubmit(e) {
		e.preventDefault();
		this.props.onSave(this.state.HID, this.props.editedHIDs);
	}

	render() {
		const machineTypeOptions = this.props.machineTypes.map(item => (
			<option key={item.id} value={item.id}>
				{item.machinetype}
			</option>
		));

		const hardwareStateOptions = this.props.hardwareStates.map(item => (
			<option key={item.id} value={item.id}>
				{item.statename}
			</option>
		));

		const companyOptions = this.props.companies.map(item => (
			<option key={item.cID} value={item.cID}>
				{item.company}
			</option>
		));
		companyOptions.unshift(<option key="" value=""></option>);

		if (!this.state.HID) {
			return <></>;
		} else {
			return (
				<Form onSubmit={e => this.handleFormSubmit(e)}>
					<Form.Row>
						<Form.Label>
							{this.props.t('hardwareManagement.hardwareList.HID.deviceID')}
						</Form.Label>
						<Form.Control
							id="id"
							type="text"
							disabled
							value={this.getEditedIDs()}
						/>
					</Form.Row>
					<Form.Row>
						<Form.Label>
							{this.props.t('hardwareManagement.hardwareList.HID.state')}
						</Form.Label>
						<Form.Control
							id="hardwareState"
							as="select"
							onChange={e => this.hardwareStateSelectUpdate(e)}
							value={this.state.HID.hardwareState}
						>
							{hardwareStateOptions}
						</Form.Control>
					</Form.Row>
					<Form.Row>
						<Form.Label>
							{this.props.t('hardwareManagement.hardwareList.HID.machineType')}
						</Form.Label>
						<Form.Control
							id="machineType"
							as="select"
							onChange={e => this.machineTypeSelectUpdate(e)}
							value={this.state.HID.machineType}
						>
							{machineTypeOptions}
						</Form.Control>
					</Form.Row>
					<Form.Row>
						<Form.Label>
							{this.props.t(
								'hardwareManagement.hardwareList.HID.softwareVersion'
							)}
						</Form.Label>
						<Form.Control
							id="softwareVersion"
							type="text"
							placeholder={this.props.t(
								'hardwareManagement.hardwareList.HID.softwareVersionPlaceholder'
							)}
							value={this.state.HID.softwareVersion}
							onChange={e => this.inputUpdate(e)}
						/>
					</Form.Row>
					<div className={classes.ToolbarDiv}>
						{this.props.showDeleteButton ? (
							<Button
								variant="primary"
								onClick={() => this.props.onDelete(this.state.person)}
								className={classes.DeleteButton + '  DefaultButton'}
							>
								<i className="fa fa-minus mr-2" aria-hidden="true"></i>&nbsp;
								{this.props.t('partnerManagement.customerGeneral.delete')}
							</Button>
						) : null}
						<Button variant="success" type="submit" className="DefaultButton">
							<i className="fa fa-floppy-o mr-2" aria-hidden="true"></i>&nbsp;
							{this.props.t('partnerManagement.customerGeneral.save')}
						</Button>
					</div>
				</Form>
			);
		}
	}
}

export default withTranslation()(EditHID);
