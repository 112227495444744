import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import walletApi from '../../../services/api/wallet';
import InvoiceTable from './InvoiceTable';
import HelperTooltip from '../../../components/UI/HelperTooltip/HelperTooltip';
import Spinner from '../../../components/UI/Spinner/Spinner';
import machineAPI from '../../../services/api/machines';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Toggle from 'react-bootstrap-toggle';
import Button from 'react-bootstrap/Button';
import classes from './InvoiceList.module.css';

const filterParams = {
	EMAIL: 1,
	STATE: 2
};

class InvoiceList extends Component {
	state = {
		data: [],
		elementVisibilities: [],
		filteredData: []
	};

	componentDidMount() {
		this.loadMachines();
	}

	loadMachines = async () => {
		let vmId = [];
		let machines = [];
		if (!this.props.vmId) {
			await machineAPI.getVisibleVendingMachines().then(res => {
				if (
					res &&
					res.data &&
					res.data.response &&
					res.data.response.length > 0
				) {
					machines = res.data.response;
					vmId = res.data.response.map(e => {
						return e.vmID;
					});
				}
			});
		}
		let startDate = this.getToday(0);
		let endDate = this.getToday(this.props.day);
		this.setState(
			{ startDate: startDate, endDate: endDate, vmId: vmId, machines },
			() => this.loadData()
		);
	};

	loadData = () => {
		if (
			this.props.partnerWalet &&
			this.props.partnerWalet.length > 0 &&
			this.props.partnerWalet[0].id
		) {
			let param = {
				walletID: this.props.partnerWalet[0].id,
				fromDate: this.state.startDate,
				toDate: this.state.endDate
			};

			walletApi.getTransactionsOfPartnerWallet(param).then(res => {
				if (res && res.data && res.data.transactions)
					this.setState({ data: res.data.transactions }, () => {
						this.provideFiltredDataset();
					});
			});
		}
	};

	getToday = day => {
		let today = moment();
		let todayAddDays = today.add(day, 'days').format('YYYY.MM.DD');
		return todayAddDays;
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};
	renderLoadButton = () => {
		return (
			<Button
				className={classes.DefaultFilterButton}
				onClick={() => this.loadData()}
			>
				<i className="fa fa-check mr-2"></i>&nbsp;
				{this.props.t('device.details.sales.timeRange')}
			</Button>
		);
	};

	// ------------ FilterDialog------------

	onShowDialog(value1) {
		this.setState(value1);
	}

	onHideDialog(value2) {
		this.setState(value2);
	}

	stateTypeFormater = e => {
		let element = this.props.t('invisibleTouchManager.controlpanel.noData');
		switch (e) {
			case 3:
				element = this.props.t('invisibleTouchManager.invoicePanel.declained');
				return element;
			case 2:
				element = this.props.t('invisibleTouchManager.invoicePanel.provided');
				return element;
			case 1:
				element = this.props.t('invisibleTouchManager.invoicePanel.requested');
				return element;

			default:
				return element;
		}
	};

	getEmail = element => {
		let name = this.props.t('invisibleTouchManager.controlpanel.noData');
		if (element && element.user && element.user.email)
			name = element.user.email;
		return name;
	};

	getSetOptions = param => {
		const distinct = array => {
			return [...new Map(array.map(item => [item['label'], item])).values()];
		};
		let product = [];
		switch (param) {
			case filterParams.EMAIL:
				let emailSet = this.state.data
					.filter(e => e.billingStateID !== null)
					.filter(e => e.userWalletID !== null)
					.map(e => {
						let label = this.getEmail(e);
						return { value: e.userWalletID, label: label };
					});
				product = distinct(emailSet);
				return [product, param];
			case filterParams.STATE:
				let stateSet = this.state.data
					.filter(e => e.billingStateID !== null)
					.filter(e => e.userWalletID !== null)
					.map(e => {
						let name = this.props.t(
							'invisibleTouchManager.controlpanel.noData'
						);
						if (e && e.billingStateID)
							name = this.stateTypeFormater(e.billingStateID);
						return { value: e.userWalletID, label: name };
					});
				product = distinct(stateSet.filter(e => e.label));
				return [product, param];
		}
	};

	onToggleElementVisibility(element, param) {
		let elementVisibilities = [...this.state.elementVisibilities];
		let item = elementVisibilities.find(e => e.element === element);
		let set = elementVisibilities.filter(e => e.param === param);
		if (!item) {
			item = { element, visible: false, param };
			elementVisibilities.push(item);
		} else if (set && set.length === 1 && !set[0].visible) {
			elementVisibilities = elementVisibilities.filter(e => e.param !== param);
		} else {
			item.visible = !item.visible;
		}
		this.setState({ elementVisibilities: elementVisibilities }, () => {
			this.provideFiltredDataset();
		});
	}

	isElementTypeVisible(element) {
		let result = true;
		let item = this.state.elementVisibilities.find(e => e.element === element);
		if (item) {
			result = item.visible;
		}
		return result;
	}

	onToggleAllVisibilities(set, param) {
		let newVisibilitySettings = [];
		if (
			this.state.elementVisibilities.filter(item => !item.visible).length === 0
		) {
			newVisibilitySettings = set[0].map(e => {
				return { element: e.value, visible: false, param };
			});
		}
		if (
			this.state.elementVisibilities.filter(item => !item.visible).length !== 0
		) {
			newVisibilitySettings = this.state.elementVisibilities.filter(
				e => e.param !== param
			);
		}
		this.setState({ elementVisibilities: newVisibilitySettings }, () => {
			this.provideFiltredDataset();
		});
	}
	getproductID = value => {
		let productID = null;
		if (value && value.productID) productID = value.productID;
		return productID;
	};

	provideFiltredDataset() {
		let filteredData = this.state.data
			.filter(e => this.isElementTypeVisible(this.getEmail(e)))
			.filter(e =>
				this.isElementTypeVisible(this.stateTypeFormater(e.billingStateID))
			)
			.map((e, index) => {
				return { ...e, index };
			});
		if (this.props.vmId) {
			filteredData = filteredData.filter(e => e.vmID != null);
		}
		this.setState({ filteredData: filteredData });
	}

	renderDialog(set, value2, stateValue) {
		const param = set[1];
		const elementList = set[0].map((element, index) => {
			return (
				<div key={index}>
					<div>
						<strong style={{ marginLeft: '30px' }}>{element.label}</strong>
						<Toggle
							onClick={() =>
								this.onToggleElementVisibility(element.label, param)
							}
							on="show"
							off="hide"
							active={this.isElementTypeVisible(element.label)}
							onstyle="info"
							offstyle="warning"
							size="sm"
							style={{
								width: '75px',
								height: '30px',
								borderRadius: '25px',
								float: 'right'
							}}
						/>
					</div>
					<hr />
				</div>
			);
		});

		return (
			<Modal
				show={stateValue}
				onHide={() => this.onHideDialog(value2)}
				scrollable="true"
			>
				<Modal.Header>
					<Modal.Title>
						{this.props.t('salesSnadIncome.table.visible')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body max-height="260px">{elementList}</Modal.Body>
				<Modal.Footer>
					<Button
						variant={
							this.state.elementVisibilities.filter(item => !item.visible)
								.length === 0
								? 'warning'
								: 'info'
						}
						className="DefaultButtonMd"
						onClick={() => this.onToggleAllVisibilities(set, param)}
					>
						<i className="fa fa-eye mr-2"></i>&nbsp;
						{this.state.elementVisibilities.filter(item => !item.visible)
							.length === 0
							? this.props.t('eventList.hideAll')
							: this.props.t('eventList.showAll')}
					</Button>

					<Button
						variant="success"
						className="DefaultButton"
						onClick={() => this.onHideDialog(value2)}
					>
						<i className="fa fa-times-circle mr-2"></i>&nbsp;
						{this.props.t('eventList.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	resetFilter = () => {
		let newVisibilitySettings = [];

		this.setState({ elementVisibilities: newVisibilitySettings }, () => {
			this.provideFiltredDataset();
		});
	};

	editBillingStateHandler = (bsID, row, walletID) => {
		if (bsID && row && row.id && walletID) {
			let body = {
				transactionID: row.id,
				billingStateID: bsID
			};

			walletApi.editBillingState(body).then(res => this.loadData());
		}
	};

	// ------------ FilterDialog------------

	renderTable = () => {
		return !this.state.filteredData ? (
			<Spinner />
		) : (
			<InvoiceTable
				data={this.state.filteredData}
				currency={this.props.currency}
				customers={this.props.customers}
				priceSum={this.state.priceSum}
				incomeSum={this.state.incomeSum}
				transactionSum={this.state.transactionSum}
				startDate={this.state.startDate}
				endDate={this.state.endDate}
				onFilterChange={this.afterColumnFilter}
				elementVisibilities={this.state.elementVisibilities}
				machines={this.state.machines}
				onDateChange={this.inputChangeHandler}
				onResetFilter={this.resetFilter}
				showEmailFilterDialog={() => this.onShowDialog({ emailDialog: true })}
				showStateFilterDialog={() => this.onShowDialog({ stateDialog: true })}
				loadButton={this.renderLoadButton()}
				onEditBillingState={this.editBillingStateHandler}
			/>
		);
	};

	renderTitle() {
		return (
			<Row style={{ alignItems: 'flex-start' }}>
				<CardTitle
					className="text-muted"
					style={{ cursor: 'pointer', marginLeft: '20px' }}
				>
					<i className={'fa fa-file'} style={{ marginRight: '10px' }} />
					{this.props.t('invisibleTouchManager.invoicesTable.title')}
				</CardTitle>
				<HelperTooltip
					height={'100px'}
					width={'600px'}
					direction={'right'}
					id={'SalesAndIncomeHelperTooltip'}
					title={this.props.t('salesSnadIncome.tooltip.title')}
					content={this.props.t('salesSnadIncome.tooltip.content')}
				></HelperTooltip>
			</Row>
		);
	}

	render() {
		const { t } = this.props;
		return (
			<Card>
				{this.renderDialog(
					this.getSetOptions(filterParams.EMAIL),
					{ emailDialog: false },
					this.state.emailDialog
				)}

				{this.renderDialog(
					this.getSetOptions(filterParams.STATE),
					{ stateDialog: false },
					this.state.stateDialog
				)}

				<CardBody>
					{this.renderTitle()}
					{this.renderTable()}
				</CardBody>
			</Card>
		);
	}
}

export default withTranslation()(InvoiceList);
