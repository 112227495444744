import React, { useEffect } from 'react';
import { Card, Form, FormControl, Button } from 'react-bootstrap';
import {
	Section,
	SectionList,
	SectionSubTitle,
	SectionTitle
} from '../../../components/Section';
import { withTranslation } from 'react-i18next';
import InvisibleTouchutil from '../../../services/util/invisibleTouchUtil';

const BalanceHandlingCard = props => {
	const { t } = props;
	useEffect(() => {
		document.getElementById(InvisibleTouchutil.formID.ADD_BALANCE_FORM).reset();
		document
			.getElementById(InvisibleTouchutil.formID.REMOVE_BALANCE_FORM)
			.reset();
	}, [props.balance]);

	return (
		<>
			<Card>
				<SectionList>
					<Form
						noValidate
						validated={props.addValidated}
						id={InvisibleTouchutil.formID.ADD_BALANCE_FORM}
					>
						<Section>
							<SectionTitle>
								{t('invisibleTouchManager.blancePanel.addCredit')}
							</SectionTitle>
							<SectionSubTitle>
								{t('invisibleTouchManager.blancePanel.credit')}
							</SectionSubTitle>
							<FormControl
								required
								className={'mt-2 mb-2'}
								type="number"
								min="0"
								step="0.01"
								name="addAmount"
								value={props.addAmount}
								onChange={e => props.onInputChange(e)}
							/>
							<SectionSubTitle>
								{' '}
								{t('invisibleTouchManager.blancePanel.remark')}
							</SectionSubTitle>
							<FormControl
								className={'mt-2 mb-3'}
								required
								type="text"
								name="addRemarkText"
								value={props.addRemarkText}
								onChange={props.onInputChange}
							/>
							<Button
								className="DefaultButton"
								variant="success"
								onClick={() => props.onSave(true)}
							>
								<i className="fa fa-floppy-o mr-2" aria-hidden="true"></i>&nbsp;
								{t('partnerManagement.customerGeneral.save')}
							</Button>
						</Section>
					</Form>
					<Form
						noValidate
						validated={props.removeValidated}
						id={InvisibleTouchutil.formID.REMOVE_BALANCE_FORM}
					>
						<Section last>
							<SectionTitle>
								{t('invisibleTouchManager.blancePanel.removeCredit')}
							</SectionTitle>
							<SectionSubTitle>
								{' '}
								{t('invisibleTouchManager.blancePanel.credit')}
							</SectionSubTitle>
							<FormControl
								required
								className={'mt-2 mb-2'}
								type="number"
								min="0"
								step="0.01"
								name="removeAmount"
								value={props.removeAmount}
								onChange={e => props.onInputChange(e)}
							/>
							<SectionSubTitle>
								{t('invisibleTouchManager.blancePanel.remark')}
							</SectionSubTitle>
							<FormControl
								className={'mt-2 mb-3'}
								required
								type="text"
								name="removeRemarkText"
								value={props.removeRemarkText}
								onChange={props.onInputChange}
							/>
							<Button
								className="DefaultButton"
								variant="success"
								onClick={() => props.onSave()}
							>
								<i className="fa fa-floppy-o mr-2" aria-hidden="true"></i>&nbsp;
								{t('partnerManagement.customerGeneral.save')}
							</Button>
						</Section>
					</Form>
				</SectionList>
			</Card>
		</>
	);
};

export default withTranslation()(BalanceHandlingCard);
