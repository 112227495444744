import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { scaleSequential, interpolator } from 'd3-scale';
import {
	interpolateViridis,
	interpolateGreys,
	interpolateRainbow
} from 'd3-scale-chromatic';
import { Row, Button } from 'reactstrap';
import { select } from 'd3';
// import classes from '../CashFlow/IncomeChart.module.css';

const PLChart = props => {
	const getWidth = () => {
		return d3Chart.current ? d3Chart.current.parentElement.offsetWidth : null;
	};
	const getHeight = () => {
		return d3Chart.current ? d3Chart.current.parentElement.offsetHeight : null;
	};

	useEffect(() => {
		const { t } = props;

		const margin = { top: 20, right: 80, bottom: 30, left: 30 };
		const width = getWidth() - margin.left - margin.right;
		const height = getHeight();

		const data = props.data;

		const color = d3
			.scaleOrdinal()
			.domain(
				data.series.map(e => {
					return e.name;
				})
			)
			.range(d3.schemeCategory10);

		const x = d3
			.scaleUtc()
			.domain(d3.extent(data.dates))
			.range([margin.left, width - margin.right]);
		const y = d3
			.scaleLinear()
			.domain([0, d3.max(data.series, d => d3.max(d.values))])
			.nice()
			.range([height - margin.bottom, margin.top]);
		// const enter = svg => {
		// 	if (!loading) svg.selectAll('*');
		// };
		// const remove = (svg, path, legend, tooltip)=> {
		// 	if (loading) svg.selectAll('*').remove();
		// 	setLoading(false);
		// 	svg.call(hover,svg,path, legend, tooltip);
		// };

		function hover(svg, path, legend, tooltip) {
			if ('ontouchstart' in document)
				svg

					.style('-webkit-tap-highlight-color', 'transparent')
					.on('touchmove', moved)
					.on('touchstart', entered)
					.on('touchend', left);
			else
				svg

					.on('mousemove', moved)
					.on('mouseenter', entered)
					.on('mouseleave', left);

			//-------- lineDot -------------
			const dot = svg.append('g').attr('display', 'none');

			dot.append('circle').attr('r', 5);

			function updateTooltipContent(pointer, data, xm, i, da, e, ym) {
				const time = d3.timeFormat('%B %a %d');
				const leftIdx = pointer[0] < width / 1.5 ? +180 : -230;
				tooltip
					.html(time(xm))

					.style('display', 'block')
					.style('left', pointer[0] + leftIdx + 'px')
					.style('top', '55px')
					.style('font-size', '18px')

					.selectAll()
					.data(da)
					.enter()
					.append('div')
					.html('o   ')
					.style('font-size', '16px')
					.style('font-weight', 'bold')
					.style('color', d => {
						return color(d.name);
					})

					.append('text')
					.style('color', '#000')
					.style('font-size', '14px')
					.style('font-weight', 'bold')
					.style('text-align', 'right')
					.text(d => {
						return (
							d.name.slice(0, 12) +
							(d.name.length > 12 ? ' ...' : '') +
							' ' +
							': '
						);
					})

					.append('text')
					.style('font-size', '14px')
					.style('font-weight', 'bold')
					.style('text-align', 'left')
					.style('color', 'grey')
					.text(d => {
						return d.value + ' DB';
					});
			}

			function moved(event) {
				event.preventDefault();
				const e = event;
				const pointer = d3.pointer(event, this);
				const xm = x.invert(pointer[0]);
				const ym = y.invert(pointer[1]);
				const i = d3.bisectCenter(data.dates, xm);
				const s = d3.least(data.series, d => Math.abs(d.values[i] - ym));
				const d0 = data.series[i - 1];
				const d1 = data.series[i];
				const name = data.series.map(e => {
					return e.name;
				});
				const da = data.series.map(e => {
					return { name: e.name, value: e.values[i] };
				});

				updateTooltipContent(pointer, data, xm, i, da, e, ym);
				legend
					.attr('stroke', d => (d === s ? null : '#ddd'))
					.filter(d => d === s)
					.raise();
				path
					.attr('stroke', d => (d === s ? null : '#ddd'))
					.filter(d => d === s)
					.raise();
				dot
					.attr('transform', `translate(${x(data.dates[i])},${y(s.values[i])})`)
					.attr('stroke', color(s.name))
					.attr('stroke-width', 2.5)
					.attr('fill', 'white');
				tooltip.attr('display', null).attr('x', pointer[0]);
			}

			function entered() {
				legend.style('mix-blend-mode', null).attr('stroke', '#bbb');
				path.style('mix-blend-mode', null).attr('stroke', '#bbb');
				d3.select('.mouse-line').attr('display', null);
				d3.select('mouse-line-tooltipLabel').attr('display', null);
				dot.attr('display', null);

				d3.selectAll('#tooltip').style('display', 'block');
			}

			function left() {
				legend.style('mix-blend-mode', 'multiply').attr('stroke', null);
				path.style('mix-blend-mode', 'multiply').attr('stroke', null);
				d3.select('.mouse-line').attr('display', 'none');
				d3.select('mouse-line-tooltipLabel').attr('display', 'none');

				d3.selectAll('#tooltip').style('display', 'none');

				dot.attr('display', 'none');
			}
		}
		// const remove = (svg, path, legend, tooltip) => {
		// 	if (loading) svg.selectAll('*').remove();
		// 	setLoading(false);
		// 	svg.call(hover, svg, path, legend, tooltip);
		// };
		const xAxis = g =>
			g
				.attr('transform', `translate(-10,${height - margin.bottom})`)
				.call(
					d3
						.axisBottom(x)
						.ticks(width / (props.days === 7 ? 180 : 50))
						.tickSizeOuter(0)
						.tickFormat(d => d3.timeFormat('%m. %d.')(d))
				)

				.call(g =>
					g
						.select('.domain')
						.attr('color', 'lightgray')
						.attr('opacity', '0.2')
				)
				.call(g =>
					g
						.selectAll('.tick')
						.attr('color', 'lightgray')
						.attr('opacity', '0.2')
				)
				.selectAll('text')
				.style('text-anchor', 'start')
				.style('fill', '#000')
				.style('stroke', 'none')
				.attr('font-size', '12px');

		const yAxis = g =>
			g
				.attr('transform', `translate(${margin.left - 5},0)`)
				.call(d3.axisLeft(y))
				.call(g =>
					g
						.select('.domain')
						.attr('color', 'lightgray')
						.attr('opacity', '0.2')
				)
				.call(g =>
					g
						.select('.tick:last-of-type text')
						.clone()
						.attr('x', 3)
						.attr('text-anchor', 'start')
						.attr('font-weight', 'bold')
						.text(data.y)
				)
				.call(g => g.selectAll('.tick line'))
				.call(g =>
					g
						.selectAll('.tick')
						.attr('color', 'lightgray')
						.attr('opacity', '0.2')
				)

				.selectAll('text')
				.style('fill', '#000')
				.style('stroke', 'none')

				.attr('font-size', '12px');
		//---eredeti-----------
		const svg = d3
			.select(d3Chart.current)
			.attr('viewBox', [0, 0, width, height])
			.style('overflow', 'visible');

		// svg.selectAll('*').remove();
		//---eredeti-----------
		// //----szétbontott hívás ----
		// const svg = d3.select(d3Chart.current).data([data]);
		// // Enter hook
		// const svgEnter = svg.enter();
		// // Update cycle
		// //	svgEnter.append('svg').append('g');
		// svg
		// 	.attr('width', width + margin.left + margin.right)
		// 	.attr('height', height + margin.top + margin.bottom);

		// // Exit cycle (not really needed here)
		// svg.exit().remove();
		// //----szétbontott hívás ----
		svg.append('g').call(xAxis);

		svg.append('g').call(yAxis);
		const line = d3
			.line()
			.defined(d => !isNaN(d))
			.x((d, i) => x(data.dates[i]))
			.y(d => y(d));

		const path = svg
			.append('g')
			.attr('class', 'line')
			.attr('fill', 'none')
			.selectAll('path')
			.style('mix-blend-mode', 'multiply')
			.data(data.series)
			.join('path')
			.attr('stroke', d => color(d.name))
			.attr('stroke-width', 1.5)
			.attr('stroke-linejoin', 'round')
			.attr('stroke-linecap', 'round')
			.attr('d', d => line(d.values));
		//------- right legend -----------------
		const legend = svg
			.append('g')
			.selectAll('text')
			.data(data.series)
			.join('text')
			.attr('fill', d => color(d.name))
			.attr('x', width - margin.right / 2)
			.attr('y', (d, i) => i * 20)
			.text(d => d.name.slice(0, 12) + (d.name.length > 12 ? ' ...' : ''));
		//------------------ tooltip----------------------
		//const tooltip = svg.append('g').attr('display', 'none');
		const tooltip = d3
			.select('#chart')
			.append('div')
			.attr('id', 'tooltip')
			.style('position', 'absolute')
			.style('background-color', '#fefefe')
			.style('opacity', '0.8')
			.style('padding-left', '20px')
			.style('padding-right', '20px')
			.style('padding-top', '10px')
			.style('padding-bottom', '10px')
			.style('display', 'none');

		svg.call(hover, path, legend, tooltip);

		//	svg.call(remove, svg, path, legend, tooltip);
	});
	// useEffect(() => {
	// 	setLoading(true);
	// }, [props.data]);
	const d3Chart = useRef();
	const chart = () => {
		let chart = null;
		// if (loading) {
		chart = (
			<svg
				ref={d3Chart}
				style={{
					width: '100%',
					height: '300px'
				}}
			></svg>
		);
		//	}
		return chart;
	};
	return <div id="chart">{chart()}</div>;
};
export default withTranslation()(PLChart);
