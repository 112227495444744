import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Badge from 'react-bootstrap/Badge';

const DescriptionTooltip = props => {
	const popover = (
		<Popover id="popover-basic">
			<Popover.Title as="h3">{props.title}</Popover.Title>
			<Popover.Content>{props.content}</Popover.Content>
		</Popover>
	);

	return (
		<OverlayTrigger
			trigger="hover"
			placement={props.placement}
			overlay={popover}
		>
			<h5>
				<Badge className={props.className}>{props.btnTitle} </Badge>
			</h5>
		</OverlayTrigger>
	);
};

export default DescriptionTooltip;
