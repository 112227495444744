import React, { Component } from 'react';
//import * as name from 'chartjs-plugin-colorschemes';
import { Line } from 'react-chartjs-2';

class HourlyIncomeLineChart extends Component {
	prepareData() {
		return {
			datasets: this.props.series.map(series => {
				const xySet = series.xValues.map((value, index) => {
					return { x: value, y: series.yValues[index] };
				});
				return {
					label: series.name,
					data: xySet,
					fill: false,
					lineTension: 0
				};
			})
		};
	}

	options = {
		plugins: {
			colorschemes: {
				scheme: 'tableau.HueCircle19'
			},
			datalabels: {
				display: false
			}
		},
		scales: {
			xAxes: [
				{
					type: 'time',
					time: {
						unit: 'hour',
						displayFormats: {
							hour: 'MMM D h:00'
						}
					}
				}
			],
			yAxes: [
				{
					/*ticks: {
						// Include a dollar sign in the ticks
						callback: function(value, index, values) {
							
							switch (value) {
								case 0:
									return 'Error';
								case 1:
									return 'Warning';
								case 2:
									return 'Operational';
								case 3:
									return 'Programming';
								default:
									return '';
							}
						},
						min: 0,
						max: 3
					}*/
				}
			]
		},

		tooltips: {
			callbacks: {
				label: function (tooltipItem) {
					return tooltipItem.yLabel;
				}
			}
		}
	};

	render() {
		const data = this.prepareData();

		return <Line data={data} options={{ ...this.options }} />;
	}
}

export default HourlyIncomeLineChart;
