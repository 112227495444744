import React from 'react';
import { withTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import promotionsApi from '../../../../services/api/promotions';
import moment from 'moment';

const SummaryModal = props => {
	const { t } = props;

	const onSubmitHandler = props => {
		props.onSave(props.data);
	};

	const deviceNameFormater = () => {
		if (props.data.assignedGroupID) {
			let group = props.machineGroups.find(
				e => e.id == props.data.assignedGroupID
			);

			return group.name;
		}
		if (props.data.assignedVMID) {
			let machine = props.machines.find(e => e.vmID == props.data.assignedVMID);

			return machine.customName;
		}
	};

	const promotionNameFormater = value => {
		switch (value) {
			case 0:
				return t('promotionManager.promotionTable.general');
			case 1:
				return t('promotionManager.promotionTable.firstBuy');
			case 2:
				return t('promotionManager.promotionTable.sequental');
			case 3:
				return t('promotionManager.promotionTable.creditBonus');
			case 4:
				return t('promotionManager.promotionTable.product');
			case 5:
				return t('promotionManager.promotionTable.onlineCreditBonus');
			case 6:
				return t('promotionManager.promotionTable.monthlyBonusCredit');
		}
	};
	const aDaySorter = value => {
		let days = value.sort().map(e => {
			let day;
			switch (e) {
				case '1':
					return (day = t(
						'promotionManager.promotionTable.activeDays.days.mon'
					));

				case '2':
					return (day = t(
						'promotionManager.promotionTable.activeDays.days.tue'
					));

				case '3':
					return (day = t(
						'promotionManager.promotionTable.activeDays.days.wed'
					));

				case '4':
					return (day = t(
						'promotionManager.promotionTable.activeDays.days.thu'
					));

				case '5':
					return (day = t(
						'promotionManager.promotionTable.activeDays.days.fri'
					));

				case '6':
					return (day = t(
						'promotionManager.promotionTable.activeDays.days.sut'
					));

				case '7':
					return (day = t(
						'promotionManager.promotionTable.activeDays.days.sun'
					));
			}
		});
		return days;
	};

	const activeDaysFormater = () => {
		let ad = props.data.activeDays;

		const activeDaysList = props.data.activeDays
			? String(props.data.activeDays).split(',')
			: null;
		if (!activeDaysList || activeDaysList === 7 || activeDaysList.length == 0) {
			return (ad = t('promotionManager.promotionTable.activeDays.everyDay'));
		} else {
			let days = aDaySorter(activeDaysList);
			ad = days.map(e => {
				return e;
			});
			return ad.join(',   ');
		}
	};

	return (
		<Form>
			<Col>
				<Form.Group>
					<Col>
						<Form.Label>
							<strong>
								{t('promotionManager.addPromotion.promotionName.title')}
								{':'}
							</strong>
						</Form.Label>
						<Form.Control
							style={{ backgroundColor: 'white' }}
							name="name"
							plaintext
							readOnly
							defaultValue={props.data.name}
						/>
					</Col>
				</Form.Group>
				<Form.Group>
					<Col>
						<Form.Label>
							<strong>
								{t('promotionManager.promotionTable.promotionType')}
								{':'}
							</strong>
						</Form.Label>
						<Form.Control
							style={{ backgroundColor: 'white' }}
							name="promotionType"
							plaintext
							readOnly
							defaultValue={promotionNameFormater(props.data.promotionTypeID)}
						></Form.Control>
					</Col>
				</Form.Group>
				<Form.Group>
					<Col>
						<Form.Label>
							<strong>
								{t('promotionManager.addPromotion.promotionDescription.title')}
								{':'}
							</strong>
						</Form.Label>
						<Form.Control
							style={{ backgroundColor: 'white' }}
							name="description"
							plaintext
							readOnly
							defaultValue={props.data.description}
						/>
					</Col>
				</Form.Group>
				<Form.Group controlId="machineGroupSelector">
					<Col>
						<Form.Label>
							<strong>
								{t('promotionManager.summary.promotionDevice')}
								{':'}
							</strong>
						</Form.Label>
						<Form.Control
							style={{ backgroundColor: 'white' }}
							name="machineGroupSelector"
							plaintext
							readOnly
							defaultValue={deviceNameFormater()}
						></Form.Control>
					</Col>
				</Form.Group>
				<Form.Group controlId="startDate">
					<Col>
						<Form.Label>
							<strong>
								{t('promotionManager.addPromotion.startDate.title')}
								{':'}
							</strong>
						</Form.Label>
						<Form.Control
							style={{ backgroundColor: 'white' }}
							name="startDate"
							plaintext
							readOnly
							defaultValue={moment(props.data.startDate).format('YYYY-MM-DD')}
						/>
					</Col>
				</Form.Group>
				<Form.Group controlId="endDate">
					<Col>
						<Form.Label>
							<strong>
								{t('promotionManager.addPromotion.endDate.title')}
								{':'}
							</strong>
						</Form.Label>
						<Form.Control
							style={{ backgroundColor: 'white' }}
							name="endDate"
							plaintext
							readOnly
							defaultValue={
								props.data.endDate
									? moment(props.data.endDate).format('YYYY-MM-DD')
									: ''
							}
						/>
					</Col>
				</Form.Group>
				<Form.Group controlId="dailyPeriodStart">
					<Col>
						<Form.Label>
							<strong>
								{t('promotionManager.addPromotion.startTime.title')}
								{':'}
							</strong>
						</Form.Label>
						<Form.Control
							style={{ backgroundColor: 'white' }}
							name="dailyPeriodStart"
							plaintext
							readOnly
							defaultValue={props.data.dailyPeriodStart}
						/>
					</Col>
				</Form.Group>
				<Form.Group controlId="dailyPeriodEnd">
					<Col>
						<Form.Label>
							<strong>
								{t('promotionManager.addPromotion.endTime.title')}
								{':'}
							</strong>
						</Form.Label>
						<Form.Control
							style={{ backgroundColor: 'white' }}
							name="dailyPeriodEnd"
							plaintext
							readOnly
							defaultValue={props.data.dailyPeriodEnd}
						/>
					</Col>
				</Form.Group>
				<Form.Group controlId="activeDays">
					<Col>
						<Form.Label>
							<strong>
								{t('promotionManager.promotionTable.activeDays.title')}
								{':'}
							</strong>
						</Form.Label>
						<Form.Control
							style={{ backgroundColor: 'white' }}
							name="activeDays"
							plaintext
							readOnly
							defaultValue={activeDaysFormater()}
						></Form.Control>
					</Col>
				</Form.Group>
			</Col>
			<hr></hr>

			<Row style={{ justifyContent: 'space-between' }}>
				<Col sm={2}>
					<Button
						variant="success"
						className="DefaultButton"
						onClick={() => onSubmitHandler(props)}
					>
						<i className="fa fa-floppy-o mr-2" />
						{t('promotionManager.addPromotion.savebutton')}
					</Button>
				</Col>
				<Col sm={2}>
					<Button
						variant="primary"
						className="DefaultButton"
						onClick={() => props.onHide()}
					>
						<i className="fa fa-times-circle mr-2" />
						{t('promotionManager.deletePromotion.cancelButton')}
					</Button>
				</Col>
			</Row>
		</Form>
	);
};

export default withTranslation()(SummaryModal);
