import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './AddMachineGroupForm.module.css';
import { Row, Col } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import MachineSelectedTable from './MachineSelectedTable';
import MachineSelectorTable from './MachineSelectorTable';
import HelperTooltip from '../../../../components/UI/HelperTooltip/HelperTooltip';

import groupApi from '../../../../services/api/machineGroup';

class AddMachineGroupForm extends Component {
	state = {
		selectedMachines: [],
		newGroupName: '',
		description: '',
		creationDate: new Date(),
		validated: false
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.machines !== this.props.machines) {
			this.setState({});
		}
	}

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	onSubmitHandler = () => {
		let vms = [...this.state.selectedMachines];
		let vmids = vms.map(vm => {
			return vm.vmID;
		});
		let vmIDS = vmids.join(',');

		groupApi
			.createGroup(this.state.newGroupName, this.state.description)
			.then(request => {
				groupApi.getMachineGroups().then(request => {
					const groups = request.data.groups;
					const group = groups[groups.length - 1];
					groupApi
						.setAssignedVms(group.id, vmIDS)
						.then(request => this.props.onHide());
				});
			});
	};

	selectedMachines = value => {
		if (this.state.selectedMachines.length !== value.length) {
			this.setState({ selectedMachines: value });
		}
	};

	loadSelectorTabel = () => {
		return (
			<>
				<MachineSelectorTable
					height={this.props.selected ? '180' : '450'}
					data={this.props.machines}
					onSelectedMachines={this.selectedMachines}
				/>
			</>
		);
	};

	loadSelectedTabel = () => {
		return (
			<>
				<MachineSelectedTable data={this.state.selectedMachines} height="180" />
			</>
		);
	};
	validationHandler = () => {
		const form = document.getElementById('addMachinegroupForm');
		if (
			form.checkValidity() === true &&
			this.state.selectedMachines &&
			this.state.selectedMachines.length > 0
		) {
			this.onSubmitHandler();
		} else {
			this.setState({ validated: true });
		}
	};

	//Render-----------------------------------------------------------------

	render() {
		const { t } = this.props;
		return (
			<Form
				noValidate
				validated={this.state.validated}
				id={'addMachinegroupForm'}
			>
				<Row>
					<Col>
						<Form.Group
							className={classes.FormGroup}
							controlId="newGroupName"
							style={{ padding: '15px' }}
						>
							<Row style={{ alignItems: 'flex-start' }}>
								<Form.Label className={classes.Label}>
									{t('groupManager.addGroup.listName.title')}
								</Form.Label>
								<HelperTooltip
									height={'100px'}
									width={'250px'}
									direction={'right'}
									id={'groupName'}
									title={t('groupManager.addGroup.listName.tooltip.title')}
									content={t('groupManager.addGroup.listName.tooltip.content')}
								></HelperTooltip>
							</Row>
							<Form.Control
								required
								name="newGroupName"
								type="text"
								placeholder={t('groupManager.addGroup.listName.placeholder')}
								value={this.state.newGroupName}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group
							className={classes.FormGroup}
							controlId="description"
							style={{ padding: '15px' }}
						>
							<Row style={{ alignItems: 'flex-start' }}>
								<Form.Label className={classes.Label}>
									{t('groupManager.addGroup.listDescription.title')}
								</Form.Label>
								<HelperTooltip
									height={'100px'}
									width={'250px'}
									direction={'right'}
									id={'groupDescription'}
									title={t(
										'groupManager.addGroup.listDescription.tooltip.title'
									)}
									content={t(
										'groupManager.addGroup.listDescription.tooltip.content'
									)}
								></HelperTooltip>
							</Row>
							<Form.Control
								required
								name="description"
								type="text"
								placeholder={t(
									'groupManager.addGroup.listDescription.placeholder'
								)}
								value={this.state.description}
								onChange={this.inputChangeHandler}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Form.Group
					className={classes.FormGroupTable}
					controlId="addNewMachines"
					style={{ padding: '15px' }}
				>
					<Row style={{ alignItems: 'flex-start' }}>
						<Form.Label className={classes.Label}>
							{t('groupManager.addGroup.selectorTable.title')}
						</Form.Label>
						<HelperTooltip
							height={'100px'}
							width={'400px'}
							direction={'right'}
							id={'machineSelector'}
							title={t('groupManager.addGroup.selectorTable.tooltip.title')}
							content={t(
								'groupManager.addGroup.selectorTable.tooltip.device.content'
							)}
						></HelperTooltip>
					</Row>
					{this.loadSelectorTabel()}
				</Form.Group>
				{this.props.selected ? (
					<Form.Group
						className={classes.FormGroupTable}
						controlId="NewMachineGroup"
						style={this.props.style}
					>
						<Form.Label className={classes.Label}>
							<strong>{t('groupManager.addGroup.selectedTable')}</strong>
						</Form.Label>

						{this.loadSelectedTabel()}
					</Form.Group>
				) : null}
				<hr></hr>
				<Button
					variant="success"
					className="DefaultButton"
					onClick={e => this.validationHandler()}
				>
					<i className="fa fa-floppy-o mr-2" />
					{t('groupManager.addGroup.savebutton')}
				</Button>
				{this.state.validated &&
				this.state.selectedMachines &&
				this.state.selectedMachines.length === 0 ? (
					<Form.Label style={{ color: 'red', marginTop: '10px' }}>
						{t('groupManager.addGroup.validationError')}
					</Form.Label>
				) : null}
			</Form>
		);
	}
}

export default withTranslation()(AddMachineGroupForm);
