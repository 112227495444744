import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';
import HelperTooltip from '../../../../components/UI/HelperTooltip/HelperTooltip';
import classes from '../PromotionTypeSelector/PromotionTypeSelector.module.css';
import { SizePerPageDropDown } from 'react-bootstrap-table';

const timePeriod = props => {
	const { t } = props;

	let timeperiod = (
		<>
			<Row style={{ paddingLeft: '20px', paddingTop: '5px' }}>
				<Form.Check
					style={{ fontWeight: 'bold', paddingBottom: '9px' }}
					label={t('promotionManager.addPromotion.timeHandling.title')}
					type="checkbox"
					onChange={props.onChecked}
					checked={props.timePeriodChecked}
				/>

				<HelperTooltip
					height={'110px'}
					width={'600px'}
					direction={'top'}
					id={'machineGroups'}
					title={t('promotionManager.addPromotion.timeHandling.tooltip.title')}
					content={t(
						'promotionManager.addPromotion.timeHandling.tooltip.content'
					)}
				></HelperTooltip>
			</Row>
			<Row>
				<Col
					style={{
						display: 'flex',
						justifyContent: 'center',
						padding: '20px',
						fontWeight: 'bold',
						color: 'gray'
					}}
				>
					<Form.Label className={classes.Label}>
						{t('promotionManager.addPromotion.timeHandling.noTimePeriod')}
					</Form.Label>
				</Col>
			</Row>
		</>
	);
	if (props.timePeriodChecked) {
		timeperiod = (
			<>
				<Row style={{ paddingLeft: '20px', paddingTop: '5px' }}>
					<Form.Check
						style={{ fontWeight: 'bold', paddingBottom: '9px' }}
						label={t('promotionManager.addPromotion.timeHandling.title')}
						type="checkbox"
						onChange={props.onChecked}
						checked={props.timePeriodChecked}
					/>

					<HelperTooltip
						height={'110px'}
						width={'600px'}
						direction={'top'}
						id={'machineGroups'}
						title={t(
							'promotionManager.addPromotion.timeHandling.tooltip.title'
						)}
						content={t(
							'promotionManager.addPromotion.timeHandling.tooltip.content'
						)}
					></HelperTooltip>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={6}>
						<Form.Group controlId="dailyPeriodStart">
							<Form.Label className={classes.Label}>
								{t('promotionManager.addPromotion.startTime.title')}
							</Form.Label>
							<Form.Control
								required
								name="dailyPeriodStart"
								type="time"
								value={props.dailyPeriodStart}
								onChange={props.onChanged}
							></Form.Control>
						</Form.Group>
					</Col>
					<Col xs={12} sm={12} md={12} lg={6}>
						<Form.Group controlId="dailyPeriodEnd">
							<Form.Label className={classes.Label}>
								{t('promotionManager.addPromotion.endTime.title')}
							</Form.Label>
							<Form.Control
								required
								name="dailyPeriodEnd"
								type="time"
								value={props.dailyPeriodEnd}
								onChange={props.onChanged}
							></Form.Control>
						</Form.Group>
					</Col>
				</Row>
			</>
		);
	}

	return timeperiod;
};

export default withTranslation()(timePeriod);
