import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';
import classes from '../PromotionTypeSelector/PromotionTypeSelector.module.css';

const ActiveDays = props => {
	const { t } = props;

	const defChecked = value => {
		let checked = false;
		if (props.activeDays) {
			checked =
				props.activeDays.filter(
					day => day.toString().trim() == value.toString().trim()
				).length > 0;
		}
		return checked;
	};

	const colBackground = () => {
		const edit = document.getElementById('edit');

		if (edit) {
			return classes.ColEdit;
		} else {
			return classes.Col;
		}
	};

	const param = [
		{
			label: t('promotionManager.promotionTable.activeDays.days.mon'),
			value: 1
		},
		{
			label: t('promotionManager.promotionTable.activeDays.days.tue'),
			value: 2
		},
		{
			label: t('promotionManager.promotionTable.activeDays.days.wed'),
			value: 3
		},
		{
			label: t('promotionManager.promotionTable.activeDays.days.thu'),
			value: 4
		},
		{
			label: t('promotionManager.promotionTable.activeDays.days.fri'),
			value: 5
		},
		{
			label: t('promotionManager.promotionTable.activeDays.days.sut'),
			value: 6
		},
		{
			label: t('promotionManager.promotionTable.activeDays.days.sun'),
			value: 7
		}
	];
	const checkBox = param.map(e => (
		<Col xs={6} sm={3} md={3} lg>
			<Form.Check
				id={e.value}
				name="activeDays"
				type="checkbox"
				label={e.label}
				value={e.value}
				checked={defChecked(e.value)}
				onChange={props.onChanged}
			></Form.Check>
		</Col>
	));
	return (
		<Form.Group controlId="activeDays">
			<Col>
				<Row className={colBackground()} style={{ alignItems: 'center' }}>
					{checkBox}
				</Row>
			</Col>
		</Form.Group>
	);
};

export default withTranslation()(ActiveDays);
