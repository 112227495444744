import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import FilterBar from '../../../FilterBar/FilterBar';

import { withTranslation } from 'react-i18next';

class ProductSelectorTable extends Component {
	state = {
		selected: [],
		data: [],
		selectedRow: []
	};

	componentDidMount() {
		if (this.props.selected) {
			let selected = this.props.selected;
			let selectedRow = this.props.selected.map(i => i.id);
			this.setState({ selected: selected, selectedRow: selectedRow });
		}
		this.setState({ data: this.props.data });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.data !== this.props.data) {
			this.setState({ data: this.props.data });
		}
		if (prevState.data !== this.state.data) {
			this.filterData();
		}
	}

	onRowSelect = (row, isSelected) => {
		if (isSelected) {
			let selected = [...this.state.selected];
			selected.push(row);
			let selectedRow = [...this.state.selectedRow];
			selectedRow.push(row.id);
			this.setState({ selected: selected, selectedRow: selectedRow });
		} else {
			let selected = this.state.selected.filter(e => e !== row);
			let selectedRow = this.state.selectedRow.filter(e => e !== row.id);
			this.setState({ selected: selected, selectedRow: selectedRow });
		}
	};

	onSelectAll = (isSelected, row) => {
		if (!isSelected) {
			this.setState({ selected: [], selectedRow: [] });
		} else {
			this.setState({ selected: row, selectedRow: row.id });
		}
	};

	isColumnsVisibile = columName => {
		return (
			!this.props.hiddenColumns ||
			this.props.hiddenColumns.indexOf(columName) < 0
		);
	};

	userFormatter = (cell, row) => {
		return <i className="fa fa-tint" id="userIcon" />;
	};

	typeFormatter = (cell, row) => {
		if (cell === 'coffe') {
			return (
				<p>
					<i className="fa fa-coffee mr-2 " />
					{cell}
				</p>
			);
		} else if (cell === 'snack') {
			return (
				<p>
					<i className="fa fa-cutlery mr-2 " />
					{cell}
				</p>
			);
		} else if (cell === 'bottle') {
			return (
				<p>
					<i className="fa fa-glass mr-2 " />
					{cell}
				</p>
			);
		} else {
			return (
				<p>
					<i className="fa fa-columns mr-2 " />
					{cell}
				</p>
			);
		}
	};

	renderFilterBar = () => {
		return (
			<FilterBar
				key="filterbar"
				onFilterChanged={value => this.onFilterChange(value)}
			/>
		);
	};

	filterData() {
		let filteredData = [...this.state.data];
		let filterValue = this.state.currentFilterValue
			? this.state.currentFilterValue.trim().toUpperCase()
			: '';
		if (filterValue.length > 0) {
			filteredData = this.state.data.filter(data => {
				let curData = { ...data };
				let promotionFieldArray = Object.values(curData);
				return promotionFieldArray.some(
					val =>
						String(val)
							.toUpperCase()
							.indexOf(filterValue) >= 0
				);
			});
		}

		this.setState({ filteredData: filteredData });
	}

	onFilterChange(filteValue) {
		this.setState({ currentFilterValue: filteValue }, () => this.filterData());
	}

	//Table options
	render() {
		this.props.selectedProducts(this.state.selected);
		const selectRowProp = {
			mode: 'checkbox',
			bgColor: 'ghostWhite',
			onSelect: this.onRowSelect,
			onSelectAll: this.onSelectAll,
			selected: this.state.selectedRow,
			clickToSelect: true
		};
		const tableOptions = {
			sortIndicator: true,
			hideSizePerPage: true,
			withFirstAndLast: true,
			alwaysShowAllBtns: false,
			clearSearch: true,
			hidePageListOnlyOnePage: true,
			sortName: 'id',
			sortOrder: 'asc',
			searchPanel: this.renderFilterBar,
			onSearchChange: this.onFilterChange
		};
		const { t } = this.props;
		return (
			<>
				{!this.props.hideToolbar ? this.renderFilterBar() : null}
				<BootstrapTable
					key="bootstrapTable"
					data={this.state.filteredData}
					version="4"
					// striped
					hover
					insertRow={false}
					options={tableOptions}
					multiColumnSearch
					condensed
					bordered={false}
					dataAlign={'center'}
					size="sm"
					height="180"
					selectRow={selectRowProp}
				>
					{this.isColumnsVisibile('id') ? (
						<TableHeaderColumn
							width="30"
							dataAlign="end"
							dataField="id"
							isKey
							dataFormat={this.userFormatter}
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px' }}
						/>
					) : null}
					{this.isColumnsVisibile('name') ? (
						<TableHeaderColumn
							dataField="name"
							dataAlign="center"
							width="150"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal',
								fontWeight: 'bold'
							}}
						>
							{t('groupManager.selectorTable.deviceName')}
						</TableHeaderColumn>
					) : null}
					{this.isColumnsVisibile('type') ? (
						<TableHeaderColumn
							dataField="type"
							dataAlign="center"
							dataFormat={this.typeFormatter}
							width="150"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{t('groupManager.selectorTable.type')}
						</TableHeaderColumn>
					) : null}
				</BootstrapTable>
			</>
		);
	}
}

export default withTranslation()(ProductSelectorTable);
