/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import classes from './HardwareSelectorDialog.module.css';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

class HardwareSelectorDialogModule extends Component {
	state = {
		selectedHardwares: [],
		filteredHardwareList: this.props.allHardwares
	};

	filterText = '';

	selectHardware(machine) {
		this.setState({ selectedHardwares: [machine] });
	}

	onOkClick() {
		this.props.onSelected(this.state.selectedHardwares);
		this.setState({ selectedHardwares: [] });
	}

	onSelectAll() {
		this.setState({ selectedHardwares: [...this.state.filteredHardwareList] });
	}

	onDeSelectAll() {
		this.setState({ selectedHardwares: [] });
	}

	isAnySelected() {
		return this.state.selectedHardwares.length > 0;
	}

	onFilterChange(e) {
		this.filterText = e.target.value;
		clearTimeout(this.filterTimeout);
		this.filterTimeout = setTimeout(() => this.filterTheList(), 300);
	}

	filterTheList() {
		const filteredList = [];
		this.props.allHardwares.forEach(hardware => {
			if (
				this.filterText.trim() == '' ||
				(hardware.hardwareID &&
					hardware.hardwareID
						.toUpperCase()
						.indexOf(this.filterText.toUpperCase().trim()) >= 0)
			) {
				filteredList.push(hardware);
			}
		});

		this.setState({ filteredHardwareList: filteredList });
	}

	renderHardwares() {
		const machineList = this.state.filteredHardwareList.map(hardware => {
			return (
				<ListGroup.Item
					key={hardware.vmID}
					active={this.state.selectedHardwares.find(
						curHardware => curHardware.hardwareID == hardware.hardwareID
					)}
					onClick={e => this.selectHardware(hardware)}
				>
					<span>
						<b>{this.props.t('hidSelectorDialog.hardwareIdentifier')}</b>&nbsp;
						{hardware.hardwareID}
					</span>
					<br />
					<span>
						<b>{this.props.t('hidSelectorDialog.manufactureDate')}</b>&nbsp;
						{hardware.manufactureDate}
					</span>
				</ListGroup.Item>
			);
		});

		return (
			<div style={{ height: '300px', overflowY: 'auto' }}>
				<ListGroup>{machineList}</ListGroup>
			</div>
		);
	}

	render() {
		return (
			<Form>
				<Row>
					<Col sm="12">
						<Form.Control
							type="text"
							placeholder={this.props.t('salesList.filter')}
							onChange={e => this.onFilterChange(e)}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm="12">{this.renderHardwares()}</Col>
				</Row>
				<Row>
					<Col sm="12" className={classes.ToolbarDiv}>
						<Button
							variant="success"
							className="defaultButton"
							onClick={() => this.onOkClick()}
						>
							<i className="fa fa-check mr-2" aria-hidden="true"></i>&nbsp;
							{this.props.t('hidSelectorDialog.ok')}
						</Button>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default withTranslation()(HardwareSelectorDialogModule);
