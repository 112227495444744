import React, { Component } from 'react';
import { Card, Row, CardBody, CardTitle } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import FilterBar from '../../views/FilterBar/FilterBar';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import EditPromotionDialog from './PromotionDialog/EditPromotionDialog';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';
import PromotionTable from './PromotionTable/PromotionTable';
import HelperTooltip from '../../components/UI/HelperTooltip/HelperTooltip';
import promotionApi from '../../services/api/promotions';
import machineGroupApi from '../../services/api/machineGroup';
import tagsAPI from '../../services/api/editor/editorTags';
import machineApi from '../../services/api/machines';
import Spinner from '../../components/UI/Spinner/Spinner';

import { withTranslation } from 'react-i18next';

class PromotionManager extends Component {
	state = {
		machines: [],
		machineGroups: [],
		promotions: [],
		loading: true,
		promotionBody: {
			activeDays: null,
			dailyPeriodEnd: null,
			dailyPeriodStart: null,
			description: null,
			endDate: '',
			name: null,
			promotionTypeID: null,
			startDate: null,
			state: 'A',
			params: null
		},
		productTags: []
	};

	//GET  promotion, machine, mchineGroup data
	componentDidMount() {
		this.loadTags();
		this.loadMachinesAndGroups(() => this.loadPromotions());
	}

	loadMachinesAndGroups = promise => {
		machineGroupApi.getMachineGroups().then(ma => {
			let machineGroups = ma ? ma.data.groups : [];
			machineApi.getVisibleVendingMachines().then(mg => {
				let machines = mg ? mg.data.response : [];
				const promotionBody = { ...this.state.promotionBody };
				promotionBody.assignedVMID = machines ? machines[0].vmID : undefined;
				this.setState(
					{
						machineGroups: machineGroups,
						machines: machines,
						promotionBody
					},
					() => promise()
				);
			});
		});
	};

	// Load Product Tags
	loadTags = () => {
		tagsAPI.getTags().then(result => {
			if (result && result.data) {
				this.setState({ productTags: result.data });
			}
		});
	};

	// DataHandling
	loadPromotions = () => {
		promotionApi.getPromotions().then(pr => {
			let promotions = [];
			if (pr && pr.data && pr.data.promotions) promotions = pr.data.promotions;
			this.setState(
				{
					promotions: promotions,

					loading: false
				},
				() => this.filterPromotions()
			);
		});
	};

	//Render Filterbar with ADDButton
	renderFilterBar = () => {
		return (
			<FilterBar
				key="filterbar"
				showAddButton
				onShowCreateItem={() => this.handleAddButtonClick()}
				onFilterChanged={value => this.onFilterChange(value)}
			/>
		);
	};

	filterPromotions() {
		let filteredPromotions = [];
		if (this.state.promotions && this.state.promotions.length >= 0) {
			filteredPromotions = [...this.state.promotions];
			let filterValue = this.state.currentFilterValue
				? this.state.currentFilterValue.trim().toUpperCase()
				: '';
			if (filterValue.length > 0) {
				filteredPromotions = this.state.promotions.filter(promotions => {
					let curPromotions = { ...promotions };
					let promotionFieldArray = Object.values(curPromotions);
					return promotionFieldArray.some(
						val =>
							String(val)
								.toUpperCase()
								.indexOf(filterValue) >= 0
					);
				});
			}
		}

		this.setState({ filteredPromotions: filteredPromotions });
	}

	onFilterChange(filteValue) {
		this.setState({ currentFilterValue: filteValue }, () =>
			this.filterPromotions()
		);
	}

	handleAddButtonClick = () => {
		this.showDialog(this.state.promotionBody);
	};

	// Promotion Dialog handling
	editProfil = promotion => {
		this.showDialog(promotion);
	};

	showDialog(promotion) {
		this.setState({
			showPromotionDialog: true,
			promotionToDialog: promotion
		});
	}

	hideDialog = () => {
		this.setState({
			showPromotionDialog: false,
			promotionToDialog: null
		});
	};

	saveDialog = req => {
		if (req.id) {
			promotionApi.updatePromotion(req).then(req => {
				this.hideDialog();
				this.loadPromotions();
			});
		} else {
			promotionApi.createPromotion(req).then(req => {
				this.hideDialog();
				this.loadPromotions();
			});
		}
	};

	onDeletePromotion = promotion => {
		this.setState({ promotionToDelete: promotion });
	};

	onDeletePromotionConfirmed = () => {
		let pID = this.state.promotionToDelete.id;
		promotionApi
			.deletePromotion(pID)
			.then(response =>
				this.setState({ promotionToDelete: null, loading: true }, () =>
					this.loadPromotions()
				)
			);
	};

	statusSwitchHandler = row => {
		let requestBody = row;
		let state = true;
		if (requestBody.assignedGroupID) {
			requestBody.promotedMachineGroup = this.state.machineGroups.filter(
				e => e.id === requestBody.assignedGroupID
			);
		}
		if (requestBody.assignedVMID) {
			requestBody.promotedMachine = this.state.machines.filter(
				e => e.vmID === requestBody.assignedVMID
			);
		}
		if (row.state === 'A') {
			return (
				(requestBody.state = 'I'),
				promotionApi.updatePromotion(requestBody, state).then(res => {
					this.setState({ loading: true }, () => {
						this.loadPromotions();
					});
				})
			);
		}
		if (row.state === 'I') {
			return (
				(requestBody.state = 'A'),
				promotionApi.updatePromotion(requestBody, state).then(res => {
					this.setState({ loading: true }, () => {
						this.loadPromotions();
					});
				})
			);
		}
	};

	// render DeletConfirmation
	renderDeleteConfirmation() {
		if (this.state.promotionToDelete) {
			return (
				<ConfirmationDialog
					isOpen={this.state.promotionToDelete}
					onToggle={() => this.setState({ promotionToDelete: null })}
					title={this.props.t('promotionManager.deletePromotion.title')}
					confirmationText={this.props.t(
						'promotionManager.deletePromotion.label'
					)}
					cancelText={this.props.t(
						'promotionManager.deletePromotion.cancelButton'
					)}
					okText={this.props.t('promotionManager.deletePromotion.okButton')}
					onCancel={() => this.setState({ promotionToDelete: null })}
					onOk={() => this.onDeletePromotionConfirmed()}
				/>
			);
		} else {
			return null;
		}
	}

	// render Promotion Dialog
	renderEditPromotionDialog() {
		return (
			<Modal
				show={this.state.showPromotionDialog}
				onHide={() => this.hideDialog()}
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i className="fa fa-bullhorn" style={{ marginRight: '10px' }}></i>
						{!this.state.promotionToDialog.id
							? this.props.t('promotionManager.addPromotion.title')
							: this.props.t('promotionManager.editPromotion.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: 'white' }}>
					<EditPromotionDialog
						promotion={this.state.promotionToDialog}
						style={{ backgroundColor: 'ghostwhite' }}
						machines={this.state.machines}
						machineGroups={this.state.machineGroups}
						onSave={this.saveDialog}
						onHide={this.hideDialog}
						productTags={this.state.productTags}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	//Render Table
	renderTable() {
		let table = <Spinner />;
		if (!this.state.loading) {
			table = (
				<PromotionTable
					machines={this.state.machines}
					machineGroups={this.state.machineGroups}
					actDate={this.state.actDate}
					data={this.state.filteredPromotions}
					statusSwitchHandler={this.statusSwitchHandler}
					onDeletePromotion={this.onDeletePromotion}
					editProfil={this.editProfil}
					renderFilterBar={this.renderFilterBar}
					onFilterChange={this.onFilterChange}
				/>
			);
		}
		return table;
	}

	//Render Title
	renderTitle() {
		return (
			<Row style={{ alignItems: 'flex-start' }}>
				<CardTitle
					className="text-muted"
					style={{ cursor: 'pointer', marginLeft: '20px' }}
				>
					<i className={'fa fa-bullhorn'} style={{ marginRight: '10px' }} />
					{this.props.t('promotionManager.label')}
				</CardTitle>
				<HelperTooltip
					height={'100px'}
					width={'600px'}
					direction={'right'}
					id={'PromotionManagerHelperTooltip'}
					title={this.props.t('promotionManager.tooltip.title')}
					content={this.props.t('promotionManager.tooltip.content')}
				></HelperTooltip>
			</Row>
		);
	}

	//Render!
	render() {
		return (
			<div className="animated fadeIn">
				<DashboardCardContainerWithFlow>
					<Card body>
						<PageTiltleCard title={this.props.t('promotionManager.title')} />
						{this.state.promotionToDialog
							? this.renderEditPromotionDialog()
							: null}
						{this.renderDeleteConfirmation()}
						<Card className={this.props.className} style={this.props.style}>
							<CardBody>
								{this.renderTitle()}
								{this.renderTable()}
							</CardBody>
						</Card>
					</Card>
				</DashboardCardContainerWithFlow>
			</div>
		);
	}
}

export default withTranslation()(PromotionManager);
