import React, { Component } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { withTranslation } from 'react-i18next';
// import { HorizontalBar, Chart } from 'react-chartjs-2';

// import Toggle from 'react-bootstrap-toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

// import customersAPI from '../../../../services/api/customers';
import classes from './MachineStateCard.module.css';
// import { tooltip } from 'leaflet';
import { Link } from 'react-router-dom';

// import UserUtil from '../../../../services/util/userUtil';
// import VMUtil from '../../../../services/util/vmUtil';
import vmApi from '../../../../services/api/machines';
import proxy from '../../../PromotionManager/proxy';

class MachineStateCard extends Component {
	state = { machineList: [] };

	possibleStates = [
		{
			name: this.props.t('customerDashboard.stateCard.legendOk'),
			className: classes.OkLegend
		},
		{
			name: this.props.t('customerDashboard.stateCard.legendWarning'),
			className: classes.WarningLegend
		},
		{
			name: this.props.t('customerDashboard.stateCard.legendProgramming'),
			className: classes.ProgrammingLegend
		},
		{
			name: this.props.t('customerDashboard.stateCard.legendMalfunction'),
			className: classes.MalfunctionLegend
		},
		{
			name: this.props.t('customerDashboard.stateCard.legendOffline'),
			className: classes.OfflineLegend
		}
	];

	componentDidMount() {
		this.loadData();
	}

	async loadData() {
		// Basic Test Data
		// this.setState({
		// 	machineList: [
		// 		{ name: 'Valami', state: 0, connectionState: 1, type: 0 },
		// 		{ name: 'Valami1', state: 1, connectionState: 1, type: 1 },
		// 		{ name: 'Valami2', state: 2, connectionState: 0, type: 2 },
		// 		{ name: 'Valami3', state: 3, connectionState: 1, type: 3 },
		// 		{ name: 'Valami4', state: 2, connectionState: 1, type: 4 },
		// 		{ name: 'Matcha Latte', state: 2, connectionState: 1, type: 4 }
		// 	]
		// });
		// Test data wit lot of OK machine
		// let p = proxy.machineList;
		// this.setState({
		// 	machineList: p
		// });

		const response = await vmApi.getVisibleVendingMachines();

		if (response.data.response) {
			const vms = Object.values(response.data.response);
			const machineList = vms.map(vm => {
				return {
					key: vm.vmID,
					name: vm.customName || vm.name,
					state: vm.vendingMachineState,
					type: vm.machine,
					connectionState: vm.onlineState === 'offline' ? 0 : 1
				};
			});

			this.setState({ machineList });
		}
	}

	fullDivWidth = 480;

	//--------- all state in one label
	getDivWidth(machineList) {
		if (machineList.length === 0) return 0;
		return (
			(machineList.length / this.state.machineList.length) * 100 //this.fullDivWidth
		);
	}

	maxMachineInTooltip = 10;

	renderTooltip(title, machines) {
		const tooltipCount =
			machines.length > this.maxMachineInTooltip
				? this.maxMachineInTooltip
				: machines.length;
		let toolTips = [];
		for (let index = 0; index < tooltipCount; index++) {
			toolTips.push(
				<div key={index} style={{ display: 'block', marginBottom: '10px' }}>
					<span>{machines[index].name} </span>
					<span>{machines[index].type}</span>
				</div>
			);
		}
		if (machines.length > this.maxMachineInTooltip) {
			toolTips.push(
				<div key={'...'} style={{ display: 'block' }}>
					<span>...</span>
				</div>
			);
		}

		return (
			<Tooltip id={'tooltip_' + title}>
				<span>{title}</span>
				{toolTips}
			</Tooltip>
		);
	}

	renderMachineDiv(machineList, className, tooltiptitle, link) {
		return (
			<OverlayTrigger
				placement="bottom"
				delay={{ show: 250, hide: 400 }}
				overlay={this.renderTooltip(tooltiptitle, machineList)}
			>
				<Link to={link}>
					<div
						className={classes.MachineDiv + ' ' + className}
						style={{ width: this.getDivWidth(machineList) + '%' }}
					>
						{machineList.length > 0 ? machineList.length : ''}
					</div>
				</Link>
			</OverlayTrigger>
		);
	}

	render() {
		// const { t } = this.props;
		//-------OK machine-----------
		const okMachines = this.state.machineList.filter(
			machine => machine.state === 0 && machine.connectionState === 1
		);
		//-------------
		const programmingMachines = this.state.machineList.filter(
			machine => machine.state === 1 && machine.connectionState === 1
		);
		const warningMachines = this.state.machineList.filter(
			machine => machine.state === 2 && machine.connectionState === 1
		);

		const malfunctionMachines = this.state.machineList.filter(
			machine => machine.state === 3 && machine.connectionState === 1
		);
		const offlineMachines = this.state.machineList.filter(
			machine => machine.connectionState === 0
		);
		const onlineMachines = this.state.machineList.filter(
			machine => machine.connectionState === 1
		);

		const numberOfMachines = this.state.machineList.length;
		//--------Ok machine----------
		const okMachineDiv = this.renderMachineDiv(
			okMachines,
			classes.OkMachinesDiv,
			this.props.t('customerDashboard.stateCard.tooltips.okMachines'),
			'/vms?machinestate=ok'
		);
		//--------------
		const warningMachineDiv = this.renderMachineDiv(
			warningMachines,
			classes.WarningMachinesDiv,
			this.props.t('customerDashboard.stateCard.tooltips.warningMachines'),
			'/vms?machinestate=warning'
		);
		const programmingMachineDiv = this.renderMachineDiv(
			programmingMachines,
			classes.ProgrammingMachinesDiv,
			this.props.t('customerDashboard.stateCard.tooltips.programmingMachines'),
			'/vms?machinestate=programming'
		);
		const malfunctionMachineDiv = this.renderMachineDiv(
			malfunctionMachines,
			classes.MalfunctionMachinesDiv,
			this.props.t('customerDashboard.stateCard.tooltips.malfunctionMachines'),
			'/vms?machinestate=error'
		);
		const offlineMachineDiv = this.renderMachineDiv(
			offlineMachines,
			classes.OfflineMachinesDiv,
			this.props.t('customerDashboard.stateCard.tooltips.offlineMachines'),
			'/vms?offline=true'
		);

		const legendList = this.possibleStates.map((state, index) => {
			return (
				// state.name === 'Ok' ? (
				// 	<div key={index} className={classes.Legend + ' ' + state.className}>
				// 		<div></div> <span>{state.name}</span>
				// 	</div>
				// ) : null

				// Ok státusz megjelenítés nélkül
				//	state.name !== 'Ok' ? (
				<div key={index} className={classes.Legend + ' ' + state.className}>
					<div></div> <span>{state.name}</span>
				</div>
				//) : null
			);
		});

		return (
			<Card className={this.props.className} style={this.props.style}>
				<CardBody>
					<Row>
						<Col sm="12">
							<div style={{ textAlign: 'center', textDecoration: 'bold' }}>
								<h4>
									<b>{this.props.t('customerDashboard.stateCard.title')}</b>
								</h4>
							</div>
						</Col>
					</Row>

					<div className={classes.TopLegends}>
						<span>
							{this.props.t('customerDashboard.stateCard.allMachines')}:&nbsp;
							{numberOfMachines}
						</span>
						<span>
							{this.props.t('customerDashboard.stateCard.onlineMachines')}
							:&nbsp;
							{onlineMachines.length}
						</span>
						<span>
							{this.props.t('customerDashboard.stateCard.offlineMachines')}
							:&nbsp;
							{offlineMachines.length}
						</span>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignUtems: 'center'
						}}
					>
						<div className={classes.Chart}>
							{okMachineDiv}
							{warningMachineDiv}
							{programmingMachineDiv}
							{malfunctionMachineDiv}
							{offlineMachineDiv}
						</div>
					</div>
					<div className={classes.Legends}>{legendList}</div>
				</CardBody>
			</Card>
		);
	}
}

export default withTranslation()(MachineStateCard);
