import React from 'react';
import { Col, Row } from 'reactstrap';
import classes from './SalesAndIncomeTable.module.css';
import { Form } from 'react-bootstrap';

const DietailRow = props => {
	return (
		<Row className={classes.DietailRow} style={props.style}>
			<Col xs={12} sm={6} md={4} lg={4}>
				<Form.Label>{props.name}</Form.Label>
			</Col>
			<Col xs={12} sm={6} md={2} lg={2}>
				<Row noGutters>
					<Col
						xs={6}
						sm={6}
						md={6}
						lg={6}
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginRight: '5px'
						}}
					>
						{props.label}
					</Col>
					<Col xs={6} sm={6} md={6} lg={5}>
						{props.measure}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
export default DietailRow;
