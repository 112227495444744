import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
//Operator
import CreateOperator from '../../views/Operator/CreateOperator'
import OperatorList from '../../views/Operator/OperatorList'
import EditOperator from '../../views/Operator/EditOperator'

class CustomerUsers extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/customerUsers/creatOperator"
            name="CreatOperator"
            component={CreateOperator}
          />

          <Route
            path="/customerUsers/editOperator/:userID/:userEmail"
            name="EditOperator"
            component={EditOperator}
          />

          <Route
            path="/customerUsers"
            name="OperatorList"
            component={OperatorList}
          />

          <Redirect from="/" to="/customerUsers" />
        </Switch>
      </div>
    )
  }
}
//<Container fluid>
export default CustomerUsers
