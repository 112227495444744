//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { RedMachineButton } from '../../../../components/Machines/MachineComponents';
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent';
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard';

type Props = {
	vmID: string,
	history: Object,
	products?: Array<Object>
};
type States = {};

class Lazio18 extends React.Component<Props, States> {
	keyboardConfig = [
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						<i className="fa fa-long-arrow-up mr-1" />
						<i className="fa fa-long-arrow-up mr-1" /> <br />1
					</>
				),
				buttonID: '1'
			},
			{
				buttonContent: '7',
				buttonID: '7'
			},
			{
				buttonContent: '13',
				buttonID: '13'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						<i className="fa fa-long-arrow-up mr-1" />2
					</>
				),
				buttonID: '2'
			},
			{
				buttonContent: '8',
				buttonID: '8'
			},
			{
				buttonContent: '14',
				buttonID: '14'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						<i className="fa fa-long-arrow-down mr-1" />3
					</>
				),
				buttonID: '3'
			},
			{
				buttonContent: '9',
				buttonID: '9'
			},
			{
				buttonContent: '15',
				buttonID: '15'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: <>+ 4</>,
				buttonID: '4'
			},
			{
				buttonContent: '10',
				buttonID: '10'
			},
			{
				buttonContent: '16',
				buttonID: '16'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: <>- 5</>,
				buttonID: '5'
			},
			{
				buttonContent: '11',
				buttonID: '11'
			},
			{
				buttonContent: '17',
				buttonID: '17'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						<i className="fa fa-long-arrow-down mr-1" />
						<i className="fa fa-long-arrow-down mr-1" /> <br />6
					</>
				),
				buttonID: '6'
			},
			{
				buttonContent: '12',
				buttonID: '12'
			},
			{
				buttonContent: '18',
				buttonID: '18'
			}
		]
	];

	render() {
		return (
			<DirectChooseMachineBaseComponent
				machineTitle="Lazio 18"
				lineNumber={2}
				lineLength={16}
				keyboard={
					<DirectChooseKeyboard
						programmingButtonVisible={true}
						keyboardConfig={this.keyboardConfig}
						type="vertical"
						{...this.props}
					/>
				}
				{...this.props}
			/>
		);
	}
}

export default withRouter(Lazio18);
