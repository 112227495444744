//@flow
import React from 'react';

import { withRouter } from 'react-router-dom';
import { RedMachineButton } from '../../../../components/Machines/MachineComponents';
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard';
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent';

type Props = {
	products?: Array<Object>
};

class Brio200 extends React.Component<Props> {
	keyboardConfig = [
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						1<br />
						<i className="fa fa-long-arrow-up mr-1" />
					</>
				),
				buttonID: '1'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						2<br />
						<i className="fa fa-long-arrow-down mr-1" />
					</>
				),
				buttonID: '2'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						3<br />
						EXIT
					</>
				),
				buttonID: '3'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						4<br />
						CONFIRM
					</>
				),
				buttonID: '4'
			}
		],
		[
			{
				ButtonComponent: RedMachineButton,
				buttonContent: (
					<>
						5<br />
						CHANGE
					</>
				),
				buttonID: '5'
			}
		],
		[
			{
				buttonContent: <>6</>,
				buttonID: '6'
			}
		],
		[
			{
				buttonContent: <>7</>,
				buttonID: '7'
			}
		],
		[
			{
				buttonContent: <>8</>,
				buttonID: '8'
			}
		],
		[
			{
				buttonContent: <>9</>,
				buttonID: '9'
			}
		],
		[
			{
				buttonContent: <>10</>,
				buttonID: '10'
			}
		]
	];

	render() {
		const { products = [] } = this.props;

		return (
			<DirectChooseMachineBaseComponent
				machineTitle="Brio200"
				lineNumber={1}
				lineLength={16}
				keyboard={
					<DirectChooseKeyboard
						programmingButtonVisible={false}
						keyboardConfig={this.keyboardConfig}
						products={products}
					/>
				}
				{...this.props}
			/>
		);
	}
}

export default withRouter(Brio200);
