import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CostumerList from '../../views/CostumerList/CostumerList';

class CostumerListPageRoute extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/costumer_list" name="CostumerList" component={CostumerList} />
                    <Redirect from="/" to="/costumer_list" />
                </Switch>
            </div>
        );
    }
}

export default CostumerListPageRoute;