import axios from './api';
import qs from 'qs';

export default {
	getPackages: () => {
		return axios.get().get(`/editor/packages`);
	},

	getPackageFiles: packageID => {
		const param = {
			packageID
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return axios
			.getBinary()
			.get(`/editor/package${query}`)
			.then()
			.catch(e => console.log(e));
	}
};
