import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import HardwareSelectorDialog from '../../../HardwareSelectorDialog/HardwareSelectorDialog';
import MISelectorDialog from '../../../MISelectorDialog/MISelectorDialog';
import machineAPI from '../../../../services/api/machines';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';

import classes from './AddDeviceDialog.module.css';
import { Container } from 'reactstrap';

class AddDeviceDialog extends Component {
	state = {
		showHardwareSelector: false,
		showMISelector: false,
		hardwareList: [],
		miList: [],

		hasHID: 0,
		selectedHID: null,
		selectedMI: null
	};

	componentDidMount() {
		machineAPI.getHIDHardwares(true).then(result => {
			let hardwareList = [...result.data.response];
			this.setState({ hardwareList });
		});

		machineAPI.getMIHardwares(true).then(result => {
			let miList = [...result.data.response];
			this.setState({ miList });
		});
	}

	showHardwareSelector() {
		this.setState({ showHardwareSelector: true });
	}

	hideHardwareSelector() {
		this.setState({ showHardwareSelector: false });
	}

	showMISelector() {
		this.setState({ showMISelector: true });
	}
	hidMISelector() {
		this.setState({ showMISelector: false });
	}

	setSelectedHID(selectedHardwares) {
		this.setState({
			selectedHID: selectedHardwares,
			showHardwareSelector: false
		});
	}

	setSelectedMI(selectedHardwares) {
		this.setState({
			selectedMI: selectedHardwares,
			showMISelector: false
		});
	}

	renderHardwareSelectorDialog() {
		return (
			<Modal
				show={this.state.showHardwareSelector}
				onHide={() => this.hideHardwareSelector()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i
							className="fa fa-cog"
							aria-hidden="true"
							style={{ marginRight: '10px' }}
						></i>
						{this.props.t('hidSelectorDialog.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: '0px' }}>
					<HardwareSelectorDialog
						allHardwares={this.state.hardwareList}
						onSelected={newSelection => this.setSelectedHID(newSelection)}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	renderMISelectorDialog() {
		return (
			<Modal
				show={this.state.showMISelector}
				onHide={() => this.hidMISelector()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i
							className="fa fa-cog"
							aria-hidden="true"
							style={{ marginRight: '10px' }}
						></i>
						{this.props.t('miSelectorDialog.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: '0px' }}>
					<MISelectorDialog
						allHardwares={this.state.miList}
						onSelected={newSelection => this.setSelectedMI(newSelection)}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	handleComboChange(e) {
		this.setState({ hasHID: e.target.value });
	}

	handleCreateMachine() {
		const selectedHID = this.state.selectedHID
			? this.state.selectedHID[0]
			: null;
		const selectedMI = this.state.selectedMI ? this.state.selectedMI[0] : null;
		this.props.onCreateMachine(selectedHID, selectedMI);
	}

	isCreateButtonClickable() {
		return (
			(this.state.hasHID == 1 &&
				this.state.selectedHID &&
				this.state.selectedHID.length > 0) ||
			this.state.hasHID == 0
		);
	}

	render() {
		const hardwareSelectorDialog = this.renderHardwareSelectorDialog();
		const miSelectorDialog = this.renderMISelectorDialog();

		return (
			<Container>
				{hardwareSelectorDialog}
				{miSelectorDialog}
				<Row>
					<Col>
						<Form
							onSubmit={() => this.handleCreateMachine()}
							className={classes.TopBottomPadding}
						>
							<Form.Group controlId="hasHID">
								<Form.Label>
									{this.props.t('addDeviceDialog.hasHID')}
								</Form.Label>
								<Form.Control
									as="select"
									value={this.state.hasHID}
									onChange={e => this.handleComboChange(e)}
									className={classes.BottomMargin}
								>
									<option value={0}>
										{this.props.t('addDeviceDialog.no')}
									</option>
									<option value={1}>
										{this.props.t('addDeviceDialog.yes')}
									</option>
								</Form.Control>

								{this.state.hasHID == 1 ? (
									<>
										<Form.Label>
											{this.props.t('addDeviceDialog.selectedHumanInterface')}
										</Form.Label>
										<Container className={classes.BottomMargin}>
											<Row>
												<Col lg="11" className={classes.NoPadding}>
													<Form.Control
														type="text"
														value={
															this.state.selectedHID
																? this.state.selectedHID[0].hardwareID
																: ''
														}
														readOnly="true"
														onClick={() => this.showHardwareSelector()}
													/>
												</Col>
												<Col lg="1" className={classes.NoPadding}>
													<Button
														variant="success"
														onClick={() => this.showHardwareSelector()}
													>
														...
													</Button>
												</Col>
											</Row>
										</Container>
									</>
								) : null}
								<Form.Label>
									{this.props.t('addDeviceDialog.selectedMachineInterface')}
								</Form.Label>
								<Container>
									<Row>
										<Col lg="11" className={classes.NoPadding}>
											<Form.Control
												type="text"
												value={
													this.state.selectedMI
														? this.state.selectedMI[0].electronicsItemNumber
														: ''
												}
												readOnly="true"
												onClick={() => this.showMISelector()}
											/>
										</Col>
										<Col lg="1" className={classes.NoPadding}>
											<Button
												variant="success"
												onClick={() => this.showMISelector()}
											>
												...
											</Button>
										</Col>
									</Row>
								</Container>
								<Form.Label className={classes.HintLabel}>
									{this.props.t('addDeviceDialog.hint')}
								</Form.Label>
							</Form.Group>
							<div className={classes.RightAlign}>
								<Button
									variant="success"
									className="DefaultButton"
									type="submit"
									disabled={!this.isCreateButtonClickable()}
								>
									<i className="fa fa-check-circle mr-2" />
									{this.props.t('addDeviceDialog.create')}
								</Button>
							</div>
						</Form>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default withTranslation()(AddDeviceDialog);
