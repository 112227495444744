// @flow
import React, { Component } from 'react';

import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	FormGroup,
	FormText,
	Input,
	InputGroup,
	Label,
	Row
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import realtime from '../../services/api/realtime';
import type { Subscription } from '../../services/api/realtime';

const Red = styled.strong`
	color: red;
`;

const LCDText = styled(FormText)`
	font-size: 18px;
`;

const LCDButton = styled(Button)`
	width: 40px;
	height: auto;
`;

type Props = {
	t: TFunction
};
type State = {
	message: string,
	buttonId: string,
	vmId: string,
	newVmId: string,
	incomingMessages: Array<string>,
	outgoingMessages: Array<string>,
	lcdLines: Array<string>
};

const LCD_LINE_NUMBER = 5;

class VMDebugConsol extends Component<Props, State> {
	state = {
		showComponent: false,
		message: '',
		incomingMessages: [],
		outgoingMessages: [],
		lcdLines: Array(LCD_LINE_NUMBER).fill(''),
		buttonId: '',
		vmId: '0',
		newVmId: ''
	};
	lcdSubscription: ?Subscription = null;

	addOutgoingMessage = (message: string) => {
		this.setState(state => ({
			outgoingMessages: [message, ...state.outgoingMessages]
		}));
	};

	addIncomingMessage = (message: string) => {
		this.setState(state => ({
			incomingMessages: [message, ...state.incomingMessages]
		}));
	};

	pressMessageOnChange = e => {
		this.setState({ buttonId: e.target.value });
	};

	sendPress = async () => this.sendButtonPress(this.state.buttonId);

	sendButtonPress = async buttonId => {
		await realtime.pressButton(this.state.vmId, buttonId);
		this.addOutgoingMessage(`Button press: ${buttonId}`);
	};

	lcdOFF = async () => {
		await realtime.turnOffLCD(this.state.vmId);
		this.addOutgoingMessage('LCD Status: off');
	};

	lcdON = async () => {
		await realtime.turnOnLCD(this.state.vmId);
		this.addOutgoingMessage('LCD Status: on');
	};

	reset = () => {
		this.setState(
			{
				vmId: '0',
				newVmId: ''
			},
			this.connectToVm
		);
	};

	vmidOnChange = e => {
		this.setState({ newVmId: e.target.value });
	};

	submitVMID = () => {
		this.setState(
			state => ({
				vmId: state.newVmId !== '' ? state.newVmId : state.vmId,
				newVmId: ''
			}),
			this.connectToVm
		);
	};

	connectToVm = async () => {
		if (this.lcdSubscription) {
			this.lcdSubscription.unsubscribe();
		}

		this.lcdSubscription = await realtime.subscribeLCDForVM(
			this.state.vmId,
			this.handleLCDMessage
		);

		this.setState({
			outgoingMessages: [],
			incomingMessages: [],
			lcdLines: Array(LCD_LINE_NUMBER).fill('')
		});
	};

	handleLCDMessage = message => {
		const newmessage = message.toString();

		this.addIncomingMessage(`LCD: ${message}`);

		this.setState(state => {
			const newLcdLines = [...state.lcdLines];
			if (newmessage.indexOf('LINE1') !== -1) {
				//hex convertálás
				let helper = this.hex2a(newmessage.replace('LINE1=', ''));

				newLcdLines[0] = helper
					.replace(
						new RegExp(String.fromCharCode(65533), 'g'),
						String.fromCharCode(253)
					)
					.replace(
						new RegExp(String.fromCharCode(65532), 'g'),
						String.fromCharCode(252)
					);
			}

			if (newmessage.indexOf('LINE2') !== -1) {
				//hex convertálás
				let helper = this.hex2a(newmessage.replace('LINE2=', ''));

				newLcdLines[1] = helper
					.replace(
						new RegExp(String.fromCharCode(65533), 'g'),
						String.fromCharCode(253)
					)
					.replace(
						new RegExp(String.fromCharCode(65532), 'g'),
						String.fromCharCode(252)
					);
			}

			if (newmessage.indexOf('LINE3') !== -1) {
				//hex convertálás
				let helper = this.hex2a(newmessage.replace('LINE3=', ''));

				newLcdLines[2] = helper
					.replace(
						new RegExp(String.fromCharCode(65533), 'g'),
						String.fromCharCode(253)
					)
					.replace(
						new RegExp(String.fromCharCode(65532), 'g'),
						String.fromCharCode(252)
					);
			}

			if (newmessage.indexOf('LINE4') !== -1) {
				//hex convertálás
				let helper = this.hex2a(newmessage.replace('LINE4=', ''));

				newLcdLines[3] = helper
					.replace(
						new RegExp(String.fromCharCode(65533), 'g'),
						String.fromCharCode(253)
					)
					.replace(
						new RegExp(String.fromCharCode(65532), 'g'),
						String.fromCharCode(252)
					);
			}

			return {
				lcdLines: newLcdLines
			};
		});
	};

	componentDidMount = async () => {
		await this.connectToVm();
	};

	hex2a = hexx => {
		var hex = hexx.toString(); //force conversion
		var str = '';
		for (var i = 0; i < hex.length; i += 2)
			str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
		return str;
	};

	render = () => {
		const {
			newVmId,
			vmId,
			buttonId,
			lcdLines,
			incomingMessages,
			outgoingMessages
		} = this.state;

		const { t } = this.props;

		return (
			<div>
				<Row>
					<Col md="12" lg="6" xl="6">
						<Card className="text-black bg-success">
							<CardHeader>
								<h1>{t('vmdebugconsol.card1.title')}</h1>
							</CardHeader>
							<CardBody>
								{lcdLines.map((lcdLine, id) => (
									<h3
										className="lcd_font text-center pb-2 text-nowrap"
										key={`lcd-element-${id}`}
									>
										{lcdLine.replace(/ /g, '\u00A0')}&ensp;
									</h3>
								))}
								<Row className="d-flex justify-content-center">
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="1"
										onClick={() => this.sendButtonPress('1')}
									>
										<strong>1</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="2"
										onClick={() => this.sendButtonPress('2')}
									>
										<strong>2</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="3"
										onClick={() => this.sendButtonPress('3')}
									>
										<strong>3</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="4"
										onClick={() => this.sendButtonPress('4')}
									>
										<strong>4</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="5"
										onClick={() => this.sendButtonPress('5')}
									>
										<strong>5</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="6"
										onClick={() => this.sendButtonPress('6')}
									>
										<strong>6</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="7"
										onClick={() => this.sendButtonPress('7')}
									>
										<strong>7</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="8"
										onClick={() => this.sendButtonPress('8')}
									>
										<strong>8</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="9"
										onClick={() => this.sendButtonPress('9')}
									>
										<strong>9</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="10"
										onClick={() => this.sendButtonPress('10')}
									>
										<strong>10</strong>
									</LCDButton>
								</Row>
								<Row className="d-flex justify-content-center">
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="11"
										onClick={() => this.sendButtonPress('11')}
									>
										<strong>11</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="12"
										onClick={() => this.sendButtonPress('12')}
									>
										<strong>12</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="13"
										onClick={() => this.sendButtonPress('13')}
									>
										<strong>13</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="14"
										onClick={() => this.sendButtonPress('14')}
									>
										<strong>14</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="15"
										onClick={() => this.sendButtonPress('15')}
									>
										<strong>15</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="16"
										onClick={() => this.sendButtonPress('16')}
									>
										<strong>16</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="17"
										onClick={() => this.sendButtonPress('17')}
									>
										<strong>17</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="18"
										onClick={() => this.sendButtonPress('18')}
									>
										<strong>18</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="19"
										onClick={() => this.sendButtonPress('19')}
									>
										<strong>19</strong>
									</LCDButton>
									<LCDButton
										className="m-2 lcd_button flex-fill p-2"
										outline
										color="warning"
										id="20"
										onClick={() => this.sendButtonPress('20')}
									>
										<strong>20</strong>
									</LCDButton>
								</Row>
							</CardBody>
						</Card>
					</Col>
					<Col md="12" lg="6" xl="6">
						<Card>
							<CardHeader>
								<i className="icon-note" />
								<strong>{t('vmdebugconsol.card4.title')}</strong>
							</CardHeader>
							<CardBody>
								<FormGroup>
									<Label>
										<strong>{t('vmdebugconsol.settings.input1.label')}</strong>
									</Label>
									<InputGroup>
										<div className="input-group-prepend">
											<span className="input-group-text">
												<i className="fa fa-user" />
											</span>
										</div>
										<Input
											onChange={this.vmidOnChange}
											type="text"
											className="form-control-success"
											id="input1-group1"
											name="input1-group1 "
											placeholder={t(
												'vmdebugconsol.settings.input1.placeholder'
											)}
											value={newVmId}
										/>
										{newVmId !== '' && (
											<div className="input-group-append">
												<Button
													onClick={this.submitVMID}
													type="button"
													color="primary"
												>
													Érvényesít
												</Button>
											</div>
										)}
									</InputGroup>
									<LCDText color="" className="mt-3">
										<strong>LCD topic : </strong> /machine/ <Red>{vmId}</Red>{' '}
										/lcd
									</LCDText>
									<LCDText color="">
										<strong>LCD STATUS topic : </strong> /machine/{' '}
										<Red>{vmId}</Red> /lcd_status
									</LCDText>
									<LCDText color="">
										<strong>GOMB PRESS topic : </strong> /machine/{' '}
										<Red>{vmId}</Red> /btn_command
									</LCDText>
								</FormGroup>
								<Button
									onClick={this.lcdON}
									className="mr-2"
									color="success"
									size="md"
								>
									LCD ON
								</Button>
								<Button onClick={this.lcdOFF} color="warning" size="md">
									LCD OFF
								</Button>
								<FormGroup className="mt-4">
									<Label>
										<strong>{t('vmdebugconsol.settings.input2.label')}</strong>
									</Label>
									<InputGroup>
										<div className="input-group-prepend">
											<span className="input-group-text">
												<i className="fa fa-user" />
											</span>
										</div>
										<Input
											onChange={this.pressMessageOnChange}
											type="text"
											className="form-control-success"
											id="input1-group1"
											name="input1-group1 "
											placeholder={t(
												'vmdebugconsol.settings.input2.placeholder'
											)}
											value={buttonId}
										/>

										<div className="input-group-append">
											<Button
												onClick={this.sendPress}
												type="button"
												color="success"
											>
												{t('vmdebugconsol.settings.sendbutton.title')}
											</Button>
										</div>
									</InputGroup>
									<FormText color="">
										<strong>
											{t('vmdebugconsol.settings.input2.infotext')} :{' '}
										</strong>{' '}
										/machine/ <strong>{vmId}</strong> /btn_command -> {buttonId}
									</FormText>
								</FormGroup>
							</CardBody>
							<CardFooter>
								<Button
									onClick={this.reset}
									type="reset"
									className="btn-sm"
									color="danger"
								>
									<i className="fa fa-ban mr-1" />
									{t('vmdebugconsol.settings.resetbutton.title')}
								</Button>
							</CardFooter>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md="12" lg="6" xl="6">
						<Card className="text-black bg-primary">
							<CardHeader>
								<h1>{t('vmdebugconsol.card3.title')}</h1>
							</CardHeader>
							<CardBody>
								{outgoingMessages.map((message, id) => (
									<h5 className=" text-left pb-2" key={`out-element-${id}`}>
										{message}&ensp;
									</h5>
								))}
							</CardBody>
						</Card>
					</Col>
					<Col md="12" lg="6" xl="6">
						<Card className="text-black bg-warning">
							<CardHeader>
								<h1>{t('vmdebugconsol.card2.title')}</h1>
							</CardHeader>
							<CardBody>
								{incomingMessages.map((message, id) => (
									<h5 className="text-left pb-2" key={`in-element-${id}`}>
										{message}&ensp;
									</h5>
								))}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		);
	};
}

export default withTranslation()(VMDebugConsol);
