import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import {
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	NavItem
} from 'reactstrap';

import usersAPI from '../../services/api/users';
import notificationAPI from '../../services/api/notifications';
import Auth from '../../services/api/auth';
import usersApi from '../../services/api/users';

import EditUserDialog from '../../views/UserList/EditUserDialog/EditUserDialog';
import { ROLES } from '../../services/util/consts';

class UserDropdown extends Component {
	state = {
		dropdownOpen: false,
		passwordChangeState: 0
	};

	componentDidMount() {
		this.componentIsMounted = true;
		this.refreshProfile();
	}

	componentWillUnmount() {
		this.componentIsMounted = false;
	}

	showEditDialog() {
		this.setState({ showEditUserDialog: true, passwordChangeState: 0 });
	}

	hideEditDialog() {
		this.setState({ showEditUserDialog: false, passwordChangeState: 0 });
	}

	refreshProfile() {
		Auth.getProfile().then(result => {
			if (this.componentIsMounted) {
				this.setState({ profile: result.data.response });
			}
		});
	}

	toggleDropdown() {
		this.setState({ dropdownOpen: !this.state.dropdownOpen });
	}

	handleSettingsClick() {
		this.showEditDialog();
	}

	handleLogoutClick() {
		this.props.history.replace('/Logout');
	}

	onUserDataSave(userToUpdate, notificationSettings) {
		let tempUser = { ...userToUpdate };
		tempUser.insertedAt = undefined;
		usersAPI.updateUser(tempUser).then(() => {
			this.refreshProfile();
			this.hideEditDialog();
		});

		notificationAPI.saveNotificationSettings(
			this.state.profile.uID,
			notificationSettings
		);
	}

	async changePassword(oldPassword, newPassword) {
		try {
			await usersApi.setNewPassword(oldPassword, newPassword);

			this.setState({ passwordChangeState: 1 });
		} catch (error) {
			this.setState({ passwordChangeState: 2 });
		}
	}

	renderEditUserDialog() {
		if (!!!this.state.profile) return;

		const possibleRoles = [
			ROLES.root,
			ROLES.companyAdmin,
			ROLES.regionalCompanyAdmin,
			ROLES.technician,
			ROLES.hvendTechnican
		];
		return (
			<Modal
				show={this.state.showEditUserDialog}
				onHide={() => this.hideEditDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.props.t('users.dropDown.dialog.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: '0px' }}>
					<EditUserDialog
						cmID={this.state.profile.cmID}
						user={this.state.profile}
						possibleRoles={possibleRoles}
						disableRoleInput
						hideAssignmentSettings={true}
						hideOriginalPassword={false}
						hidePasswordEdit={false}
						passwordChangeState={this.state.passwordChangeState}
						onSave={(userToUpdate, notificationSettings) =>
							this.onUserDataSave(userToUpdate, notificationSettings)
						}
						onSavePassword={(oldPassword, newPassword) => {
							this.changePassword(oldPassword, newPassword).then();
						}}
					/>
				</Modal.Body>
			</Modal>
		);
	}

	renderDropDownCaption() {
		let result = [];
		if (this.state.profile) {
			result.push(
				<i key="usericon" className="fa fa-user" aria-hidden="true"></i>
			);
			result.push(' ');
			if (!!this.state.profile.firstName || !!this.state.profile.lastName) {
				let availableName = !!this.state.profile.firstName
					? this.state.profile.firstName
					: '';
				availableName = !!this.state.profile.firstName
					? availableName + ' '
					: availableName;
				availableName = !!this.state.profile.lastName
					? availableName + this.state.profile.lastName
					: availableName;

				result.push(availableName);
			} else {
				result.push(this.state.profile.email);
			}
		}

		return result;
	}

	render() {
		return (
			<NavItem className={this.props.className}>
				{this.renderEditUserDialog()}
				<ButtonDropdown
					isOpen={this.state.dropdownOpen}
					toggle={() => this.toggleDropdown()}
					direction="up"
				>
					<DropdownToggle
						key="toggle"
						color="transparent"
						caret
						className="mr-2 "
						style={{ paddingBottom: '1px' }}
					>
						{this.renderDropDownCaption()}
					</DropdownToggle>

					<DropdownMenu key="menu">
						<DropdownItem
							key="settings"
							onClick={() => this.handleSettingsClick()}
						>
							{this.props.t('users.dropDown.settings')}
						</DropdownItem>
						<DropdownItem
							key="loguout"
							onClick={() => this.handleLogoutClick()}
						>
							{this.props.t('users.dropDown.logout')}
						</DropdownItem>
					</DropdownMenu>
				</ButtonDropdown>
			</NavItem>
		);
	}
}

const mapStateToProps = state => ({
	usePrivilege: state.user
});

export default withRouter(
	connect(mapStateToProps)(withTranslation()(UserDropdown))
);
