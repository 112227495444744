import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'reactstrap';
import {
	Section,
	SectionList,
	SectionSmallSubTitle,
	SectionSmallTitle,
	SectionSubTitle,
	SectionTitle,
	UnavailableSection
} from '../../../components/Section';
import Toggle from 'react-bootstrap-toggle';
import classes from './ServiceCard.module.css';
import { withTranslation } from 'react-i18next';
import ConfimationDialog from '../../ConfirmationDialog/ConfirmationDialog';

class ServicesCard extends Component {
	state = {
		confirmationVisible: false
	};

	handleToggleInvisibleTouch = newValue => {
		this.props.handleToggleInvisibleTouch(newValue);
	};
	handleToggleTelemetry = newValue => {
		this.props.handleToggleTelemetry(newValue);
	};
	handleToggleRemote = newValue => {
		this.props.handleToggleRemote(newValue);
	};

	hideConfirmationDialog() {
		this.setState({
			confirmationVisible: false
		});
	}

	showConfirmation() {
		this.setState({
			confirmationVisible: true
		});
	}

	renderConfirmationDialog = () => {
		return (
			<ConfimationDialog
				title={this.props.t('device.details.services.confTitle')}
				confirmationText={
					<>
						<Col>{this.props.t('device.details.services.confirmation')}</Col>
					</>
				}
				okText={this.props.t('device.details.invisibleTouch.ok')}
				cancelText={this.props.t('device.details.invisibleTouch.cancel')}
				isOpen={this.state.confirmationVisible}
				onToggle={() => {
					this.hideConfirmationDialog();
				}}
				onOk={() => {
					this.hideConfirmationDialog();
				}}
				cancelOff
			/>
		);
	};

	renderInvisibleTouch(machine) {
		return (
			<Section last>
				<Row>
					<Col>
						<Col>
							<SectionSmallTitle>
								{this.props.t('device.details.services.telemetry')}
							</SectionSmallTitle>
						</Col>
						<Col>
							<SectionSmallSubTitle>
								{
									<Toggle
										onClick={() =>
											this.props.editMode
												? this.props.root
													? this.handleToggleTelemetry(
															machine.telemetryServiceEnabled != 'Y'
													  )
													: this.showConfirmation()
												: {}
										}
										on={this.props.t('device.details.enabled')}
										off={this.props.t('device.details.disabled')}
										active={machine.telemetryServiceEnabled == 'Y'}
										onClassName={classes.OnTelemetry}
										offstyle="primary"
										size="sm"
										enabled={this.props.editMode}
										style={{
											width: '150px',
											height: '30px',
											borderColor: ' var(--invoice-dark)',
											borderRadius: '25px'
										}}
									/>
								}
							</SectionSmallSubTitle>
						</Col>
					</Col>
					<Col>
						<Col>
							<SectionSmallTitle>
								{this.props.t('device.details.services.remoteControl')}
							</SectionSmallTitle>
						</Col>
						<Col>
							<SectionSmallSubTitle>
								{
									<Toggle
										onClick={() =>
											this.props.editMode
												? this.props.root
													? this.handleToggleRemote(
															machine.remoteControlServiceEnabled != 'Y'
													  )
													: this.showConfirmation()
												: {}
										}
										on={this.props.t('device.details.enabled')}
										off={this.props.t('device.details.disabled')}
										active={machine.remoteControlServiceEnabled == 'Y'}
										onClassName={classes.OnRemote}
										offstyle="primary"
										size="sm"
										enabled={this.props.editMode}
										style={{
											width: '150px',
											height: '30px',
											borderColor: ' var(--purchases-dark)',
											borderRadius: '25px'
										}}
									/>
								}
							</SectionSmallSubTitle>
						</Col>
					</Col>
					{this.props.machine.invisibleTouchCapable ? (
						<Col>
							<Col>
								<SectionSmallTitle>
									{this.props.t('device.details.services.invisibleTouch')}
								</SectionSmallTitle>
							</Col>
							<Col>
								<SectionSmallSubTitle>
									{
										<Toggle
											onClick={() =>
												this.props.editMode
													? this.props.root
														? this.handleToggleInvisibleTouch(
																machine.invisibleTouchServiceEnabled != 'Y'
														  )
														: this.showConfirmation()
													: {}
											}
											on={this.props.t('device.details.enabled')}
											off={this.props.t('device.details.disabled')}
											active={machine.invisibleTouchServiceEnabled == 'Y'}
											// onstyle="info"
											onClassName={classes.OnIvtouch}
											offstyle="primary"
											size="sm"
											enabled={this.props.editMode}
											style={{
												width: '150px',
												height: '30px',
												borderColor: ' var(--message-dark)',
												borderRadius: '25px'
											}}
										/>
									}
								</SectionSmallSubTitle>
							</Col>
						</Col>
					) : null}
				</Row>
			</Section>
		);
	}

	render() {
		const { machine, t } = this.props;
		if (!machine) return null;
		return (
			<Card>
				{/* <div
					hidden={machine.note !== 'Virtual HID'}
					className={classes.UnavailableFilter}
				> */}
				{this.renderConfirmationDialog()}
				<UnavailableSection
					unavailable={
						machine.note === 'Virtual HID' &&
						this.props.user.privilege != 'class1'
					}
					unavailableMassage={t('device.details.unavailableMassage')}
				>
					<SectionList>
						<Section>
							<SectionTitle>{t('device.details.services.title')}</SectionTitle>
						</Section>
						{this.renderInvisibleTouch(machine)}
					</SectionList>
				</UnavailableSection>
				{/* </div> */}
			</Card>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user
});

export default connect(mapStateToProps)(withTranslation()(ServicesCard));
