//@flow
import React from 'react'
import { Col, Row } from 'reactstrap'
import VMUtil from '../../../../services/util/vmUtil'
import ButtonWithLabelVertical from '../../../../components/Machines/ButtonWithLabelVertical'

type Props = {
  products: Array<Object>,
  keyboardConfig: Array<
    Array<{
      buttonContent: any,
      buttonID: string,
      ledIndex?: number,
    }>
  >,
  programmingButtonVisible?: boolean,
  onButtonClick?: (buttonId: string, isSlave: ?boolean) => () => Promise<void>,
  ledStatuses?: Array<boolean>,
}

export default class DirectChooseVerticalKeyboard extends React.Component<Props> {
  render() {
    const {
      onButtonClick = (buttonId: string, isSlave: ?boolean) => async () => {},
      keyboardConfig = [],
      ledStatuses = [],
      products = [],
    } = this.props

    return (
      <>
        {keyboardConfig.map((line, rowIndex) => (
          <Row
            key={`machine-keyboard-line-${rowIndex}`}
            className="d-flex justify-content-center m-0"
          >
            {line.map((button, lineIndex) => {
              const { buttonID, ledIndex, buttonContent, ...rest } =
                button || {}
              return (
                <>
                  {button && (
                    <ButtonWithLabelVertical
                      xs={{ size: 12 / line.length }}
                      md={{ size: 12 / line.length }}
                      lg={{ size: 12 / line.length }}
                      xl={{ size: 12 / line.length }}
                      key={`machine-${rowIndex}-${lineIndex}`}
                      onButtonClick={onButtonClick}
                      style={{ margin: '0px', height: '100%' }}
                      buttonContent={buttonContent}
                      buttonID={buttonID}
                      labelContent={VMUtil.getButtonLabel(button, products)}
                      hasLed={
                        ledIndex !== undefined &&
                        ledStatuses[ledIndex] !== undefined
                      }
                      {...(ledIndex !== undefined &&
                      ledStatuses[ledIndex] !== undefined
                        ? {
                            isLedOn: ledStatuses[ledIndex],
                          }
                        : {})}
                      {...rest}
                    />
                  )}
                  {!button && <Col xs="3" md="3" lg="3" xl="3"></Col>}
                </>
              )
            })}
          </Row>
        ))}
      </>
    )
  }
}
