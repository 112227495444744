//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent';
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard';

type Props = {
	vmID: string,
	history: Object,
	products?: Array<Object>
};
type States = {};

class VeneziaPlus extends React.Component<Props, States> {
	keyboardConfig = [
		[
			{
				buttonContent: <>22</>,
				buttonID: '22'
			},
			{
				buttonContent: <>25</>,
				buttonID: '25'
			},
			{
				buttonContent: <>26</>,
				buttonID: '26'
			},
			{
				buttonContent: <>24</>,
				buttonID: '24'
			}
		],
		[
			{
				buttonContent: <>1</>,
				buttonID: '1'
			},
			{
				buttonContent: <>8</>,
				buttonID: '8'
			}
		],
		[
			{
				buttonContent: <>2</>,
				buttonID: '2'
			},
			{
				buttonContent: <>9</>,
				buttonID: '9'
			}
		],
		[
			{
				buttonContent: <>3</>,
				buttonID: '3'
			},
			{
				buttonContent: <>10</>,
				buttonID: '10'
			}
		],
		[
			{
				buttonContent: <>4</>,
				buttonID: '4'
			},
			{
				buttonContent: <>11</>,
				buttonID: '11'
			}
		],
		[
			{
				buttonContent: <>5</>,
				buttonID: '5'
			},
			{
				buttonContent: <>12</>,
				buttonID: '12'
			}
		],
		[
			{
				buttonContent: <>6</>,
				buttonID: '6'
			},
			{
				buttonContent: <>13</>,
				buttonID: '13'
			}
		],
		[
			{
				buttonContent: <>7</>,
				buttonID: '7'
			},
			{
				buttonContent: <>14</>,
				buttonID: '14'
			}
		]
	];

	render() {
		return (
			<DirectChooseMachineBaseComponent
				machineTitle="VeneziaPlus"
				lineNumber={4}
				lineLength={20}
				keyboard={
					<DirectChooseKeyboard
						type="vertical"
						programmingButtonVisible={false}
						keyboardConfig={this.keyboardConfig}
						{...this.props}
					/>
				}
				{...this.props}
			/>
		);
	}
}

export default withRouter(VeneziaPlus);
