import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PackageManager from '../../views/PackageManager/PackageManager';

class PackageManagerPageRoute extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route
						path="/package_manager"
						name="PackageManager"
						component={PackageManager}
					/>
					<Redirect from="/" to="/package_manager" />
				</Switch>
			</div>
		);
	}
}

export default PackageManagerPageRoute;
