import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';

import Button from 'react-bootstrap/Button';
// import Toggle from 'react-bootstrap-toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

import customersAPI from '../../../../services/api/customers';

class IncomeCard extends Component {
	mainChartDaily = {
		labels: [],
		datasets: [
			{
				label: 'income',
				backgroundColor: 'rgba(216,0,28,0.1)',
				borderColor: 'red',
				pointHoverBackgroundColor: '#fff',
				borderWidth: 2,
				data: [],
				yAxisID: 'y-axis-1',
				type: 'bar'
			},
			{
				label: 'sales',
				//	fill: false,
				backgroundColor: 'rgba(99,194,222,0.1)',
				borderColor: '#63C2DE',
				pointHoverBackgroundColor: '#fff',
				borderWidth: 2,
				data: [],
				yAxisID: 'y-axis-2',
				type: 'bar'
			}
		]
	};

	mainChartOptsDaily = {
		tooltips: {
			enabled: true,
			intersect: true,
			mode: 'index',
			position: 'nearest',
			callbacks: {
				label: tooltipItem => {
					const incomeString = this.props.t('customerDashboard.income.income');
					const salesString = this.props.t('customerDashboard.income.sales');
					if (tooltipItem.datasetIndex == 0) {
						return `${incomeString}: ${tooltipItem.yLabel} ${this.props.currency}`;
					} else {
						return `${salesString}: ${tooltipItem.yLabel} ${this.props.t(
							'units.pieces'
						)}`;
					}
				},
				labelColor: function(tooltipItem, chart) {
					return {
						backgroundColor:
							chart.data.datasets[tooltipItem.datasetIndex].borderColor
					};
				}
			}
		},
		plugins: {
			datalabels: {
				display: false
			}
		},
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						drawOnChartArea: false
					}
				}
			],
			yAxes: [
				{
					display: true,
					position: 'left',
					id: 'y-axis-1',
					ticks: {
						beginAtZero: true,
						maxTicksLimit: 10,
						stepSize: Math.ceil(3 / 5),
						max: 2,
						callback: (value, index, values) => {
							return `${value} Ft`;
						}
					}
				},
				{
					display: true,
					position: 'right',
					id: 'y-axis-2',
					ticks: {
						beginAtZero: true,
						maxTicksLimit: 10,
						stepSize: Math.ceil(3 / 5),
						max: 2,
						callback: (value, index, values) => {
							return `${value} ${this.props.t('units.pieces')}`;
						}
					}
				}
			]
		},
		elements: {
			line: {
				tension: 0
			},
			point: {
				radius: 0,
				hitRadius: 10,
				hoverRadius: 4,
				hoverBorderWidth: 3
			}
		}
	};

	mainChartMonthly = {
		labels: [],
		datasets: [
			{
				label: '',
				backgroundColor: 'rgba(216,0,28,0.1)',
				borderColor: 'red',
				pointHoverBackgroundColor: '#fff',
				borderWidth: 2,
				data: []
			}
		]
	};

	mainChartOptsMonthly = {
		tooltips: {
			enabled: false,
			intersect: true,
			mode: 'index',
			position: 'nearest',
			callbacks: {
				label: tooltipItem => {
					const incomeString = this.props.t('customerDashboard.income.income');
					const salesString = this.props.t('customerDashboard.income.sales');
					if (tooltipItem.datasetIndex == 0) {
						return `${incomeString}: ${tooltipItem.yLabel} ${this.props.currency}`;
					} else {
						return `${salesString}: ${tooltipItem.yLabel} ${this.props.t(
							'units.pieces'
						)}`;
					}
				},
				labelColor: function(tooltipItem, chart) {
					return {
						backgroundColor:
							chart.data.datasets[tooltipItem.datasetIndex].borderColor
					};
				}
			}
		},
		plugins: {
			datalabels: {
				display: false
			}
		},
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		scales: {
			xAxes: [
				{
					gridLines: {
						drawOnChartArea: true
					}
				}
			],
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
						maxTicksLimit: 10,
						stepSize: Math.ceil(3 / 5),
						max: 2
					}
				}
			]
		},
		elements: {
			line: {
				tension: 0.01
			},
			point: {
				radius: 0,
				hitRadius: 10,
				hoverRadius: 4,
				hoverBorderWidth: 3
			}
		}
	};

	state = { showMonth: false, visibleDayCount: 31 };

	getLabelFromDateDaily(date) {
		return (
			(date.getMonth() + 1 + '').padStart(2, 0) +
			'. ' +
			(date.getDate() + '').padStart(2, 0) +
			'.'
		);
	}

	getLabelFromDateMonthly(date) {
		return (
			date.getFullYear() +
			'. ' +
			(date.getMonth() + 1 + '').padStart(2, 0) +
			'. '
		);
	}

	isSameDay(d1, d2) {
		return (
			d1.getFullYear() === d2.getFullYear() &&
			d1.getMonth() === d2.getMonth() &&
			d1.getDate() === d2.getDate()
		);
	}

	isSameMonth(d1, d2) {
		return (
			d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth()
		);
	}

	chartDataDaily = [];
	chartDataDailyCount = [];
	chartLabelsDaily = [];

	chartDataMonthly = [];
	chartLabelsMonthly = [];

	dailyData = [];

	prepareChartDataDaily() {
		this.chartDataDaily = [];
		this.chartLabelsDaily = [];
		this.chartDataDailyCount = [];

		const finalDate = new Date();
		for (let i = this.state.visibleDayCount; i >= 0; i--) {
			let currentDate = new Date();
			currentDate.setDate(finalDate.getDate() - i);
			let dayData = this.dailyData.find(row =>
				this.isSameDay(new Date(row.tickDateTime), currentDate)
			);
			if (dayData) {
				this.chartDataDaily.push(dayData.tickValue);
				this.chartDataDailyCount.push(dayData.tickCount);
				this.chartLabelsDaily.push(
					this.getLabelFromDateDaily(new Date(dayData.tickDateTime))
				);
			} else {
				this.chartDataDaily.push(0);
				this.chartDataDailyCount.push(0);
				this.chartLabelsDaily.push(
					this.getLabelFromDateDaily(new Date(currentDate))
				);
			}
		}

		this.updateChartDaily();
	}

	prepareChartDataMonthly(data) {
		this.chartDataMonthly = [];
		this.chartLabelsMonthly = [];

		const finalDate = new Date();
		for (let i = this.visibleMonthCount; i >= 0; i--) {
			let currentDate = new Date();
			currentDate.setDate(1); // There was an issue with the 31. day of the months
			currentDate.setMonth(finalDate.getMonth() - i);
			let monthData = data.find(row =>
				this.isSameMonth(new Date(row.tickDateTime), currentDate)
			);
			if (monthData) {
				this.chartDataMonthly.push(monthData.tickValue);
				this.chartLabelsMonthly.push(
					this.getLabelFromDateMonthly(new Date(monthData.tickDateTime))
				);
			} else {
				this.chartDataMonthly.push(0);
				this.chartLabelsMonthly.push(
					this.getLabelFromDateMonthly(new Date(currentDate))
				);
			}
		}

		this.updateChartMonthly();
	}

	updateChartDaily() {
		if (!!!this.referenceDaily) return;

		this.mainChartDaily.datasets[0].data = this.chartDataDaily;
		if (this.mainChartDaily.datasets.length > 1) {
			this.mainChartDaily.datasets[1].data = this.chartDataDailyCount;
			this.mainChartDaily.datasets[1].labels = this.chartLabelsDaily;
		}
		this.mainChartDaily.labels = this.chartLabelsDaily;

		let maxValue =
			this.chartDataDaily.length === 0
				? 0
				: this.chartDataDaily.reduce((prev, current) =>
						current > prev ? current : prev
				  );

		this.referenceDaily.chartInstance.options.scales.yAxes[0].ticks.max = maxValue;
		this.referenceDaily.chartInstance.options.scales.yAxes[0].ticks.stepSize = Math.min(
			1,
			Math.round(maxValue / 5)
		);

		maxValue =
			this.chartDataDailyCount.length === 0
				? 0
				: this.chartDataDailyCount.reduce((prev, current) =>
						current > prev ? current : prev
				  );
		this.referenceDaily.chartInstance.options.scales.yAxes[1].ticks.max = maxValue;
		this.referenceDaily.chartInstance.options.scales.yAxes[1].ticks.stepSize = Math.min(
			1,
			Math.round(maxValue / 5)
		);

		this.referenceDaily.chartInstance.update();
	}

	updateChartMonthly() {
		if (!!!this.referenceMonthly) return;

		this.mainChartMonthly.datasets[0].data = this.chartDataMonthly;
		this.mainChartMonthly.labels = this.chartLabelsMonthly;

		const maxValue =
			this.chartDataMonthly.length === 0
				? 0
				: this.chartDataMonthly.reduce((prev, current) =>
						current > prev ? current : prev
				  );

		this.referenceMonthly.chartInstance.options.scales.yAxes[0].ticks.max = maxValue;
		this.referenceMonthly.chartInstance.options.scales.yAxes[0].ticks.stepSize = Math.min(
			1,
			Math.round(maxValue / 5)
		);

		this.referenceMonthly.chartInstance.update();
	}

	componentDidMount() {
		this.loadData();
	}

	loadData() {
		customersAPI.getDailyIncome().then(resp => {
			this.dailyData = resp.data.response;
			this.prepareChartDataDaily();
		});
		/*
		this.visibleMonthCount = 12;
		customersAPI.getMonthlyIncome().then(resp => {
			this.prepareChartDataMonthly(resp.data.response);
		});*/
	}

	onToggleMonthlyView() {
		this.setState({ showMonth: !this.state.showMonth });
	}

	onToggleVisibleDayCount() {
		if (this.state.visibleDayCount === 7) {
			this.setState({ visibleDayCount: 31 }, () => {
				this.props.onDayCountChange(this.state.visibleDayCount);
				this.prepareChartDataDaily();
			});
		} else {
			this.setState({ visibleDayCount: 7 }, () => {
				this.props.onDayCountChange(this.state.visibleDayCount);
				this.prepareChartDataDaily();
			});
		}
	}

	render() {
		const { t } = this.props;
		let chart;
		let subtitle;
		if (!this.state.showMonth) {
			subtitle = t('customerDashboard.income.subTitle.daily');
			chart = (
				<Bar
					data={this.mainChartDaily}
					options={this.mainChartOptsDaily}
					height={300}
					ref={reference => {
						this.referenceDaily = reference;
						this.updateChartDaily();
					}}
					redraw
				/>
			);
		} else {
			subtitle = t('customerDashboard.income.subTitle.monthly');
			chart = (
				<Bar
					data={this.mainChartMonthly}
					options={this.mainChartOptsMonthly}
					height={300}
					ref={reference => {
						this.referenceMonthly = reference;
						this.updateChartMonthly();
					}}
					redraw
				/>
			);
		}

		return (
			<Card className={this.props.className} style={this.props.style}>
				<CardBody>
					<Row>
						<Col sm="2">
							<CardTitle className="mb-0 text-muted">
								<i
									className="fa fa-line-chart fa-lg"
									style={{ marginRight: '10px' }}
								></i>
								{t('customerDashboard.income.title')}
							</CardTitle>

							<div className="small text-muted">
								{this.state.visibleDayCount === 7
									? t('customerDashboard.income.subTitle.daily7')
									: t('customerDashboard.income.subTitle.daily31')}
							</div>
						</Col>
						<Col sm="8">
							<div style={{ textAlign: 'center' }}>
								<Button
									className={this.props.IncomeSwitchButton}
									onClick={() => this.onToggleVisibleDayCount()}
									disabled={this.state.visibleDayCount === 7}
								>
									<i className="fa fa-line-chart mr-2"></i>
									{t('customerDashboard.income.daily7')}
								</Button>
								<Button
									className={this.props.IncomeSwitchButton}
									onClick={() => this.onToggleVisibleDayCount()}
									disabled={this.state.visibleDayCount !== 7}
								>
									<i className="fa fa-line-chart mr-2"></i>
									{t('customerDashboard.income.daily31')}
								</Button>
							</div>
						</Col>
						<Col sm="2"></Col>
					</Row>
					<div
						className="chart-wrapper"
						style={{ height: 300 + 'px', marginTop: '10px' }}
					>
						{chart}
					</div>
				</CardBody>
			</Card>
		);
	}
}

export default withTranslation()(IncomeCard);
