//@flow
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
	Card,
	CardBody,
	CardGroup,
	CardImg,
	Col,
	Container,
	Input,
	InputGroup,
	Row
} from 'reactstrap';
import Form from 'react-bootstrap/Form';
import { EXPAND_LEFT } from 'react-ladda';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { setLanguage as setI18n } from '../../localization';
import { withTranslation } from 'react-i18next';
import 'ladda/dist/ladda-themeless.min.css';
import { withRouter } from 'react-router-dom';

import classes from './RegistrationForm.module.css';

import UsersApi from '../../services/api/users';
import { deleteUser } from '../../store/user';
import Logo from '../../assets/images/logo.png';
import { setLanguage as setLanguageInStore } from '../../store/langauge';

class RegistrationForm extends Component {
	state = {
		editedUser: {
			firstName: '',
			lastName: '',
			connPhone: '',
			password: '',
			passwordConfirm: ''
		},
		underActivation: false
	};

	passwordMinLength = 6;

	constructor(props) {
		super(props);
		this.props.deleteUser();
	}

	componentDidMount() {
		UsersApi.getLanguageByPassKey(this.props.match.params.key)
			.then(resp => {
				const langCode = resp.data.response;
				this.props.setLanguage(langCode);
				setI18n(langCode);
			})
			.catch(e => {
				this.setState({ invalidKey: true });
			});
	}

	handleInputChange(e) {
		let editedUser = { ...this.state.editedUser };
		editedUser[e.target.id] = e.target.value;
		this.setState({ editedUser: editedUser });
	}

	handleFormSubmit(e) {
		e.preventDefault();

		this.setState({ underActivation: true }, () => {
			UsersApi.activateUser({
				firstName: this.state.editedUser.firstName,
				lastName: this.state.editedUser.lastName,
				passw: this.state.editedUser.password,
				passKey: this.props.match.params.key,
				connPhone: this.state.editedUser.connPhone
			})
				.then(() => {
					this.props.history.replace('/');
				})
				.catch(() => {
					this.setState({ underActivation: false });
				});
		});
	}

	renderPasswordAlert() {
		const { t } = this.props;
		if (
			this.state.editedUser.password.length === 0 ||
			this.state.editedUser.passwordConfirm.length === 0
		) {
			return (
				<p className={'text-left ' + classes.Password}>
					{t('login.resetPassword.info')}
				</p>
			);
		}

		if (this.state.editedUser.password.length < this.passwordMinLength) {
			return (
				<p className={'text-left ' + classes.Password + ' ' + classes.Error}>
					Password too short!
				</p>
			);
		}

		if (
			this.state.editedUser.password !== this.state.editedUser.passwordConfirm
		) {
			return (
				<p className={'text-left ' + classes.Password + ' ' + classes.Error}>
					Password mistmatch!
				</p>
			);
		}

		return (
			<p className={'text-left ' + classes.Password}>Passwords are correct!</p>
		);
	}

	isRegisterButtonEnabled() {
		if (
			this.state.underActivation ||
			this.state.editedUser.firstName.length === 0 ||
			this.state.editedUser.lastName.length === 3 ||
			this.state.editedUser.password.length < this.passwordMinLength ||
			this.state.editedUser.password !== this.state.editedUser.passwordConfirm
		) {
			return false;
		}

		return true;
	}

	render() {
		const { t } = this.props;
		if (this.state.invalidKey) {
			return (
				<Redirect
					to={{
						pathname: '/login'
					}}
				/>
			);
		} else {
			return (
				<div className="app flex-row align-items-center">
					<Container>
						<Row className="justify-content-center">
							<Col md="5">
								<CardGroup className="mb-4">
									<Card className="p-8">
										<CardBody>
											<div className="text-left">
												<CardImg
													className="card-img-top"
													id="mainLogo"
													src={Logo}
													alt="Card image cap"
												/>
											</div>
											<h1>{t('login.resetPassword.title')}</h1>
											<Form onSubmit={e => this.handleFormSubmit(e)}>
												<Form.Label>
													{t('login.registration.firstName')}
												</Form.Label>
												<InputGroup className="mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="fa fa-user" />
														</span>
													</div>
													<Form.Control
														id="firstName"
														type="text"
														value={this.state.editedUser.firstName}
														onChange={e => this.handleInputChange(e)}
													/>
												</InputGroup>
												<Form.Label>
													{t('login.registration.lastName')}
												</Form.Label>
												<InputGroup className="mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="fa fa-user" />
														</span>
													</div>
													<Form.Control
														id="lastName"
														type="text"
														value={this.state.editedUser.lastName}
														onChange={e => this.handleInputChange(e)}
													/>
												</InputGroup>
												<Form.Label>
													{t('login.registration.phoneNumber')}
												</Form.Label>
												<InputGroup className="mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="fa fa-phone" />
														</span>
													</div>
													<Form.Control
														id="connPhone"
														type="text"
														value={this.state.editedUser.connPhone}
														onChange={e => this.handleInputChange(e)}
													/>
												</InputGroup>
												{this.renderPasswordAlert()}
												<InputGroup className="mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="fa fa-lock" />
														</span>
													</div>
													<Input
														type="password"
														id="password"
														placeholder={t('login.password')}
														value={this.state.editedUser.password}
														onChange={e => this.handleInputChange(e)}
													/>
												</InputGroup>
												<InputGroup className="mb-3">
													<div className="input-group-prepend">
														<span className="input-group-text">
															<i className="fa fa-lock" />
														</span>
													</div>
													<Input
														type="password"
														id="passwordConfirm"
														placeholder={t('login.passwordAgain')}
														value={this.state.editedUser.password_confirm}
														onChange={e => this.handleInputChange(e)}
													/>
												</InputGroup>
												<Row>
													<Col xs="12">
														<Button
															id="redButton"
															className={
																'btn btn-success btn-ladda ' +
																classes.RegisterButton
															}
															data-style={EXPAND_LEFT}
															type="submit"
															disabled={!this.isRegisterButtonEnabled()}
														>
															{t('login.resetPassword.button')}
														</Button>
													</Col>
												</Row>
											</Form>
										</CardBody>
									</Card>
								</CardGroup>
							</Col>
						</Row>
					</Container>
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	const { language, user } = state;
	return { language, user };
}

const mapDispatchToProps = dispatch => {
	return {
		deleteUser: () => {
			dispatch(deleteUser());
		},
		setLanguage: language => dispatch(setLanguageInStore(language))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withTranslation()(RegistrationForm)));
