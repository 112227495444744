import React, { Component } from 'react';
import { Card, Nav, Label, Row, CardBody, Col, CardTitle } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import classes from './CampaningManager.module.css';

import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import FilterBar from '../../views/FilterBar/FilterBar';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import AddCampaningDialog from './AddCampaningDialog/AddCampaningDialog';
import EditCampaningDialog from './EditCampaningDialog/EditCampaningDialog';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import proxy from '../PromotionManager/proxy';

class CampaningManager extends Component {
	state = {
		campaning: proxy.campaning
	};

	//Render Filterbar with ADDButton
	renderFilterBar = () => {
		return (
			<FilterBar
				key="filterbar"
				showAddButton
				onShowCreateItem={() => this.handleAddButtonClick()}
				// onFilterChanged={value => this.onFilterChange(value)}
			/>
		);
	};

	// filterCampaning() {
	//     let filteredCampaning = [...this.state.Campaning];
	//     let filterValue = this.state.currentFilterValue
	//         ? this.state.currentFilterValue.trim().toUpperCase()
	//         : '';
	//     if (filterValue.length > 0) {
	//         filteredCampaning = this.state.Campaning.filter(Campaning => {
	//             let curCampaning = { ...Campaning };
	//             let CostumerFieldArray = Object.values(curCampaning);
	//             return CostumerFieldArray.some(
	//                 val =>
	//                     String(val)
	//                         .toUpperCase()
	//                         .indexOf(filterValue) >= 0
	//             );
	//         });
	//     }

	//     this.setState({ filteredCampaning: filteredCampaning });
	// }

	// onFilterChange(filteValue) {
	//     this.setState({ currentFilterValue: filteValue }, () => this.filterCampaning());
	// }

	handleAddButtonClick = () => {
		this.showAddDialog();
	};

	//AddCostumer rendering and handleing

	showAddDialog() {
		this.setState({ showAddCustomerDialog: true });
	}

	hideAddDialog() {
		this.setState({ showAddCustomerDialog: false });
	}

	renderAddCostumerrDialog() {
		return (
			<Modal
				show={this.state.showAddCustomerDialog}
				onHide={() => this.hideAddDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.props.t('customerlist.addbutton.label')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<AddCampaningDialog />
				</Modal.Body>
			</Modal>
		);
	}

	//editProfil------------------------------------------
	editProfil = costumer => {
		this.showEditDialog(costumer);
	};

	showEditDialog(costumer) {
		this.setState({ showEditCampaningDialog: true, costumerToEdit: costumer });
	}

	hideEditDialog() {
		this.setState({ showEditCampaningDialog: false, costumerToEdit: null });
	}

	onDeleteUser = costumer => {
		this.setState({ costumerToDelete: costumer });
	};

	//  Edit ikon klikk
	editFormatter = (cell, row) => {
		return (
			<>
				<Button
					onClick={e => this.editProfil(row)}
					variant="success"
					className={classes.EditButton}
				>
					<i className="fa fa-pencil" />
				</Button>
				<Button
					onClick={e => this.onDeleteUser(row)}
					className={classes.DeleteButton}
				>
					<i className="fa fa-trash" />
				</Button>
			</>
		);
	};
	//  render DeletConfirmation
	renderDeleteConfirmation() {
		if (this.state.costumerToDelete) {
			return (
				<ConfirmationDialog
					isOpen={this.state.costumerToDelete}
					onToggle={() => this.setState({ costumerToDelete: null })}
					title={this.props.t('customerlist.deletebutton.title')}
					confirmationText={this.props.t('customerlist.deletebutton.label')}
					cancelText={this.props.t('customerlist.deletebutton.cancel')}
					okText={this.props.t('customerlist.deletebutton.ok')}
					onCancel={() => this.setState({ costumerToDelete: null })}
					onOk={() => this.setState({ costumerToDelete: null })}
					// onOk={() => this.onDeleteUserConfirmed()}
				/>
			);
		} else {
			return null;
		}
	}

	// render EditDialog
	renderEditUserDialog() {
		return (
			<Modal
				show={this.state.showEditCampaningDialog}
				onHide={() => this.hideEditDialog()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<i
							class="icon-people"
							style={{ marginRight: '10px', width: '640px' }}
						></i>
						{this.props.t('customerlist.editbutton.label')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ backgroundColor: 'GhostWhite' }}>
					<EditCampaningDialog costumer={this.state.costumerToEdit} />
				</Modal.Body>
			</Modal>
		);
	}

	//-----------------------------------------------------------------

	//Data formaters------------------
	//User ikon
	userFormatter = (cell, row) => {
		return <i className="icon-user" id="userIcon" />;
	};

	//Render Table
	isColumnsVisibile(columName) {
		return (
			!this.props.hiddenColumns ||
			this.props.hiddenColumns.indexOf(columName) < 0
		);
	}

	renderTable() {
		//Table options
		const tableOptions = {
			sortIndicator: true,
			hideSizePerPage: false,
			paginationSize: 3,
			sizePerPageList: [5, 10, 25, 50, 100],
			sizePerPage: this.props.recordPerPage || 10,
			withFirstAndLast: true,
			paginationPosition: 'bottom',
			alwaysShowAllBtns: false,
			clearSearch: true,
			hidePageListOnlyOnePage: true,
			sortName: 'ID',
			sortOrder: 'asc',
			searchPanel: this.renderFilterBar,
			onSearchChange: this.onFilterChange
		};
		// const { t } = this.props;
		return (
			<>
				{!this.props.hideToolbar ? this.renderFilterBar() : null}
				<BootstrapTable
					key="bootstrapTable"
					data={this.state.campaning}
					version="4"
					striped
					hover
					pagination={!this.props.hidePager}
					insertRow={false}
					options={tableOptions}
					multiColumnSearch
					condensed
					bordered={false}
					dataAlign={'center'}
					size="sm"
				>
					{this.isColumnsVisibile('userIcon') ? (
						<TableHeaderColumn
							isKey
							width="30"
							dataAlign="center"
							dataField="ID"
							dataFormat={this.userFormatter}
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px' }}
						/>
					) : null}
					{this.isColumnsVisibile('campaningadat1') ? (
						<TableHeaderColumn
							dataField="campaningadat1"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal',
								fontWeight: 'bold'
							}}
						>
							{'campaningadat1'}
						</TableHeaderColumn>
					) : null}

					{this.isColumnsVisibile('campaningadat2') ? (
						<TableHeaderColumn
							dataField="campaningadat2"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{'campaningadat2'}
						</TableHeaderColumn>
					) : null}
					{this.isColumnsVisibile('campaningadat3') ? (
						<TableHeaderColumn
							dataField="campaningadat3"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{'campaningadat3'}
						</TableHeaderColumn>
					) : null}

					{this.isColumnsVisibile('campaningadat4') ? (
						<TableHeaderColumn
							dataField="campaningadat4"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal'
							}}
						>
							{'campaningadat4'}
						</TableHeaderColumn>
					) : null}
					{this.isColumnsVisibile('campaningadat4') ? (
						<TableHeaderColumn
							dataField="campaningadat4"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{
								paddingTop: '15px',
								whiteSpace: 'normal'
							}}
						>
							{'campaningadat4'}
						</TableHeaderColumn>
					) : null}
					{this.isColumnsVisibile('campaningadat5') ? (
						<TableHeaderColumn
							dataField="campaningadat5"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{'campaningadat5'}
						</TableHeaderColumn>
					) : null}

					{this.isColumnsVisibile('campaningadat6') ? (
						<TableHeaderColumn
							dataField="campaningadat6"
							dataSort
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
							tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						>
							{'campaningadat6'}
						</TableHeaderColumn>
					) : null}
					{this.isColumnsVisibile('action') ? (
						<TableHeaderColumn
							width="90"
							dataField="userID"
							dataFormat={this.editFormatter}
							thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						>
							{this.props.t('customerlist.editbutton.title')}
						</TableHeaderColumn>
					) : null}
				</BootstrapTable>
			</>
		);
	}

	//Render Title
	renderTitle() {
		const { titleOnClick = () => {} } = this.props;

		return (
			<Row>
				<Col>
					<CardTitle
						className="text-muted"
						style={{ cursor: 'pointer' }}
						onClick={titleOnClick}
					>
						<i className={`icon-people`} style={{ marginRight: '10px' }} />
						{/* {this.props.t('customerlist.title')} */}
						{'Kampányok'}
					</CardTitle>
				</Col>
			</Row>
		);
	}

	//Render!
	render() {
		return (
			<div className="animated fadeIn">
				<Nav>
					<Card body>
						<Label className="h1">Kampány Manager</Label>
						<Label className="h5">Fejlesztés alatt</Label>
					</Card>
				</Nav>
				<DashboardCardContainerWithFlow>
					<Card body>
						<PageTiltleCard title={'Kampány Manager'} />
						{this.renderEditUserDialog()}
						{this.renderAddCostumerrDialog()}
						{this.renderDeleteConfirmation()}
						<Card className={this.props.className} style={this.props.style}>
							<CardBody>
								{this.renderTitle()}
								{this.renderTable()}
							</CardBody>
						</Card>
					</Card>
				</DashboardCardContainerWithFlow>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	usePrivilege: state.costumer
});

export default connect(mapStateToProps)(withTranslation()(CampaningManager));
