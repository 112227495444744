// ---
import alergen_gluten from '../../assets/alergens/allergen_gluten.png';
import alergen_milk from '../../assets/alergens/allergen_milk.png';
import alergen_tree_nuts from '../../assets/alergens/allergen_tree_nuts.png';
import alergen_celery from '../../assets/alergens/allergen_celery.png';
import alergen_egg from '../../assets/alergens/allergen_egg.png';
import alergen_fish from '../../assets/alergens/allergen_fish.png';
import alergen_lupin from '../../assets/alergens/allergen_lupin.png';
import alergen_molluscs from '../../assets/alergens/allergen_molluscs.png';
import alergen_mustard from '../../assets/alergens/allergen_mustard.png';
import alergen_peanut from '../../assets/alergens/allergen_peanut.png';
import alergen_sesame from '../../assets/alergens/allergen_sesame.png';
import alergen_soy from '../../assets/alergens/allergen_soy.png';
import alergen_sulphur_dioxide from '../../assets/alergens/allergen_sulphur_dioxide.png';
// ---- Location flags -------------
import AF_flag from '../../assets/flags/Afghanistan - AF.png';
import AL_flag from '../../assets/flags/Albania - AL.png';
import DZ_flag from '../../assets/flags/Algeria - DZ.png';
import AD_flag from '../../assets/flags/Andorra - AD.png';
import AO_flag from '../../assets/flags/Angola - AO.png';
import AG_flag from '../../assets/flags/Antigua and Barbuda - AG.png';
import AR_flag from '../../assets/flags/Argentina - AR.png';
import AM_flag from '../../assets/flags/Armenia - AM.png';
import AU_flag from '../../assets/flags/Australia - AU.png';
import AT_flag from '../../assets/flags/Austria - AT.png';
import AZ_flag from '../../assets/flags/Azerbaijan - AZ.png';
import BS_flag from '../../assets/flags/Bahamas - BS.png';
import BH_flag from '../../assets/flags/Bahrain - BH.png';
import BD_flag from '../../assets/flags/Bangladesh - BD.png';
import BB_flag from '../../assets/flags/Barbados - BB.png';
import EU_flag from '../../assets/flags/Basque - EU.png';
import BY_flag from '../../assets/flags/Belarus - BY.png';
import BE_flag from '../../assets/flags/Belgium - BE.png';
import BZ_flag from '../../assets/flags/Belize - BZ.png';
import BJ_flag from '../../assets/flags/Benin - BJ.png';
import BT_flag from '../../assets/flags/Bhutan - BT.png';
import BO_flag from '../../assets/flags/Bolivia - BO.png';
import BA_flag from '../../assets/flags/Bosnia and Herzegovina - BA.png';
import BW_flag from '../../assets/flags/Botswana - BW.png';
import BR_flag from '../../assets/flags/Brazil - BR.png';
import BN_flag from '../../assets/flags/Brunei Darussalam - BN.png';
import BG_flag from '../../assets/flags/Bulgaria - BG.png';
import BF_flag from '../../assets/flags/Burkina Faso - BF.png';
import BI_flag from '../../assets/flags/Burundi - BI.png';
import CV_flag from '../../assets/flags/Cabo Verde - CV.png';
import KH_flag from '../../assets/flags/Cambodia - KH.png';
import CM_flag from '../../assets/flags/Cameroon - CM.png';
//import CA_flag from '../../assets/flags/Canada - CA.png';
import CA_flag from '../../assets/flags/Catalan - CA .png';
import CF_flag from '../../assets/flags/Central African Republic - CF.png';
import TD_flag from '../../assets/flags/Chad - TD.png';
import CL_flag from '../../assets/flags/Chile - CL.png';
import CN_flag from '../../assets/flags/China - CN.png';
import CO_flag from '../../assets/flags/Colombia - CO.png';
import KM_flag from '../../assets/flags/Comoros - KM.png';
import CG_flag from '../../assets/flags/Congo - CG.png';
import CR_flag from '../../assets/flags/Costa Rica - CR.png';
import HR_flag from '../../assets/flags/Croatia - HR.png';
import CU_flag from '../../assets/flags/Cuba - CU.png';
import CY_flag from '../../assets/flags/Cyprus - CY.png';
import CZ_flag from '../../assets/flags/Czechia - CZ.png';
//import CI_flag from '../../assets/flags/Côte d'Ivoire - CI.png';
import CD_flag from '../../assets/flags/Democratic Republic of Congo - CD.png';
import DK_flag from '../../assets/flags/Denmark - DK.png';
import DJ_flag from '../../assets/flags/Djibouti - DJ.png';
import DM_flag from '../../assets/flags/Dominica - DM.png';
import DO_flag from '../../assets/flags/Dominican Republic - DO.png';
import EC_flag from '../../assets/flags/Ecuador - EC.png';
import EG_flag from '../../assets/flags/Egypt - EG.png';
import SV_flag from '../../assets/flags/El Salvador - SV.png';
import GQ_flag from '../../assets/flags/Equatorial Guinea - GQ.png';
import ER_flag from '../../assets/flags/Eritrea - ER.png';
import EE_flag from '../../assets/flags/Estonia - EE.png';
import SZ_flag from '../../assets/flags/Eswatini - SZ.png';
import ET_flag from '../../assets/flags/Ethiopia - ET.png';
import FJ_flag from '../../assets/flags/Fiji - FJ.png';
import FI_flag from '../../assets/flags/Finland - FI.png';
import FR_flag from '../../assets/flags/France - FR.png';
import GA_flag from '../../assets/flags/Gabon - GA.png';
import GM_flag from '../../assets/flags/Gambia  - GM.png';
import GE_flag from '../../assets/flags/Georgia - GE.png';
import DE_flag from '../../assets/flags/Germany - DE.png';
import GH_flag from '../../assets/flags/Ghana - GH.png';
import GR_flag from '../../assets/flags/Greece - GR.png';
import GL_flag from '../../assets/flags/Greenland - GL.png';
import GD_flag from '../../assets/flags/Grenada - GD.png';
import GT_flag from '../../assets/flags/Guatemala - GT.png';
import GN_flag from '../../assets/flags/Guinea - GN.png';
import GW_flag from '../../assets/flags/Guinea-Bissau - GW.png';
import GY_flag from '../../assets/flags/Guyana - GY.png';
import HT_flag from '../../assets/flags/Haiti - HT.png';
import HN_flag from '../../assets/flags/Honduras - HN.png';
import HU_flag from '../../assets/flags/Hungary - HU.png';
import IS_flag from '../../assets/flags/Iceland - IS.png';
import IN_flag from '../../assets/flags/India - IN.png';
import ID_flag from '../../assets/flags/Indonesia - ID.png';
import IR_flag from '../../assets/flags/Iran - IR.png';
import IQ_flag from '../../assets/flags/Iraq - IQ.png';
import IE_flag from '../../assets/flags/Ireland - IE.png';
import IL_flag from '../../assets/flags/Israel - IL.png';
import IT_flag from '../../assets/flags/Italy - IT.png';
import JM_flag from '../../assets/flags/Jamaica - JM.png';
import JP_flag from '../../assets/flags/Japan - JP.png';
import JO_flag from '../../assets/flags/Jordan - JO.png';
import KZ_flag from '../../assets/flags/Kazakhstan - KZ.png';
import KE_flag from '../../assets/flags/Kenya - KE.png';
import KI_flag from '../../assets/flags/Kiribati - KI.png';
import KW_flag from '../../assets/flags/Kuwait - KW.png';
import KG_flag from '../../assets/flags/Kyrgyzstan - KG.png';
import LA_flag from "../../assets/flags/Lao People's Democratic Republic - LA.png";
import LV_flag from '../../assets/flags/Latvia - LV.png';
import LB_flag from '../../assets/flags/Lebanon - LB.png';
import LS_flag from '../../assets/flags/Lesotho - LS.png';
import LR_flag from '../../assets/flags/Liberia - LR.png';
import LY_flag from '../../assets/flags/Libya - LY.png';
import LI_flag from '../../assets/flags/Liechtenstein - LI.png';
import LT_flag from '../../assets/flags/Lithuania - LT.png';
import LU_flag from '../../assets/flags/Luxembourg - LU.png';
import MG_flag from '../../assets/flags/Madagascar - MG.png';
import MW_flag from '../../assets/flags/Malawi - MW.png';
import MY_flag from '../../assets/flags/Malaysia - MY.png';
import MV_flag from '../../assets/flags/Maldives - MV.png';
import ML_flag from '../../assets/flags/Mali - ML.png';
import MT_flag from '../../assets/flags/Malta - MT.png';
import MH_flag from '../../assets/flags/Marshall Islands - MH.png';
import MR_flag from '../../assets/flags/Mauritania - MR.png';
import MU_flag from '../../assets/flags/Mauritius - MU.png';
import MX_flag from '../../assets/flags/Mexico - MX.png';
import FM_flag from '../../assets/flags/Micronesia  - FM.png';
import MD_flag from '../../assets/flags/Moldova - MD.png';
import MC_flag from '../../assets/flags/Monaco - MC.png';
import MN_flag from '../../assets/flags/Mongolia - MN.png';
import ME_flag from '../../assets/flags/Montenegro - ME.png';
import MA_flag from '../../assets/flags/Morocco - MA.png';
import MZ_flag from '../../assets/flags/Mozambique - MZ.png';
import MM_flag from '../../assets/flags/Myanmar - MM.png';
import NA_flag from '../../assets/flags/Namibia - NA.png';
import NR_flag from '../../assets/flags/Nauru - NR.png';
import NP_flag from '../../assets/flags/Nepal - NP.png';
import NL_flag from '../../assets/flags/Netherlands - NL.png';
import NZ_flag from '../../assets/flags/New Zealand - NZ.png';
import NI_flag from '../../assets/flags/Nicaragua - NI.png';
import NE_flag from '../../assets/flags/Niger  - NE.png';
import NG_flag from '../../assets/flags/Nigeria - NG.png';
import KR_flag from '../../assets/flags/North Korea  - KR.png';
import NO_flag from '../../assets/flags/Norway - NO.png';
import OM_flag from '../../assets/flags/Oman - OM.png';
import PK_flag from '../../assets/flags/Pakistan - PK.png';
import PW_flag from '../../assets/flags/Palau - PW.png';
import PA_flag from '../../assets/flags/Panama - PA.png';
import PG_flag from '../../assets/flags/Papua New Guinea - PG.png';
import PY_flag from '../../assets/flags/Paraguay - PY.png';
import PE_flag from '../../assets/flags/Peru - PE.png';
import PH_flag from '../../assets/flags/Philippines - PH.png';
import PL_flag from '../../assets/flags/Poland - PL.png';
import PT_flag from '../../assets/flags/Portugal - PT.png';
import QA_flag from '../../assets/flags/Qatar - QA.png';
import MK_flag from '../../assets/flags/Republic of North Macedonia - MK.png';
import RO_flag from '../../assets/flags/Romania - RO.png';
import RU_flag from '../../assets/flags/Russian Federation - RU.png';
import RW_flag from '../../assets/flags/Rwanda - RW.png';
import KN_flag from '../../assets/flags/Saint Kitts and Nevis - KN.png';
import LC_flag from '../../assets/flags/Saint Lucia - LC.png';
import VC_flag from '../../assets/flags/Saint Vincent and the Grenadines - VC.png';
import WS_flag from '../../assets/flags/Samoa - WS.png';
import SM_flag from '../../assets/flags/San Marino - SM.png';
import ST_flag from '../../assets/flags/Sao Tome and Principe - ST.png';
import SA_flag from '../../assets/flags/Saudi Arabia - SA.png';
import SN_flag from '../../assets/flags/Senegal - SN.png';
import RS_flag from '../../assets/flags/Serbia - RS.png';
import SC_flag from '../../assets/flags/Seychelles - SC.png';
import SL_flag from '../../assets/flags/Sierra Leone - SL.png';
import SG_flag from '../../assets/flags/Singapore - SG.png';
import SK_flag from '../../assets/flags/Slovakia - SK.png';
import SI_flag from '../../assets/flags/Slovenia - SI.png';
import SB_flag from '../../assets/flags/Solomon Islands - SB.png';
import SO_flag from '../../assets/flags/Somalia - SO.png';
import ZA_flag from '../../assets/flags/South Africa - ZA.png';
import KP_flag from '../../assets/flags/South Korea - KP.png';
import ES_flag from '../../assets/flags/Spain - ES.png';
import LK_flag from '../../assets/flags/Sri Lanka - LK.png';
import SD_flag from '../../assets/flags/Sudan  - SD.png';
import SR_flag from '../../assets/flags/Suriname - SR.png';
import SE_flag from '../../assets/flags/Sweden - SE.png';
import CH_flag from '../../assets/flags/Switzerland - CH.png';
import SY_flag from '../../assets/flags/Syrian Arab Republic - SY.png';
import TW_flag from '../../assets/flags/Taiwan  - TW.png';
import TJ_flag from '../../assets/flags/Tajikistan - TJ.png';
import TZ_flag from '../../assets/flags/Tanzania - TZ.png';
import TH_flag from '../../assets/flags/Thailand - TH.png';
import TL_flag from '../../assets/flags/Timor-Leste - TL.png';
import TG_flag from '../../assets/flags/Togo - TG.png';
import TO_flag from '../../assets/flags/Tonga - TO.png';
import TT_flag from '../../assets/flags/Trinidad and Tobago - TT.png';
import TN_flag from '../../assets/flags/Tunisia - TN.png';
import TR_flag from '../../assets/flags/Turkey - TR.png';
import TM_flag from '../../assets/flags/Turkmenistan - TM.png';
import TV_flag from '../../assets/flags/Tuvalu - TV.png';
import UG_flag from '../../assets/flags/Uganda - UG.png';
import UA_flag from '../../assets/flags/Ukraine - UA.png';
import AE_flag from '../../assets/flags/United Arab Emirates - AE.png';
import GB_flag from '../../assets/flags/United Kingdom of Great Britain and Northern Ireland - GB.png';
import US_flag from '../../assets/flags/United States of America - US.png';
import UY_flag from '../../assets/flags/Uruguay - UY.png';
import UZ_flag from '../../assets/flags/Uzbekistan - UZ.png';
import VU_flag from '../../assets/flags/Vanuatu - VU.png';
import VA_flag from '../../assets/flags/Vatican - VA.png';
import VE_flag from '../../assets/flags/Venezuela  - VE.png';
import VN_flag from '../../assets/flags/Viet Nam - VN.png';
import EH_flag from '../../assets/flags/Western Sahara - EH.png';
import YE_flag from '../../assets/flags/Yemen - YE.png';
import ZM_flag from '../../assets/flags/Zambia - ZM.png';
import ZW_flag from '../../assets/flags/Zimbabwe - ZW.png';
// ---- Location flags -------------

export default class {
	static alergens = [
		{ badge: alergen_gluten, name: 'gluten' },
		{ badge: alergen_milk, name: 'milk' },
		{ badge: alergen_tree_nuts, name: 'treenuts' },
		{ badge: alergen_tree_nuts, name: 'nuts' },
		{ badge: alergen_celery, name: 'celery' },
		{ badge: alergen_egg, name: 'egg' },
		{ badge: alergen_fish, name: 'fish' },
		{ badge: alergen_lupin, name: 'lupin' },
		{ badge: alergen_molluscs, name: 'molluscs' },
		{ badge: alergen_mustard, name: 'mustard' },
		{ badge: alergen_peanut, name: 'peanut' },
		{ badge: alergen_peanut, name: 'peanuts' },
		{ badge: alergen_sesame, name: 'sesame' },
		{ badge: alergen_soy, name: 'soy' },
		{ badge: alergen_soy, name: 'soybeans' },
		{ badge: alergen_sulphur_dioxide, name: 'sulphurdioxide' }
	];
	static flags = [
		{
			flag: AF_flag,
			language: 'AF',
			name: 'Afghanistan'
		},
		{
			flag: AL_flag,
			language: 'AL',
			name: 'Albania'
		},
		{
			flag: DZ_flag,
			language: 'DZ',
			name: 'Algeria'
		},
		{
			flag: AD_flag,
			language: 'AD',
			name: 'Andorra'
		},
		{
			flag: AO_flag,
			language: 'AO',
			name: 'Angola'
		},
		{
			flag: AG_flag,
			language: 'AG',
			name: 'Antigua and Barbuda'
		},
		{
			flag: AR_flag,
			language: 'AR',
			name: 'Argentina'
		},
		{
			flag: AM_flag,
			language: 'AM',
			name: 'Armenia'
		},
		{
			flag: AU_flag,
			language: 'AU',
			name: 'Australia'
		},
		{
			flag: AT_flag,
			language: 'AT',
			name: 'Austria'
		},
		{
			flag: AZ_flag,
			language: 'AZ',
			name: 'Azerbaijan'
		},
		{
			flag: BS_flag,
			language: 'BS',
			name: 'Bahamas'
		},
		{
			flag: BH_flag,
			language: 'BH',
			name: 'Bahrain'
		},
		{
			flag: BD_flag,
			language: 'BD',
			name: 'Bangladesh'
		},
		{
			flag: BB_flag,
			language: 'BB',
			name: 'Barbados'
		},
		{
			flag: EU_flag,
			language: 'EU',
			name: 'Basque'
		},
		{
			flag: BY_flag,
			language: 'BY',
			name: 'Belarus'
		},
		{
			flag: BE_flag,
			language: 'BE',
			name: 'Belgium'
		},
		{
			flag: BZ_flag,
			language: 'BZ',
			name: 'Belize'
		},
		{
			flag: BJ_flag,
			language: 'BJ',
			name: 'Benin'
		},
		{
			flag: BT_flag,
			language: 'BT',
			name: 'Bhutan'
		},
		{
			flag: BO_flag,
			language: 'BO',
			name: 'Bolivia'
		},
		{
			flag: BA_flag,
			language: 'BA',
			name: 'Bosnia and Herzegovina'
		},
		{
			flag: BW_flag,
			language: 'BW',
			name: 'Botswana'
		},
		{
			flag: BR_flag,
			language: 'BR',
			name: 'Brazil'
		},
		{
			flag: BN_flag,
			language: 'BN',
			name: 'Brunei Darussalam'
		},
		{
			flag: BG_flag,
			language: 'BG',
			name: 'Bulgaria'
		},
		{
			flag: BF_flag,
			language: 'BF',
			name: 'Burkina Faso'
		},
		{
			flag: BI_flag,
			language: 'BI',
			name: 'Burundi'
		},
		{
			flag: CV_flag,
			language: 'CV',
			name: 'Cabo Verde'
		},
		{
			flag: KH_flag,
			language: 'KH',
			name: 'Cambodia'
		},
		{
			flag: CM_flag,
			language: 'CM',
			name: 'Cameroon'
		},
		{
			flag: CA_flag,
			language: 'CA ',
			name: 'Catalan'
		},
		{
			flag: CF_flag,
			language: 'CF',
			name: 'Central African Republic'
		},
		{
			flag: TD_flag,
			language: 'TD',
			name: 'Chad'
		},
		{
			flag: CL_flag,
			language: 'CL',
			name: 'Chile'
		},
		{
			flag: CN_flag,
			language: 'CN',
			name: 'China'
		},
		{
			flag: CO_flag,
			language: 'CO',
			name: 'Colombia'
		},
		{
			flag: KM_flag,
			language: 'KM',
			name: 'Comoros'
		},
		{
			flag: CG_flag,
			language: 'CG',
			name: 'Congo'
		},
		{
			flag: CR_flag,
			language: 'CR',
			name: 'Costa Rica'
		},
		{
			flag: HR_flag,
			language: 'HR',
			name: 'Croatia'
		},
		{
			flag: CU_flag,
			language: 'CU',
			name: 'Cuba'
		},
		{
			flag: CY_flag,
			language: 'CY',
			name: 'Cyprus'
		},
		{
			flag: CZ_flag,
			language: 'CZ',
			name: 'Czechia'
		},
		{
			flag: DK_flag,
			language: 'DK',
			name: 'Denmark'
		},
		{
			flag: DJ_flag,
			language: 'DJ',
			name: 'Djibouti'
		},
		{
			flag: DM_flag,
			language: 'DM',
			name: 'Dominica'
		},
		{
			flag: DO_flag,
			language: 'DO',
			name: 'Dominican Republic'
		},
		{
			flag: EC_flag,
			language: 'EC',
			name: 'Ecuador'
		},
		{
			flag: EG_flag,
			language: 'EG',
			name: 'Egypt'
		},
		{
			flag: SV_flag,
			language: 'SV',
			name: 'El Salvador'
		},
		{
			flag: GQ_flag,
			language: 'GQ',
			name: 'Equatorial Guinea'
		},
		{
			flag: ER_flag,
			language: 'ER',
			name: 'Eritrea'
		},
		{
			flag: EE_flag,
			language: 'EE',
			name: 'Estonia'
		},
		{
			flag: SZ_flag,
			language: 'SZ',
			name: 'Eswatini'
		},
		{
			flag: ET_flag,
			language: 'ET',
			name: 'Ethiopia'
		},
		{
			flag: FJ_flag,
			language: 'FJ',
			name: 'Fiji'
		},
		{
			flag: FI_flag,
			language: 'FI',
			name: 'Finland'
		},
		{
			flag: FR_flag,
			language: 'FR',
			name: 'France'
		},
		{
			flag: GA_flag,
			language: 'GA',
			name: 'Gabon'
		},
		{
			flag: GM_flag,
			language: 'GM',
			name: 'Gambia '
		},
		{
			flag: GE_flag,
			language: 'GE',
			name: 'Georgia'
		},
		{
			flag: DE_flag,
			language: 'DE',
			name: 'Germany'
		},
		{
			flag: GH_flag,
			language: 'GH',
			name: 'Ghana'
		},
		{
			flag: GR_flag,
			language: 'GR',
			name: 'Greece'
		},
		{
			flag: GL_flag,
			language: 'GL',
			name: 'Greenland'
		},
		{
			flag: GD_flag,
			language: 'GD',
			name: 'Grenada'
		},
		{
			flag: GT_flag,
			language: 'GT',
			name: 'Guatemala'
		},
		{
			flag: GN_flag,
			language: 'GN',
			name: 'Guinea'
		},
		{
			flag: GW_flag,
			language: 'GW',
			name: 'Guinea-Bissau'
		},
		{
			flag: GY_flag,
			language: 'GY',
			name: 'Guyana'
		},
		{
			flag: HT_flag,
			language: 'HT',
			name: 'Haiti'
		},
		{
			flag: HN_flag,
			language: 'HN',
			name: 'Honduras'
		},
		{
			flag: HU_flag,
			language: 'HU',
			name: 'Hungary'
		},
		{
			flag: IS_flag,
			language: 'IS',
			name: 'Iceland'
		},
		{
			flag: IN_flag,
			language: 'IN',
			name: 'India'
		},
		{
			flag: ID_flag,
			language: 'ID',
			name: 'Indonesia'
		},
		{
			flag: IR_flag,
			language: 'IR',
			name: 'Iran'
		},
		{
			flag: IQ_flag,
			language: 'IQ',
			name: 'Iraq'
		},
		{
			flag: IE_flag,
			language: 'IE',
			name: 'Ireland'
		},
		{
			flag: IL_flag,
			language: 'IL',
			name: 'Israel'
		},
		{
			flag: IT_flag,
			language: 'IT',
			name: 'Italy'
		},
		{
			flag: JM_flag,
			language: 'JM',
			name: 'Jamaica'
		},
		{
			flag: JP_flag,
			language: 'JP',
			name: 'Japan'
		},
		{
			flag: JO_flag,
			language: 'JO',
			name: 'Jordan'
		},
		{
			flag: KZ_flag,
			language: 'KZ',
			name: 'Kazakhstan'
		},
		{
			flag: KE_flag,
			language: 'KE',
			name: 'Kenya'
		},
		{
			flag: KI_flag,
			language: 'KI',
			name: 'Kiribati'
		},
		{
			flag: KW_flag,
			language: 'KW',
			name: 'Kuwait'
		},
		{
			flag: KG_flag,
			language: 'KG',
			name: 'Kyrgyzstan'
		},
		{
			flag: LA_flag,
			language: 'LA',
			name: "Lao People's Democratic Republic"
		},
		{
			flag: LV_flag,
			language: 'LV',
			name: 'Latvia'
		},
		{
			flag: LB_flag,
			language: 'LB',
			name: 'Lebanon'
		},
		{
			flag: LS_flag,
			language: 'LS',
			name: 'Lesotho'
		},
		{
			flag: LR_flag,
			language: 'LR',
			name: 'Liberia'
		},
		{
			flag: LY_flag,
			language: 'LY',
			name: 'Libya'
		},
		{
			flag: LI_flag,
			language: 'LI',
			name: 'Liechtenstein'
		},
		{
			flag: LT_flag,
			language: 'LT',
			name: 'Lithuania'
		},
		{
			flag: LU_flag,
			language: 'LU',
			name: 'Luxembourg'
		},
		{
			flag: MG_flag,
			language: 'MG',
			name: 'Madagascar'
		},
		{
			flag: MW_flag,
			language: 'MW',
			name: 'Malawi'
		},
		{
			flag: MY_flag,
			language: 'MY',
			name: 'Malaysia'
		},
		{
			flag: MV_flag,
			language: 'MV',
			name: 'Maldives'
		},
		{
			flag: ML_flag,
			language: 'ML',
			name: 'Mali'
		},
		{
			flag: MT_flag,
			language: 'MT',
			name: 'Malta'
		},
		{
			flag: MH_flag,
			language: 'MH',
			name: 'Marshall Islands'
		},
		{
			flag: MR_flag,
			language: 'MR',
			name: 'Mauritania'
		},
		{
			flag: MU_flag,
			language: 'MU',
			name: 'Mauritius'
		},
		{
			flag: MX_flag,
			language: 'MX',
			name: 'Mexico'
		},
		{
			flag: FM_flag,
			language: 'FM',
			name: 'Micronesia '
		},
		{
			flag: MD_flag,
			language: 'MD',
			name: 'Moldova'
		},
		{
			flag: MC_flag,
			language: 'MC',
			name: 'Monaco'
		},
		{
			flag: MN_flag,
			language: 'MN',
			name: 'Mongolia'
		},
		{
			flag: ME_flag,
			language: 'ME',
			name: 'Montenegro'
		},
		{
			flag: MA_flag,
			language: 'MA',
			name: 'Morocco'
		},
		{
			flag: MZ_flag,
			language: 'MZ',
			name: 'Mozambique'
		},
		{
			flag: MM_flag,
			language: 'MM',
			name: 'Myanmar'
		},
		{
			flag: NA_flag,
			language: 'NA',
			name: 'Namibia'
		},
		{
			flag: NR_flag,
			language: 'NR',
			name: 'Nauru'
		},
		{
			flag: NP_flag,
			language: 'NP',
			name: 'Nepal'
		},
		{
			flag: NL_flag,
			language: 'NL',
			name: 'Netherlands'
		},
		{
			flag: NZ_flag,
			language: 'NZ',
			name: 'New Zealand'
		},
		{
			flag: NI_flag,
			language: 'NI',
			name: 'Nicaragua'
		},
		{
			flag: NE_flag,
			language: 'NE',
			name: 'Niger '
		},
		{
			flag: NG_flag,
			language: 'NG',
			name: 'Nigeria'
		},
		{
			flag: KR_flag,
			language: 'KR',
			name: 'North Korea '
		},
		{
			flag: NO_flag,
			language: 'NO',
			name: 'Norway'
		},
		{
			flag: OM_flag,
			language: 'OM',
			name: 'Oman'
		},
		{
			flag: PK_flag,
			language: 'PK',
			name: 'Pakistan'
		},
		{
			flag: PW_flag,
			language: 'PW',
			name: 'Palau'
		},
		{
			flag: PA_flag,
			language: 'PA',
			name: 'Panama'
		},
		{
			flag: PG_flag,
			language: 'PG',
			name: 'Papua New Guinea'
		},
		{
			flag: PY_flag,
			language: 'PY',
			name: 'Paraguay'
		},
		{
			flag: PE_flag,
			language: 'PE',
			name: 'Peru'
		},
		{
			flag: PH_flag,
			language: 'PH',
			name: 'Philippines'
		},
		{
			flag: PL_flag,
			language: 'PL',
			name: 'Poland'
		},
		{
			flag: PT_flag,
			language: 'PT',
			name: 'Portugal'
		},
		{
			flag: QA_flag,
			language: 'QA',
			name: 'Qatar'
		},
		{
			flag: MK_flag,
			language: 'MK',
			name: 'Republic of North Macedonia'
		},
		{
			flag: RO_flag,
			language: 'RO',
			name: 'Romania'
		},
		{
			flag: RU_flag,
			language: 'RU',
			name: 'Russian Federation'
		},
		{
			flag: RW_flag,
			language: 'RW',
			name: 'Rwanda'
		},
		{
			flag: KN_flag,
			language: 'KN',
			name: 'Saint Kitts and Nevis'
		},
		{
			flag: LC_flag,
			language: 'LC',
			name: 'Saint Lucia'
		},
		{
			flag: VC_flag,
			language: 'VC',
			name: 'Saint Vincent and the Grenadines'
		},
		{
			flag: WS_flag,
			language: 'WS',
			name: 'Samoa'
		},
		{
			flag: SM_flag,
			language: 'SM',
			name: 'San Marino'
		},
		{
			flag: ST_flag,
			language: 'ST',
			name: 'Sao Tome and Principe'
		},
		{
			flag: SA_flag,
			language: 'SA',
			name: 'Saudi Arabia'
		},
		{
			flag: SN_flag,
			language: 'SN',
			name: 'Senegal'
		},
		{
			flag: RS_flag,
			language: 'RS',
			name: 'Serbia'
		},
		{
			flag: SC_flag,
			language: 'SC',
			name: 'Seychelles'
		},
		{
			flag: SL_flag,
			language: 'SL',
			name: 'Sierra Leone'
		},
		{
			flag: SG_flag,
			language: 'SG',
			name: 'Singapore'
		},
		{
			flag: SK_flag,
			language: 'SK',
			name: 'Slovakia'
		},
		{
			flag: SI_flag,
			language: 'SI',
			name: 'Slovenia'
		},
		{
			flag: SB_flag,
			language: 'SB',
			name: 'Solomon Islands'
		},
		{
			flag: SO_flag,
			language: 'SO',
			name: 'Somalia'
		},
		{
			flag: ZA_flag,
			language: 'ZA',
			name: 'South Africa'
		},
		{
			flag: KP_flag,
			language: 'KP',
			name: 'South Korea'
		},
		{
			flag: ES_flag,
			language: 'ES',
			name: 'Spain'
		},
		{
			flag: LK_flag,
			language: 'LK',
			name: 'Sri Lanka'
		},
		{
			flag: SD_flag,
			language: 'SD',
			name: 'Sudan '
		},
		{
			flag: SR_flag,
			language: 'SR',
			name: 'Suriname'
		},
		{
			flag: SE_flag,
			language: 'SE',
			name: 'Sweden'
		},
		{
			flag: CH_flag,
			language: 'CH',
			name: 'Switzerland'
		},
		{
			flag: SY_flag,
			language: 'SY',
			name: 'Syrian Arab Republic'
		},
		{
			flag: TW_flag,
			language: 'TW',
			name: 'Taiwan '
		},
		{
			flag: TJ_flag,
			language: 'TJ',
			name: 'Tajikistan'
		},
		{
			flag: TZ_flag,
			language: 'TZ',
			name: 'Tanzania'
		},
		{
			flag: TH_flag,
			language: 'TH',
			name: 'Thailand'
		},
		{
			flag: TL_flag,
			language: 'TL',
			name: 'Timor-Leste'
		},
		{
			flag: TG_flag,
			language: 'TG',
			name: 'Togo'
		},
		{
			flag: TO_flag,
			language: 'TO',
			name: 'Tonga'
		},
		{
			flag: TT_flag,
			language: 'TT',
			name: 'Trinidad and Tobago'
		},
		{
			flag: TN_flag,
			language: 'TN',
			name: 'Tunisia'
		},
		{
			flag: TR_flag,
			language: 'TR',
			name: 'Turkey'
		},
		{
			flag: TM_flag,
			language: 'TM',
			name: 'Turkmenistan'
		},
		{
			flag: TV_flag,
			language: 'TV',
			name: 'Tuvalu'
		},
		{
			flag: UG_flag,
			language: 'UG',
			name: 'Uganda'
		},
		{
			flag: UA_flag,
			language: 'UA',
			name: 'Ukraine'
		},
		{
			flag: AE_flag,
			language: 'AE',
			name: 'United Arab Emirates'
		},
		{
			flag: GB_flag,
			language: 'EN',
			name: 'United Kingdom'
		},
		{
			flag: US_flag,
			language: 'US',
			name: 'United States of America'
		},
		{
			flag: UY_flag,
			language: 'UY',
			name: 'Uruguay'
		},
		{
			flag: UZ_flag,
			language: 'UZ',
			name: 'Uzbekistan'
		},
		{
			flag: VU_flag,
			language: 'VU',
			name: 'Vanuatu'
		},
		{
			flag: VA_flag,
			language: 'VA',
			name: 'Vatican'
		},
		{
			flag: VE_flag,
			language: 'VE',
			name: 'Venezuela '
		},
		{
			flag: VN_flag,
			language: 'VN',
			name: 'Viet Nam'
		},
		{
			flag: EH_flag,
			language: 'EH',
			name: 'Western Sahara'
		},
		{
			flag: YE_flag,
			language: 'YE',
			name: 'Yemen'
		},
		{
			flag: ZM_flag,
			language: 'ZM',
			name: 'Zambia'
		},
		{
			flag: ZW_flag,
			language: 'ZW',
			name: 'Zimbabwe'
		}
	];
}
