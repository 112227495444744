//@flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { RedMachineButton } from '../../../../components/Machines/MachineComponents'
import DirectChooseMachineBaseComponent from '../DirectChooseMachine/DirectChooseMachineBaseComponent'
import DirectChooseKeyboard from '../DirectChooseMachine/DirectChooseKeyboard'

type Props = {
  vmID: string,
  history: Object,
  products?: Array<Object>,
}
type States = {}

class Kikko extends React.Component<Props, States> {
  keyboardConfig = [
    [
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <i className="fa fa-long-arrow-down mr-1" />
            17
          </>
        ),
        buttonID: '17',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            <i className="fa fa-long-arrow-up mr-1" />
            18
          </>
        ),
        buttonID: '18',
      },
    ],
    [
      {
        buttonContent: <>1</>,
        buttonID: '1',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            ENTER <br />9
          </>
        ),
        buttonID: '9',
      },
    ],
    [
      {
        buttonContent: <>2</>,
        buttonID: '2',
      },
      {
        ButtonComponent: RedMachineButton,
        buttonContent: (
          <>
            EXIT <br />
            10
          </>
        ),
        buttonID: '10',
      },
    ],
    [
      {
        buttonContent: <>3</>,
        buttonID: '3',
      },
      {
        buttonContent: <>11</>,
        buttonID: '11',
      },
    ],
    [
      {
        buttonContent: <>4</>,
        buttonID: '4',
      },
      {
        buttonContent: <>12</>,
        buttonID: '12',
      },
    ],
    [
      {
        buttonContent: <>5</>,
        buttonID: '5',
      },
      {
        buttonContent: <>13</>,
        buttonID: '13',
      },
    ],
    [
      {
        buttonContent: <>6</>,
        buttonID: '6',
      },
      {
        buttonContent: <>14</>,
        buttonID: '14',
      },
    ],
    [
      {
        buttonContent: <>7</>,
        buttonID: '7',
      },
      {
        buttonContent: <>15</>,
        buttonID: '15',
      },
    ],
    [
      {
        buttonContent: <>8</>,
        buttonID: '8',
      },
      {
        buttonContent: <>16</>,
        buttonID: '16',
      },
    ],
  ]

  render() {
    return (
      <DirectChooseMachineBaseComponent
        machineTitle="Kikko"
        lineNumber={2}
        lineLength={16}
        keyboard={
          <DirectChooseKeyboard
            keyboardConfig={this.keyboardConfig}
            {...this.props}
          />
        }
        {...this.props}
      />
    )
  }
}

export default withRouter(Kikko)
