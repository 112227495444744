import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import StatisticsCard from './StatisticsCard';
import BalanceHandlingCard from './BalanceHandlingCard';
import Spinner from '../../../components/UI/Spinner/Spinner';

class Overview extends Component {
	render() {
		const { data, balance, transactions, machines } = this.props;
		return (
			<>
				{data && balance && transactions && machines ? (
					<Row>
						<Col>
							<StatisticsCard
								data={data}
								balance={balance}
								transactions={transactions}
								machines={machines}
							/>
						</Col>
						<Col>
							<BalanceHandlingCard
								data={data}
								balance={balance}
								transactions={transactions}
								machines={machines}
								removeAmount={this.props.removeAmount}
								addAmount={this.props.addAmount}
								removeRemarkText={this.props.removeRemarkText}
								addRemarkText={this.props.addRemarkText}
								addValidated={this.props.addValidated}
								removeValidated={this.props.removeValidated}
								onSave={this.props.onSave}
								onInputChange={this.props.onInputChange}
							/>
						</Col>
					</Row>
				) : (
					<Spinner />
				)}
			</>
		);
	}
}

export default withTranslation()(Overview);
