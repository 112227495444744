//@flow
import React from 'react';
import { Button, Col, Row, Card, CardTitle } from 'reactstrap';
//Pörgős gomb
import 'ladda/dist/ladda-themeless.min.css';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import Form from 'react-bootstrap/Form';
import VMProductCard from './VMProductCard.js';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import classes from './ProductInfoDialog.module.css';
import LayoutUtil from '../../../../services/util/layoutUtil';
import customerApi from '../../../../services/api/customers';
import machineApi from '../../../../services/api/machines';
import productsApi from '../../../../services/api/products';
import customHardwareTypes from '../../../../services/types/customHardwareTypes';
import realtime from '../../../../services/api/realtime';

type Props = {
	products: Array<Object>,
	loading: boolean,
	t: (id: string) => string
};
type States = {};

class VMProductMatrix extends React.Component<Props, States> {
	state = {
		loading: true,
		activeLayout: null,
		selectedLayout: null,
		layouts: null,
		products: null,
		showLocationSwitchDialog: false,
		activeMachineInProductList: 0
	};
	listeners = [];

	async componentDidMount() {
		this.loadData();
		this.listeners.push(
			await realtime.subscribeSale(this.props.machine.hardwareID, () =>
				this.handleNewSale()
			)
		);
	}

	componentWillUnmount() {
		this.listeners.forEach(listener => listener.unsubscribe());
	}

	handleNewSale() {
		setTimeout(() => {
			this.setState({ loading: true }, () => this.setActiveProducts());
		}, 3000);
	}

	loadData = async () => {
		let layouts = [];
		if (this.props.activeLayoutSet && this.props.activeLayoutSet.length > 0) {
			layouts = this.props.activeLayoutSet;
		}

		// Prepare layout options
		let layoutOptions = [];
		if (layouts.length > 0) {
			layoutOptions = layouts.map(layout => {
				const date = new Date(layout.insertedAt);
				const dateString = date ? date.toLocaleString() : '';
				return {
					label:
						layout.name +
						'\u00A0\u00A0\u00A0\u00A0' +
						this.props.t('device.details.productMatrix.date') +
						' ' +
						dateString,
					value: layout
				};
			});
		}
		layoutOptions.splice(0, 0, { label: '', value: null });

		const activeLayout = this.props.activeLayout;

		const selectedLayout = this.props.activeLayoutSet
			? this.props.activeLayoutSet.find(
					layoutOption => this.props.activeLayout == layoutOption.layoutID
			  )
			: null;
		const currencySymbol = await this.loadCurrecySymbol();
		const flagList = await this.loadLanguages();

		let activeLocation = 'en';
		if (flagList && flagList.length > 0) {
			activeLocation = flagList[0].language;
		}

		const peripherials = await this.loadPeripherials();
		this.setState(
			{
				layouts,
				layoutOptions,
				activeLayout,
				selectedLayout,
				activeLocation,
				currencySymbol,
				flagList,
				peripherials
			},
			() => this.setActiveProducts()
		);
	};

	setActiveProducts = async () => {
		const response = await machineApi.getProducts(
			this.props.machine.vmID,
			this.state.activeLocation
		);
		if (response && response.response) {
			const products = response.response.map((product: any) => {
				product.price = Math.round(parseFloat(product.price) * 100) / 100;
				product.image = machineApi.getProductImage(
					this.props.machine.vmID,
					product.pID
				);

				return product;
			});

			this.setState({ products, loading: false });
		} else {
			this.setState({ loading: false });
		}
	};

	loadPeripherials = async () => {
		const resp = await machineApi.getVMPeripherials(this.props.machine.vmID);

		return resp && resp.data && resp.data.connectedParts
			? resp.data.connectedParts
			: undefined;
	};

	loadCurrecySymbol = async () => {
		return await customerApi.getCustomerCurrencySymbol(this.props.user.cmID);
	};

	loadLanguages = async () => {
		let languages = ['en'];
		let fl = [];
		const response = await machineApi
			.getProductLocations(this.props.machine.vmID)
			.then()
			.catch(e => {
				return;
			});
		if (response && response.langauges) {
			languages = response.langauges.split(',');
		}

		return languages
			.map(languageISO =>
				LayoutUtil.flags.find(
					languageDesc =>
						languageISO.toLocaleUpperCase().trim() ==
						languageDesc.language.toLocaleUpperCase().trim()
				)
			)
			.filter(languageDesc => !!languageDesc);
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleRefresh = () => {
		if (!this.state.selectedLayout) return;

		this.props.onSave(this.state.selectedLayout);
	};

	getLayoutOptions = () => {
		return this.state.layoutOptions.map((e, index) => (
			<option
				key={e.label + index}
				label={e.label}
				value={e.value ? e.value.uniqueID : null}
				selected={
					(this.state.activeLayout &&
						e.value &&
						e.value.layoutID == this.state.activeLayout) ||
					(!this.state.activeLayout && e.value == null)
				}
			>
				{e.label}
			</option>
		));
	};

	onSwitchLocation = id => {
		this.setState({ activeLocation: id, loading: true }, () =>
			this.setActiveProducts()
		);
	};

	renderLocationSwitchPanel = () => {
		return this.state.flagList && this.state.flagList.length > 0
			? this.state.flagList.map(e => {
					const flag = LayoutUtil.flags.find(f => f.language === e.language);
					if (e && e.language) {
						return (
							<Col className={classes.ModalBody}>
								<Button
									className={
										e.language === this.state.activeLocation
											? classes.OnActiveLanguageButton
											: classes.LanguageButton
									}
									onClick={() => this.onSwitchLocation(e.language)}
									id={e.language}
								>
									{flag && flag.flag ? (
										<img
											className={'mr-3 '}
											src={flag.flag}
											width="20"
											height="20"
										/>
									) : null}
									{e.name ? e.name : ''}
								</Button>
							</Col>
						);
					}
			  })
			: null;
	};

	renderMachineSwitchPanel = shelfStructure => {
		return shelfStructure.map((machine, index) => {
			return (
				<Col className={classes.ModalBody}>
					<Button
						className={
							index == this.state.activeMachineInProductList
								? classes.OnActiveLanguageButton
								: classes.LanguageButton
						}
						onClick={() => this.setState({ activeMachineInProductList: index })}
						id={'machineButton' + index}
					>
						{index == 0
							? this.props.t('layoutManager.master')
							: this.props.t('layoutManager.slave')}
						&nbsp;{machine.targetMachine}
					</Button>
				</Col>
			);
		});
	};

	setSelectedLayout = e => {
		const selectedLayout = this.props.activeLayoutSet.find(
			layoutOption => e.target.value == layoutOption.uniqueID
		);
		this.setState({
			selectedLayout: selectedLayout
		});
	};

	getActivelayoutName = value => {
		let name = '';
		if (value) {
			let n = null;
			n = this.props.activeLayoutSet.find(e => e.layoutID === value);
			if (n && n.name) name = n.name;
		}
		return name;
	};

	renderLayoutPanel = () => {
		const { t, editable, machine } = this.props;
		return (
			<Row className={classes.LayoutPanelRow}>
				<Col xs="12" sm="12" md="12" lg="12" xl="12">
					{this.props.editMode ? (
						<Row className={classes.LayoutPanelRow}>
							<LaddaButton
								className="btn btn-success btn-ladda DefaultButton"
								loading={this.props.saveInProgress}
								data-style={EXPAND_LEFT}
								onClick={() => {
									this.handleRefresh();
								}}
								style={{ marginRight: '0.5rem' }}
								disabled={!this.state.selectedLayout}
							>
								{' '}
								<i className="fa fa-floppy-o mr-2" />
								{t('device.details.save')}
							</LaddaButton>
							<LaddaButton
								className="btn btn-primary btn-ladda DefaultButton"
								loading={this.props.saveInProgress}
								data-style={EXPAND_LEFT}
								onClick={() => this.props.onCancel()}
								style={{ marginRight: '0.5rem' }}
							>
								<i className="fa fa-check-circle mr-2" />
								{t('device.details.cancel')}
							</LaddaButton>
						</Row>
					) : (
						<Row className={classes.LayoutPanelRow}>
							<LaddaButton
								className="btn btn-success btn-ladda DefaultButtonLd"
								style={{ marginRight: '5px' }}
								loading={this.props.saveInProgress}
								data-style={EXPAND_LEFT}
								onClick={this.props.onEdit}
							>
								<i className="fa fa-random mr-2" />
								{t('layoutManager.changeLayout')}
							</LaddaButton>
							<LaddaButton
								className="btn btn-success btn-ladda DefaultButtonLd"
								style={{ marginLeft: '5px' }}
								loading={this.props.saveInProgress}
								data-style={EXPAND_LEFT}
								onClick={this.handleTopUpAll}
							>
								<i className="fa fa-arrow-up mr-2" aria-hidden="true"></i>
								{t('layoutManager.allTopUp')}
							</LaddaButton>
						</Row>
					)}
				</Col>
			</Row>
		);
	};

	handleTopUpAll = () => {
		this.setState({ loading: true }, async () => {
			await Promise.all(
				this.state.products
					.filter(
						product =>
							product.maxCount &&
							product.maxCount > 0 &&
							product.currentCount < product.maxCount
					)
					.map(async product => {
						await productsApi.editAmounts(
							this.props.machine.vmID,
							product.pID,
							product.maxCount
						);
					})
			);
			this.loadData();
		});
	};

	// This function creates the storing structure for the product if necccesary.
	addProductToShelfStructure(renderedProductList, product) {
		const targetMachine = product.slavePattern ? product.slavePattern : null;
		let targetMachineObject = renderedProductList.find(
			productList => productList.targetMachine == targetMachine
		);
		let hasSnakyMatrix = false;
		if (!targetMachineObject) {
			let snakkyDimensionsX = 0;
			let snakkyDimensionsY = 0;

			if (this.state.peripherials) {
				const snakyStorage = this.state.peripherials.find(peri => {
					if (
						peri.name != customHardwareTypes.CUSTOM_SNAKY_PRODUCT_STORAGE.name
					)
						return false;
					if (targetMachine) {
						return (
							peri.slavePrefix &&
							peri.slavePrefix.toLocaleUpperCase().trim() ==
								targetMachine.toLocaleUpperCase().trim()
						);
					} else {
						return peri.slavePrefix == null || peri.slavePrefix == '';
					}
				});
				hasSnakyMatrix = !!snakyStorage;
				try {
					const periCustomData = JSON.parse(snakyStorage.customData);
					snakkyDimensionsX = parseInt(periCustomData.snakyColCount);
					snakkyDimensionsY = parseInt(periCustomData.snakyRowCount);
				} catch (error) {}
			}

			const organisedList = [];
			for (let i = 0; i < snakkyDimensionsY; i++) {
				const lineArray = [];
				for (let j = 0; j < snakkyDimensionsX; j++) {
					lineArray.push(null);
				}
				organisedList.push(lineArray);
			}

			targetMachineObject = {
				targetMachine,
				snakkyDimensionsX,
				snakkyDimensionsY,
				productLists: [[], organisedList] // unorganised & organized list (example caffes and snakkys)
			};
			renderedProductList.push(targetMachineObject);
		} else {
			hasSnakyMatrix = targetMachineObject.snakkyDimensionsX > 0;
		}

		let targetArray = targetMachineObject.productLists[0];
		if (hasSnakyMatrix && product.productID > 9) {
			const lineIndex = Math.floor(parseInt(product.productID) / 10) - 1;
			targetArray =
				lineIndex < targetMachineObject.snakkyDimensionsY
					? targetMachineObject.productLists[1][lineIndex] // Add to it's line
					: targetMachineObject.productLists[0]; // Add to the unorganised List

			const targetX = Math.floor(parseInt(product.productID) % 10);
			if (targetX <= targetArray.length) {
				targetArray[targetX] = product;
			} else {
				targetArray.push(product);
			}
		} else {
			targetArray.push(product);
		}
	}

	uniqueKeyCounter = 0;

	getUniqueKey() {
		this.uniqueKeyCounter++;
		return 'key-' + this.uniqueKeyCounter;
	}

	getPositionStringForProduct(product, position, targetMachine) {
		if (product) {
			return `${product.productID} ${
				product.slavePattern ? product.slavePattern : ''
			}`;
		} else {
			return `${position} ${targetMachine ? targetMachine : ''}`;
		}
	}

	renderMatrix = () => {
		const { t } = this.props;

		let matrix = <Spinner />;

		const shelfStructure = [];
		if (this.state.products) {
			this.state.products.forEach(product => {
				this.addProductToShelfStructure(shelfStructure, product);
			});
		}

		let productListing = <Spinner />;
		if (!this.state.loading && this.state.products && this.state.flagList) {
			if (this.state.products.length <= 0) {
				productListing = (
					<div style={{ margin: '30px', padding: '20px' }}>
						<h1>{t('promotionManager.promotionTable.noData')}</h1>
					</div>
				);
			} else {
				productListing = shelfStructure
					.filter(
						(machine, machineIndex) =>
							this.state.activeMachineInProductList == machineIndex
					)
					.map((machine, machineIndex) => (
						<>
							<Row>
								<h1>{machine.name}</h1>
							</Row>
							{machine.productLists.map((productList, pIndex) => (
								<>
									{pIndex == 0 ? (
										<div
											className="justify-content-center row"
											style={{ width: '100%' }}
										>
											{productList.map((prodListItem, i) => (
												<div
													className="justify m-1"
													id="imgSetProm"
													key={this.getUniqueKey()}
												>
													<VMProductCard
														VMItem={prodListItem}
														key={this.getUniqueKey()}
														location={this.state.activeLocation}
														flags={this.state.flagList}
														currencySymbol={this.state.currencySymbol}
														vmID={this.props.machine.vmID}
														positionString={`${prodListItem.productID} ${
															prodListItem.slavePattern
																? prodListItem.slavePattern
																: ''
														}`}
													/>
												</div>
											))}
										</div>
									) : (
										productList.map((prodSubList, pSubi) => (
											<div
												class="justify-content-center row"
												style={{ width: '100%' }}
												key={this.getUniqueKey()}
											>
												{prodSubList.map((product, i) => (
													<div
														className="justify m-1"
														id="imgSetProm"
														key={this.getUniqueKey()}
													>
														<VMProductCard
															VMItem={product}
															key={this.getUniqueKey()}
															location={this.state.activeLocation}
															flags={this.state.flagList}
															currencySymbol={this.state.currencySymbol}
															vmID={this.props.machine.vmID}
															positionString={this.getPositionStringForProduct(
																product,
																10 * (pSubi + 1) + i,
																machine.targetMachine
															)}
														/>
													</div>
												))}
											</div>
										))
									)}
								</>
							))}
						</>
					));
			}
		}

		matrix = (
			<>
				<Row className={classes.LayoutPanelRow}>
					{this.props.editMode ? (
						<>
							<Col xs="12" sm="12" md="12" lg="8" xl="6">
								<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
									<Col xs="12" sm="12" md="12" lg="6" xl="6">
										<h1 style={{ textAlign: 'center' }}>{'Set layout'}</h1>
									</Col>
									<Col xs="12" sm="12" md="6" lg="6" xl="6">
										<Form.Control
											id="selectedLayout"
											as="select"
											name="selectedLayout"
											type="text"
											placeholder={
												this.state.activeLayout ? this.state.activeLayout : ''
											}
											style={{
												borderRadius: '5px',
												border: '1px solid gray'
											}}
											onChange={e => this.setSelectedLayout(e)}
										>
											{this.state.layoutOptions
												? this.getLayoutOptions()
												: null}
										</Form.Control>
									</Col>
								</Row>
							</Col>
							<Col xs="12" sm="12" md="12" lg="12" xl="12">
								{this.renderLayoutPanel()}
							</Col>
						</>
					) : (
						<>
							<Col>
								<Row className="justify-content-center ">
									<h1 className="text-muted ">
										{t('layoutManager.activelayout')}
									</h1>
									<h1 className="ml-3">
										{this.getActivelayoutName(this.props.activeLayout)}
									</h1>
								</Row>
							</Col>
							<Col xs="12" sm="12" md="12" lg="12" xl="12">
								{this.renderLayoutPanel()}
							</Col>
						</>
					)}
				</Row>
				{!this.state.loading ? (
					<>
						<Row className={classes.LanguageRow}>
							<Col sm="12" md="12" lg className={classes.ModalText}>
								{t('layoutManager.languages')}
							</Col>

							{this.renderLocationSwitchPanel()}
						</Row>
						<Row className={classes.LanguageRow}>
							<Col sm="12" md="12" lg className={classes.ModalText}>
								{t('layoutManager.machine')}
							</Col>

							{this.renderMachineSwitchPanel(shelfStructure)}
						</Row>

						<Row
							className="justify-content-center"
							style={{ marginLeft: '0px', marginRight: '0px' }}
						>
							{productListing}
						</Row>
					</>
				) : (
					<Spinner />
				)}
			</>
		);

		return matrix;
	};

	//Render
	render() {
		return this.renderMatrix();
	}
}

function mapStateToProps(state) {
	const { user } = state;
	return { user };
}

export default connect(mapStateToProps)(
	withRouter(withTranslation()(VMProductMatrix))
);
