import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Col, Row, Badge } from 'reactstrap';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import packageApi from '../../../services/api/package';
import Tooltip from 'react-bootstrap/Tooltip';
import classes from './PackagesTable.module.css';
import { saveAs } from 'file-saver';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

const PackagesTable = props => {
	const { t } = props;
	const [idx, setIdx] = useState(null);
	const [saveInProgress, setSaveInProgress] = useState([]);

	useEffect(() => {
		if (props.packageToDel) {
			setIdx(null);
		}
		if (idx) {
			props.onShowLayouts(idx);
			setIdx(null);
		}
	}, [idx, props.packageToDel, saveInProgress]);

	const dateFormater = cell => {
		let date = '';
		if (cell) {
			date = moment(cell).format('YYYY.MM.DD HH:mm:ss');
		}
		return date;
	};

	const userFormatter = (cell, row) => {
		return <i className="icon-folder" id="userIcon" />;
	};

	const showListFormater = (cell, row) => {
		if (row && row.packageID) {
			return (
				<Button
					onClick={() => setIdx(row.packageID)}
					variant="success"
					className={classes.ActivateButton}
				>
					<i className="fa fa-list mr-2" />
					&nbsp;
					{t('groupManager.mainTable.display')}
				</Button>
			);
		}
	};

	const getFileById = async row => {
		if (!row && !row.packageID) return;
		let currentSaveInProgress = [...saveInProgress, row.packageID];
		setSaveInProgress(currentSaveInProgress);
		const res = await packageApi.getPackageFiles(row.packageID);
		if (res && res.data) {
			let contentType = '';
			let fileName = '';
			if (row.filename) {
				fileName = row.filename;
			}
			if (res.headers && res.headers['content-type']) {
				contentType = res.headers['content-type'];
			}
			const attachment = new Blob([res.data], {
				type: contentType
			});
			saveAs(attachment, fileName);
			currentSaveInProgress = currentSaveInProgress.filter(
				id => id != row.packageID
			);
			setSaveInProgress(currentSaveInProgress);
		}
		return;
	};

	const downloadFormater = (cell, row) => {
		if (!row) return;

		return (
			<LaddaButton
				onClick={() => getFileById(row)}
				variant="success"
				className={
					saveInProgress.includes(row.packageID)
						? classes.DownloadButtonLoading
						: classes.DownloadButton
				}
				loading={saveInProgress.includes(row.packageID)}
				data-style={EXPAND_LEFT}
			>
				<i className="fa fa-floppy-o mr-2" /> {t('attachedFiles.download')}
			</LaddaButton>
		);
	};

	//--- prepare for delet packages if it will need ---

	// const deleteFormater = (cell, row) => {
	// 	return (
	// 		<Button
	// 			onClick={() => props.onDel(row.id)}
	// 			className={classes.DeleteButton}
	// 		>
	// 			<i className="fa fa-trash mr-2" />
	// 			{t('promotionManager.promotionTable.edit.delete')}
	// 		</Button>
	// 	);
	// };

	// -------------------------------------------------------

	const detailsButton = (coll, row) => {
		let name = [];
		if (props.machines && row && row.packageID) {
			name = props.machines
				.map(e => {
					if (e.appliedPackageID == row.packageID) {
						return (
							<Row style={{ padding: '10px' }}>
								<Col>{e.customName}</Col>
							</Row>
						);
					} else {
						return null;
					}
				})
				.filter(e => e != null);
			if (name.length > 10) {
				name = name.slice(0, 10);
				name.push('...');
			}
		}

		const overlay = (
			<Tooltip
				style={{
					pointerEvents: 'none',
					borderColor: 'transparent',
					background: 'transparent'
				}}
			>
				<Col style={{ pointerEvents: 'none' }}>
					{name.length < 1
						? props.t('promotionManager.promotionTable.noData')
						: name}
				</Col>
			</Tooltip>
		);

		const button = (
			<Badge
				{...props}
				className={classes.MachineTooltip}
				style={{ backgroundColor: 'transparent ' }}
			>
				<i className="fa fa-info-circle" />
			</Badge>
		);

		return (
			<OverlayTrigger
				placement="right"
				delay={{ show: 250, hide: 400 }}
				overlay={overlay}
			>
				{button}
			</OverlayTrigger>
		);
	};

	//Render Table
	const isColumnsVisibile = columName => {
		return !props.hiddenColumns || props.hiddenColumns.indexOf(columName) < 0;
	};

	//Table options
	const tableOptions = {
		sortIndicator: true,
		hideSizePerPage: false,
		paginationSize: 3,
		sizePerPageList: [10],
		sizePerPage: props.recordPerPage || 10,
		paginationPosition: 'bottom',
		withFirstAndLast: true,
		alwaysShowAllBtns: true,
		pageStartIndex: 1,
		clearSearch: true,
		hidePageListOnlyOnePage: true,
		sortName: 'ID',
		sortOrder: 'asc',
		searchPanel: props.renderFilterBar,
		onSearchChange: props.onFilterChange
	};
	// const { t } = props;

	return (
		<>
			{!props.hideToolbar ? props.renderFilterBar() : null}
			<BootstrapTable
				key="bootstrapTable"
				data={props.data}
				version="4"
				striped
				hover
				pagination={!props.hidePager}
				insertRow={false}
				options={tableOptions}
				multiColumnSearch
				condensed
				bordered={false}
				dataAlign={'center'}
				size="sm"
			>
				{isColumnsVisibile('userIcon') ? (
					<TableHeaderColumn
						isKey
						width="100"
						dataAlign="center"
						dataField="ID"
						dataFormat={userFormatter}
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px' }}
					/>
				) : null}
				{isColumnsVisibile('filename') ? (
					<TableHeaderColumn
						width="200"
						dataField="filename"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{props.t('packageManager.packageTable.packageName')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('packageID') ? (
					<TableHeaderColumn
						width="200"
						dataField="packageID"
						dataAlign="center"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{
							paddingTop: '15px',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{props.t('packageManager.packageTable.identiifer')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('insertedAt') ? (
					<TableHeaderColumn
						width="200"
						dataField="insertedAt"
						dataFormat={dateFormater}
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
					>
						{props.t('packageManager.packageTable.createdAt')}
					</TableHeaderColumn>
				) : null}

				{isColumnsVisibile('packageID') ? (
					<TableHeaderColumn
						width="300"
						dataField="packageID"
						dataFormat={detailsButton}
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
					>
						{props.t('packageManager.layoutTable.vmName')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('layouts') ? (
					<TableHeaderColumn
						width="150"
						dataField="layouts"
						dataAlign="center"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						dataFormat={showListFormater}
					>
						{props.t('packageManager.packageTable.layouts')}
					</TableHeaderColumn>
				) : null}
				{isColumnsVisibile('id') ? (
					<TableHeaderColumn
						width="200"
						dataField="id"
						dataAlign="left"
						dataSort
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						dataFormat={downloadFormater}
					>
						{props.t('attachedFiles.download')}
					</TableHeaderColumn>
				) : null}
				{/* deletion is not a necessary function for the time being, but the interface has been pre-cut for future needs */}
				{/* {isColumnsVisibile('layouts') ? (
					<TableHeaderColumn
						width="50"
						dataField="layouts"
						dataAlign="center"
						thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
						tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
						dataFormat={deleteFormater}
					></TableHeaderColumn>
				) : null} */}
			</BootstrapTable>
		</>
	);
};

export default withTranslation()(PackagesTable);
