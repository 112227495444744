import React, { Component } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
//BootstrapTable
import {
  BootstrapTable,
  DeleteButton,
  TableHeaderColumn,
} from 'react-bootstrap-table'
// import '../DeviceGroups/AddMachineGroupForm/node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'

import { withTranslation } from 'react-i18next'
import OperatorApi from '../../services/api/operators'
import logger from '../../services/logger'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const mapStateToProps = state => ({
  user: state.user,
})

class OperatorList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      update: false,
      users: [],
    }
  }

  //Komponens betölt
  componentDidMount = () => {
    this.updateUsers()
  }

  //Update table data
  updateUsers = async () => {
    var cmID = this.props.user.cmID

    try {
      const response = await OperatorApi.getOwnerOperators(cmID)
      if (response.data.hasOwnProperty('response')) {
        const users = Object.values(response.data.response)
        this.setState({ users })
      } else {
        this.setState({ users: [] })
      }
    } catch (error) {
      logger.error(error)
    }
  }

  //editProfile
  editProfile = (uID, email) => {
    this.props.history.push('customerUsers/editOperator/' + uID + '/' + email)
  }

  //TABLE FORMATTERS

  //Edit ikon klikk
  editFormatter = (cell, row) => {
    return (
      <Button onClick={e => this.editProfile(cell, row.email)} id="editButton">
        <i className="fa fa-chevron-right" />
      </Button>
    )
  }

  //User ikon
  userFormatter = (cell, row) => {
    return <i className="icon-people" id="userIcon" />
  }

  //Add user button action
  handleAddButtonClick = () => {
    this.props.history.push('customerUsers/CreatOperator')
  }

  //Create add user button
  createAddButton = () => {
    const { t } = this.props
    return (
      <DeleteButton
        btnText={t('customerusers.addoperator.button.title')}
        btnContextual="btn-success"
        btnGlyphicon="fa fa-plus"
        onClick={e => this.handleAddButtonClick()}
      />
    )
  }

  //Render
  render() {
    //Table options
    const tableOptions = {
      sortIndicator: true,
      hideSizePerPage: false,
      paginationSize: 3,
      sizePerPageList: [5, 10, 25, 50, 100],
      sizePerPage: 5,
      hidePageListOnlyOnePage: true,
      clearSearch: true,
      alwaysShowAllBtns: false,
      withFirstAndLast: true,
      paginationPosition: 'top',
      insertBtn: this.createAddButton,
    }

    const { t } = this.props

    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="icon-menu" />
              {t('customerusers.table.title')}
              <div className="card-actions" />
            </CardHeader>
            <CardBody>
              <BootstrapTable
                data={this.state.users}
                version="4"
                striped
                hover
                pagination
                search
                insertRow={true}
                options={tableOptions}
                condensed
              >
                <TableHeaderColumn
                  width="30"
                  dataField=""
                  dataAlign="center"
                  dataFormat={this.userFormatter}
                  thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
                  tdStyle={{ paddingTop: '15px' }}
                />
                <TableHeaderColumn
                  dataField="cmID"
                  dataSort
                  thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
                  tdStyle={{
                    paddingTop: '15px',
                    whiteSpace: 'normal',
                    fontWeight: 'bold',
                  }}
                >
                  Customer ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="uID"
                  dataSort
                  thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
                  tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
                >
                  User ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="email"
                  isKey
                  dataSort
                  thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
                  tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
                >
                  {t('customerusers.tableheader.email')}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="privilege"
                  dataSort
                  thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
                  tdStyle={{ paddingTop: '15px', whiteSpace: 'normal' }}
                >
                  {t('customerusers.tableheader.privilege')}
                </TableHeaderColumn>

                <TableHeaderColumn
                  width="50"
                  dataField="uID"
                  dataFormat={this.editFormatter}
                  thStyle={{ backgroundColor: 'rgb(223,228,233)' }}
                />
              </BootstrapTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default connect(mapStateToProps)(
  withRouter(withTranslation()(OperatorList))
)
