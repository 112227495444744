import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withRouter } from 'react-router-dom';
import 'react-dates/initialize';
import classes from './HardwareRiport.module.css';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import VMUtil from '../../services/util/vmUtil';
import UserUtil from '../../services/util/userUtil';
import { Button, Col, Row, Badge } from 'reactstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import TableToolBar from './TableToolBar';
import SummaryPanel from './SummaryPanel';
import HardwareUtil from '../../services/util/hardwareUtil';
import { Form } from 'react-bootstrap';
import ReactExport from '@ibrahimrahmani/react-export-excel';

import { connect } from 'react-redux';
import store from '../../store';
import { setTableData } from '../../store/table';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const filterParams = {
	HARDWARE: 1,
	TYPE: 2,
	MANUFACTURER: 3,
	IDENTFIER: 4,
	DEVICE: 5,
	DOORIDENTIFIER: 6,
	LOCATION: 7
};

const RightArrowButton = props => {
	if (!props) return;
	return (
		<Button
			{...props}
			style={{
				fontSize: 'small',
				fontWeight: 'bold',
				color: 'var(--balance)',
				backgroundColor: 'transparent',
				border: '0px',
				cursor: 'pointer !important',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
			onClick={() => {
				props.history.push('/vms/' + props.encodedvmID + '/detailer?page=4');
			}}
		>
			{props.t('device.details.datasheet.title')}
			<i className="fa fa-chevron-right ml-2 mt-1" />
		</Button>
	);
};

const DetailsButton = props => {
	if (!props || !props.details) return;
	const overlay = (
		<Tooltip
			style={{
				pointerEvents: 'none',
				borderColor: 'transparent',
				background: 'transparent'
			}}
		>
			<Col style={{ pointerEvents: 'none' }}>
				{props.details.map(e => {
					for (const [key, value] of Object.entries(e)) {
						return (
							<Row style={{ padding: '10px' }}>
								<Col>
									{key}
									{':'}
								</Col>
								<Col>{value}</Col>
							</Row>
						);
					}
				})}
			</Col>
		</Tooltip>
	);

	const button = (
		<Badge
			{...props}
			className={classes.DetailsButton}
			style={{ backgroundColor: 'transparent ' }}
		>
			<i className="fa fa-info-circle" />
		</Badge>
	);

	return (
		<OverlayTrigger
			placement="right"
			delay={{ show: 250, hide: 400 }}
			overlay={overlay}
		>
			{button}
		</OverlayTrigger>
	);
};
class HardwareTable extends Component {
	state = {};

	componentDidMount = () => {
		if (this.props.data) {
			store.dispatch(setTableData(JSON.stringify(this.props.data)));
			HardwareUtil.setParamToStore(this.props.data);
			HardwareUtil.getLocalStorage();
		}
	};

	getDietailsSumLabel = param => {
		return <Form.Label id={param}></Form.Label>;
	};

	renderPaginationPanel = props => {
		if (props && props.components && props.components.pageList) {
			return (
				<div className="col">
					<div className="px-3">{props.components.pageList}</div>
				</div>
			);
		}
	};

	dateFormatter = (cell, row) => {
		if (row.fromDate) {
			return moment(row.fromDate).format('YYYY. MM. DD. HH:mm:ss');
		} else {
			return this.props.t('promotionManager.promotionTable.noData');
		}
	};

	createCustomToolBar = props => {
		if (props && props.components && props.components.btnGroup) {
			const getCSVBtn = () => {
				return (
					<Button
						className={classes.ExportExcelButton}
						onClick={() =>
							props.components.btnGroup.props.children[0].props.onClick()
						}
					>
						<i className="fa fa-download mr-2" />
						{this.props.t('salesSnadIncome.exportToCSV')}
					</Button>
				);
			};
			return (
				<TableToolBar
					excelExportButton={() => this.renderExcelExportButton()}
					btnGroup={getCSVBtn()}
				/>
			);
		}
	};
	addressFormater = (cell, row, xsl) => {
		if (!row) return;
		let location =
			(row.country ? row.country : '') +
			' ' +
			(row.city ? row.city : '') +
			' ' +
			(row.address ? row.address : '') +
			' ' +
			(row.zipcode ? row.zipcode : '');

		return location && location.length > 3 ? (
			location
		) : xsl ? (
			this.props.t('promotionManager.promotionTable.noData')
		) : (
			<strong style={{ opacity: '0.2' }}>
				{this.props.t('promotionManager.promotionTable.noData')}
			</strong>
		);
	};

	isMachineDetailsEnabled = row => {
		if (!row) return;
		return row.supportAccessEnabled || !UserUtil.isRoot();
	};

	toDataSheetbutton = (cell, row) => {
		if (!row || !row.vmID) return;
		if (this.isMachineDetailsEnabled(row)) {
			return (
				<RightArrowButton
					{...this.props}
					encodedvmID={VMUtil.encodeVMID(row.vmID)}
				/>
			);
		} else {
			return null;
		}
	};

	formatHardwareName = cell => {
		if (!cell) return;
		return cell.replaceAll(/_/g, ' ');
	};
	formatTypeName = cell => {
		if (cell && cell != '') {
			return cell;
		} else {
			return (
				<strong style={{ opacity: '0.2' }}>
					{this.props.t('promotionManager.promotionTable.noData')}
				</strong>
			);
		}
	};
	formatDetails = cell => {
		if (cell && cell != '[]') {
			return <DetailsButton {...this.props} details={JSON.parse(cell)} />;
		}
	};

	afterColumnFilter = (filterConds, result) => {
		if (result) {
			store.dispatch(setTableData(JSON.stringify(result)));
			HardwareUtil.setParamToStore(result);
			HardwareUtil.getLocalStorage();
		}
	};

	// ---------- XSL Export component --------------
	formatExcel = v => {
		let value = [];
		if (v) {
			value = v;
		}
		const { t } = this.props;
		const header = [
			{
				value: t('hardwareRiport.hardwartable.commissioning'),
				widthPx: 150,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('hardwareRiport.hardwartable.hardware'),
				widthPx: 200,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('hardwareRiport.hardwartable.type'),
				widthPx: 150,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('hardwareRiport.hardwartable.manufacturer'),
				widthPx: 150,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('hardwareRiport.hardwartable.identifier'),
				widthPx: 150,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('hardwareRiport.hardwartable.device'),
				widthPx: 200,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('hardwareRiport.hardwartable.doorId'),
				widthPx: 200,
				style: { font: { sz: '24', bold: true } }
			},
			{
				value: t('hardwareRiport.hardwartable.location'),
				widthPx: 200,
				style: { font: { sz: '24', bold: true } }
			}
		];
		const val = value.map(e => {
			let commissioning = this.dateFormatter(null, e);
			let hardware = e.name ? this.formatHardwareName(e.name) : '';
			let type = e.customType ? e.customType : '';
			let manufacturer = e.customManufacturerName
				? e.customManufacturerName
				: '';
			let identifier = e.customIdentifier ? e.customIdentifier : '';
			let device = e.vmCustomName ? e.vmCustomName : '';
			let doorId = e.vmName ? e.vmName : '';
			let location = this.addressFormater(null, e, true);
			let el = [
				{ value: commissioning },
				{ value: hardware },
				{ value: type },
				{ value: manufacturer },
				{ value: identifier },
				{ value: device },
				{ value: doorId },
				{ value: location }
			];
			return el;
		});
		const styledMultiDataSet = [
			{
				columns: header,
				data: val
			}
		];
		return (
			<div>
				<ExcelFile
					element={
						<Button className={classes.ExportExcelButton}>
							<i className=" fa fa-file mr-2"></i> {'Export to XSL'}
						</Button>
					}
				>
					<ExcelSheet
						dataSet={styledMultiDataSet}
						name={t('salesSnadIncome.title')}
					/>
				</ExcelFile>
			</div>
		);
	};

	renderExcelExportButton = () => {
		let dataSet = [];
		if (JSON.parse(this.props.table)) {
			dataSet = JSON.parse(this.props.table);
		}

		return this.formatExcel(dataSet);
	};

	// ---------- FilterComponent --------------
	renderHeaderFilter = (title, value, param) => {
		let noVisibleElements = null;
		noVisibleElements = this.props.elementVisibilities.find(
			e => e.param === param
		);
		return (
			<Row style={{ alignItmes: 'center', justifyContent: 'center' }}>
				<Badge
					className={
						noVisibleElements
							? classes.ActiveFilterButton
							: classes.FilterButton
					}
					onClick={value}
				>
					<i className="fa fa-search" style={{ margin: '3px' }} />
				</Badge>
				{title}
			</Row>
		);
	};

	renderResetFilterButton = (title, value) => {
		return (
			<Row
				style={{
					alignItmes: 'center',
					justifyContent: 'center'
				}}
			>
				<div className={classes.FilterButton} onClick={value}>
					<span
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItmes: 'center',
							justifyContent: 'center',
							height: '70px',
							width: '60px'
						}}
					>
						{title}
						<i className="fa fa-search" style={{ marginTop: '5px' }} />
					</span>
				</div>
			</Row>
		);
	};

	resetFilter = () => {
		this.props.onResetFilter();
	};

	//Render
	render() {
		const { t } = this.props;
		//Table options
		const tableOptions = {
			sortIndicator: true,
			hideSizePerPage: true,
			sizePerPage: 20,
			withFirstAndLast: true,
			paginationPosition: 'bottom',
			paginationSize: 3,
			defaultSortName: 'fromDate',
			defaultSortOrder: 'desc',
			toolBar: this.createCustomToolBar,
			afterColumnFilter: this.afterColumnFilter
		};
		const textFilterOptions = {
			type: 'TextFilter',
			placeholder: t('filterToolbar.filterPlaceholder'),
			style: {
				marginTop: '20px',
				borderRadius: '5px',
				borderColor: 'var(--control-mid)'
			}
		};

		return (
			<>
				<BootstrapTable
					data={this.props.data}
					version="4"
					pagination
					options={tableOptions}
					bordered={false}
					condensed
					size="sm"
					trClassName={classes.MobilRow}
					tableHeaderClass={classes.TableHeaderClass}
					headerContainerClass={classes.HeaderContainerClass}
					exportCSV
				>
					<TableHeaderColumn
						csvHeader={t('device.details.sales.id')}
						filter={textFilterOptions}
						width="50"
						dataField="id"
						isKey
						dataAlign="center"
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{ verticalAlign: 'middle' }}
						hidden
					>
						{t('device.details.sales.id')}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('hardwareRiport.hardwartable.commissioning')}
						csvFormat={this.dateFormatter}
						filter={textFilterOptions}
						width="140"
						dataAlign="center"
						dataField="fromDate"
						thStyle={{
							borderColor: 'transparent'
						}}
						dataFormat={this.dateFormatter}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{t('hardwareRiport.hardwartable.commissioning')}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('hardwareRiport.hardwartable.hardware')}
						filter={textFilterOptions}
						width="150"
						dataAlign="center"
						dataField="name"
						dataFormat={this.formatHardwareName}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{this.renderHeaderFilter(
							t('hardwareRiport.hardwartable.hardware'),
							this.props.showHardwareFilterDialog,
							filterParams.HARDWARE
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('hardwareRiport.hardwartable.type')}
						filter={textFilterOptions}
						width="110"
						dataAlign="center"
						dataField="customType"
						dataFormat={this.formatTypeName}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{this.renderHeaderFilter(
							t('hardwareRiport.hardwartable.type'),
							this.props.showTypeFilterDialog,
							filterParams.TYPE
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('hardwareRiport.hardwartable.manufacturer')}
						filter={textFilterOptions}
						width="110"
						dataAlign="center"
						dataField="customManufacturerName"
						dataFormat={this.formatTypeName}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{this.renderHeaderFilter(
							t('hardwareRiport.hardwartable.manufacturer'),
							this.props.showManufacturerFilterDialog,
							filterParams.MANUFACTURER
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('hardwareRiport.hardwartable.identifier')}
						filter={textFilterOptions}
						width="140"
						dataAlign="center"
						dataField="customIdentifier"
						dataFormat={this.formatTypeName}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{this.renderHeaderFilter(
							t('hardwareRiport.hardwartable.identifier'),
							this.props.showIdentifierFilterDialog,
							filterParams.IDENTFIER
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('hardwareRiport.hardwartable.details')}
						filter={textFilterOptions}
						width="90"
						dataAlign="center"
						dataField="customData"
						dataFormat={this.formatDetails}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{t('hardwareRiport.hardwartable.details')}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('hardwareRiport.hardwartable.device')}
						filter={textFilterOptions}
						width="110"
						dataAlign="center"
						dataField="vmCustomName"
						dataFormat={this.formatTypeName}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal',
							fontWeight: 'bold'
						}}
					>
						{this.renderHeaderFilter(
							t('hardwareRiport.hardwartable.device'),
							this.props.showDeviceFilterDialog,
							filterParams.DEVICE
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('hardwareRiport.hardwartable.doorId')}
						filter={textFilterOptions}
						width="130"
						dataAlign="center"
						dataField="vmName"
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{this.renderHeaderFilter(
							t('hardwareRiport.hardwartable.doorId'),
							this.props.showDoorIdentifierFilterDialog,
							filterParams.DOORIDENTIFIER
						)}
					</TableHeaderColumn>
					<TableHeaderColumn
						csvHeader={t('hardwareRiport.hardwartable.location')}
						filter={textFilterOptions}
						width="130"
						dataAlign="center"
						dataFormat={this.addressFormater}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'middle',
							whiteSpace: 'normal'
						}}
					>
						{t('hardwareRiport.hardwartable.location')}
					</TableHeaderColumn>
					<TableHeaderColumn
						width="80"
						dataAlign="center"
						dataFormat={this.toDataSheetbutton}
						thStyle={{
							borderColor: 'transparent'
						}}
						tdStyle={{
							verticalAlign: 'right',
							whiteSpace: 'normal'
						}}
					>
						{this.renderResetFilterButton(
							this.props.t('salesSnadIncome.reset'),
							this.resetFilter
						)}
					</TableHeaderColumn>
				</BootstrapTable>
				<SummaryPanel
					transactionSum={this.getDietailsSumLabel(
						HardwareUtil.summaryParameters.TRANSACTION_SUM
					)}
				/>
			</>
		);
	}
}

function mapStateToProps(state) {
	const { table } = state;
	return { table };
}

export default connect(mapStateToProps)(
	withRouter(withTranslation()(HardwareTable))
);
