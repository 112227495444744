import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';

// import HelperTooltip from '../../../components/UI/HelperTooltip/HelperTooltip';
import machineApi from '../../../../services/api/machines';

//Todo: make API conect

class NewSlaveForm extends Component {
	state = {
		validated: false,
		prefix: '',
		vendor: '',
		machineType: ''
	};

	validationHandler = () => {
		const form = document.getElementById('addSlaveForm');
		if (form.checkValidity() === true) {
			this.setNewSlave();
		} else {
			this.setState({ validated: true });
		}
	};

	setNewSlave = () => {
		const param = {
			vmID: this.props.vmID,
			prefix: this.state.prefix,
			vendor: this.state.vendor,
			machineType: this.state.machineType
		};
		machineApi
			.createSlave(param)
			.then(res => this.props.onHide())
			.catch(e => console.log(e));
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		return (
			<Form noValidate validated={this.state.validated} id={'addSlaveForm'}>
				<Form.Group controlId="prefix">
					<Row style={{ paddingLeft: '15px' }}>
						<Form.Label>
							{this.props.t(
								'device.details.datasheet.nfd.nfdData.slave.prefix.title'
							)}
						</Form.Label>
					</Row>
					<Form.Control
						required
						name="prefix"
						type="text"
						placeholder={this.props.t(
							'device.details.datasheet.nfd.nfdData.slave.prefix.placeholder'
						)}
						value={this.state.prefix || ''}
						onChange={this.inputChangeHandler}
					/>
					<Form.Control.Feedback type="invalid">
						{this.props.t('newDeviceForm.validation')}
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group controlId="vendor">
					<Row style={{ paddingLeft: '15px' }}>
						<Form.Label>
							{this.props.t(
								'device.details.datasheet.nfd.nfdData.slave.vendor.title'
							)}
						</Form.Label>
					</Row>
					<Form.Control
						required
						name="vendor"
						type="text"
						placeholder={this.props.t(
							'device.details.datasheet.nfd.nfdData.slave.vendor.placeholder'
						)}
						value={this.state.vendor || ''}
						onChange={this.inputChangeHandler}
					/>
					<Form.Control.Feedback type="invalid">
						{this.props.t('newDeviceForm.validation')}
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group controlId="machineType">
					<Row style={{ paddingLeft: '15px' }}>
						<Form.Label>
							{this.props.t(
								'device.details.datasheet.nfd.nfdData.slave.machineType.title'
							)}
						</Form.Label>
					</Row>
					<Form.Control
						required
						placeholder={this.props.t(
							'device.details.datasheet.nfd.nfdData.slave.machineType.placeholder'
						)}
						name="machineType"
						type="text"
						value={this.state.machineType || ''}
						onChange={this.inputChangeHandler}
					/>

					<Form.Control.Feedback type="invalid">
						{this.props.t('newDeviceForm.validation')}
					</Form.Control.Feedback>
				</Form.Group>

				<hr></hr>
				<Button
					variant="success"
					className="DefaultButton"
					onClick={() => this.validationHandler()}
				>
					<i className="fa fa-floppy-o mr-2" />
					&nbsp;
					{this.props.t('customerlist.addbutton.savebutton')}
				</Button>
			</Form>
		);
	}
}

export default withTranslation()(NewSlaveForm);
