import axios from './api';
import qs from 'qs';

export default {
	getAllUser: async () => {
		return await axios.get().get(`/getAllUser`);
	},

	getNewestUsers: async limit => {
		const param = {
			limit
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/users/newest${query}`);
	},

	getUserById: async userID => {
		const param = {
			userID,
			isID: true
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/profile${query}`);
	},

	checkUserByEmail: async email => {
		const param = {
			email
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/checkUserByEmail${query}`);
	},

	/**
	 * This function loads the customer related statistics from the database.
	 */
	getStatistics: async (statisticDataID, limitNumber) => {
		const param = {
			statisticDataID,
			limitNumber
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/users/statistics${query}`);
	},

	getLanguageByPassKey: async passKey => {
		const param = { passKey };

		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/users/getLanguageByPassKey${query}`);
	},

	getUsersByCmId: async cmId => {
		const param = {
			cmId: cmId
		};
		const query = qs.stringify(param, { addQueryPrefix: true });
		return await axios.get().get(`/getUsersBycmId${query}`);
	},

	updateUser: async userData => {
		return await axios.get().post(`/users`, { user: { ...userData } });
	},

	activateUser: async userData => {
		return await axios.get().post(`/users/activate`, { user: { ...userData } });
	},

	userRegistration: async (payload: {
		email: string,
		privilege: string,
		cmID: string,
		userMenu: string,
		debugMenu: string,
		vmsMenu: string,
		regMenu: string
	}) => {
		const param = {
			...payload,
			isRoot: true
		};
		return await axios.get().post(`/registerUser`, param);
	},
	postUserInfo: async email => {
		const param = {
			email,
			isMeta: true
		};
		return await axios.get().post(`/registerUser`, param);
	},

	inviteUser: inviteData => {
		return axios.get().post('/users/inviteUser', inviteData);
	},

	deleteUser: async uID => {
		return await axios.get().delete('/users', { params: { uID: uID } });
	},

	getAssignedMachines: async uID => {
		const query = qs.stringify({ uID }, { addQueryPrefix: true });
		return await axios.get().get(`/users/assignedMachines${query}`);
	},

	setAssignedMachines: async (uID, vmIDs) => {
		return await axios.get().post('/users/assignedMachines', { uID, vmIDs });
	},

	setNewPassword: async (oldPassword, newPassword) => {
		return await axios
			.get()
			.post('/users/setNewPassword', { oldPassword, newPassword });
	},

	setNewPasswordForUser: async (uID, newPassword) => {
		return await axios
			.get()
			.post('/users/setNewPasswordForUser', { userID: uID, newPassword });
	},

	getDashboardPeriod: async () => {
		return await axios.get().get(`/users/dashboardPeriod`);
	},

	setDashboardPeriod: async (periodStart, periodEnd) => {
		return await axios
			.get()
			.post(`/users/dashboardPeriod`, { periodStart, periodEnd });
	}
};
