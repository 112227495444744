//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import type { Location } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import respUtil from '../../../services/util/responsibilityUtil';
import { Button, Card, CardBody, Col, Row, Badge } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import classes from './DeviceList.module.css';
import DeviceListSearchPanel from './DeviceListSearchPanel';
import EventListCardToolTip from '../../Dashboard/CardHandling/EventListCard/ToolTip/EventListTooltip';
import Stock from '../Stock';
import { withTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import getConnectionStatusLocID from '../../../i18n/connectionStatus';
import UserUtil from '../../../services/util/userUtil';
import VMUtil from '../../../services/util/vmUtil';
import { getColorForState } from '../../../services/util/colors';
import getMachineStatusById from '../../../i18n/machineStatus';
import qs from 'qs';

function stockFormatter(cell, row) {
	return <Stock maxCount={row.maxCount} currentCount={row.currentCount} />;
}

const VMStatusCard = function({ onlineState, ...rest }: any) {
	return (
		<span
			className={`bg-${getColorForState(onlineState)}`}
			style={{
				width: '1em',
				height: '1em',
				borderRadius: '1em',
				float: 'right'
			}}
			{...rest}
		/>
	);
};

const RightArrowButton = function(props: any) {
	return (
		<Button
			{...props}
			style={{
				maxWidth: '40px',
				fontSize: '19px',
				color: '#6ebc3e',
				backgroundColor: 'transparent',
				border: '0px',
				cursor: 'pointer !important'
			}}
			onClick={() => {
				props.history.push('/vms/' + props.encodedvmID + '/detailer');
			}}
		>
			<i className="fa fa-chevron-right" />
		</Button>
	);
};

const AssignedPeopleButton = function(props: any) {
	const userlist = props.assignedUsers
		? props.assignedUsers.split(',').map(user => (
				<>
					{user}
					<br />
				</>
		  ))
		: null;

	const overlay = <Tooltip>{userlist}</Tooltip>;

	const button = (
		<Button
			{...props}
			style={{
				maxWidth: '40px',
				fontSize: '19px',
				color: 'var(--balance-dark)',
				backgroundColor: 'transparent',
				border: '0px',
				marginRight: '10px'
			}}
		>
			<i className="fa fa-users" />
		</Button>
	);
	if (userlist && userlist.length > 0) {
		return (
			<OverlayTrigger
				placement="left"
				delay={{ show: 250, hide: 400 }}
				overlay={overlay}
			>
				{button}
			</OverlayTrigger>
		);
	} else {
		return button;
	}
};

const RemoteControlButton = function(props) {
	const overlay = (
		<Tooltip
			style={{
				pointerEvents: 'none',
				borderColor: 'transparent',
				background: 'transparent'
			}}
		>
			{props.t('devicelist.remote')}
		</Tooltip>
	);
	return (
		<OverlayTrigger
			placement="left"
			delay={{ show: 250, hide: 400 }}
			overlay={overlay}
		>
			<Button
				{...props}
				className={classes.RemoteButton}
				onClick={() => {
					props.history.push('/vms/' + props.encodedvmID + '/detailer?page=5');
				}}
			>
				<i className="fa fa-calculator" style={{ marginRight: '5px' }} />
				&nbsp;
				<i className="fa fa-caret-right" />
			</Button>
		</OverlayTrigger>
	);
};

const StatisticsButton = function(props) {
	const incomeToday =
		props.dailyIncome && props.dailyIncome.incomeToday
			? props.dailyIncome.incomeToday
			: 0;
	const salesCountToday =
		props.dailyIncome && props.dailyIncome.salesCountToday
			? props.dailyIncome.salesCountToday
			: 0;

	const overlay = (
		<Tooltip
			style={{
				pointerEvents: 'none',
				borderColor: 'transparent',
				background: 'transparent'
			}}
		>
			<div style={{ pointerEvents: 'none' }}>
				{props.t('devicelist.table.tooltip.salesToday')}: {salesCountToday}
				<br />
				{props.t('devicelist.table.tooltip.incomeToday')}: {incomeToday}
			</div>
		</Tooltip>
	);

	const button = (
		<Button
			{...props}
			className={classes.RemoteButton}
			onClick={() => {
				props.history.push('/vms/' + props.encodedvmID + '/detailer?page=1');
			}}
		>
			<i className="fa fa-bar-chart" style={{ marginRight: '5px' }} />
			&nbsp;
			<i className="fa fa-caret-right" />
		</Button>
	);

	return (
		<OverlayTrigger
			placement="right"
			delay={{ show: 250, hide: 400 }}
			overlay={overlay}
		>
			{button}
		</OverlayTrigger>
	);
};

type Props = {
	t: TFunction,
	vms: Array<{ idState: string }>,
	history: Object,
	location: Location
};
type States = {
	filteredVms: Array<Object>,
	search: { text: string, status: string[] | null }
};

class DeviceList extends React.Component<Props, States> {
	tdStyle = {
		padding: '5px',
		fontWeight: 400,
		verticalAlign: 'middle',
		outline: 'none'
	};
	thStyle = {
		backgroundColor: 'rgb(223,228,233)'
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			filteredVms: this.props.vms,
			search: {
				text: '',
				status: []
			}
		};
	}
	componentDidMount() {
		const urlParams = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true
		});

		if (urlParams && urlParams.offline) {
			const search = { ...this.state.search };
			search.status = ['offline'];
			this.setState({ search });
		}
		if (urlParams && urlParams.connectionState) {
			const search = { ...this.state.search };
			search.status = ['online'];
			this.setState({ search });
		}
		if (urlParams && urlParams.machinesWithErrors) {
			const search = { ...this.state.search };
			search.status = ['error'];
			this.setState({ search });
		}
		if (urlParams && urlParams.machinestate) {
			const search = { ...this.state.search };
			search.status = [urlParams.machinestate];
			this.setState({ search });
		}
	}

	isMachineDetailsEnabled = row => {
		return row.supportAccessEnabled || !UserUtil.isRoot();
	};

	//Edit gomb akciója
	editAction = (row: Object) => {
		/*var vmID = row.vmID;
		if (this.isMachineDetailsEnabled(row)) {
			this.props.history.push('#/vms/' + VMUtil.encodeVMID(vmID) + '/detailer');
		}*/
	};

	gettdStyle = (cell, row) => {
		let result = { ...this.tdStyle };
		/*if (this.isMachineDetailsEnabled(row)) {
			result.cursor = 'pointer';
		}*/
		return result;
	};

	onSearchChange = (search: Object) => {
		this.setState({ search });
	};

	getFilteredVms = (search: any, vms: Array<Object>): Array<Object> => {
		const { text = '', status = [] } = search;
		if (!text && !status) {
			return vms;
		}

		return vms.filter((vm: Object) => {
			// Check all the values if they match the filter string
			if (
				!Object.keys(vm).find(key =>
					String(vm[key])
						.toLowerCase()
						.includes(text.toLowerCase())
				)
			) {
				return false;
			}

			const onlineStatusFilters = ['online', 'offline'];

			const onlineStatus = status.filter(status =>
				onlineStatusFilters.includes(status)
			);
			const vmStatus = status.filter(
				status => !onlineStatusFilters.includes(status)
			);

			return (
				(onlineStatus.length === 0 || onlineStatus.includes(vm.onlineState)) &&
				(vmStatus.length === 0 ||
					vmStatus.includes(
						VMUtil.getMachineStateStringFromID(vm.vendingMachineState)
					))
			);
		});
	};

	renderSearchPanel = () => {
		return (
			<DeviceListSearchPanel
				refressTable={this.props.reload}
				vms={this.props.vms}
				onSearch={this.onSearchChange}
				status={this.state.search.status}
				onCreateMachine={(hid, mi) => this.props.onCreateMachine(hid, mi)}
			/>
		);
	};

	renderVMName = (vmID: string, row: Object) => {
		const text = (
			<>
				<b
					style={{ cursor: 'pointer' }}
					onClick={() => {
						this.props.history.push(
							'/vms/' + VMUtil.encodeVMID(row.vmID) + '/detailer'
						);
					}}
				>
					{row.customName}
				</b>
			</>
		);

		return <React.Fragment>{text}</React.Fragment>;
	};

	renderVMCustomName = (vmID: string, row: Object) => {
		const text = (
			<>
				<b
					style={{ cursor: 'pointer' }}
					onClick={() => {
						this.props.history.push(
							'/vms/' + VMUtil.encodeVMID(row.vmID) + '/detailer'
						);
					}}
				>
					{row.name}
				</b>
			</>
		);

		return <React.Fragment>{text}</React.Fragment>;
	};

	renderStatusCard = (onlineState: string) => {
		return <VMStatusCard onlineState={onlineState} />;
	};

	renderStatusText = (idState: string, vm: Object) => {
		const { t } = this.props;
		const dateTime =
			vm && vm.statusUpdatedAt ? moment(vm.statusUpdatedAt).fromNow() : '';
		const hasCorrectLastError = vm.lastError && vm.lastError.params.length > 0;
		const tooltip = hasCorrectLastError
			? this.renderMalfunctionTooltip(vm.lastError.params[0], null)
			: null;

		return (
			<React.Fragment>
				<b
					className={`text-${getColorForState(
						VMUtil.getMachineStateStringFromID(vm.vendingMachineState)
					)}`}
				>
					{t(getMachineStatusById(vm.vendingMachineState))}
				</b>
				<br />
				{dateTime} <br />
				<b className={`text-${getColorForState(vm.idState)}`}>
					{t(getConnectionStatusLocID(vm.idState))}
				</b>
				{vm.vendingMachineState == 3 && hasCorrectLastError ? (
					<OverlayTrigger
						placement="right"
						delay={{ show: 250, hide: 400 }}
						overlay={tooltip}
						trigger="click"
					>
						<i class="fa fa-info-circle"></i>
					</OverlayTrigger>
				) : null}
			</React.Fragment>
		);
	};

	renderMalfunctionTooltip(param, props) {
		try {
			const parsedValues = JSON.parse(param.parameterValue);
			return (
				<Tooltip
					id="button-tooltip"
					style={{
						borderRadius: '10px',
						padding: '0px',
						overflow: 'hidden'
					}}
				>
					<EventListCardToolTip screens={parsedValues} />
				</Tooltip>
			);
		} catch (error) {
			return (
				<Tooltip id="button-tooltip" {...props}>
					{param.m}
				</Tooltip>
			);
		}
	}

	//Edit ikon klikk
	editFormatter = (cell: any, row: Object) => {
		// if (this.isMachineDetailsEnabled(row)) {
		return (
			<RightArrowButton
				{...this.props}
				encodedvmID={VMUtil.encodeVMID(row.vmID)}
			/>
		);
		// } else {
		// 	return null;
		// }
	};

	detailButtonsFormatter = (cell: any, row: Object) => {
		if (this.isMachineDetailsEnabled(row)) {
			const toolbar = (
				<Row style={{ justifyContent: 'center', alignItems: 'center' }}>
					{/* <AssignedPeopleButton
						assignedUsers={row.assignedUsers}
						{...this.props}
						encodedvmID={VMUtil.encodeVMID(row.vmID)}
					/> */}
					<RemoteControlButton
						{...this.props}
						encodedvmID={VMUtil.encodeVMID(row.vmID)}
					/>
					<StatisticsButton
						dailyIncome={row.dailyIncome}
						{...this.props}
						encodedvmID={VMUtil.encodeVMID(row.vmID)}
					/>
				</Row>
			);
			return toolbar;
		} else {
			return null;
		}
	};

	dateFormatter = (cell: any) => {
		return cell ? moment(cell.createdAt).fromNow() : '';
	};

	lastStatusFormatter = (cell: any, row: Object) => {
		const { t } = this.props;
		const dateTime = cell ? moment(cell).fromNow() : '';

		return (
			<>
				{dateTime} <br />
				<b className={`text-${getColorForState(row.idState)}`}>
					{t(getConnectionStatusLocID(row.idState))}
				</b>
			</>
		);
	};

	getMachinaType = (cell: any, machine: Object) => {
		if (machine.machine === 'Brio3MiniSnakky') {
			return 'Brio3 - MiniSnakky';
		} else if (machine.machine === 'KikkoSnakky') {
			return 'Kikko - Snakky';
		}
		return machine.machine;
	};

	isColumnsVisibile(columName) {
		return (
			!this.props.hiddenColumns ||
			this.props.hiddenColumns.indexOf(columName) < 0
		);
	}

	renderStatusBadge = (cell, row) => {
		if (!cell || cell === 'N') {
			return <Badge className={classes.StatusBadge}>{'Original'}</Badge>;
		} else {
			return (
				<img
					src={require('../../../assets/images/logo.png')}
					width="40"
					height="40"
				></img>
			);
		}
	};

	renderServiceBadges = (cell, row) => {
		const renderStatus = valu => {
			if (valu) {
				return <span style={{ color: 'green' }}>{'  ON'}</span>;
			} else {
				return <span style={{ color: 'red' }}>{'  OFF'}</span>;
			}
		};
		const renderOverlay = (title, status) => {
			return (
				<Tooltip
					style={{
						pointerEvents: 'none',
						borderColor: 'transparent',
						background: 'transparent'
					}}
				>
					{title} :{status}
				</Tooltip>
			);
		};

		return (
			<Row style={{ justifyContent: 'center', alignItems: 'center' }}>
				<OverlayTrigger
					placement="left"
					delay={{ show: 250, hide: 400 }}
					overlay={renderOverlay(
						this.props.t('device.details.services.telemetry'),
						renderStatus(row.telemetryServiceEnabled === 'Y')
					)}
				>
					<Badge
						className={
							row.telemetryServiceEnabled === 'Y'
								? classes.TelemetryBadge
								: classes.DefaultBadge
						}
					>
						<i className="fa fa-desktop"></i>
					</Badge>
				</OverlayTrigger>
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderOverlay(
						this.props.t('device.details.services.remoteControl'),
						renderStatus(row.remoteControlServiceEnabled === 'Y')
					)}
				>
					<Badge
						className={
							row.remoteControlServiceEnabled === 'Y'
								? classes.RemoteBadge
								: classes.DefaultBadge
						}
					>
						<i className="fa fa-calculator"></i>
					</Badge>
				</OverlayTrigger>
				<OverlayTrigger
					placement="right"
					delay={{ show: 250, hide: 400 }}
					overlay={renderOverlay(
						this.props.t('device.details.services.invisibleTouch'),
						renderStatus(row.invisibleTouchServiceEnabled === 'Y')
					)}
				>
					<Badge
						className={
							row.invisibleTouchServiceEnabled === 'Y' &&
							row.isInvisibleTouchEnabled
								? classes.InvisibleBadge
								: classes.DefaultBadge
						}
					>
						<i className="fa fa-id-badge"></i>
					</Badge>
				</OverlayTrigger>
			</Row>
		);
	};

	renderTable(filteredVms, options, t) {
		return (
			<BootstrapTable
				data={filteredVms.filter(e => e.deletedAt === null)}
				striped
				version="4"
				pagination={!this.props.hidePager}
				insertRow={false}
				multiColumnSearch
				options={options}
				condensed
				bordered={false}
				dataAlign={'center'}
				className={'react-bs-table-gray-border ' + classes.TableMarginRemover}
				size="sm"
			>
				{this.isColumnsVisibile('onlineState') ? (
					<TableHeaderColumn
						width="2em"
						dataField="onlineState"
						dataFormat={this.renderStatusCard}
						thStyle={this.thStyle}
						tdStyle={(cell, row) => this.gettdStyle(cell, row)}
						dataAlign="center"
					/>
				) : null}
				{this.isColumnsVisibile('vmID') ? (
					<TableHeaderColumn
						width="140"
						dataField="nfdUpgraded"
						dataFormat={this.renderStatusBadge}
						thStyle={this.thStyle}
						tdStyle={(cell, row) => this.gettdStyle(cell, row)}
						dataSort
						dataAlign="center"
						hidden={respUtil.breakpoints.md}
					>
						{t('devicelist.tableheader.upgStatus')}
					</TableHeaderColumn>
				) : null}
				{this.isColumnsVisibile('customName') ? (
					<TableHeaderColumn
						// isKey
						width="180"
						dataField="customName"
						dataFormat={this.renderVMName}
						thStyle={this.thStyle}
						tdStyle={(cell, row) => this.gettdStyle(cell, row)}
						dataSort
						dataAlign="center"
					>
						{t('devicelist.tableheader.deviceid')}
					</TableHeaderColumn>
				) : null}
				{this.isColumnsVisibile('vmID') ? (
					<TableHeaderColumn
						isKey
						width="180"
						dataField="vmID"
						dataFormat={this.renderServiceBadges}
						thStyle={this.thStyle}
						tdStyle={(cell, row) => this.gettdStyle(cell, row)}
						dataSort
						dataAlign="center"
						hidden={respUtil.breakpoints.md}
					>
						{t('devicelist.tableheader.NFDServiceStatus')}
					</TableHeaderColumn>
				) : null}
				{this.isColumnsVisibile('machinePartnerIdentifier') ? (
					<TableHeaderColumn
						width="180"
						dataField="name"
						dataFormat={this.renderVMCustomName}
						thStyle={this.thStyle}
						tdStyle={(cell, row) => this.gettdStyle(cell, row)}
						dataSort
						dataAlign="center"
						hidden={respUtil.breakpoints.sm}
					>
						{t('devicelist.tableheader.devicePartnerid')}
					</TableHeaderColumn>
				) : null}

				{this.isColumnsVisibile('machine') ? (
					<TableHeaderColumn
						width="140"
						dataField="machine"
						thStyle={this.thStyle}
						dataFormat={this.getMachinaType}
						dataSort
						tdStyle={(cell, row) => {
							return {
								...this.gettdStyle(cell, row),
								fontWeight: 'bold'
							};
						}}
						dataAlign="center"
						hidden={respUtil.breakpoints.md}
					>
						{t('devicelist.tableheader.machine')}
					</TableHeaderColumn>
				) : null}

				{this.isColumnsVisibile('idState') ? (
					<TableHeaderColumn
						width="120"
						dataField="idState"
						thStyle={this.thStyle}
						tdStyle={(cell, row) => this.gettdStyle(cell, row)}
						dataFormat={this.renderStatusText}
						dataSort
						dataAlign="center"
					>
						{t('devicelist.tableheader.state')}
					</TableHeaderColumn>
				) : null}

				{this.isColumnsVisibile('lastSale') ? (
					<TableHeaderColumn
						dataField="lastSale"
						thStyle={this.thStyle}
						tdStyle={(cell, row) => this.gettdStyle(cell, row)}
						dataFormat={this.dateFormatter}
						dataSort
						width="130"
						dataAlign="center"
						hidden={respUtil.breakpoints.md}
					>
						{t('devicelist.tableheader.lastSale')}
					</TableHeaderColumn>
				) : null}

				{this.isColumnsVisibile('detailButtons') ? (
					<TableHeaderColumn
						width="180"
						dataField="machine"
						thStyle={this.thStyle}
						tdStyle={(cell, row) => this.gettdStyle(cell, row)}
						dataFormat={this.detailButtonsFormatter}
						dataAlign="center"
						hidden={respUtil.breakpoints.md}
					>
						{t('devicelist.tableheader.quick')}
					</TableHeaderColumn>
				) : null}

				{this.isColumnsVisibile('machineAction') ? (
					<TableHeaderColumn
						width="80"
						dataField="machine"
						thStyle={this.thStyle}
						tdStyle={(cell, row) => this.gettdStyle(cell, row)}
						dataFormat={this.editFormatter}
						dataAlign="center"
						hidden={respUtil.breakpoints.md}
					>
						{t('devicelist.tableheader.details')}
					</TableHeaderColumn>
				) : null}
			</BootstrapTable>
		);
	}

	render() {
		const { search } = this.state;
		const { vms, t } = this.props;
		const filteredVms = this.getFilteredVms(search, vms);
		const options = {
			sortIndicator: true,
			sizePerPageList: [10],
			paginationSize: 3,
			hidePageListOnlyOnePage: true,
			clearSearch: true,
			withFirstAndLast: true,
			alwaysShowAllBtns: true,
			pageStartIndex: 1,
			searchPanel: this.renderSearchPanel,
			onSearchChange: this.onSearchChange,
			getRowStyle: this.getRowStyle
		};

		if (this.props.renderDocked) {
			return (
				<>
					{!this.props.hideFilter ? this.renderSearchPanel() : null}
					{this.renderTable(filteredVms, options, t)}
				</>
			);
		} else {
			return (
				<>
					<Row>
						<Col>
							<Card>
								<CardBody>
									<h3>{t('devicelist.table.header')}</h3>
									{!this.props.hideFilter ? this.renderSearchPanel() : null}
									{this.renderTable(filteredVms, options, t)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</>
			);
		}
	}
}

const mapStateToProps = state => ({
	user: state.user
});

export default connect(mapStateToProps)(
	withRouter(withTranslation()(DeviceList))
);
