import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CashFlow from '../../views/CashFlow/CashFlow';

class NewIncomeRoute extends Component {
	render() {
		return (
			<div>
				<Switch>
					<Route path="/cashFlow" name="CashFlow" component={CashFlow} />
					<Redirect from="/" to="/cashFlow" />
				</Switch>
			</div>
		);
	}
}

export default NewIncomeRoute;
