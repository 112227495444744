//@flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
	Card,
	CardBody,
	CardSubtitle,
	CardText,
	CardTitle,
	Col,
	Row
} from 'reactstrap';
// import { Line, Chart } from 'react-chartjs-2';
// import statisticsAPI from '../../../services/api/statistics';
import customersAPI from '../../../services/api/customers';
import userAPI from '../../../services/api/users';
// import userUtil from '../../../services/util/userUtil';
// import { SystemStaticsDataType } from './SystemStaticsDataType';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import Button from 'react-bootstrap/Button';
// import { getLocaleDateString } from '../../../services/util/dateUtil';

import classes from './DashboardCardSalesData.module.css';

class DashboardCardWithStatistics extends React.Component {
	state = {
		labelValue: '',
		periodLabel: ''
	};

	interval = null;
	componentDidMount() {
		if (this.props.visibleDayCount) {
			this.visibleDayCount = this.props.visibleDayCount;
		}
		if (this.props.dataTimePeriod) {
			this.dataTimePeriod = this.props.dataTimePeriod;
		} else {
			this.dataTimePeriod = 1;
		}

		this.refreshData();

		const defaultInterval = 24 * 60 * 60 * 1000;
		this.interval = setInterval(
			() => this.refreshData(),
			this.props.refreshInterval || defaultInterval
		);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	async refreshData() {
		if (this.state.showEditPeriodDialog) return;

		const endOfDay = new Date();
		endOfDay.setHours(23, 59, 59, 0);
		let periodStart = this.props.periodStart;
		let periodEnd = this.props.periodEnd || endOfDay;

		if (this.props.configurable) {
			const resp = await userAPI.getDashboardPeriod();

			periodStart = resp.data.periodStart;
			periodEnd = resp.data.periodEnd;
			this.setState({
				dialogPeriodStart: new Date(periodStart),
				dialogPeriodEnd: new Date(periodEnd)
			});
		}

		customersAPI
			.getIncomeStatistics(periodStart, periodEnd || endOfDay)
			.then(resp => {
				this.setState({
					labelValueTop: Math.round(resp.data.priceSum * 100) / 100,
					labelValueBottom: Math.round(resp.data.salesCount * 100) / 100
				});

				const periodLabel =
					new Date(resp.data.periodStart).toLocaleDateString(undefined, {
						month: 'long',
						day: 'numeric'
					}) +
					' - ' +
					new Date(resp.data.periodEnd).toLocaleDateString(undefined, {
						month: 'long',
						day: 'numeric'
					});
				this.setState({ periodLabel });
			});
	}

	showEditPeriodDialog() {
		this.setState({ showEditPeriodDialog: true });
	}

	hideEditPeriodDialog() {
		this.setState({ showEditPeriodDialog: false });
	}

	async savePeriod(periodStart, periodEnd) {
		await userAPI.setDashboardPeriod(periodStart, periodEnd);
		this.hideEditPeriodDialog();
		this.refreshData();
	}

	renderPeriodSettingsModal() {
		//	const dateFormat = getLocaleDateString();
		return (
			<Modal
				show={this.state.showEditPeriodDialog}
				onHide={() => this.hideEditPeriodDialog()}
				size="sm"
			>
				{' '}
				<Modal.Header>
					<Modal.Title>
						{this.props.t('customerDashboard.periodSettings.title')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						{this.props.t('customerDashboard.periodSettings.periodStart')}

						<DatePicker
							className={classes.DatePicker}
							selected={this.state.dialogPeriodStart}
							timeIntervals={15}
							dateFormat={'yyyy MMMM d'}
							onChange={date => this.setState({ dialogPeriodStart: date })}
						/>
					</div>
					<div>
						{this.props.t('customerDashboard.periodSettings.periodEnd')}
						<DatePicker
							className={classes.DatePicker}
							selected={this.state.dialogPeriodEnd}
							timeIntervals={15}
							dateFormat="yyyy MMMM d"
							onChange={date => this.setState({ dialogPeriodEnd: date })}
						/>
					</div>
					<div className={classes.ToolbarDiv}>
						<Button
							variant="primary"
							onClick={() =>
								this.savePeriod(
									this.state.dialogPeriodStart,
									this.state.dialogPeriodEnd
								)
							}
						>
							{this.props.t('userManagement.userGeneral.save')}
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		);
	}

	render() {
		const {
			subTitleTop = '',
			subTitleBottom = '',
			iconClass = 'fa fa-user',
			titleOnClick
		} = this.props;

		let title = this.props.title;
		if (this.props.configurable) {
			title = this.state.periodLabel;
		}

		const editPeriodDialog = this.props.configurable
			? this.renderPeriodSettingsModal()
			: null;

		return (
			<Card className={this.props.className} style={this.props.style}>
				{editPeriodDialog}
				<CardBody className={classes.BodyStyle}>
					<Row>
						<Col className={classes.Header}>
							{title}
							{this.props.configurable ? (
								<i
									className={`fa fa-cog fa-lg text-light `}
									style={{ float: 'right' }}
									onClick={() => this.showEditPeriodDialog()}
								/>
							) : null}
						</Col>
					</Row>
					<Row>
						<Col>
							<CardTitle
								onClick={titleOnClick}
								className={'text-muted text-light ' + classes.IconAlign}
								style={{ cursor: titleOnClick ? 'pointer' : 'default' }}
							>
								<i
									className={`${iconClass} fa-lg text-light ${classes.IconAlign}`}
									style={{ marginRight: '10px' }}
								/>
								<div className={classes.TextDiv}>
									<div>
										<strong className="text-light">{subTitleTop}</strong>
									</div>
									<div>
										<strong className="text-light">{subTitleBottom}</strong>
									</div>
								</div>
								<div className={classes.TextDivAmount}>
									<div>
										<strong className="text-light" style={{ float: 'right' }}>
											{' ' +
												`  ${this.state.labelValueTop} ${this.props.currency}`}
										</strong>
									</div>
									<div>
										<strong className="text-light" style={{ float: 'right' }}>
											{' '}
											{this.state.labelValueBottom}&nbsp;
											{this.props.t('units.pieces')}
										</strong>
									</div>
								</div>
							</CardTitle>
						</Col>
					</Row>
					<CardSubtitle
						onClick={titleOnClick}
						style={{
							fontSize: '1.75rem',
							paddingLeft: '40px'
						}}
					></CardSubtitle>
					<CardText
						onClick={titleOnClick}
						style={{ margin: '-20px', marginTop: '10px' }}
						className="text-muted text-bold text-light"
					></CardText>
				</CardBody>
			</Card>
		);
	}
}

export default withTranslation()(DashboardCardWithStatistics);
