import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import statistics from '../../services/api/statistics';
import { getDate } from '../../services/util/dateUtil';
import SalesAndIncomeTable from './SalesAndIncomeTable/SalesAndIncomeTable';
import HelperTooltip from '../../components/UI/HelperTooltip/HelperTooltip';
import SalesAndIncomeUtil from '../../services/util/salesAndIncomeUtils';
import Spinner from '../UI/Spinner/Spinner';
import machineAPI from '../../services/api/machines';

import Modal from 'react-bootstrap/Modal';
import Toggle from 'react-bootstrap-toggle';
import Button from 'react-bootstrap/Button';
import classes from './SalesAndIncomeTable/SalesAndIncomeTable.module.css';
import realtime from '../../services/api/realtime';
import { toast, ToastContainer } from 'react-toastify';
import { now } from 'lodash';

const filterParams = {
	DEVICE: 1,
	POSITION: 2,
	PRODUCT: 3,
	TRANSACTION: 4,
	PAYMENT: 5,
	PRICE: 6,
	INCOME: 7,
	EMAIL: 8,
	SELECTIONMETHOD: 9
};

class SalesAndIncomeCard extends Component {
	state = {
		data: [],
		elementVisibilities: [],
		filteredData: [],
		keepUpdated: true
	};
	listeners = [];

	handleNewSale = () => {
		setTimeout(() => {
			if (this.state.keepUpdated) {
				this.setState(
					{
						endDate: new Date(),
						filteredData: undefined,
						data: []
					},
					() => {
						this.loadData();
					}
				);
			}
		}, 3000);
	};

	componentDidMount() {
		this.loadMachines().then(() => {
			if (this.state.vmId) {
				this.state.vmId.forEach(vmID => {
					machineAPI.getHardwareIDSByMachineID(vmID).then(hardwareID => {
						if (hardwareID && hardwareID.length > 0) {
							this.listeners.push(
								realtime.subscribeSale(hardwareID[0], this.handleNewSale)
							);
						}
					});
				});
			}
		});
	}

	componentWillUnmount = () => {
		this.listeners.forEach(listener => listener.unsubscribe());
	};

	loadMachines = async () => {
		let vmId = [];
		if (!this.props.vmId) {
			vmId = await machineAPI.getVisibleVendingMachines().then(res =>
				res.data.response
					.filter(e => e.telemetryServiceEnabled === 'Y')
					.map(e => {
						return e.vmID;
					})
			);
		} else {
			vmId.push(this.props.vmId);
		}
		let startDate = getDate(-this.props.day);
		let endDate = getDate(0);
		this.setState({ startDate: startDate, endDate: endDate, vmId: vmId }, () =>
			this.loadData()
		);
	};

	loadData = () => {
		let param = {
			fromDate: this.state.startDate,
			toDate: this.state.endDate,
			machinesIDString: this.state.vmId.join()
		};

		statistics.getSalesandIncome(param).then(res =>
			this.setState({ data: res.data }, () => {
				this.provideFiltredDataset();
			})
		);
	};

	inputChangeHandler = event => {
		this.setState({ [event.target.name]: event.target.value });
	};
	renderLoadButton = () => {
		return (
			<Button
				className={classes.DefaultFilterButton}
				onClick={() => this.loadData()}
			>
				<i className="fa fa-check mr-2"></i>&nbsp;
				{this.props.t('device.details.sales.timeRange')}
			</Button>
		);
	};

	// ------------ FilterDialog------------

	onShowDialog(value1) {
		this.setState(value1);
	}

	onHideDialog(value2) {
		this.setState(value2);
	}

	paymentMethodFormater = e => {
		let result = '';
		switch (e) {
			case 0:
				result = this.props.t('salesSnadIncome.table.paymentMethods.lcd');
				break;
			case 1:
				result = this.props.t('salesSnadIncome.table.paymentMethods.exe');
				break;
			case 2:
				result = this.props.t('salesSnadIncome.table.paymentMethods.cash');
				break;
			case 3:
				result = this.props.t('salesSnadIncome.table.paymentMethods.cd1');
				break;
			case 4:
				result = this.props.t('salesSnadIncome.table.paymentMethods.cd2');
				break;
			case 5:
				result = this.props.t('salesSnadIncome.table.paymentMethods.wallet');
				break;
			case 6:
				result = this.props.t('salesSnadIncome.table.paymentMethods.cd');
				break;
			default:
				result = e;
		}
		return result;
	};

	selectionModeFormatter = e => {
		let data = '';
		switch (e) {
			case 0:
				data = this.props.t('salesSnadIncome.table.selectionModes.machine');
				break;
			case 1:
				data = this.props.t('salesSnadIncome.table.selectionModes.app');
				break;
		}

		return data;
	};

	transactionTypeFormater = cell => {
		let data = '';
		switch (cell) {
			case SalesAndIncomeUtil.paymentTransactionTypes.PAYMENT:
				data = this.props.t('salesSnadIncome.table.devicePurchase');
				break;
			case SalesAndIncomeUtil.paymentTransactionTypes
				.PAYMENT_INTO_INVISIBLE_TOUCH_BALANCE:
				data = this.props.t('salesSnadIncome.table.topUp');
				break;
		}

		return data;
	};

	transactionTypeFilterValue = e => {
		return this.transactionTypeFormater(e.transactionType);
	};

	paymentMethodFilterValue = e => {
		return this.paymentMethodFormater(e.paymentMethod);
	};

	selectionMethodFilterValue = e => {
		return this.selectionModeFormatter(e.selectionMethod);
	};

	getSetOptions = param => {
		const distinct = (value, index, self) => {
			return self.indexOf(value) === index;
		};
		let product = [];
		switch (param) {
			case filterParams.DEVICE:
				product = this.state.data
					.map(e => {
						return e.vmName;
					})
					.filter(e => e !== null)
					.filter(e => e !== '')
					.filter(distinct);
				return [product, param];
			case filterParams.PRODUCT:
				product = this.state.data
					.map(e => {
						return e.productName;
					})
					.filter(e => e !== null)
					.filter(distinct);
				return [product, param];
			case filterParams.POSITION:
				product = this.state.data
					.map(e => {
						return e.buttonID;
					})
					.filter(e => e !== null)
					.filter(distinct);
				return [product, param];
			case filterParams.TRANSACTION:
				product = this.state.data
					.map(e => {
						return this.transactionTypeFilterValue(e);
					})
					.filter(e => e !== null)
					.filter(distinct);
				return [product, param];
			case filterParams.PAYMENT:
				product = this.state.data
					.map(e => {
						return this.paymentMethodFilterValue(e);
					})
					.filter(e => e !== null)
					.filter(distinct);

				return [product, param];
			case filterParams.EMAIL:
				product = this.state.data
					.map(e => e.customerEmail)
					.filter(e => e !== null)
					.filter(distinct);

				return [product, param];
			case filterParams.SELECTIONMETHOD:
				product = this.state.data
					.map(e => {
						return this.selectionMethodFilterValue(e);
					})
					.filter(e => e !== null)
					.filter(distinct);
				return [product, param];
			default:
				return [];
		}
	};

	onToggleElementVisibility(element, param) {
		let elementVisibilities = [...this.state.elementVisibilities];
		let item = elementVisibilities.find(e => e.element === element);
		if (!item) {
			item = { element, visible: false, param };
			elementVisibilities.push(item);
		} else {
			item.visible = !item.visible;
		}

		this.setState({ elementVisibilities: elementVisibilities }, () => {
			this.provideFiltredDataset();
		});
	}

	isElementTypeVisible(element) {
		let result = true;
		let item = this.state.elementVisibilities.find(e => e.element === element);
		if (item) {
			result = item.visible;
		}
		return result;
	}

	onToggleAllVisibilities(set, param) {
		let newVisibilitySettings = [];
		if (
			this.state.elementVisibilities.filter(item => !item.visible).length === 0
		) {
			newVisibilitySettings = set[0].map(e => {
				return { element: e, visible: false, param };
			});
		}
		if (
			this.state.elementVisibilities.filter(item => !item.visible).length !== 0
		) {
			newVisibilitySettings = this.state.elementVisibilities.filter(
				e => e.param !== param
			);
		}
		this.setState({ elementVisibilities: newVisibilitySettings }, () => {
			this.provideFiltredDataset();
		});
	}

	provideFiltredDataset() {
		if (!!this.state.data && this.state.data.length > 0) {
			let filteredData = this.state.data
				.filter(e => this.isElementTypeVisible(e.vmName))
				.filter(e => this.isElementTypeVisible(e.productName))
				.filter(e => this.isElementTypeVisible(e.buttonID))
				.filter(e =>
					this.isElementTypeVisible(this.transactionTypeFilterValue(e))
				)
				.filter(e =>
					this.isElementTypeVisible(this.paymentMethodFilterValue(e))
				)
				.filter(e => this.isElementTypeVisible(e.customerEmail))
				.filter(e =>
					this.isElementTypeVisible(this.selectionMethodFilterValue(e))
				)
				.map((e, index) => {
					return { ...e, index };
				});
			if (this.props.vmId) {
				filteredData = filteredData.filter(e => e.vmID != null);
			}
			this.setState({ filteredData: filteredData });
		} else {
			this.setState({ filteredData: [] });
		}
	}

	renderDialog(set, value2, stateValue) {
		const param = set[1];
		const elementList = set[0].map((element, index) => {
			return (
				<div key={index}>
					<div>
						<strong style={{ marginLeft: '30px' }}>{element}</strong>
						<Toggle
							onClick={() => this.onToggleElementVisibility(element, param)}
							on="show"
							off="hide"
							active={this.isElementTypeVisible(element)}
							onstyle="info"
							offstyle="warning"
							size="sm"
							style={{
								width: '75px',
								height: '30px',
								borderRadius: '25px',
								float: 'right'
							}}
						/>
					</div>
					<hr />
				</div>
			);
		});

		return (
			<Modal
				show={stateValue}
				onHide={() => this.onHideDialog(value2)}
				scrollable="true"
			>
				<Modal.Header>
					<Modal.Title>
						{this.props.t('salesSnadIncome.table.visible')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body max-height="260px">{elementList}</Modal.Body>
				<Modal.Footer>
					<Button
						variant={
							this.state.elementVisibilities.filter(item => !item.visible)
								.length === 0
								? 'warning'
								: 'info'
						}
						className="DefaultButtonMd"
						onClick={() => this.onToggleAllVisibilities(set, param)}
					>
						<i className="fa fa-eye mr-2"></i>&nbsp;
						{this.state.elementVisibilities.filter(item => !item.visible)
							.length === 0
							? this.props.t('eventList.hideAll')
							: this.props.t('eventList.showAll')}
					</Button>

					<Button
						variant="success"
						className="DefaultButton"
						onClick={() => this.onHideDialog(value2)}
					>
						<i className="fa fa-times-circle mr-2"></i>&nbsp;
						{this.props.t('eventList.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	resetFilter = () => {
		let newVisibilitySettings = [];

		this.setState({ elementVisibilities: newVisibilitySettings }, () => {
			this.provideFiltredDataset();
		});
	};

	// ------------ FilterDialog------------

	renderTable = () => {
		return !this.state.filteredData ? (
			<Spinner />
		) : (
			<SalesAndIncomeTable
				data={this.state.filteredData}
				priceSum={this.state.priceSum}
				incomeSum={this.state.incomeSum}
				transactionSum={this.state.transactionSum}
				startDate={this.state.startDate}
				endDate={this.state.endDate}
				onFilterChange={this.afterColumnFilter}
				elementVisibilities={this.state.elementVisibilities}
				onDateChange={e => {
					this.setState({ keepUpdated: false });
					this.inputChangeHandler(e);
				}}
				onResetFilter={this.resetFilter}
				showDeviceFilterDialog={() => this.onShowDialog({ deviceDialog: true })}
				showEmailFilterDialog={() => this.onShowDialog({ emailDialog: true })}
				showPositionFilterDialog={() =>
					this.onShowDialog({ positionDialog: true })
				}
				showProductFilterDialog={() =>
					this.onShowDialog({ productDialog: true })
				}
				showTransactionTypeFilterDialog={() =>
					this.onShowDialog({ transactionTypeDialog: true })
				}
				showPaymentMethodFilterDialog={() =>
					this.onShowDialog({ paymentMethodDialog: true })
				}
				showSelectionMethodFilterDialog={() =>
					this.onShowDialog({ selectionMethodDialog: true })
				}
				loadButton={this.renderLoadButton()}
			/>
		);
	};

	renderTitle() {
		return (
			<Row style={{ alignItems: 'flex-start' }}>
				<CardTitle
					className="text-muted"
					style={{ cursor: 'pointer', marginLeft: '20px' }}
				>
					<i className={'fa fa-bullhorn'} style={{ marginRight: '10px' }} />
					{this.props.t('salesSnadIncome.title')}
				</CardTitle>
				<HelperTooltip
					height={'100px'}
					width={'600px'}
					direction={'right'}
					id={'SalesAndIncomeHelperTooltip'}
					title={this.props.t('salesSnadIncome.tooltip.title')}
					content={this.props.t('salesSnadIncome.tooltip.content')}
					style={{ marginRight: '30px' }}
				></HelperTooltip>
				<div style={{ display: 'inline-block', marginLeft: '3rem' }}>
					<Button
						className={classes.DefaultFilterButton}
						style={{
							width: '25px',
							position: 'relative',
							backgroundColor: this.state.keepUpdated ? undefined : '#dfe4e9',
							display: 'inline-flex'
						}}
						onClick={() =>
							this.setState(
								{
									keepUpdated: !this.state.keepUpdated,
									endDate: new Date(),
									filteredData: undefined,
									data: []
								},
								() => this.loadData()
							)
						}
					>
						<i className="fa fa-refresh"></i>
					</Button>
					<span
						className="text-muted card-title"
						style={{
							paddingTop: '4px',
							paddingLeft: '4px'
						}}
					>
						{this.props.t('salesSnadIncome.automaticallyUpdate')}&nbsp;
						{this.state.keepUpdated
							? this.props.t('salesSnadIncome.updateEnabled')
							: this.props.t('salesSnadIncome.updateDisabled')}
					</span>
				</div>
			</Row>
		);
	}

	render() {
		const { t } = this.props;
		return (
			<Card>
				{this.renderDialog(
					this.getSetOptions(filterParams.DEVICE),
					{ deviceDialog: false },
					this.state.deviceDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.POSITION),
					{ positionDialog: false },
					this.state.positionDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.PRODUCT),
					{ productDialog: false },
					this.state.productDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.TRANSACTION),
					{ transactionTypeDialog: false },
					this.state.transactionTypeDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.PAYMENT),
					{ paymentMethodDialog: false },
					this.state.paymentMethodDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.EMAIL),
					{ emailDialog: false },
					this.state.emailDialog
				)}
				{this.renderDialog(
					this.getSetOptions(filterParams.SELECTIONMETHOD),
					{ selectionMethodDialog: false },
					this.state.selectionMethodDialog
				)}
				<CardBody>
					{this.renderTitle()}
					{this.renderTable()}
				</CardBody>
			</Card>
		);
	}
}

export default withTranslation()(SalesAndIncomeCard);
