import React from 'react'
import { StyledComponent } from 'styled-components'
import { Col, Row, Tooltip } from 'reactstrap'
import styled from 'styled-components'
import { MachineLabel, MachineButton } from './MachineComponents'

const ButtonLed = styled.i`
  position: absolute;
  bottom: 5px;
  right: 20px;
  color: ${props => (props.isLedOn ? 'lime' : 'black')};
`

type Props = {
  buttonContent: any,
  labelContent?: any,
  buttonOnClick?: () => void,
  ButtonComponent?: StyledComponent<'button', any, {}>,
  buttonAlign?: 'left' | 'right',
}

export default class ButtonWithLabelVertical extends React.Component<Props> {
  state = { tooltipOpen: false }

  toggleTooltip = () => this.setState({ tooltipOpen: !this.state.tooltipOpen })
  render() {
    const {
      buttonContent,
      buttonID,
      slavePattern,
      labelContent = null,
      onButtonClick = (buttonId: string, slavePattern: ?string) => () => {},
      ButtonComponent = MachineButton,
      hasLed = false,
      isLedOn = false,
      ...rest
    } = this.props
    const Label = (
      <>
        <MachineLabel style={{ width: '100%', height: '80px' }}>
          {labelContent}
        </MachineLabel>
      </>
    )

    const Button = (
      <>
        <ButtonComponent
          id={`button-tooltip-${buttonID}-${slavePattern}`}
          onClick={onButtonClick(buttonID, slavePattern)}
        >
          {buttonContent}
          {hasLed && <ButtonLed isLedOn={isLedOn} className="fa fa-circle" />}
        </ButtonComponent>
        {labelContent && (
          <Tooltip
            placement="right"
            target={`button-tooltip-${buttonID}-${slavePattern}`}
            isOpen={this.state.tooltipOpen}
            toggle={this.toggleTooltip}
          >
            {labelContent}
          </Tooltip>
        )}
      </>
    )

    return (
      <Col
        {...rest}
        style={{
          padding: '2px',
          height: '100%',
        }}
      >
        <Row className="justify-content-center">
          <Col xs="12" md="12" lg="12" xl="12">
            {Button}
          </Col>
        </Row>
        {labelContent && (
          <Row className="d-none d-sm-block justify-content-center">
            <Col xs="12" md="12" lg="12" xl="12">
              {Label}
            </Col>
          </Row>
        )}
      </Col>
    )
  }
}
