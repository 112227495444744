import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
// import ModalFooter from 'react-bootstrap/ModalFooter';
// import ModalHeader from 'react-bootstrap/ModalHeader';
// import ModalBody from 'react-bootstrap/ModalBody';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Container } from 'reactstrap';
import DatePicker from 'react-datepicker';

import { dateFromMYSQLTimeStamp } from '../../services/util/dateUtil';

class HardwareConflictDialog extends Component {
	state = {
		activeMachineID: null,
		hardwaresToChange: []
	};

	componentDidMount() { }

	handleSelectionChange(e) {
		this.setState(
			{
				activeMachineID: e.target ? e.target.value : null
			},
			() => this.handleActiveHardwareChange()
		);
	}

	handleActiveHardwareChange() {
		let hardwaresToChange = [];
		if (this.state.activeMachineID) {
			const activeMachineConnectionDate = this.props.hardwareIDS
				.filter(hardware => hardware.hardwareID === this.state.activeMachineID)
				.map(hardware => hardware.fromDate)[0];

			hardwaresToChange = this.props.hardwareIDS
				.filter(hardware => hardware.hardwareID !== this.state.activeMachineID)
				.map(hardware => {
					return {
						...hardware,
						connectToOtherMachine: false,
						machineNameToConnect: '',
						toDate: dateFromMYSQLTimeStamp(activeMachineConnectionDate)
					};
				});
		}
		this.setState({
			hardwaresToChange
		});
	}

	handleCheckBoxClick(hardware) {
		let newSet = [...this.state.hardwaresToChange];
		newSet = newSet.map(curHardware => {
			if (curHardware.hardwareID === hardware.hardwareID) {
				curHardware.connectToOtherMachine = !curHardware.connectToOtherMachine;
			}
			return curHardware;
		});
		this.setState({ hardwaresToChange: newSet });
	}

	handleDateChange(hardware, newDate) {
		let newSet = [...this.state.hardwaresToChange];
		newSet = newSet.map(curHardware => {
			if (curHardware.hardwareID === hardware.hardwareID) {
				curHardware.toDate = newDate;
			}
			return curHardware;
		});
		this.setState({ hardwaresToChange: newSet });
	}

	handleMachineNameChange(hardware, e) {
		let newSet = [...this.state.hardwaresToChange];
		newSet = newSet.map(curHardware => {
			if (curHardware.hardwareID === hardware.hardwareID) {
				curHardware.machineNameToConnect = e.target.value;
			}
			return curHardware;
		});
		this.setState({ hardwaresToChange: newSet });
	}

	getStringRepFromHardware(hardware) {
		const date = dateFromMYSQLTimeStamp(hardware.fromDate);
		const dateString =
			date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
		return `${this.props.t('hardwareConflictDialog.harwareID')}${hardware.hardwareID
			}. ${this.props.t('hardwareConflictDialog.connected')}${dateString}`;
	}

	getShortRepFromHardware(hardware) {
		const date = dateFromMYSQLTimeStamp(hardware.fromDate);
		const dateString =
			date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
		return (
			<>
				{this.props.t('hardwareConflictDialog.harwareID')}
				<b>{hardware.hardwareID}</b>.<br />
				{this.props.t('hardwareConflictDialog.connected')}
				<b>{dateString}</b>
			</>
		);
	}

	renderActiveMachineSelector() {
		const machineSelectionsList = [];

		machineSelectionsList.push(<option value={null} key="0"></option>);

		// Map all the connected hardwares
		machineSelectionsList.push(
			this.props.hardwareIDS
				? this.props.hardwareIDS.map(curHardware => {
					const optionText = this.getStringRepFromHardware(curHardware);
					return (
						<option
							key={curHardware.hardwareID}
							value={curHardware.hardwareID}
						>
							{optionText}
						</option>
					);
				})
				: null
		);

		return (
			<Row style={{ justifyContent: 'Center' }}>
				<div class="form-group">
					<label>
						{this.props.t('hardwareConflictDialog.selectHardwareID')}
					</label>
					<select
						class="form-control"
						id="activeMachineSelection"
						value={this.state.activeMachineID ? this.state.activeMachineID : ''}
						onChange={e => this.handleSelectionChange(e)}
					>
						{machineSelectionsList}
					</select>
				</div>
			</Row>
		);
	}

	renderHardwareActionList() {
		const actionList = this.state.hardwaresToChange
			.filter(hardware => hardware.hardwareID !== this.state.activeMachineID)
			.map(hardware => {
				return (
					<div key={hardware.hardwareID}>
						<Row style={{ justifyContent: 'Center' }}>
							<Col>{this.getShortRepFromHardware(hardware)}</Col>
						</Row>
						<Row style={{ justifyContent: 'Center', marginTop: '0.5rem' }}>
							<Col>
								<label for="exampleFormControlInput1">
									{this.props.t('hardwareConflictDialog.closeDate')}
								</label>
							</Col>
							<Col>
								<DatePicker
									selected={hardware.toDate}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="time"
									dateFormat="yyyy MMMM d h:mm aa"
									onChange={date => this.handleDateChange(hardware, date)}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: '0.5rem' }}>
							<Col>
								<label for="viewLCDChecked">
									{this.props.t('hardwareConflictDialog.connectHardware')}
								</label>
							</Col>
							<Col>
								<input
									className="form-check-input"
									type="checkbox"
									id="viewLCDChecked"
									name="viewLCDChecked"
									checked={hardware.connectToOtherMachine}
									onChange={() => this.handleCheckBoxClick(hardware)}
									style={{ marginLeft: '0px' }}
								/>
							</Col>
						</Row>
						{hardware.connectToOtherMachine ? (
							<Row>
								<Col>
									<label for="machineNameToConnect">
										{this.props.t('hardwareConflictDialog.nameOfMachine')}
									</label>
								</Col>
								<Col>
									<input
										type="input"
										class="form-control"
										id="machineNameToConnect"
										value={hardware.machineNameToConnect}
										onChange={e => this.handleMachineNameChange(hardware, e)}
									/>
								</Col>
							</Row>
						) : null}
						<hr />
					</div>
				);
			});
		return (
			<>
				{this.props.t('hardwareConflictDialog.pleaseSelect')}
				<div style={{ marginTop: '1rem' }}>{actionList}</div>
			</>
		);
	}

	render() {
		return (
			<Modal
				show={this.props.show}
				onHide={this.props.onCancel}
				className="model-info"
				size="lg"
			>
				<Modal.Header style={{ justifyContent: 'left' }}>
					<i className="fa fa-cog" /> {'	'}
					<div style={{ marginLeft: '0.5rem' }}>
						{this.props.t('hardwareConflictDialog.title')}
					</div>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row style={{ justifyContent: 'Center' }}>
							<div class="alert alert-primary" role="alert">
								<p style={{ textAlign: 'center' }}>
									<span
										style={{ display: 'inline-block', marginRight: '1rem' }}
										class="fa fa-exclamation"
									/>

									{this.props.t('hardwareConflictDialog.topMessage')}
								</p>
								<p style={{ textAlign: 'center' }}>
									{this.props.t('hardwareConflictDialog.middleMessage')}
								</p>
								<p style={{ marginTop: '3rem', textAlign: 'center' }}>
									{this.props.t('hardwareConflictDialog.bottomMessage')}
								</p>
							</div>
						</Row>
						{this.renderActiveMachineSelector()}
						<hr />
						{this.state.activeMachineID
							? this.renderHardwareActionList()
							: null}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button
						color="secondary"
						className="DefaultButton"
						onClick={() => this.props.onCancel()}
					>
						{this.props.t('hardwareConflictDialog.cancel')}
					</Button>
					<Button
						color={this.props.infoDialog ? 'info' : 'danger'}
						className="DefaultButton"
						onClick={() => this.props.onOk(this.state.hardwaresToChange)}
					>
						{this.props.t('hardwareConflictDialog.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default withTranslation()(HardwareConflictDialog);
