import axios from './api'
import qs from 'qs'

const getRemoteLogs = async param => {
  const query = qs.stringify(param, { addQueryPrefix: true })
  return await axios.get().get(`/machines/logs${query}`)
}

export default {
  getRemoteLogs: getRemoteLogs,
  getOperatorLogs: async (startDate, endDate) => {
    return getRemoteLogs({
      startDate,
      endDate,
      isOperator: true,
    })
  },
  getOwnLogs: async (startDate, endDate) => {
    return getRemoteLogs({
      startDate,
      endDate,
    })
  },
  getLogs: async vmID => {
    return await axios.get().get(`/machines/${vmID}/logs`)
  },
}
