import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
//Admin
import AddAdmin from '../../views/Root/AddNewAdmin.js'
import AdminList from '../../views/Root/AdminList.js'
import UserEditScreen from '../../views/Users/UserEditScreen.js'

class CustomerUsers extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/rootUsers/addAdmin"
            name="AddAdmin"
            component={AddAdmin}
          />

          <Route
            path="/rootUser/:uID/editprofile"
            name="Profile Edit"
            component={UserEditScreen}
          />

          <Route path="/rootUsers" name="AdminList" component={AdminList} />

          <Redirect from="/" to="/rootUsers" />
        </Switch>
      </div>
    )
  }
}
//<Container fluid>
export default CustomerUsers
