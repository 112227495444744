import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';
import ChartByType from './ChartByType';

class ProductSales extends Component {
	render() {
		return (
			<div
				className="animated fadeIn"
				style={{
					paddingLeft: '20px',
					paddingRight: '20px',
					paddingTop: '10px'
				}}
			>
				<Card body>
					<PageTiltleCard
						title={this.props.t('productSales.title')}
					></PageTiltleCard>
				</Card>
				<ChartByType />
			</div>
		);
	}
}

export default withTranslation()(ProductSales);
