//@flow
import React from 'react';

import DashboardCardWithStatistics from '../CardHandling/DashboardCardWithStatistics';
import DashboardCardContainerWithFlow from '../CardHandling/DashboardCardContainerWithFlow';
import { SystemStaticsDataType } from '../CardHandling/SystemStaticsDataType';

import { Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PageTitleCard from '../CardHandling/PageTitleCard/PageTitleCard';

// import TrafficCard from '../CardHandling/TrafficCard/TrafficCard';
// import MachineListCard from '../CardHandling/MachineListCard/MachineListCard';
// import UserList from '../../UserList/UserList';

import classes from './RootDashboard.module.css';

class RootDashboard extends React.Component {
	componentDidMount() { }

	render() {
		const statRefreshInteval = 60000;
		const { t } = this.props;
		return (
			<div className="animated fadeIn">
				<Row>
					<DashboardCardContainerWithFlow>
						<div className={classes.RootCardTraffic}>
							<PageTitleCard title={t('customerDashboard.title')} />
						</div>
						<div className={classes.HeaderCard}>
							<DashboardCardWithStatistics
								className={classes.RootCard + ' bg-info'}
								subTitle={t('rootdashboard.allPartner.title')}
								iconClass="fa fa-user"
								refreshInterval={statRefreshInteval}
								statisticsDataType={SystemStaticsDataType.partnerCount}
								dayBased={true}
								titleOnClick={() => (window.location = '#/partnerManagement')}
							/>
						</div>
						<div className={classes.HeaderCard}>
							<DashboardCardWithStatistics
								className={classes.RootCard + ' bg-info'}
								subTitle={t('rootdashboard.allMachines.title')}
								iconClass="fa fa-server"
								refreshInterval={statRefreshInteval}
								statisticsDataType={SystemStaticsDataType.machineCount}
								dayBased={true}
								titleOnClick={() => (window.location = '#/hardwareManagement')}
							/>
						</div>
						<div className={classes.HeaderCard}>
							<DashboardCardWithStatistics
								className={classes.RootCard + ' bg-info'}
								subTitle={t('rootdashboard.activeConnections.title')}
								iconClass="fa fa-plug"
								refreshInterval={statRefreshInteval}
								statisticsDataType={SystemStaticsDataType.liveMachineCount}
							/>
						</div>
						<div className={classes.HeaderCard}>
							<DashboardCardWithStatistics
								className={classes.RootCard + ' bg-info'}
								subTitle={t('rootdashboard.freeUnits.title')}
								iconClass="fa fa-briefcase"
								refreshInterval={statRefreshInteval}
								statisticsDataType={SystemStaticsDataType.freeDeviceCount}
								dayBased={true}
							/>
						</div>
						{/* <div className={classes.HeaderCard}>
							<DashboardCardWithStatistics
								className={classes.RootCard + ' bg-warning'}
								subTitle={t('rootdashboard.machinesWithErrors.title')}
								iconClass="fa fa-exclamation-triangle"
								refreshInterval={statRefreshInteval}
								statisticsDataType={
									SystemStaticsDataType.machineWithErrorsCount
								}
							/>
						</div>
						<TrafficCard className={classes.RootCardTraffic} />
						<MachineListCard
							title={t('rootdashboard.newestFiveMachine.title')}
							className={classes.RootCardMachines}
							iconClass="fa fa-server"
							hiddenColumns={[
								'stock',
								'lastSaleDate',
								'statusUpdatedAt',
								'detailButtons',
								'lastSale',
								'machinePartnerIdentifier'
							]}
							titleOnClick={() => (window.location = '#/hardwareManagement')}
						></MachineListCard>
						<UserList
							title={t('rootdashboard.newestFiveUsers.title')}
							className={classes.RootCardMachines}
							renderDocked
							hideToolbar
							hidePager
							limit="5"
							titleOnClick={() => (window.location = '#/users')}
							hiddenColumns={['connPhone', 'firstName', 'lastName', 'action']}
						></UserList> */}
					</DashboardCardContainerWithFlow>
				</Row>
			</div>
		);
	}
}

export default withRouter(withTranslation()(RootDashboard));
