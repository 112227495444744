import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import CustomersApi from '../../services/api/customers';

import Button from 'react-bootstrap/Button';
import Spinner from '../../components/UI/Spinner/Spinner';

import CustomerGeneral from './General/CustomerGeneral';
import SystemApi from '../../services/api/system';
import classes from './CompanyProfileView.module.css';
import DashboardCardContainerWithFlow from '../Dashboard/CardHandling/DashboardCardContainerWithFlow';
import { ROLES } from '../../services/util/consts';
import PageTiltleCard from '../Dashboard/CardHandling/PageTitleCard/PageTitleCard';

class CompanyProfile extends Component {
	state = { valueChanged: false };

	componentDidMount() {
		SystemApi.getCurrencies().then(res => {
			this.setState({ currencies: res.data.currencies });
		});
		CustomersApi.getCustomer(this.props.usePrivilege.cmID).then(resp => {
			this.setState({
				customer: resp.data.response[0]
			});
		});
	}

	onChangeMade(newCustomer) {
		this.setState({ valueChanged: true, customer: { ...newCustomer } });
	}

	async onSaveChanges() {
		await CustomersApi.saveCustomer(this.state.customer);
		this.setState({ valueChanged: false });
	}

	render() {
		const { t } = this.props;
		const editable = this.props.usePrivilege.privilege === ROLES.companyAdmin;

		return (
			<div className="animated fadeIn">
				<DashboardCardContainerWithFlow>
					<Card body>
						<PageTiltleCard title={t('sidebarmenu.companyInformation')} />
						<Card>
							<CardBody>
								<Row>
									<Col sm="5">
										<CardTitle className="mb-0 text-muted">
											<i
												className="icon-equalizer"
												style={{ marginRight: '10px' }}
											></i>
											{t('sidebarmenu.companyInformation')}
										</CardTitle>

										<div className="small text-muted"></div>
									</Col>
									<Col>
										{editable ? (
											<Button
												variant={
													this.state.valueChanged ? 'success' : 'secondary'
												}
												disabled={!this.state.valueChanged}
												className="DefaultButton"
												onClick={() => this.onSaveChanges()}
												style={{
													float: 'right'
												}}
											>
												<i
													className="fa fa-floppy-o mr-2"
													aria-hidden="true"
												></i>
												&nbsp;
												{this.props.t('partnerManagement.customerGeneral.save')}
											</Button>
										) : null}
									</Col>
								</Row>
								<div className={classes.InnerDiv}>
									{this.state.customer ? (
										<CustomerGeneral
											disabled={!editable}
											company={this.state.customer}
											currencies={this.state.currencies}
											onChange={companyProfile =>
												this.onChangeMade(companyProfile)
											}
										/>
									) : (
										<Spinner />
									)}
								</div>
							</CardBody>
						</Card>
					</Card>
				</DashboardCardContainerWithFlow>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	usePrivilege: state.user
});

export default connect(mapStateToProps)(withTranslation()(CompanyProfile));
